import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  signerData: {
    name: null,
    date: null,
    comment: null,
  },
  data: null,
  isLoading: false,
});

const getters = {
  form: (state) => state.data?.form || null,
  formItems: (state) => state.data?.items || [],
  company: (state) => state.data?.company || null,
  sender: (state) => state.data?.sender || null,
  signer: (state) => state.data?.signer || null,
};

const actions = {
  async getData({ commit }, { hash }) {
    commit("loading", true);
    return axios
      .get(`${apiUrl}form-signature-session/in-progress`, { params: { hash } })
      .then(({ data }) => {
        const items = data.items.map((item) => ({
          ...item,
          imagesLoading: false,
          images: (item.images || []).map((image) => ({ id: image, url: null })),
          tasks: (item.tasks || []).map((task) => ({
            ...task,
          })),
        }));
        data.items = items;
        commit("setData", data);
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        commit("loading", false);
      });
  },
  async getFormItemImages({ commit }, { hash, item, loading = true }) {
    if (loading) {
      commit("toggleImagesLoading", { item, isLoading: true });
    }
    for (let image of item.images) {
      await axios
        .get(`${apiUrl}form-signature-session/image`, {
          responseType: "blob",
          params: { hash, itemId: item.id, imageId: image.id },
          skipErrorHandling: true,
        })
        .then(({ data }) => {
          const imageUrl = URL.createObjectURL(data);
          commit("addImage", { item, imageUrl, imageId: image.id });
        })
        .catch((error) => {
          commit("addImage", { item, imageUrl: null, imageId: image.id });
        });
    }
    commit("toggleImagesLoading", { item, isLoading: false });
  },
};

const mutations = {
  setData(state, data) {
    state.data = data;
    state.signerData = {
      name: data.signer.name,
      date: data.signer.date,
      comment: data.signer.comment,
    };
  },
  updateSignerField(state, { field, value }) {
    state.signerData[field] = value;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  toggleImagesLoading(state, { item, isLoading }) {
    const index = state.data.items.findIndex((x) => x.id === item.id);
    if (index !== -1) {
      state.data.items[index].imagesLoading = isLoading;
    }
  },
  addImage(state, { item, imageUrl, imageId }) {
    const index = state.data.items.findIndex((x) => x.id === item.id);
    if (index !== -1 && Array.isArray(state.data.items[index].images)) {
      const imageIndex = state.data.items[index].images.findIndex(
        (x) => x.id === imageId,
      );
      if (imageIndex !== -1) {
        state.data.items[index].images.splice(imageIndex, 1, {
          ...state.data.items[index].images[imageIndex],
          url: imageUrl,
        });
      } else {
        state.data.items[index].images.push({ id: imageId, url: imageUrl });
      }
    }
  },
};

export const formSignSessionInProgress = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
