<template>
  <v-card
    flat
    class="pa-10 ma-5 mb-0"
    color="input-background"
  >
    <div class="h-100 d-flex flex-column align-center justify-center">
      <div class="mb-2 font-weight-bold text-h6 text-center">{{ title }}</div>
      <div class="mb-8 font-weight-medium text-center">
        {{ subtitle }}
      </div>
      <v-btn
        color="primary"
        @click="onActivateClick"
        depressed
        :loading="isActivating"
      >
        {{ buttonText }}
        <AppSubscriptionPlanStar
          v-if="!canIntegrate"
          class="pl-2"
          small
          :planCode="planCode"
        />
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { configureIntegration } from "@/services/integration/configureIntegrationService";
import { planRestrictionsMixins } from "@/helpers/mixins";

export default {
  mixins: [planRestrictionsMixins],
  props: {
    integrationName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    planCode: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isActivating: false,
    };
  },
  computed: {
    canIntegrate() {
      return this.planRestrictionsMixins_canIntegrateByIntegrationName(
        this.integrationName,
      );
    },
  },
  methods: {
    onActivateClick() {
      if (!this.canIntegrate) {
        this.openUpgradePlanDialog();
      } else {
        this.isActivating = true;

        // Create a showConfigDialog function that emits an event
        const showConfigDialog = (integrationName) => {
          this.$emit("show-config-dialog", integrationName);
          this.isActivating = false;
        };

        configureIntegration({
          integrationName: this.integrationName,
          onSuccess: () => {
            this.$store.dispatch("activeIntegrations/getActiveIntegrations");
            this.$emit("success", this.integrationName);
          },
          showConfigDialog: showConfigDialog,
        }).finally(() => {
          // Only set isActivating to false if we didn't show a dialog
          // (otherwise it would flash when a dialog is shown)
          if (this.isActivating) {
            this.isActivating = false;
          }
        });
      }
    },
    openUpgradePlanDialog() {
      this.$store.dispatch("upgradePlanDialog/setUpgradePlanDialog", {
        active: true,
        planCode: this.planCode || this.$constant.PLAN_CODE.GRIPR_PRO,
        onAfterUpgrade: () => this.onActivateClick(),
      });
    },
  },
};
</script>
