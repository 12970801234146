import { ROUTE_NAMES, ROUTE_PERMISSIONS } from "@/helpers/routeNames";
import { authGuard, userGuard } from "@/auth";
import multiguard from "../multiguard";

const { ROOT, USER_LIST, TEAM_LIST } = ROUTE_NAMES.EMPLOYEE;

export const employeeRoutes = [
  {
    path: "/employee",
    name: ROOT,
    beforeEnter: multiguard([authGuard, userGuard]),
    component: () =>
      import(/* webpackChunkName: "employee" */ "../../pages/EmployeesPage"),
    meta: {
      permission: {
        isRedirectRoute: true,
      },
    },
    children: [
      {
        path: "user-list",
        name: USER_LIST,
        meta: { permission: ROUTE_PERMISSIONS.USER },
        component: () =>
          import(
            /* webpackChunkName: "employee" */ "../../pages/EmployeesPage/UsersListPage"
          ),
      },
      {
        path: "teams-list",
        name: TEAM_LIST,
        meta: { permission: ROUTE_PERMISSIONS.USER },
        component: () =>
          import(
            /* webpackChunkName: "employee" */ "../../pages/EmployeesPage/TeamsListPage"
          ),
      },
    ],
  },
];
