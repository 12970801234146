export const DOMAIN_DRAWER_SECTIONS = {
  COMPANY: "COMPANY",
  CRM: "CRM",
  MANAGE: "MANAGE",
};

export const SETTINGS_DRAWER_SECTIONS = {
  PERSONAL: "PERSONAL",
  WORKSPACE: "WORKSPACE",
  SETTINGS: "SETTINGS",
  SUPPORT: "SUPPORT",
};

export const DRAWER = {
  WIDTH: 240,
  MINI_WIDTH: 64,
};
