<template>
  <div>
    <AppContextMenu
      ref="menu"
      min-width="200px"
      max-width="400px"
      max-height="400px"
      :closeOnClick="!addLabelDialog.active && !updateLabelDialog.active"
    >
      <v-list-item
        dense
        class="ui-background"
        @click="onLabelChange({ id: null })"
        :class="{ 'v-list-item--active': isSingleSelection && !selectedLabel?.id }"
        :readOnly="!selectedLabel?.id"
      >
        <v-list-item-title class="text-body-2">
          {{ $t(!selectedLabel?.id ? "common.noneSelected" : "common.clearSelection") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        dense
        class="ui-background d-flex justify-center"
        v-if="isLoading && !labels.length"
      >
        <v-icon>{{ $icons.SOLID.ACTION.LOADING }}</v-icon>
      </v-list-item>
      <div v-else>
        <v-list-item
          dense
          v-for="label of labels"
          :key="label.id"
          class="ui-background"
          :class="{
            'v-list-item--active': isSingleSelection && label.id === selectedLabel?.id,
          }"
          @click.stop="onLabelChange(label)"
        >
          <v-list-item-content class="text-body-2">
            {{ label.name }}
          </v-list-item-content>
          <v-btn
            v-if="canEditLabel"
            small
            icon
            @click.stop="onEditLabel(label)"
          >
            <v-icon small>{{ $icons.LIGHT.ACTION.EDIT }}</v-icon>
          </v-btn>
        </v-list-item>
      </div>
      <AppSelectAppendBtn
        v-if="canEditLabel"
        dense
        @click.stop="onAddLabel"
      >
        {{ $t(`${translationPath}.createLabel`) }}
      </AppSelectAppendBtn>
    </AppContextMenu>
    <AppLabelDialog
      v-model="addLabelDialog.active"
      :type="labelType"
      :title="$t(`${translationPath}.addLabelDialog.title`)"
      :confirmText="$t('common.create')"
    />
    <AppLabelDialog
      v-model="updateLabelDialog.active"
      :data="updateLabelDialog.data"
      :type="labelType"
      :title="$t(`${translationPath}.updateLabelDialog.title`)"
      :confirmText="$t('common.save')"
      :deleteTitle="$t(`${translationPath}.updateLabelDialog.deleteLabelDialog.title`)"
      :deleteSubtitle="
        $t(`${translationPath}.updateLabelDialog.deleteLabelDialog.subTitle`)
      "
    />
  </div>
</template>

<script>
import { labelMixins } from "@/helpers/mixins";
import { permissionMixins } from "@/helpers/mixins";
import { mapGetters } from "vuex";

export default {
  props: {
    labelType: String,
    selectedItemLabelPath: String,
  },
  mixins: [labelMixins, permissionMixins],
  data() {
    return {
      addLabelDialog: {
        active: false,
      },
      updateLabelDialog: {
        active: false,
        data: null,
      },
      selectedItems: [],
    };
  },
  computed: {
    ...mapGetters("label", {
      labelsByType: "labelsByType",
      isLoadingByType: "isLoadingByType",
    }),
    canEditLabel() {
      return this.permissionMixins_userLabel.edit.can;
    },
    labels() {
      return this.labelsByType(this.labelType);
    },
    isLoading() {
      return this.isLoadingByType(this.labelType);
    },
    isSingleSelection() {
      return this.selectedItems.length === 1;
    },
    selectedLabel() {
      if (!this.isSingleSelection) return null;

      const selectedItem = this.selectedItems[0];
      return this.selectedItemLabelPath.split(".").reduce((accumulator, key) => {
        return accumulator?.[key];
      }, selectedItem);
    },
    translationPath() {
      return this.labelMixins_translationPath(this.labelType);
    },
  },
  methods: {
    open({ from, items }) {
      this.getLabels();
      this.setItems(items);
      this._open({ from });
    },
    setItems(items) {
      this.selectedItems = items || [];
    },
    _open({ from }) {
      this.$refs.menu.open({ from, direction: "bottom" });
    },
    close() {
      this.$refs.menu.close();
    },
    onLabelChange(label) {
      this.$emit("label:change", {
        items: this.selectedItems,
        label: label,
      });
      this.close();
    },
    getLabels() {
      if (!this.labels) {
        this.$store
          .dispatch("label/getLabels", {
            labelType: this.labelType,
          })
          .finally(() => {
            this.afterGet();
          });
      }
    },
    afterGet() {
      this.$refs.menu.updateDimensions();
    },
    onAddLabel() {
      this.addLabelDialog.active = true;
    },
    onEditLabel(label) {
      this.updateLabelDialog.active = true;
      this.updateLabelDialog.data = label;
    },
  },
};
</script>
