import i18n from "@/plugins/i18n";
import { capitalize } from "lodash";

const DEFAULT_HEADER_PROPS = {
  access: true,
  compact: false,
  required: false,
  enabled: true,
  sortable: true,
  align: "start",
  disabled: false,
};

export const addDefaultHeaderProps = (config) => {
  return {
    ...DEFAULT_HEADER_PROPS,
    ...config,
  };
};

export const HEADER_PRESETS = {
  SELECT: {
    value: "data-table-select",
    compact: true,
    align: "center",
  },
  STATUS: {
    align: "center",
    compact: true,
    text: () => capitalize(i18n.t("common.status")),
  },
  MENU: {
    align: "center",
    compact: true,
    sortable: false,
    text: () => capitalize(i18n.t("common.menu")),
    value: "menu",
    disabled: true,
  },
  NAME: {
    required: true,
    text: () => capitalize(i18n.t("common.name")),
    value: "name",
  },
  DATE: {
    compact: true,
  },
  CONTENT_OVERVIEW: {
    align: "end",
    compact: true,
    sortable: false,
    disabled: true,
    text: () => capitalize(i18n.t("common.contentOverview")),
  },
};

export const createTableHeader = (config, preset) => {
  const header = addDefaultHeaderProps({
    ...HEADER_PRESETS[preset],
    ...config,
  });

  // Evaluate the text function if it exists
  if (typeof header.text === "function") {
    header.text = header.text();
  }

  return header;
};

export const createTableHeaders = (headerConfigs) => {
  return headerConfigs.map(({ preset, ...config }) => createTableHeader(config, preset));
};
