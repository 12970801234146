import axios from "axios";
const apiUrl = process.env.VUE_APP_ACCESS_CONTROL_API_URL;

const state = () => ({
  permissions: [
    /// global//me example
    // {
    //   resource: "me",
    //   permissions: {
    //     status: { view: { can: true } },
    //   },
    // },
    //DAC/entity/resource example
    // {
    //   resource: "project",
    //   id: "123-id-uuid",
    //   permissions: {
    //     contact: { view: { can: true } },
    //     client: { view: { can: true } },
    //   },
    // },
  ],
});

const getters = {
  hasLoadedResource:
    (state) =>
    ({ id = undefined, resource }) => {
      return !!state.permissions.find((p) => p.id === id && p.resource === resource);
    },
  getPermissions:
    (state) =>
    ({ id = undefined, resource }) => {
      if (!resource || !id) {
        return state.permissions.find((p) => p.resource === "me")?.permissions || {};
      }
      return (
        state.permissions.find((p) => p.resource === resource && p.id === id)
          ?.permissions || {}
      );
    },
};

const actions = {
  async fetchResourcePermission({ commit, getters }, { resource, id }) {
    if (getters.hasLoadedResource({ id, resource })) {
      return getters.getPermissions({ id, resource });
    }

    try {
      const response = await axios.post(`${apiUrl}permission/resource-permission`, {
        resource,
        resourceIds: [id],
      });

      const permissions = response.data?.permissions?.resources?.[0];

      commit("setResourcePermissions", {
        resource,
        id,
        permissions,
      });

      return permissions;
    } catch (error) {
      console.error("Permission fetch failed:", error);
      throw error;
    }
  },
};

const mutations = {
  setResourcePermissions: (state, { resource, id, permissions }) => {
    const index = state.permissions.findIndex(
      (p) => p.resource === resource && p.id === id,
    );

    if (index === -1) {
      state.permissions.push({ resource, id, permissions });
    } else {
      state.permissions[index].permissions = permissions;
    }
  },
};

export const permissions = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
