// utils/permissions.js
import router from "@/router";
import store from "@/store";
import { uniqWith, isEqual } from "lodash";

/*
  README:
  If item.permissions are easily available, this this helper logic is not required. Just check the item.
  This helper is only required if item.permissions are not easily available.
*/

const RESOURCE_MAPPING = {
  formId: "form",
  projectId: "project",
  taskId: "task",
};

const SPECIAL_PATH_GETTERS = {
  cms: "auth/hasCmsAccess",
  support: "auth/isSupportUser",
};

export const permissionHelpers = {
  getResourceByIdParam(idParam) {
    return RESOURCE_MAPPING[idParam] || "me";
  },
  // Base permission getter
  // Pass EITHER (id and resource OR idParam) + always path.
  // If idParam is passed, it automatically figures out the resource and id.
  // If only path is passed, it will check the global permissions from /me
  getPermissionObject({ id = null, resource = null, idParam = null, path }) {
    const routeParams = router.currentRoute.params;

    if (path in SPECIAL_PATH_GETTERS) {
      const getterPath = SPECIAL_PATH_GETTERS[path];
      return {
        view: {
          can: store.getters[getterPath],
        },
      };
    }

    // Default resource mapping
    if (id && resource) {
      return this._resolvePermission(path, resource, id);
    }

    // If idParam is specified, use that to determine resource and id
    if (idParam && routeParams[idParam]) {
      const resource = this.getResourceByIdParam(idParam);

      return this._resolvePermission(path, resource, routeParams[idParam]);
    }

    // Fallback to 'me' with no id if no matches
    return this._resolvePermission(path, "me", null);
  },

  // Helper method to avoid repetition
  _resolvePermission(path, resource, id) {
    const getPermissions = store.getters["permissions/getPermissions"];
    const permissions = getPermissions({ id, resource });

    if (!path) {
      return permissions;
    }

    const permissionObject = path
      .split(".")
      .reduce((obj, key) => obj?.[key], permissions);

    return permissionObject;
  },
  getRequiredPermissionForRoute(resolvedRoute) {
    const permissions = resolvedRoute.matched.reduce((acc, record) => {
      if (record.meta?.permission && !record.meta.permission.isRedirectRoute) {
        // Clone the permission object to avoid reference issues
        const permission = { ...record.meta.permission };
        acc.push(permission);
      }
      return acc;
    }, []);

    // Filter out duplicate permission objects
    return uniqWith(permissions, isEqual);
  },

  async findFirstAccessibleRoute(routes, routeContext = {}) {
    const accessChecks = await Promise.all(
      routes.map(async (route) => ({
        route,
        hasAccess: await this.checkRoutePermission({
          ...router.resolve({
            name: route.name,
            params: routeContext.params || {},
            query: routeContext.query || {},
          }).route,
        }),
      })),
    );

    const firstAccessible = accessChecks.find((check) => check.hasAccess);
    return firstAccessible ? firstAccessible.route : null;
  },

  findRouteByName(routes, routeName) {
    for (const route of routes) {
      // Check current route
      if (route.name === routeName) {
        return route;
      }

      // Check children recursively
      if (route.children?.length) {
        const childRoute = this.findRouteByName(route.children, routeName);
        if (childRoute) {
          return childRoute;
        }
      }
    }
    return null;
  },

  // Private helper methods to reduce duplication
  _isRedirectRoute(route) {
    return route?.meta?.permission?.isRedirectRoute && route?.children?.length > 0;
  },

  // Checks permissions for a standard route (not a redirect route)
  _checkStandardRoutePermissions(permissions) {
    return (
      !permissions.length || permissions.every((permission) => permission?.view?.can)
    );
  },

  // Sync version to check parent route chain permissions
  _checkParentRouteChainPermissions(routeName) {
    const resolvedRoute = router.resolve({ name: routeName }).route;
    const permissions = this.getRequiredPermissionForRoute(resolvedRoute);

    if (!permissions.length) return true;

    const permissionObjects = permissions.map((permissionMeta) => {
      const { path, idParam, resource } = permissionMeta || {};
      return this.getPermissionObject({ resource, path, idParam });
    });

    return permissionObjects.every((p) => p?.view?.can);
  },

  // Async version to check parent route chain permissions
  async _checkParentRouteChainPermissionsAsync(to) {
    const permissions = this.getRequiredPermissionForRoute(to);

    if (!permissions.length) return true;

    const permissionChecks = await Promise.all(
      permissions.map(async ({ path = null, idParam = null }) => {
        if (!path && !idParam) return true;

        const resource = this.getResourceByIdParam(idParam);

        if (idParam) {
          const id = to.params?.[idParam];
          const permission = await store.dispatch("permissions/fetchResourcePermission", {
            resource,
            id,
          });
          return permission.view.can;
        }

        const permission = this.getPermissionObject({ path });
        return permission.view.can;
      }),
    );

    return permissionChecks.every(Boolean);
  },

  // Main public methods
  canViewRoute(routeName) {
    const route = this.findRouteByName(router.options.routes, routeName);

    // Special handling for redirect routes
    if (this._isRedirectRoute(route)) {
      // First check parent permissions in the route chain
      if (!this._checkParentRouteChainPermissions(routeName)) {
        return false;
      }

      // If parent permissions pass, check if ANY child is accessible
      return route.children.some((child) => this.canViewRoute(child.name));
    }

    // Standard route permission check
    const permissions = this.getPermissionsForRoute(routeName);
    return this._checkStandardRoutePermissions(permissions);
  },

  async checkRoutePermission(to) {
    const route = this.findRouteByName(router.options.routes, to.name);

    // Special handling for redirect routes
    if (this._isRedirectRoute(route)) {
      // First check parent permissions in the route chain
      if (!(await this._checkParentRouteChainPermissionsAsync(to))) {
        return false;
      }

      // If parent permissions pass, check if ANY child route is accessible
      const childChecks = await Promise.all(
        route.children.map((child) =>
          this.checkRoutePermission({
            ...router.resolve({
              name: child.name,
              params: to.params,
              query: to.query,
            }).route,
          }),
        ),
      );

      return childChecks.some(Boolean);
    }

    // Standard route permission check
    return await this._checkParentRouteChainPermissionsAsync(to);
  },

  /**
   * Synchronously checks if a route can be displayed in navigation
   * Use this for menu items, UI elements, and computed properties where it is more
   * logical to check route instead of resource (example navigation drawer)
   * Only uses already loaded permissions
   * @param {string} routeName - The route name to check
   * @returns {boolean} Whether the route should be visible
   */
  getPermissionsForRoute(routeName) {
    const route = this.findRouteByName(router.options.routes, routeName);
    const resolvedRoute = router.resolve({ name: routeName }).route;

    // If route is redirect route, get permissions from children
    if (route?.meta?.permission?.isRedirectRoute && route?.children?.length) {
      return route.children.flatMap((child) => this.getPermissionsForRoute(child.name));
    }

    const permissions = this.getRequiredPermissionForRoute(resolvedRoute);

    if (!permissions.length) {
      return [];
    }

    return permissions.map((permissionMeta) => {
      const { path, idParam, resource } = permissionMeta || {};
      return this.getPermissionObject({
        resource,
        path,
        idParam,
      });
    });
  },
};
