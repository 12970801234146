var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'drop-area rounded-lg overflow-hidden',
    _vm.isDraggingOver ? 'drop-area--dragging-over' : '',
    !_vm.hasFiles ? 'drop-area--empty' : 'drop-area--filled',
    _vm.loading ? 'drop-area--loading' : '',
    _vm.disabled ? 'drop-area--disabled' : '',
    _vm.borderEnabled ? 'border-a' : '',
    _vm.cursorEnabled === false ? 'drop-area--no-cursor' : '',
  ],on:{"click":_vm.handleClick,"dragover":function($event){$event.preventDefault();return _vm.onDragOver.apply(null, arguments)},"dragleave":function($event){$event.preventDefault();return _vm.onDragLeave.apply(null, arguments)},"drop":function($event){$event.preventDefault();return _vm.onFileDrop.apply(null, arguments)}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }