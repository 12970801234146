<template>
  <v-dialog
    v-model="localDialog"
    scrollable
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        {{ $t("timeRegistration.projectSpecificHourTypes.title") }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t("timeRegistration.projectSpecificHourTypes.subtitleStart") }}
        <br />
        <a
          href="#"
          class="info--text underline-on-hover"
          @click.prevent="openTimeEntrySettings"
        >
          {{ $t("timeRegistration.projectSpecificHourTypes.clickHere") }}
        </a>
        {{ $t("timeRegistration.projectSpecificHourTypes.subtitleEnd") }}
      </v-card-subtitle>
      <v-card-text ref="cardText">
        <CreateActivitiesCard
          :title="$t('timeRegistration.projectSpecificHourTypes.activity')"
          :projectId="projectId"
          :showNumber="hasShowNumberOnProjectSpecificHourTypesIntegrationEnabled"
          :showAccounting="false"
          :isInternal="false"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          depressed
          color="primary"
          @click="close"
        >
          {{ $t("common.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import CreateActivitiesCard from "@/pages/SettingsPage/AccountPage/SystemSettings/TimeEntrySettings/CreateActivitiesCard.vue";

export default {
  name: "AppActivitiesDialog",
  components: {
    CreateActivitiesCard,
  },
  props: {
    dialog: Boolean,
    projectId: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      isCreating: false,
    };
  },
  watch: {
    dialog: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getHourTypes();
        }
      },
    },
  },
  computed: {
    ...mapGetters("activeIntegrations", {
      hasShowNumberOnProjectSpecificHourTypesIntegrationEnabled:
        "hasShowNumberOnProjectSpecificHourTypesIntegrationEnabled",
    }),
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  methods: {
    getHourTypes() {
      this.$store.dispatch("hourTypes/getHourTypes", {
        isActive: true,
        projectId: this.projectId,
      });
    },
    close() {
      this.localDialog = false;
    },
    openTimeEntrySettings() {
      window.open(
        this.$router.resolve({
          name: this.$routeNames.SETTINGS.TIME_ENTRY_SETTINGS,
        }).href,
        "_blank",
      );
    },
  },
};
</script>
