import axios from "axios";
import FileSaver from "file-saver";
import byteSize from "byte-size";
const apiUrl = process.env.VUE_APP_API_URL;
const CancelToken = axios.CancelToken;
let detailsCancel;

const state = () => ({
  item: null,
  storageRelatedEntities: {},
  parent: {
    item: null,
    canUpload: null,
    isRoot: false,
    isLoading: false,
  },
  share: {
    link: null,
    isLoading: false,
    isCreating: false,
    hasCopied: false,
  },
  isLoading: false,
  isDownloading: false,
  isUpdatingFileVersion: false,
});

const getters = {
  hasShareLink: (state) => {
    return !state.share.isLoading && state.share.link;
  },
  parentIsValid: (state) => {
    return state.parent.item || state.parent.isRoot ? true : false;
  },
  canUpload: (state, getters) => {
    return getters.parentIsValid && !state.isLoading && state.parent.canUpload;
  },
};

const actions = {
  async getDetails({ commit, dispatch }, id) {
    commit("loading", true);
    if (detailsCancel) detailsCancel();
    return axios
      .get(`${apiUrl}storage/file/${id}`, {
        cancelToken: new CancelToken(function executor(c) {
          detailsCancel = c;
        }),
      })
      .then(async ({ data }) => {
        const storage = data.storage;

        if (storage.size) {
          storage.byteSize = `${byteSize(storage.size)}`;
        }

        if (storage.parentId) {
          dispatch("getDetailsParent", storage.parentId);
        }

        commit("setItem", storage);
        commit("loading", false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          commit("loading", false);
          throw new Error(error);
        }
      });
  },
  async getDetailsParent({ commit }, id) {
    commit("parentLoading", true);
    return axios
      .get(`${apiUrl}storage/folder/${id}`, { skipErrorHandling: true })
      .then(async ({ data }) => {
        const storage = data.storage;
        commit("setParentItem", storage);
        commit("setParentIsRoot", data.isRoot);
        commit("setParentCanUpload", data.canUpload);
        commit("parentLoading", false);
      })
      .catch((error) => {
        commit("parentLoading", false);
        throw new Error(error);
      });
  },
  async getStorageRelatedEntities({ commit }, { storageId }) {
    return axios
      .post(`${apiUrl}storage/related-entities`, { ids: [storageId] })
      .then(({ data }) => {
        const relatedEntity = data.storages[0];
        commit("setStorageRelatedEntities", relatedEntity);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {});
  },
  async download({ commit }, { ids, queryParams = null }) {
    commit("downloading", true);
    return axios
      .get(`${apiUrl}storage/download`, {
        params: { ids, ...queryParams },
        responseType: "blob",
      })
      .then((res) => {
        commit("downloading", false);
        let fileName = "";
        const contentDisposition = res.headers["content-disposition"];
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, "");
        }
        FileSaver.saveAs(res.data, fileName);
      })
      .catch((error) => {
        commit("downloading", false);
        throw new Error(error);
      });
  },
  async getShareLink({ commit }, id) {
    commit("setShareLink", null);
    commit("saredHasCopied", false);
    commit("shareIsLoading", true);
    return axios
      .get(`${apiUrl}storage/share/${id}`)
      .then(({ data }) => {
        commit("shareIsLoading", false);
        let url = null;
        if (data?.share) {
          const hash = data.share.hash;
          url = `${window.location.origin}/view/storage/${hash}`;
        }
        commit("setShareLink", url);

        return url;
      })
      .catch((error) => {
        commit("setShareLink", null);
        commit("shareIsLoading", false);
        throw new Error(error);
      });
  },
  async createShareLink({ commit }, id) {
    commit("saredHasCopied", false);
    commit("shareIsCreating", true);
    return axios
      .post(`${apiUrl}storage/share/${id}`)
      .then(({ data }) => {
        let url = null;
        if (data?.share) {
          const hash = data.share.hash;
          url = `${window.location.origin}/view/storage/${hash}`;
        }
        commit("setShareLink", url);
        commit("shareIsCreating", false);

        return url;
      })
      .catch((error) => {
        commit("setShareLink", null);
        commit("shareIsCreating", false);
        throw new Error(error);
      });
  },
  async replaceFile({ commit }, { id, formData }) {
    commit("updatingFileVersion", true);
    return axios
      .put(`${apiUrl}storage/file/${id}`, formData)
      .then((res) => {
        commit("updatingFileVersion", false);
      })
      .catch((error) => {
        commit("updatingFileVersion", false);
        throw new Error(error);
      });
  },
};

const mutations = {
  setItem(state, item) {
    state.item = item;
  },
  setParentItem(state, item) {
    state.parent.item = item;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  parentLoading(state, isLoading) {
    state.parent.isLoading = isLoading;
  },
  setParentIsRoot(state, isRoot) {
    state.parent.isRoot = isRoot;
  },
  setParentCanUpload(state, canUpload) {
    state.parent.canUpload = canUpload;
  },
  downloading(state, isDownloading) {
    state.isDownloading = isDownloading;
  },
  updatingFileVersion(state, isUpdatingFileVersion) {
    state.isUpdatingFileVersion = isUpdatingFileVersion;
  },
  setShareLink(state, link) {
    state.share.link = link;
  },
  saredHasCopied(state, hasCopied) {
    state.share.hasCopied = hasCopied;
  },
  shareIsLoading(state, isLoading) {
    state.share.isLoading = isLoading;
  },
  shareIsCreating(state, isCreating) {
    state.share.isCreating = isCreating;
  },
  setStorageRelatedEntities(state, storageRelatedEntities) {
    state.storageRelatedEntities = storageRelatedEntities;
  },
};

export const storageDetails = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
