import { openOauth2Window } from "./integrationSetup/oAuth2SetupService";
import { setupCustomAuth } from "./integrationSetup/customSetupService";

/**
 * Configures an integration based on its authentication type
 * @param {Object} options
 * @param {String} options.integrationName - The name of the integration
 * @param {Function} options.onSuccess - Callback for successful configuration
 * @param {Function} options.showConfigDialog - Function to show config dialog (required for non-OAuth integrations)
 * @returns {Promise} - Promise that resolves when the integration is configured
 */
export const configureIntegration = ({
  integrationName,
  onSuccess = () => {},
  showConfigDialog = null,
}) => {
  const constant = window.$nuxt?.$constant || require("@/helpers/constants");

  // OAuth2 integrations
  if (constant.O_AUTH2_INTEGRATIONS.includes(integrationName)) {
    return new Promise((resolve) => {
      openOauth2Window({ integrationName }, () => {
        onSuccess(integrationName);
        resolve(integrationName);
      });
    });
  }

  // No code, no dialog integrations
  else if (constant.NO_CODE_INTEGRATIONS.includes(integrationName)) {
    return setupCustomAuth({ integrationName })
      .then(() => {
        onSuccess(integrationName);
        return integrationName;
      })
      .catch((err) => {
        // Handle specific errors, like adding partner for EBYGGESOK
        const isAddGriprAsPartner =
          err.message === "Legg til Gripr som integrasjonspartner (samarbeidspartner)";
        if (isAddGriprAsPartner && window.$nuxt) {
          window.$nuxt.$helpers.summonStonlyGuide("0LS1BgLbY5");
        }
        throw err;
      });
  }

  // Other integrations that need dialog
  else {
    if (!showConfigDialog) {
      throw new Error("showConfigDialog function is required for this integration type");
    }
    showConfigDialog(integrationName);
    return Promise.resolve();
  }
};
