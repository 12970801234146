var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',_vm._g(_vm._b({ref:"dataTable",class:`elevation-0 overflow-hidden border-a fix-loader-visibility-on-data-table ${_vm.stickyColsClass} ${_vm.groupHeaderClass}`,attrs:{"hide-default-footer":"","fixed-header":"","headers":_vm._visibleHeaders,"items":_vm.hideItems ? [] : _vm.items,"options":_vm.dataTableOptions,"server-items-length":_vm.serverItemsLength,"loadingText":_vm.$t('common.loading')},on:{"update:options":_vm.onOptionsUpdate,"update:page":_vm.onPageUpdate,"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:`group.header`,fn:function(slotProps){return [_vm._t("group.header",null,null,{
          ...slotProps,
          isGroupIndeterminate: _vm.isGroupIndeterminate({ items: slotProps.items }),
          isGroupSelected: _vm.isItemsSelected(slotProps.items),
          toggleGroup: () => _vm.toggleItems(slotProps.items),
        })]}},(_vm.showSelectAllBanner)?{key:`body.prepend`,fn:function({ headers }){return [_c('AppDataTableSelectAllBanner',{attrs:{"headersLength":headers.length,"itemsLength":_vm.items.length,"totalItems":_vm.serverItemsLength,"isSelectingAll":_vm.dataTableBulkActionMixin_isSelectingAll,"disabled":_vm.$attrs.loading},on:{"select-all":_vm.dataTableBulkActionMixin_selectAllItems}})]}}:null,{key:`header.data-table-select`,fn:function({ props, on }){return [_c('v-simple-checkbox',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple"}],attrs:{"color":"primary"}},'v-simple-checkbox',props,false),on))]}},{key:"footer",fn:function(){return [_c('div',{ref:"dataTableFooter",staticClass:"py-1 border-t p-relative d-flex align-center justify-center"},[_c('AppStatsText',{attrs:{"isStatsLoading":_vm.isStatsLoading,"statsText":_vm.statsText}}),(_vm.pageFromRoute)?_c('v-pagination',{staticClass:"pagination-items-remove-box-shadow",attrs:{"length":_vm.pageCount,"total-visible":"7"},model:{value:(_vm.pageFromRoute),callback:function ($$v) {_vm.pageFromRoute=$$v},expression:"pageFromRoute"}}):_vm._e(),_c('div',{staticClass:"p-absolute",staticStyle:{"right":"0","min-width":"65px"}},[_c('AppMinimalisticSelect',{attrs:{"value":_vm.dataTableOptions.itemsPerPage,"items":_vm.itemsPerPageItems},on:{"change":_vm.onItemsPerPageChange}})],1)],1)]},proxy:true},_vm._l((Object.keys(_vm.$scopedSlots).filter(
        (name) => name !== 'group.header',
      )),function(slotName){return {key:slotName,fn:function(slotProps){return [_vm._t(slotName,null,null,slotProps)]}}})],null,true),model:{value:(_vm.dataTableBulkActionMixin_selectedItems),callback:function ($$v) {_vm.dataTableBulkActionMixin_selectedItems=$$v},expression:"dataTableBulkActionMixin_selectedItems"}},'v-data-table',_vm.$attrs,false),_vm.$listeners)),(_vm.showSelect)?_c('AppBulkActionMenu',{staticStyle:{"bottom":"100px","z-index":"3","transform":"translateX(-50%)","left":"50%","position":"absolute","width":"fit-content"},attrs:{"bulkActionConfigs":_vm.bulkActionConfigs,"selectedItems":_vm.dataTableBulkActionMixin_selectedItems},on:{"clear:click":_vm.dataTableBulkActionMixin_clearSelection}}):_vm._e(),_c('AppEditHeadersDialog2',{attrs:{"headers":_vm.headersWithoutSelect,"defaultHeaders":_vm.defaultHeadersWithoutSelect,"frozenColumnsCount":_vm.dataTableOptions.stickyCols},on:{"submit":_vm.onEditHeadersSubmit},model:{value:(_vm.editHeadersDialog),callback:function ($$v) {_vm.editHeadersDialog=$$v},expression:"editHeadersDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }