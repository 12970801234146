<template>
  <div>
    <AppDefaultDraggable
      v-model="tabs"
      :ghostClass="$constant.DRAG_SETTINGS.GHOST_CLASS_HIDDEN"
      :dragClass="$constant.DRAG_SETTINGS.DRAG_CLASS_BACKGROUND"
      class="custom-tabs-draggable"
      :disabled="isLoading"
      @end="onDragEnd"
    >
      <div
        class="draggable-item"
        v-for="tab in tabs"
        :ref="`resourceViewTabRef-${tab.id}`"
        :key="tab.id"
      >
        <AppResourceViewTab
          :isActive="tab.id === activeTabId"
          @click="handleTabClick(tab, $event)"
          @contextmenu.prevent="openContextMenu($event.currentTarget, tab)"
        >
          <v-icon
            v-if="tab.isPrivate"
            small
            class="mr-3"
          >
            {{ $icons.SOLID.ACTION.LOCK }}
          </v-icon>
          <AppTextTruncate
            :text="tab.name"
            maxWidth="200"
            textClass="custom-tab-text"
          />
        </AppResourceViewTab>
      </div>
    </AppDefaultDraggable>

    <!-- Slider element -->
    <div
      class="tab-slider"
      ref="slider"
      :class="{ 'no-transition': !initialPositionSet }"
    ></div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AppResourceViewTabDraggableList",
  props: {
    value: {
      type: Array,
      required: true,
    },
    activeTabId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      initialPositionSet: false,
    };
  },
  computed: {
    tabs: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    ...mapState("resourceViews", {
      isLoading: (state) => state.isLoading,
    }),
  },
  watch: {
    activeTabId(newVal, oldVal) {
      if (oldVal !== null) {
        // Only enable transitions after the first tab change
        this.initialPositionSet = true;
      }
      this.$nextTick(() => {
        this.moveSlider();
      });
    },
    tabs: {
      handler() {
        setTimeout(() => {
          this.moveSlider();
        }, 5);
      },
    },
  },
  mounted() {
    //set slider intially
    this.moveSlider();
    this.$nextTick(() => {
      setTimeout(() => {
        this.moveSlider();
      }, 5);
    });
  },
  methods: {
    moveSlider() {
      if (!this.$refs.slider) return;

      if (!this.activeTabId || this.tabs.length === 0) {
        // Hide slider if no active tab
        this.$refs.slider.style.opacity = "0";
        return;
      }

      const activeRef = this.$refs[`resourceViewTabRef-${this.activeTabId}`];
      if (!activeRef || !activeRef[0]) return;

      const tabEl = activeRef[0].querySelector(".custom-tab");
      if (!tabEl) return;

      const slider = this.$refs.slider;
      const tabRect = tabEl.getBoundingClientRect();
      const containerRect = this.$el.getBoundingClientRect();

      slider.style.width = `${tabRect.width}px`;
      slider.style.transform = `translateX(${tabRect.left - containerRect.left}px)`;
      slider.style.opacity = "1";
    },
    handleTabClick(tab, event) {
      if (tab.id === this.activeTabId) {
        this.$root.$emit("refresh-view-data");
      } else {
        this.$emit("tab-click", tab.id);
      }
    },
    openContextMenu(element, tab) {
      this.$emit("context-menu", { element, resourceView: tab });
    },
    onDragEnd(event) {
      this.initialPositionSet = true;
      if (event.oldIndex !== event.newIndex) {
        this.$emit("drag-end", {
          oldIndex: event.oldIndex,
          newIndex: event.newIndex,
        });
      }
    },
  },
};
</script>

<style scoped>
.custom-tabs-draggable {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  flex: 0 1 auto;
}

.custom-tab-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.tab-slider {
  position: absolute;
  bottom: -2px;
  height: 2px;
  background-color: var(--v-primary-base, #1976d2);
  transition: transform 0.3s ease, width 0.3s ease, opacity 0.3s ease;
}

.tab-slider.no-transition {
  transition: none !important;
}
</style>
