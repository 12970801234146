import { RESOURCE_ROUTE_PERMISSIONS, ROUTE_NAMES } from "@/helpers/routeNames";
import multiguard from "../multiguard";
import { authGuard, userGuard } from "@/auth";
import { HELP_CATEGORIES } from "@/helpers/constants/help";

const { ROOT, MENU } = ROUTE_NAMES.TASK_VIEW;

export const taskViewRoutes = [
  {
    path: "/task-view/:taskId",
    name: ROOT,
    meta: {
      permission: RESOURCE_ROUTE_PERMISSIONS.TASK,
      helpCategory: HELP_CATEGORIES.TASK,
    },
    beforeEnter: multiguard([authGuard, userGuard]),
    component: () =>
      import(/* webpackChunkName: "taskView" */ "../../pages/TaskManagement/TaskView"),
    children: [
      {
        path: "history",
        name: MENU.HISTORY,
        meta: {
          permission: RESOURCE_ROUTE_PERMISSIONS.TASK,
        },
        component: () =>
          import(
            /* webpackChunkName: "taskView" */ "../../pages/TaskManagement/MenuPages/HistoryPage"
          ),
      },
      {
        path: "storage/:folderId",
        name: MENU.STORAGE,
        meta: {
          permission: RESOURCE_ROUTE_PERMISSIONS.TASK,
        },
        component: () =>
          import(
            /* webpackChunkName: "taskView" */ "../../pages/TaskManagement/TaskView/MenuPages/StoragePage"
          ),
      },
      {
        path: "forms",
        name: MENU.FORMS,
        meta: {
          permission: RESOURCE_ROUTE_PERMISSIONS.TASK.FORM,
        },
        component: () =>
          import(
            /* webpackChunkName: "taskView" */ "../../pages/TaskManagement/MenuPages/FormPage"
          ),
      },
      {
        path: "product-usage",
        name: MENU.PRODUCT_USAGE,
        meta: {
          permission: RESOURCE_ROUTE_PERMISSIONS.TASK,
        },
        component: () =>
          import(
            /* webpackChunkName: "taskView" */ "../../pages/TaskManagement/MenuPages/ProductUsagePage"
          ),
      },
      {
        path: "to-do-list",
        name: MENU.TODO_LIST,
        meta: {
          permission: RESOURCE_ROUTE_PERMISSIONS.TASK,
        },
        component: () =>
          import(
            /* webpackChunkName: "taskView" */ "../../pages/TaskManagement/MenuPages/ToDoList"
          ),
      },
      {
        path: "comments",
        name: MENU.COMMENTS,
        meta: {
          permission: RESOURCE_ROUTE_PERMISSIONS.TASK.COMMENT,
        },
        component: () =>
          import(
            /* webpackChunkName: "taskView" */ "../../pages/TaskManagement/MenuPages/CommentsPage"
          ),
      },
    ],
  },
];
