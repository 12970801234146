<template>
  <div>
    <v-divider class="primary-border" />
    <v-menu
      offset-x
      transition="slide-y-transition"
    >
      <template v-slot:activator="{ on }">
        <div
          v-on="on"
          class="pa-4 d-flex cursor-pointer"
          :class="isSupportingDomain ? 'cmsAdmin' : ''"
        >
          <AppDrawerMenu
            :title="user.name"
            :subTitle="user.email"
            :avatarItem="user"
          />
        </div>
      </template>

      <v-list>
        <v-list-item
          v-if="canViewIntegrationPage"
          :to="{ name: $routeNames.SETTINGS.INTEGRATIONS }"
          :replace="isUnderSettings"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("drawer.userMenu.integrations") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="canViewCms"
          :to="{ name: $routeNames.CMS.ROOT }"
        >
          <v-list-item-content>
            <v-list-item-title>CMS</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider
          class="my-2"
          v-if="canViewCms || canViewIntegrationPage"
        />
        <v-list-item @click="logout">
          <v-list-item-content>
            <v-list-item-title>{{ $t("common.logout") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { permissionHelpers } from "@/helpers/util";
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("auth", {
      user: "user",
      isSupportingDomain: "isSupportingDomain",
    }),
    canViewCms() {
      return permissionHelpers.canViewRoute(this.$routeNames.CMS.ROOT);
    },
    canViewIntegrationPage() {
      return permissionHelpers.canViewRoute(this.$routeNames.SETTINGS.INTEGRATIONS);
    },
    isUnderSettings() {
      return this.isUnderRoute(this.$routeNames.SETTINGS.ROOT);
    },
  },
  methods: {
    isUnderRoute(routeName) {
      return this.$route.matched.some((m) => m.name === routeName);
    },
    logout() {
      this.$auth.logout();
    },
  },
};
</script>
