<template>
  <div class="information_drawer">
    <v-toolbar
      class="border-b"
      dense
      flat
      fixed
      height="68"
    >
      <v-toolbar-title class="font-weight-bold">
        {{ $t("common.overview") }}
      </v-toolbar-title>
      <v-spacer />
      <AppToggleMenuBtn>
        <AppMenuItem
          :disabled="!canEditProject"
          :text="$t('projectView.info.useTemplate')"
          :icon="$icons.REGULAR.ACTION.ADD"
          @click="templatePickerDialog = true"
        />
        <AppMenuCopyUrlBtn
          :route="{
            name: this.$routeNames.PROJECT.VIEW,
            params: { projectId: this.project?.id },
          }"
        />
        <AppMenuDeleteBtn
          :disabled="!canDeleteProject"
          @click="deleteProjectDialog = true"
          :text="$t('projectView.info.deleteProject')"
        />
      </AppToggleMenuBtn>
      <v-btn
        icon
        @click="close"
      >
        <v-icon>{{ $icons.LIGHT.ACTION.CLOSE }}</v-icon>
      </v-btn>
    </v-toolbar>
    <AppCenterLoader
      v-model="isLoading"
      :title="$t('common.loading')"
      :height="'calc(100% - 68px)'"
    />
    <div
      class="overflow-y-auto secondary-height"
      v-if="project"
    >
      <div class="pa-5 pb-0">
        <div class="p-relative">
          <div class="d-flex">
            <div class="label--small">
              {{ $t("common.address") | capitalize }}
            </div>
            <v-spacer />
            <AppAddressDetails
              v-if="addressItems.length !== 0"
              :addressItems="addressItems"
            />
          </div>
          <AppAddressCombobox
            :disabled="!canEditProject"
            ref="address"
            :value="project.address"
            @change="onAddressChange"
            class="pb-5"
          />
        </div>
        <AppFieldMenuDatePicker
          :disabled="!canEditProject"
          class="pb-5"
          hide-details
          :label="$t('projectView.info.startDate')"
          :date="project.startDate"
          :max="project.endDate"
          @date:change="onStartDateChange"
        />
        <AppFieldMenuDatePicker
          :disabled="!canEditProject"
          class="pb-5"
          hide-details
          :label="$t('projectView.info.estimatedEndDate')"
          :date="project.endDate"
          :min="project.startDate"
          :defaultPickerDate="project.startDate"
          @date:change="onEndDateChange"
        />
        <div class="label--small">
          {{ $t("projectView.info.projectManager") | capitalize }}
        </div>
        <AppClientGroupedUserAutocomplete
          :disabled="!canEditProject"
          class="mb-5"
          user-avatar
          hide-details
          :value="project.ownerId"
          :items="assignableOwners"
          :loading="isAssignableOwnersLoading"
          :labelPaths="['workTitleLabel', 'contact.relation.label']"
          @change="onProjectManagerChange"
        />
        <div class="label--small">
          {{ $t("projectView.info.customer") | capitalize }}
        </div>

        <AppClientAutocomplete
          :disabled="!canEditProject"
          class="pb-5"
          hide-details
          :value="project.clientId"
          :isReadyToInitialFetch="!!project"
          :type="$constant.CUSTOMER"
          :queryParams="{
            isActive: true,
            alwaysIncludeIds: [project?.clientId],
          }"
          @change="onClientChange"
          @created="onClientCreated"
        />

        <div class="label--small">
          {{ $t("projectView.info.projectAccess") | capitalize }}
        </div>
        <AppDefaultSelect
          :disabled="!canEditProject"
          :value="project.permissionLevel"
          :items="permissionLevels"
          :clearable="false"
          class="pb-5"
          item-text="name"
          item-value="value"
          hide-details
          @change="onPermissionLevelChange"
        />
        <div class="d-flex align-end">
          <div class="label--small">
            {{ $t("projectView.info.projectStatus") | capitalize }}
          </div>
          <v-spacer />
          <AppSettingsBtn
            v-if="canEditStatuses"
            @click.stop="onEditStatusesClick"
            :tooltip="statusMixins_tooltip('project')"
          />
        </div>
        <AppChangeCustomStatusAutocomplete
          :disabled="!canEditProject"
          class="pb-5"
          :value="project.statusId"
          :loading="project?.isUpdatingStatus"
          model="project"
          hide-details
          @change="updateProject({ statusId: $event })"
        />
        <AppProjectAutocomplete
          v-model="localParentId"
          :disabled="!canEditProject"
          :idsToExclude="[project?.id]"
          :isReadyToInitialFetch="!!project"
          hide-details
          type="mainProject"
          @change="
            {
              ({ project }) => (localParentId = project.id);
            }
          "
        />
        <div class="label--small">
          {{ $t("projectView.info.projectId") | capitalize }}
        </div>
        <div class="pb-8">{{ project.number }}</div>
      </div>
      <div
        v-if="canViewIntegrations && !isBoligmappaEnabled"
        class="mb-5"
      >
        <AppIntegrationActivator
          :integrationName="$constant.BOLIGMAPPA"
          :title="'Boligmappa'"
          :subtitle="$t('projectView.info.boligmappa.notActivatedSubTitle')"
          :buttonText="$t('projectView.info.boligmappa.activateButton')"
          :planCode="$constant.PLAN_CODE.GRIPR_PRO"
          @success="projectRefresh"
          @show-config-dialog="onShowConfigDialog"
        />
      </div>
      <div v-else-if="canConnectToBoligmappa && isBoligmappaEnabled">
        <v-toolbar
          class="border-b border-t"
          dense
          flat
          fixed
          height="68"
        >
          <v-toolbar-title class="font-weight-bold d-flex align-center">
            <img
              :src="getIntegrationBrandIconUrl($constant.BOLIGMAPPA)"
              class="mr-3"
              height="24"
              width="24"
              alt="Boligmappa"
            />
            Boligmappa
          </v-toolbar-title>
          <v-spacer />
          <div class="mr-1">
            <AppToggleMenuBtn v-if="project.boligmappaNumber">
              <AppMenuItem
                :disabled="!canConnectToBoligmappa"
                :text="$t('projectView.info.boligmappa.changeAddress')"
                :icon="$icons.REGULAR.COMMON.LOCATION_DOT"
                @click="boligmappaPropertyWizardDialog = true"
              />
              <AppMenuItem
                :disabled="!canConnectToBoligmappa"
                :text="$t('projectView.info.boligmappa.disconnectAddress')"
                color="error"
                :icon="$icons.REGULAR.COMMON.LOCATION_DOT_SLASH"
                @click="decoupleBoligmappaAddress"
              />
            </AppToggleMenuBtn>
          </div>
        </v-toolbar>
        <div
          v-if="!project.boligmappaNumber"
          class="pa-5"
        >
          <v-btn
            block
            depressed
            color="primary"
            @click="boligmappaPropertyWizardDialog = true"
          >
            {{ $t("projectView.info.boligmappa.ConnectToAddress") }}
          </v-btn>
        </div>
        <div
          class="pa-5"
          v-else
        >
          <AppBoligmappaDetails
            :boligmappaNumber="project.boligmappaNumber"
            :boligmappaAddress="project.boligmappaAddress"
          />
        </div>
      </div>
      <AppBoligmappaPropertyWizardDialog
        v-model="boligmappaPropertyWizardDialog"
        @completed="onBoligmappaWizardCompletion"
        :project="project"
      />
      <AppProjectTemplatePickerDialog
        v-if="canEditProject"
        v-model="templatePickerDialog"
        :type="$constant.PROJECT"
        @select="onTemplateSelect"
      />
      <AppEditCustomStatusDialog
        v-model="editStatusDialog"
        model="project"
      />
      <AppDeleteConfirmationDialog
        v-if="canDeleteProject"
        v-model="deleteProjectDialog"
        :title="$tc('project.deleteProject.title', 1)"
        :validator="$t('common.delete').toLowerCase()"
        :validatorText="
          $t('project.deleteProject.validatorText', {
            delete: $t('common.delete').toLowerCase(),
          })
        "
        @delete="deleteProject"
      />

      <template v-if="!$isProduction">
        <div v-if="canViewIntegrations && !isIn4moEnabled">
          <AppIntegrationActivator
            :integrationName="$constant.IN4MO"
            :title="'In4mo Insurance'"
            :subtitle="$t('projectView.info.in4mo.notActivatedSubTitle')"
            :buttonText="$t('projectView.info.in4mo.activateButton')"
            :planCode="$constant.PLAN_CODE.GRIPR_PRO"
            @success="projectRefresh"
            @show-config-dialog="onShowConfigDialog"
          />
        </div>
        <div v-else-if="canConnectToIn4mo && isIn4moEnabled">
          <v-toolbar
            class="border-b border-t"
            dense
            flat
            fixed
            height="68"
          >
            <v-toolbar-title class="font-weight-bold d-flex align-center">
              <img
                :src="getIntegrationBrandIconUrl($constant.IN4MO)"
                class="mr-3"
                height="24"
                width="24"
                alt="In4mo Insurance"
              />
              In4mo Insurance
            </v-toolbar-title>
            <v-spacer />
            <div class="mr-1">
              <AppToggleMenuBtn v-if="hasConnectedIn4moCase">
                <AppMenuItem
                  :disabled="!canConnectToIn4mo"
                  :text="$t('projectView.info.in4mo.disconnect')"
                  color="error"
                  :icon="$icons.REGULAR.COMMON.UNLINK"
                  @click="disconnectIn4moCase"
                />
              </AppToggleMenuBtn>
            </div>
          </v-toolbar>

          <div
            v-if="!hasConnectedIn4moCase"
            class="pa-5"
          >
            <div class="mb-5 text-center">
              {{ $t("projectView.info.in4mo.noConnectedCase") }}
            </div>
            <v-btn
              block
              depressed
              color="primary"
              @click="openIn4moConnectDialog"
            >
              {{ $t("projectView.info.in4mo.connectToCase") }}
            </v-btn>
          </div>

          <div
            class="pa-5"
            v-else-if="insuranceCase"
          >
            <AppInformoInsuranceCaseDetails :insuranceCase="insuranceCase" />
          </div>
        </div>
      </template>
      <AppInformoConnectCaseDialog
        v-model="in4moConnectDialog"
        :projectId="project.id"
        @connected="onIn4moCaseConnected"
      />
      <IntegrationInstanceConfigDialog
        v-model="integrationConfigDialog.active"
        :integrationName="integrationConfigDialog.integrationName"
        @dialog:change="onIntegrationConfigDialogChange"
        @complete="onIntegrationConfigComplete"
      />
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
import { mapGetters, mapState } from "vuex";
import { getSearchUsers } from "@/services/search/users";
import { decoupleBoligmappaAddress } from "@/services/project/projectService";
import { configureIntegration } from "@/services/integration/configureIntegrationService";
import { planRestrictionsMixins, routerMixins, permissionMixins } from "@/helpers/mixins";
import { statusMixins } from "@/helpers/mixins";
import IntegrationInstanceConfigDialog from "@/pages/SettingsPage/AccountPage/IntegrationsPage/dialogs/config/IntegrationInstanceConfigDialog.vue";
import { getIntegrationBrandIconUrl } from "@/helpers/constants/integration";

export default {
  mixins: [planRestrictionsMixins, routerMixins, statusMixins, permissionMixins],
  components: {
    IntegrationInstanceConfigDialog,
  },
  props: {
    project: Object,
  },
  data() {
    return {
      permissionLevel: "",
      isAssignableOwnersLoading: false,
      boligmappaPropertyWizardDialog: false,
      templatePickerDialog: false,
      deleteProjectDialog: false,
      editStatusDialog: false,
      assignableOwners: [],
      startDateMenu: false,
      permissionLevels: [
        { name: this.$t("common.permissionLevel.public"), value: "PUBLIC" },
        { name: this.$t("common.permissionLevel.restricted"), value: "RESTRICTED" },
        { name: this.$t("common.permissionLevel.private"), value: "PRIVATE" },
      ],
      activeLevels: [
        { name: this.$t("projectView.info.active"), value: true },
        { name: this.$t("projectView.info.inactive"), value: false },
      ],
      projectAddressFields: [
        "address",
        "bruksnummer",
        "gardsnummer",
        "festenummer",
        "latitude",
        "longitude",
      ],
      in4moCaseId: "",
      selectedCaseId: null,
      in4moConnectDialog: false,
      integrationConfigDialog: {
        active: false,
        integrationName: null,
      },
    };
  },
  computed: {
    ...mapGetters("activeIntegrations", {
      isIntegrationEnabled: "isIntegrationEnabled",
    }),
    canEditStatuses() {
      return this.permissionMixins_status.edit.can;
    },
    canEditProject() {
      return this.project?.permissions?.edit.can;
    },
    canDeleteProject() {
      return this.project?.permissions?.delete.can;
    },
    canConnectToBoligmappa() {
      return this.project?.permissions?.boligmappa?.connect?.can;
    },
    canViewIntegrations() {
      return this.permissionMixins_integration.view.can;
    },
    isBoligmappaEnabled() {
      return this.isIntegrationEnabled(this.$constant.BOLIGMAPPA);
    },
    isIn4moEnabled() {
      return this.isIntegrationEnabled(this.$constant.IN4MO);
    },
    isLoading() {
      return !this.project;
    },
    addressItems() {
      return this.projectAddressFields
        .filter((prop) => this.project[prop])
        .map((prop) => ({ key: prop, value: this.project[prop] }));
    },
    localParentId: {
      get() {
        return this.project?.parentId;
      },
      set(parentId) {
        this.updateProject({ parentId });
      },
    },
    ...mapState("in4mo", {
      availableCases: (state) => state.availableCases,
      insuranceCase: (state) => state.insuranceCase,
      isIn4moLoadingCases: (state) => state.isLoadingCases,
      isIn4moConnecting: (state) => state.isConnecting,
      isIn4moDisconnecting: (state) => state.isDisconnecting,
    }),
    canConnectToIn4mo() {
      return this.project?.permissions?.in4mo?.connect?.can;
    },
    hasConnectedIn4moCase() {
      return !!this.project?.in4moInsuranceCaseId;
    },
  },
  methods: {
    getIntegrationBrandIconUrl,
    async deleteProject() {
      await this.$store.dispatch("projects/deleteProject", this.project.id).then(() => {
        this.routerMixins_goBack({
          fallbackRoute: { name: this.$routeNames.PROJECT.ROOT },
        });
      });
    },
    onClientCreated({ client }) {
      this.onClientChange(client.id);
    },
    getAssignableOwners() {
      this.isAssignableOwnersLoading = true;
      const params = {
        projectId: this.project?.id,
        isAssignableAsProjectLeader: true,
        alwaysIncludeIds: [this.project?.ownerId],
      };
      getSearchUsers({ params })
        .then((users) => {
          this.assignableOwners = users;
        })
        .finally(() => (this.isAssignableOwnersLoading = false));
    },
    onAddressChange(location) {
      if (location !== null && typeof location === "object") {
        const address = location.text;
        const gardsnummer = location.gardsnummer?.toString();
        const festenummer = location.festenummer?.toString();
        const bruksnummer = location.bruksnummer?.toString();
        const latitude = location.lat?.toString();
        const longitude = location.lon?.toString();

        const body = {
          address,
          gardsnummer,
          festenummer,
          bruksnummer,
          latitude,
          longitude,
        };

        this.updateProject(body);
      } else {
        const body = {
          address: location,
          gardsnummer: null,
          festenummer: null,
          bruksnummer: null,
          latitude: null,
          longitude: null,
        };
        this.updateProject(body);
      }
    },
    onClientChange(clientId) {
      this.updateProject({ clientId });
    },
    onProjectManagerChange(ownerId) {
      this.updateProject({ ownerId });
    },
    onPermissionLevelChange(permissionLevel) {
      this.updateProject({ permissionLevel });
    },
    onStartDateChange(startDate) {
      this.updateProject({ startDate });
    },
    onEndDateChange(endDate) {
      this.updateProject({ endDate });
    },
    updateProject(body, afterUpdate) {
      this.$emit("project:update", { body, afterUpdate });
    },
    projectRefresh() {
      this.$emit("project:refresh");
    },
    onBoligmappaWizardCompletion(e) {
      this.projectRefresh();
    },
    onEditStatusesClick() {
      this.editStatusDialog = true;
    },
    async decoupleBoligmappaAddress() {
      await decoupleBoligmappaAddress({ projectId: this.project?.id });
      this.projectRefresh();
    },
    onTemplateSelect({ templateId, afterTemplateSelect }) {
      this.updateProject({ templateId }, afterTemplateSelect);
    },
    close() {
      this.$router.replace({ name: this.$routeNames.PROJECT.INFO });
    },
    async loadIn4moData() {
      if (this.project?.id && this.isIn4moEnabled) {
        try {
          if (this.hasConnectedIn4moCase) {
            await this.$store.dispatch("in4mo/getProjectInsuranceCase", this.project.id);
          }
        } catch (error) {
          console.error("Error loading In4mo data:", error);
        }
      }
    },
    openIn4moConnectDialog() {
      this.in4moConnectDialog = true;
    },
    onIn4moCaseConnected() {
      this.projectRefresh();
    },
    async disconnectIn4moCase() {
      try {
        await this.$store.dispatch("in4mo/disconnectInsuranceCase", {
          projectId: this.project.id,
        });
        this.projectRefresh();
      } catch (error) {
        this.$toast.error(this.$t("projectView.info.in4mo.disconnectError"));
      }
    },
    onShowConfigDialog(integrationName) {
      this.integrationConfigDialog.integrationName = integrationName;
      this.integrationConfigDialog.active = true;
    },
    onIntegrationConfigDialogChange(active) {
      if (!active) this.integrationConfigDialog.integrationName = null;
    },
    onIntegrationConfigComplete() {
      const integrationName = this.integrationConfigDialog.integrationName;
      this.projectRefresh();
      this.$store.dispatch("activeIntegrations/getActiveIntegrations");
    },
  },
  mounted() {
    this.getAssignableOwners();
    this.loadIn4moData();
  },
  watch: {
    project(newVal) {
      if (newVal?.id) {
        this.loadIn4moData();
      }
    },
    isIn4moEnabled(newVal) {
      if (newVal && this.project?.id) {
        this.loadIn4moData();
      }
    },
  },
};
</script>
