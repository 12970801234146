export const HELP_CATEGORIES = {
  DEFAULT: "default",
  HOME: "home",
  DASHBOARD: "dashboard",
  TASK: "task",
  FORM: "form",
  TIME_REGISTRATION: "time-registration",
  PROJECT: "project",
  STORAGE: "storage",
  CLIENT: "client",
  CONTACT: "contact",
  TEMPLATES: "templates",
  SETTINGS: "settings",
  PRODUCT: "product",
  INTEGRATION: "integration",
};

const SCRIBEHOW_BASE_URL = "https://scribehow.com/page-embed";

const HELP_CONFIG = {
  [HELP_CATEGORIES.DEFAULT]: {
    id: null,
    routeKey: null,
  },
  [HELP_CATEGORIES.HOME]: {
    id: "Kom_i_gang__6q9S0L7jRl2-Xu_mm_wUGA",
    routeKey: "drawer.route.home",
  },
  [HELP_CATEGORIES.DASHBOARD]: {
    id: "Dashboard__KCXssQ6HTA-I9uuc77SwVw",
    routeKey: "drawer.route.dashboard",
  },
  [HELP_CATEGORIES.TASK]: {
    id: "Oppgaver__jI_yzDRqRCq2eLxa3YhALA",
    routeKey: "drawer.route.tasks",
  },
  [HELP_CATEGORIES.FORM]: {
    id: "Untitled__tpLCwoHJRxyr5DiWOG1htA",
    routeKey: "drawer.route.forms",
  },
  [HELP_CATEGORIES.TIME_REGISTRATION]: {
    id: "Timer__Yo9ymcIQQKieaGRlvl9qqQ",
    routeKey: "drawer.route.timeRegistration",
  },
  [HELP_CATEGORIES.PROJECT]: {
    id: "Prosjekt__5z31k54NQRuZ9AtcGrLGCg",
    routeKey: "drawer.route.projects",
  },
  [HELP_CATEGORIES.STORAGE]: {
    id: "Dokumentsenter__d2Y0EWqFSZqv74P_fWp3sg",
    routeKey: "drawer.route.files",
  },
  [HELP_CATEGORIES.CLIENT]: {
    id: "Kunder_og_leverandorer__vKPc1YWkSa2vFIOHRDBO3A",
    routeKey: "drawer.route.clients",
  },
  [HELP_CATEGORIES.CONTACT]: {
    id: "Kontaktpersoner__6Yhe7YgwRqeGqeJ6rZ0IXw",
    routeKey: "drawer.route.contacts",
  },
  [HELP_CATEGORIES.TEMPLATES]: {
    id: "Maler__PlqX0pAQSOOF7vok-jfdzA",
    routeKey: "drawer.route.templates",
  },
  [HELP_CATEGORIES.SETTINGS]: {
    id: "Innstillinger__rSNTiJ9dQsSELTOhRhBGpw",
    routeKey: "drawer.route.settings",
  },
  [HELP_CATEGORIES.PRODUCT]: {
    id: "Produkter__aIpP6J8iT1enbQ38w1Q_cg",
    routeKey: "drawer.route.products",
  },
  [HELP_CATEGORIES.INTEGRATION]: {
    id: "Integrasjoner__Ouz_l2HTTJutzI77Y3MzQQ",
    routeKey: "drawer.route.integrations",
  },
};

export const getHelpConfig = (category) => {
  const config = HELP_CONFIG[category] || HELP_CONFIG[HELP_CATEGORIES.DEFAULT];

  return {
    url: config.id ? `${SCRIBEHOW_BASE_URL}/${config.id}` : null,
    routeKey: config.routeKey,
    category,
  };
};
