<template>
  <div class="information_drawer pa-0">
    <v-toolbar
      class="border-b"
      dense
      flat
      fixed
      height="68"
    >
      <v-toolbar-title class="font-weight-bold">
        {{ $t("common.eventLog") }}
      </v-toolbar-title>
    </v-toolbar>
    <AppCenterLoader
      v-model="isLoading"
      :title="$t('common.loading')"
      :height="'calc(100% - 68px)'"
    />
    <template v-if="!isLoading">
      <AppLogList
        class="pt-5"
        :items="items"
        :isLoadingMore="isLoadingMore"
        :canLoadMore="canLoadMore"
        @click:load-more="onLoadMore"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    templateId: String,
  },
  computed: {
    ...mapState("cmsTemplateHistory", {
      items: (state) => state.items,
      isLoading: (state) => state.isLoading,
      isLoadingMore: (state) => state.isLoadingMore,
      canLoadMore: (state) => state.canLoadMore,
      offset: (state) => state.offset,
    }),
  },
  methods: {
    getHistory(id, offset) {
      this.$store.dispatch("cmsTemplateHistory/getItems", { id, offset });
    },
    onLoadMore() {
      this.getHistory(this.templateId, this.offset);
    },
  },
  mounted() {
    this.getHistory(this.templateId, 0);
  },
};
</script>
