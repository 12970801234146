<template>
  <v-app>
    <AppDrawer />
    <v-main>
      <router-view />
    </v-main>

    <v-overlay
      :value="isLoadingPage"
      :opacity="0.8"
      color="background"
    >
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </v-overlay>

    <!-- should make these render conditionally since they are triggered so rarely and dont have to be in the background at all times -->
    <AppSnackbar />
    <AppUpgradePlanDialog />
    <AppDownloadApp v-model="dialog.downloadApp.isActive" />
    <AppTrialExpiredDialog
      v-if="showTrialExpiredDialog"
      :dialog="showTrialExpiredDialog"
    />
    <AppHelpPanel />
    <AppDebugOverlay v-if="isPossibleToShowDebugOverlay" />
  </v-app>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import { isMobile } from "./helpers/util";
import { periodicUpdateService } from "@/services/periodic/periodicUpdateService";
import { setupShortcuts } from "@/helpers/shortcuts";
import { KEYBOARD_SHORTCUTS } from "@/helpers/constants/shortcuts";

export default {
  data() {
    return {
      hovering: false,
      dialog: {
        downloadApp: {
          hasShown: false,
          isActive: false,
        },
      },
      isMobile: isMobile(),
    };
  },
  name: "App",
  computed: {
    ...mapState("auth", {
      me: (state) => state.me,
      isLoadingUserInitially: (state) => state.isLoadingUserInitially,
    }),
    ...mapGetters("auth", {
      isSupportingDomain: "isSupportingDomain",
      currentDomain: "currentDomain",
      user: "user",
      domains: "domains",
      subscriptionPlan: "subscriptionPlan",
    }),
    isPossibleToShowDebugOverlay() {
      if (!this.$isProduction) {
        return true;
      }

      return this.user?.email?.endsWith("@gripr.no");
    },
    showTrialExpiredDialog() {
      return this.subscriptionPlan?.mustMakePlanChoice;
    },
    isOnRoot() {
      return this.$route.fullPath === process.env.BASE_URL;
    },
    isLoadingPage() {
      const isLoading = this.$auth.loading || this.isLoadingUserInitially;
      return isLoading || this.isOnRoot;
    },
    triggerTrialExpiredDialog() {
      if (!this.me) return false;
      return (
        this.subscriptionPlan?.trial.remainingDays < 0 &&
        this.subscriptionPlan.plan === this.$constant.PLAN_CODE.GRIPR_TRIAL
      );
    },
    shouldShowDownloadAppDialog() {
      const EXCLUDED_ROUTES = [
        this.$routeNames.GET_STARTED.NEW_WORKSPACE,
        this.$routeNames.GET_STARTED.LANDING,
        this.$routeNames.CHILD_DOMAIN_INVITATION.ROOT,
      ];

      const isOnRouteThatShouldNotShowDownloadAppDialog =
        EXCLUDED_ROUTES.includes(this.$route.name) || this.isOnRoot;

      const hasShown = this.dialog.downloadApp.hasShown;
      const shouldShow =
        this.isMobile &&
        !!this.user?.id &&
        !hasShown &&
        !isOnRouteThatShouldNotShowDownloadAppDialog;

      return shouldShow;
    },
  },
  watch: {
    me: {
      handler(me) {
        this.onUserChange(me);
      },
      immediate: true,
      deep: true,
    },
    shouldShowDownloadAppDialog: {
      handler(shouldShow) {
        if (shouldShow) {
          this.showDownloadAppDialog();
        }
      },
      immediate: true,
    },
  },
  methods: {
    onUserChange(me) {
      if (me) {
        this.getActiveIntegrations();
        this.setLanguage();
        this.setUpPeriodicUserFetching();
        this.configureRollbar();
      }
    },
    getActiveIntegrations() {
      if (this.currentDomain?.id && this.domains?.length > 0) {
        this.$store.dispatch("activeIntegrations/getActiveIntegrations");
      }
    },
    showDownloadAppDialog() {
      window.location.href = "gripr://";
      this.dialog.downloadApp.isActive = true;
      this.dialog.downloadApp.hasShown = true;
    },
    setUpPeriodicUserFetching() {
      periodicUpdateService.startInterval(
        "userUpdate",
        () => {
          this.$store.dispatch("auth/getMe");
        },
        1 * 60 * 1000, // 1 minute
      );
    },
    setLanguage() {
      const language = this?.user?.language || "nb";

      this.$i18n.locale = language;

      moment.locale(language);
      this.$vuetify.lang.current = language;
    },
    configureRollbar() {
      if (this.$rollbar) {
        this.$rollbar.configure({
          payload: {
            person: {
              id: this.user?.id,
              email: this.user?.email,
              name: this.user?.name,
              domainId: this.currentDomain?.id,
            },
          },
        });
      }
    },
  },
  mounted() {
    this.$i18n.locale = "nb";
    this.cleanupShortcuts = setupShortcuts({
      shortcutConfig: KEYBOARD_SHORTCUTS.help,
    });
  },
  beforeDestroy() {
    periodicUpdateService.clearInterval("userUpdate");
    if (this.cleanupShortcuts) {
      this.cleanupShortcuts();
    }
  },
};
</script>
