import axios from "axios";
const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;
import store from "../../..";
import i18n from "../../../../plugins/i18n";
import { PLAN_CODE, PRODUCT_CODE } from "../../../../helpers/constants";

const state = () => ({
  isLoading: false,
  billingDetails: null,
  isChangingPlan: false,
  isAdjustingSeats: false,
  isUpdatingExtraStorage: false,
  isUpdatingIntegrations: false,
  isUpdatingESignPurchase: false,
});

const getters = {
  companyName: (state) => state.billingDetails?.name || "",
  invoiceEmail: (state) => state.billingDetails?.invoiceEmail || "",
  invoicesDueIn: (state) => state.billingDetails?.invoicesDueIn || 0,
  nextRenewalDate: (state) => state.billingDetails?.nextRenewalDate || "",
  invoiceMethod: (state) => state.billingDetails?.invoiceMethod || "",
  activePlanCode: (state) => state.billingDetails?.activePlanCode || "",
  nextInvoiceTotalAmount: (state) => state.billingDetails?.nextInvoiceTotalAmount || 0,
  discountPercentage: (state) => state.billingDetails?.discountPercentage || 0,
  hasDiscount: (state, getters) => getters.discountPercentage > 0,
  intervalType: (state) => state.billingDetails?.intervalType || "",
  currency: (state) => state.billingDetails?.currency || "NOK",
  signUpDate: (state) => state.billingDetails?.signUpDate || "",
  usedSeats: (state) => state.billingDetails?.usedSeats || 0,

  plans: (state) => state.billingDetails?.plans || [],
  subscriptions: (state) => state.billingDetails?.subscriptions || [],
  products: (state) => state.billingDetails?.products || [],

  planByCode: (state, getters) => (code) =>
    getters.plans.find((plan) => plan.code === code),
  subscriptionByCode: (state, getters) => (code) =>
    getters.subscriptions.find((subscription) => subscription.plan.code === code),
  activePlanLimits: (state, getters) => getters.planByCode(getters.activePlanCode).limits,
  freePlan: (state, getters) => getters.planByCode(PLAN_CODE.GRIPR_FREE) || null,
  proPlan: (state, getters) => getters.planByCode(PLAN_CODE.GRIPR_PRO) || null,
  activePlanSubscription: (state, getters) =>
    getters.subscriptionByCode(getters.activePlanCode) || null,
  platformFeeSubscription: (state, getters) =>
    getters.subscriptionByCode(PLAN_CODE.PLATFORM_FEE) || null,
  productByCode: (state, getters) => (code) =>
    getters.products.find((product) => product.code === code),
  eSignProduct: (state, getters) => getters.productByCode(PRODUCT_CODE.E_SIGN),
};

function showSnackbar(isSuccess) {
  const text = isSuccess
    ? i18n.t("common.updateSuccessful")
    : i18n.t("common.updateFailed");
  const color = isSuccess ? "success" : "error";
  store.dispatch("snackbar/snackbar", {
    show: true,
    text,
    color,
  });
}

const actions = {
  async getBillingDetails({ commit }) {
    commit("loading", true);
    return axios
      .get(`${apiUrl}billing/details`)
      .then(({ data }) => {
        commit("setBillingDetails", data.billingDetails);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", false);
      });
  },
  async updateBillingAccount({ commit }, { invoiceEmail }) {
    return axios
      .put(`${apiUrl}billing/account`, { invoiceEmail })
      .then(({ data }) => {
        showSnackbar(true);
        commit("setBillingDetails", data.billingDetails);
      })
      .catch((error) => {
        showSnackbar(false);
        throw new Error(error);
      });
  },
  async updateMainSubscription(
    { commit, dispatch },
    { planCode, quantity, updateKey, extendTrial },
  ) {
    commit(updateKey, true);
    return axios
      .put(`${apiUrl}billing/main-subscription`, {
        code: planCode,
        quantity,
        extendTrial,
      })
      .then(async ({ data }) => {
        showSnackbar(true);
        commit("setBillingDetails", data.billingDetails);
        await dispatch("auth/getMe", {}, { root: true });
        return data;
      })
      .catch((error) => {
        showSnackbar(false);
        throw new Error(error);
      })
      .finally(() => {
        commit(updateKey, false);
      });
  },
  async cancelSubscriptionChange({ commit }, { subscriptionId }) {
    return axios
      .post(`${apiUrl}billing/subscription/${subscriptionId}/cancel-changes`)
      .then(({ data }) => {
        showSnackbar(true);
        commit("setBillingDetails", data.billingDetails);
      })
      .catch((error) => {
        showSnackbar(false);
        throw new Error(error);
      });
  },
  async purchaseProduct({ commit }, { quantity, productCode, updateKey }) {
    commit(updateKey, true);
    return axios
      .post(`${apiUrl}billing/product/purchase`, { quantity, code: productCode })
      .then(({ data }) => {
        showSnackbar(true);
        commit("setBillingDetails", data.billingDetails);
      })
      .catch((error) => {
        showSnackbar(false);
        throw new Error(error);
      })
      .finally(() => {
        commit(updateKey, false);
      });
  },
};

const mutations = {
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setBillingDetails(state, billingDetails) {
    state.billingDetails = billingDetails;
  },
  setIsChangingPlan(state, isChangingPlan) {
    state.isChangingPlan = isChangingPlan;
  },
  setIsAdjustingSeats(state, isAdjustingSeats) {
    state.isAdjustingSeats = isAdjustingSeats;
  },
  setIsUpdatingExtraStorage(state, isUpdatingExtraStorage) {
    state.isUpdatingExtraStorage = isUpdatingExtraStorage;
  },
  setIsUpdatingIntegrations(state, isUpdatingIntegrations) {
    state.isUpdatingIntegrations = isUpdatingIntegrations;
  },
  setIsUpdatingESignPurchase(state, isUpdatingESignPurchase) {
    state.isUpdatingESignPurchase = isUpdatingESignPurchase;
  },
  updateRecipient(state, { name, email }) {
    let recipient = state.billingDetails.recipients[0];
    if (recipient) {
      recipient.name = name;
      recipient.email = email;
    }
  },
};

export const billingDetails = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
