<template>
  <div>
    <AppTimeEntryDialog
      v-model="dialog.updateTimeEntry.active"
      :title="$t('timeRegistration.newTimeEntry.editTitle')"
      :data="dialog.updateTimeEntry.data"
    />
    <AppDeleteConfirmationDialog
      v-model="dialog.deleteTimeEntry.active"
      :title="
        $tc('timeRegistration.deleteTimeEntry.title', dialog.deleteTimeEntry.item?.length)
      "
      :validator="$t('timeRegistration.delete').toLowerCase()"
      :validatorText="
        $t('timeRegistration.deleteTimeEntry.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      :item="dialog.deleteTimeEntry.item"
      @delete="deleteTimeEntries"
    />
    <AppConfirmationTextareaDialog
      v-model="dialog.giveDeclineReason.active"
      :title="$t('timeRegistration.giveDeclineReason.title')"
      :textareaLabel="$t('timeRegistration.giveDeclineReason.label')"
      :data="dialog.giveDeclineReason.ids"
      @confirm="
        updateTimeEntries({
          body: {
            declineComment: $event.comment,
            status: $constant.TIME_ENTRY_STATUS.DECLINED,
          },
          ids: $event.data,
        })
      "
    />
    <AppTimeEntryDeclineReasonDialog
      v-model="dialog.seeDeclineReason.active"
      :timeEntry="dialog.seeDeclineReason.timeEntry"
    />
    <AppDataTableServerSidePagination2
      ref="dataTable"
      :loading="isLoading"
      :defaultHeaders="defaultHeaders"
      :items="timeEntries"
      :noDataText="$t('common.noTimeEntries')"
      :search="search"
      :serverItemsLength="serverItemsLength"
      :tableModel="tableModel"
      :actionConfigs="actionConfigs"
      :getAllItems="getAllItems"
      removeMutationType="timeEntries/removeTimeEntry"
      updateMutationType="timeEntries/updateTimeEntry"
    >
      <template
        v-slot:[`group.header`]="{
          toggle,
          group,
          items,
          isOpen,
          headers,
          isGroupIndeterminate,
          isGroupSelected,
          toggleGroup,
        }"
      >
        <template v-for="(header, index) in headers">
          <td
            v-if="header.value === 'data-table-select'"
            @click.stop="toggleGroup"
            class="table-cell--hover-effect"
            v-ripple
          >
            <div class="d-flex align-center justify-center h-100">
              <AppDefaultCheckboxIcon
                :isIndeterminate="isGroupIndeterminate"
                :isSelected="isGroupSelected"
              />
            </div>
          </td>
          <td
            v-if="index === 0"
            :key="'group-' + index"
            :colspan="calculateGroupHeaderColspan(headers)"
          >
            <div class="d-flex align-center">
              <v-btn
                class="mr-4"
                icon
                @click.stop="toggle"
              >
                <v-icon>
                  {{
                    isOpen
                      ? $icons.LIGHT.COMMON.CHEVRON_DOWN
                      : $icons.LIGHT.COMMON.CHEVRON_UP
                  }}
                </v-icon>
              </v-btn>
              <div
                class="group-header__title"
                :class="groupByMixins2_headerTitleColor(group)"
              >
                <v-icon
                  v-if="groupByMixins2_isGroupSelected(dataFields.STATUS)"
                  :color="headerIconColor(group)"
                  class="pr-3"
                >
                  {{ $icons.SOLID.ACTION.STATUS }}
                </v-icon>
                {{ headerTitle(group) }}
              </div>
            </div>
          </td>
          <td
            v-if="header.value === dataFields.DURATION"
            :key="'group-' + index"
          >
            <div class="text-end subtitle--text text-no-wrap">
              <span class="font-weight-bold">
                {{ $t("timeRegistration.common.total") | capitalize }}
              </span>
              {{
                timeMixins_formatHoursShort(
                  timeMixins_aggregateDuration(
                    items?.filter((item) => !item.isExtra),
                    dataFields.DURATION,
                  ),
                )
              }}
            </div>
          </td>
          <td
            v-if="header.value === dataFields.BREAK_DURATION"
            :key="'group-' + index"
          >
            <div class="text-end subtitle--text text-no-wrap">
              {{
                timeMixins_formatHoursShort(
                  timeMixins_aggregateDuration(items, dataFields.BREAK_DURATION),
                )
              }}
            </div>
          </td>
        </template>
      </template>
      <template v-slot:item="{ item, isSelected, select, headers }">
        <AppTimeEntryTableItem
          :timeEntry="item"
          :select="select"
          :isSelected="isSelected"
          :isContextMenuActiveItem="isContextMenuActiveItem(item)"
          :headers="headers"
          :tableModel="tableModel"
          @menu:click="openContextMenu"
          @imagePreview:click="onImagePreviewClick"
        />
      </template>
    </AppDataTableServerSidePagination2>
    <AppDataTableContextMenu
      ref="contextMenu"
      :actionConfigs="actionConfigs"
      :items="[contextMenuActiveItem]"
      @input="onContextMenuChange"
    />
    <AppFilePreviewDialog
      v-model="dialog.filePreview.fileId"
      :fileIds="dialog.filePreview.fileIds"
      @dialog:close="onImagePreviewDialogClose"
      :canEditFile="false"
    />
    <AppDateRangePickerDialog
      v-model="dialog.copyTimeEntryDateRangePicker.active"
      :selectedItems="dialog.copyTimeEntryDateRangePicker.items"
      @confirm="onCopyTimeEntryByDateRange"
    />
    <AppContextMenu ref="statusMenu">
      <AppChangeStatusMenuItems
        :status="itemsToEdit.length === 1 ? itemsToEdit[0].status : null"
        :statuses="statusMixins_timeEntriesStatusItems"
        :itemsDisabled="
          timeEntryActionMixins_isChangeStatusesItemsDisabled(
            itemsToEdit,
            itemsToEdit.length > 1,
          )
        "
        @change="(e) => onStatusesChange({ status: e, items: itemsToEdit })"
      />
    </AppContextMenu>
    <AppContextMenu ref="copyMenu">
      <AppMenuActionBtn
        v-for="action in copyActionConfigs"
        :key="action.key"
        v-bind="action.props"
        v-on="action.on"
        :selectedItems="itemsToEdit"
      />
    </AppContextMenu>
  </div>
</template>

<script>
import {
  timeMixins,
  planRestrictionsMixins,
  statusMixins,
  groupByMixins2,
  permissionMixins,
  timeEntryActionMixins,
  dataFieldsMixin,
} from "@/helpers/mixins";
import moment from "moment";
import { mapGetters } from "vuex";
import { createTableHeaders, permissionHelpers } from "@/helpers/util";

export default {
  mixins: [
    timeMixins,
    planRestrictionsMixins,
    statusMixins,
    groupByMixins2,
    timeEntryActionMixins,
    permissionMixins,
    dataFieldsMixin,
  ],
  props: {
    timeEntries: Array,
    isLoading: Boolean,
    search: String,
    serverItemsLength: Number,
    tableModel: String,
    getAllItems: Function,
  },
  data() {
    return {
      dialog: {
        updateTimeEntry: {
          data: null,
          active: false,
        },
        deleteTimeEntry: {
          active: false,
          item: [],
        },
        giveDeclineReason: {
          active: false,
          ids: null,
        },
        seeDeclineReason: {
          active: false,
          timeEntry: null,
        },
        upgradePlan: {
          active: false,
          planCode: null,
        },
        filePreview: {
          fileId: null,
          fileIds: [],
        },
        copyTimeEntryDateRangePicker: {
          active: false,
          items: [],
        },
      },
      contextMenuActiveItem: null,
      itemsToEdit: [],
    };
  },
  computed: {
    ...mapGetters("activeIntegrations", {
      hasTimeEntriesIntegrationEnabled: "hasTimeEntriesIntegrationEnabled",
    }),
    projectId() {
      return this.$route.params.projectId;
    },
    copyActionConfigs() {
      return this.$constant.getSubmenuActionConfigsByType(
        this.actionConfigs,
        this.$constant.ACTION_KEYS.COPY,
      );
    },
    canCreateTimeEntry() {
      return this.permissionMixins_timeEntry.create.can;
    },
    canSendToAccounting() {
      return this.permissionMixins_timeEntry.sendToAccounting.can;
    },
    actions() {
      const {
        EDIT,
        COPY,
        EDIT_STATUS,
        SEND_TO_ACCOUNTING,
        COPY_TO_NEXT_DAY,
        COPY_TO_NEXT_WEEK,
        COPY_TO_REST_OF_WEEK,
        COPY_TO_DATE_OR_PERIOD,
        DELETE_FROM_ACCOUNTING,
        DELETE,
        DECLINE_REASON,
      } = this.$constant.ACTION_KEYS;

      const openMenu = (menuRef) => (e) => this.openEditMenu({ ...e, menuRef });

      return {
        [EDIT]: {
          click: this.onTimeEntryEdit,
          disabled: ({ items }) => this.timeEntryActionMixins_isEditDisabled({ items }),
        },
        [COPY]: {
          click: openMenu("copyMenu"),
          disabled: !this.canCreateTimeEntry,
        },
        [EDIT_STATUS]: {
          click: openMenu("statusMenu"),
          disabled: ({ items }) =>
            this.timeEntryActionMixins_isChangeStatusesDisabled(items, items?.length > 1),
        },
        [SEND_TO_ACCOUNTING]: {
          click: this.onSendToAccounting,
          disabled: ({ items }) =>
            items.every((item) => item?.transferred) || !this.canSendToAccounting,
        },
        [COPY_TO_NEXT_DAY]: {
          click: ({ items }) => this.onCopyClick({ actionKey: COPY_TO_NEXT_DAY, items }),
          disabled: !this.canCreateTimeEntry,
        },
        [COPY_TO_NEXT_WEEK]: {
          click: ({ items }) => this.onCopyClick({ actionKey: COPY_TO_NEXT_WEEK, items }),
          disabled: !this.canCreateTimeEntry,
        },
        [COPY_TO_REST_OF_WEEK]: {
          click: ({ items }) =>
            this.onCopyClick({ actionKey: COPY_TO_REST_OF_WEEK, items }),
          disabled: !this.canCreateTimeEntry,
        },
        [COPY_TO_DATE_OR_PERIOD]: {
          click: ({ items }) =>
            this.onCopyClick({ actionKey: COPY_TO_DATE_OR_PERIOD, items }),
          disabled: !this.canCreateTimeEntry,
        },
        [DELETE_FROM_ACCOUNTING]: {
          click: this.deleteInAccounting,
          disabled: ({ items }) =>
            items.every((item) => !item?.transferred) || !this.canSendToAccounting,
        },
        [DELETE]: {
          click: this.onTimeEntriesDelete,
          disabled: ({ items }) => this.timeEntryActionMixins_isDeleteDisabled({ items }),
        },
        [DECLINE_REASON]: {
          click: this.onShowDeclinedReason,
          disabled: ({ items }) =>
            !this.timeEntryActionMixins_isDeclined({ item: items[0] }),
        },
      };
    },
    actionConfigs() {
      const {
        EDIT,
        COPY,
        EDIT_STATUS,
        SEND_TO_ACCOUNTING,
        COPY_TO_NEXT_DAY,
        COPY_TO_NEXT_WEEK,
        COPY_TO_REST_OF_WEEK,
        COPY_TO_DATE_OR_PERIOD,
        DELETE_FROM_ACCOUNTING,
        DELETE,
        DECLINE_REASON,
      } = this.$constant.ACTION_KEYS;

      const withMenuContext = (clickHandler) => (e) => {
        clickHandler({
          direction: e.hasSubMenu ? "right" : "top",
          parentMenuRef: e.hasSubMenu ? this.$refs.contextMenu : null,
          ...e,
        });
      };

      const actions = this.actions;

      const customActions = [
        {
          key: EDIT,
          props: {
            disabled: actions[EDIT].disabled,
          },
          on: {
            click: actions[EDIT].click,
          },
        },
        {
          key: COPY,
          props: {
            labelKey: "timeRegistration.copy.copyTo",
            hasSubMenu: true,
            disabled: actions[COPY].disabled,
          },
          on: {
            click: withMenuContext(actions[COPY].click),
          },
        },
        {
          key: EDIT_STATUS,
          props: {
            disabled: actions[EDIT_STATUS].disabled,
          },
          on: {
            click: withMenuContext(actions[EDIT_STATUS].click),
          },
        },
        {
          key: SEND_TO_ACCOUNTING,
          props: {
            hidden: !this.hasTimeEntriesIntegrationEnabled,
            planCode: this.$constant.PLAN_CODE.GRIPR_PRO,
            showStar: () => !this.planRestrictionsMixins_canIntegrateAccounting,
            disabled: actions[SEND_TO_ACCOUNTING].disabled,
          },
          on: {
            click: actions[SEND_TO_ACCOUNTING].click,
          },
        },
        {
          key: COPY_TO_NEXT_DAY,
          on: {
            click: actions[COPY_TO_NEXT_DAY].click,
          },
        },
        {
          key: COPY_TO_NEXT_WEEK,
          on: {
            click: actions[COPY_TO_NEXT_WEEK].click,
          },
        },
        {
          key: COPY_TO_REST_OF_WEEK,
          on: {
            click: actions[COPY_TO_REST_OF_WEEK].click,
          },
        },
        {
          key: COPY_TO_DATE_OR_PERIOD,
          props: {
            disabled: actions[COPY_TO_DATE_OR_PERIOD].disabled,
          },
          on: {
            click: actions[COPY_TO_DATE_OR_PERIOD].click,
          },
        },
        {
          key: DELETE_FROM_ACCOUNTING,
          props: {
            hidden: !this.hasTimeEntriesIntegrationEnabled,
            planCode: this.$constant.PLAN_CODE.GRIPR_PRO,
            showStar: () => !this.planRestrictionsMixins_canIntegrateAccounting,
            disabled: actions[DELETE_FROM_ACCOUNTING].disabled,
          },
          on: {
            click: actions[DELETE_FROM_ACCOUNTING].click,
          },
        },
        {
          key: DELETE,
          props: {
            disabled: actions[DELETE].disabled,
          },
          on: {
            click: actions[DELETE].click,
          },
        },
        {
          key: DECLINE_REASON,
          props: {
            hidden: ({ items }) =>
              !this.timeEntryActionMixins_isDeclined({ item: items[0] }),
          },
          on: {
            click: actions[DECLINE_REASON].click,
          },
        },
      ];

      const config = this.$constant.generateActionConfig({
        primaryActions: customActions,
        secondaryActions: this.$constant.TIME_ENTRY_ACTIONS,
      });

      return config;
    },
    defaultHeaders() {
      const { EDIT_STATUS } = this.$constant.ACTION_KEYS;
      const actions = this.actions;
      return createTableHeaders([
        {
          preset: "SELECT",
        },
        {
          preset: "STATUS",
          value: this.dataFields.STATUS,
          sortable: false,
          ...actions[EDIT_STATUS],
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.date"),
          ),
          value: this.dataFields.DATE,
          disabled: true,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.user"),
          ),
          value: this.dataFields.USER,
          disabled: true,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.comment"),
          ),
          value: this.dataFields.COMMENT,
          disabled: true,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.images"),
          ),
          value: this.dataFields.IMAGE_IDS,
          sortable: false,
          disabled: true,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.project"),
          ),
          value: this.dataFields.PROJECT,
          disabled: true,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.task"),
          ),
          value: this.dataFields.TASK,
          disabled: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.accounting")),
          value: this.dataFields.TRANSFERRED,
          access: this.hasTimeEntriesIntegrationEnabled,
          align: "center",
          sortable: false,
          disabled: true,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.hourType"),
          ),
          value: this.dataFields.HOUR_TYPE,
          disabled: true,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.time"),
          ),
          value: this.dataFields.TIME,
          align: "end",
          disabled: true,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.hours"),
          ),
          value: this.dataFields.DURATION,
          align: "end",
          disabled: true,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.break"),
          ),
          value: this.dataFields.BREAK_DURATION,
          align: "end",
          disabled: true,
        },
        {
          preset: "MENU",
          value: this.dataFields.MENU,
        },
      ]);
    },
  },
  methods: {
    openEditMenu(e) {
      const { items, menuRef } = e;
      this.$refs[menuRef].open(e);
      this.itemsToEdit = items;
    },
    openEditHeadersDialog() {
      this.$refs.dataTable.openEditHeadersDialog();
    },
    isContextMenuActiveItem(item) {
      return this.contextMenuActiveItem?.id === item?.id;
    },
    onCopyClick({ actionKey, items }) {
      const {
        COPY_TO_NEXT_DAY,
        COPY_TO_NEXT_WEEK,
        COPY_TO_REST_OF_WEEK,
        COPY_TO_DATE_OR_PERIOD,
      } = this.$constant.ACTION_KEYS;

      const { id, date } = items[0];
      let dateFrom, dateTo, weekDays;

      switch (actionKey) {
        case COPY_TO_NEXT_DAY:
          dateFrom = moment(date).add(1, "day").format("YYYY-MM-DD");
          dateTo = dateFrom;
          weekDays = this.dateMixins_weekdayValues;
          break;
        case COPY_TO_NEXT_WEEK:
          dateFrom = moment(date).add(1, "week").format("YYYY-MM-DD");
          dateTo = moment(date).add(1, "week").format("YYYY-MM-DD");
          weekDays = this.dateMixins_weekdayValues;
          break;
        case COPY_TO_REST_OF_WEEK:
          dateFrom = moment(date).add(1, "day").format("YYYY-MM-DD");
          dateTo = moment(date).endOf("week").format("YYYY-MM-DD");
          weekDays = this.dateMixins_weekdayValues.filter(
            (day) => day !== "SATURDAY" && day !== "SUNDAY",
          );
          break;
        case COPY_TO_DATE_OR_PERIOD:
          this.dialog.copyTimeEntryDateRangePicker.active = true;
          this.dialog.copyTimeEntryDateRangePicker.items = items;
          return;
        default:
          return;
      }

      const body = {
        ids: [id],
        dateFrom,
        dateTo,
        weekDays,
      };

      this.onTimeEntriesCopy(body);
    },
    onCopyTimeEntryByDateRange({ dates, days, items }) {
      const dateFrom = dates[0];
      const dateTo = dates[1] || dates[0];

      const body = {
        ids: items.map((item) => item.id),
        dateFrom,
        dateTo,
        weekDays: days,
      };

      this.onTimeEntriesCopy(body);
    },
    onTimeEntriesCopy(body) {
      this.$store
        .dispatch("timeEntries/copyTimeEntries", {
          body,
        })
        .then(() => {
          this.$emit("refresh");
        });
    },
    headerIconColor(group) {
      return this.statusMixins_statusColor(
        group,
        false,
        this.statusMixins_timeEntriesStatusItems,
      );
    },
    onSendToAccounting({ items }) {
      if (!this.planRestrictionsMixins_canIntegrateAccounting) {
        this.dialog.upgradePlan.active = true;
        return;
      }

      if (!this.timeEntryActionMixins_hasMappedHourTypes({ items })) {
        this.onHasUnmappedHourTypes();
        return;
      }

      if (!this.timeEntryActionMixins_isAllItemsApproved({ items })) {
        this.showSnackbar(
          "timeRegistration.accounting.snackbar.onlyApprovedTimeEntries",
          "warning",
        );
        return;
      }

      const untransferredIds = items.filter((te) => !te.transferred).map((te) => te.id);

      this.$store.dispatch("timeEntries/sendToAccounting", {
        ids: untransferredIds,
      });
    },
    onHasUnmappedHourTypes() {
      const canEnterSettingsPage = permissionHelpers.canViewRoute(
        this.$routeNames.SETTINGS.TIME_ENTRY_SETTINGS,
      );

      if (canEnterSettingsPage) {
        const route = this.$router.resolve({
          name: this.$routeNames.SETTINGS.SYSTEM_SETTINGS,
        });
        window.open(route.href, "_blank");
      } else {
        this.showSnackbar(
          "timeRegistration.accounting.snackbar.hrAdminCantConfigure",
          "error",
        );
      }
    },
    showSnackbar(messageKey, color) {
      this.$store.dispatch("snackbar/snackbar", {
        show: true,
        text: this.$t(messageKey),
        color,
      });
    },
    deleteInAccounting({ items }) {
      if (!this.planRestrictionsMixins_canIntegrateAccounting) {
        this.dialog.upgradePlan.active = true;
        return;
      }
      const transferredIds = items
        .filter((item) => item.transferred)
        .map((item) => item.id);

      this.$store.dispatch("timeEntries/deleteInAccounting", {
        ids: transferredIds,
      });
    },
    onStatusChange({ status, items }) {
      if (status === "DECLINED") {
        this.dialog.giveDeclineReason.active = true;
        this.dialog.giveDeclineReason.ids = items[0];
        return;
      }
      this.updateTimeEntries({ body: { status }, ids: items.map((item) => item.id) });
    },
    onStatusesChange({ status, items }) {
      const ids = items.filter((item) => item.status !== status).map((te) => te.id);

      if (status === "DECLINED") {
        this.dialog.giveDeclineReason.active = true;
        this.dialog.giveDeclineReason.ids = ids;
        return;
      }
      this.updateTimeEntries({ body: { status }, ids });
    },
    updateTimeEntries({ body, ids }) {
      this.$store.dispatch("timeEntries/updateTimeEntries", {
        ids,
        body,
      });
    },
    headerTitle(group) {
      const commonHeaderTitle = this.groupByMixins2_commonHeaderTitle(group);
      if (commonHeaderTitle) return commonHeaderTitle;
      if (this.groupByMixins2_isGroupSelected(this.dataFields.STATUS))
        return this.$t(`statuses.timeEntry.${group}`);
      if (this.groupByMixins2_isGroupSelected(this.dataFields.TRANSFERRED))
        return this.$t(
          `timeRegistration.accounting.${group ? "inAccounting" : "notInAccounting"}`,
        );
      return group;
    },
    openContextMenu({ from, timeEntry, direction }) {
      this.$refs.contextMenu.open({ from, direction });
      this.$nextTick(() => {
        this.contextMenuActiveItem = timeEntry;
      });
    },
    onContextMenuChange(isOpen) {
      if (!isOpen) {
        this.contextMenuActiveItem = null;
      }
    },
    onTimeEntriesDelete({ items }) {
      this.dialog.deleteTimeEntry.active = true;
      this.dialog.deleteTimeEntry.item = items;
    },
    deleteTimeEntries({ item, event }) {
      this.$store.dispatch("timeEntries/deleteTimeEntries", {
        ids: item.map((item) => item.id),
      });
    },
    onTimeEntryEdit() {
      this.dialog.updateTimeEntry.active = true;
      this.dialog.updateTimeEntry.data = this.contextMenuActiveItem;
    },
    onShowDeclinedReason() {
      if (!this.contextMenuActiveItem) return;

      this.dialog.seeDeclineReason.active = true;
      this.dialog.seeDeclineReason.timeEntry = this.contextMenuActiveItem;
    },
    onImagePreviewClick({ timeEntry }) {
      const fileIds = timeEntry.imageIds;
      const fileId = fileIds[0];
      if (!fileId) return;
      this.dialog.filePreview.fileId = fileId;
      this.dialog.filePreview.fileIds = fileIds.map((id) => ({ id }));
    },
    onImagePreviewDialogClose() {
      this.dialog.filePreview.fileId = null;
      this.dialog.filePreview.fileIds = [];
    },
    hasDurationColumn(headers) {
      return headers.some((header) => header.value === this.dataFields.DURATION);
    },
    hasBreakDurationColumn(headers) {
      return headers.some((header) => header.value === this.dataFields.BREAK_DURATION);
    },
    hasSelectColumn(headers) {
      return headers.some((header) => header.value === "data-table-select");
    },
    calculateGroupHeaderColspan(headers) {
      let colspan = headers.length;
      if (this.hasSelectColumn(headers)) {
        colspan -= 1;
      }
      if (this.hasDurationColumn(headers)) {
        colspan -= 1;
      }
      if (this.hasBreakDurationColumn(headers)) {
        colspan -= 1;
      }
      return colspan;
    },
  },
};
</script>
