<template>
  <v-dialog
    v-model="localDialog"
    max-width="600px"
    scrollable
  >
    <v-card>
      <v-card-title>
        {{ $t("common.selectFiles") }}
      </v-card-title>
      <v-card-text>
        <AppFilePicker
          v-model="selectedFiles"
          :mimeTypes="mimeTypes"
          :modes="modes"
          maxHeight="600px"
          :disableNameEdit="disableNameEdit"
        />
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onConfirm"
          @cancel="onCancel"
          :disabled="isSubmitDisabled"
          :confirmText="$t('common.upload')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    dialog: Boolean,
    modes: Array,
    //mimeTypes supports wildcards like image/* or video/*
    mimeTypes: Array,
    disableNameEdit: Boolean,
  },
  model: {
    prop: "dialog",
    event: "change",
  },
  data() {
    return {
      selectedFiles: [],
    };
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    isSubmitDisabled() {
      return this.selectedFiles.length === 0;
    },
    ...mapGetters("auth", {
      activeDomainId: "activeDomainId",
    }),
  },
  methods: {
    onConfirm() {
      if (this.selectedFiles.length > 0) {
        this.$emit("submit", this.selectedFiles);
        this.selectedFiles = [];
      }
      this.localDialog = false;
    },
    onCancel() {
      this.localDialog = false;
    },
  },
};
</script>
