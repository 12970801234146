<template>
  <div class="text-border-reset">
    <AppDeleteConfirmationDialog
      ref="deleteConfirmation"
      v-model="dialog.delete.active"
      :title="
        $tc(
          'settings.corporateManagement.deleteConfirmation.title',
          dialog.delete.item?.length,
        )
      "
      skip-validation
      :item="dialog.delete.item"
      @delete="({ item }) => onDelete({ ids: item?.map((item) => item.id) })"
    />
    <AppDataTableServerSidePagination
      ref="dataTable"
      :loading="isLoading"
      :defaultHeaders="defaultHeaders"
      :items="childDomains"
      :tableModel="tableModel"
      :serverItemsLength="serverItemsLength"
      :noDataText="$t('common.noChildDomains')"
      :actionConfigs="actionConfigs"
      removeMutationType="corporateManagement/removeChildDomain"
      updateMutationType="corporateManagement/updateChildDomain"
    >
      <template v-slot:item="{ item, headers, select, isSelected }">
        <AppCorporateManagementTableItem
          :item="item"
          :headers="headers"
          :tableModel="tableModel"
          :select="select"
          :isSelected="isSelected"
          :isContextMenuActiveItem="isContextMenuActiveItem(item)"
          @menu:click="openContextMenu"
        />
      </template>
    </AppDataTableServerSidePagination>
    <AppCorporateManagementDetailsDialog
      v-model="dialog.details.active"
      :item="dialog.details.item"
      :initial-section="dialog.details.section"
      @update:accessesGiven="$emit('update:accessesGiven', $event)"
      @invite="$emit('invite', $event)"
      @delete="onDelete"
    />
    <AppDataTableContextMenu
      ref="contextMenu"
      :actionConfigs="actionConfigs"
      :items="[contextMenuActiveItem]"
      @input="onContextMenuChange"
    />
    <AppGiveChildDomainAccessMenu
      ref="giveChildDomainAccessMenu"
      :items="itemsToEdit"
      @submit="$emit('update:accessesGiven', $event)"
    />
  </div>
</template>

<script>
import { filtersMixins } from "@/helpers/mixins";
import { createTableHeaders } from "@/helpers/util";
import { permissionMixins } from "@/helpers/mixins";

export default {
  mixins: [filtersMixins, permissionMixins],
  props: {
    childDomains: Array,
    isLoading: Boolean,
    tableModel: String,
    serverItemsLength: Number,
  },
  data() {
    return {
      dialog: {
        delete: {
          active: false,
          item: [],
        },
        details: {
          active: false,
          item: null,
          section: null,
        },
      },
      contextMenuActiveItem: null,
      itemsToEdit: [],
    };
  },
  computed: {
    canEditCorporateManagement() {
      return this.permissionMixins_corporateManagement.edit.can;
    },
    actions() {
      const { DETAILS, DELETE, ACCESS_CONTROL, ANALYTICS, EDIT_CHILD_DOMAIN_ACCESS } =
        this.$constant.ACTION_KEYS;

      const openMenu = (menuRef) => (e) => this.openEditMenu({ ...e, menuRef });
      const openDetailsWithSection =
        (section) =>
        ({ items }) => {
          this.openDetailsDialog({ items, initialSection: section });
        };

      return {
        [EDIT_CHILD_DOMAIN_ACCESS]: {
          disabled: !this.canEditCorporateManagement,
          click: openMenu("giveChildDomainAccessMenu"),
        },
        [DETAILS]: {
          click: openDetailsWithSection("details"),
        },
        [ANALYTICS]: {
          click: openDetailsWithSection("analytics"),
        },
        [ACCESS_CONTROL]: {
          click: openDetailsWithSection("accessControl"),
        },
        [DELETE]: {
          disabled: !this.canEditCorporateManagement,
          click: this.onChildDomainsDelete,
        },
      };
    },
    defaultHeaders() {
      const { DETAILS, EDIT_CHILD_DOMAIN_ACCESS } = this.$constant.ACTION_KEYS;

      return createTableHeaders([
        {
          preset: "SELECT",
        },
        {
          preset: "NAME",
          value: this.filtersMixins_dataFields.NAME,
          text: this.$t("settings.corporateManagement.fields.name"),
          ...this.actions[DETAILS],
        },
        {
          value: this.filtersMixins_dataFields.ORG_NR,
          text: this.$t("settings.corporateManagement.fields.orgNr"),
          disabled: true,
        },
        {
          value: this.filtersMixins_dataFields.STATUS,
          text: this.$t("settings.corporateManagement.fields.status"),
          disabled: true,
        },
        {
          value: this.filtersMixins_dataFields.PLAN,
          text: this.$t("settings.corporateManagement.fields.plan"),
          disabled: true,
        },
        {
          value: this.filtersMixins_dataFields.CONNECTED_AT,
          text: this.$t("settings.corporateManagement.fields.connectedDate"),
          disabled: true,
        },
        {
          value: this.filtersMixins_dataFields.OWNER,
          text: this.$t("settings.corporateManagement.fields.owner"),
          disabled: true,
        },
        {
          value: this.filtersMixins_dataFields.ACCESSES_GIVEN,
          text: this.$t("settings.corporateManagement.fields.accessesGiven"),
          ...this.actions[EDIT_CHILD_DOMAIN_ACCESS],
        },
        {
          preset: "MENU",
          value: this.filtersMixins_dataFields.MENU,
        },
      ]);
    },
    actionConfigs() {
      const { DETAILS, DELETE, ACCESS_CONTROL, ANALYTICS, EDIT_CHILD_DOMAIN_ACCESS } =
        this.$constant.ACTION_KEYS;
      const actions = this.actions;

      const withMenuContext = (clickHandler) => (e) => {
        clickHandler({
          direction: e.hasSubMenu ? "right" : "top",
          parentMenuRef: e.hasSubMenu ? this.$refs.contextMenu : null,
          ...e,
        });
      };

      const customActions = [
        {
          key: DETAILS,
          on: {
            click: withMenuContext(actions[DETAILS].click),
          },
        },
        {
          key: ANALYTICS,
          on: {
            click: withMenuContext(actions[ANALYTICS].click),
          },
        },
        {
          key: ACCESS_CONTROL,
          on: {
            click: withMenuContext(actions[ACCESS_CONTROL].click),
          },
        },
        {
          key: EDIT_CHILD_DOMAIN_ACCESS,
          on: {
            click: withMenuContext(actions[EDIT_CHILD_DOMAIN_ACCESS].click),
          },
          props: {
            disabled: actions[EDIT_CHILD_DOMAIN_ACCESS].disabled,
          },
        },
        {
          key: DELETE,
          on: {
            click: withMenuContext(actions[DELETE].click),
          },
          props: {
            disabled: actions[DELETE].disabled,
          },
          divider: true,
        },
      ];

      return this.$constant.generateActionConfig({
        primaryActions: customActions,
        secondaryActions: this.$constant.CORPORATE_MANAGEMENT_ACTIONS,
      });
    },
  },
  methods: {
    openEditMenu(e) {
      const { items, menuRef } = e;
      this.$refs[menuRef].open(e);
      this.itemsToEdit = items;
    },
    openDetailsDialog({ items, initialSection }) {
      this.dialog.details.active = true;
      this.dialog.details.item = items[0];
      this.dialog.details.section = initialSection;
    },
    openEditHeadersDialog() {
      this.$refs.dataTable.openEditHeadersDialog();
    },
    onContextMenuChange(isOpen) {
      if (!isOpen) {
        this.contextMenuActiveItem = null;
      }
    },
    isContextMenuActiveItem(item) {
      return this.contextMenuActiveItem?.id === item?.id;
    },
    openContextMenu({ from, item, direction }) {
      this.$refs.contextMenu.open({ from, direction });
      this.$nextTick(() => {
        this.contextMenuActiveItem = item;
      });
    },
    onChildDomainsDelete({ items }) {
      this.dialog.delete.item = items;
      this.dialog.delete.active = true;
    },
    onDelete({ ids }) {
      this.$emit("delete", { ids });
    },
  },
};
</script>
