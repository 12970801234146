<template>
  <draggable
    v-bind="$attrs"
    v-on="listeners"
    :animation="$constant.DRAG_SETTINGS.ANIMATION_DURATION"
    :dragClass="$attrs.dragClass || $constant.DRAG_SETTINGS.DRAG_CLASS"
    :scrollSensitivity="60"
    :emptyInsertThreshold="20"
    filter=".block-draggable"
    draggable=".draggable-item"
    :forceFallback="true"
    :fallbackTolerance="5"
    @choose="onDragChoose"
    @unchoose="onDragUnchoose"
  >
    <slot></slot>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  computed: {
    listeners() {
      const { choose, unchoose, ...otherListeners } = this.$listeners;
      return otherListeners;
    },
  },
  methods: {
    onDragChoose() {
      document.documentElement.classList.add("drag-cursor");
      document.documentElement.classList.add("user-select-none");
      this.$emit("choose");
    },
    onDragUnchoose() {
      document.documentElement.classList.remove("drag-cursor");
      document.documentElement.classList.remove("user-select-none");
      this.$emit("unchoose");
    },
  },
};
</script>
