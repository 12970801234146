import { ROUTE_NAMES, ROUTE_PERMISSIONS } from "@/helpers/routeNames";
import multiguard from "../multiguard";
import { authGuard, userGuard } from "@/auth";
import { HELP_CATEGORIES } from "@/helpers/constants/help";

const { ROOT, CONTACT_PERSON_LIST, CLIENT_LIST } = ROUTE_NAMES.CONTACT;

export const contactRoutes = [
  {
    path: "/contact",
    name: ROOT,
    beforeEnter: multiguard([authGuard, userGuard]),
    meta: {
      permission: {
        isRedirectRoute: true,
      },
    },
    component: () => import(/* webpackChunkName: "contact" */ "../../pages/ContactsPage"),
    children: [
      {
        path: "contact-person-list",
        name: CONTACT_PERSON_LIST,
        meta: {
          permission: ROUTE_PERMISSIONS.CONTACT,
          helpCategory: HELP_CATEGORIES.CONTACT,
        },
        component: () =>
          import(
            /* webpackChunkName: "contact" */ "../../pages/ContactsPage/ContactPersonListPage"
          ),
      },
      {
        path: "client-list",
        name: CLIENT_LIST,
        meta: {
          permission: ROUTE_PERMISSIONS.CLIENT,
          helpCategory: HELP_CATEGORIES.CLIENT,
        },
        component: () =>
          import(
            /* webpackChunkName: "contact" */ "../../pages/ContactsPage/ClientListPage"
          ),
      },
    ],
  },
];
