<template>
  <AppWidgetTemplate
    @delete:click="$emit('delete:click')"
    isEditableWidget
    @edit:click="onEditClick"
    :title="chartName"
    :isLoading="isLoading"
    v-bind="templateProps"
  >
    <template v-if="!isLoading">
      <div class="pa-3 h-100 w-100">
        <AppDefaultChart
          :chartOptions="formattedChartOptions"
          :chartData="formattedChartData"
        />
      </div>
    </template>
    <template v-slot:append-header>
      <AppToggleMenuBtn>
        <AppMenuEditBtn @click="onEditClick" />
      </AppToggleMenuBtn>
    </template>
  </AppWidgetTemplate>
</template>

<script>
import { getChartData } from "@/services/chart/chartData";
import _ from "lodash";

export default {
  props: {
    templateProps: Object,

    chartName: String,
    chartType: String,
    isStacked: Boolean,

    createdAtRange: String,
    dataPointInterval: String,
    datasets: Array,
    datasetType: String,
    grouping: String,
    statusCategories: Array,
  },
  data() {
    return {
      isLoading: false,
      chartData: null,
    };
  },
  watch: {
    chartQueryParams: {
      handler(newVal, oldVal) {
        if (!_.isEqual(newVal, oldVal)) {
          this.getChartData();
        }
      },
      deep: true,
    },
  },
  computed: {
    chartQueryParams() {
      return {
        createdAtRange: this.createdAtRange,
        statusCategories: this.statusCategories,
        dataPointInterval: this.dataPointInterval,
        datasets: this.datasets,
        datasetType: this.datasetType,
        grouping: this.grouping,
      };
    },
    formattedChartOptions() {
      const typeMap = {
        STATUS_CATEGORY: "category",
        CREATED_AT: "datetime",
      };

      const xaxis = { type: typeMap[this.grouping] };

      const options = {
        chart: {
          type: this.chartType,
          stacked: this.isStacked,
        },
        xaxis: xaxis,
      };

      return options;
    },
    formattedChartData() {
      //todo: format chart data
      return this.chartData;
    },
  },
  methods: {
    onEditClick() {
      this.$emit("editChart:click", {
        widgetProps: {
          chartName: this.chartName,
          chartType: this.chartType,
          createdAtRange: this.createdAtRange,
          dataPointInterval: this.dataPointInterval,
          datasets: this.datasets,
          datasetType: this.datasetType,
          grouping: this.grouping,
          isStacked: this.isStacked,
          statusCategories: this.statusCategories,
        },
      });
    },

    async getChartData() {
      this.isLoading = true;

      const queryParams = {
        //filters
        createdAtRange: this.createdAtRange,
        statusCategories: this.statusCategories,

        //config
        dataPointInterval: this.dataPointInterval,
        datasets: this.datasets,
        datasetType: this.datasetType,
        grouping: this.grouping,
      };
      getChartData({ data: queryParams })
        .then((res) => {
          this.chartData = res;
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.getChartData();
  },
};
</script>
