<template>
  <div>
    <AppMiniContentSelectionDivider />
    <template v-if="activeDomainId">
      <!-- My Company Section -->
      <AppNavigationList
        v-if="hasVisibleItemsForSection($constant.DOMAIN_DRAWER_SECTIONS.COMPANY)"
      >
        <AppContentSectionHeader>
          {{ $t("drawer.routeLabel.company") }}
          <v-spacer></v-spacer>
          <v-btn
            ref="pinMenuBtn"
            color="subtitle"
            small
            icon
            @click="openPinMenu"
          >
            <v-icon
              small
              class="rotate-45"
            >
              {{ $icons.LIGHT.ACTION.PIN }}
            </v-icon>
          </v-btn>
        </AppContentSectionHeader>
        <AppNavigationItem
          v-for="item in getItemsForSection($constant.DOMAIN_DRAWER_SECTIONS.COMPANY)"
          v-if="showRoute(item)"
          :key="item.routeName"
          :to="{ name: item.routeName }"
          :prependIcon="item.icon"
          :title="$t(item.title)"
          prepend-icon-small
          replace
        />
      </AppNavigationList>

      <AppMiniContentSelectionDivider />

      <!-- Manage Section -->
      <AppNavigationList
        v-if="hasVisibleItemsForSection($constant.DOMAIN_DRAWER_SECTIONS.CRM)"
      >
        <AppContentSectionHeader>
          {{ $t("drawer.routeLabel.crm") }}
        </AppContentSectionHeader>
        <AppNavigationItem
          v-for="item in getItemsForSection($constant.DOMAIN_DRAWER_SECTIONS.CRM)"
          v-if="showRoute(item)"
          :key="item.routeName"
          :to="{ name: item.routeName }"
          :prependIcon="item.icon"
          :title="$t(item.title)"
          prepend-icon-small
          replace
        />
      </AppNavigationList>

      <!-- Company Section -->
      <AppNavigationList
        v-if="hasVisibleItemsForSection($constant.DOMAIN_DRAWER_SECTIONS.MANAGE)"
      >
        <AppContentSectionHeader>
          {{ $t("drawer.routeLabel.manage") }}
        </AppContentSectionHeader>
        <AppNavigationItem
          v-for="item in getItemsForSection($constant.DOMAIN_DRAWER_SECTIONS.MANAGE)"
          v-if="showRoute(item)"
          :key="item.routeName"
          :to="{ name: item.routeName }"
          :prependIcon="item.icon"
          :title="$t(item.title)"
          :appendIcon="
            item.showAppendIcon ? $icons.SOLID.ACTION.GO_NEXT_LEVEL : undefined
          "
          prepend-icon-small
          :show-append-icon-only-on-hover="item.showAppendIcon"
          replace
        />
      </AppNavigationList>

      <!-- Pin Menu -->
      <AppContextMenu ref="pinMenu">
        <v-list
          dense
          class="pa-0"
        >
          <v-list-item
            v-for="item in navigationItems"
            v-if="item.access"
            :key="item.routeName"
            class="px-2"
            :to="{ name: item.routeName }"
          >
            <v-list-item-icon class="ml-2 mr-2">
              <v-icon small>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-body-2">
                {{ $t(item.title) }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action
              class="my-0"
              v-if="item.routeName !== $routeNames.HOME.ROOT"
            >
              <AppDefaultTooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    style="min-width: 40px"
                    depressed
                    small
                    color="transparent"
                    @click.stop.prevent="togglePin(item.routeName)"
                  >
                    <v-icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                    >
                      {{
                        isPinned(item.routeName)
                          ? $icons.SOLID.ACTION.PIN
                          : $icons.REGULAR.ACTION.PIN
                      }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{
                    isPinned(item.routeName)
                      ? $t("drawer.pinMenu.unpin")
                      : $t("drawer.pinMenu.pin")
                  }}
                </span>
              </AppDefaultTooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </AppContextMenu>
    </template>
  </div>
</template>

<script>
import { permissionHelpers } from "@/helpers/util";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      pinnedItems: [],
    };
  },
  watch: {
    activeDomainId: {
      immediate: true,
      handler(newVal) {
        const pinnedItems = JSON.parse(
          localStorage.getItem(`domain_drawer_pins_${newVal}`),
        );
        const allPins = JSON.stringify(
          this.navigationItems.map((item) => item.routeName),
        );

        //First time when nothing is saved in local storage, set all pins as pinned
        //Need to handle this when new menu items are added :)
        if (pinnedItems === null) {
          localStorage.setItem(`domain_drawer_pins_${newVal}`, allPins);
        }
        this.pinnedItems = pinnedItems || JSON.parse(allPins);
      },
    },
  },
  computed: {
    navigationItems() {
      return [
        // My Company section
        {
          routeName: this.$routeNames.HOME.ROOT,
          title: "drawer.route.home",
          section: this.$constant.DOMAIN_DRAWER_SECTIONS.COMPANY,
          icon: this.$icons.REGULAR.ROUTE.HOME,
          access: true,
        },
        {
          routeName: this.$routeNames.DASHBOARD.ROOT,
          title: "drawer.route.dashboard",
          section: this.$constant.DOMAIN_DRAWER_SECTIONS.COMPANY,
          icon: this.$icons.REGULAR.ROUTE.DASHBOARD,
          access: this.canView(this.$routeNames.DASHBOARD.ROOT),
        },
        {
          routeName: this.$routeNames.STORAGE.ROOT,
          title: "drawer.route.files",
          section: this.$constant.DOMAIN_DRAWER_SECTIONS.COMPANY,
          icon: this.$icons.REGULAR.ROUTE.STORAGE,
          access: this.canView(this.$routeNames.STORAGE.ROOT),
        },
        {
          routeName: this.$routeNames.EMPLOYEE.ROOT,
          title: "drawer.route.employees",
          section: this.$constant.DOMAIN_DRAWER_SECTIONS.COMPANY,
          icon: this.$icons.REGULAR.ROUTE.USER,
          access: this.canView(this.$routeNames.EMPLOYEE.ROOT),
        },
        {
          routeName: this.$routeNames.TEMPLATE.ROOT,
          title: "drawer.route.templates",
          section: this.$constant.DOMAIN_DRAWER_SECTIONS.COMPANY,
          icon: this.$icons.REGULAR.ROUTE.TEMPLATE,
          showAppendIcon: true,
          access: this.canView(this.$routeNames.TEMPLATE.ROOT),
        },

        // Manage section
        {
          routeName: this.$routeNames.CONTACT.ROOT,
          title: "drawer.route.contacts",
          section: this.$constant.DOMAIN_DRAWER_SECTIONS.CRM,
          icon: this.$icons.REGULAR.ROUTE.CONTACT,
          access: this.canView(this.$routeNames.CONTACT.ROOT),
        },
        {
          routeName: this.$routeNames.PROJECT.ROOT,
          title: "drawer.route.projects",
          section: this.$constant.DOMAIN_DRAWER_SECTIONS.CRM,
          icon: this.$icons.REGULAR.ROUTE.PROJECT,
          access: this.canView(this.$routeNames.PROJECT.ROOT),
        },

        // Company section
        {
          routeName: this.$routeNames.TASK.ROOT,
          section: this.$constant.DOMAIN_DRAWER_SECTIONS.MANAGE,
          title: "drawer.route.tasks",
          icon: this.$icons.REGULAR.ROUTE.TASK,
          access: this.canView(this.$routeNames.TASK.ROOT),
        },
        {
          routeName: this.$routeNames.FORM.ROOT,
          title: "drawer.route.forms",
          section: this.$constant.DOMAIN_DRAWER_SECTIONS.MANAGE,
          icon: this.$icons.REGULAR.ROUTE.FORM,
          access: this.canView(this.$routeNames.FORM.ROOT),
        },
        {
          routeName: this.$routeNames.TIME_REGISTRATION.ROOT,
          title: "drawer.route.timeRegistration",
          section: this.$constant.DOMAIN_DRAWER_SECTIONS.MANAGE,
          icon: this.$icons.REGULAR.ROUTE.TIME_REGISTRATION,
          access: this.canView(this.$routeNames.TIME_REGISTRATION.ROOT),
        },
      ];
    },
    storageKey() {
      return `domain_drawer_pins_${this.activeDomainId}`;
    },
    ...mapGetters("auth", {
      activeDomainId: "activeDomainId",
    }),
  },
  methods: {
    canView(routeName) {
      return permissionHelpers.canViewRoute(routeName);
    },
    openPinMenu() {
      const from = this.$refs.pinMenuBtn;

      this.$refs.pinMenu.open({
        from,
        nudge: 4,
        direction: "right",
      });
    },
    showRoute(item) {
      const { routeName, access } = item;

      // Home is always visible if accessible
      if (routeName === this.$routeNames.HOME.ROOT) {
        return access;
      }

      return access && (this.isPinned(routeName) || this.isUnderRoute(routeName));
    },
    hasVisibleItemsForSection(section) {
      return this.navigationItems
        .filter((item) => item.section === section)
        .some((item) => this.showRoute(item));
    },
    isPinned(routeName) {
      return this.pinnedItems.includes(routeName);
    },
    isUnderRoute(routeName) {
      return this.$route.matched.some((m) => m.name === routeName);
    },
    togglePin(routeName) {
      const newPinnedItems = this.isPinned(routeName)
        ? this.pinnedItems.filter((item) => item !== routeName)
        : [...this.pinnedItems, routeName];

      this.pinnedItems = newPinnedItems;
      localStorage.setItem(this.storageKey, JSON.stringify(newPinnedItems));
    },
    getItemsForSection(section) {
      return this.navigationItems.filter((item) => item.section === section);
    },
  },
};
</script>

<style>
.rotate-45 {
  transform: rotate(45deg);
}
</style>
