<template>
  <div>
    <AppDeleteConfirmationDialog
      ref="deleteConfirmation"
      v-model="dialog.deleteUser.active"
      :item="dialog.deleteUser.item"
      :title="$tc('users.deleteConfirmation.title', dialog.deleteUser?.item?.length)"
      :subtitle="$t('users.deleteConfirmation.description')"
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('fileManagement.fileExplorer.deleteConfirmation.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="deleteUser"
    />
    <AppEditUserDialog
      v-model="dialog.editUser.active"
      :item="dialog.editUser.item"
      @afterEdit:completed="afterEdit"
    />
    <AppDataTable
      :headers="defaultHeaders"
      :items="users"
      :loading="isLoading"
      :noDataText="$t('common.noUsers')"
      :isStatsLoading="isUserStatsLoading"
      :statsText="statsText"
      :actionConfigs="actionConfigs"
      updateMutationType="settingsUsers/updateUser"
    >
      <template v-slot:item="{ item, headers, select, isSelected }">
        <AppUserTableItem
          :user="item"
          :select="select"
          :isSelected="isSelected"
          :headers="headers"
          :isContextMenuActiveItem="isContextMenuActiveItem(item)"
          :canDoActions="canDoActions"
          :jobTitleLabel="jobTitleLabel(item)"
          @menu:click="onMenuClick"
        />
      </template>
    </AppDataTable>
    <AppActivityIndicator
      :show="isInviting"
      :text="$t('users.userInvite.sendingInvite')"
    />
    <AppActivityIndicator
      :show="isUpdatingStatus"
      :text="$t('users.userInvite.isUpdatingStatus')"
    />
    <AppLabelMenu
      ref="labelMenuJobTitle"
      @label:change="onLabelChange"
      :labelType="labelType"
      selectedItemLabelPath="workTitleLabel"
    />
    <AppUserRolePickerMenu
      ref="roleMenu"
      @submit="onRoleChange"
    />
    <AppDataTableContextMenu
      ref="contextMenu"
      :actionConfigs="actionConfigs"
      :items="[contextMenuActiveItem]"
      @input="onContextMenuChange"
    />
    <AppInviteUserIncreaseLicenseDialog
      v-model="dialog.increaseLicense.active"
      :totalDesiredLicensesToUse="dialog.increaseLicense.requiredLicenses"
      @license:upgrade="onLicenseUpgradeCompleted"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { planRestrictionsMixins, permissionMixins } from "@/helpers/mixins";
import { createTableHeaders } from "@/helpers/util";

export default {
  mixins: [planRestrictionsMixins, permissionMixins],
  props: {
    users: Array,
    isLoading: Boolean,
    canDoActions: Boolean,
  },
  data() {
    return {
      dialog: {
        deleteUser: {
          active: false,
          item: null,
        },
        editUser: {
          active: false,
          item: null,
        },
        increaseLicense: {
          active: false,
          requiredLicenses: 0,
          pendingAction: null,
        },
      },
      contextMenuActiveItem: null,
      addLabelDialog: {
        active: false,
      },
      updateLabelDialog: {
        active: false,
        data: null,
      },
      labelType: this.$constant.LABEL_TYPE.JOB_TITLE,
      isContextMenuOpen: false,
      itemsToEdit: [],
    };
  },
  computed: {
    ...mapState("settingsUsers", {
      isInviting: (state) => state.isInviting,
      isUpdatingStatus: (state) => state.isUpdatingStatus,
    }),
    ...mapState("userStats", {
      isUserStatsLoading: (state) => state.isLoading,
    }),
    ...mapGetters("label", {
      labelsByType: "labelsByType",
    }),
    ...mapGetters("auth", {
      currentUserId: "currentUserId",
    }),
    ...mapGetters("userStats", {
      statsCountNotDeletedOrInactive: "statsCountNotDeletedOrInactive",
    }),
    actions() {
      const { RE_INVITE, SET_INACTIVE, EDIT, EDIT_ROLE, DELETE, EDIT_JOB_TITLE } =
        this.$constant.ACTION_KEYS;

      const openMenu = (menuRef) => (e) => this.openEditMenu({ ...e, menuRef });

      return {
        [RE_INVITE]: {
          click: this.onUsersInvite,
          disabled: this.isInviteDisabled,
        },
        [SET_INACTIVE]: {
          click: this.setInactiveUser,
          disabled: this.isSetInactiveDisabled,
        },
        [EDIT]: {
          click: this.onEditClick,
          disabled: !this.canEditUser,
        },
        [EDIT_ROLE]: {
          click: openMenu("roleMenu"),
          disabled: ({ items }) => !this.canEditRole({ items }),
        },
        [EDIT_JOB_TITLE]: {
          click: openMenu("labelMenuJobTitle"),
          disabled: !this.canEditUser,
        },
        [DELETE]: {
          click: this.onUsersDelete,
          disabled: this.isSetDeletedDisabled,
        },
      };
    },
    actionConfigs() {
      const { RE_INVITE, SET_INACTIVE, EDIT, EDIT_ROLE, DELETE } =
        this.$constant.ACTION_KEYS;

      const withMenuContext = (clickHandler) => (e) => {
        clickHandler({
          direction: e.hasSubMenu ? "right" : "top",
          parentMenuRef: e.hasSubMenu ? this.$refs.contextMenu : null,
          ...e,
        });
      };

      const actions = this.actions;

      const customActions = [
        {
          key: RE_INVITE,
          props: {
            disabled: actions[RE_INVITE].disabled,
          },
          on: {
            click: actions[RE_INVITE].click,
          },
        },
        {
          key: SET_INACTIVE,
          props: {
            disabled: actions[SET_INACTIVE].disabled,
          },
          on: {
            click: actions[SET_INACTIVE].click,
          },
        },
        {
          key: EDIT,
          props: {
            disabled: actions[EDIT].disabled,
          },
          on: {
            click: actions[EDIT].click,
          },
        },
        {
          key: EDIT_ROLE,
          props: {
            disabled: actions[EDIT_ROLE].disabled,
          },
          on: {
            click: withMenuContext(actions[EDIT_ROLE].click),
          },
        },
        {
          key: DELETE,
          props: {
            disabled: actions[DELETE].disabled,
          },
          on: {
            click: actions[DELETE].click,
          },
        },
      ];

      const config = this.$constant.generateActionConfig({
        primaryActions: customActions,
        secondaryActions: this.$constant.USER_ACTIONS,
      });

      return config;
    },
    canEditUser() {
      return this.permissionMixins_user.edit.can;
    },
    statsText() {
      return this.$tc(
        "common.informationHeader.users",
        this.statsCountNotDeletedOrInactive,
      );
    },
    defaultHeaders() {
      const { EDIT_JOB_TITLE, EDIT_ROLE } = this.$constant.ACTION_KEYS;

      return createTableHeaders([
        {
          preset: "SELECT",
        },
        {
          text: this.$t("users.name"),
          value: "fullName",
          disabled: true,
        },
        {
          text: this.$t("common.email"),
          value: "email",
          disabled: true,
        },
        {
          text: this.$t("users.phone"),
          value: "phone",
          sortable: false,
          disabled: true,
        },
        {
          text: this.$t("users.label.jobTitle.title"),
          value: "workTitleLabel.name",
          ...this.actions[EDIT_JOB_TITLE],
        },
        {
          text: this.$t("users.role"),
          value: "role.name",
          ...this.actions[EDIT_ROLE],
        },
        {
          text: this.$t("users.status"),
          value: "status",
          compact: true,
          disabled: true,
        },
        {
          text: this.$t("common.id"),
          value: "number",
          compact: true,
          disabled: true,
        },
        {
          preset: "MENU",
        },
      ]);
    },
  },
  methods: {
    canEditRole({ items }) {
      const hasSelectedDomainOwner = items.some(
        (item) => item?.role?.type === "DOMAIN_OWNER",
      );
      return this.canEditUser && !hasSelectedDomainOwner;
    },
    openEditMenu(e) {
      const { items, menuRef } = e;
      this.$refs[menuRef].open(e);
      this.itemsToEdit = items;
    },
    onContextMenuChange(isOpen) {
      if (!isOpen) {
        this.contextMenuActiveItem = null;
      }
    },
    isContextMenuActiveItem(item) {
      return this.contextMenuActiveItem?.id === item?.id;
    },
    isSetInactiveDisabled({ items }) {
      if (!this.canEditUser) return true;
      if (items.every((item) => this.isCurrentUser(item))) return false;
      if (items.every((item) => this.hasStatus(item, this.$constant.INACTIVE)))
        return true;

      return false;
    },
    isInviteDisabled({ items }) {
      if (!this.canEditUser) return true;
      if (items.every((item) => this.hasStatus(item, this.$constant.ACTIVE))) return true;

      return false;
    },
    isSetDeletedDisabled({ items }) {
      if (!this.canEditUser) return true;

      if (items.every((item) => this.isCurrentUser(item))) return false;
      if (items.every((item) => this.hasStatus(item, this.$constant.DELETED)))
        return true;

      return false;
    },
    isCurrentUser(user) {
      return user?.id === this.currentUserId;
    },
    getLabels() {
      this.$store.dispatch("label/getLabels", { type: this.labelType });
    },
    jobTitleLabel(item) {
      return this.labelsByType(this.labelType).find(
        (label) => label?.id === item?.workTitleLabel?.id,
      );
    },
    getUserStats() {
      this.$store.dispatch("userStats/getStats");
    },
    hasStatus(item, status) {
      if (!item) return false;
      return item.domainUser.status === status;
    },
    onMenuClick({ from, user }) {
      this.$refs.contextMenu.open({ from });
      this.$nextTick(() => {
        this.contextMenuActiveItem = user;
      });
    },
    onLabelChange({ items, label }) {
      const id = items[0].id;
      this.onDomainUserUpdate({
        id,
        labelId: label.id,
      });
    },
    onRoleChange({ role }) {
      const hasSelectedGuestUsers = this.itemsToEdit.some(
        (item) => item?.role?.type === "GUEST",
      );
      const roleIsNotGuest = role?.type !== "GUEST";

      if (hasSelectedGuestUsers && roleIsNotGuest) {
        // Calculate how many users are being upgraded from guest
        const guestUsersCount = this.itemsToEdit.filter(
          (item) => item?.role?.type === "GUEST",
        ).length;

        // Check if we need more licenses for the guest->regular conversion
        const licensesNeeded =
          this.planRestrictionsMixins_getAdditionalRequiredLicensesForInvite(
            guestUsersCount,
          );

        if (licensesNeeded > 0) {
          // Store the pending role change and show upgrade dialog
          this.dialog.increaseLicense.active = true;
          this.dialog.increaseLicense.requiredLicenses = guestUsersCount;
          this.dialog.increaseLicense.pendingAction = {
            type: "roleChange",
            payload: { role },
          };
          return;
        }
      }

      this.applyRoleChange(role);
    },
    applyRoleChange(role) {
      const ids = this.itemsToEdit.map((item) => item.id);

      this.onDomainUsersUpdate({
        ids,
        roleId: role.id,
      });
    },
    onUsersInvite({ items }) {
      const nonInvitedUsers = items.filter(
        (item) => item?.domainUser.status !== this.$constant.INVITED,
      );

      if (nonInvitedUsers.length > 0) {
        // Only need licenses for users that aren't already invited
        const licensesNeeded =
          this.planRestrictionsMixins_getAdditionalRequiredLicensesForInvite(
            nonInvitedUsers.length,
          );

        if (licensesNeeded > 0) {
          this.dialog.increaseLicense.active = true;
          this.dialog.increaseLicense.requiredLicenses = nonInvitedUsers.length;
          this.dialog.increaseLicense.pendingAction = {
            type: "invite",
            payload: { items },
          };
          return;
        }
      }

      this.applyInvite(items);
    },
    applyInvite(items) {
      const emails = items.map((item) => item.email);
      this.$emit("users:invite", { emails });
    },
    onLicenseUpgradeCompleted() {
      const { type, payload } = this.dialog.increaseLicense.pendingAction;

      switch (type) {
        case "roleChange":
          this.applyRoleChange(payload.role);
          break;
        case "invite":
          this.applyInvite(payload.items);
          break;
      }

      // Reset the dialog state
      this.dialog.increaseLicense.active = false;
      this.dialog.increaseLicense.requiredLicenses = 0;
      this.dialog.increaseLicense.pendingAction = null;
    },
    deleteUser({ item }) {
      this.onDomainUsersUpdate({
        ids: item.map((i) => i.id),
        status: this.$constant.DELETED,
      });
    },
    setInactiveUser({ items }) {
      this.onDomainUsersUpdate({
        ids: items.map((item) => item.id),
        status: this.$constant.INACTIVE,
      });
    },
    afterEdit() {
      this.$emit("afterEdit:completed");
    },
    onDomainUserUpdate(e) {
      this.$emit("user:onDomainUserUpdate", e);
    },
    onDomainUsersUpdate(e) {
      this.$emit("user:onDomainUsersUpdate", e);
    },
    onEditClick({ items }) {
      const item = items[0];
      this.dialog.editUser.active = true;
      this.dialog.editUser.item = item;
    },
    onUsersDelete({ items }) {
      this.dialog.deleteUser.active = true;
      this.dialog.deleteUser.item = items;
    },
  },
  mounted() {
    this.getUserStats();
    this.getLabels();
  },
};
</script>
