import store from "@/store";

export function setupShortcuts({ shortcutConfig, actionParams }) {
  const handleShortcuts = (event) => {
    // Ignore if user is typing in an input field, textarea, or contenteditable element
    if (
      event.target.matches(
        'input, textarea, select, [contenteditable="true"], .v-text-field input, .v-textarea textarea, .v-select input',
      )
    ) {
      return;
    }

    const ctrlCheck = shortcutConfig.isCtrlKey ? event.ctrlKey || event.metaKey : true;

    if (ctrlCheck && event.key.toLowerCase() === shortcutConfig.key) {
      event.preventDefault();
      shortcutConfig.action({ store, ...actionParams });
    }
  };

  window.addEventListener("keydown", handleShortcuts);
  return () => window.removeEventListener("keydown", handleShortcuts);
}
