var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"list_tr list_tr-height",class:_vm.rowStateClasses,on:{"contextmenu":_vm.dataTableItemMixins_onRightClick}},_vm._l((_vm.headers),function(header,index){return _c('AppDefaultTableCell',{key:index,attrs:{"header":header,"isSelected":_vm.isSelected,"select":_vm.select,"index":index,"item":_vm.form}},[(header.value == _vm.dataFields.STATUS)?[_c('AppCustomStatusIcon',{attrs:{"model":"form","statusId":_vm.statusId,"loading":_vm.form?.isUpdatingStatus,"disabled":_vm.form?.isUpdatingStatus}})]:_vm._e(),(header.value === _vm.dataFields.NAME)?[_c('div',{staticClass:"table-cell-content__breakable-long-text"},[_vm._v(" "+_vm._s(_vm.form.name)+" ")])]:_vm._e(),(header.value === _vm.dataFields.SIGNATURE_STATUS)?[_c('AppFormSignatureSessionStatusChip',{attrs:{"signatureSession":_vm.form.signatureSession},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('signature:click', { form: _vm.form })}}})]:_vm._e(),(header.value === _vm.dataFields.PROGRESS)?[_c('AppFormProgressionChip',{attrs:{"progress":_vm.form.progress}})]:_vm._e(),(header.value === _vm.dataFields.OWNER)?[(_vm.form.owner)?[_c('AppUserAvatar',{attrs:{"user":_vm.form.owner,"show-name":"","truncate":20}})]:_vm._e()]:_vm._e(),(header.value === _vm.dataFields.PROJECT)?[(_vm.form?.project?.id)?_c('div',{staticClass:"table-cell-content__breakable-long-text"},[_c('AppActionText',{attrs:{"to":{
            name: _vm.$routeNames.PROJECT.VIEW,
            params: { projectId: _vm.form?.project?.id },
          }}},[_vm._v(" "+_vm._s(_vm.form.project.name)+" ")])],1):_vm._e()]:_vm._e(),(header.value === _vm.dataFields.TASK)?[(_vm.form?.task?.id)?_c('div',{staticClass:"table-cell-content__breakable-long-text"},[_c('AppActionText',{attrs:{"to":{
            name: _vm.$routeNames.TASK_VIEW.ROOT,
            params: { taskId: _vm.form?.task?.id },
          }}},[_vm._v(" "+_vm._s(_vm.taskName)+" ")])],1):_vm._e()]:_vm._e(),(header.value === _vm.dataFields.CATEGORY)?[(_vm.form.category)?_c('AppColorChip',{attrs:{"text":_vm.$te(`formManagement.category.${_vm.form.category.name}`)
            ? _vm.$t(`formManagement.category.${_vm.form.category.name}`)
            : _vm.form.category.name,"color":"info--text"}}):_vm._e()]:_vm._e(),(header.value === _vm.dataFields.DUE_DATE)?[(_vm.form.dueDate)?[_c('div',[_vm._v(_vm._s(_vm.formatDueDate(_vm.form.dueDate)))])]:_vm._e()]:_vm._e(),(header.value === _vm.dataFields.MENU)?[_c('v-btn',{ref:"ellipsis",refInFor:true,attrs:{"icon":""},on:{"click":_vm.dataTableItemMixins_onEllipsisClick}},[_c('v-icon',[_vm._v(_vm._s(_vm.$icons.REGULAR.ACTION.MENU))])],1)]:_vm._e()],2)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }