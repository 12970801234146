export const isEmptyValue = (value) => {
  // Handle non-collections with special cases
  if (!_.isObject(value) && typeof value !== "string") {
    return value === null || value === undefined;
  }

  // For arrays, check if any elements are non-empty
  if (_.isArray(value)) {
    return _.every(value, isEmptyValue);
  }

  // For other collections (objects, strings), use isEmpty
  return _.isEmpty(value);
};
