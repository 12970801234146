import router from "@/router";
import _ from "lodash";
import store from "@/store";

export function formatDataTableOptionsToQuery(options) {
  if (!options) return {};
  const detachedCopy = JSON.parse(JSON.stringify(options));

  const offset = (options.page - 1) * options.itemsPerPage;
  const limit = options.itemsPerPage;

  const fieldsToDelete = [
    "multiSort",
    "mustSort",
    "page",
    "itemsPerPage",
    "pageCount",
    "stickyCols",
    "headers",
  ];

  fieldsToDelete.forEach((field) => delete detachedCopy[field]);

  const query = {
    ...detachedCopy,
    ...(!!limit ? { limit } : {}),
    offset: !!offset ? offset : 0,
  };

  return query;
}

export function formatViewQueryParams({ type } = {}) {
  // Start with basic params
  let queryParams = {};

  const projectId = router.currentRoute.params.projectId;
  // Add projectId if provided
  if (projectId) {
    queryParams.projectIds = [projectId];
  }

  const configParams = store.getters["resourceViews/getViewConfigAsQueryParams"];
  const unsavedConfig = store.getters["resourceViews/getUnsavedConfig"];

  //Fallback to 1, because sometimes when moving quickly between pages,
  //the query param can be undefined
  const currentPage = router.currentRoute.query.page || 1;

  if (_.isEmpty(unsavedConfig)) {
    const resourceViewId = router.currentRoute.query.resourceViewId;
    if (resourceViewId) {
      queryParams.resourceViewId = resourceViewId;
    }

    if (type === "TABLE") {
      queryParams.page = currentPage;
    }
  } else {
    if (type === "TABLE") {
      const itemsPerPage = unsavedConfig.itemsPerPage || 25;
      const parsedPage = parseInt(currentPage);

      queryParams.limit = itemsPerPage;
      queryParams.offset = (parsedPage - 1) * itemsPerPage;
    }
    //Otherwise use all config params
    Object.assign(queryParams, configParams);
  }

  return queryParams;
}
