<template>
  <BaseDocumentTemplatePickerDialog
    v-bind="$props"
    :title="$t('fileManagement.localDocumentTemplatePicker.title')"
    :templates="templates"
    :isLoading="isLoading"
    :isSaving="isSaving"
    :categories="categories"
    :isCategoriesLoading="isCategoriesLoading"
    v-model="localDialog"
    show-delete
    show-download
    show-category
    @add-template="onAddTemplateClick"
    @init="onInit"
    @filter-change="onFilterChange"
    @download-item="onDownloadItem"
    @delete-item="onDeleteItem"
  />
</template>

<script>
import BaseDocumentTemplatePickerDialog from "./BaseDocumentTemplatePickerDialog.vue";
import { mapState } from "vuex";

export default {
  components: {
    BaseDocumentTemplatePickerDialog,
  },
  props: {
    dialog: Boolean,
    group: String,
    projectId: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    ...mapState("localDocumentTemplateLibrary", {
      templates: (state) => state.templates,
      isLoading: (state) => state.isLoading,
      isSaving: (state) => state.isSaving,
    }),
    ...mapState("documentTemplateLibraryCategory", {
      categories: (state) => state.categories,
      isCategoriesLoading: (state) => state.isLoading,
    }),
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  methods: {
    onInit() {
      this.getCategories();
      this.getTemplates();
    },
    getCategories() {
      this.$store.dispatch("documentTemplateLibraryCategory/getCategories", this.group);
    },
    getTemplates(args = {}) {
      this.$store.dispatch("localDocumentTemplateLibrary/getTemplates", {
        ...args,
        group: this.group,
        projectId: this.projectId,
      });
    },
    onFilterChange(filter) {
      this.getTemplates(filter);
    },
    onDownloadItem(item) {
      this.$store.dispatch("localDocumentTemplateLibrary/downloadItem", item);
    },
    onDeleteItem({ item, onSuccess }) {
      this.$store.dispatch("localDocumentTemplateLibrary/deleteItem", {
        item,
        onSuccess,
      });
    },
    async onAddTemplateClick(selectedTemplates) {
      const selectedIds = selectedTemplates.map((x) => x.id);
      const body = {
        templates: selectedIds,
        group: this.group,
        projectId: this.projectId,
      };
      await this.$store.dispatch("localDocumentTemplateLibrary/saveTemplates", { body });
      this.localDialog = false;
    },
  },
};
</script>
