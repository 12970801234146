import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  workspaces: [],
  isLoading: false,
  filter: null,
});

const getters = {};

const actions = {
  async getWorkspaces({ state, commit }, filter) {
    commit("loading", true);
    commit("setFilter", filter);

    return axios
      .get(`${apiUrl}domain`, {
        params: { ...state.filter },
      })
      .then(({ data }) => {
        const domains = data?.domains;
        commit("setWorkspaces", domains);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
  async acceptInvitation({ state, dispatch }, { id }) {
    return axios
      .put(`${apiUrl}auth/invitation/accept/${id}`)
      .then(({ data }) => {
        dispatch("getWorkspaces", state.filter);
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async rejectInvitation({ state, dispatch }, { id }) {
    return axios
      .put(`${apiUrl}auth/invitation/reject/${id}`)
      .then(({ data }) => {
        dispatch("getWorkspaces", state.filter);
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
};

const mutations = {
  setWorkspaces(state, workspaces) {
    state.workspaces = workspaces;
  },
  setFilter(state, filter) {
    state.filter = filter;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const workspaces = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
