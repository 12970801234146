<template>
  <AppGroupByBtn2
    :tableModel="tableModel"
    :menuItems="menuItems"
    :isNoGroupSelected="!groupByMixins2_groupBy"
  />
</template>

<script>
import { groupByMixins2 } from "@/helpers/mixins/groupByMixins2";

export default {
  mixins: [groupByMixins2],
  props: {
    tableModel: String,
  },
  computed: {
    menuItems() {
      return [
        {
          text: this.$options.filters.capitalize(this.$t("common.noGroupText")),
          icon: this.$icons.REGULAR.ACTION.EMPTY,
          isSelected: !this.groupByMixins2_groupBy,
          click: () => this.groupByMixins2_setGroupBy(undefined),
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.status")),
          icon: this.$icons.REGULAR.COMMON.SQUARE,
          isSelected: this.groupByMixins2_isGroupSelected(this.dataFields.STATUS),
          click: () => this.groupByMixins2_setGroupBy(this.dataFields.STATUS),
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("formManagement.list.responsible"),
          ),
          icon: this.$icons.REGULAR.ROUTE.USER,
          isSelected: this.groupByMixins2_isGroupSelected(this.dataFields.OWNER),
          click: () => this.groupByMixins2_setGroupBy(this.dataFields.OWNER),
        },
        ...(this.dataFields.PROJECT
          ? [
              {
                text: this.$t("common.project"),
                icon: this.$icons.REGULAR.ROUTE.PROJECT,
                isSelected: this.groupByMixins2_isGroupSelected(this.dataFields.PROJECT),
                click: () => this.groupByMixins2_setGroupBy(this.dataFields.PROJECT),
              },
            ]
          : []),
        {
          text: this.$options.filters.capitalize(this.$t("common.task")),
          icon: this.$icons.REGULAR.ROUTE.TASK,
          isSelected: this.groupByMixins2_isGroupSelected(this.dataFields.TASK),
          click: () => this.groupByMixins2_setGroupBy(this.dataFields.TASK),
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.category")),
          icon: this.$icons.REGULAR.ACTION.CATEGORY,
          isSelected: this.groupByMixins2_isGroupSelected(this.dataFields.CATEGORY),
          click: () => this.groupByMixins2_setGroupBy(this.dataFields.CATEGORY),
        },
        {
          text: this.$options.filters.capitalize(this.$t("taskManagement.list.dueDate")),
          icon: this.$icons.REGULAR.ACTION.DUE_DATE,
          isSelected: this.groupByMixins2_isGroupSelected(this.dataFields.DUE_DATE),
          click: () => this.groupByMixins2_setGroupBy(this.dataFields.DUE_DATE),
        },
      ];
    },
  },
};
</script>
