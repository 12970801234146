import { shouldUpdateItems } from "@/helpers/util";
import { formatDataTableOptionsToQuery } from "@/helpers/util";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
const integrationApiUrl = process.env.VUE_APP_INTEGRATION_API_URL;
const CancelToken = axios.CancelToken;
let getProductUsagesCancel;

const state = () => ({
  products: [],
  isLoading: false,
  isUpdating: false,
  count: null,
});

const actions = {
  async getProducts({ commit }, { filter, modelId, model, dataTableOptions }) {
    if (getProductUsagesCancel) getProductUsagesCancel();
    commit("loading", true);

    const optionsQuery = formatDataTableOptionsToQuery(dataTableOptions);
    const params = { modelId, model, ...optionsQuery, ...filter };

    return axios
      .get(`${apiUrl}product-usage`, {
        params,
        cancelToken: new CancelToken(function executor(c) {
          getProductUsagesCancel = c;
        }),
      })
      .then(({ data }) => {
        const { productUsages, count } = data;

        const shouldUpdate = shouldUpdateItems(
          count,
          dataTableOptions?.page,
          dataTableOptions?.itemsPerPage,
        );

        if (shouldUpdate) {
          commit("setProducts", productUsages);
        }
        commit("setCount", count);
        commit("loading", false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          commit("loading", false);
          throw new Error(error);
        }
      });
  },
  async createProducts({ commit, state }, { products, modelId, model }) {
    const mappedProducts = products.map((product) => {
      const mappedProduct = {
        productId: product.id,
        unit: product.unit,
        name: product.name,
        addedAt: product.addedAt,
        amount: product.amount,
        unitPrice: product.unitPrice,
        comment: product.comment,
      };
      return mappedProduct;
    });
    const body = {
      items: mappedProducts,
      model,
      modelId,
    };
    return axios
      .post(`${apiUrl}product-usage`, body)
      .then(({ data }) => {
        const { productUsages } = data;
        commit("addProducts", productUsages);
        const countAdded = productUsages?.length || 0;
        commit("setCount", state.count + countAdded);
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async updateProducts({ commit }, { products }) {
    commit("setIsUpdating", true);
    const mappedProducts = products.map((product) => {
      const mappedProduct = { ...product, unit: product.unit };
      return mappedProduct;
    });
    const body = { items: mappedProducts };
    return axios
      .put(`${apiUrl}product-usage`, body)
      .then(({ data }) => {
        const { productUsages } = data;
        for (const product of productUsages) {
          commit("updateProduct", product);
        }
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("setIsUpdating", false);
      });
  },
  async sendToAccounting({ commit }, { body } = {}) {
    return axios
      .post(`${integrationApiUrl}accounting/send-product`, body)
      .then(({ data }) => {
        for (const product of data) {
          commit("updateProduct", product);
        }
        return data;
      })
      .catch((error) => {
        const message = error.response.data.message;
        throw new Error(message);
      });
  },
  async deleteProducts({ commit }, { ids }) {
    commit("loading", true);
    const body = { data: { ids } };
    return axios
      .delete(`${apiUrl}product-usage`, body)
      .then(({ data }) => {
        const { productUsages } = data;
        for (const product of productUsages) {
          commit("removeProduct", product);
        }
        const countRemoved = productUsages?.length || 0;
        commit("setCount", state.count - countRemoved);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", false);
      });
  },
  resetState({ commit }) {
    commit("setProducts", []);
  },
};

const mutations = {
  setCount(state, count) {
    state.count = count;
  },
  setProducts(state, products) {
    state.products = products;
  },
  updateProduct(state, product) {
    const index = state.products.findIndex((oldProduct) => oldProduct.id === product.id);
    if (index !== -1) {
      state.products.splice(index, 1, product);
    }
  },
  addProducts(state, products) {
    for (const product of products) {
      state.products.unshift(product);
    }
  },
  removeProduct(state, product) {
    const index = state.products.findIndex((oldProduct) => oldProduct.id === product.id);
    if (index !== -1) {
      state.products.splice(index, 1);
    }
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setIsUpdating(state, isUpdating) {
    state.isUpdating = isUpdating;
  },
  setFilter(state, filter) {
    state.filter = filter;
  },
};

export const productUsage = {
  namespaced: true,
  state,
  actions,
  mutations,
};
