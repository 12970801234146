<template>
  <div
    v-if="!isLoading"
    :class="labelClass"
    :style="labelStyle"
    @click="togglePublished"
  >
    {{ labelText }}
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
    },
    template: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    labelClass() {
      return `p-absolute text-body-2 ${
        this.template?.isPublished
          ? "success-super-light--background border-a--success--thin success--text"
          : "input-background--background border-a"
      } ${
        this.disabled ? "v-card--disabled" : ""
      } pa-2 rounded d-flex align-center justify-center cursor-pointer`;
    },
    labelStyle() {
      return "margin: auto; top: -20px; width: 120px; height: 40px; left:0; right:0; font-weight: 500;";
    },
    labelText() {
      return this.$t(
        this.template?.isPublished ? "common.published" : "common.unpublished",
      ).toUpperCase();
    },
  },
  methods: {
    togglePublished() {
      this.$emit("template:update", { isPublished: !this.template?.isPublished });
    },
  },
};
</script>
