import axios from "axios";
import _ from "lodash";
import { isEmptyValue } from "@/helpers/util";
const apiUrl = process.env.VUE_APP_API_URL;
const CancelToken = axios.CancelToken;
let getResourceViewsCancel;

const state = () => ({
  resourceViews: [],
  // Working copy of the current view config
  unsavedConfig: null,
  // Scoped/unsaveable filters for the current view
  scopedFilters: {},
  //This is automatically set in a router.afterEach to get a reactive currentResourceView
  currentResourceViewId: null,
  //Array of all saveable filters for the current resource
  allFilters: [],
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
});

const getters = {
  getResourceViewById: (state) => (resourceViewId) => {
    return state.resourceViews.find((view) => view.id === resourceViewId) || null;
  },
  getCurrentResourceView: (state, getters) => {
    const resourceViewId = state.currentResourceViewId;
    return resourceViewId ? getters.getResourceViewById(resourceViewId) : null;
  },
  getCurrentResourceViewConfig: (state, getters) => {
    return state.unsavedConfig || {};
  },
  getSaveableFiltersForCurrentView: (state) => {
    if (!state.unsavedConfig || !state.unsavedConfig.filters) return {};

    const result = {};
    Object.keys(state.unsavedConfig.filters).forEach((key) => {
      if (state.allFilters.includes(key)) {
        result[key] = state.unsavedConfig.filters[key];
      }
    });
    return result;
  },
  hasResourceViews: (state) => {
    return state.resourceViews.length > 0;
  },
  hasUnsavedChanges: (state, getters) => {
    if (!state.unsavedConfig) return false;

    const originalConfig = getters.getOriginalResourceViewConfig;

    // Quick reference check first (if it's the same object, definitely no changes)
    if (state.unsavedConfig === originalConfig) return false;

    // Then do the deep comparison
    return !_.isEqual(state.unsavedConfig, originalConfig);
  },
  getUnsavedConfig: (state) => {
    return state.unsavedConfig;
  },
  getOriginalResourceViewConfig: (state, getters) => {
    return getters.getCurrentResourceView?.config || {};
  },
  getViewConfigAsQueryParams: (state, getters, rootState, rootGetters) => {
    const config = getters.getCurrentResourceViewConfig;

    if (!config) return {};

    let params = {};

    // Format filters for API
    if (config.filters) {
      Object.entries(config.filters).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          params[key] = value;
        }
      });
    }

    // Add scoped filters to params
    Object.assign(params, state.scopedFilters);

    // Format sort
    if (config.sort && config.sort.length) {
      params.sortBy = config.sort.map((sort) => sort.by);
      params.sortDesc = config.sort.map((sort) => sort.desc);
    }

    // Format grouping
    if (config.group && config.group.length) {
      params.groupBy = config.group.map((group) => group.by);
      params.groupDesc = config.group.map((group) => group.desc);
    }

    return params;
  },
};

const actions = {
  async getResourceViews({ commit }, { model }) {
    if (getResourceViewsCancel) {
      getResourceViewsCancel();
    }
    commit("loading", true);

    return axios
      .get(`${apiUrl}resource-view`, {
        params: { model },
        cancelToken: new CancelToken(function executor(c) {
          getResourceViewsCancel = c;
        }),
      })
      .then(({ data }) => {
        const { resourceViews, allFilters } = data;

        commit("setResourceViews", resourceViews);
        commit("setAllFilters", allFilters);
        return resourceViews;
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          throw new Error(error);
        }
      })
      .finally(() => {
        commit("loading", false);
      });
  },
  async createResourceView({ commit, state }, { model, name, type }) {
    commit("creating", true);

    return axios
      .post(`${apiUrl}resource-view`, { model, name, type })
      .then(({ data }) => {
        const resourceView = data?.resourceView;
        if (resourceView) {
          commit("addResourceView", resourceView);

          // If this is going to be the current view, initialize unsavedConfig
          if (!state.currentResourceViewId) {
            commit("setUnsavedConfig", _.cloneDeep(resourceView.config || {}));
          }

          return resourceView;
        }
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("creating", false);
      });
  },

  async updateResourceView({ commit, getters }, { resourceViewId, updates }) {
    commit("updating", true);

    return axios
      .put(`${apiUrl}resource-view/${resourceViewId}`, updates)
      .then(({ data }) => {
        const resourceView = data?.resourceView;
        if (resourceView) {
          commit("updateResourceView", resourceView);
          return resourceView;
        }
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("updating", false);
      });
  },

  async deleteResourceView({ commit, getters }, { resourceViewId }) {
    commit("deleting", true);

    return axios
      .delete(`${apiUrl}resource-view/${resourceViewId}`)
      .then(({ data }) => {
        const resourceView = data?.resourceView;
        if (resourceView) {
          commit("removeResourceView", resourceView.id);
          return true;
        }
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("deleting", false);
      });
  },

  async reorderResourceViews({ commit, state }, { model, ids }) {
    const oldOrder = [...state.resourceViews];

    commit("reorderResourceViews", ids);

    return axios
      .patch(`${apiUrl}resource-view/reorder`, { ids })
      .then(() => {
        return true;
      })
      .catch((error) => {
        commit("setResourceViews", oldOrder);
        throw new Error(error);
      });
  },

  async saveResourceViewConfig({ commit, state }, { resourceViewId }) {
    commit("updating", true);

    // Always use the unsavedConfig for saving
    const config = state.unsavedConfig;

    if (!config) {
      commit("updating", false);
      return Promise.reject(new Error("No configuration to save"));
    }

    return axios
      .put(`${apiUrl}resource-view/${resourceViewId}`, { config })
      .then(({ data }) => {
        const resourceView = data?.resourceView;
        if (resourceView) {
          commit("updateResourceView", resourceView);

          // Update unsavedConfig to match the saved state
          if (resourceViewId === state.currentResourceViewId) {
            commit("setUnsavedConfig", _.cloneDeep(resourceView.config || {}));
          }

          return resourceView;
        }
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("updating", false);
      });
  },
  updateScopedFilter({ commit, state }, { key, value }) {
    // Create a new object with all existing filters plus the updated one
    const updatedFilters = {
      ...state.scopedFilters,
      [key]: value,
    };

    commit("setScopedFilters", updatedFilters);
  },
  async getUnsavedConfig({ commit, getters }, { resourceViewId }) {
    commit("loading", true);

    const resourceView = getters.getResourceViewById(resourceViewId);
    if (!resourceView) {
      commit("loading", false);
      // Return empty config instead of throwing error
      return {};
    }

    // Use _.cloneDeep instead of JSON.parse/stringify
    commit("setUnsavedConfig", _.cloneDeep(resourceView.config || {}));

    commit("loading", false);
    return resourceView.config;
  },
  async updateUnsavedConfig({ commit, state, getters }, { updates }) {
    // If unsavedConfig exists, use it directly
    // Otherwise, create a deep clone of the current view config
    const currentConfig =
      state.unsavedConfig || _.cloneDeep(getters.getCurrentResourceViewConfig || {});

    // Create a new deep clone of the current config
    let newConfig = _.cloneDeep(currentConfig);

    // For each top-level key in updates
    Object.keys(updates).forEach((key) => {
      // Replace the property with a deep clone of the update
      const updatedValue = _.cloneDeep(updates[key]);

      // Clean up empty values in the object
      Object.keys(updatedValue).forEach((nestedKey) => {
        const value = updatedValue[nestedKey];

        if (isEmptyValue(value)) {
          delete updatedValue[nestedKey];
        }
      });

      // Set the cleaned value
      newConfig[key] = updatedValue;
    });

    // Set the updated config
    commit("setUnsavedConfig", newConfig);
  },

  discardUnsavedChanges({ commit, getters }) {
    // Reset to the original config from the current view
    const originalConfig = getters.getOriginalResourceViewConfig;
    commit("setUnsavedConfig", originalConfig ? _.cloneDeep(originalConfig) : null);
  },
};

const mutations = {
  setResourceViews(state, resourceViews) {
    state.resourceViews = resourceViews;
  },
  setAllFilters(state, allFilters) {
    state.allFilters = allFilters;
  },
  addResourceView(state, resourceView) {
    state.resourceViews.push(resourceView);
  },
  updateResourceView(state, resourceView) {
    const index = state.resourceViews.findIndex((v) => v.id === resourceView.id);
    if (index !== -1) {
      state.resourceViews.splice(index, 1, resourceView);
    }
  },
  removeResourceView(state, resourceViewId) {
    state.resourceViews = state.resourceViews.filter((v) => v.id !== resourceViewId);
  },
  reorderResourceViews(state, ids) {
    // Create a new array with the resource views in the specified order
    const orderedResourceViews = [];
    ids.forEach((id) => {
      const resourceView = state.resourceViews.find((v) => v.id === id);
      if (resourceView) orderedResourceViews.push(resourceView);
    });

    // Add any resource views that weren't in the ids array
    state.resourceViews.forEach((resourceView) => {
      if (!ids.includes(resourceView.id)) {
        orderedResourceViews.push(resourceView);
      }
    });

    state.resourceViews = orderedResourceViews;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  creating(state, isCreating) {
    state.isCreating = isCreating;
  },
  updating(state, isUpdating) {
    state.isUpdating = isUpdating;
  },
  deleting(state, isDeleting) {
    state.isDeleting = isDeleting;
  },
  //Triggered by router.afterEach to get a reactive currentResourceViewId in the state based on the route query
  setCurrentResourceViewId(state, resourceViewId) {
    if (resourceViewId) {
      state.currentResourceViewId = resourceViewId;
    }
  },
  setScopedFilters(state, filters) {
    state.scopedFilters = filters;
  },
  setUnsavedConfig(state, config) {
    state.unsavedConfig = config;
  },
};

export const resourceViews = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
