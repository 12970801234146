<template>
  <Table
    :item="item"
    :readonly="false"
    @item:update="$emit('item:update', $event)"
  />
</template>

<script>
import Table from "@/components/form/common/answerTypes/Table.vue";

export default {
  components: { Table },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
