import { VALID_LANDING_PAGE_ROUTE_NAMES_AND_ICONS } from "../constants";
import { ROUTE_NAMES } from "../routeNames";
import { permissionHelpers } from "./permissions";

export const currentRootRouteName = (landingPage) => {
  return isValidLandingPageRouteName({ routeName: landingPage })
    ? landingPage
    : getFallbackRouteName();
};

const getFallbackRouteName = () => {
  const validRoutes = VALID_LANDING_PAGE_ROUTE_NAMES_AND_ICONS.filter((route) => {
    // Only consider routes with weight
    if (!route.weight) return false;

    // Check if user has permission
    const hasPermissionToView = permissionHelpers.canViewRoute(route.name);
    return hasPermissionToView;
  }).sort((a, b) => a.weight - b.weight);

  return validRoutes[0]?.name || "forbidden";
};

export const isValidLandingPageRouteName = ({ routeName }) => {
  if (!routeName) return false;

  let validRouteNames = Object.values(VALID_LANDING_PAGE_ROUTE_NAMES_AND_ICONS).map(
    (f) => f.name,
  );

  const hasPermissionToView = permissionHelpers.canViewRoute(routeName);

  if (!validRouteNames.includes(routeName) || !hasPermissionToView) return false;

  return true;
};
