import {
  AUTOGEAR,
  CUSTOMER_AND_SUPPLIER_ARE_DIFFERENT_TABLES_INTEGRATIONS,
  EDITING_CLIENT_ON_CONTACT_NOT_ALLOWED_INTEGRATIONS,
  IMPORT_TYPES,
  INTEGRATION_NAMES_SUPPORTING_IMPORT_TYPES,
  TIME_ENTRY_INTEGRATION_NAMES,
  TIME_ENTRY_PRODUCT_REQUIRED_INTEGRATIONS,
  SHOW_NUMBER_ON_PROJECT_SPECIFIC_HOUR_TYPES_INTEGRATIONS,
} from "@/helpers/constants";
import axios from "axios";
const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

const state = () => ({
  integrations: [],
  isLoading: false,
  isRefreshing: false,
  hasFetched: false,
});

const getters = {
  getIntegrationByName: (state) => (name) => {
    return state.integrations.find((i) => i === name);
  },
  isIntegrationEnabled: (state, getters) => (name) => {
    const integration = getters.getIntegrationByName(name);
    return integration ? true : false;
  },
  isAutogearIntegrationEnabled: (state, getters) => {
    return getters.isIntegrationEnabled(AUTOGEAR);
  },
  isTimeEntryProductRequiredIntegrationEnabled: (state) => {
    return (
      state.integrations.filter((integration) => {
        return TIME_ENTRY_PRODUCT_REQUIRED_INTEGRATIONS.includes(integration);
      })?.length > 0
    );
  },
  hasActiveCustomerAndSupplierSeparateTablesIntegration: (state) => {
    return CUSTOMER_AND_SUPPLIER_ARE_DIFFERENT_TABLES_INTEGRATIONS.some((integration) => {
      return state.integrations.includes(integration);
    });
  },
  hasEditingClientOnContactNotAllowedIntegrations: (state) => {
    return EDITING_CLIENT_ON_CONTACT_NOT_ALLOWED_INTEGRATIONS.some((integration) => {
      return state.integrations.includes(integration);
    });
  },
  hasUserImportIntegrationEnabled: (state) => {
    if (!Array.isArray(state.integrations)) return false;
    let value = false;
    for (let name of INTEGRATION_NAMES_SUPPORTING_IMPORT_TYPES[IMPORT_TYPES.USER]) {
      const integration = state.integrations.find((i) => i === name);
      if (integration) {
        value = true;
      }
    }

    return value;
  },
  hasClientImportIntegrationEnabled: (state) => {
    if (!Array.isArray(state.integrations)) return false;
    let value = false;
    for (let name of INTEGRATION_NAMES_SUPPORTING_IMPORT_TYPES[IMPORT_TYPES.CLIENT]) {
      const integration = state.integrations.find((i) => i === name);
      if (integration) {
        value = true;
      }
    }

    return value;
  },
  hasContactImportIntegrationEnabled: (state) => {
    if (!Array.isArray(state.integrations)) return false;
    let value = false;
    for (let name of INTEGRATION_NAMES_SUPPORTING_IMPORT_TYPES[IMPORT_TYPES.CONTACT]) {
      const integration = state.integrations.find((i) => i === name);
      if (integration) {
        value = true;
      }
    }

    return value;
  },
  hasProjectImportIntegrationEnabled: (state) => {
    if (!Array.isArray(state.integrations)) return false;
    let value = false;
    for (let name of INTEGRATION_NAMES_SUPPORTING_IMPORT_TYPES[IMPORT_TYPES.PROJECT]) {
      const integration = state.integrations.find((i) => i === name);
      if (integration) {
        value = true;
      }
    }

    return value;
  },
  hasProductIntegrationEnabled: (state) => {
    if (!Array.isArray(state.integrations)) return false;
    let value = false;
    for (let name of INTEGRATION_NAMES_SUPPORTING_IMPORT_TYPES[IMPORT_TYPES.PRODUCT]) {
      const integration = state.integrations.find((i) => i === name);
      if (integration) {
        value = true;
      }
    }

    return value;
  },
  hasTimeEntriesIntegrationEnabled: (state) => {
    if (!Array.isArray(state.integrations)) return false;
    let value = false;
    for (let name of TIME_ENTRY_INTEGRATION_NAMES) {
      const integration = state.integrations.find((i) => i === name);
      if (integration) {
        value = true;
      }
    }
    return value;
  },
  hasShowNumberOnProjectSpecificHourTypesIntegrationEnabled: (state) => {
    return SHOW_NUMBER_ON_PROJECT_SPECIFIC_HOUR_TYPES_INTEGRATIONS.some((integration) => {
      return state.integrations.includes(integration);
    });
  },
};

const actions = {
  async getActiveIntegrations({ commit, state }) {
    if (!state.hasFetched) {
      commit("setLoading", true);
    } else {
      commit("setRefreshing", true);
    }
    return axios
      .get(`${apiUrl}active-integration`)
      .then(({ data }) => {
        const integrations = data.integrations || [];
        commit("setIntegrations", integrations);
        commit("setHasFetched", true);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("setRefreshing", false);
        commit("setLoading", false);
      });
  },
};

const mutations = {
  setIntegrations(state, integrations) {
    state.integrations = integrations;
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setRefreshing(state, isRefreshing) {
    state.isRefreshing = isRefreshing;
  },
  setHasFetched(state, hasFetched) {
    state.hasFetched = hasFetched;
  },
};

export const activeIntegrations = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
