import { RESOURCE_ROUTE_PERMISSIONS, ROUTE_NAMES } from "@/helpers/routeNames";
import multiguard from "../multiguard";
import { authGuard, userGuard } from "@/auth";
import { HELP_CATEGORIES } from "@/helpers/constants/help";

const { ROOT, COMPLETION, PREVIEW } = ROUTE_NAMES.FORM_VIEW;

export const formViewRoutes = [
  {
    path: "/form-view/:formId",
    name: ROOT,
    meta: {
      permission: RESOURCE_ROUTE_PERMISSIONS.FORM,
      helpCategory: HELP_CATEGORIES.FORM,
    },
    redirect: { name: COMPLETION.DETAILS },
    beforeEnter: multiguard([authGuard, userGuard]),
    component: () =>
      import(/* webpackChunkName: "formView" */ "../../pages/FormManagement/FormView"),
    children: [
      {
        path: "edit",
        name: COMPLETION.ROOT,
        meta: { permission: RESOURCE_ROUTE_PERMISSIONS.FORM },
        component: () =>
          import(
            /* webpackChunkName: "formView" */ "../../pages/FormManagement/FormView/FormCompletionPage"
          ),
        children: [
          {
            path: "details",
            name: COMPLETION.DETAILS,
            meta: { permission: RESOURCE_ROUTE_PERMISSIONS.FORM },
            component: () =>
              import(
                /* webpackChunkName: "formView" */ "../../pages/FormManagement/MenuPages/DetailsPage"
              ),
          },
          {
            path: "comments",
            name: COMPLETION.COMMENTS,
            meta: { permission: RESOURCE_ROUTE_PERMISSIONS.FORM.COMMENT },
            component: () =>
              import(
                /* webpackChunkName: "formView" */ "../../pages/FormManagement/MenuPages/CommentsPage"
              ),
          },
          {
            path: "storage/:folderId",
            name: COMPLETION.STORAGE,
            meta: { permission: RESOURCE_ROUTE_PERMISSIONS.FORM },
            component: () =>
              import(
                /* webpackChunkName: "formView" */ "../../pages/FormManagement/MenuPages/StoragePage"
              ),
          },
          {
            path: "history",
            name: COMPLETION.HISTORY,
            meta: { permission: RESOURCE_ROUTE_PERMISSIONS.FORM },
            component: () =>
              import(
                /* webpackChunkName: "formView" */ "../../pages/FormManagement/MenuPages/HistoryPage"
              ),
          },
          {
            path: "signature",
            name: COMPLETION.SIGNATURE,
            meta: { permission: RESOURCE_ROUTE_PERMISSIONS.FORM },
            component: () =>
              import(
                /* webpackChunkName: "formView" */ "../../pages/FormManagement/MenuPages/SignaturePage"
              ),
          },
        ],
      },
      {
        path: "preview",
        name: PREVIEW.ROOT,
        meta: { permission: RESOURCE_ROUTE_PERMISSIONS.FORM },
        component: () =>
          import(
            /* webpackChunkName: "formView" */ "../../pages/FormManagement/FormView/FormPreviewPage"
          ),
        children: [
          {
            path: "details",
            name: PREVIEW.DETAILS,
            meta: { permission: RESOURCE_ROUTE_PERMISSIONS.FORM },
            component: () =>
              import(
                /* webpackChunkName: "formView" */ "../../pages/FormManagement/MenuPages/DetailsPage"
              ),
          },
          {
            path: "comments",
            name: PREVIEW.COMMENTS,
            meta: { permission: RESOURCE_ROUTE_PERMISSIONS.FORM.COMMENT },
            component: () =>
              import(
                /* webpackChunkName: "formView" */ "../../pages/FormManagement/MenuPages/CommentsPage"
              ),
          },
          {
            path: "storage/:folderId",
            name: PREVIEW.STORAGE,
            meta: { permission: RESOURCE_ROUTE_PERMISSIONS.FORM },
            component: () =>
              import(
                /* webpackChunkName: "formView" */ "../../pages/FormManagement/MenuPages/StoragePage"
              ),
          },
          {
            path: "history",
            name: PREVIEW.HISTORY,
            meta: { permission: RESOURCE_ROUTE_PERMISSIONS.FORM },
            component: () =>
              import(
                /* webpackChunkName: "formView" */ "../../pages/FormManagement/MenuPages/HistoryPage"
              ),
          },
          {
            path: "signature",
            name: PREVIEW.SIGNATURE,
            meta: { permission: RESOURCE_ROUTE_PERMISSIONS.FORM },
            component: () =>
              import(
                /* webpackChunkName: "formView" */ "../../pages/FormManagement/MenuPages/SignaturePage"
              ),
          },
        ],
      },
    ],
  },
];
