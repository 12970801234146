<template>
  <AppClientAutocomplete
    v-bind="params"
    v-on="listeners"
    multiple
    clearable
  />
</template>

<script>
export default {
  computed: {
    params() {
      return { ...this.$props, ...this.$attrs };
    },
    listeners() {
      return {
        ...this.$listeners,
      };
    },
  },
};
</script>
