<template>
  <v-dialog
    v-model="localDialog"
    max-width="600px"
    scrollable
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("formManagement.common.addTableRow") }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="addRowForm"
          v-model="isValid"
          v-on:submit.prevent
          @submit="onSubmit"
        >
          <div
            v-for="field in fields"
            :key="field.key"
            class="p-relative"
          >
            <div class="label--small">
              {{ field.text }}
            </div>
            <AppDefaultTextarea
              v-model="formData[field.key]"
              :autofocus="field === fields[0]"
            />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onSubmit"
          @cancel="closeDialog"
          :confirmText="$t('formManagement.common.addTableRow')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    fields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isValid: false,
      formData: {},
    };
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.resetForm();
      }
    },
  },
  methods: {
    resetForm() {
      this.formData = this.fields.reduce((acc, field) => {
        acc[field.key] = "";
        return acc;
      }, {});
    },
    closeDialog() {
      this.localDialog = false;
    },
    onSubmit() {
      this.$emit("submit", this.formData);
      this.closeDialog();
    },
  },
};
</script>
