<template>
  <v-slide-x-reverse-transition :hide-on-leave="true">
    <div class="filters_drawer primary-box-shadow border-l">
      <v-toolbar
        class="border-b primary"
        dense
        flat
        fixed
        height="68"
      >
        <v-toolbar-title class="ui-background--text">
          {{ $t("common.filters") }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          @click="close"
          icon
        >
          <v-icon color="ui-background">{{ $icons.SOLID.ACTION.CLOSE }}</v-icon>
        </v-btn>
      </v-toolbar>
      <div
        v-if="isLoading"
        style="height: calc(100% - (68px))"
        class="d-flex align-center justify-center"
      >
        <AppCircularLoader />
      </div>
      <div
        v-else
        class="pa-6 overflow-y-auto"
        style="height: calc(100% - (68px))"
      >
        <!-- Saveable Filters Section -->
        <div class="mb-6">
          <div class="d-flex align-center mb-3">
            <h3 class="text-subtitle-1 font-weight-medium">
              {{ $t("views.filters.saveableFilters") }}
            </h3>
            <AppDefaultTooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  class="ml-2 cursor-pointer"
                  v-on="on"
                >
                  {{ $icons.LIGHT.COMMON.CIRCLE_QUESTION }}
                </v-icon>
              </template>
              <span>{{ $t("views.filters.saveableFiltersTooltip") }}</span>
            </AppDefaultTooltip>
          </div>

          <template v-for="child in saveableFilterComponents">
            <component
              :is="child.is"
              :key="child.id"
              :value="getFilterValue(child)"
              v-bind="child"
              @change="(event) => onFiltersChange({ event, child })"
            />
          </template>
        </div>

        <!-- Divider between sections -->
        <div
          v-if="unsaveableFilterComponents.length > 0"
          class="mb-4 border-b"
        ></div>

        <!-- Unsaveable Filters Section -->
        <div v-if="unsaveableFilterComponents.length > 0">
          <div class="d-flex align-center mb-3">
            <h3 class="text-subtitle-1 font-weight-medium">
              {{ $t("views.filters.contextFilters") }}
            </h3>
            <AppDefaultTooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  class="ml-2 cursor-pointer"
                  v-on="on"
                >
                  {{ $icons.LIGHT.COMMON.CIRCLE_QUESTION }}
                </v-icon>
              </template>
              <span>{{ $t("views.filters.contextFiltersTooltip") }}</span>
            </AppDefaultTooltip>
          </div>

          <template v-for="child in unsaveableFilterComponents">
            <component
              :is="child.is"
              :key="child.id"
              :value="getFilterValue(child)"
              v-bind="child"
              @change="(event) => onFiltersChange({ event, child })"
            />
          </template>
        </div>
      </div>
    </div>
  </v-slide-x-reverse-transition>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import _ from "lodash";

export default {
  props: {
    children: Array,
  },
  computed: {
    ...mapState("resourceViews", {
      isLoading: (state) => state.isLoading,
      allFilters: (state) => state.allFilters,
      scopedFilters: (state) => state.scopedFilters,
    }),
    ...mapGetters("resourceViews", {
      currentResourceViewConfig: "getCurrentResourceViewConfig",
    }),
    // Split children into saveable and unsaveable filter components
    saveableFilterComponents() {
      return this.children.filter((child) =>
        this.allFilters.includes(child.filterValueKey),
      );
    },
    unsaveableFilterComponents() {
      return this.children.filter(
        (child) => !this.allFilters.includes(child.filterValueKey),
      );
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async onFiltersChange({ event, child }) {
      const isSaveable = this.allFilters.includes(child.filterValueKey);

      if (isSaveable) {
        // Get current filters or create an empty object
        const currentFilters = { ...(this.currentResourceViewConfig.filters || {}) };

        // Update the filter value
        currentFilters[child.filterValueKey] = event;

        await this.$store.dispatch("resourceViews/updateUnsavedConfig", {
          updates: {
            filters: currentFilters,
          },
        });

        this.$root.$emit("refresh-view-data");
      } else {
        // For unsaveable filters, use the dedicated action
        this.$store.dispatch("resourceViews/updateScopedFilter", {
          key: child.filterValueKey,
          value: event,
        });

        // For unsaveable (scoped) filters, we do want to refresh immediately
        this.$root.$emit("refresh-view-data");
      }
    },
    getFilterValue(child) {
      // Get all filters by merging saveable and unsaveable filters
      const saveableFilters = this.currentResourceViewConfig?.filters || {};
      const unsaveableFilters = this.scopedFilters || {};

      // Merge filters using lodash
      const allFilters = _.merge({}, saveableFilters, unsaveableFilters);

      // Return the value for this filter key
      return allFilters[child.filterValueKey];
    },
  },
};
</script>

<style scoped>
/* You might want to add some styling for the filter sections */
</style>
