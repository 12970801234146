import { ROUTE_NAMES, ROUTE_PERMISSIONS } from "@/helpers/routeNames";
import multiguard from "../multiguard";
import { authGuard, userGuard } from "@/auth";
import { HELP_CATEGORIES } from "@/helpers/constants/help";

const {
  ROOT,
  FORM_TEMPLATES,
  PROJECT_TEMPLATES,
  FORM_TEMPLATE_BUILDER,
  PROJECT_TEMPLATE_BUILDER,
  FORM_TEMPLATE_BUILDER_DETAILS,
  FORM_TEMPLATE_BUILDER_HISTORY,
  PROJECT_TEMPLATE_BUILDER_DETAILS,
  PROJECT_TEMPLATE_BUILDER_HISTORY,
} = ROUTE_NAMES.TEMPLATE;

export const templateRoutes = [
  {
    path: "/template",
    name: ROOT,
    meta: {
      permission: {
        isRedirectRoute: true,
      },
      helpCategory: HELP_CATEGORIES.TEMPLATES,
    },
    beforeEnter: multiguard([authGuard, userGuard]),
    component: () =>
      import(/* webpackChunkName: "template" */ "../../pages/TemplateManagement"),
    children: [
      {
        path: "forms",
        name: FORM_TEMPLATES,
        meta: {
          permission: ROUTE_PERMISSIONS.FORM_TEMPLATE,
        },
        component: () =>
          import(
            /* webpackChunkName: "template" */ "../../pages/TemplateManagement/FormTemplatePage"
          ),
      },
      {
        path: "projects",
        name: PROJECT_TEMPLATES,
        meta: {
          permission: ROUTE_PERMISSIONS.PROJECT_TEMPLATE,
        },
        component: () =>
          import(
            /* webpackChunkName: "template" */ "../../pages/TemplateManagement/ProjectTemplatePage"
          ),
      },
    ],
  },
  {
    path: "/template/form/:templateId",
    name: FORM_TEMPLATE_BUILDER,
    meta: {
      permission: ROUTE_PERMISSIONS.FORM_TEMPLATE,
    },
    beforeEnter: multiguard([authGuard, userGuard]),
    component: () =>
      import(
        /* webpackChunkName: "template" */ "../../pages/TemplateManagement/FormTemplatePage/FormTemplateBuilderPage"
      ),
    children: [
      {
        path: "details",
        name: FORM_TEMPLATE_BUILDER_DETAILS,
        meta: {
          permission: ROUTE_PERMISSIONS.FORM_TEMPLATE,
        },
        component: () =>
          import(
            /* webpackChunkName: "template" */ "../../pages/TemplateManagement/FormTemplatePage/FormTemplateBuilderPage/MenuPages/DetailsPage"
          ),
      },
      {
        path: "history",
        name: FORM_TEMPLATE_BUILDER_HISTORY,
        meta: {
          permission: ROUTE_PERMISSIONS.FORM_TEMPLATE,
        },
        component: () =>
          import(
            /* webpackChunkName: "template" */ "../../pages/TemplateManagement/FormTemplatePage/FormTemplateBuilderPage/MenuPages/HistoryPage"
          ),
      },
    ],
  },
  {
    path: "/template/project/:templateId",
    name: PROJECT_TEMPLATE_BUILDER,
    meta: {
      permission: ROUTE_PERMISSIONS.PROJECT_TEMPLATE,
    },
    beforeEnter: multiguard([authGuard, userGuard]),
    component: () =>
      import(
        /* webpackChunkName: "template" */ "../../pages/TemplateManagement/ProjectTemplatePage/ProjectTemplateBuilderPage"
      ),
    children: [
      {
        path: "details",
        name: PROJECT_TEMPLATE_BUILDER_DETAILS,
        meta: {
          permission: ROUTE_PERMISSIONS.PROJECT_TEMPLATE,
        },
        component: () =>
          import(
            /* webpackChunkName: "template" */ "../../pages/TemplateManagement/ProjectTemplatePage/ProjectTemplateBuilderPage/Menu/DetailsPage"
          ),
      },
      {
        path: "history",
        name: PROJECT_TEMPLATE_BUILDER_HISTORY,
        meta: {
          permission: ROUTE_PERMISSIONS.PROJECT_TEMPLATE,
        },
        component: () =>
          import(
            /* webpackChunkName: "template" */ "../../pages/TemplateManagement/ProjectTemplatePage/MenuPages/HistoryPage"
          ),
      },
    ],
  },
];
