<template>
  <v-dialog
    v-model="localDialog"
    max-width="600px"
    scrollable
  >
    <v-card v-if="dialog">
      <v-card-title>
        <span class="headline">
          {{ title ? title : $t("member.newMember.title") }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="newMemberForm"
          v-model="member.valid"
          v-on:submit.prevent
          @submit="onCreate"
        >
          <div class="p-relative">
            <div class="label--small">
              {{ $t("common.members") | capitalize }}
            </div>
            <AppClientGroupedUserAutocomplete
              v-model="member.membersIds.values"
              :items="assignableUsers"
              :loading="isAssignableUsersLoading"
              user-avatar
              multiple
              clearable
              :noDataText="$t('common.noUsers')"
              :labelPaths="['workTitleLabel', 'contact.relation.label']"
            />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onCreate"
          @cancel="localDialog = false"
          :confirmText="$t('common.save')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getSearchUsers } from "@/services/search/users";
import { mapGetters } from "vuex";

export default {
  props: {
    dialog: Boolean,
    title: String,
    data: Object,
    projectId: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      assignableUsers: [],
      isAssignableUsersLoading: false,
      member: {
        valid: false,
        membersIds: {
          values: [],
        },
      },
    };
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    ...mapGetters("auth", {
      currentDomain: "currentDomain",
    }),
  },
  watch: {
    dialog: {
      handler(dialog) {
        if (dialog) {
          this.fillFormWithData(this.data);
          this.getAssignableUsers();
        }
      },
    },
  },
  methods: {
    fillFormWithData(data) {
      if (data) {
        this.member.membersIds.values = data.members;
      }
    },
    getAssignableUsers() {
      this.isAssignableUsersLoading = true;
      const params = {
        projectId: this.projectId,
        isAssignableAsProjectMember: true,
        alwaysIncludeIds: this.member.membersIds.values,
      };
      getSearchUsers({ params })
        .then((users) => {
          this.assignableUsers = users;
        })
        .finally(() => (this.isAssignableUsersLoading = false));
    },
    onCreate() {
      const valid = this.$refs.newMemberForm.validate();
      if (valid) {
        const data = {
          body: {
            members: this.member.membersIds.values,
          },
        };
        this.$emit("submit:form", data);
        this.$refs.newMemberForm.reset();
        this.localDialog = false;
      }
    },
    removeMember(item) {
      const index = this.member.membersIds.values.indexOf(item.id);
      if (index >= 0) this.member.membersIds.values.splice(index, 1);
    },
  },
};
</script>
