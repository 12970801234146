<script>
import { VIcon } from "vuetify/lib";

export default {
  props: {
    item: Object,
  },
  data() {
    return {};
  },
  computed: {
    isDocumentDeleted() {
      const hasDocument = !!this.item?.document;
      return !hasDocument;
    },
  },
  methods: {
    genNameField() {
      const name = this.item?.document?.name || this.item?.name;
      return this.$createElement(
        "span",
        { class: `${this.isDocumentDeleted ? "error--text" : ""} text-truncate` },
        name,
      );
    },
    genIcon() {
      return this.$createElement("div", { class: "px-5" }, [
        this.$createElement(
          VIcon,
          { props: { color: this.isDocumentDeleted ? "error" : "" } },
          this.isDocumentDeleted
            ? this.$icons.LIGHT.FILE.FILE_EXCLAMATION
            : this.$icons.LIGHT.FILE.FILE,
        ),
      ]);
    },
    genDocumentDeletedChip() {
      if (!this.isDocumentDeleted) return;
      return this.$createElement("div", { class: "px-5" }, [
        this.$createElement("AppColorChip", {
          props: {
            color: "error--text",
            text: this.$t("templateManagement.project.documentDeleted"),
          },
        }),
      ]);
    },
    genDocumentItemRow() {
      return this.$createElement(
        "div",
        {
          style: "height: 65px; border-radius: 0px; overflow: hidden",
          class: `pa-2 d-flex align-center border-a--transparent`,
          props: { flat: true },
        },
        [this.genIcon(), this.genNameField(), this.genDocumentDeletedChip()],
      );
    },
  },
  render(h) {
    return this.genDocumentItemRow();
  },
};
</script>
