import Vue from "vue";
import Vuex from "vuex";
import * as modules from "./modules/";
import { createPermissionExtractor } from "@/helpers/util/permissions/permissionExtractor";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const initializeStore = () => {
  return new Vuex.Store({
    modules,
    strict: debug,
    plugins: [createPermissionExtractor],
  });
};

export default initializeStore();
