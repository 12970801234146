<template>
  <AppDefaultAutocomplete
    v-bind="$attrs"
    v-on="$listeners"
    :items="roles"
    item-text="name"
    item-value="id"
    return-object
    :clearable="false"
    :loading="isLoading && !roles.length"
  />
</template>

<script>
import { getRoles } from "@/services/role/role";

export default {
  props: {
    preventFetch: Boolean,
  },
  data() {
    return {
      roles: [],
      isLoading: false,
    };
  },
  methods: {
    getRoles() {
      const queryParams = this.$attrs.queryParams || {};
      this.isLoading = true;
      getRoles({ queryParams })
        .then((roles) => {
          this.roles = roles;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    if (!this.preventFetch) {
      this.getRoles();
    }
  },
};
</script>
