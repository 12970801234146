<template>
  <v-row>
    <v-col :style="leftColStyle">
      <div class="d-flex align-center justify-center">
        <AppDefaultTextField
          :id="nameInputId"
          :value="hourType.name"
          hide-details
          backgroundColor="ui-background"
          @blur="onNameBlur"
          :disabled="!hourType.isActive"
        >
          <template v-slot:append>
            <div @click.stop.prevent>
              <AppToggleMenuBtn
                v-if="!hourType.isLocked"
                :icon="$icons.REGULAR.ACTION.SETTINGS"
                small
              >
                <AppMenuItem
                  :text="$t(isActive ? 'common.deactivate' : 'common.activate')"
                  :icon="$icons.REGULAR.COMMON[isActive ? 'TOGGLE_OFF' : 'TOGGLE_ON']"
                  @click="onHourTypeUpdate({ isActive: !isActive })"
                />
                <v-divider />
                <AppMenuDeleteBtn
                  :icon="$icons.REGULAR.COMMON.CALENDAR_DAY"
                  :disabled="!hourType?.canDelete"
                  @click="onHourTypeDelete"
                />
              </AppToggleMenuBtn>
              <AppDefaultTooltip
                v-else
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-on="on"
                    v-bind="attrs"
                    style="height: 28px; width: 28px"
                    class="d-flex justify-center"
                  >
                    <v-icon
                      color="info"
                      small
                    >
                      {{ $icons.REGULAR.ACTION.INFO }}
                    </v-icon>
                  </div>
                </template>
                <span>
                  {{ $t("settings.systemSettings.timeEntrySettings.hourTypeIsLocked") }}
                </span>
              </AppDefaultTooltip>
            </div>
          </template>
        </AppDefaultTextField>
      </div>
    </v-col>
    <template v-if="showAccounting && hasTimeEntriesIntegrationEnabled">
      <v-icon>
        {{ $icons.REGULAR.COMMON.ARROW_RIGHT }}
      </v-icon>
      <v-col style="min-width: 175px">
        <AppDefaultAutocomplete
          backgroundColor="ui-background"
          :disabled="!hourType.isActive"
          :value="hourType.externalCode"
          :items="accountingHourTypes"
          :loading="isAccountingHourTypesLoading"
          item-text="name"
          item-value="externalCode"
          hide-details
          @change="onHourTypeUpdate({ externalCode: $event })"
        />
      </v-col>
    </template>

    <template v-else-if="showNumber">
      <v-col style="min-width: 175px">
        <!-- Number field -->
        <AppDefaultTextField
          backgroundColor="ui-background"
          disabled
          :value="hourType.number"
          hide-details
          @blur="onNumberBlur"
          :id="`hour-type-list-item-number-text-field-${
            hourType?.id || hourType?.tempId
          }`"
        />
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    hourType: Object,
    accountingHourTypes: Array,
    isAccountingHourTypesLoading: Boolean,
    showNumber: {
      type: Boolean,
      default: false,
    },
    showAccounting: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    id() {
      return this.hourType?.id || null;
    },
    isActive() {
      return this.hourType?.isActive;
    },
    isUnsaved() {
      return this.id ? false : true;
    },
    nameInputId() {
      if (this.isUnsaved)
        return `hour-type-list-item-name-text-field-${this.hourType?.tempId}`;
      return `hour-type-list-item-name-text-field-${this.id}`;
    },
    ...mapGetters("activeIntegrations", {
      hasTimeEntriesIntegrationEnabled: "hasTimeEntriesIntegrationEnabled",
    }),
    leftColStyle() {
      return !this.showNumber &&
        !this.showAccounting &&
        !this.hasTimeEntriesIntegrationEnabled
        ? { minWidth: "175px", flex: "1 1 auto" }
        : { minWidth: "175px" };
    },
  },
  methods: {
    onHourTypeDelete() {
      this.$emit("hourType:delete", {
        hourType: this.hourType,
      });
    },
    onNameBlur(event) {
      const name = event.srcElement.value;
      if (name === this.hourType.name) return;
      const action = this.isUnsaved ? "hourType:create" : "hourType:update";

      this.$emit(action, {
        body: {
          name,
          ...(this.hourType?.tempId && { tempId: this.hourType?.tempId }),
        },
        id: this.id,
      });
    },
    onNumberBlur(event) {
      const number = event.target.value;
      if (number === this.hourType.number) return;

      this.$emit("hourType:update", {
        body: { number },
        id: this.id,
      });
    },
    onHourTypeUpdate(body) {
      this.$emit("hourType:update", {
        body,
        id: this.id,
      });
    },
  },
};
</script>
