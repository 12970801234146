<template>
  <BaseDocumentTemplatePickerDialog
    v-bind="$props"
    :title="$t('fileManagement.sharedDocumentTemplatePicker.title')"
    :saveBtnText="$t('fileManagement.sharedDocumentTemplatePicker.saveBtn')"
    :templates="templates"
    :isLoading="isLoading"
    :isSaving="isSaving"
    v-model="localDialog"
    allow-duplicate-templates
    show-download
    @add-template="onAddTemplateClick"
    @init="onInit"
    @filter-change="onFilterChange"
    @download-item="onDownloadItem"
  />
</template>

<script>
import BaseDocumentTemplatePickerDialog from "./BaseDocumentTemplatePickerDialog.vue";
import { mapState } from "vuex";

export default {
  components: {
    BaseDocumentTemplatePickerDialog,
  },
  props: {
    dialog: Boolean,
    parentId: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    ...mapState("sharedDocumentTemplateLibrary", {
      templates: (state) => state.templates,
      isLoading: (state) => state.isLoading,
      isSaving: (state) => state.isSaving,
    }),
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  methods: {
    onInit() {
      this.getTemplates();
    },
    getTemplates(args = {}) {
      this.$store.dispatch("sharedDocumentTemplateLibrary/getTemplates", {
        ...args,
      });
    },
    onFilterChange(filter) {
      this.getTemplates(filter);
    },
    onDownloadItem(item) {
      this.$store.dispatch("sharedDocumentTemplateLibrary/downloadItem", item);
    },
    async onAddTemplateClick(selectedTemplates) {
      const selectedIds = selectedTemplates.map((x) => x.id);
      const body = { ids: selectedIds, parentId: this.parentId };
      await this.$store.dispatch("sharedDocumentTemplateLibrary/saveTemplates", {
        body,
      });
      this.localDialog = false;
    },
  },
};
</script>
