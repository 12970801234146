import Vue from "vue";
import VueRouter from "vue-router";
import {
  projectRoutes,
  timeRegistrationRoutes,
  authRoutes,
  settingsRoutes,
  getStartedRoutes,
  officeOnlineRoutes,
  cmsRoutes,
  externalRoutes,
  formViewRoutes,
  taskViewRoutes,
  templateRoutes,
  taskRoutes,
  formRoutes,
  storageRoutes,
  contactRoutes,
  employeeRoutes,
  dashboardRoutes,
  childDomainInvitationRoutes,
  projectViewRoutes,
  homeRoutes,
} from "./routes";
import multiguard from "./multiguard";
import { authGuard, permissionGuard, rootRouteRedirectGuard, userGuard } from "@/auth";
import store from "@/store";
import { referralRoutes } from "./routes/referral";
import { setupDocumentTitleWatcher } from "./documentTitleWatcher";
import { setupQueryParamPreservation } from "./preserveQueryParams";
import {
  SERVER_SIDE_PAGINATION_TABLE__PARENT_ROUTE_NAMES,
  CALENDAR__PARENT_ROUTE_NAMES,
} from "@/helpers/routeNames";
import _ from "lodash";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "root",
    beforeEnter: multiguard([authGuard, userGuard, rootRouteRedirectGuard]),
  },
  {
    path: "*",
    name: "notFound",
    component: () =>
      import(/* webpackChunkName: "notFound" */ "../pages/common/NotFoundPage"),
  },
  {
    path: "/error",
    name: "error",
    component: () => import(/* webpackChunkName: "error" */ "../pages/common/ErrorPage"),
  },
  {
    path: "/forbidden",
    name: "forbidden",
    component: () =>
      import(/* webpackChunkName: "forbidden" */ "../pages/common/ForbiddenPage"),
  },
  {
    path: "/invalid-user",
    name: "invalidUser",
    component: () =>
      import(/* webpackChunkName: "invalidUser" */ "../pages/common/InvalidUserPage"),
  },

  // routes to keep
  ...dashboardRoutes,
  ...homeRoutes,
  ...authRoutes,
  ...referralRoutes,
  ...taskRoutes,
  ...timeRegistrationRoutes,
  ...formRoutes,
  ...storageRoutes,
  ...contactRoutes,
  ...taskViewRoutes,
  ...templateRoutes,
  ...employeeRoutes,
  ...formViewRoutes,
  ...externalRoutes,
  ...projectRoutes,
  ...projectViewRoutes,
  ...settingsRoutes,
  ...getStartedRoutes,
  ...childDomainInvitationRoutes,
  ...officeOnlineRoutes,
  ...cmsRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.onError((error) => {
  if (error.message && error.message.includes("Loading chunk")) {
    window.location.reload();
  }
});

router.beforeResolve(async (to, from, next) => {
  return await permissionGuard(to, from, next);
});

router.afterEach((to) => {
  const resourceViewId = to.query.resourceViewId;
  store.commit("resourceViews/setCurrentResourceViewId", resourceViewId);
});

//DEBUGGING: For debugging router navigation
// router.beforeEach((to, from, next) => {
//   console.log("Attempting navigation:", {
//     from: from.fullPath,
//     to: to.fullPath,
//     timestamp: new Date().getTime(),
//   });
//   next();
// });

setupQueryParamPreservation(router, [
  //preserve current page query for AppDataTableServerSidePagination pages
  {
    preserveParams: ["page", "resourceViewId"],
    parentRouteNames: SERVER_SIDE_PAGINATION_TABLE__PARENT_ROUTE_NAMES,
  },
  //preserve start and end date queries for calendar routes
  {
    preserveParams: ["start", "end", "resourceViewId"],
    parentRouteNames: CALENDAR__PARENT_ROUTE_NAMES,
  },
]);

setupDocumentTitleWatcher(router);

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  store.commit("route/addVisitedRoute", location.name);
  return routerPush.call(this, location).catch((error) => error);
};

export default router;
