<template>
  <div>
    <AppDataListWidgetBase
      class="h-100"
      :templateProps="templateProps"
      :title="$t('common.tasks')"
      :actionBtnText="$t('common.create')"
      :groupByName="groupByName"
      :tabs="tabs"
      :loading="isLoadingData"
      :isMoreItems="isMoreItems"
      :groupBy="groupBy"
      :items="tasks"
      tabId="task-list-widget"
      :selectedTabName="localSelectedTabName"
      :isActionBtnDisabled="!canCreateTask"
      @tab:click="onTabClick"
      @tab:change="onTabChange"
      @actionBtn:click="onNewTask"
      @delete:click="onDelete"
      @scroll:bottom="getTasks({ shouldPushItems: true })"
      @items:get="tasksResetGet"
    >
      <template v-slot:item="{ item }">
        <AppTaskListWidgetItem
          :task="item"
          @status:click="onStatusClick"
          @taskName:click="onTaskNameClick"
        />
      </template>
      <template v-slot:groupHeaderTitle="{ categoryName }">
        {{ headerTitle(categoryName).toUpperCase() }}
      </template>
      <template v-slot:groupByItems>
        <AppMenuItem
          :text="$t('common.noGroupText') | capitalize"
          :icon="$icons.REGULAR.ACTION.EMPTY"
          :isSelected="isGroupSelected(undefined)"
          @click="setGroupBy(undefined)"
        />
        <AppMenuItem
          :text="$t('common.status') | capitalize"
          :icon="$icons.REGULAR.COMMON.SQUARE"
          :isSelected="isGroupSelected(dataFields.STATUS)"
          @click="setGroupBy(dataFields.STATUS)"
        />
        <AppMenuItem
          :text="$t('taskManagement.list.responsible') | capitalize"
          :icon="$icons.REGULAR.ROUTE.USER"
          :isSelected="isGroupSelected(dataFields.OWNER)"
          @click="setGroupBy(dataFields.OWNER)"
        />
        <AppMenuItem
          :text="$t('taskManagement.list.dueDate') | capitalize"
          :icon="$icons.REGULAR.ACTION.DUE_DATE"
          :isSelected="isGroupSelected(dataFields.DUE_DATE)"
          @click="setGroupBy(dataFields.DUE_DATE)"
        />
        <AppMenuItem
          v-if="tableModel === $constant.TASKS"
          :text="$t('common.project')"
          :icon="$icons.REGULAR.ROUTE.PROJECT"
          :isSelected="isGroupSelected(dataFields.PROJECT)"
          @click="setGroupBy(dataFields.PROJECT)"
        />
      </template>
    </AppDataListWidgetBase>
    <AppTaskViewDialog
      v-model="dialog.taskView.active"
      :task="dialog.taskView.selectedTask"
      :isUpdating="isUpdatingTask"
      @task:update="onTaskUpdate"
    />
    <AppTaskDialog
      v-if="canCreateTask"
      v-model="dialog.newTask.active"
      @created="onTaskCreated"
      :data="{ projectId }"
    />
    <AppCustomStatusPickerMenu
      ref="statusMenu"
      @submit="onStatusChange"
      model="task"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { dateMixins, permissionMixins } from "@/helpers/mixins";
import { getTasks } from "@/services/task/tasks";
import { normalizeTasks } from "@/helpers/normalization";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export default {
  mixins: [dateMixins, permissionMixins],
  props: {
    templateProps: Object,
    tableModel: String,
    projectId: String,
    groupBy: String,
    selectedTabName: String,
  },
  data() {
    return {
      dialog: {
        newTask: {
          active: false,
        },
        taskView: {
          active: false,
          selectedTask: null,
        },
      },
      tasks: [],
      limit: 0,
      offset: 0,
      count: 0,
      serverItemsLength: null,
      isLoadingTasks: false,
      isUpdatingTask: false,
      localSelectedTabName: "ACTIVE",
      itemsToEdit: [],
    };
  },
  computed: {
    ...mapGetters("statuses", {
      getStatusesByModel: "getStatusesByModel",
      hasFetchedStatuses: "hasFetchedStatuses",
      isLoadingStatusesForModel: "isLoadingStatusesForModel",
    }),
    canCreateTask() {
      return this.permissionMixins_task.create.can;
    },
    dataFields() {
      return this.$constant.TABLE_HEADER[this.tableModel];
    },
    categories() {
      return [...new Set(this.tasks.map((task) => task.category.name))];
    },
    isLoadingStatuses() {
      return this.isLoadingStatusesForModel("task");
    },
    notClosedStatuses() {
      return this.getStatusesByCategory(["NOT_STARTED", "ACTIVE", "DONE"]);
    },
    closedStatuses() {
      return this.getStatusesByCategory(["CLOSED"]);
    },
    tabs() {
      return [
        {
          title: this.$t("widgetLibrary.taskList.tabs.active"),
          name: "ACTIVE",
        },
        {
          title: this.$t("widgetLibrary.taskList.tabs.closed"),
          name: "CLOSED",
        },
      ];
    },
    groupByName() {
      if (this.isGroupSelected(undefined)) return this.$t("common.noGroupText");
      if (this.isGroupSelected(this.dataFields.STATUS)) return this.$t("common.status");
      if (this.isGroupSelected(this.dataFields.OWNER))
        return this.$t("taskManagement.list.responsible");
      if (this.isGroupSelected(this.dataFields.PROJECT)) return this.$t("common.project");
      if (this.isGroupSelected(this.dataFields.TASK)) return this.$t("common.task");
      if (this.isGroupSelected(this.dataFields.CATEGORY))
        return this.$t("common.category");
      if (this.isGroupSelected(this.dataFields.DUE_DATE))
        return this.$t("taskManagement.list.dueDate");
    },
    isLoadingData() {
      return this.isLoadingTasks || this.isLoadingStatuses;
    },
    isMoreItems() {
      return this.serverItemsLength > this.tasks?.length;
    },
    statuses() {
      return this.getStatusesByModel("task");
    },
  },
  methods: {
    onTaskNameClick({ task }) {
      this.dialog.taskView.selectedTask = task;
      this.dialog.taskView.active = true;
    },
    tasksResetGet() {
      this.resetState();
      this.getTasks();
    },
    getStatusesByCategory(categories) {
      const statusIds = [];

      categories.forEach((category) => {
        if (this.statuses[category]) {
          statusIds.push(...this.statuses[category].map((status) => status.id));
        }
      });
      return statusIds;
    },
    resetState() {
      this.tasks = [];
      this.limit = 0;
      this.offset = 0;
      this.serverItemsLength = null;
    },
    async onTaskUpdate({ data, task }) {
      this.isUpdatingTask = true;
      await this.updateTask({
        id: task?.id,
        data,
      });
      this.isUpdatingTask = false;
    },
    getTasks({ params, shouldPushItems } = {}) {
      const statusIds =
        this.localSelectedTabName === "ACTIVE"
          ? this.notClosedStatuses
          : this.closedStatuses;

      const defaultLimit = 20;

      this.isLoadingTasks = true;
      this.offset = this.limit;
      this.limit = this.limit + defaultLimit;
      getTasks({
        projectId: this.projectId,
        statusIds,
        groupBy: [this.groupBy],
        groupDesc: [false],
        limit: defaultLimit,
        offset: this.offset,
        ...params,
      })
        .then(({ tasks, count: serverItemsLength, isCanceled } = {}) => {
          if (isCanceled) return;
          this.serverItemsLength = serverItemsLength;
          const normalizedTasks = normalizeTasks(tasks);
          if (shouldPushItems) {
            const uniqueTaskIds = new Set();
            const uniqueTasks = [...this.tasks, ...normalizedTasks].filter((task) => {
              return !uniqueTaskIds.has(task.id) && uniqueTaskIds.add(task.id);
            });
            this.tasks = uniqueTasks;
          } else {
            const uniqueTaskIds = new Set();
            const uniqueTasks = normalizedTasks.filter((task) => {
              return !uniqueTaskIds.has(task.id) && uniqueTaskIds.add(task.id);
            });
            this.tasks = uniqueTasks;
          }
          this.isLoadingTasks = false;
        })
        .catch((error) => {
          this.isLoadingTasks = false;
          throw new Error(error);
        });
    },
    async updateTask({ id, data }) {
      if (typeof data?.statusId === "string") {
        this.toggleUpdatingStatus({ id, isUpdatingStatus: true });
      }
      return axios
        .put(`${apiUrl}task/${id}`, data)
        .then(({ data }) => {
          const task = data?.task;
          if (task) {
            this.dialog.taskView.selectedTask = task;
            const index = this.tasks.findIndex((x) => x.id === task.id);
            if (index !== -1) {
              this.tasks.splice(index, 1, task);
            }
          }
        })
        .catch((error) => {
          throw new Error(error);
        })
        .finally(() => {
          if (typeof data?.statusId === "string") {
            this.toggleUpdatingStatus({ id, isUpdatingStatus: false });
          }
        });
    },
    toggleUpdatingStatus({ id, isUpdatingStatus }) {
      const index = this.tasks.findIndex((x) => x.id === id);
      if (index !== -1) {
        if (this.tasks[index]) {
          this.tasks.splice(index, 1, { ...this.tasks[index], isUpdatingStatus });
        }
      }
    },
    headerTitle(group) {
      if (!group) return this.$t(`common.noGroup.${this.groupBy}`);

      if (this.isGroupSelected(this.dataFields.CATEGORY)) {
        return this.translatedCategory(group);
      }
      if (this.isGroupSelected(this.dataFields.DUE_DATE)) {
        return this.dateMixins_formatDateWithTranslationsLong(group);
      }
      return group;
    },
    translatedCategory(key) {
      return this.$te(`taskManagement.category.${key}`)
        ? this.$t(`taskManagement.category.${key}`)
        : key;
    },
    onStatusChange({ statusId }) {
      const data = { statusId };

      this.onTaskUpdate({ data, task: this.itemsToEdit[0] });
    },
    onStatusClick(e) {
      const { items } = e;
      this.$refs.statusMenu.open(e);
      this.itemsToEdit = items;
    },
    onTabChange({ name }) {
      this.localSelectedTabName = name;
      const body = { selectedTabName: name };
      this.$emit("item:update", body);
    },
    onTabClick() {
      //wait for tab name to change
      this.$nextTick(() => {
        this.resetState();
        this.getTasks();
      });
    },
    isGroupSelected(group) {
      return this.groupBy === group;
    },
    setGroupBy(group) {
      const body = { groupBy: group };
      this.$emit("item:update", body);
    },
    onNewTask() {
      this.dialog.newTask.active = true;
    },
    async onTaskCreated({ task }) {
      this.serverItemsLength++;
      this.tasks.unshift(task);
    },
    async getStatuses() {
      await this.$store.dispatch("statuses/getStatuses", { model: "task" });
    },
    onDelete() {
      this.$emit("delete:click");
    },
    async initializeComponent() {
      if (!!this.selectedTabName) {
        this.localSelectedTabName = this.selectedTabName;
      }
      this.resetState();
      if (!this.hasFetchedStatuses("task")) {
        await this.getStatuses();
      }
      this.getTasks();
    },
  },
  async mounted() {
    await this.initializeComponent();
  },
};
</script>
