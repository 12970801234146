import { dateMixins } from "../mixins";
import { dataFieldsMixin } from "./dataFieldsMixin";
import { mapGetters, mapActions } from "vuex";

// New version of groupByMixins that works with the views store
export const groupByMixins2 = {
  mixins: [dateMixins, dataFieldsMixin],
  computed: {
    ...mapGetters("resourceViews", ["getCurrentResourceViewConfig"]),
    groupByMixins2_viewConfig() {
      return this.getCurrentResourceViewConfig || {};
    },
    groupByMixins2_groupBy() {
      return this.groupByMixins2_viewConfig.group?.[0]?.by;
    },
    groupByMixins2_noGroupText() {
      return this.$t(`common.noGroup.${this.groupByMixins2_groupBy}`);
    },
  },
  methods: {
    groupByMixins2_commonHeaderTitle(group) {
      const hasNoGroupValue = group === null || group === undefined || group === "";
      if (hasNoGroupValue) return this.groupByMixins2_noGroupText;

      if (
        this.groupByMixins2_isGroupSelected(this.dataFields.DUE_DATE) ||
        this.groupByMixins2_isGroupSelected(this.dataFields.DATE) ||
        this.groupByMixins2_isGroupSelected(this.dataFields.START_DATE)
      ) {
        return (
          this.dateMixins_formatDateWithTranslationsLong(group) ||
          this.groupByMixins2_noGroupText
        );
      }

      return null;
    },
    groupByMixins2_isGroupSelected(header) {
      return this.groupByMixins2_groupBy === header;
    },
    groupByMixins2_setGroupBy(groupBy) {
      if (groupBy === this.groupByMixins2_groupBy) return;

      let updates = {};

      if (groupBy === undefined) {
        updates.group = [];
      } else {
        updates.group = [{ by: groupBy, desc: false }];
      }

      this.$store.dispatch("resourceViews/updateUnsavedConfig", {
        updates,
      });

      // Emit refresh-view-data to update the display immediately
      this.$root.$emit("refresh-view-data");
    },
    groupByMixins2_headerTitleColor(group) {
      if (!this.groupByMixins2_isGroupSelected(this.dataFields.DATE)) return "";
      return this.groupByMixins2_isDateToday(group) ? "info--text" : "";
    },
    groupByMixins2_isDateToday(inputDate) {
      return new Date(inputDate).toDateString() === new Date().toDateString();
    },
    groupByMixins2_showColumn(columnName) {
      const columns = this.groupByMixins2_viewConfig.columns || [];
      return columns.includes(columnName);
    },
  },
};
