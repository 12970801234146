import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  roles: [],
  isLoading: false,
});

const getters = {};

const actions = {
  async getRoles({ commit }, { queryParams } = {}) {
    return axios
      .get(`${apiUrl}role`, { params: queryParams })
      .then(({ data }) => {
        const roles = data.roles;
        commit("setRoles", roles);
        commit("loading", false);
        return roles;
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
};

const mutations = {
  setRoles(state, roles) {
    state.roles = roles;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const roles = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
