<template>
  <div>
    <AppDataTableContextMenu
      ref="contextMenu"
      :actionConfigs="actionConfigs"
      :items="[contextMenuActiveItem]"
      @input="$emit('input', $event)"
    />
    <AppContextMenu ref="openWithMenu">
      <AppMenuActionBtn
        v-for="action in openWithActionConfig"
        :key="action.key"
        v-bind="action.props"
        v-on="action.on"
        :selectedItems="[contextMenuActiveItem]"
      />
    </AppContextMenu>
  </div>
</template>

<script>
import { planRestrictionsMixins } from "@/helpers/mixins";

export default {
  mixins: [planRestrictionsMixins],
  props: {
    contextMenuActiveItem: Object,
    actionConfigs: Array,
  },
  computed: {
    openWithActionConfig() {
      return this.$constant.getSubmenuActionConfigsByType(
        this.actionConfigs,
        this.$constant.ACTION_KEYS.OPEN_WITH,
      );
    },
  },
  methods: {
    open(e) {
      this.$refs.contextMenu.open(e);
    },
    openOpenWithMenu(e) {
      this.$refs.openWithMenu.open(e);
    },
  },
};
</script>
