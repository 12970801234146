<script>
import { VIcon } from "vuetify/lib";
import { VBtn } from "vuetify/lib";
import ResponseItemSubHeader from "./ResponseItemSubHeader.vue";

export default {
  props: {
    values: Array,
    id: String,
    noIndent: Boolean,
  },
  data() {
    return {
      localValues: [],
    };
  },
  watch: {
    values: {
      handler(values) {
        if (this.localValues.length > 0) return;
        this.localValues = [...values];
      },
      immediate: true,
    },
  },
  methods: {
    addChoice() {
      this.localValues = this.localValues.concat("");
      this.focusField(this.localValues.length - 1);
    },
    deleteChoice(index) {
      this.localValues = [
        ...this.localValues.slice(0, index),
        ...this.localValues.slice(index + 1),
      ];
      this.updateValues();
    },
    updateChoice({ value, index }) {
      this.localValues[index] = value;
    },
    fieldBlur({ value, index }) {
      if (!value || value?.trim() === "") {
        this.deleteChoice(index);
      } else {
        this.updateValues();
      }
    },
    updateValues() {
      this.$emit("values:update", { values: this.localValues });
    },
    focusField(index) {
      this.$nextTick(() => {
        const field = document.getElementById(`formItemValuesChoice_${this.id}_${index}`);
        field?.focus();
      });
    },
    genChoice({ value, index }) {
      return this.$createElement(
        "div",
        {
          class: "d-flex align-center draggable-item",
          style: "border-radius: 4px;",
        },
        [
          this.$createElement(
            "div",
            {
              class: "d-flex align-center cursor-grab draggable-handle pl-2 mr-2",
            },
            [
              this.$createElement(VIcon, {
                props: { small: true },
                domProps: { textContent: this.$icons.SOLID.ACTION.DRAG },
              }),
            ],
          ),
          this.$createElement(
            "div",
            { class: "font-weight-bold d-flex justify-center", style: "width: 32px;" },
            [this.$createElement("span", index + 1)],
          ),
          this.genChoiceInput({ value, index }),
        ],
      );
    },
    genChoiceInput({ value, index }) {
      return this.$createElement(
        "AppDefaultTextField",
        {
          props: {
            id: `formItemValuesChoice_${this.id}_${index}`,
            label: this.$t("templateManagement.form.builder.item.choiceTextPlaceholder"),
            hideDetails: true,
            value: value,
          },
          class: "border-a-2",
          on: {
            input: (e) => this.updateChoice({ value: e, index }),
            blur: (e) => this.fieldBlur({ value: e.target.value, index }),
            keydown: (e) => this.onkeydownPress(e),
          },
        },
        [
          index > 0
            ? this.$createElement("template", { slot: "append" }, [
                this.genDeleteButton({
                  onClick: () => this.deleteChoice(index),
                }),
              ])
            : null,
        ],
      );
    },
    genDeleteButton({ onClick, size = 32 }) {
      return this.$createElement(
        VBtn,
        {
          props: { icon: true, color: "error", small: true },
          on: { click: onClick },
        },
        [this.$createElement(VIcon, this.$icons.LIGHT.ACTION.DELETE)],
      );
    },
    onkeydownPress(e) {
      if (e.key === "Enter") {
        const value = e.target.value;
        if (!value || value?.trim() === "") return;
        this.addChoice();
      }
    },
    genChoices() {
      return this.localValues.map((value, index) => this.genChoice({ value, index }));
    },
    focusFirstChoice() {
      if (this.localValues.length === 0) {
        this.addChoice();
      } else {
        this.focusField(0);
      }
    },
  },
  render(h) {
    return this.$createElement("div", { class: "d-flex" }, [
      !this.noIndent && this.$createElement("div", { style: "min-width: 32px" }),
      this.$createElement("div", { class: "w-100 py-2 pr-2" }, [
        this.$createElement(ResponseItemSubHeader, {
          props: {
            title: this.$t("templateManagement.form.builder.item.choices"),
          },
          scopedSlots: {
            append: () =>
              this.$createElement(
                VBtn,
                {
                  props: {
                    color: "primary",
                    text: true,
                    small: true,
                    depressed: true,
                  },
                  class: "border-a text-transform-none",
                  on: { click: this.addChoice },
                },
                [
                  this.$createElement(VIcon, { class: "mr-2", props: { small: true } }, [
                    this.$icons.SOLID.ACTION.ADD,
                  ]),
                  this.$t("templateManagement.form.builder.item.addChoice"),
                ],
              ),
          },
        }),
        this.$createElement(
          "AppDefaultDraggable",
          {
            class: "w-100 draggable-container-gap-8",
            attrs: {
              value: this.localValues,
              handle: ".draggable-handle",
              ghostClass: this.$constant.DRAG_SETTINGS.GHOST_CLASS_DASHED_OUTLINE,
              dragClass: this.$constant.DRAG_SETTINGS.DRAG_CLASS_WHITE_BACKGROUND,
            },
            on: {
              change: (evt) => {
                const newValues = [...this.localValues];
                const movedItem = newValues.splice(evt.moved.oldIndex, 1)[0];
                newValues.splice(evt.moved.newIndex, 0, movedItem);
                this.localValues = newValues;
                this.updateValues();
              },
            },
          },
          this.genChoices(),
        ),
      ]),
    ]);
  },
};
</script>
