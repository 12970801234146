<template>
  <v-dialog
    v-model="localValue"
    width="80vw"
    scrollable
  >
    <v-card :style="{ height: '80vh', display: 'flex', flexDirection: 'column' }">
      <v-card-title>
        <v-row>
          <v-col
            class="d-flex"
            :style="colDimensions"
          >
            <span class="headline">{{ dialogTitle }}</span>
            <v-spacer />
            <v-btn
              icon
              @click="generateRandomChartData"
            >
              <v-icon>{{ $icons.REGULAR.COMMON.DICE }}</v-icon>
            </v-btn>
          </v-col>
          <v-col :style="colDimensions">
            <div
              class="text-h6 font-weight-bold pl-5"
              style="word-break: break-word"
            >
              {{ chartTitle }}
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text
        style="flex: 1 1 auto; overflow: hidden"
        class="primary--text"
      >
        <v-row
          class="h-100"
          no-gutters
        >
          <v-col
            class="pr-5"
            :style="{
              ...colDimensions,
              height: '100%',
              overflowY: 'auto',
              overflowX: 'hidden',
            }"
          >
            <div>
              <div>
                <div class="label--small">{{ $t("chart.chartName") }}</div>
                <div class="d-flex align-center">
                  <AppDefaultTextField
                    v-model="widgetProps.chartName"
                    clearable
                    autofocus
                  />
                </div>
              </div>
              <div>
                <div class="label--small">{{ $t("chart.chartTypeTitle") }}</div>
                <div class="d-flex align-center">
                  <AppDefaultSelect
                    :items="translateItems(chartTypesWithCompatability)"
                    :value="widgetProps.chartType.value"
                    :clearable="false"
                    return-object
                    @input="handleChartTypeChange"
                  >
                    <template v-slot:item="{ item, attrs, on }">
                      <v-list-item
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-content>
                          <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item-content>
                        <AppColorChip
                          color="success--text"
                          v-if="item.isCompatible"
                        >
                          {{ $t("common.recommended") }}
                        </AppColorChip>
                      </v-list-item>
                    </template>
                  </AppDefaultSelect>
                  <AppDefaultSwitch
                    v-if="isStackableChartType"
                    class="pa-0 ma-0 pl-5"
                    :hide-details="false"
                    v-model="widgetProps.isStacked"
                    :label="$t('chart.stacked')"
                  />
                </div>
              </div>
              <div>
                <div class="label--small">{{ $t("chart.datasetTypeTitle") }}</div>
                <div class="d-flex align-center">
                  <div style="width: 200px">
                    <AppDefaultSelect
                      :items="translateItems($constant.datasetTypes)"
                      v-model="widgetProps.datasetType"
                      :clearable="false"
                      @input="handleDatasetTypeChange"
                    />
                  </div>

                  <div
                    v-if="showResourceSelect"
                    class="pl-4"
                    style="flex: 1"
                  >
                    <AppDefaultSelect
                      :items="translateItems($constant.chartDatasetOptions)"
                      :value="
                        isSingleDimensionChartType
                          ? widgetProps.datasets[0]
                          : widgetProps.datasets
                      "
                      :clearable="false"
                      :multiple="!isSingleDimensionChartType"
                      return-object
                      @input="handleDatasetsChange"
                    />
                  </div>

                  <div
                    v-if="showFormCategoriesSelect"
                    class="pl-4"
                    style="flex: 1"
                  >
                    <AppDefaultAutocomplete
                      :items="formCategories"
                      :loading="isLoadingFormCategories"
                      :value="
                        isSingleDimensionChartType
                          ? widgetProps.datasets[0]
                          : widgetProps.datasets
                      "
                      item-text="text"
                      item-value="id"
                      :clearable="false"
                      :multiple="!isSingleDimensionChartType"
                      select-all
                      @change="handleDatasetsChange"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div class="w-100">
                  <div class="label--small">{{ $t("chart.groupByTitle") }}</div>
                  <div class="d-flex align-center">
                    <AppDefaultSelect
                      :items="translateItems(groupingOptionsWithCompatability)"
                      :value="widgetProps.grouping.value"
                      :clearable="false"
                      return-object
                      @input="handleChartGroupingChange"
                    >
                      <template v-slot:item="{ item, attrs, on }">
                        <v-list-item
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-list-item-content class="flex-row">
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                          </v-list-item-content>
                          <AppColorChip
                            color="success--text"
                            v-if="item.isCompatible"
                          >
                            {{ $t("common.recommended") }}
                          </AppColorChip>
                        </v-list-item>
                      </template>
                    </AppDefaultSelect>
                  </div>
                </div>
              </div>
              <div
                class="d-flex"
                v-if="widgetProps.grouping.value === 'STATUS_CATEGORY'"
              >
                <div class="w-100">
                  <div class="label--small">{{ $t("statuses.statusCategories") }}</div>
                  <div class="d-flex align-center">
                    <AppDefaultSelect
                      :items="translateItems($constant.chartGroupOptions.STATUS_CATEGORY)"
                      :value="widgetProps.statusCategories"
                      :clearable="false"
                      multiple
                      return-object
                      @input="handleChartStatusCategoriesChange"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div class="w-100">
                  <div class="label--small">{{ $t("common.creationDate") }}</div>
                  <div class="d-flex align-center">
                    <AppDefaultSelect
                      :items="translateItems($constant.createdRangeOptions)"
                      :value="widgetProps.createdAtRange"
                      :clearable="false"
                      @input="handleCreatedRangeChange"
                      return-object
                    />
                  </div>
                </div>
                <div
                  class="pl-4"
                  style="width: 250px"
                  v-if="isDatetimeGrouping"
                >
                  <div class="label--small">
                    {{ $t("chart.timeGranularityTitle") }}
                  </div>
                  <div class="d-flex align-center">
                    <AppDefaultSelect
                      :items="translateItems(filteredDataPointIntervalOptions)"
                      :value="widgetProps.dataPointInterval.value"
                      :clearable="false"
                      return-object
                      @input="handleDataPointIntervalChange"
                    />
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col
            :style="{
              ...colDimensions,
              height: '100%',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
            }"
          >
            <AppDefaultChart
              v-if="isChartVisible && value"
              class="w-100 h-100"
              :chartOptions="chartOptions"
              :chartData="chartData"
            />
          </v-col>
        </v-row>
        <v-spacer />
      </v-card-text>
      <v-card-actions>
        <div class="w-100">
          <AppWarningAlert
            class="mb-3"
            v-if="!isCompatible"
          >
            {{ compatibilityWarningMessage }}
          </AppWarningAlert>
          <div>
            <v-btn
              depressed
              color="primary"
              @click="save"
            >
              {{ saveWidgetText }}
            </v-btn>
            <v-btn
              text
              @click="closeDialog"
            >
              {{ $t("common.close") }}
            </v-btn>
          </div>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { statusMixins } from "@/helpers/mixins";
import _ from "lodash";
import { mapState, mapActions } from "vuex";

export default {
  mixins: [statusMixins],
  props: {
    value: Boolean,
    data: Object,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      widgetProps: {
        chartName: "",
        isStacked: true,
        chartType: this.getDefaultWidgetProps().chartType,
        grouping: this.getDefaultWidgetProps().grouping,
        statusCategories: this.getDefaultWidgetProps().statusCategories,
        datasetType: "RESOURCE",
        datasets: this.getDefaultWidgetProps().datasets,
        dataPointInterval: this.getDefaultWidgetProps().dataPointInterval,
        createdAtRange: this.getDefaultWidgetProps().createdAtRange,
      },

      minDate: moment().subtract(1, "quarter").toDate(),
      maxDate: moment().toDate(),

      isChartVisible: true,
      incompatibleCombinations: [
        { chartType: "pie", chartGrouping: "CREATED_AT" },
        { chartType: "donut", chartGrouping: "CREATED_AT" },
        { chartType: "polarArea", chartGrouping: "CREATED_AT" },
        { chartType: "radar", chartGrouping: "CREATED_AT" },
        { chartType: "treemap", chartGrouping: "CREATED_AT" },
      ],
      colDimensions: {
        minWidth: "20rem",
      },
      chartData: {},
      randomCategoryMockDatesets: {
        TASK: [],
        PROJECT: [],
        FORM: [],
      },
      randomDatetimeMockDatasets: {
        TASK: [],
        PROJECT: [],
        FORM: [],
      },
    };
  },
  watch: {
    value: {
      handler(value) {
        if (!value) return;
        this.fetchFormCategories();
        if (this.data) {
          this.fillFormWithData();
        } else {
          this.generateRandomChartData();
        }
      },
    },
  },
  computed: {
    ...mapState("formCategories", {
      formCategories: (state) => state.categories,
      isLoadingFormCategories: (state) => state.isLoading,
    }),

    dialogTitle() {
      if (!!this.data) {
        return this.$t("chart.createDialog.editTitle");
      }
      return this.$t("chart.createDialog.createTitle");
    },
    saveWidgetText() {
      if (!!this.data) {
        return this.$t("chart.createDialog.editWidget");
      }
      return this.$t("chart.createDialog.addWidget");
    },
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    chartTypesWithCompatability() {
      return this.translateItems(this.$constant.chartTypes).map((type) => {
        const isCompatible = this.checkCombinationCompatibility({
          chartType: type.value,
          chartGrouping: this.widgetProps.grouping.value,
        });
        return { ...type, isCompatible };
      });
    },
    groupingOptionsWithCompatability() {
      return this.translateItems(this.$constant.chartGroupingOptions).map(
        (statusCategory) => {
          const isCompatible = this.checkCombinationCompatibility({
            chartType: this.widgetProps.chartType.value,
            chartGrouping: statusCategory.value,
          });
          return { ...statusCategory, isCompatible };
        },
      );
    },
    chartOptions() {
      let additionalOptions = {};
      if (this.isDatetimeGrouping) {
        additionalOptions = {
          xaxis: {
            max: this.maxDate.getTime(),
            min: this.minDate.getTime(),
          },
          tooltip: {
            x: {
              formatter: (value) => {
                const interval = this.widgetProps.dataPointInterval.value;
                switch (interval) {
                  case "year":
                    return `${moment(value).format("YYYY")}`;
                  case "quarter":
                    return `${this.$t("chart.intervals.quarter")} ${moment(value).format(
                      "Q YYYY",
                    )}`;
                  case "month":
                    return `${moment(value).format("MMM YYYY")}`;
                  case "week":
                    return `${this.$t("chart.intervals.week")} ${moment(value).format(
                      "WW YYYY",
                    )}`;
                  case "day":
                    return `${moment(value).format("DD MMM YYYY")}`;
                }
                return value;
              },
            },
          },
        };
      }

      const baseOptions = {
        chart: {
          type: this.widgetProps.chartType.value,
          stacked: this.widgetProps.isStacked,
        },
        xaxis: {
          type: this.widgetProps.grouping.axisType,
        },
      };

      const options = _.merge(baseOptions, additionalOptions);

      return options;
    },
    isDatetimeGrouping() {
      return this.widgetProps.grouping.axisType === "datetime";
    },
    isCategoryGrouping() {
      return this.widgetProps.grouping.axisType === "category";
    },
    isStackableChartType() {
      const notStackableTypes = ["pie", "donut", "polarArea"];
      return !notStackableTypes.includes(this.widgetProps.chartType.value);
    },
    isSingleDimensionChartType() {
      const singleDimensionTypes = ["pie", "donut", "polarArea", "radar"];
      return singleDimensionTypes.includes(this.widgetProps.chartType.value);
    },
    isCompatible() {
      return (
        this.chartTypesWithCompatability.some(
          (type) => type.value === this.widgetProps.chartType.value && type.isCompatible,
        ) &&
        this.groupingOptionsWithCompatability.some(
          (statusCategory) =>
            statusCategory.value === this.widgetProps.grouping.value &&
            statusCategory.isCompatible,
        )
      );
    },
    chartTitle() {
      const timeRange = this.widgetProps.createdAtRange.text.toLowerCase();
      const grouping = this.widgetProps.grouping.text.toLowerCase();

      let dataOptions;
      if (this.widgetProps.datasetType === "FORM_CATEGORY") {
        dataOptions = this.formCategories
          .filter((fc) => {
            return this.widgetProps.datasets.includes(fc.id);
          })
          .map((fc) => fc.text)
          .join(", ");
      } else if (this.widgetProps.datasets.length > 0) {
        dataOptions =
          this.widgetProps.datasets.map((option) => option.text).join(", ") ||
          this.$t("common.nothing");
      } else {
        dataOptions = this.$t("common.nothing");
      }

      const fallbackName = this.$t("chart.createDialog.fallbackName", {
        timeRange,
        dataOptions,
        grouping,
      });

      return this.widgetProps.chartName || fallbackName;
    },
    compatibilityWarningMessage() {
      if (!this.isCompatible) {
        const chartType = this.widgetProps.chartType.text;
        const grouping = this.widgetProps.grouping.text.toLowerCase();
        const incompatibleWarning = this.$t("chart.incompatibleWarning", {
          chartType,
          grouping,
        });

        return incompatibleWarning;
      }
      return "";
    },
    filteredDataPointIntervalOptions() {
      const range = this.widgetProps.createdAtRange.value;
      const options = this.translateItems(this.$constant.dataPointIntervalOptions);

      return options.map((option) => {
        let disabled = false;
        switch (range) {
          case "LAST_WEEK":
            disabled = option.value !== "day";
            break;
          case "LAST_MONTH":
            disabled = !["day", "week"].includes(option.value);
            break;
          case "LAST_QUARTER":
            disabled = !["day", "week", "month"].includes(option.value);
            break;
          case "LAST_YEAR":
            disabled = !["day", "week", "month", "quarter"].includes(option.value);
            break;
        }
        return { ...option, disabled };
      });
    },
    showResourceSelect() {
      return this.widgetProps.datasetType === "RESOURCE";
    },
    showFormCategoriesSelect() {
      return this.widgetProps.datasetType === "FORM_CATEGORY";
    },
  },
  methods: {
    ...mapActions("formCategories", ["getCategories"]),
    translateItems(items) {
      return items.map((item) => ({
        ...item,
        text: this.$options.filters.capitalize(this.$t(item.textKey)),
      }));
    },

    async fetchFormCategories() {
      try {
        await this.getCategories();
      } catch (error) {
        console.error("Error fetching form categories:", error);
      }
    },

    fillFormWithData() {
      const props = this.getFormattedWidgetPropsFromData();
      this.widgetProps = props;
      this.generateRandomChartData();
      this.refreshChart();
    },
    getFormattedWidgetPropsFromData() {
      if (!this.data) return;

      const chartType = this.getChartType(this.data.chartType);
      const grouping = this.getGrouping(this.data.grouping);
      const statusCategories = this.getStatusCategories(this.data.statusCategories);
      const datasetType = this.data.datasetType || "RESOURCE";
      const datasets = this.getDatasets(this.data.datasets);
      const dataPointInterval = this.getDataPointInterval(this.data.dataPointInterval);
      const createdAtRange = this.getCreatedRange(this.data.createdAtRange);

      return {
        chartName: this.data.chartName,
        isStacked: this.data.isStacked,
        chartType,
        grouping,
        statusCategories,
        datasetType,
        datasets,
        dataPointInterval,
        createdAtRange,
      };
    },
    getDefaultWidgetProps() {
      return {
        chartName: "",
        isStacked: true,
        chartType: this.getChartType("bar"),
        grouping: this.getGrouping("STATUS_CATEGORY"),
        statusCategories: this.translateItems(
          this.$constant.chartGroupOptions.STATUS_CATEGORY,
        ),
        datasetType: "RESOURCE",
        datasets: this.getDatasets(["FORM", "TASK"]),
        dataPointInterval: this.getDataPointInterval("week"),
        createdAtRange: this.getCreatedRange("LAST_QUARTER"),
      };
    },
    getChartType(value) {
      return this.translateItems(this.$constant.chartTypes).find(
        (type) => type.value === value,
      );
    },
    getGrouping(value) {
      return this.translateItems(this.$constant.chartGroupingOptions).find(
        (option) => option.value === value,
      );
    },

    getStatusCategories(values) {
      return this.$constant.chartGroupOptions.STATUS_CATEGORY.filter((option) =>
        values.includes(option.value),
      );
    },

    getDatasets(values) {
      if (!values) return [];

      if (this.data?.datasetType === "FORM_CATEGORY") {
        return values;
      } else {
        return this.translateItems(this.$constant.chartDatasetOptions).filter((option) =>
          values.includes(option.value),
        );
      }
    },

    getDataPointInterval(value) {
      return this.translateItems(this.$constant.dataPointIntervalOptions).find(
        (option) => option.value === value,
      );
    },

    getCreatedRange(value) {
      return this.translateItems(this.$constant.createdRangeOptions).find(
        (option) => option.value === value,
      );
    },
    resetForm() {
      this.widgetProps = this.getDefaultWidgetProps();
    },

    generateRandomChartData() {
      this.generateRandomCategoryData();
      this.generateRandomDatetimeData(); // Generate a large set of data points
      this.generateChartData();
    },
    generateRandomValue() {
      const minValue = 0.1;
      return Math.random() * (1 - minValue) + minValue;
    },
    generateRandomCategoryData() {
      const datasets = this.translateItems(this.$constant.chartDatasetOptions);
      const generateDataset = () => {
        // Always generate base data using status categories
        return this.translateItems(this.$constant.chartGroupOptions.STATUS_CATEGORY).map(
          (statusCategory) => ({
            key: statusCategory.value,
            label: statusCategory.text,
            value: this.generateRandomValue(),
            color: statusCategory.color,
          }),
        );
      };

      for (const dataset of datasets) {
        this.randomCategoryMockDatesets[dataset.value] = generateDataset();
      }
    },
    generateRandomDatetimeData() {
      const datasets = this.translateItems(this.$constant.chartDatasetOptions);
      const interval = this.widgetProps.dataPointInterval.value;
      const minDate = moment().subtract(1, "years").startOf(interval).add(1, "day");
      const maxDate = moment().startOf("day").add(1, "day");

      const generateDataset = (min, max, interval) => {
        const data = [];
        for (let m = moment(max); m.isSameOrAfter(min); m.subtract(1, interval)) {
          const dataPoint = { label: m.toDate(), value: this.generateRandomValue() };
          data.push(dataPoint);
        }

        return data;
      };

      datasets.forEach((dataset) => {
        this.randomDatetimeMockDatasets[dataset.value] = generateDataset(
          minDate,
          maxDate,
          interval,
        );
      });
    },
    generateChartData() {
      const datasets = [];

      if (this.widgetProps.datasetType === "FORM_CATEGORY") {
        // Form categories - keep existing logic
        this.widgetProps.datasets.forEach((categoryId, index) => {
          const category = this.formCategories.find((cat) => cat.id === categoryId);
          if (category) {
            const formCategoryDataset = {
              name: category.text,
              color: this.generateColorFromId(index),
              dataPoints: this.structureDataPoints({
                key: "FORM",
                datasetMultiplier: 2 / this.widgetProps.datasets.length,
              }),
            };
            datasets.push(formCategoryDataset);
          }
        });
      } else {
        // Resources - modify to respect order
        const {
          task: taskColor,
          project: projectColor,
          form: formColor,
        } = this.$vuetify.theme.themes.light;

        const colorMap = {
          FORM: formColor,
          TASK: taskColor,
          PROJECT: projectColor,
        };

        const nameMap = {
          FORM: this.$t("common.forms"),
          TASK: this.$t("common.tasks"),
          PROJECT: this.$t("common.projects"),
        };

        const multiplierMap = {
          FORM: 2,
          TASK: 1,
          PROJECT: 1.2,
        };

        // Process datasets in the order they were selected
        this.widgetProps.datasets.forEach((dataset) => {
          datasets.push({
            name: this.$options.filters.capitalize(nameMap[dataset.value]),
            color: colorMap[dataset.value],
            dataPoints: this.structureDataPoints({
              key: dataset.value,
              datasetMultiplier: multiplierMap[dataset.value],
            }),
          });
        });
      }

      this.chartData = { datasets };
    },
    structureDataPoints({ key, datasetMultiplier = 1 } = {}) {
      let dataPoints = [];
      let granularity = null;

      if (this.isDatetimeGrouping) {
        dataPoints = this.randomDatetimeMockDatasets[key];
        granularity = this.widgetProps.dataPointInterval.value;
      } else {
        dataPoints = this.randomCategoryMockDatesets[key];

        if (this.widgetProps.grouping.value === "STATUS_CATEGORY") {
          dataPoints = dataPoints.filter((item) => {
            return this.widgetProps.statusCategories.some((selectedGroup) => {
              return selectedGroup.value === item.key;
            });
          });
        }

        const multiplierMap = {
          ALL_TIME: "twoYears",
          LAST_WEEK: "week",
          LAST_MONTH: "month",
          LAST_QUARTER: "quarter",
          LAST_YEAR: "year",
        };
        granularity = multiplierMap[this.widgetProps.createdAtRange.value];
      }

      return dataPoints.map((dataPoint) => {
        const value = Math.floor(
          this.computeDataPointValue(dataPoint.value, granularity) * datasetMultiplier,
        );
        return {
          ...dataPoint,
          value,
        };
      });
    },
    checkCombinationCompatibility({ chartType, chartGrouping }) {
      return !this.incompatibleCombinations.some(
        (incompatibility) =>
          incompatibility.chartType === chartType &&
          incompatibility.chartGrouping === chartGrouping,
      );
    },
    computeDataPointValue(value, granularity) {
      return this.getGranularityMultiplier({ value, granularity })();
    },
    getGranularityMultiplier({ value, granularity }) {
      const granularityRandomizer = {
        day: () => value * 10,
        week: () => value * 50,
        month: () => value * 200,
        quarter: () => value * 800,
        year: () => value * 3000,
        twoYears: () => value * 3000,
      };
      return granularityRandomizer[granularity];
    },
    refreshChart() {
      this.isChartVisible = false;
      this.$nextTick(() => {
        this.isChartVisible = true;
      });
    },
    adjustDatasetForChartType() {
      const defaultDataSet = this.translateItems(this.$constant.chartDatasetOptions).find(
        (option) => option.value === "FORM",
      );

      if (this.isSingleDimensionChartType) {
        this.widgetProps.datasets =
          this.widgetProps.datasets.length > 0
            ? [this.widgetProps.datasets[0]]
            : [defaultDataSet];
      }
    },
    handleChartGroupingChange(value) {
      this.widgetProps.grouping = value;
      this.generateChartData();
      this.refreshChart();
    },
    handleChartStatusCategoriesChange(value) {
      this.widgetProps.statusCategories = value;
      this.generateChartData();
    },
    handleChartTypeChange(value) {
      this.widgetProps.chartType = value;
      this.adjustDatasetForChartType();
      this.refreshChart();
    },
    setTimeRangeDates(selectedRange) {
      const now = moment();
      let min, max;

      switch (selectedRange.value) {
        case "ALL_TIME":
          min = now.clone().subtract(1, "year");
          max = now;
          break;
        case "LAST_WEEK":
          min = now.clone().subtract(1, "weeks");
          max = now;
          break;
        case "LAST_MONTH":
          min = now.clone().subtract(1, "months");
          max = now;
          break;
        case "LAST_QUARTER":
          min = now.clone().subtract(1, "quarters");
          max = now;
          break;
        case "LAST_YEAR":
          min = now.clone().subtract(1, "years");
          max = now;
          break;
      }

      this.minDate = min.toDate();
      this.maxDate = max.toDate();
    },
    handleDatasetsChange(value) {
      if (this.isSingleDimensionChartType) {
        this.widgetProps.datasets = [value];
      } else {
        this.widgetProps.datasets = value;
      }
      this.generateChartData();
    },
    handleCreatedRangeChange(selectedRange) {
      this.widgetProps.createdAtRange = selectedRange;
      this.setTimeRangeDates(selectedRange);

      const isIncompatibleDataPointInterval = this.filteredDataPointIntervalOptions.some(
        (option) =>
          option.value === this.widgetProps.dataPointInterval.value && option.disabled,
      );
      if (isIncompatibleDataPointInterval) {
        this.handleDataPointIntervalChange(
          this.filteredDataPointIntervalOptions.find((option) => !option.disabled),
        );
      } else if (this.isCategoryGrouping) {
        this.generateChartData();
      }
    },
    handleDataPointIntervalChange(value) {
      this.widgetProps.dataPointInterval = value;
      this.generateRandomDatetimeData();
      this.generateChartData();
    },
    formatWidgetProps() {
      const {
        chartType,
        grouping,
        datasets,
        dataPointInterval,
        createdAtRange,
        statusCategories,
        datasetType,
      } = this.widgetProps;

      return {
        ...this.widgetProps,
        chartName: this.chartTitle,
        chartType: chartType.value,
        createdAtRange: createdAtRange.value,
        grouping: grouping.value,
        dataPointInterval: dataPointInterval.value,
        datasetType: datasetType,
        datasets:
          this.widgetProps.datasetType === "RESOURCE"
            ? datasets.map((dataset) => dataset.value)
            : datasets,
        statusCategories: statusCategories.map((statusCategory) => statusCategory.value),
      };
    },
    save() {
      const formattedProps = this.formatWidgetProps();
      this.$emit("save", formattedProps);
      this.resetForm();
      this.closeDialog();
    },
    closeDialog() {
      this.localValue = false;
    },
    generateColorFromId(id) {
      const baseColors = [
        "#FF4081", // Pink
        "#2196F3", // Blue
        "#4CAF50", // Green
        "#FFC107", // Amber
        "#9C27B0", // Purple
        "#00BCD4", // Cyan
        "#FF5722", // Deep Orange
        "#3F51B5", // Indigo
        "#009688", // Teal
        "#795548", // Brown
        "#607D8B", // Blue Grey
        "#E91E63", // Pink
        "#673AB7", // Deep Purple
        "#8BC34A", // Light Green
        "#FF9800", // Orange
      ];

      return baseColors[id % baseColors.length];
    },
    handleDatasetTypeChange(value) {
      this.widgetProps.datasetType = value;

      // Set default values based on dataset type
      if (value === "RESOURCE") {
        this.widgetProps.datasets = this.isSingleDimensionChartType
          ? [this.translateItems(this.$constant.chartDatasetOptions)[0]] // Default to first option (FORM)
          : this.translateItems(this.$constant.chartDatasetOptions).slice(0, 2); // Default to first two options
      } else {
        // For form categories, fetch them first and set default to first category
        if (this.formCategories.length > 0) {
          this.widgetProps.datasets = [this.formCategories[0].id];
        }
      }

      this.generateChartData();
    },
  },
  mounted() {
    this.generateRandomChartData();
  },
};
</script>
