<script>
import moment from "moment";

export default {
  props: {
    item: Object,
  },
  methods: {
    genTaskNameElm(task) {
      return this.$createElement("div", { class: "text-truncate" }, [
        this.$createElement(
          "span",
          {
            class: "primary-link--text underline-on-hover cursor-pointer",
            on: { click: (e) => this.$emit("item:taskClick", { task, item: this.item }) },
          },
          task.name,
        ),
      ]);
    },
    genTaskAssigneeElm(task) {
      return this.$createElement(
        "div",
        { class: "text-truncate" },
        task?.owner?.name || this.$t("common.notSet"),
      );
    },
    genTaskDueDateElm(task) {
      return this.$createElement(
        "div",
        task.dueDate
          ? moment(task.dueDate).format("DD.MM.YYYY")
          : this.$t("common.notSet"),
      );
    },
    genTaskDoneElm(task) {
      return this.$createElement(
        "div",
        { style: "width: 20px", class: "d-flex align-center" },
        [
          this.$createElement("AppCustomStatusIconBtn", {
            props: {
              model: "task",
              statusId: task?.statusId,
              loading: task?.isUpdatingStatus,
              disabled: task?.isUpdatingStatus,
            },
          }),
        ],
      );
    },
    genTasks() {
      const elms = [];
      if (Array.isArray(this.item?.tasks)) {
        for (let task of this.item.tasks) {
          elms.push(
            this.$createElement("div", { class: "form-preview__task-row pb-1" }, [
              this.genTaskNameElm(task),
              this.genTaskAssigneeElm(task),
              this.genTaskDueDateElm(task),
              this.genTaskDoneElm(task),
            ]),
          );
        }
      }
      return elms;
    },
  },
  render(h) {
    return h("div", { class: "py-2" }, [
      h("div", { class: "form-preview__task-row  pb-1" }, [
        h(
          "span",
          { class: "text-body-2 font-weight-bold" },
          this.$options.filters.capitalize(this.$t("common.tasks")),
        ),
        h(
          "span",
          { class: "text-body-2 font-weight-bold" },
          this.$t("taskManagement.list.responsible"),
        ),
        h(
          "span",
          { class: "text-body-2 font-weight-bold" },
          this.$t("taskManagement.list.dueDate"),
        ),
        h("span", { class: "text-body-2 font-weight-bold" }, this.$t("common.status")),
      ]),
      this.genTasks(),
    ]);
  },
};
</script>
