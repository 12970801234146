<template>
  <tr
    class="list_tr list_tr-height"
    :class="rowStateClasses"
    @contextmenu="dataTableItemMixins_onRightClick"
  >
    <AppDefaultTableCell
      v-for="(header, index) in headers"
      :key="index"
      :header="header"
      :isSelected="isSelected"
      :select="select"
      :index="index"
      :item="client"
    >
      <template v-if="header.value === dataFields.NAME">
        <div class="table-cell-content__breakable-long-text">
          <span class="d-inline-block">{{ client.name }}</span>
        </div>
      </template>

      <template v-if="header.value === dataFields.ORGANIZATION_NUMBER">
        {{ client.organizationNumber }}
      </template>

      <template v-if="header.value === dataFields.OWNER">
        <template v-if="client.owner">
          <AppUserAvatar
            :user="client.owner"
            show-name
            :truncate="15"
          />
        </template>
      </template>

      <template v-if="header.value === dataFields.TYPE">
        <div class="table-cell-content__breakable--margin">
          <template v-if="client.type">
            {{ $t(`contact.client.type.${client.type.toLowerCase()}`) }}
            <template v-if="client.isPrivate">
              ({{ $t("contact.client.customerType.private") }})
            </template>
          </template>
        </div>
      </template>

      <template v-if="header.value === dataFields.PHONE">
        <AppPhoneLinkText :phone="client?.phone" />
      </template>

      <template v-if="header.value === dataFields.EMAIL">
        <AppLinkText
          :link="`mailto:${client.email}`"
          underline-on-hover
        >
          {{ client.email }}
        </AppLinkText>
      </template>

      <template v-if="header.value === dataFields.ACTIVE">
        <AppDefaultCheckboxIcon
          :isSelected="client.isActive"
          :disabled="!!projectId"
        />
      </template>

      <template v-if="header.value === dataFields.MENU">
        <v-btn
          icon
          @click="dataTableItemMixins_onEllipsisClick"
          ref="ellipsis"
        >
          <v-icon>
            {{ $icons.REGULAR.ACTION.MENU }}
          </v-icon>
        </v-btn>
      </template>
    </AppDefaultTableCell>
  </tr>
</template>

<script>
import { dataTableItemMixins } from "@/helpers/mixins";

export default {
  mixins: [dataTableItemMixins],
  props: {
    client: Object,
    isContextMenuActiveItem: Boolean,
    isSelected: Boolean,
    select: Function,
    dataFields: Object,
    headers: Array,
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    rowStateClasses() {
      return {
        "list_tr--active": this.isContextMenuActiveItem,
        "list_tr--selected": this.isSelected,
      };
    },
  },
  methods: {
    openMenu(params) {
      this.$emit("menu:click", { client: this.client, ...params });
    },
  },
};
</script>
