<template>
  <div
    class="pl-3 pb-1 text-overline subtitle--text d-flex w-100 align-center"
    v-if="!isMini"
  >
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("appDrawer", {
      isMini: "isDrawerMiniVariant",
    }),
  },
};
</script>
