<template>
  <tr
    class="list_tr list_tr-height"
    :class="rowStateClasses"
    @contextmenu="dataTableItemMixins_onRightClick"
  >
    <AppDefaultTableCell
      v-for="(header, index) in headers"
      :key="index"
      :header="header"
      :isSelected="isSelected"
      :select="select"
      :index="index"
      :item="user"
    >
      <template v-if="header.value === 'fullName'">
        <AppUserAvatar
          :user="user"
          show-name
          :truncate="70"
        />
      </template>

      <template v-if="header.value === 'email'">
        <AppLinkText
          :link="`mailto:${user.email}`"
          underline-on-hover
        >
          <AppTextTruncateValue
            :text="user.email"
            :truncate="30"
          />
        </AppLinkText>
      </template>

      <template v-if="header.value === 'phone'">
        <AppPhoneLinkText :phone="phone" />
      </template>

      <template v-if="header.value === 'workTitleLabel.name'">
        <AppLabelBtn
          ref="jobTitleLabelBtn"
          :title="jobTitle"
          :isUpdating="user.isUpdatingLabel"
        />
      </template>

      <template v-if="header.value === 'role.name'">
        <AppColorChip :text="user.role.name" />
      </template>

      <template v-if="header.value === 'status'">
        <AppUserStatusChip :user="user" />
      </template>

      <template v-if="header.value === 'number'">
        {{ user.number }}
      </template>

      <template v-if="header.value === 'menu'">
        <v-btn
          icon
          @click="dataTableItemMixins_onEllipsisClick"
          ref="ellipsis"
        >
          <v-icon>{{ $icons.REGULAR.ACTION.MENU }}</v-icon>
        </v-btn>
      </template>
    </AppDefaultTableCell>
  </tr>
</template>

<script>
import { dataTableItemMixins } from "@/helpers/mixins";

export default {
  mixins: [dataTableItemMixins],
  props: {
    user: Object,
    isHighlighted: Boolean,
    labels: Array,
    canEditLabels: Boolean,
    jobTitleLabel: Object,
    select: Function,
    isSelected: Boolean,
    isContextMenuActiveItem: Boolean,
    headers: Array,
    dataFields: Object,
  },
  data() {
    return {
      isEditingWorkTitle: false,
      menu: {
        show: false,
        x: 0,
        y: 0,
      },
    };
  },
  computed: {
    rowStateClasses() {
      return {
        "list_tr--active": this.isContextMenuActiveItem,
        "list_tr--selected": this.isSelected,
      };
    },
    phone() {
      return this.user?.phone;
    },
    jobTitle() {
      return this.jobTitleLabel?.name;
    },
    phoneRules() {
      return [
        this.form.phone.valid
          ? true
          : this.$t("settings.account.details.invalidPhoneNumber"),
      ];
    },
  },
  methods: {
    openMenu(params) {
      this.$emit("menu:click", {
        user: this.user,
        ...params,
      });
    },
  },
};
</script>
