<template>
  <AppContextMenu
    ref="menu"
    :closeOnContentClick="false"
  >
    <div class="pa-3">
      <div class="d-flex align-center">
        <div>
          <div class="text-subtitle-2">
            {{ $t("settings.corporateManagement.editAccessGranted") }}
          </div>
          <div class="text-caption">
            {{ $t("settings.corporateManagement.giveAccessMenu.giveAccessSubtitle") }}
          </div>
        </div>
        <v-spacer></v-spacer>
        <AppStonlyGuideBtn guideId="LdnJRjRYxv" />
      </div>
      <v-list dense>
        <v-list-item
          v-for="(access, key) in accessesGiven"
          :key="key"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{
                $t(
                  `settings.corporateManagement.detailsDialog.sections.accessControl.cards.${key}.title`,
                )
              }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn-toggle
              v-model="accessesGiven[key]"
              dense
              color="primary"
              :mandatory="items.length === 1"
            >
              <v-btn
                :disabled="!canEditCorporateManagement"
                small
                height="40"
                width="110"
                class="text-transform-none"
                v-for="btn in buttons"
                :key="btn.text"
                :value="btn.value"
              >
                {{ btn.text }}
              </v-btn>
            </v-btn-toggle>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-btn
        :disabled="!canEditCorporateManagement"
        depressed
        width="110"
        color="primary"
        class="mt-3"
        @click="onSubmit"
      >
        {{ $t("common.confirm") }}
      </v-btn>
    </div>
  </AppContextMenu>
</template>

<script>
import { permissionMixins } from "@/helpers/mixins";

export default {
  mixins: [permissionMixins],
  props: {
    items: Array,
  },
  data() {
    return {
      accessesGiven: {
        templates: null,
        storages: null,
      },
    };
  },
  computed: {
    canEditCorporateManagement() {
      return this.permissionMixins_corporateManagement.edit.can;
    },
    buttons() {
      return [
        {
          text: this.$t("settings.corporateManagement.giveAccessMenu.access"),
          color: "success",
          value: true,
        },
        {
          text: this.$t("settings.corporateManagement.giveAccessMenu.noAccess"),
          color: "error",
          value: false,
        },
      ];
    },
  },
  watch: {
    items: {
      handler(val) {
        if (val.length === 1) {
          this.accessesGiven = JSON.parse(JSON.stringify(val[0].accessesGiven));
        } else {
          this.accessesGiven = {
            templates: null,
            storages: null,
          };
        }
      },
    },
  },
  methods: {
    onPermissionChange(key, value) {
      this.accessesGiven[key] = this.accessesGiven[key] === value ? null : value;
    },
    onSubmit() {
      const changedAccessesGiven = Object.entries(this.accessesGiven).reduce(
        (acc, [key, value]) => {
          if (value !== null) {
            acc[key] = value;
          }
          return acc;
        },
        {},
      );

      this.$emit("submit", {
        ids: this.items.map((item) => item.id),
        data: changedAccessesGiven,
      });
      this.close();
    },
    close() {
      this.$refs.menu.close();
    },
    open(e) {
      this.$refs.menu.open(e);
    },
  },
};
</script>
