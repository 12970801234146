<template>
  <div>
    <!-- Personal Section -->
    <AppNavigationList
      v-if="hasVisibleItemsForSection($constant.SETTINGS_DRAWER_SECTIONS.PERSONAL)"
    >
      <AppContentSectionHeader>
        {{ $t("drawer.routeLabel.personal") }}
      </AppContentSectionHeader>
      <AppNavigationItem
        v-for="item in getItemsForSection($constant.SETTINGS_DRAWER_SECTIONS.PERSONAL)"
        v-if="showRoute(item)"
        :key="item.routeName"
        :to="{ name: item.routeName }"
        :prependIcon="item.icon"
        :title="$t(item.title)"
        prepend-icon-small
        replace
      />
    </AppNavigationList>

    <!-- Workspace Section -->
    <AppMiniContentSelectionDivider
      v-if="hasVisibleItemsForSection($constant.SETTINGS_DRAWER_SECTIONS.WORKSPACE)"
    />
    <AppNavigationList
      v-if="hasVisibleItemsForSection($constant.SETTINGS_DRAWER_SECTIONS.WORKSPACE)"
    >
      <AppContentSectionHeader>
        {{ $t("drawer.routeLabel.workspace") }}
      </AppContentSectionHeader>
      <AppNavigationItem
        v-for="item in getItemsForSection($constant.SETTINGS_DRAWER_SECTIONS.WORKSPACE)"
        v-if="showRoute(item)"
        :key="item.routeName"
        :to="{ name: item.routeName }"
        :prependIcon="item.icon"
        :title="$t(item.title)"
        prepend-icon-small
        replace
      />
    </AppNavigationList>

    <!-- Settings Section -->
    <AppMiniContentSelectionDivider
      v-if="hasVisibleItemsForSection($constant.SETTINGS_DRAWER_SECTIONS.SETTINGS)"
    />
    <AppNavigationList
      v-if="hasVisibleItemsForSection($constant.SETTINGS_DRAWER_SECTIONS.SETTINGS)"
    >
      <AppContentSectionHeader>
        {{ $t("drawer.routeLabel.settings") }}
      </AppContentSectionHeader>
      <AppNavigationItem
        v-for="item in getItemsForSection($constant.SETTINGS_DRAWER_SECTIONS.SETTINGS)"
        :key="item.routeName"
        v-if="showRoute(item)"
        :to="{ name: item.routeName }"
        :prependIcon="item.icon"
        :title="$t(item.title)"
        prepend-icon-small
        replace
      />
    </AppNavigationList>

    <!-- Support Section -->
    <AppMiniContentSelectionDivider
      v-if="hasVisibleItemsForSection($constant.SETTINGS_DRAWER_SECTIONS.SUPPORT)"
    />
    <AppNavigationList
      v-if="hasVisibleItemsForSection($constant.SETTINGS_DRAWER_SECTIONS.SUPPORT)"
    >
      <AppContentSectionHeader>
        {{ $t("drawer.routeLabel.support") }}
      </AppContentSectionHeader>
      <AppNavigationItem
        v-for="item in getItemsForSection($constant.SETTINGS_DRAWER_SECTIONS.SUPPORT)"
        :key="item.routeName"
        v-if="showRoute(item)"
        :to="{ name: item.routeName }"
        :prependIcon="item.icon"
        :title="$t(item.title)"
        prepend-icon-small
        replace
      />
    </AppNavigationList>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { permissionHelpers } from "@/helpers/util";

export default {
  computed: {
    navigationItems() {
      return [
        // Personal Section
        {
          routeName: this.$routeNames.SETTINGS.PROFILE,
          title: "drawer.route.profile",
          section: this.$constant.SETTINGS_DRAWER_SECTIONS.PERSONAL,
          icon: this.$icons.REGULAR.ROUTE.PROFILE_SETTINGS,
          access: true,
        },
        {
          routeName: this.$routeNames.SETTINGS.WORKSPACES,
          title: "drawer.route.workspaces",
          section: this.$constant.SETTINGS_DRAWER_SECTIONS.PERSONAL,
          icon: this.$icons.REGULAR.ROUTE.WORKSPACE,
          access: true,
        },
        {
          routeName: this.$routeNames.SETTINGS.NOTIFICATIONS,
          title: "drawer.route.alerts",
          section: this.$constant.SETTINGS_DRAWER_SECTIONS.PERSONAL,
          icon: this.$icons.REGULAR.ROUTE.NOTIFICATION,
          access: true,
        },

        // Workspace Section
        {
          routeName: this.$routeNames.SETTINGS.ACCOUNT,
          title: "drawer.route.account",
          section: this.$constant.SETTINGS_DRAWER_SECTIONS.WORKSPACE,
          icon: this.$icons.REGULAR.ROUTE.ACCOUNT_SETTINGS,
          access: this.canView(this.$routeNames.SETTINGS.ACCOUNT),
        },
        {
          routeName: this.$routeNames.SETTINGS.CORPORATE_MANAGEMENT,
          title: "drawer.route.corporateManagement",
          section: this.$constant.SETTINGS_DRAWER_SECTIONS.WORKSPACE,
          icon: this.$icons.REGULAR.ROUTE.CORPORATE_MANAGEMENT,
          access: this.canView(this.$routeNames.SETTINGS.CORPORATE_MANAGEMENT),
        },
        {
          routeName: this.$routeNames.SETTINGS.BILLING,
          title: "drawer.route.billing",
          section: this.$constant.SETTINGS_DRAWER_SECTIONS.WORKSPACE,
          icon: this.$icons.REGULAR.ROUTE.BILLING,
          access: this.canView(this.$routeNames.SETTINGS.BILLING),
        },
        {
          routeName: this.$routeNames.SETTINGS.PRODUCTS,
          title: "drawer.route.products",
          section: this.$constant.SETTINGS_DRAWER_SECTIONS.WORKSPACE,
          icon: this.$icons.REGULAR.ROUTE.PRODUCT,
          access: this.canView(this.$routeNames.SETTINGS.PRODUCTS),
        },
        {
          routeName: this.$routeNames.SETTINGS.SYSTEM_SETTINGS,
          title: "drawer.route.systemSettings",
          section: this.$constant.SETTINGS_DRAWER_SECTIONS.WORKSPACE,
          icon: this.$icons.REGULAR.ROUTE.SYSTEM_SETTINGS,
          access: this.canView(this.$routeNames.SETTINGS.SYSTEM_SETTINGS),
        },

        // Settings Section
        {
          routeName: this.$routeNames.SETTINGS.MODULE_CONTROL,
          title: "drawer.route.moduleControl",
          section: this.$constant.SETTINGS_DRAWER_SECTIONS.SETTINGS,
          icon: this.$icons.REGULAR.ROUTE.MODULE_CONTROL,
          access: this.canView(this.$routeNames.SETTINGS.MODULE_CONTROL),
        },
        {
          routeName: this.$routeNames.SETTINGS.INTEGRATIONS,
          title: "drawer.route.integrations",
          section: this.$constant.SETTINGS_DRAWER_SECTIONS.SETTINGS,
          icon: this.$icons.REGULAR.ROUTE.INTEGRATION_SETTINGS,
          access: this.canView(this.$routeNames.SETTINGS.INTEGRATIONS),
        },

        // Support Section
        {
          routeName: this.$routeNames.SETTINGS.SUPPORT_WORKSPACES,
          title: "drawer.route.supportWorkspaces",
          section: this.$constant.SETTINGS_DRAWER_SECTIONS.SUPPORT,
          icon: this.$icons.REGULAR.ROUTE.WORKSPACE,
          access: this.isSupportUser,
        },
      ];
    },
    ...mapGetters("auth", {
      isSupportUser: "isSupportUser",
    }),
  },
  methods: {
    showRoute(item) {
      return item.access;
    },
    getItemsForSection(section) {
      return this.navigationItems.filter((item) => item.section === section);
    },
    canView(routeName) {
      return permissionHelpers.canViewRoute(routeName);
    },
    hasVisibleItemsForSection(section) {
      return this.navigationItems
        .filter((item) => item.section === section)
        .some((item) => this.showRoute(item));
    },
    shouldShowDivider(previousSection, nextSection) {
      return (
        this.hasVisibleItemsForSection(previousSection) &&
        this.hasVisibleItemsForSection(nextSection)
      );
    },
  },
};
</script>
