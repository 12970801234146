<template>
  <div>
    <v-card
      flat
      class="bg-light-grey"
    >
      <!-- Header -->
      <div class="d-flex align-center mb-4">
        <v-icon
          color="primary"
          class="mr-2"
        >
          {{ $icons.REGULAR.COMMON.HOME || "fas fa-home" }}
        </v-icon>
        <div class="text-h6 font-weight-medium">
          {{ $t("projectView.info.boligmappa.connectedProperty") }}
        </div>
      </div>

      <!-- Boligmappa ID -->
      <AppProjectInfoIntegrationRow
        :label="$t('projectView.info.boligmappa.boligmappaNumber')"
        :value="boligmappaNumber"
        :icon="$icons.REGULAR.COMMON.HASHTAG || 'fas fa-hashtag'"
        :single-line="true"
        class="mb-4"
      />

      <!-- Address -->
      <div>
        <AppProjectInfoIntegrationRow
          class="mb-4"
          :label="$t('projectView.info.boligmappa.boligmappaAddress')"
          :value="boligmappaAddress"
          :icon="$icons.REGULAR.COMMON.LOCATION_DOT || 'fas fa-map-marker-alt'"
          :single-line="false"
        />
      </div>
    </v-card>

    <v-btn
      depressed
      color="primary"
      block
      href="https://proff.boligmappa.no/Account/Logon"
      target="_blank"
      class="text-transform-none"
    >
      <v-icon
        small
        left
      >
        {{ $icons.REGULAR.COMMON.EXTERNAL_LINK || "fas fa-external-link-alt" }}
      </v-icon>
      {{ $t("projectView.info.boligmappa.logInToBoligmappa") }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "AppBoligmappaDetails",

  props: {
    boligmappaNumber: {
      type: String,
      required: true,
    },
    boligmappaAddress: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.text-transform-none {
  text-transform: none !important;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
</style>
