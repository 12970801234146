import { ROUTE_NAMES } from "@/helpers/routeNames";
import { authGuard, userGuard } from "@/auth";
import multiguard from "../multiguard";
import { HELP_CATEGORIES } from "@/helpers/constants/help";
const { ROOT } = ROUTE_NAMES.HOME;

export const homeRoutes = [
  {
    path: "/home",
    name: ROOT,
    meta: {
      helpCategory: HELP_CATEGORIES.HOME,
    },
    beforeEnter: multiguard([authGuard, userGuard]),
    component: () => import(/* webpackChunkName: "home" */ "../../pages/HomePage"),
  },
];
