import _ from "lodash";

export const formTemplateBuilderMixins = {
  data() {
    return {
      formTemplateBuilderMixins_responseTypes: [
        { key: this.$constant.TEXTAREA, icon: this.$icons.REGULAR.COMMON.TEXT },
        { key: this.$constant.DATE_FIELD, icon: this.$icons.REGULAR.COMMON.CALENDAR_DAY },
        {
          key: this.$constant.RADIO_GROUP,
          icon: this.$icons.REGULAR.COMMON.CIRCLE_CHECK,
          values: ["YES", "NO", "NA"],
          showValues: true,
        },
        {
          key: this.$constant.RADIO_GROUP,
          icon: this.$icons.REGULAR.COMMON.CIRCLE_CHECK,
          values: ["OK", "DEVIATION", "NA"],
          showValues: true,
        },
        {
          key: this.$constant.MULTI_CHOICE,
          icon: this.$icons.REGULAR.COMMON.SQUARE_CHECK,
          values: [""],
        },
        {
          key: this.$constant.TABLE,
          icon: this.$icons.REGULAR.ACTION.TABLE,
          values: [{ key: "", text: "", type: "TEXT_FIELD" }],
        },
      ],
    };
  },
  methods: {
    formTemplateBuilderMixins_updateItem({ item, body, extra }) {
      this.$emit("item:update", { item, body, extra });
    },
    formTemplateBuilderMixins_moveItem({ item, body }) {
      this.$emit("item:move", { item, body });
    },
    formTemplateBuilderMixins_deleteItem({ item }) {
      this.$emit("item:delete", { item });
    },
    formTemplateBuilderMixins_createItem({ body, tempId, onSuccess }) {
      this.$emit("item:create", { body, tempId, onSuccess });
    },
    formTemplateBuilderMixins_openOptionsMenu(e) {
      this.$emit("optionsMenu:open", e);
    },
    formTemplateBuilderMixins_openDefaultAnswerTemplateMenu(e) {
      this.$emit("defaultAnswerTemplateMenu:open", e);
    },
    formTemplateBuilderMixins_getRootItems(items) {
      return items.filter(
        (item) => item.parentId === null || item.parentId === undefined,
      );
    },
    formTemplateBuilderMixins_getChildrenByParentId(items, parentId) {
      return items.filter((item) => item.parentId === parentId);
    },
    formTemplateBuilderMixins_getHierarchyByParentId(items, parentId) {
      return items
        .filter((item) => item.parentId === parentId)
        .map((item) => {
          const children = this.formTemplateBuilderMixins_getHierarchyByParentId(
            items,
            item.id,
          );
          return {
            ...item,
            children: item.type === this.$constant.GROUP ? children : [],
          };
        });
    },
    formTemplateBuilderMixins_getNextIndex(items) {
      if (!Array.isArray(items) || items.length === 0) return 0;
      const index = items
        .map((item) => item.index)
        .reduce((acc, item) => {
          return Math.max(acc, item);
        });

      return index + 1;
    },
    formTemplateBuilderMixins_genIndexingText({
      sectionIndex,
      parentGroupIndex,
      responseItemIndex,
    }) {
      let sectionNumber = "#";
      if (sectionIndex !== undefined) {
        sectionNumber += sectionIndex + 1;
      }

      let parentGroupNumber = "";
      if (parentGroupIndex !== undefined) {
        parentGroupNumber = "." + (parentGroupIndex + 1);
      }

      let responseItemNumber = "";
      if (responseItemIndex !== undefined) {
        responseItemNumber = "." + (responseItemIndex + 1);
      }

      let indexingText = sectionNumber + parentGroupNumber + responseItemNumber;

      return indexingText;
    },
    formTemplateBuilderMixins_validateTextInputValue({ newVal, oldVal }) {
      const isNewValue = newVal !== oldVal;
      const isBothEmpty = _.isEmpty(newVal) && _.isEmpty(oldVal);
      const isValid = typeof newVal !== "undefined" && isNewValue && !isBothEmpty;
      return isValid;
    },
  },
};
