var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('AppContextMenu',{ref:"contextMenu",attrs:{"width":"200"},on:{"input":_vm.onContextMenuInput}},[_c('AppMenuEditBtn',{attrs:{"disabled":!_vm.canEditResourceView,"text":_vm.$t('common.rename')},on:{"click":_vm.renameResourceView}}),_c('AppMenuItem',{attrs:{"disabled":!_vm.canDeleteResourceView,"text":_vm.selectedResourceView?.isPrivate
          ? _vm.$t('views.actions.makePublic')
          : _vm.$t('views.actions.makePrivate'),"icon":_vm.selectedResourceView?.isPrivate
          ? _vm.$icons.REGULAR.COMMON.LOCK_OPEN
          : _vm.$icons.REGULAR.COMMON.LOCK},on:{"click":_vm.togglePrivate}}),_c('v-divider',{staticClass:"my-2"}),_c('AppMenuDeleteBtn',{attrs:{"disabled":!_vm.canDeleteResourceView},on:{"click":_vm.openDeleteDialog}})],1),_c('AppDeleteConfirmationDialog',{attrs:{"title":_vm.$t('views.delete.title'),"subtitle":_vm.$t('views.delete.confirmMessage', { name: _vm.deleteDialog.resourceViewName }),"validator":_vm.$t('common.delete').toLowerCase(),"validatorText":_vm.$t('views.delete.validatorText', {
        validator: _vm.$t('common.delete').toLowerCase(),
      }),"item":{ resourceViewId: _vm.deleteDialog.resourceViewId }},on:{"delete":_vm.onDeleteConfirmed},model:{value:(_vm.deleteDialog.show),callback:function ($$v) {_vm.$set(_vm.deleteDialog, "show", $$v)},expression:"deleteDialog.show"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }