import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function getRoles({ queryParams } = {}) {
  return axios
    .get(`${apiUrl}role`, { params: queryParams })
    .then(({ data }) => {
      const { roles } = data;
      return roles;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
