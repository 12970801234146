import { redirectToLoginGuard, redirectToSignupGuard } from "@/auth";
import { ROUTE_NAMES } from "@/helpers/routeNames";

const { ROOT, LOGIN, SIGNUP } = ROUTE_NAMES.AUTH;

export const authRoutes = [
  {
    path: "/auth",
    name: ROOT,
    redirect: "auth/login",
    children: [
      {
        path: "login",
        name: LOGIN,
        beforeEnter: redirectToLoginGuard,
      },
      {
        path: "signup",
        name: SIGNUP,
        beforeEnter: redirectToSignupGuard,
      },
    ],
  },
];
