<template>
  <AppDataListWidgetItemBase
    @status:click="$emit('status:click', $event)"
    @title:click="onfillOutFormClick"
    @subTitle:click="onGoToProjectClick"
    :title="form.name"
    :subTitle="projectName"
    :statusId="statusId"
    statusModel="form"
    :user="form.owner"
    :item="form"
    :underTextVHtml="underTextVHtml"
  />
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  props: {
    form: Object,
  },
  computed: {
    ...mapGetters("statuses", {
      isClosedStatus: "isClosedStatus",
    }),
    statusId() {
      return this.form?.statusId;
    },
    projectName() {
      return this.form?.project?.name;
    },
    underTextVHtml() {
      const { owner, createdAt, statusUpdater, statusUpdatedAt } = this.form || {};

      const createdByText = this.getCreatedByText(owner);
      const createdDateText = this.getCreatedDateText(createdAt);
      const statusUpdatedByText = this.getStatusUpdateByText(statusUpdater);
      const statusUpdatedAtText = this.getStatusUpdatedAtText(statusUpdatedAt);

      const created = this.getCreatedText(createdByText, createdDateText);
      const closed = this.getClosedText(statusUpdatedByText, statusUpdatedAtText);

      return this.$t("widget.formList.underTextVhtml", {
        created,
        br: closed && created ? "<br>" : "",
        closed,
      });
    },
  },
  methods: {
    onfillOutFormClick() {
      this.$router.push({
        name: this.$routeNames.FORM_VIEW.ROOT,
        params: { formId: this.form.id },
      });
    },
    onGoToProjectClick() {
      const projectUrl = this.$router.resolve({
        name: this.$routeNames.PROJECT.VIEW,
        params: { projectId: this.form.project.id },
      }).href;
      window.open(projectUrl, "_blank");
    },
    formatDate(date) {
      if (!date) return;
      return moment(date).format("DD.MM.YYYY");
    },
    getCreatedByText(owner) {
      return owner?.name
        ? this.$t("widget.formList.underTextCreatedBy", { createdBy: owner.name })
        : "";
    },
    getCreatedDateText(createdAt) {
      return createdAt
        ? this.$t("widget.formList.underTextCreatedOn", {
            createdDate: this.formatDate(createdAt),
          })
        : "";
    },
    getStatusUpdateByText(statusUpdater) {
      return statusUpdater?.fullName
        ? this.$t("widget.formList.underTextUpdatedBy", {
            updatedBy: statusUpdater.fullName,
          })
        : "";
    },
    getStatusUpdatedAtText(statusUpdatedAt) {
      return statusUpdatedAt
        ? this.$t("widget.formList.underTextUpdatedOn", {
            updatedAt: this.formatDate(statusUpdatedAt),
          })
        : "";
    },
    getCreatedText(createdBy, createdDate) {
      return createdBy || createdDate
        ? this.$t("widget.formList.underTextCreated", { createdBy, createdDate })
        : "";
    },
    getClosedText(statusUpdateBy, statusUpdatedAt) {
      const isClosed = this.isClosedStatus("form", this.statusId);
      return (statusUpdateBy || statusUpdatedAt) && isClosed
        ? this.$t("widget.formList.underTextClosed", {
            closedBy: statusUpdateBy,
            closedDate: statusUpdatedAt,
          })
        : "";
    },
  },
};
</script>
