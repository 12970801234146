const state = {
  isVisible: false,
};

const getters = {
  isVisible: (state) => state.isVisible,
};

const actions = {
  show({ commit }) {
    commit("setVisibility", true);
  },

  hide({ commit }) {
    commit("setVisibility", false);
  },

  toggle({ commit, state }) {
    commit("setVisibility", !state.isVisible);
  },
};

const mutations = {
  setVisibility(state, value) {
    state.isVisible = value;
  },
};

export const helpPanel = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
