import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function getTeams({} = {}) {
  const body = {
    params: {},
  };
  return axios
    .get(`${apiUrl}team`, body)
    .then(({ data }) => {
      const { teams } = data;
      return teams;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
