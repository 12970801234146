<template>
  <div>
    <v-card
      flat
      class="bg-light-grey"
    >
      <!-- Header -->
      <div class="d-flex align-center mb-4">
        <v-icon
          color="primary"
          class="mr-2"
        >
          {{ $icons.SOLID.COMMON.HOUSE_CRACK }}
        </v-icon>
        <div class="text-h6 font-weight-medium">
          {{ $t("projectView.info.in4mo.connectedCase") }}
        </div>
      </div>

      <!-- Case ID and Status -->
      <div class="mb-4">
        <div class="d-flex justify-space-between align-center">
          <div class="d-flex align-center">
            <v-icon
              small
              color="grey darken-1"
              class="mr-2"
            >
              {{ $icons.REGULAR.COMMON.HASHTAG || "fas fa-hashtag" }}
            </v-icon>
            <span class="font-weight-medium">{{ insuranceCase.caseId }}</span>
          </div>
          <v-chip
            x-small
            :color="getStatusColor(insuranceCase.status)"
            class="white--text"
          >
            {{ insuranceCase.status }}
          </v-chip>
        </div>
      </div>

      <!-- Priority and Created Date -->
      <div class="mb-4 pb-3 border-b">
        <AppProjectInfoIntegrationRow
          :label="$t('projectView.info.in4mo.priority')"
          :value="insuranceCase.priority"
          :icon="$icons.REGULAR.COMMON.FLAG || 'fas fa-flag'"
          :single-line="true"
          class="mb-2"
        />
        <AppProjectInfoIntegrationRow
          :label="$t('projectView.info.in4mo.createdAt')"
          :value="formatDate(insuranceCase.createdAt)"
          :icon="$icons.REGULAR.COMMON.CALENDAR || 'fas fa-calendar-alt'"
          :single-line="true"
        />
      </div>

      <!-- Description -->
      <div
        v-if="insuranceCase.description"
        class="mb-4"
      >
        <div class="d-flex align-center mb-2">
          <v-icon
            small
            color="grey darken-1"
            class="mr-2"
          >
            {{ $icons.REGULAR.COMMON.ALIGN_LEFT }}
          </v-icon>
          <span class="subtitle-2 font-weight-medium">
            {{ $t("projectView.info.in4mo.description") }}
          </span>
        </div>
        <div class="pa-3 rounded-lg grey lighten-5 text-body-2">
          {{ insuranceCase.description }}
        </div>
      </div>

      <!-- Notes -->
      <div
        v-if="insuranceCase.notes"
        class="mb-4 pb-3 border-b"
      >
        <div class="d-flex align-center mb-2">
          <v-icon
            small
            color="grey darken-1"
            class="mr-2"
          >
            {{ $icons.REGULAR.COMMON.STICKY_NOTE || "fas fa-sticky-note" }}
          </v-icon>
          <span class="subtitle-2 font-weight-medium">
            {{ $t("projectView.info.in4mo.notes") }}
          </span>
        </div>
        <div class="pa-3 rounded-lg grey lighten-5 text-body-2">
          {{ insuranceCase.notes }}
        </div>
      </div>

      <!-- Damage info -->
      <div
        v-if="insuranceCase.damage"
        class="mb-4 pb-3 border-b"
      >
        <div class="d-flex align-center mb-3">
          <v-icon
            small
            color="error"
            class="mr-2"
          >
            {{
              $icons.REGULAR.COMMON.EXCLAMATION_TRIANGLE || "fas fa-exclamation-triangle"
            }}
          </v-icon>
          <span class="subtitle-1 font-weight-medium">
            {{ $t("projectView.info.in4mo.damageInfo") }}
          </span>
        </div>

        <AppProjectInfoIntegrationRow
          v-if="insuranceCase.damage.type"
          :label="$t('projectView.info.in4mo.damageType')"
          :value="insuranceCase.damage.type"
          :single-line="true"
          class="mb-2"
        />

        <AppProjectInfoIntegrationRow
          v-if="insuranceCase.damage.incidentDate"
          :label="$t('projectView.info.in4mo.incidentDate')"
          :value="formatDate(insuranceCase.damage.incidentDate)"
          :single-line="true"
          class="mb-2"
        />

        <AppProjectInfoIntegrationRow
          v-if="insuranceCase.damage.address"
          :label="$t('projectView.info.in4mo.damageLocation')"
          :single-line="false"
          class="mb-2"
        >
          <template
            v-if="
              insuranceCase.damage.address.zipCode || insuranceCase.damage.address.city
            "
          >
            {{ insuranceCase.damage.address.zipCode }}
            {{ insuranceCase.damage.address.city }}
          </template>
        </AppProjectInfoIntegrationRow>
      </div>

      <!-- Contact info -->
      <div
        v-if="insuranceCase.customer || insuranceCase.mainContact"
        class="mb-4 pb-3 border-b"
      >
        <div class="d-flex align-center mb-3">
          <v-icon
            small
            color="primary"
            class="mr-2"
          >
            {{ $icons.REGULAR.COMMON.USER || "fas fa-user" }}
          </v-icon>
          <span class="subtitle-1 font-weight-medium">
            {{ $t("projectView.info.in4mo.contactInfo") }}
          </span>
        </div>

        <AppProjectInfoIntegrationRow
          v-if="insuranceCase.customer && insuranceCase.customer.name"
          :label="$t('projectView.info.in4mo.customerName')"
          :value="insuranceCase.customer.name"
          :single-line="true"
          class="mb-2"
        />

        <AppProjectInfoIntegrationRow
          v-if="mainContactDetails"
          :label="$t('projectView.info.in4mo.mainContact')"
          :single-line="false"
          class="mb-1"
        >
          <div class="font-weight-medium">{{ mainContactDetails.name }}</div>
          <div
            v-if="mainContactDetails.phone"
            class="text-body-2"
          >
            {{ mainContactDetails.phone }}
          </div>
          <div
            v-if="mainContactDetails.email"
            class="text-body-2"
          >
            {{ mainContactDetails.email }}
          </div>
        </AppProjectInfoIntegrationRow>
      </div>

      <!-- Tasks -->
      <div
        v-if="insuranceCase.tasks && insuranceCase.tasks.length > 0"
        class="mb-4"
      >
        <div class="d-flex align-center mb-3">
          <v-icon
            small
            color="secondary"
            class="mr-2"
          >
            {{ $icons.REGULAR.COMMON.TASKS || "fas fa-tasks" }}
          </v-icon>
          <span class="subtitle-1 font-weight-medium">
            {{ $t("projectView.info.in4mo.tasks") }}
          </span>
        </div>

        <v-chip
          v-for="task in insuranceCase.tasks"
          :key="task.id"
          class="mr-2 mb-2"
          :color="task.isInspection ? 'primary' : 'secondary'"
          small
          outlined
        >
          {{ task.name }}
        </v-chip>
      </div>
    </v-card>

    <v-btn
      depressed
      color="primary"
      block
      href="https://no.in4mo.net/mmr300/index"
      target="_blank"
      class="text-transform-none"
    >
      <v-icon
        small
        left
      >
        {{ $icons.REGULAR.COMMON.EXTERNAL_LINK || "fas fa-external-link-alt" }}
      </v-icon>
      {{ $t("projectView.info.in4mo.logInToPortal") }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "AppIn4moInsuranceCaseDetails",

  props: {
    insuranceCase: {
      type: Object,
      required: true,
    },
  },

  computed: {
    mainContactDetails() {
      if (!this.insuranceCase?.mainContact?.id || !this.insuranceCase?.contacts) {
        return null;
      }

      return this.insuranceCase.contacts.find(
        (contact) => contact.id === this.insuranceCase.mainContact.id,
      );
    },
  },

  methods: {
    formatDate(dateString) {
      if (!dateString) return "";

      try {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat(this.$i18n.locale, {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }).format(date);
      } catch (e) {
        return dateString;
      }
    },

    getStatusColor(status) {
      const statusMap = {
        Open: "green",
        Pending: "orange",
        Closed: "grey",
        Escalated: "red",
        "In Progress": "blue",
      };

      return statusMap[status] || "primary";
    },
  },
};
</script>
