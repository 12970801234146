<script>
import { VIcon } from "vuetify/lib";
import { VBtn } from "vuetify/lib";
import ResponseItemSubHeader from "./ResponseItemSubHeader.vue";

export default {
  props: {
    values: Array,
    id: String,
    noIndent: Boolean,
  },
  data() {
    return {
      localTableHeaders: [],
    };
  },
  watch: {
    values: {
      handler(values) {
        if (this.localTableHeaders.length > 0) return;
        this.localTableHeaders = [...(values || [])];
      },
      immediate: true,
    },
  },
  computed: {
    hasInvalidHeaders() {
      return this.localTableHeaders.some((value) => !value.text?.trim());
    },
  },
  methods: {
    addTableHeader() {
      this.localTableHeaders = [
        ...this.localTableHeaders,
        { key: "", text: "", type: "TEXT_FIELD" },
      ];
      this.$nextTick(() => {
        if (this.$refs.lastHeaderInput) {
          this.$refs.lastHeaderInput.focus();
        }
      });
    },

    updateTableHeader(text, index) {
      const newValues = [...this.localTableHeaders];
      if (!text.trim()) {
        newValues.splice(index, 1);
      } else {
        newValues[index] = {
          key: text.toLowerCase(),
          text: text,
          type: "TEXT_FIELD",
        };
      }
      this.localTableHeaders = newValues;
      this.$emit("values:update", { values: newValues });
    },

    onkeydownPress(e) {
      if (e.key === "Enter") {
        const value = e.target.value;
        if (!value || value?.trim() === "") return;

        if (this.localTableHeaders.length > 5) {
          e.target.blur();
        } else {
          this.addTableHeader();
        }
      }
    },

    genDeleteButton({ onClick, size = 32 }) {
      return this.$createElement(
        VBtn,
        {
          props: { icon: true, color: "error", small: true },
          on: { click: onClick },
        },
        [this.$createElement(VIcon, this.$icons.LIGHT.ACTION.DELETE)],
      );
    },
  },
  render(h) {
    return h("div", { class: "d-flex" }, [
      !this.noIndent && h("div", { style: "min-width: 32px" }),
      h("div", { class: "w-100 py-2 pr-2" }, [
        h(ResponseItemSubHeader, {
          props: {
            title: this.$t("templateManagement.form.builder.item.tableHeaders"),
          },
          scopedSlots: {
            append: () =>
              h(
                VBtn,
                {
                  props: {
                    color: "primary",
                    text: true,
                    small: true,
                    depressed: true,
                    disabled: (this.localTableHeaders || []).length >= 6,
                  },
                  class: "border-a text-transform-none",
                  on: { click: () => this.addTableHeader() },
                },
                [
                  h(VIcon, { class: "mr-2", props: { small: true } }, [
                    this.$icons.SOLID.ACTION.ADD,
                  ]),
                  this.$t("templateManagement.form.builder.item.addColumn"),
                ],
              ),
          },
        }),
        h(
          "AppDefaultDraggable",
          {
            class: "d-flex align-center flex-wrap",
            style: "gap: 8px;",
            attrs: {
              value: this.localTableHeaders,
              disabled: this.hasInvalidHeaders,
              handle: ".draggable-handle",
              ghostClass: this.$constant.DRAG_SETTINGS.GHOST_CLASS_DASHED_OUTLINE,
              dragClass: this.$constant.DRAG_SETTINGS.DRAG_CLASS_WHITE_BACKGROUND,
            },
            on: {
              change: (evt) => {
                if (this.hasInvalidHeaders) return;

                const newValues = [...this.localTableHeaders];
                const movedItem = newValues.splice(evt.moved.oldIndex, 1)[0];
                newValues.splice(evt.moved.newIndex, 0, movedItem);
                this.localTableHeaders = newValues;
                this.$emit("values:update", { values: newValues });
              },
            },
          },
          [
            ...(this.localTableHeaders || []).map((header, index) =>
              h(
                "div",
                {
                  class: "d-flex align-center draggable-item background-inherit",
                  style: "width: calc(33.33% - 6px); border-radius: 4px;",
                },
                [
                  h(
                    "div",
                    {
                      class: `d-flex align-center cursor-${
                        this.hasInvalidHeaders ? "" : "grab draggable-handle"
                      } pl-2 mr-3`,
                    },
                    [
                      h(VIcon, {
                        props: { small: true },
                        style: this.hasInvalidHeaders ? "opacity: 0.5;" : "",
                        domProps: { textContent: this.$icons.SOLID.ACTION.DRAG },
                      }),
                    ],
                  ),
                  h(
                    "AppDefaultTextField",
                    {
                      ref:
                        index === this.localTableHeaders.length - 1
                          ? "lastHeaderInput"
                          : undefined,
                      class: "border-a-2",
                      props: {
                        hideDetails: true,
                        value: header.text,
                        label: this.$t("templateManagement.form.builder.item.headerName"),
                      },
                      on: {
                        blur: (e) => this.updateTableHeader(e.target.value, index),
                        keydown: (e) => this.onkeydownPress(e),
                      },
                    },
                    [
                      index > 0
                        ? h("template", { slot: "append" }, [
                            this.genDeleteButton({
                              onClick: () => {
                                const newValues = [...this.localTableHeaders];
                                newValues.splice(index, 1);
                                this.localTableHeaders = newValues;
                                this.$emit("values:update", { values: newValues });
                              },
                            }),
                          ])
                        : null,
                    ],
                  ),
                ],
              ),
            ),
          ],
        ),
      ]),
    ]);
  },
};
</script>
