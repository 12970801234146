<script>
import { VSpacer, VIcon, VForm } from "vuetify/lib";
import { formTemplateBuilderMixins, permissionMixins } from "@/helpers/mixins";

export default {
  mixins: [formTemplateBuilderMixins, permissionMixins],
  props: {
    item: Object,
    isFirstSection: Boolean,
    isLastSection: Boolean,
  },
  data() {
    return {
      edit: false,
      name: {
        isValid: false,
      },
    };
  },
  computed: {
    canEditFormTemplate() {
      return this.permissionMixins_formTemplate.edit.can;
    },
    isLocked() {
      if (!this.item) return false;
      if (!Array.isArray(this.item.meta)) return false;
      return this.item.meta.find((m) => m.field === "LOCK") ? true : false;
    },
  },
  methods: {
    onFormUpdate(e, field) {
      const value = typeof e === "object" && e !== null ? e?.target?.value : e;
      if (typeof value !== "undefined" && value !== this.item[field]) {
        const isFormField = this[field] ? true : false;
        if (isFormField && !this[field].isValid) return;
        this.formTemplateBuilderMixins_updateItem({
          item: this.item,
          body: { [field]: value },
        });
      }
      this.onEditEnd();
    },
    onEdit() {
      this.edit = true;
    },
    onEditEnd() {
      this.edit = false;
    },
    genTitleInput() {
      return this.$createElement(
        "div",
        {
          class: "d-flex",
        },
        [
          this.$createElement(
            "div",
            { class: "d-flex align-center justify-center", style: "min-width: 32px" },
            [this.$createElement(VIcon, this.$icons.REGULAR.FILE.FILE)],
          ),
          this.$createElement(
            VForm,
            {
              props: { value: this.name.isValid },
              class: "w-100",
              on: {
                submit: (e) => e.preventDefault(),
                input: (e) => (this.name.isValid = e),
              },
              click: (e) => {
                e.stopPropagation();
              },
            },
            [
              this.$createElement("AppDefaultTextField", {
                class: "text-h6 ",
                ref: "nameTextField",
                props: {
                  label: this.$t("templateManagement.form.builder.item.pageTitle"),
                  hideDetails: true,
                  value: this.item?.name,
                  rules: this.$rules.NAME_RULES,
                  autofocus: true,
                },
                on: {
                  blur: (e) => this.onFormUpdate(e, "name"),
                  keydown: (e) => {
                    if (e.key === "Enter") {
                      this.$refs.nameTextField.blur();
                    }
                  },
                },
              }),
            ],
          ),
        ],
      );
    },
    genTitleMenu() {
      return this.$createElement(
        "AppToggleMenuBtn",
        {
          props: {
            disabled: !this.canEditFormTemplate,
            activatorClass: "ml-1 mr-1",
          },
        },
        [
          this.$createElement("AppMenuItem", {
            props: {
              disabled: this.isFirstSection,
              text: this.$t("common.moveUp"),
              icon: this.$icons.REGULAR.COMMON.ARROW_UP,
            },
            on: {
              click: () =>
                this.formTemplateBuilderMixins_moveItem({
                  item: this.item,
                  body: { index: this.item.index - 1, parentId: this.item.parentId },
                }),
            },
          }),
          this.$createElement("AppMenuItem", {
            props: {
              disabled: this.isLastSection,
              text: this.$t("common.moveDown"),
              icon: this.$icons.REGULAR.COMMON.ARROW_DOWN,
            },
            on: {
              click: () =>
                this.formTemplateBuilderMixins_moveItem({
                  item: this.item,
                  body: { index: this.item.index + 1, parentId: this.item.parentId },
                }),
            },
          }),
          this.$createElement("AppMenuCopyBtn", {
            on: {
              click: () =>
                this.$emit("items:copy", {
                  items: [this.item].map((i) => ({ id: i.id })),
                }),
            },
          }),
          this.$createElement("AppMenuDeleteBtn", {
            props: { disabled: this.isLocked },
            on: {
              click: () => this.formTemplateBuilderMixins_deleteItem({ item: this.item }),
            },
          }),
        ],
      );
    },
    genLeft() {
      return this.$createElement("div", { class: "d-flex align-center w-100" }, [
        [
          this.$createElement(
            "div",
            {
              class: "d-flex align-center justify-center",
              style: "min-width: 32px",
            },
            [this.$createElement(VIcon, this.$icons.REGULAR.FILE.FILE)],
          ),
          [
            this.$createElement(
              "pre",
              {
                on: {
                  click: (e) => {
                    if (!this.canEditFormTemplate) return;
                    e.stopPropagation();
                    this.onEdit();
                  },
                },
                style:
                  "min-height: 40px; letter-spacing: normal !important; white-space: pre-wrap",
                class: `pl-3 font-weight-medium text-h6 w-100 d-flex align-center ${
                  this.canEditFormTemplate ? "cursor-text" : ""
                }`,
              },
              this.item?.name,
            ),
          ],
        ],
        this.$createElement(VSpacer),
        this.genTitleMenu(),
      ]);
    },
  },
  render(h) {
    return h(
      "div",
      { style: "position: sticky; top: 0; z-index: 2", class: "background-inherit" },
      [
        ...(this.edit
          ? [this.genTitleInput()]
          : [
              h("AppExpansionPanelHeader", { class: "background-inherit" }, [
                this.genLeft(),
              ]),
            ]),
      ],
    );
  },
};
</script>
