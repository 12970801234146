import { formatDataTableOptionsToQuery } from "@/helpers/util";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
const CancelToken = axios.CancelToken;
let getChildDomainsCancel;

const state = () => ({
  childDomains: [],
  count: null,
  isLoading: false,
  isUpdating: false,
  isInviting: false,
});

const getters = {
  getChildDomainById: (state) => (id) => {
    return state.childDomains.find((domain) => domain.id === id);
  },
};

const actions = {
  async getChildDomains({ commit }, { filters = null, dataTableOptions = null }) {
    if (getChildDomainsCancel) {
      getChildDomainsCancel();
    }
    commit("loading", true);

    const optionsQuery = formatDataTableOptionsToQuery(dataTableOptions);

    const params = {
      ...filters,
      ...optionsQuery,
    };

    return axios
      .get(`${apiUrl}child-domain`, {
        params,
        cancelToken: new CancelToken((c) => {
          getChildDomainsCancel = c;
        }),
      })
      .then(({ data }) => {
        commit("setChildDomains", data.childDomains);
        commit("setCount", data.count);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          throw new Error(error);
        }
      })
      .finally(() => {
        commit("loading", false);
      });
  },

  async inviteToChildDomain({ commit, state }, { emails }) {
    commit("inviting", true);
    return axios
      .post(`${apiUrl}child-domain/invite`, { emails })
      .then(({ data }) => {
        const childDomains = data.childDomains;
        const existingIds = state.childDomains.map((domain) => domain.id);

        const uniqueDomains = childDomains.filter(
          (childDomain) => !existingIds.includes(childDomain.id),
        );

        uniqueDomains.forEach((childDomain) => {
          commit("addChildDomain", { childDomain });
        });
        commit("setCount", state.count + uniqueDomains.length);
        return uniqueDomains;
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("inviting", false);
      });
  },

  async deleteChildDomains({ commit, state }, { ids }) {
    commit("loading", true);
    const config = { data: { ids } };

    return axios
      .delete(`${apiUrl}child-domain`, config)
      .then(() => {
        ids.forEach((id) => {
          commit("removeChildDomain", { id });
        });
        commit("setCount", state.count - ids.length);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", false);
      });
  },

  async shareDataToChildren({ commit }, { ids, data }) {
    commit("loading", true);
    return axios
      .put(`${apiUrl}child-domain/give-data-access`, { ids, ...data })
      .then(({ data }) => {
        const childDomains = data.childDomains;
        if (childDomains) {
          childDomains.forEach((childDomain) => {
            commit("updateChildDomain", { childDomain });
          });
        }
        return data;
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", false);
      });
  },

  async requestChildDomainData({ commit }, { data }) {
    commit("loading", true);
    return axios
      .put(`${apiUrl}child-domain/request-data-access`, data)
      .then(({ data }) => {
        const childDomains = data.childDomains;
        if (childDomains) {
          childDomains.forEach((childDomain) => {
            commit("updateChildDomain", { childDomain });
          });
        }
        return data;
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", false);
      });
  },

  resetState({ commit }) {
    commit("setChildDomains", []);
    commit("setCount", null);
  },
};

const mutations = {
  setChildDomains(state, childDomains) {
    state.childDomains = childDomains;
  },
  setCount(state, count) {
    state.count = count;
  },
  addChildDomain(state, { childDomain }) {
    state.childDomains.unshift(childDomain);
  },
  updateChildDomain(state, { childDomain }) {
    if (!childDomain) throw new Error("No child domain");
    const index = state.childDomains.findIndex((x) => x.id === childDomain.id);
    if (index !== -1) {
      state.childDomains.splice(index, 1, childDomain);
    }
  },
  removeChildDomain(state, { id }) {
    const index = state.childDomains.findIndex((x) => x.id === id);
    if (index !== -1) {
      state.childDomains.splice(index, 1);
    }
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  updating(state, isUpdating) {
    state.isUpdating = isUpdating;
  },
  inviting(state, isInviting) {
    state.isInviting = isInviting;
  },
};

export const corporateManagement = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
