<template>
  <v-dialog
    v-model="localDialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title class="headline">
        {{ $t("feedback.dialog.title") }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="close"
        >
          <v-icon>{{ $icons.REGULAR.ACTION.CLOSE }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="position-relative">
        <v-progress-circular
          v-if="isLoading"
          indeterminate
          color="primary"
          class="loading-spinner"
        ></v-progress-circular>
        <div
          id="hubspot-form-container"
          class="hubspot-form-container"
        ></div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      hubspotScriptLoaded: false,
      isLoading: false,
    };
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  watch: {
    localDialog: {
      handler(val) {
        if (val) {
          this.isLoading = true;
          const container = document.getElementById("hubspot-form-container");
          if (container) {
            container.innerHTML = "";
          }
          this.loadHubspotForm();
        }
      },
      immediate: true,
    },
  },
  methods: {
    close() {
      this.localDialog = false;
      const container = document.getElementById("hubspot-form-container");
      if (container) {
        container.innerHTML = "";
      }
    },
    loadHubspotForm() {
      this.isLoading = true;

      if (!this.hubspotScriptLoaded && !window.hbspt) {
        const script = document.createElement("script");
        script.charset = "utf-8";
        script.type = "text/javascript";
        script.src = "//js-eu1.hsforms.net/forms/embed/v2.js";
        script.async = true;
        script.onload = () => {
          this.hubspotScriptLoaded = true;
          this.createHubspotForm();
        };
        script.onerror = () => {
          this.isLoading = false;
        };
        document.head.appendChild(script);
      } else {
        setTimeout(() => {
          this.createHubspotForm();
        }, 100);
      }
    },
    createHubspotForm() {
      const loadingTimeout = setTimeout(() => {
        this.isLoading = false;
      }, 10000);

      if (window.hbspt && window.hbspt.forms) {
        const container = document.getElementById("hubspot-form-container");
        if (!container) {
          this.isLoading = false;
          clearTimeout(loadingTimeout);
          return;
        }

        container.innerHTML = "";

        try {
          window.hbspt.forms.create({
            portalId: "144035730",
            formId: "a35a774a-5459-41d0-8988-a6b680002268",
            region: "eu1",
            target: "#hubspot-form-container",
            onFormReady: () => {
              this.isLoading = false;
              clearTimeout(loadingTimeout);
            },
            onFormSubmit: () => {
              // Optional: handle form submission
            },
          });
        } catch (error) {
          this.isLoading = false;
          clearTimeout(loadingTimeout);
        }
      } else {
        this.isLoading = false;
        clearTimeout(loadingTimeout);
      }
    },
  },
  beforeDestroy() {
    const container = document.getElementById("hubspot-form-container");
    if (container) {
      container.innerHTML = "";
    }
  },
};
</script>

<style scoped>
.hubspot-form-container {
  min-height: 400px;
  width: 100%;
}

.position-relative {
  position: relative;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
</style>
