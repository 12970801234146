<template>
  <v-dialog
    v-model="localDialog"
    max-width="1000px"
    scrollable
  >
    <v-card>
      <v-card-title class="d-flex align-center">
        <div class="flex-grow-1">
          {{ $t("dataTable.editHeaders.title") }}
        </div>
        <AppDefaultTooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              icon
              v-bind="attrs"
              v-on="on"
              @click="onResetToDefault"
            >
              <v-icon small>{{ $icons.REGULAR.ACTION.RESET }}</v-icon>
            </v-btn>
          </template>
          {{ $t("common.resetToDefault") }}
        </AppDefaultTooltip>
        <AppDefaultTooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              class="ml-1"
              icon
              v-bind="attrs"
              v-on="on"
              @click="onResetToSaved"
            >
              <v-icon small>{{ $icons.REGULAR.ACTION.RESET_TO_LAST }}</v-icon>
            </v-btn>
          </template>
          {{ $t("common.resetToSaved") }}
        </AppDefaultTooltip>
      </v-card-title>
      <v-card-text class="h-100">
        <v-row class="h-100">
          <v-col
            cols="6"
            class="h-100"
          >
            <div
              style="height: 48px"
              class="d-flex align-center"
            >
              <AppDefaultTextField
                :placeholder="$t('dataTable.editHeaders.searchPlaceholder')"
                hide-details
                autofocus
                v-model="search"
              />
            </div>
            <div
              class="overflow-y-auto"
              style="height: 450px"
            >
              <v-list>
                <v-list-item
                  v-for="(header, index) in sortedHeaders"
                  :key="index"
                  class="border-a--transparent"
                  @click="toggleHeader(header)"
                  :disabled="header.required"
                  dense
                >
                  <v-list-item-icon>
                    <v-icon :color="header.required ? 'grey' : 'primary'">
                      {{
                        header.enabled
                          ? $icons.SOLID.COMMON.SQUARE_CHECK
                          : $icons.LIGHT.COMMON.SQUARE
                      }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    {{ header?.text }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-col>
          <v-col
            cols="6"
            class="h-100"
          >
            <div
              style="height: 48px"
              class="d-flex align-center"
            >
              <div class="subtitle-1 font-weight-medium primary-text--text">
                {{ $t("dataTable.editHeaders.selectedColumns") }}
              </div>
              <v-spacer />
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    ref="frozenColumnsBtn"
                    depressed
                    v-bind="attrs"
                    v-on="on"
                    class="text-transform-none"
                    small
                    text
                  >
                    {{ $tc("dataTable.editHeaders.frozenColumns", displayFrozenCount) }}
                    <v-icon
                      right
                      small
                    >
                      {{ $icons.REGULAR.ACTION.FREEZE }}
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(header, index) in enabledAllHeaders.length + 1"
                    :key="index"
                    class="v-list-item-disable-default-hover list_tr"
                    @click="localFrozenColumnsCount = index"
                    :class="{
                      'list_tr--selected': index === displayFrozenCount,
                      'list_tr--success': index === displayFrozenCount,
                    }"
                  >
                    <v-list-item-title>{{ index }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div
              class="overflow-y-auto draggable-container-gap-8"
              style="max-height: 450px"
            >
              <!-- Frozen columns -->
              <AppDefaultDraggable
                v-model="frozenHeaders"
                group="headers"
                :disabled="!hasFrozenColumns"
                :ghostClass="$constant.DRAG_SETTINGS.GHOST_CLASS_HIDDEN"
                class="draggable-container-gap-8"
              >
                <AppEditHeadersDraggableItem
                  v-for="(header, index) in frozenHeaders"
                  v-show="shouldShowHeader(header)"
                  class="ui-background--background"
                  :key="index"
                  :header="header"
                  @click="toggleHeader(header)"
                />
              </AppDefaultDraggable>
              <AppEditHeadersFreezeColumnsDivider
                v-if="hasFrozenColumns"
                :count="localFrozenColumnsCount"
              />
              <!-- Non-frozen columns -->
              <AppDefaultDraggable
                v-model="nonFrozenHeaders"
                group="headers"
                :ghostClass="$constant.DRAG_SETTINGS.GHOST_CLASS_HIDDEN"
                class="draggable-container-gap-8"
              >
                <AppEditHeadersDraggableItem
                  v-for="(header, index) in nonFrozenHeaders"
                  v-show="shouldShowHeader(header)"
                  class="ui-background--background"
                  :key="index"
                  :header="header"
                  @click="toggleHeader(header)"
                />
              </AppDefaultDraggable>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onSubmit"
          @cancel="onCancel"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    dialog: Boolean,
    headers: Array,
    defaultHeaders: Array,
    frozenColumnsCount: {
      type: Number,
      default: 0,
    },
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      frozenHeaders: [],
      nonFrozenHeaders: [],
      search: "",
    };
  },
  computed: {
    ...mapGetters("resourceViews", [
      "getCurrentResourceView",
      "getCurrentResourceViewConfig",
    ]),
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    allHeaders() {
      return [...this.frozenHeaders, ...this.nonFrozenHeaders];
    },
    enabledAllHeaders() {
      return [...this.enabledFrozenHeaders, ...this.enabledNonFrozenHeaders];
    },
    enabledFrozenHeaders() {
      return this.frozenHeaders.filter(this.shouldShowHeader);
    },
    enabledNonFrozenHeaders() {
      return this.nonFrozenHeaders.filter(this.shouldShowHeader);
    },
    sortedHeaders() {
      const headerOrder = this.defaultHeaders.map((header) => header.text);
      return this.allHeaders
        .filter(
          (header) =>
            header.text?.toLowerCase().includes(this.search.toLowerCase()) &&
            header.access,
        )
        .sort((a, b) => headerOrder.indexOf(a.text) - headerOrder.indexOf(b.text));
    },
    frozenColumnOptions() {
      const maxFrozen = this.allHeaders.length;
      return Array.from({ length: maxFrozen + 1 }, (_, i) => i);
    },
    hasEnabledHeaders() {
      return this.enabledAllHeaders.length > 0;
    },
    hasFrozenColumns() {
      return this.enabledFrozenHeaders.length > 0;
    },
    displayFrozenCount() {
      return this.frozenHeaders.filter(this.shouldShowHeader).length;
    },
    localFrozenColumnsCount: {
      get() {
        return this.frozenHeaders.length;
      },
      set(newCount) {
        const allHeaders = [...this.frozenHeaders, ...this.nonFrozenHeaders];
        const { frozen, nonFrozen } = this.splitHeadersByFrozenCount(
          allHeaders,
          newCount,
        );
        this.frozenHeaders = frozen;
        this.nonFrozenHeaders = nonFrozen;
      },
    },
  },
  watch: {
    dialog: {
      immediate: true,
      handler(isOpen) {
        if (isOpen) {
          this.initializeHeaders();
        }
      },
    },
  },
  methods: {
    onResetToDefault() {
      // Create completely new header objects to break the reference
      const freshHeaders = this.defaultHeaders.map((header) => ({
        ...header,
        enabled: true, // All default headers are enabled by default
      }));

      // Split into frozen and non-frozen
      const { frozen, nonFrozen } = this.splitHeadersByFrozenCount(
        freshHeaders,
        this.frozenColumnsCount,
      );

      this.frozenHeaders = frozen;
      this.nonFrozenHeaders = nonFrozen;
    },
    onResetToSaved() {
      // Create completely new header objects to break the reference
      const freshHeaders = JSON.parse(JSON.stringify(this.headers));

      // Split into frozen and non-frozen
      const { frozen, nonFrozen } = this.splitHeadersByFrozenCount(
        freshHeaders,
        this.frozenColumnsCount,
      );

      this.frozenHeaders = frozen;
      this.nonFrozenHeaders = nonFrozen;
    },
    close() {
      this.localDialog = false;
    },
    onCancel() {
      this.close();
    },
    async onSubmit() {
      this.$emit("submit", {
        headers: this.allHeaders,
        frozenColumnsCount: this.displayFrozenCount,
      });

      this.close();
    },
    toggleHeader(header) {
      if (header.required) return;
      header.enabled = !header.enabled;
    },
    shouldShowHeader(header) {
      return header.enabled && header.access;
    },
    initializeHeaders() {
      const allHeaders = JSON.parse(JSON.stringify(this.headers));
      const { frozen, nonFrozen } = this.splitHeadersByFrozenCount(
        allHeaders,
        this.frozenColumnsCount,
      );
      this.frozenHeaders = frozen;
      this.nonFrozenHeaders = nonFrozen;
    },
    splitHeadersByFrozenCount(headers, targetFrozenCount) {
      let visibleCount = 0;
      let splitIndex = 0;

      for (let i = 0; i < headers.length && visibleCount < targetFrozenCount; i++) {
        if (this.shouldShowHeader(headers[i])) {
          visibleCount++;
        }
        splitIndex = i + 1;
      }

      return {
        frozen: headers.slice(0, splitIndex),
        nonFrozen: headers.slice(splitIndex),
      };
    },
  },
};
</script>
