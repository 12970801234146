<template>
  <v-dialog
    :value="value.show"
    @input="updateValue('show', $event)"
    max-width="400"
  >
    <v-card>
      <v-card-title>{{ $t("common.rename") }}</v-card-title>
      <v-card-text>
        <AppDefaultTextField
          :value="value.name"
          @input="updateValue('name', $event)"
          outlined
          dense
          hide-details
          autofocus
          @keyup.enter="save"
        />
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          :show="true"
          :confirmText="$t('common.save')"
          :loading="isUpdating"
          @cancel="updateValue('show', false)"
          @confirm="save"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AppResourceViewDialog",
  props: {
    value: {
      type: Object,
      required: true,
    },
    isUpdating: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateValue(key, value) {
      const updatedValue = { ...this.value, [key]: value };
      this.$emit("input", updatedValue);
    },
    save() {
      if (!this.value.name) return;

      this.$emit("save", {
        name: this.value.name,
        editId: this.value.editId,
      });
    },
  },
};
</script>
