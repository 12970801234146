<template>
  <div>
    <AppProjectDialog
      v-model="dialog.updateProject.active"
      :data="dialog.updateProject.item"
      :title="$t('project.updateProject.title')"
      :submitBtnText="$t('common.save')"
      @confirm="afterProjectUpdate"
    />
    <AppDeleteConfirmationDialog
      v-model="dialog.deleteProject.active"
      :item="dialog.deleteProject.item"
      :title="$tc('project.deleteProject.title', dialog.deleteProject.item?.length)"
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('project.deleteProject.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="deleteProject"
    />
    <AppDataTableServerSidePagination
      ref="dataTable"
      :loading="isLoading"
      :defaultHeaders="defaultHeaders"
      :items="projects"
      :tableModel="tableModel"
      :serverItemsLength="serverItemsLength"
      :noDataText="$t('common.noProjects')"
      :actionConfigs="actionConfigs"
      :getAllItems="getAllItems"
      removeMutationType="projects/removeProject"
      updateMutationType="projects/updateProject"
      :statsText="statsText"
      :isStatsLoading="isStatsLoading"
    >
      <template
        v-slot:[`group.header`]="{
          toggle,
          group,
          isOpen,
          headers,
          isGroupIndeterminate,
          isGroupSelected,
          toggleGroup,
        }"
      >
        <td
          @click.stop="toggleGroup"
          class="table-cell--hover-effect"
          v-ripple
        >
          <div class="d-flex align-center justify-center h-100">
            <AppDefaultCheckboxIcon
              :isIndeterminate="isGroupIndeterminate"
              :isSelected="isGroupSelected"
            />
          </div>
        </td>
        <td :colspan="headers.length - 4">
          <div class="d-flex align-center">
            <v-btn
              class="mr-4"
              icon
              @click.stop="toggle"
            >
              <v-icon>
                {{
                  isOpen
                    ? $icons.LIGHT.COMMON.CHEVRON_DOWN
                    : $icons.LIGHT.COMMON.CHEVRON_UP
                }}
              </v-icon>
            </v-btn>
            <div class="group-header__title">
              {{ headerTitle(group) }}
            </div>
          </div>
        </td>
        <td :colspan="3"></td>
      </template>

      <template v-slot:item="{ item, headers, select, isSelected }">
        <AppProjectTableItem
          :project="item"
          :isSelected="isSelected"
          :isContextMenuActiveItem="isContextMenuActiveItem(item)"
          :select="select"
          :headers="headers"
          :dataFields="filtersMixins_dataFields"
          @menu:click="openContextMenu"
        />
      </template>
    </AppDataTableServerSidePagination>
    <AppDataTableContextMenu
      ref="contextMenu"
      :actionConfigs="actionConfigs"
      :items="[contextMenuActiveItem]"
      @input="onContextMenuChange"
    />
    <AppCustomStatusPickerMenu
      ref="statusMenu"
      @submit="onProjectsUpdate"
      :closeOnContentClick="false"
      model="project"
    />
    <AppClientPickerMenu
      ref="customerMenu"
      @submit="onProjectsUpdate"
      :type="this.$constant.CUSTOMER"
      :queryParams="{ isActive: true }"
    />
    <AppUserPickerMenu
      ref="projectManagerMenu"
      valueKey="owner"
      :queryParams="{ isAssignableAsProjectLeader: true, projectId: routeProjectId }"
      @submit="(id) => onProjectsUpdate({ ownerId: id })"
    />
    <AppUserPickerMenu
      ref="membersMenu"
      valueKey="members"
      multiple
      :queryParams="{ projectId: routeProjectId }"
      @submit="(ids) => onProjectsUpdate({ members: ids })"
    />
    <AppAccessLevelPickerMenu
      ref="permissionLevelMenu"
      @submit="onProjectsUpdate"
    />
    <AppDatePickerMenu
      ref="startDateMenu"
      @submit="({ date }) => onProjectsUpdate({ startDate: date })"
    />
    <AppDatePickerMenu
      ref="endDateMenu"
      @submit="({ date }) => onProjectsUpdate({ endDate: date })"
    />
    <AppProjectPickerMenu
      ref="parentProjectMenu"
      @submit="({ projectId }) => onProjectsUpdate({ parentId: projectId })"
    />
  </div>
</template>

<script>
import { getAllProjects } from "@/services/project/allProjects";
import { filtersMixins, groupByMixins, permissionMixins } from "@/helpers/mixins";
import { mapGetters } from "vuex";
import { createTableHeaders } from "@/helpers/util";

export default {
  mixins: [groupByMixins, filtersMixins, permissionMixins],
  props: {
    projects: Array,
    isLoading: Boolean,
    tableModel: String,
    serverItemsLength: Number,
    statsText: String,
    isStatsLoading: Boolean,
  },
  data() {
    return {
      dialog: {
        updateProject: {
          active: false,
          item: null,
        },
        deleteProject: {
          active: false,
          item: null,
        },
      },
      contextMenuActiveItem: null,
      isContextMenuOpen: false,
      itemsToEdit: [],
    };
  },
  computed: {
    ...mapGetters("activeIntegrations", {
      hasProjectImportIntegrationEnabled: "hasProjectImportIntegrationEnabled",
    }),
    routeProjectId() {
      return this.$route.params.projectId;
    },
    actions() {
      const {
        GO_TO,
        EDIT,
        COPY_URL,
        DELETE,
        EDIT_STATUS,
        EDIT_PROJECT_MANAGER,
        EDIT_CUSTOMER,
        EDIT_MEMBERS,
        EDIT_PERMISSION_LEVEL,
        EDIT_START_DATE,
        EDIT_END_DATE,
        EDIT_PARENT_PROJECT,
      } = this.$constant.ACTION_KEYS;

      const openMenu = (menuRef) => (e) => this.openEditMenu({ ...e, menuRef });
      const canNotEdit = ({ items }) => !this.canEditItems({ items });
      const canNotDelete = ({ items }) => !this.canDeleteItems({ items });

      return {
        [GO_TO]: {
          click: this.onProjectOpen,
        },
        [EDIT]: {
          click: this.onProjectEdit,
          disabled: canNotEdit,
        },
        [COPY_URL]: {
          click: this.onCopyUrl,
        },
        [EDIT_STATUS]: {
          click: openMenu("statusMenu"),
          disabled: ({ items }) =>
            canNotEdit({ items }) || items.some((item) => item.isUpdatingStatus),
        },
        [EDIT_PROJECT_MANAGER]: {
          click: openMenu("projectManagerMenu"),
          disabled: canNotEdit,
        },
        [EDIT_MEMBERS]: {
          click: openMenu("membersMenu"),
          disabled: canNotEdit,
        },
        [EDIT_CUSTOMER]: {
          click: openMenu("customerMenu"),
          disabled: canNotEdit,
        },
        [EDIT_START_DATE]: {
          click: openMenu("startDateMenu"),
          disabled: canNotEdit,
        },
        [EDIT_END_DATE]: {
          click: openMenu("endDateMenu"),
          disabled: canNotEdit,
        },
        [EDIT_PERMISSION_LEVEL]: {
          click: openMenu("permissionLevelMenu"),
          disabled: canNotEdit,
        },
        [EDIT_PARENT_PROJECT]: {
          click: openMenu("parentProjectMenu"),
          disabled: canNotEdit,
        },
        [DELETE]: {
          click: this.onProjectsDelete,
          disabled: canNotDelete,
        },
      };
    },
    actionConfigs() {
      const {
        GO_TO,
        EDIT,
        COPY_URL,
        DELETE,
        EDIT_STATUS,
        EDIT_PROJECT_MANAGER,
        EDIT_MEMBERS,
        EDIT_CUSTOMER,
        EDIT_PERMISSION_LEVEL,
        EDIT_START_DATE,
        EDIT_END_DATE,
        EDIT_PARENT_PROJECT,
      } = this.$constant.ACTION_KEYS;

      const withMenuContext = (clickHandler) => (e) => {
        clickHandler({
          direction: e.hasSubMenu ? "right" : "top",
          parentMenuRef: e.hasSubMenu ? this.$refs.contextMenu : null,
          ...e,
        });
      };

      const actions = this.actions;

      const customActions = [
        {
          key: GO_TO,
          on: {
            click: actions[GO_TO].click,
          },
        },
        {
          key: EDIT,
          props: {
            disabled: actions[EDIT].disabled,
          },
          on: {
            click: actions[EDIT].click,
          },
        },
        {
          key: COPY_URL,
          on: {
            click: actions[COPY_URL].click,
          },
        },
        {
          key: EDIT_STATUS,
          props: {
            disabled: actions[EDIT_STATUS].disabled,
          },
          on: {
            click: withMenuContext(actions[EDIT_STATUS].click),
          },
        },
        {
          key: EDIT_PROJECT_MANAGER,
          props: {
            disabled: actions[EDIT_PROJECT_MANAGER].disabled,
          },
          on: {
            click: withMenuContext(actions[EDIT_PROJECT_MANAGER].click),
          },
        },
        {
          key: EDIT_MEMBERS,
          props: {
            disabled: actions[EDIT_MEMBERS].disabled,
          },
          on: {
            click: withMenuContext(actions[EDIT_MEMBERS].click),
          },
        },
        {
          key: EDIT_CUSTOMER,
          props: {
            disabled: actions[EDIT_CUSTOMER].disabled,
          },
          on: {
            click: withMenuContext(actions[EDIT_CUSTOMER].click),
          },
        },
        {
          key: EDIT_START_DATE,
          props: {
            disabled: actions[EDIT_START_DATE].disabled,
          },
          on: {
            click: withMenuContext(actions[EDIT_START_DATE].click),
          },
        },
        {
          key: EDIT_END_DATE,
          props: {
            disabled: actions[EDIT_END_DATE].disabled,
          },
          on: {
            click: withMenuContext(actions[EDIT_END_DATE].click),
          },
        },
        {
          key: EDIT_PERMISSION_LEVEL,
          props: {
            disabled: actions[EDIT_PERMISSION_LEVEL].disabled,
          },
          on: {
            click: withMenuContext(actions[EDIT_PERMISSION_LEVEL].click),
          },
        },
        {
          key: EDIT_PARENT_PROJECT,
          props: {
            disabled: actions[EDIT_PARENT_PROJECT].disabled,
          },
          on: {
            click: withMenuContext(actions[EDIT_PARENT_PROJECT].click),
          },
        },
        {
          key: DELETE,
          props: {
            disabled: actions[DELETE].disabled,
          },
          on: {
            click: actions[DELETE].click,
          },
          divider: true,
        },
      ];

      const config = this.$constant.generateActionConfig({
        primaryActions: customActions,
        secondaryActions: this.$constant.PROJECT_ACTIONS,
      });

      return config;
    },
    defaultHeaders() {
      const {
        GO_TO,
        EDIT_STATUS,
        EDIT_CUSTOMER,
        EDIT_PROJECT_MANAGER,
        EDIT_MEMBERS,
        EDIT_START_DATE,
      } = this.$constant.ACTION_KEYS;

      return createTableHeaders([
        {
          preset: "SELECT",
        },
        {
          preset: "STATUS",
          value: this.filtersMixins_dataFields.STATUS,
          ...this.actions[EDIT_STATUS],
        },
        {
          text: this.$options.filters.capitalize(this.$t("project.list.number")),
          value: this.filtersMixins_dataFields.NUMBER,
          access: this.hasProjectImportIntegrationEnabled,
          disabled: true,
        },
        {
          preset: "NAME",
          text: this.$options.filters.capitalize(this.$t("project.list.projectName")),
          value: this.filtersMixins_dataFields.NAME,
          ...this.actions[GO_TO],
        },
        {
          text: this.$options.filters.capitalize(this.$t("project.list.customer")),
          value: this.filtersMixins_dataFields.CLIENT,
          ...this.actions[EDIT_CUSTOMER],
        },
        {
          text: this.$options.filters.capitalize(this.$t("project.list.projectManager")),
          value: this.filtersMixins_dataFields.OWNER,
          ...this.actions[EDIT_PROJECT_MANAGER],
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.members")),
          value: this.filtersMixins_dataFields.MEMBERS,
          ...this.actions[EDIT_MEMBERS],
        },
        {
          preset: "DATE",
          text: this.$options.filters.capitalize(this.$t("project.list.startDate")),
          value: this.filtersMixins_dataFields.START_DATE,
          ...this.actions[EDIT_START_DATE],
        },
        {
          preset: "MENU",
          value: this.filtersMixins_dataFields.MENU,
        },
      ]);
    },
    ...mapGetters("statuses", {
      hasFetchedStatuses: "hasFetchedStatuses",
    }),
  },
  methods: {
    openEditHeadersDialog() {
      this.$refs.dataTable.openEditHeadersDialog();
    },
    async getAllItems() {
      return await getAllProjects({
        filters: {
          ...this.filtersMixins_filters,
          ...(this.routeProjectId ? { projectId: this.routeProjectId } : {}),
        },
      });
    },
    onContextMenuChange(isOpen) {
      if (!isOpen) {
        this.contextMenuActiveItem = null;
      }
    },
    canEditItems({ items }) {
      return this.permissionMixins_canEditItems({ items });
    },
    canDeleteItems({ items }) {
      return this.permissionMixins_canDeleteItems({ items });
    },
    onCopyUrl({ items }) {
      const project = items[0];

      const route = {
        name: this.$routeNames.PROJECT.VIEW,
        params: { projectId: project?.id },
      };

      const projectUrl = this.$router.resolve(route).href;

      const fullPath = `${window.location.origin}${projectUrl}`;

      navigator.clipboard.writeText(fullPath);

      this.$store.dispatch("snackbar/snackbar", {
        show: true,
        text: this.$t("common.copied"),
        color: "success",
      });
    },
    isContextMenuActiveItem(item) {
      return this.contextMenuActiveItem?.id === item?.id;
    },
    openContextMenu({ from, project }) {
      this.$refs.contextMenu.open({ from });
      this.$nextTick(() => {
        this.contextMenuActiveItem = project;
      });
    },
    headerTitle(group) {
      const commonHeaderTitle = this.groupByMixins_commonHeaderTitle(group);
      if (commonHeaderTitle) return commonHeaderTitle;
      return group;
    },
    deleteProject({ item }) {
      this.$emit("projects:delete", { items: item });
    },
    onProjectsDelete({ items }) {
      this.dialog.deleteProject.active = true;
      this.dialog.deleteProject.item = items;
    },
    onProjectEdit({ items }) {
      const item = items[0];
      this.dialog.updateProject.active = true;
      this.dialog.updateProject.item = item;
    },
    onProjectOpen({ items }) {
      const item = items[0];
      this.$router.push({
        name: this.$routeNames.PROJECT.VIEW,
        params: { projectId: item.id },
      });
    },
    openEditMenu(e) {
      const { items, menuRef } = e;
      this.$refs[menuRef].open(e);
      this.itemsToEdit = items;
    },
    onProjectsUpdate(value) {
      const body = { ...value, ids: this.itemsToEdit.map((item) => item.id) };
      this.$emit("projects:update", body);
    },
    afterProjectUpdate(e) {
      this.$emit("project:afterUpdate", e);
    },
    async getStatuses() {
      await this.$store.dispatch("statuses/getStatuses", { model: "project" });
    },
  },
  async mounted() {
    if (!this.hasFetchedStatuses("project")) {
      this.getStatuses();
    }
  },
};
</script>
