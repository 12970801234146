<template>
  <div>
    <v-dialog
      v-model="localDialog"
      max-width="600px"
      scrollable
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ title || $t("users.userInvite.title") }}</span>
        </v-card-title>
        <v-card-subtitle
          class="primary-text--text mt-0"
          v-if="subtitle"
        >
          {{ subtitle }}
        </v-card-subtitle>
        <v-card-text>
          <v-form
            ref="inviteForm"
            v-model="formValid"
            v-on:submit.prevent
            @submit="onInvite"
          >
            <div class="p-relative">
              <div class="label--small required-asterisk">
                {{ $t("common.email") }}
              </div>
              <AppDefaultTextField
                hide-details
                ref="emailInput"
                v-model="currentEmail.value"
                :rules="currentEmail.rules"
                @keydown.enter.prevent="addEmail"
                @blur="addEmail"
                autofocus
                clearable
              />
            </div>
            <v-chip-group
              column
              class="hide-details-compensation"
            >
              <v-chip
                v-for="(email, index) in form.emails"
                :ref="`emailChip-${index}`"
                :key="index"
                close
                :closeIcon="$icons.LIGHT.ACTION.CLOSE"
                @click="editEmail(index)"
                @click:close="removeEmail(index)"
              >
                {{ email }}
              </v-chip>
            </v-chip-group>
            <AppRoleAutocomplete
              v-if="isInviteToWorkspace"
              labelKey="users.role"
              :disabled="isLoadingDefaultRole"
              :queryParams="{ excludeTypes: ['DOMAIN_OWNER'] }"
              v-model="form.role"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <AppDialogActionBtnPair
            @confirm="onInvite"
            @cancel="onClose"
            :disabled="isDisabledInviteBtn"
            :confirmText="$t('users.userInvite.invite')"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AppInviteUserIncreaseLicenseDialog
      v-model="increaseLicenseDialog.active"
      :totalDesiredLicensesToUse="form?.emails?.length || 0"
      @license:upgrade="onInvite"
    />
  </div>
</template>

<script>
import { getRoles } from "@/services/role/role";
import { planRestrictionsMixins } from "@/helpers/mixins";

export default {
  mixins: [planRestrictionsMixins],
  props: {
    dialog: Boolean,
    title: String,
    subtitle: String,
    defaultRoleType: {
      type: String,
      default: "USER",
    },
    isInviteToWorkspace: Boolean,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },

  computed: {
    localDialog: {
      get() {
        if (this.dialog) {
          this.getDefaultRole();
        }
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    isDisabledInviteBtn() {
      const disabledDueToNoEmails = !this.form?.emails?.length;
      if (!this.isInviteToWorkspace) return disabledDueToNoEmails;

      const disabledDueToNoRole = !this.form.role;

      return disabledDueToNoEmails || disabledDueToNoRole;
    },
    isInviteFormValid() {
      return (
        this.$refs.inviteForm.validate() && this.formValid && this.form.emails.length > 0
      );
    },
    isLicenseUpgradeRequired() {
      if (!this.isInviteToWorkspace) return false;

      const licensesNeeded =
        this.planRestrictionsMixins_getAdditionalRequiredLicensesForInvite(
          this.form.emails.length,
        );
      const hasSelectedGuestRole = this.form.role?.type === "GUEST";
      return licensesNeeded > 0 && !hasSelectedGuestRole;
    },
  },
  data() {
    return {
      formValid: false,
      currentEmail: {
        value: "",
        rules: [...this.$rules.EMAIL_RULES, this.checkDuplicateEmail],
      },
      form: {
        emails: [],
        role: null,
      },
      editingIndex: null,
      isLoadingDefaultRole: false,
      increaseLicenseDialog: {
        active: false,
      },
    };
  },
  methods: {
    getRoles,
    async getDefaultRole() {
      if (!this.isInviteToWorkspace) return;

      this.form.role = null;
      this.isLoadingDefaultRole = true;
      const roles = await this.getRoles({ type: this.defaultRoleType });
      this.form.role = roles.find((role) => role.type === this.defaultRoleType);
      this.isLoadingDefaultRole = false;
    },
    addEmail() {
      if (
        this.currentEmail.value &&
        this.currentEmail.rules.every((rule) => rule(this.currentEmail.value) === true)
      ) {
        if (this.editingIndex !== null) {
          this.form.emails.splice(this.editingIndex, 1, this.currentEmail.value);
          this.$nextTick(() => {
            const chipRef = this.$refs[`emailChip-${this.editingIndex}`];
            chipRef[0].toggle();
            this.editingIndex = null;
          });
        } else {
          this.form.emails.push(this.currentEmail.value);
        }
        this.currentEmail.value = "";
        this.$nextTick(() => {
          this.$refs.emailInput.focus();
        });
      }
    },
    removeEmail(index) {
      this.form.emails.splice(index, 1);
    },
    editEmail(index) {
      this.currentEmail.value = this.form.emails[index];
      this.editingIndex = index;
      this.$nextTick(() => {
        this.$refs.emailInput.focus();
      });
    },
    formatBody() {
      const body = {
        emails: this.form.emails,
        roleId: this.form.role?.id,
      };
      return body;
    },
    onInvite() {
      if (!this.isInviteFormValid) return;

      if (this.isLicenseUpgradeRequired) {
        this.openLicenseUpgradeDialog();
        return;
      }

      this.submitInvite();
    },
    openLicenseUpgradeDialog() {
      this.increaseLicenseDialog.active = true;
    },
    submitInvite() {
      this.$emit("submit:form", this.formatBody());
      this.resetForm();
      this.closeDialog();
    },
    resetForm() {
      this.$refs.inviteForm.reset();
      this.form.emails = [];
      this.form.role = null;
      this.currentEmail.value = "";
    },
    closeDialog() {
      this.localDialog = false;
    },
    onClose() {
      this.closeDialog();
      this.$refs.inviteForm.resetValidation();
    },
    checkDuplicateEmail(email) {
      if (this.editingIndex !== null) {
        const otherEmails = this.form.emails.filter(
          (_, index) => index !== this.editingIndex,
        );
        return (
          !otherEmails.includes(email) ||
          this.$t("users.userInvite.messages.duplicateEmail")
        );
      }
      return (
        !this.form.emails.includes(email) ||
        this.$t("users.userInvite.messages.duplicateEmail")
      );
    },
  },
};
</script>
