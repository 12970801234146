import { getInstance } from "./index";
import store from "../store";
import {
  currentRootRouteName,
  permissionHelpers,
  storeUtmParametersInLocalStorage,
} from "../helpers/util";
import { ROUTE_NAMES } from "@/helpers/routeNames";
import router from "@/router";
import i18n from "@/plugins/i18n";

export const authGuard = (to, from, next) => {
  const authService = getInstance();
  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      return next();
    }
    const args = { appState: { targetUrl: to.fullPath } };
    if (to.query.ref) {
      args.ref = to.query.ref;
    }
    // Otherwise, log in
    authService.loginWithRedirect(args);
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};

export const userGuard = async (to, from, next) => {
  const me = await getMe(next);

  if (isAuth0Login(to)) {
    return next();
  }
  if (me?.user?.hasNoDomainsAndIsInvited) {
    return next({ name: ROUTE_NAMES.GET_STARTED.LANDING });
  }
  if (!me?.domains?.length) {
    return next({ name: ROUTE_NAMES.GET_STARTED.NEW_WORKSPACE });
  }

  return next();
};

//prevents access if user does not have permission to route
//redirects to first accessible child if route has its access control based on its children instead of its own
export const permissionGuard = async (to, from, next) => {
  try {
    //If we're not actually changing pages, just let it through
    //For example, if we're just adding query params to the same path
    if (to.path === from.path) {
      return next();
    }

    const route = permissionHelpers.findRouteByName(router.options.routes, to.name);

    if (route?.children?.length && route?.meta?.permission?.isRedirectRoute) {
      const routeContext = {
        params: to.params,
        query: to.query,
      };

      const validChild = await permissionHelpers.findFirstAccessibleRoute(
        route.children,
        routeContext,
      );

      if (validChild) {
        return next({
          name: validChild.name,
          params: { ...to.params },
          query: { ...to.query },
        });
      }
    }

    const hasPermission = await permissionHelpers.checkRoutePermission(to);

    if (!hasPermission) {
      return onForbidden(to, from, next);
    }

    return next();
  } catch (error) {
    console.error("Permission check failed:", error);
    return onForbidden(to, from, next);
  }
};

export const rootRouteRedirectGuard = (to, from, next) => {
  const landingPage = store.getters["auth/landingPage"];

  if (isAuth0Login(to)) {
    return next();
  }

  const routeName = currentRootRouteName(landingPage);
  return next({ name: routeName });
};

export const redirectToLoginGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    const email = to?.query?.email;
    authService.logout();
    setTimeout(() => {
      authService.loginWithRedirect({
        appState: { targetUrl: "/" },
        authorizationParams: {
          ...(email && {
            login_hint: email,
          }),
        },
      });
    }, 100);
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};

export const redirectToSignupGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    const email = to?.query?.email;
    const returnPath = to?.query?.returnPath
      ? decodeURIComponent(to.query.returnPath)
      : undefined;
    const campaignCode = to?.params?.campaignCode;
    if (campaignCode) {
      localStorage.setItem("signupCampaignCode", campaignCode);
    }
    storeUtmParametersInLocalStorage(to?.query);

    if (authService.isAuthenticated) {
      authService.logout();
    }
    setTimeout(() => {
      authService.loginWithRedirect({
        appState: { targetUrl: returnPath || "/" },
        authorizationParams: {
          screen_hint: "signup",
          ...(email && {
            login_hint: email,
            connection: "Username-Password-Authentication",
          }),
        },
      });
    }, 100);
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};

const getMe = async (next) => {
  let { me } = store?.state?.auth;

  if (!me) {
    try {
      me = await store.dispatch("auth/getMe");
    } catch (error) {
      next({ name: "error" });
      throw new Error(error);
    }
  }
  return me;
};

// Skip guard during Auth0 login
const isAuth0Login = (to) => {
  const { code, state } = to.query;
  return !!code && !!state;
};

function onForbidden(to, from, next) {
  const showSnack = (text = i18n.t("routeErrors.forbidden")) => {
    store.dispatch("snackbar/snackbar", {
      show: true,
      text,
      color: "error",
      timeout: 8000,
    });
  };

  // Handle initial route access (direct URL access)
  if (!from.name) {
    showSnack();
    return next({ name: ROUTE_NAMES.ROOT.ROOT });
  }

  // Handle recursive navigation attempts
  if (to.name === from.name) {
    showSnack(i18n.t("routeErrors.unexpectedError"));
    return next(false);
  }

  // Standard case - stay on current route
  showSnack(i18n.t("routeErrors.forbidden") + " " + i18n.t("routeErrors.forbiddenHelp"));
  return next(false);
}
