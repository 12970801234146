<template>
  <v-dialog
    v-model="localDialog"
    scrollable
    max-width="600px"
  >
    <v-card v-if="dialog">
      <v-card-title>
        <span class="headline">{{ $t("taskManagement.list.newTask") }}</span>
      </v-card-title>
      <v-card-text ref="cardText">
        <v-form
          ref="taskForm"
          v-model="task.valid"
          v-on:submit.prevent
          @submit="onSubmit"
        >
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("common.name") }}
            </div>
            <AppDefaultTextField
              ref="nameRef"
              v-model="task.name.value"
              :rules="task.name.rules"
              autofocus
            />
          </div>
          <div class="pb-5 p-relative">
            <div class="label--small">
              {{ $t("common.description") }}
            </div>
            <AppDefaultTextarea
              ref="descriptionRef"
              hide-details
              v-model="task.description.value"
            />
          </div>
          <div class="pb-5 p-relative">
            <div class="label--small">
              {{ $t("taskManagement.list.responsible") | capitalize }}
            </div>
            <AppClientGroupedUserAutocomplete
              v-model="task.ownerId.value"
              @change="onOwnerChange"
              :items="assignableUsers"
              :loading="isAssignableUsersLoading"
              :noDataText="$t('common.noUsers')"
              :labelPaths="['workTitleLabel', 'contact.relation.label']"
              user-avatar
              hide-details
            />
          </div>
          <div class="p-relative">
            <div class="label--small">
              {{ $t("taskManagement.list.participants") | capitalize }}
            </div>
            <AppClientGroupedUserAutocomplete
              v-model="task.members.values"
              :items="assignableUsers"
              :loading="isAssignableUsersLoading"
              :noDataText="$t('common.noUsers')"
              :labelPaths="['workTitleLabel', 'contact.relation.label']"
              user-avatar
              multiple
              clearable
              select-all
            />
          </div>
          <div class="pb-5 p-relative">
            <div class="label--small">
              {{ $t("taskManagement.list.startDate") | capitalize }}
            </div>
            <AppFieldMenuDatePicker
              v-model="task.startDate.value"
              :max="task.dueDate.value"
              hide-details
            />
          </div>
          <div class="pb-5 p-relative">
            <div class="label--small">
              {{ $t("taskManagement.list.dueDate") | capitalize }}
            </div>
            <AppFieldMenuDatePickerRepeatable
              v-model="task.dueDate.value"
              hide-details
              model="TASK"
              :min="task.startDate.value"
              :defaultPickerDate="task.startDate.value"
              :notRepeatable="notRepeatable"
              :repeatOption="task.repeatOption.value"
              @repeatOption:change="onRepeatOptionChange"
            />
          </div>
          <AppProjectAutocomplete
            :required="!permissionMixins_canCreateOrEditResourceWithoutProject"
            ref="projectAutocomplete"
            type="project"
            v-model="task.projectId.value"
            @change="onProjectIdChange"
            isReadyToInitialFetch
            :queryParams="{
              userIds: [task?.ownerId?.value || currentUserId],
              alwaysIncludeIds: [projectId],
            }"
            @created="onProjectCreated"
          />
          <div>
            <div class="d-flex align-end">
              <div class="label--small required-asterisk">
                {{ $t("common.status") }}
              </div>
              <v-spacer />
              <AppSettingsBtn
                v-if="canEditStatuses"
                @click.stop="onEditStatusesClick"
                :tooltip="statusMixins_tooltip('task')"
              />
            </div>
            <AppChangeCustomStatusAutocomplete
              v-if="showChangeStatusAutocomplete"
              v-model="task.statusId.value"
              model="task"
              should-set-default-value
            />
          </div>
          <div class="pb-1">
            <span class="label--small">
              {{ $t("fileManagement.fileExplorer.mainToolbar.attachments") }}
            </span>
          </div>
          <AppFilePicker
            :value="task.files.values"
            :mimeTypes="['*/*']"
            maxHeight="400px"
            @change="onFilesChange"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onSubmit"
          @cancel="onClose"
          :loading="isCreating"
          :confirmText="$t('common.create')"
        />
        <AppCheckToKeepOpen
          class="pl-2"
          v-model="keepOpen"
        />
      </v-card-actions>
    </v-card>
    <AppEditCustomStatusDialog
      v-model="editStatusDialog.active"
      model="task"
    />
  </v-dialog>
</template>

<script>
import { getSearchUsers } from "@/services/search/users";
import { mapGetters } from "vuex";
import { statusMixins, permissionMixins } from "@/helpers/mixins";
import _ from "lodash";
import { splitItemsBySource, uploadFilesAndCopyStorages } from "@/helpers/util";

export default {
  mixins: [statusMixins, permissionMixins],
  props: {
    dialog: Boolean,
    data: Object,
    notRepeatable: Boolean,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      task: {
        valid: false,
        name: {
          value: "",
          rules: this.$rules.NAME_RULES,
        },
        description: {
          value: "",
        },
        ownerId: {
          value: "",
        },
        members: {
          values: [],
        },
        startDate: {
          value: "",
        },
        dueDate: {
          value: "",
        },
        projectId: {
          value: "",
        },
        statusId: {
          value: null,
        },
        repeatOption: {
          value: null,
        },
        files: {
          values: [],
        },
        folderId: null,
      },
      assignableUsers: [],
      isAssignableUsersLoading: false,
      editStatusDialog: {
        active: false,
      },
      keepOpen: false,
      showChangeStatusAutocomplete: true,
      isCreating: false,
      isDraggingOver: false,
    };
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    ...mapGetters("auth", {
      currentUserId: "currentUserId",
    }),
    projectId() {
      return this.data?.projectId || this.task.projectId.value || null;
    },
    canEditStatuses() {
      return this.permissionMixins_status.edit.can;
    },
  },

  watch: {
    dialog: {
      handler(val) {
        if (val) {
          this.fillFormWithData();
          this.getUsers(this.projectId);
        }
      },
    },
  },
  methods: {
    onFilesChange(files) {
      const oldFileCount = this.task.files.values.length;
      const newFileCount = files.length;

      if (newFileCount > oldFileCount) {
        this.scrollToBottom();
      }

      this.task.files.values = files;
    },
    onProjectCreated({ project }) {
      this.onProjectIdChange(project.id);
      this.task.projectId.value = project.id;
    },
    onOwnerChange() {
      this.$refs.projectAutocomplete.getProjects();
    },
    onProjectIdChange(projectId) {
      this.getUsers(projectId);
    },
    onRepeatOptionChange({ repeatOption }) {
      this.task.repeatOption.value = repeatOption;
    },
    getUsers(projectId) {
      this.isAssignableUsersLoading = true;
      this.assignableUsers = [];

      const params = {
        projectId,
        ...(projectId && { onlyWithProjectAccess: true }),
        alwaysIncludeIds: [this.data?.userId],
      };
      getSearchUsers({ params })
        .then((res) => {
          this.assignableUsers = res;
        })
        .finally(() => {
          this.isAssignableUsersLoading = false;
        });
    },
    onEditStatusesClick() {
      this.editStatusDialog.active = true;
    },
    onClose() {
      this.close();
      this.$refs.taskForm.resetValidation();
    },
    afterTaskCreate() {
      this.close();
    },
    close() {
      this.localDialog = false;
    },
    formatData() {
      const {
        name,
        description,
        ownerId,
        members,
        startDate,
        dueDate,
        projectId,
        statusId,
        repeatOption,
      } = this.task;

      const data = {
        formItemId: this.data?.formItemId,
        name: name.value,
        memberIds: members.values,
        ...(description.value && { description: description.value }),
        ...(ownerId.value && { ownerId: ownerId.value }),
        ...(startDate.value && { startDate: startDate.value }),
        ...(dueDate.value && { dueDate: dueDate.value }),
        ...(projectId.value && {
          projectId: projectId.value,
        }),
        ...(repeatOption.value && { repeatOption: repeatOption.value }),
        ...(statusId.value && { statusId: statusId.value }),
      };
      return data;
    },
    resetForm() {
      this.task.name.value = "";
      this.task.description.value = "";
      this.task.ownerId.value = "";
      this.task.members.values = [];
      this.task.startDate.value = "";
      this.task.dueDate.value = "";
      this.task.projectId.value = "";
      this.task.statusId.value = null;
      this.task.repeatOption.value = null;
      this.task.files.values = [];
    },
    onTaskCreated({ task }) {
      this.$emit("created", { task });
      this.resetForm();
      if (!this.keepOpen) {
        this.close();
      } else {
        this.fillFormWithData();
        this.showChangeStatusAutocomplete = false;
        this.$nextTick(() => {
          this.showChangeStatusAutocomplete = true;
        });
        this.$refs.nameRef.focus();
      }
      this.$refs.taskForm.resetValidation();
    },
    onSubmit() {
      const valid = this.$refs.taskForm.validate();
      if (valid) {
        const data = this.formatData();
        this.createOrUpdateTask(data);
      }
    },
    createOrUpdateTask(data) {
      this.isCreating = true;
      const fileItems = this.task.files.values;

      this.$store
        .dispatch("task/createTask", { data })
        .then(async (task) => {
          if (fileItems.length > 0) {
            const folderId = task.folderId;
            const { files, storages } = splitItemsBySource(fileItems);
            await uploadFilesAndCopyStorages({
              upload: {
                uploadType: "PARENT_FOLDER",
                uploadTypeId: folderId,
                files,
              },
              copy: {
                storages,
                parentId: folderId,
              },
            });
            await this.getTask(task);
            this.onTaskCreated({ task });
          } else {
            this.onTaskCreated({ task });
          }
        })
        .catch((error) => {
          // Handle error if needed
          console.error("Error creating task:", error);
        })
        .finally(() => {
          this.isCreating = false;
        });
    },
    async getTask(task) {
      await this.$store.dispatch("task/getTask", task.id);
    },
    fillFormWithData() {
      const data = this.data;
      this.task.ownerId.value = this.currentUserId;
      if (!_.isEmpty(data)) {
        this.task.dueDate.value = data.dueDate;
        this.task.startDate.value = data.startDate;
        this.task.projectId.value = data.projectId;
      }
    },
    scrollToBottom() {
      const cardContent = this.$refs.cardText;
      if (cardContent) {
        const scrollTarget = cardContent.scrollHeight;

        cardContent.scrollTo({
          top: scrollTarget,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>
