import { ROUTE_NAMES, ROUTE_PERMISSIONS } from "@/helpers/routeNames";
import multiguard from "../multiguard";
import { authGuard, userGuard } from "@/auth";
import { HELP_CATEGORIES } from "@/helpers/constants/help";
const { ROOT, ALL_FILES } = ROUTE_NAMES.STORAGE;

export const storageRoutes = [
  {
    path: "/storage",
    name: ROOT,
    meta: {
      permission: ROUTE_PERMISSIONS.STORAGE,
      helpCategory: HELP_CATEGORIES.STORAGE,
    },
    beforeEnter: multiguard([authGuard, userGuard]),
    component: () =>
      import(
        /* webpackChunkName: "storage" */ "../../pages/FileManagement/CompanyStoragePage"
      ),
    children: [
      {
        path: "all-files/:rootId/:folderId",
        name: ALL_FILES,
        meta: {
          permission: ROUTE_PERMISSIONS.STORAGE,
        },
        component: () =>
          import(
            /* webpackChunkName: "storage" */ "../../pages/FileManagement/CompanyStoragePage/AllFiles"
          ),
      },
    ],
  },
];
