<template>
  <v-dialog
    v-model="localDialog"
    max-width="600px"
    scrollable
  >
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ title }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="newContactForm"
          v-model="contact.valid"
          v-on:submit.prevent
          @submit="onCreate"
        >
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("common.firstName") }}
            </div>
            <AppDefaultTextField
              ref="nameRef"
              v-model="contact.firstName.value"
              :rules="$rules.NAME_RULES"
              autofocus
            />
          </div>

          <div class="p-relative">
            <div class="label--small">
              {{ $t("common.lastName") }}
            </div>
            <AppDefaultTextField
              ref="nameRef"
              v-model="contact.lastName.value"
            />
          </div>

          <div class="p-relative">
            <div class="label--small">
              {{ $t("common.email") | capitalize }}
            </div>
            <AppDefaultTextField
              v-model="contact.email.value"
              :rules="contact.email.rules"
            />
          </div>

          <div class="p-relative">
            <div class="label--small">
              {{ $t("common.phoneNumber") | capitalize }}
            </div>
            <AppPhoneInput
              v-model="contact.phone.value"
              :placeholder="$t('common.phoneNumber')"
              hide-label
            />
          </div>

          <div class="p-relative">
            <div class="label--small">
              {{ $t("users.label.jobTitle.title") | capitalize }}
            </div>
            <!-- if dialog re-renders the labelpicker to trigger mounted to get the labels when the dialog opens. might be a bit hacky, but it works for now :D  -->
            <AppLabelPicker
              v-if="dialog"
              v-model="contact.labelId.value"
              :labelType="$constant.LABEL_TYPE.JOB_TITLE"
            />
          </div>

          <div
            class="p-relative"
            @click="onClickClient"
          >
            <div class="label--small">
              {{ $t("common.customerSupplier") | capitalize }}
            </div>
            <div style="position: relative">
              <AppClientAutocomplete
                :disabled="isEditingClientDisabled"
                v-model="contact.clientId.value"
                isReadyToInitialFetch
                :queryParams="{
                  isActive: true,
                  alwaysIncludeIds: [contact.clientId.value],
                }"
                @created="onClientCreated"
              />
              <div
                v-if="isEditingClientDisabled"
                @click="onClickClient"
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  z-index: 1;
                "
              ></div>
            </div>
          </div>

          <div class="p-relative">
            <v-checkbox
              class="mt-0"
              v-model="contact.isActive.value"
              :label="$t('common.active')"
            />
          </div>

          <AppErrorAlert v-if="errorMessage">
            {{ errorMessage }}
          </AppErrorAlert>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onCreate"
          @cancel="close"
          :loading="isSubmitting"
          :confirmText="confirmText"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { filtersMixins } from "@/helpers/mixins";
import { mapGetters } from "vuex";

export default {
  mixins: [filtersMixins],
  props: {
    dialog: Boolean,
    data: Object,
    title: String,
    confirmText: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      tableModel: this.$constant.CONTACTS,
      isSubmitting: false,
      errorMessage: "",
      contact: {
        firstName: {
          value: "",
        },
        lastName: {
          value: "",
        },
        email: {
          value: "",
          rules: this.$rules.EMAIL_NOT_REQUIRED_RULES,
        },
        phone: {
          value: "",
        },
        labelId: {
          value: "",
        },
        clientId: {
          value: null,
        },
        isActive: {
          value: true,
        },
      },
      newClientDialog: {
        active: false,
      },
    };
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    isEditMode() {
      return this.data ? true : false;
    },
    ...mapGetters("activeIntegrations", {
      hasEditingClientOnContactNotAllowedIntegrations:
        "hasEditingClientOnContactNotAllowedIntegrations",
    }),
    isEditingClientDisabled() {
      return this.isEditMode && this.hasEditingClientOnContactNotAllowedIntegrations;
    },
  },
  watch: {
    dialog: {
      handler(dialog) {
        if (dialog) {
          this.fillFormWithData(this.data);
        }
      },
    },
  },
  methods: {
    fillFormWithData(data) {
      if (!data) return;
      this.contact.firstName.value = data?.firstName || "";
      this.contact.lastName.value = data?.lastName || "";
      this.contact.email.value = data?.email || "";
      this.contact.phone.value = data?.phone || "";
      this.contact.labelId.value = data?.labelId || "";
      this.contact.clientId.value = data?.client?.id || null;
      this.contact.isActive.value = data?.isActive || true;
    },
    resetForm() {
      this.contact.firstName.value = "";
      this.contact.lastName.value = "";
      this.contact.email.value = "";
      this.contact.phone.value = "";
      this.contact.labelId.value = "";
      this.contact.clientId.value = null;
      this.contact.isActive.value = true;
    },
    onClientCreated({ client }) {
      this.contact.clientId.value = client.id;
    },
    onCreate() {
      const valid = this.$refs.newContactForm.validate();
      if (!valid) return;
      const firstName = this.contact.firstName.value;
      const lastName = this.contact.lastName.value || null;
      const email = this.contact.email.value || null;
      const phone = this.contact.phone.value || null;
      const labelId = this.contact.labelId.value || null;
      const isActive = this.contact.isActive.value;
      const clientId = this.contact.clientId.value || null;

      const data = {
        id: this.data?.id,
        body: {
          firstName,
          lastName,
          email,
          phone,
          labelId,
          isActive: typeof isActive === "boolean" ? isActive : true,
          clientId,
        },
        filters: this.filtersMixins_filters,
        dataTableOptions: this.filtersMixins_dataTableOptions,
      };

      this.createOrUpdateContact(data);
    },
    createOrUpdateContact(data) {
      const storeType = this.isEditMode
        ? "contacts/updateContact"
        : "contacts/createContact";
      this.isSubmitting = true;
      this.errorMessage = "";
      this.$store
        .dispatch(storeType, data)
        .then((contact) => {
          this.afterSubmit(contact);
        })
        .catch((error) => {
          this.errorMessage = error.message;
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    afterSubmit(contact) {
      this.$emit("confirm", { contact });
      this.localDialog = false;
      this.resetForm();
    },
    onClickClient() {
      if (!this.isEditingClientDisabled) return;
      this.$store.dispatch("snackbar/snackbar", {
        show: true,
        vHtml: true,
        text: this.$t("common.editFieldNotAllowedByAccountingSystem", {
          field: this.$t("common.customerSupplier"),
          entity: this.$t("common.contact"),
        }),
        color: "warning",
        timeout: 15000,
      });
    },
    close() {
      this.localDialog = false;
    },
  },
};
</script>
