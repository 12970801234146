<template>
  <div>
    <AppModuleAutocomplete
      ref="autocomplete"
      v-bind="params"
      v-on="listeners"
      :items="filteredProjects"
      :loading="loading"
      item-text="name"
      item-value="id"
      :type="type"
      :noDataText="noDataText"
      :titleField="$constant.MODULE_FIELD_CONFIG.PROJECT.TITLE_FIELD"
      :subTitleFields="$constant.MODULE_FIELD_CONFIG.PROJECT.SUB_TITLE_FIELDS"
    >
      <template
        #append-item
        v-if="canCreateProjects"
      >
        <AppSelectAppendBtn @click="onCreateProject">
          {{ $t("common.create") }}
        </AppSelectAppendBtn>
      </template>
    </AppModuleAutocomplete>
    <AppProjectDialog
      v-if="canCreateProjects"
      v-model="newProjectDialog.active"
      :title="$t('project.newProject.title')"
      :confirmText="$t('common.create')"
      @confirm="onProjectCreated"
    />
  </div>
</template>

<script>
import { permissionMixins } from "@/helpers/mixins";
import { getSearchProjects } from "@/services/search/project";

export default {
  mixins: [permissionMixins],
  props: {
    value: [String, Array],
    queryParams: Object,
    isReadyToInitialFetch: Boolean,
    idsToExclude: Array,
    type: String,
  },
  data() {
    return {
      newProjectDialog: {
        active: false,
      },
      searchProjects: [],
      loading: false,
      hasInitialFetched: false,
    };
  },
  watch: {
    isReadyToInitialFetch: {
      handler(newVal) {
        if (newVal && !this.hasInitialFetched) {
          this.getProjects().then(() => {
            this.hasInitialFetched = true;
          });
        }
      },
      immediate: true,
    },
  },
  computed: {
    canCreateProjects() {
      return this.permissionMixins_project.create.can;
    },
    filteredProjects() {
      return this.searchProjects.filter((p) => !this.idsToExclude?.includes(p.id));
    },
    noDataText() {
      return this.$t("common.noProjects");
    },
    params() {
      const params = { ...this.$props, ...this.$attrs };
      return params;
    },
    listeners() {
      const listeners = {
        ...this.$listeners,
      };
      return listeners;
    },
  },
  methods: {
    onCreateProject() {
      this.newProjectDialog.active = true;
    },
    onProjectCreated({ project }) {
      this.getProjects().then(() => {
        const searchProject = this.searchProjects.find((p) => p.id === project.id);
        if (!searchProject) return;
        this.$emit("created", { project, searchProject });
      });
    },
    async getProjects() {
      this.loading = true;
      await getSearchProjects({ params: { ...this.queryParams } })
        .then((projects) => {
          this.searchProjects = projects;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    focus() {
      this.$refs.autocomplete.focus();
    },
    activateMenu() {
      this.$refs.autocomplete.activateMenu();
    },
  },
};
</script>
