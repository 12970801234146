<template>
  <div
    v-if="showDrawer"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd"
  >
    <AppDrawerVariantToggleBtn />
    <v-navigation-drawer
      :width="DRAWER.WIDTH"
      :mini-variant-width="DRAWER.MINI_WIDTH"
      app
      permanent
      :miniVariant="isMini"
      touchless
    >
      <template v-slot:prepend>
        <MainPrepend v-if="drawerType === 'MAIN'" />
        <SettingsPrepend v-else-if="drawerType === 'SETTINGS'" />
        <ProjectViewPrepend v-else-if="drawerType === 'PROJECT'" />
        <TemplatePrepend v-else-if="drawerType === 'TEMPLATE'" />
        <CMSPrepend v-else-if="drawerType === 'CMS'" />
      </template>

      <div class="d-flex flex-column h-100">
        <MainContent v-if="drawerType === 'MAIN'" />
        <SettingsContent v-else-if="drawerType === 'SETTINGS'" />
        <ProjectViewContent v-else-if="drawerType === 'PROJECT'" />
        <TemplateContent v-else-if="drawerType === 'TEMPLATE'" />
        <CMSContent v-else-if="drawerType === 'CMS' && hasCmsAccess" />
        <v-spacer />

        <template v-if="canEdit('billing')">
          <AppPlanUpgradeLabel />
        </template>
        <AppNavigationList>
          <div
            class="d-flex px-2 align-center"
            :class="{
              'flex-column-reverse': isMini,
              'justify-space-between': !isMini,
            }"
          >
            <AppDefaultTooltip
              top
              v-if="shouldShowSettingsButton"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  class="ma-1"
                  :to="{ name: $routeNames.SETTINGS.ROOT }"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    small
                    :color="$vuetify.theme.dark ? 'grey lighten-2' : 'grey darken-2'"
                  >
                    {{ $icons.REGULAR.ROUTE.SETTINGS }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("drawer.route.settings") }}</span>
            </AppDefaultTooltip>

            <AppDefaultTooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  class="ma-1"
                  @click="onHelpClick"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    small
                    :color="$vuetify.theme.dark ? 'grey lighten-2' : 'grey darken-2'"
                  >
                    {{ $icons.REGULAR.COMMON.CIRCLE_QUESTION }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t("drawer.route.help") }}
                <AppKbd
                  small
                  class="ml-2"
                  dark
                  :shortcut="$constant.KEYBOARD_SHORTCUTS.help"
                />
              </span>
            </AppDefaultTooltip>

            <AppToggleMenuBtn
              right
              v-if="
                canView('timeEntry') ||
                canCreate('project') ||
                canCreate('task') ||
                canCreate('form')
              "
            >
              <template v-slot:activator="{ attrs, on }">
                <AppDefaultTooltip top>
                  <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                    <v-btn
                      icon
                      small
                      class="ma-1"
                      v-bind="{ ...attrs, ...tooltipAttrs }"
                      v-on="{ ...on, ...tooltipOn }"
                    >
                      <v-icon
                        small
                        :color="$vuetify.theme.dark ? 'grey lighten-2' : 'grey darken-2'"
                      >
                        {{ $icons.REGULAR.ACTION.ADD }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("drawer.route.quickActions.title") }}</span>
                </AppDefaultTooltip>
              </template>
              <AppNavigationItem
                v-if="canView('timeEntry')"
                :prependIcon="$icons.REGULAR.ROUTE.TIME_REGISTRATION"
                :title="$t('drawer.route.quickActions.logHours')"
                @click="onLogHoursClick"
                prepend-icon-small
              />
              <AppNavigationItem
                v-if="canCreate('project')"
                :prependIcon="$icons.REGULAR.ROUTE.PROJECT"
                :title="$t('drawer.route.quickActions.createProject')"
                @click="onCreateProjectClick"
                prepend-icon-small
              />
              <AppNavigationItem
                v-if="canCreate('task')"
                :prependIcon="$icons.REGULAR.ROUTE.TASK"
                :title="$t('drawer.route.quickActions.newTask')"
                @click="onNewTaskClick"
                prepend-icon-small
              />
              <AppNavigationItem
                v-if="canCreate('form')"
                :prependIcon="$icons.REGULAR.ROUTE.FORM"
                :title="$t('drawer.route.quickActions.fillOutForm')"
                @click="onFillOutFormClick"
                prepend-icon-small
              />
            </AppToggleMenuBtn>
          </div>
        </AppNavigationList>
      </div>

      <template v-slot:append>
        <MainAppend />
      </template>
      <AppTimeEntryDialog
        v-if="canView('timeEntry')"
        v-model="dialog.newTimeEntry.active"
        :title="$t('timeRegistration.newTimeEntry.createTitle')"
        :defaultProjectId="projectId"
      />
      <AppProjectDialog
        v-if="canCreate('project')"
        v-model="dialog.newProject.active"
        :title="$t('project.newProject.title')"
        :defaultValue="dialog.newProject.defaultValue"
        :parentId="projectId"
      />
      <AppTaskDialog
        v-if="canCreate('task')"
        v-model="dialog.newTask.active"
        :data="{ projectId }"
      />
      <AppFormTemplatePickerDialog
        v-if="canCreate('form')"
        v-model="dialog.newForm.active"
        @select="onFormSelect"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import MainPrepend from "./prepend/MainPrepend";
import MainContent from "./content/MainContent";
import MainAppend from "./append/MainAppend";
import SettingsPrepend from "./prepend/SettingsPrepend";
import SettingsContent from "./content/SettingsContent";
import ProjectViewContent from "./content/ProjectViewContent";
import ProjectViewPrepend from "./prepend/ProjectViewPrepend";
import TemplateContent from "./content/TemplateContent";
import TemplatePrepend from "./prepend/TemplatePrepend";
import CMSContent from "./content/CMSContent";
import CMSPrepend from "./prepend/CMSPrepend";
import { permissionHelpers } from "@/helpers/util";
import { DRAWER } from "@/helpers/constants/drawer";
import { KEYBOARD_SHORTCUTS } from "@/helpers/constants/shortcuts";

export default {
  name: "AppDrawer",
  components: {
    MainPrepend,
    MainContent,
    MainAppend,
    SettingsPrepend,
    SettingsContent,
    ProjectViewContent,
    ProjectViewPrepend,
    TemplateContent,
    TemplatePrepend,
    CMSContent,
    CMSPrepend,
  },
  data() {
    return {
      DRAWER,
      swipe: {
        startX: 0,
        startY: 0,
      },
      disabledDrawerRouteNames: [
        this.$routeNames.GET_STARTED.NEW_WORKSPACE,
        this.$routeNames.GET_STARTED.LANDING,
      ],
      dialog: {
        newTimeEntry: {
          active: false,
        },
        newProject: {
          active: false,
          defaultValue: { startDate: null },
        },
        newTask: {
          active: false,
        },
        newForm: {
          active: false,
        },
      },
    };
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    ...mapState("auth", {
      me: (state) => state.me,
    }),
    ...mapGetters("auth", {
      hasCmsAccess: "hasCmsAccess",
    }),
    ...mapGetters("appDrawer", {
      isMini: "isDrawerMiniVariant",
    }),
    isDisabledRouteName() {
      return this.disabledDrawerRouteNames.some((x) => x === this.$route.name);
    },
    isOnRoot() {
      return this.$route.fullPath === process.env.BASE_URL;
    },
    showDrawer() {
      if (this.isOnRoot) return false;
      if (!this.me) return false;
      if (this.isDisabledRouteName) return false;

      const isFormView = this.isUnderRoute(this.$routeNames.FORM_VIEW.ROOT);
      if (isFormView) return false;

      const isTaskView = this.isUnderRoute(this.$routeNames.TASK_VIEW.ROOT);
      if (isTaskView) return false;

      const isFormTemplateBuilder = this.isUnderRoute(
        this.$routeNames.TEMPLATE.FORM_TEMPLATE_BUILDER,
      );
      if (isFormTemplateBuilder) return false;

      const isProjectTemplateBuilder = this.isUnderRoute(
        this.$routeNames.TEMPLATE.PROJECT_TEMPLATE_BUILDER,
      );
      if (isProjectTemplateBuilder) return false;

      const isCMSFormTemplateBuilder = this.isUnderRoute(
        this.$routeNames.CMS.FORM_TEMPLATE_BUILDER,
      );
      if (isCMSFormTemplateBuilder) return false;

      const isCMSProjectTemplateBuilder = this.isUnderRoute(
        this.$routeNames.CMS.PROJECT_TEMPLATE_BUILDER,
      );
      if (isCMSProjectTemplateBuilder) return false;

      const isOfficeOnline = this.isUnderRoute(this.$routeNames.OFFICE_ONLINE.ROOT);
      if (isOfficeOnline) return false;

      const isChildDomainInvitation = this.isUnderRoute(
        this.$routeNames.CHILD_DOMAIN_INVITATION.ROOT,
      );
      if (isChildDomainInvitation) return false;

      const isUnderErrorPages =
        this.isUnderRoute("notFound") ||
        this.isUnderRoute("forbidden") ||
        this.isUnderRoute("invalidUser") ||
        this.isUnderRoute("error");
      if (isUnderErrorPages) return false;

      return true;
    },
    drawerType() {
      const isSettingsRoute = this.isUnderRoute(this.$routeNames.SETTINGS.ROOT);
      if (isSettingsRoute) return "SETTINGS";

      const isProjectViewRoute = this.isUnderRoute(this.$routeNames.PROJECT.VIEW);
      if (isProjectViewRoute) return "PROJECT";

      const isTemplateRoute = this.isUnderRoute(this.$routeNames.TEMPLATE.ROOT);
      if (isTemplateRoute) return "TEMPLATE";

      const isCMSRoute = this.isUnderRoute(this.$routeNames.CMS.ROOT);
      if (isCMSRoute) return "CMS";

      return "MAIN";
    },
    shouldShowSettingsButton() {
      const isOnSettings = this.drawerType === "SETTINGS";
      const isOnProjectView = this.drawerType === "PROJECT";
      return !(isOnProjectView || isOnSettings);
    },
  },
  methods: {
    onHelpClick() {
      this.$store.dispatch("helpPanel/toggle");
    },
    canView(path) {
      return permissionHelpers.getPermissionObject({ path: path }).view.can;
    },
    canEdit(path) {
      return permissionHelpers.getPermissionObject({ path: path }).edit.can;
    },
    canCreate(path) {
      return permissionHelpers.getPermissionObject({ path: path }).create.can;
    },
    isUnderRoute(routeName) {
      return this.$route.matched.some((m) => m.name === routeName);
    },
    onLogHoursClick() {
      this.dialog.newTimeEntry.active = true;
    },
    onCreateProjectClick() {
      this.dialog.newProject.active = true;
    },
    onNewTaskClick() {
      this.dialog.newTask.active = true;
    },
    onFillOutFormClick() {
      this.dialog.newForm.active = true;
    },
    async onFormSelect({ templateId, onFormCreated, onFormFinally }) {
      this.$store
        .dispatch("forms/convertTemplate", {
          id: templateId,
          body: { projectId: this.projectId },
        })
        .then((form) => {
          onFormCreated();
          if (form) {
            this.$router.push({
              name: this.$routeNames.FORM_VIEW.ROOT,
              params: { formId: form.id },
            });
          }
        })
        .finally(() => {
          onFormFinally();
        });
    },

    //swipe functionality for drawer on tablets
    onDrawerVariantToggle() {
      this.$store.commit("appDrawer/setIsDrawerMiniVariant", !this.isMini);
    },
    handleTouchStart(evt) {
      const firstTouch = evt.touches[0];
      this.swipe.startX = firstTouch.clientX;
      this.swipe.startY = firstTouch.clientY;
    },

    handleTouchMove(evt) {
      if (!this.swipe.startX || !this.swipe.startY) {
        return;
      }
      evt.preventDefault();
    },
    handleTouchEnd(evt) {
      if (!this.swipe.startX || !this.swipe.startY) {
        return;
      }

      const endX = evt.changedTouches[0].clientX;
      const endY = evt.changedTouches[0].clientY;

      const swipeXLength = endX - this.swipe.startX;
      const swipeYLength = endY - this.swipe.startY;

      const swipeThreshold = 20;

      const isSwipeAboveThreshold = Math.abs(swipeXLength) > swipeThreshold;
      if (isSwipeAboveThreshold) {
        const isMoreHorizontal = Math.abs(swipeXLength) > Math.abs(swipeYLength);
        if (isMoreHorizontal) {
          const isRightSwipeAndMini = swipeXLength > 0 && this.isMini;
          const isLeftSwipeAndNotMini = swipeXLength < 0 && !this.isMini;
          if (isRightSwipeAndMini) {
            // Right swipe
            this.onDrawerVariantToggle();
          } else if (isLeftSwipeAndNotMini) {
            // Left swipe
            this.onDrawerVariantToggle();
          }
        }
      }

      // Reset values
      this.swipe.startX = 0;
      this.swipe.startY = 0;
    },
  },
};
</script>

<style>
.v-icon.v-icon {
  transition: 0s;
}
</style>
