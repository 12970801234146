<template>
  <!-- This component can easily be refactoried into a select "module item" dialog -->
  <AppSelectItemsDialog
    ref="dialog"
    v-model="localDialog"
    :title="$t('common.dialogs.selectUsersContacts.title')"
    :searchPlaceholder="$t('common.dialogs.selectUsersContacts.searchPlaceholder')"
    :confirmText="$t('common.confirm')"
    :selectedItemsText="
      $tc('common.dialogs.selectUsersContacts.itemsSelected', selectedItems.length)
    "
    :isLoadingItems="isLoading"
    :items="sortedItems"
    :selectedItems="selectedItems"
    :search="search"
    :filter="filter"
    @searchInput="updateSearch"
    @submit="onSubmit"
    @showSelectedToggle="onShowSelectedToggle"
  >
    <template #item-list="{ items }">
      <DynamicScroller
        :items="items"
        :min-item-size="60"
        class="scroller"
        style="scrollbar-gutter: stable"
      >
        <template v-slot="{ item, index, active }">
          <DynamicScrollerItem
            class="pb-2 pr-2"
            :item="item"
            :active="active"
            :data-index="index"
          >
            <AppSelectModuleItem
              :item="item"
              :search="search"
              :titleField="$constant.MODULE_FIELD_CONFIG.USER.TITLE_FIELD"
              :subTitleFields="$constant.MODULE_FIELD_CONFIG.USER.SUB_TITLE_FIELDS"
              @toggleSelected="handleToggleItem(item)"
              :isSelected="isItemSelected(item)"
            />
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
    </template>
    <template #header-actions>
      <v-checkbox
        class="pa-0 ma-0"
        hide-details
        v-model="showUsers"
        :label="$t('drawer.route.users')"
      ></v-checkbox>
      <v-checkbox
        hide-details
        class="ml-4 pa-0 ma-0"
        v-model="showContacts"
        :label="$t('drawer.route.contacts')"
      ></v-checkbox>
    </template>
  </AppSelectItemsDialog>
</template>

<script>
import { DynamicScroller, DynamicScrollerItem } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

import { getSearchUsers } from "@/services/search/users";
import { getSearchContacts } from "@/services/search/contact";

export default {
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      search: "",
      selectedItems: [],
      items: [],
      isLoading: false,
      showSelected: false,
      showUsers: true,
      showContacts: true,
    };
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        this.fetchItems();
      }
    },
  },
  computed: {
    sortedItems: {
      get() {
        return this.items.sort((a, b) => a?.name?.localeCompare(b?.name));
      },
    },
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    count() {
      return this.items?.length;
    },
  },
  components: {
    DynamicScroller,
    DynamicScrollerItem,
  },
  methods: {
    filter(item) {
      const matchesSearch =
        !this.search || item?.searchStr?.includes(this.search.toLowerCase());

      const matchesType =
        (item.type === "user" && this.showUsers) ||
        (item.type === "contact" && this.showContacts);

      return matchesSearch && matchesType;
    },
    onShowSelectedToggle(value) {
      this.showSelected = value;
    },
    updateSearch(value) {
      this.search = value;
    },
    handleToggleItem(item) {
      this.toggleItem(item);
    },
    toggleItem(item) {
      const index = this.selectedItems.findIndex((i) => i.id === item.id);
      if (index === -1) {
        this.selectedItems.push(item);
      } else {
        this.selectedItems.splice(index, 1);
      }
    },
    isItemSelected(item) {
      return this.selectedItems.some((i) => i.id === item.id);
    },
    onSubmit() {
      this.$emit("submit", this.selectedItems);
      this.selectedItems = [];
      this.localDialog = false;
    },
    async fetchItems() {
      this.isLoading = true;
      const [users, contacts] = await Promise.all([this.getUsers(), this.getContacts()]);
      this.items = [...users, ...contacts];
      this.isLoading = false;
    },
    async getUsers() {
      this.isLoadingUsers = true;
      const params = {
        // alwaysIncludeIds: this.recipients?.map((recipient) => recipient?.userId) ?? [],
      };
      let users = await getSearchUsers({ params });
      users = users.map((user) => ({
        ...user,
        type: "user",
      }));
      return users;
    },
    async getContacts() {
      let contacts = await getSearchContacts();
      contacts = contacts.map((contact) => ({
        ...contact,
        type: "contact",
      }));
      return contacts;
    },
  },
};
</script>

<style scoped>
.scroller {
  height: 100%;
}
</style>
