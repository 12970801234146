<template>
  <tr
    class="list_tr list_tr-height"
    :class="rowStateClasses"
    @contextmenu="dataTableItemMixins_onRightClick"
  >
    <AppDefaultTableCell
      v-for="(header, index) in headers"
      :key="index"
      :header="header"
      :isSelected="isSelected"
      :select="select"
      :index="index"
      :item="contact"
    >
      <template v-if="header.value === dataFields.NAME">
        <div class="table-cell-content__breakable-long-text">
          <span class="d-inline-block">{{ contact.fullName }}</span>
        </div>
      </template>

      <template v-if="header.value === dataFields.CLIENT">
        <div class="table-cell-content__breakable-long-text">
          <span class="d-inline-block">{{ clientName }}</span>
        </div>
      </template>

      <template v-if="header.value === dataFields.LABEL">
        <AppLabelBtn
          :title="jobTitle"
          :isUpdating="contact?.isUpdatingLabel"
        />
      </template>

      <template v-if="header.value === dataFields.RELATION_LABEL">
        <AppLabelBtn
          :title="responsibility"
          :isUpdating="contact?.isUpdatingRelationLabel"
        />
      </template>

      <template v-if="header.value === dataFields.PHONE">
        <AppPhoneLinkText :phone="contact.phone" />
      </template>

      <template v-if="header.value === dataFields.EMAIL">
        <AppLinkText
          :link="`mailto:${contact.email}`"
          underline-on-hover
        >
          {{ contact.email }}
        </AppLinkText>
      </template>

      <template v-if="header.value === dataFields.ACTIVE">
        <AppDefaultCheckboxIcon
          :isSelected="contact.isActive"
          :disabled="disabled(header)"
        />
      </template>

      <template v-if="header.value === dataFields.MENU">
        <div class="text-end">
          <v-btn
            icon
            @click="dataTableItemMixins_onEllipsisClick"
            ref="ellipsis"
          >
            <v-icon>{{ $icons.REGULAR.ACTION.MENU }}</v-icon>
          </v-btn>
        </div>
      </template>
    </AppDefaultTableCell>
  </tr>
</template>

<script>
import { dataTableItemMixins } from "@/helpers/mixins";

export default {
  mixins: [dataTableItemMixins],
  props: {
    contact: Object,
    jobTitleLabel: Object,
    responsibilityLabel: Object,
    isHighlighted: Boolean,
    isContextMenuActiveItem: Boolean,
    isSelected: Boolean,
    select: Function,
    headers: {
      type: Array,
      required: true,
    },
    dataFields: {
      type: Object,
      required: true,
    },
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    rowStateClasses() {
      return {
        "list_tr--active": this.isContextMenuActiveItem,
        "list_tr--selected": this.isSelected,
      };
    },
    clientName() {
      return this.contact?.client?.name;
    },
    jobTitle() {
      return this.jobTitleLabel?.name;
    },
    responsibility() {
      return this.responsibilityLabel?.name;
    },
  },
  methods: {
    disabled(header) {
      const disabled = header.disabled;
      if (typeof disabled === "function") {
        return disabled({ items: [this.contact] });
      }
      return disabled;
    },
    openMenu(params) {
      this.$emit("menu:click", {
        contact: this.contact,
        ...params,
      });
    },
  },
};
</script>
