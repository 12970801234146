export * from "./actionRequest";
export * from "./billing";
export * from "./router";
export * from "./client";
export * from "./date";
export * from "./domain";
export * from "./eSign";
export * from "./formTemplate";
export * from "./integration";
export * from "./office";
export * from "./storage";
export * from "./ui";
export * from "./dataTableModels";
export * from "./widget";
export * from "./formSignSession";
export * from "./status";
export * from "./dataTable";
export * from "./draggable";
export * from "./label";
export * from "./chart";
export * from "./moduleItem";
export * from "./calendar";
export * from "./cdn";
export * from "./loginMethod";
export * from "./menuActions";
export * from "./drawer";
export * from "./itemOrigin";
export * from "./role";
export * from "./shortcuts";
export * from "./timeEntry";

export const CONTACT_EMAIL = "hjelp@gripr.no";

export const MODULE_CONTROL_NAMES_TO_SHOW = [
  "LOOM_RECORDING",
  "HISTORICAL_LOG",
  "HSE",
  "QA",
  "COMMENT",
  "TIME_ENTRY",
  "TIME_ENTRY_ADVANCED",
  "PROJECT_SPECIFIC_HOUR_TYPES",
  "CORPORATE_MANAGEMENT",
];
