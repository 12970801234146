import { normalizeProjectMembers } from "@/helpers/normalization";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  members: [],
  isLoading: false,
  filter: null,
});

const getters = {};

const actions = {
  async getMembers({ state, commit }, { id, filter }) {
    commit("loading", true);
    commit("setFilter", filter);

    return axios
      .get(`${apiUrl}project/${id}/members`, {
        params: { ...state.filter },
      })
      .then(({ data }) => {
        const members = data?.members;
        const normalizedMembers = normalizeProjectMembers(members);

        commit("setMembers", normalizedMembers);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
};

const mutations = {
  setMembers(state, members) {
    state.members = members;
  },
  setFilter(state, filter) {
    state.filter = filter;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const projectMembers = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
