<template>
  <div class="w-100">
    <!-- Mobile view -->
    <v-data-table
      v-if="$vuetify.breakpoint.xsOnly"
      class="border-a"
      hide-default-footer
      :headers="tableHeaders"
      :items="localTableData"
      disable-sort
      mobile-breakpoint="600"
    />

    <!-- Desktop view -->
    <AppDataTable
      v-else
      :headers="tableHeaders"
      :items="localTableData"
      disable-pagination
      height="100%"
      :class="{ 'preview-mode': readonly }"
      mobile-breakpoint="0"
    >
      <template v-slot:item="{ item, headers }">
        <tr :class="['list_tr-height', { list_tr: !readonly }]">
          <AppDefaultTableCell
            v-for="(header, index) in headers"
            :key="header.value"
            :header="header"
            :item="item"
            :index="index"
          >
            <template v-if="header.value === 'menu' && !readonly">
              <v-btn
                icon
                color="error"
                @click="deleteRow(item)"
              >
                <v-icon>{{ $icons.LIGHT.ACTION.DELETE }}</v-icon>
              </v-btn>
            </template>
            <template v-else>
              <pre class="cell-content-pre">{{ item[header.value] || "" }}</pre>
            </template>
          </AppDefaultTableCell>
        </tr>
      </template>
    </AppDataTable>

    <template v-if="!readonly">
      <v-btn
        color="primary"
        text
        class="mt-2"
        @click="showAddDialog"
      >
        <v-icon left>{{ $icons.REGULAR.ACTION.ADD }}</v-icon>
        {{ $t("formManagement.common.addTableRow") }}
      </v-btn>

      <AppFormCompletionAddTableRowDialog
        v-model="addDialog"
        :fields="item.values"
        @submit="onDialogSubmit"
      />

      <!-- Cell Edit Menu -->
      <v-menu
        v-model="menu.show"
        :position-x="menu.x"
        :position-y="menu.y"
        absolute
        offset-y
        :closeOnContentClick="false"
        contentClass="pa-1 ui-background--background"
        :transition="false"
      >
        <div>
          <div class="text-caption pb-1">{{ menu.columnName }}</div>
          <AppDefaultTextarea
            v-model="menu.editValue"
            @keydown.enter="handleEnterPress"
            @blur="saveCell"
            hide-details
            autofocus
            class="edit-textarea"
            auto-grow
          ></AppDefaultTextarea>
        </div>
      </v-menu>
    </template>
  </div>
</template>

<script>
import { createTableHeaders } from "@/helpers/util";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localTableData: [],
      menu: {
        show: false,
        x: 0,
        y: 0,
        editValue: "",
        rowId: null,
        columnKey: null,
        columnName: "",
      },
      addDialog: false,
    };
  },
  computed: {
    tableHeaders() {
      const headers = this.item.values.map((header) => ({
        text: header.text,
        value: header.key,
        sortable: !this.readonly,
        disabled: this.readonly,
        click: this.readonly ? undefined : (event) => this.handleCellClick(header, event),
      }));

      headers.push({ preset: "MENU", access: !this.readonly });

      return createTableHeaders(headers);
    },
  },
  watch: {
    "item.value": {
      immediate: true,
      handler(newValue) {
        if (!newValue) {
          this.localTableData = [];
          return;
        }
        const dataWithIds = newValue.map((row) => ({
          ...row,
          _id: row._id || crypto.randomUUID(),
        }));
        this.localTableData = dataWithIds;
      },
    },
  },
  methods: {
    handleEnterPress(event) {
      if (event.shiftKey) return;
      event.preventDefault();
      this.saveCell();
    },
    saveCell() {
      if (!this.menu.rowId || !this.menu.columnKey) return;

      const index = this.localTableData.findIndex((row) => row._id === this.menu.rowId);
      if (index === -1) return;

      const currentValue = this.localTableData[index][this.menu.columnKey];
      if (currentValue === this.menu.editValue) {
        this.menu.show = false;
        return;
      }

      const newData = [...this.localTableData];
      newData[index] = {
        ...newData[index],
        [this.menu.columnKey]: this.menu.editValue,
      };

      this.localTableData = newData;
      this.menu.show = false;
      this.updateTable();
    },
    showAddDialog() {
      this.addDialog = true;
    },
    onDialogSubmit(formData) {
      const newRow = {
        _id: crypto.randomUUID(),
        ...formData,
      };
      this.localTableData = [...this.localTableData, newRow];
      this.updateTable();
    },
    deleteRow(row) {
      const index = this.localTableData.findIndex((r) => r._id === row._id);
      if (index > -1) {
        const newData = [...this.localTableData];
        newData.splice(index, 1);
        this.localTableData = newData;
        this.updateTable();
      }
    },
    updateTable() {
      this.$emit("item:update", {
        item: this.item,
        body: { value: this.localTableData },
      });
    },
    handleCellClick({ key, text }, { from, items }) {
      if (!from || !items || !items[0]) return;
      const rect = from.getBoundingClientRect();
      const item = items[0];

      // Close the menu first to reset its state
      // Use nextTick to ensure the menu is fully closed before reopening
      this.menu = {
        ...this.menu,
        show: true,
        x: rect.x,
        y: rect.y,
        editValue: item[key] || "",
        rowId: item._id,
        columnKey: key,
        columnName: text,
      };

      this.$nextTick(() => {
        setTimeout(() => {
          const textarea = document.querySelector(".edit-textarea textarea");
          if (textarea) {
            textarea.dispatchEvent(new Event("input"));
          }
        }, 50); // Small delay to ensure DOM is fully rendered
      });

      this.$nextTick(() => {
        setTimeout(() => {
          const textarea = document.querySelector(".edit-textarea textarea");
          if (textarea) {
            textarea.dispatchEvent(new Event("input"));
          }
        }, 200); // Small delay to ensure DOM is fully rendered
      });
    },
  },
};
</script>

<style scoped>
.cell-content-pre {
  white-space: pre-wrap;
  margin: 0;
  font-family: inherit;
  padding: 8px 0; /* horizontal padding only */
  width: max-content;
  max-width: 250px !important;
}

.edit-textarea {
  width: 290px;
}
</style>
