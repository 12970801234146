import { permissionHelpers } from "../util";

export const permissionMixins = {
  //core computeds for accessing root objects
  //For deeply nested objects, make computed properties for each component,
  //that use these helpers
  computed: {
    permissionMixins_billing() {
      return this._getPermission("billing");
    },
    permissionMixins_client() {
      return this._getPermission("client");
    },
    permissionMixins_contact() {
      return this._getPermission("contact");
    },
    permissionMixins_project() {
      return this._getPermission("project");
    },
    permissionMixins_comment() {
      return this._getPermission("comment");
    },
    permissionMixins_historicalLog() {
      return this._getPermission("historicalLog");
    },
    permissionMixins_formTemplate() {
      return this._getPermission("formTemplate");
    },
    permissionMixins_userLabel() {
      return this._getPermission("userLabel");
    },
    permissionMixins_corporateManagement() {
      return this._getPermission("corporateManagement");
    },
    permissionMixins_status() {
      return this._getPermission("status");
    },
    permissionMixins_storage() {
      return this._getPermission("storage");
    },
    permissionMixins_form() {
      return this._getPermission("form");
    },
    permissionMixins_integration() {
      return this._getPermission("integration");
    },
    permissionMixins_projectTemplate() {
      return this._getPermission("projectTemplate");
    },
    permissionMixins_documentTemplate() {
      return this._getPermission("documentTemplate");
    },
    permissionMixins_user() {
      return this._getPermission("user");
    },
    permissionMixins_dashboard() {
      return this._getPermission("dashboard");
    },
    permissionMixins_domainSecurity() {
      return this._getPermission("domainSecurity");
    },
    permissionMixins_product() {
      return this._getPermission("product");
    },
    permissionMixins_productUsage() {
      return this._getPermission("productUsage");
    },
    permissionMixins_task() {
      return this._getPermission("task");
    },
    permissionMixins_timeEntry() {
      return this._getPermission("timeEntry");
    },
    permissionMixins_special() {
      //Unique permissions that may apply across multiple resource types and dont fit elsewhere.
      //These might need to be used in unique ways
      return this._getPermission("special");
    },
    permissionMixins_canCreateOrEditResourceWithoutProject() {
      //If user has permission to create or edit a resource without assigning it to a project (Guests)
      return this.permissionMixins_special?.editOrCreateResourceWithoutProject.can;
    },
  },
  //Helper methods for checking permissions on items
  methods: {
    //for use in this component
    _getPermission(path, idParam) {
      return permissionHelpers.getPermissionObject({
        path,
        idParam,
      });
    },
    permissionMixins_canEditItems({ items }) {
      return items.every((item) => item?.permissions?.edit?.can);
    },
    permissionMixins_canDeleteItems({ items }) {
      return items.every((item) => item?.permissions?.delete?.can);
    },
  },
};
