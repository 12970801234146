<script>
import { VIcon } from "vuetify/lib";

export default {
  props: {
    values: Array,
  },
  render(h) {
    return h("div", { class: "d-flex border-b" }, [
      h("div", { class: "w-100 py-2 pr-2" }, [
        h(
          "div",
          {
            class: "d-flex align-center flex-wrap gap-2",
          },
          [
            ...(this.values || []).map((header) =>
              h(
                "div",
                {
                  class: "d-flex align-center background-inherit",
                  style: "width: calc(33.33% - 8px); border-radius: 4px;",
                },
                [
                  h(
                    "div",
                    {
                      class: "d-flex align-center pl-2 mr-3",
                    },
                    [
                      h(VIcon, {
                        props: { small: true },
                        domProps: { textContent: this.$icons.SOLID.ACTION.TABLE },
                      }),
                    ],
                  ),
                  h(
                    "div",
                    {
                      class: "border-a rounded pa-3",
                      style: "flex: 1",
                    },
                    header.text,
                  ),
                ],
              ),
            ),
          ],
        ),
      ]),
    ]);
  },
};
</script>
