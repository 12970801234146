import store from "@/store";
import { ROUTE_NAMES } from "@/helpers/routeNames";
import { capitalize } from "@/helpers/filters";
import i18n from "@/plugins/i18n";

let unwatch;

const DEFAULT_TITLE = "Gripr";
const CMS = i18n.t("drawer.header.cms.title");
const CONTACTS = i18n.t("drawer.route.contacts");
const TASKS = i18n.t("drawer.route.tasks");
const TASK = i18n.t("common.task");
const DASHBOARD = i18n.t("drawer.route.dashboard");
const EMPLOYEES = i18n.t("drawer.route.employees");
const FORMS = i18n.t("drawer.route.forms");
const FORM = i18n.t("common.form");
const PROJECTS = i18n.t("drawer.route.projects");
const PROJECT = i18n.t("common.project");
const SETTINGS = i18n.t("drawer.route.settings");
const STORAGE = i18n.t("drawer.route.files");
const FORM_TEMPLATES = i18n.t("drawer.route.formTemplates");
const FORM_TEMPLATE = i18n.t("common.formTemplate");
const PROJECT_TEMPLATES = i18n.t("drawer.route.projectTemplates");
const PROJECT_TEMPLATE = i18n.t("common.projectTemplate");
const TIME_REGISTRATION = i18n.t("drawer.route.timeRegistration");
const HOME = i18n.t("drawer.route.home");

const getCmsTitle = () => {
  return CMS;
};
const getContactTitle = () => {
  return CONTACTS;
};
const getDashboardTitle = () => {
  return DASHBOARD;
};
const getHomeTitle = () => {
  return HOME;
};
const getEmployeeTitle = () => {
  return EMPLOYEES;
};
const getFormTitle = (form) => {
  return getTitle({ modulePlural: FORMS, moduleSingular: FORM, itemText: form?.name });
};
const getProjectsTitle = () => {
  return PROJECTS;
};
const getProjectTitle = (project) => {
  return getTitle({
    modulePlural: PROJECTS,
    moduleSingular: PROJECT,
    itemText: project?.name,
  });
};
const getSettingsTitle = () => {
  return SETTINGS;
};
const getStorageTitle = (storage) => {
  return getTitle({
    modulePlural: STORAGE,
    moduleSingular: STORAGE,
    itemText: storage?.name,
  });
};
const getTaskTitle = (task) => {
  return getTitle({
    modulePlural: TASKS,
    moduleSingular: TASK,
    itemText: task?.name,
  });
};

const getFormTemplatesTitle = () => {
  return FORM_TEMPLATES;
};

const getProjectTemplatesTitle = () => {
  return PROJECT_TEMPLATES;
};

const getFormTemplateTitle = (formTemplate) => {
  return getTitle({
    moduleSingular: FORM_TEMPLATE,
    itemText: formTemplate?.name,
  });
};

const getProjectTemplateTitle = (projectTemplate) => {
  return getTitle({
    moduleSingular: PROJECT_TEMPLATE,
    itemText: projectTemplate?.name,
  });
};

const getTimeRegistrationTitle = () => {
  return TIME_REGISTRATION;
};

const getTaskViewItem = ({ state }) => {
  return state?.taskView?.task;
};
const getTaskListItem = ({ state, params }) => {
  return state?.task?.tasks?.find((task) => task?.id === params?.taskId);
};

const getFormViewItem = ({ state }) => {
  return state?.form?.form;
};
const getFormListItem = ({ state, params }) => {
  return state?.forms?.forms?.find((form) => form?.id === params?.formId);
};
const getProjectViewItem = ({ state }) => {
  return state?.project?.project;
};

const getFormTemplateViewItem = ({ state }) => {
  return state?.formTemplate?.template;
};
const getProjectTemplateViewItem = ({ state }) => {
  return state?.projectTemplate?.template;
};
const getStorageViewItem = ({ state }) => {
  return state.storagePreview.storage;
};

const routeTitleConfig = [
  {
    routeNames: [ROUTE_NAMES.CMS.ROOT],
    getTitle: getCmsTitle,
  },
  {
    routeNames: [ROUTE_NAMES.CONTACT.ROOT],
    getTitle: getContactTitle,
  },
  {
    routeNames: [ROUTE_NAMES.HOME.ROOT],
    getTitle: getHomeTitle,
  },
  {
    routeNames: [ROUTE_NAMES.DASHBOARD.ROOT],
    getTitle: getDashboardTitle,
  },
  {
    routeNames: [ROUTE_NAMES.TASK_VIEW.ROOT],
    getTitle: getTaskTitle,
    getItem: getTaskViewItem,
  },
  {
    routeNames: [ROUTE_NAMES.EMPLOYEE.ROOT],
    getTitle: getEmployeeTitle,
  },
  {
    routeNames: [ROUTE_NAMES.FORM.ROOT],
    getTitle: getFormTitle,
    getItem: getFormListItem,
  },
  {
    routeNames: [ROUTE_NAMES.FORM_VIEW.ROOT],
    getTitle: getFormTitle,
    getItem: getFormViewItem,
  },
  {
    routeNames: [ROUTE_NAMES.PROJECT.ROOT],
    getTitle: getProjectsTitle,
  },
  {
    routeNames: [ROUTE_NAMES.PROJECT.VIEW],
    getTitle: getProjectTitle,
    getItem: getProjectViewItem,
  },
  {
    routeNames: [ROUTE_NAMES.SETTINGS.ROOT],
    getTitle: getSettingsTitle,
  },
  {
    routeNames: [ROUTE_NAMES.STORAGE.ROOT],
    getTitle: getStorageTitle,
    getItem: getStorageViewItem,
  },
  {
    routeNames: [ROUTE_NAMES.TASK.ROOT],
    getTitle: getTaskTitle,
    getItem: getTaskListItem,
  },
  {
    routeNames: [ROUTE_NAMES.TEMPLATE.FORM_TEMPLATES],
    getTitle: getFormTemplatesTitle,
  },
  {
    routeNames: [ROUTE_NAMES.TEMPLATE.PROJECT_TEMPLATES],
    getTitle: getProjectTemplatesTitle,
  },
  {
    routeNames: [ROUTE_NAMES.TEMPLATE.FORM_TEMPLATE_BUILDER],
    getTitle: getFormTemplateTitle,
    getItem: getFormTemplateViewItem,
  },
  {
    routeNames: [ROUTE_NAMES.TEMPLATE.PROJECT_TEMPLATE_BUILDER],
    getTitle: getProjectTemplateTitle,
    getItem: getProjectTemplateViewItem,
  },
  {
    routeNames: [ROUTE_NAMES.TIME_REGISTRATION.ROOT],
    getTitle: getTimeRegistrationTitle,
  },
];

export function setupDocumentTitleWatcher(router) {
  //could use before each to update asap but it has issue with entering a project when there is already one in the store
  router.afterEach(handleRouteChange);
}

const handleRouteChange = (to, from) => {
  let titleSet = false;

  for (const config of routeTitleConfig) {
    if (matchRoute(config, to)) {
      const newUnwatch = watchRouteTitle(config, to);
      setUnwatch(newUnwatch);
      titleSet = true;
      break;
    }
  }

  if (!titleSet) {
    unwatch?.();
    setDocumentTitle(DEFAULT_TITLE);
  }
};

const matchRoute = (config, to) => {
  return config.routeNames.some((routeName) => isUnderRoute({ route: to, routeName }));
};

const watchRouteTitle = (config, to) => {
  if (!config.getItem) {
    setDocumentTitle(config.getTitle());
    return;
  }

  const watchFunction = () =>
    config.getItem({
      state: store.state,
      params: to.params,
      query: to.query,
    });

  return store.watch(
    watchFunction,
    (newItem) => {
      setDocumentTitle(config.getTitle(newItem));
    },
    { immediate: true, deep: true },
  );
};

const setDocumentTitle = (title) => {
  document.title = title || DEFAULT_TITLE;
};

const setUnwatch = (newUnwatch) => {
  if (unwatch) {
    unwatch();
  }
  unwatch = newUnwatch;
};

const isUnderRoute = ({ route, routeName }) => {
  return route.matched.some((m) => m.name === routeName);
};

const getTitle = ({ moduleSingular, modulePlural, itemText } = {}) => {
  let title;
  if (itemText && moduleSingular) {
    title = `${capitalize(moduleSingular)} - ${itemText}`;
  } else {
    title = capitalize(modulePlural || moduleSingular);
  }

  return title;
};
