<template>
  <div>
    <AppDeleteConfirmationDialog
      ref="deleteConfirmation"
      v-model="dialog.deleteForm.active"
      :item="dialog.deleteForm.item"
      :title="
        $tc('formManagement.deleteConfirmation.title', dialog?.deleteForm?.item?.length)
      "
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('fileManagement.fileExplorer.deleteConfirmation.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="formsDelete"
    />
    <AppDataTableServerSidePagination2
      ref="dataTable"
      :loading="isLoading"
      :defaultHeaders="defaultHeaders"
      :items="forms"
      :tableModel="tableModel"
      :serverItemsLength="serverItemsLength"
      :noDataText="$t('common.noForms')"
      :getAllItems="getAllItems"
      :actionConfigs="actionConfigs"
      removeMutationType="forms/removeForm"
      updateMutationType="forms/updateForm"
      :statsText="statsText"
      :isStatsLoading="isStatsLoading"
    >
      <template
        v-slot:[`group.header`]="{
          toggle,
          group,
          isOpen,
          headers,
          isGroupIndeterminate,
          isGroupSelected,
          toggleGroup,
        }"
      >
        <td
          @click.stop="toggleGroup"
          class="table-cell--hover-effect"
          v-ripple
        >
          <div class="d-flex align-center justify-center h-100">
            <AppDefaultCheckboxIcon
              :isIndeterminate="isGroupIndeterminate"
              :isSelected="isGroupSelected"
            />
          </div>
        </td>
        <td :colspan="headers.length - 6">
          <div class="d-flex align-center">
            <v-btn
              class="mr-4"
              icon
              @click.stop="toggle"
            >
              <v-icon>
                {{
                  isOpen
                    ? $icons.LIGHT.COMMON.CHEVRON_DOWN
                    : $icons.LIGHT.COMMON.CHEVRON_UP
                }}
              </v-icon>
            </v-btn>
            <div class="group-header__title">
              {{ headerTitle(group) }}
            </div>
          </div>
        </td>
        <td :colspan="5"></td>
      </template>
      <template v-slot:item="{ item, headers, select, isSelected }">
        <AppFormTableItem
          :form="item"
          :selectedFormId="formId"
          :headers="headers"
          :tableModel="tableModel"
          :select="select"
          :isSelected="isSelected"
          :isContextMenuActiveItem="isContextMenuActiveItem(item)"
          :isActiveItem="isActiveItem(item)"
          @menu:click="openContextMenu"
        />
      </template>
    </AppDataTableServerSidePagination2>
    <AppDataTableContextMenu
      ref="contextMenu"
      :actionConfigs="actionConfigs"
      :items="[contextMenuActiveItem]"
      @input="onContextMenuChange"
    />
    <AppCustomStatusPickerMenu
      ref="statusMenu"
      @submit="onFormsUpdate"
      model="form"
    />
    <AppUserPickerMenu
      ref="responsibleMenu"
      valueKey="owner"
      @submit="(id) => onFormsUpdate({ ownerId: id })"
    />
    <AppProjectPickerMenu
      ref="projectMenu"
      @submit="onFormsUpdate"
    />
    <AppDatePickerMenu
      ref="dueDateMenu"
      @submit="({ date }) => onFormsUpdate({ dueDate: date })"
    />
    <AppTaskPickerMenu
      ref="taskMenu"
      @submit="onFormsUpdate"
      :queryParams="{ projectId: $route?.params?.projectId }"
      :data="{ projectId: $route?.params?.projectId }"
    />
    <AppFormCategoryPickerMenu
      ref="categoryMenu"
      @submit="onFormsUpdate"
    />
    <AppIsActivePickerMenu
      ref="isSignableMenu"
      @submit="({ isActive }) => onFormsUpdate({ isSignable: isActive })"
    />
    <AppAccessLevelPickerMenu
      ref="isPrivateMenu"
      :allowedLevels="['PUBLIC', 'PRIVATE']"
      @submit="
        ({ permissionLevel }) =>
          onFormsUpdate({ isPrivate: permissionLevel === 'PRIVATE' })
      "
    />
    <AppCopyItemsDialog
      v-model="dialog.copyDialog.active"
      :inItems="dialog.copyDialog.items"
      type="FORM"
      @submit="onCopy"
    />
  </div>
</template>

<script>
import { permissionMixins } from "@/helpers/mixins";
import { groupByMixins2 } from "@/helpers/mixins/groupByMixins2";
import { dataFieldsMixin } from "@/helpers/mixins/dataFieldsMixin";
import { createTableHeaders } from "@/helpers/util";
import { getAllForms } from "@/services/form/allForms";

export default {
  mixins: [permissionMixins, groupByMixins2, dataFieldsMixin],

  props: {
    forms: Array,
    serverItemsLength: Number,
    isLoading: Boolean,
    formId: String,
    tableModel: String,
    statsText: String,
    isStatsLoading: Boolean,
  },
  data() {
    return {
      contextMenuActiveItem: null,
      dialog: {
        deleteForm: {
          active: false,
          item: null,
        },
        copyDialog: {
          active: false,
          items: null,
        },
      },
      isContextMenuOpen: false,
      contextMenuActiveItem: null,
      itemsToEdit: null,
    };
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    actions() {
      const {
        FILL_OUT_FORM,
        OVERVIEW,
        COPY,
        SEND_TO_SIGN,
        SIGNATURE_STATUS,
        COPY_URL,
        DELETE,
        EDIT_STATUS,
        EDIT_RESPONSIBLE,
        EDIT_DUE_DATE,
        EDIT_TASK,
        EDIT_PROJECT,
        EDIT_CATEGORY,
        EDIT_IS_SIGNABLE,
        EDIT_PERMISSION_LEVEL,
      } = this.$constant.ACTION_KEYS;

      const openMenu = (menuRef) => (e) => this.openEditMenu({ menuRef, ...e });
      const canNotEdit = ({ items }) => !this.canEditForms({ items });
      const canNotDelete = ({ items }) => !this.canDeleteForms({ items });

      return {
        [FILL_OUT_FORM]: {
          click: this.onFormFillOutClick,
        },
        [OVERVIEW]: {
          click: this.onFormDetailsClick,
        },
        [COPY]: {
          click: this.onCopyClick,
          disabled: !this.canCreateForms,
        },
        [SEND_TO_SIGN]: {
          click: this.onSendToSignClick,
        },
        [SIGNATURE_STATUS]: {
          click: this.onSignatureClick,
        },
        [EDIT_STATUS]: {
          click: openMenu("statusMenu"),
          disabled: canNotEdit,
        },
        [COPY_URL]: {
          click: this.onCopyUrl,
        },
        [EDIT_RESPONSIBLE]: {
          click: openMenu("responsibleMenu"),
          disabled: canNotEdit,
        },
        [EDIT_DUE_DATE]: {
          click: openMenu("dueDateMenu"),
          disabled: canNotEdit,
        },
        [EDIT_TASK]: {
          click: openMenu("taskMenu"),
          disabled: canNotEdit,
        },
        [EDIT_PROJECT]: {
          click: openMenu("projectMenu"),
          disabled: canNotEdit,
        },
        [EDIT_CATEGORY]: {
          click: openMenu("categoryMenu"),
          disabled: canNotEdit,
        },
        [EDIT_IS_SIGNABLE]: {
          click: openMenu("isSignableMenu"),
          disabled: canNotEdit,
        },
        [EDIT_PERMISSION_LEVEL]: {
          click: openMenu("isPrivateMenu"),
          disabled: canNotEdit,
        },
        [DELETE]: {
          click: this.onFormDelete,
          disabled: canNotDelete,
        },
      };
    },
    actionConfigs() {
      const {
        FILL_OUT_FORM,
        OVERVIEW,
        COPY,
        SEND_TO_SIGN,
        COPY_URL,
        DELETE,
        EDIT_STATUS,
        EDIT_RESPONSIBLE,
        EDIT_DUE_DATE,
        EDIT_TASK,
        EDIT_PROJECT,
        EDIT_CATEGORY,
        EDIT_IS_SIGNABLE,
        EDIT_PERMISSION_LEVEL,
      } = this.$constant.ACTION_KEYS;

      const withMenuContext = (clickHandler) => (e) => {
        clickHandler({
          direction: e.hasSubMenu ? "right" : "top",
          parentMenuRef: e.hasSubMenu ? this.$refs.contextMenu : null,
          ...e,
        });
      };

      const actions = this.actions;
      const customActions = [
        {
          key: FILL_OUT_FORM,
          on: {
            click: actions[FILL_OUT_FORM].click,
          },
        },
        {
          key: OVERVIEW,
          on: {
            click: actions[OVERVIEW].click,
          },
        },
        {
          key: COPY,
          on: {
            click: actions[COPY].click,
          },
          props: {
            disabled: actions[COPY].disabled,
          },
        },
        {
          key: SEND_TO_SIGN,
          props: {
            labelKeyParams: { isSent: this.isSentToSign },
          },
          on: {
            click: actions[SEND_TO_SIGN].click,
          },
        },
        {
          key: EDIT_STATUS,
          on: {
            click: withMenuContext(actions[EDIT_STATUS].click),
          },
          props: {
            disabled: actions[EDIT_STATUS].disabled,
          },
        },
        {
          key: COPY_URL,
          on: {
            click: this.onCopyUrl,
          },
        },
        {
          key: EDIT_RESPONSIBLE,
          on: {
            click: withMenuContext(actions[EDIT_RESPONSIBLE].click),
          },
          props: {
            disabled: actions[EDIT_RESPONSIBLE].disabled,
          },
        },
        {
          key: EDIT_DUE_DATE,
          on: {
            click: withMenuContext(actions[EDIT_DUE_DATE].click),
          },
          props: {
            disabled: actions[EDIT_DUE_DATE].disabled,
          },
        },
        {
          key: EDIT_TASK,
          on: {
            click: withMenuContext(actions[EDIT_TASK].click),
          },
          props: {
            disabled: actions[EDIT_TASK].disabled,
          },
        },
        {
          key: EDIT_PROJECT,
          on: {
            click: withMenuContext(actions[EDIT_PROJECT].click),
          },
          props: {
            disabled: actions[EDIT_PROJECT].disabled,
          },
        },
        {
          key: EDIT_CATEGORY,
          on: {
            click: withMenuContext(actions[EDIT_CATEGORY].click),
          },
          props: {
            disabled: actions[EDIT_CATEGORY].disabled,
          },
        },
        {
          key: EDIT_IS_SIGNABLE,
          on: {
            click: withMenuContext(actions[EDIT_IS_SIGNABLE].click),
          },
          props: {
            disabled: actions[EDIT_IS_SIGNABLE].disabled,
          },
        },
        {
          key: EDIT_PERMISSION_LEVEL,
          on: {
            click: withMenuContext(actions[EDIT_PERMISSION_LEVEL].click),
          },
          props: {
            disabled: actions[EDIT_PERMISSION_LEVEL].disabled,
          },
        },
        {
          key: DELETE,
          props: {
            disabled: actions[DELETE].disabled,
          },
          on: {
            click: actions[DELETE].click,
          },
          divider: true,
        },
      ];

      const config = this.$constant.generateActionConfig({
        primaryActions: customActions,
        secondaryActions: this.$constant.FORM_ACTIONS,
      });

      return config;
    },
    isSentToSign() {
      return !!this.contextMenuActiveItem?.signatureSession;
    },
    defaultHeaders() {
      const {
        EDIT_STATUS,
        FILL_OUT_FORM,
        SIGNATURE_STATUS,
        EDIT_RESPONSIBLE,
        EDIT_PROJECT,
        EDIT_TASK,
        EDIT_CATEGORY,
        EDIT_DUE_DATE,
      } = this.$constant.ACTION_KEYS;

      return createTableHeaders([
        {
          preset: "SELECT",
        },
        {
          preset: "STATUS",
          value: this.dataFields.STATUS,
          ...this.actions[EDIT_STATUS],
        },
        {
          preset: "NAME",
          text: this.$t("formManagement.list.formName"),
          value: this.dataFields.NAME,
          ...this.actions[FILL_OUT_FORM],
        },
        {
          text: this.$t("formManagement.list.signatureStatus"),
          value: this.dataFields.SIGNATURE_STATUS,
          ...this.actions[SIGNATURE_STATUS],
        },
        {
          text: this.$t("common.progress"),
          value: this.dataFields.PROGRESS,
          disabled: true,
        },
        {
          text: this.$t("formManagement.list.responsible"),
          value: this.dataFields.OWNER,
          ...this.actions[EDIT_RESPONSIBLE],
        },
        {
          text: this.$t("common.project"),
          value: this.dataFields.PROJECT,
          access: !this.projectId,
          ...this.actions[EDIT_PROJECT],
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("formManagement.list.belongsToTask"),
          ),
          value: this.dataFields.TASK,
          ...this.actions[EDIT_TASK],
        },
        {
          text: this.$t("common.category"),
          value: this.dataFields.CATEGORY,
          ...this.actions[EDIT_CATEGORY],
        },
        {
          preset: "DATE",
          text: this.$t("taskManagement.list.dueDate"),
          value: this.dataFields.DUE_DATE,
          ...this.actions[EDIT_DUE_DATE],
        },
        {
          preset: "MENU",
          value: this.dataFields.MENU,
        },
      ]);
    },
  },
  methods: {
    openEditHeadersDialog() {
      this.$refs.dataTable.openEditHeadersDialog();
    },
    openEditMenu(e) {
      const { items, menuRef } = e;
      this.$refs[menuRef].open(e);
      this.itemsToEdit = items;
    },
    async getAllItems() {
      return await getAllForms();
    },
    canEditForms({ items }) {
      return this.permissionMixins_canEditItems({ items });
    },
    canDeleteForms({ items }) {
      return this.permissionMixins_canDeleteItems({ items });
    },
    canCreateForms() {
      return this.permissionMixins_form.create.can;
    },
    onCopyUrl({ items }) {
      const form = items[0];
      const formUrl = this.$router.resolve({
        name: this.$routeNames.FORM_VIEW.ROOT,
        params: { formId: form?.id },
      }).href;

      const fullPath = `${window.location.origin}${formUrl}`;

      navigator.clipboard.writeText(fullPath);

      this.$store.dispatch("snackbar/snackbar", {
        show: true,
        text: this.$t("common.copied"),
        color: "success",
      });
    },
    isContextMenuActiveItem(item) {
      return this.contextMenuActiveItem === item;
    },
    isActiveItem(item) {
      return item.id === this.formId;
    },
    onCopyClick({ items }) {
      this.dialog.copyDialog.active = true;
      this.dialog.copyDialog.items = items;
    },
    onCopy(e) {
      this.$emit("forms:copy", { forms: e });
    },
    onSignatureClick({ items }) {
      const form = items[0];
      this.$emit("signature:click", { form });
    },
    onSendToSignClick({ items }) {
      const form = items[0];
      this.$emit("form:sendToSign", { form });
    },
    headerTitle(group) {
      const commonHeaderTitle = this.groupByMixins2_commonHeaderTitle(group);
      if (commonHeaderTitle) return commonHeaderTitle;

      if (this.groupByMixins2_isGroupSelected(this.dataFields.CATEGORY)) {
        return this.translatedCategory(group);
      }

      return group;
    },
    onStatusChange({ statusId }) {
      const body = { statusId };

      this.onFormUpdate({ body, form: this.itemsToEdit[0] });
    },
    onStatusClick(e) {
      const { items } = e;
      this.$refs.statusMenu.open(e);
      this.itemsToEdit = items;
    },
    translatedCategory(key) {
      return this.$te(`formManagement.category.${key}`)
        ? this.$t(`formManagement.category.${key}`)
        : key;
    },
    openContextMenu({ from, direction, form }) {
      this.$refs.contextMenu.open({ direction, from });
      this.$nextTick(() => {
        this.contextMenuActiveItem = form;
      });
    },
    onContextMenuChange(isOpen) {
      this.isContextMenuOpen = isOpen;
      if (!isOpen) {
        this.contextMenuActiveItem = null;
      }
    },
    onFormDetailsClick({ items }) {
      const form = items[0];
      this.$emit("form:details", { item: form });
    },
    onFormFillOutClick({ items }) {
      const form = items[0];
      this.contextMenuActiveItem = form;
      this.$emit("form:fillOut", { item: form });
    },
    onFormUpdate(e) {
      this.$emit("form:update", e);
    },
    onFormsUpdate(value) {
      const body = { ...value, ids: this.itemsToEdit.map((item) => item.id) };
      this.$emit("forms:update", { body });
    },
    onFormDelete({ items }) {
      this.dialog.deleteForm.active = true;
      this.dialog.deleteForm.item = items;
    },
    formsDelete({ item }) {
      this.$emit("forms:delete", { forms: item });
    },
  },
};
</script>
