import axios from "axios";
import { formatDataTableOptionsToQuery } from "@/helpers/util";
import { shouldUpdateItems } from "@/helpers/util";
const apiUrl = process.env.VUE_APP_API_URL;
const CancelToken = axios.CancelToken;
let getProjectsCancel;

const state = () => ({
  projects: [],
  count: null,
  isLoading: false,
  isUpdating: false,
  filter: null,
});

const getters = {};

const actions = {
  async getProjects({ commit }, { filters, dataTableOptions }) {
    if (getProjectsCancel) getProjectsCancel();
    commit("loading", true);
    const optionsQuery = formatDataTableOptionsToQuery(dataTableOptions);

    const params = {
      ...optionsQuery,
      ...filters,
    };

    return axios
      .get(`${apiUrl}project`, {
        params,
        cancelToken: new CancelToken(function executor(c) {
          getProjectsCancel = c;
        }),
      })
      .then(({ data }) => {
        const { projects, count } = data;

        const shouldUpdate = shouldUpdateItems(
          count,
          dataTableOptions?.page,
          dataTableOptions?.itemsPerPage,
        );

        if (shouldUpdate) {
          commit("setProjects", projects);
        }
        commit("setCount", count);
        commit("loading", false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          commit("loading", false);
          throw new Error(error);
        }
      });
  },
  async createProject({ commit, state }, { body }) {
    return axios
      .post(`${apiUrl}project`, body)
      .then(({ data }) => {
        const { project } = data;
        commit("addProject", project);
        commit("setCount", state.count + 1);
        return project;
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.message || "Something went wrong...";
        throw new Error(errorMessage);
      });
  },
  async updateProject({ commit }, { id, body }) {
    commit("setIsUpdating", true);

    if (typeof body?.statusId === "string") {
      commit("toggleUpdatingStatus", {
        id,
        isUpdatingStatus: true,
      });
    }

    return axios
      .put(`${apiUrl}project/${id}`, body)
      .then(({ data }) => {
        const { project } = data;
        if (project) {
          commit("updateProject", project);
        } else {
          commit("removeProject", id);
        }
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.message || "Something went wrong...";
        throw new Error(errorMessage);
      })
      .finally(() => {
        commit("setIsUpdating", false);
        commit("toggleUpdatingStatus", {
          id,
          isUpdatingStatus: false,
        });
      });
  },
  async updateProjects({ commit }, { body }) {
    commit("setIsUpdating", true);
    commit("loading", true);

    if (body.statusId) {
      for (const id of body.ids) {
        commit("toggleUpdatingStatus", {
          id,
          isUpdatingStatus: true,
        });
      }
    }

    return axios
      .put(`${apiUrl}project`, body)
      .then(({ data }) => {
        const { projects } = data;
        for (const project of projects) {
          commit("updateProject", project);
        }
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.message || "Something went wrong...";
        throw new Error(errorMessage);
      })
      .finally(() => {
        commit("setIsUpdating", false);
        commit("loading", false);
        for (const id of body.ids) {
          commit("toggleUpdatingStatus", {
            id,
            isUpdatingStatus: false,
          });
        }
      });
  },
  async deleteProject({ commit, state }, id) {
    return axios
      .delete(`${apiUrl}project/${id}`)
      .then(async ({ data }) => {
        const project = data?.project;
        if (project) {
          commit("removeProject", project.id);
          commit("setCount", state.count - 1);
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async deleteProjects({ commit, state }, { ids }) {
    const config = { data: { ids } };
    commit("loading", true);

    return axios
      .delete(`${apiUrl}project`, config)
      .then(async ({ data }) => {
        const projects = data?.projects;
        if (projects) {
          for (const project of projects) {
            commit("removeProject", project.id);
          }
          commit("setCount", state.count - projects.length);
        }
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", false);
      });
  },
  resetState({ commit }) {
    commit("setProjects", []);
  },
};

const mutations = {
  setProjects(state, projects) {
    state.projects = projects;
  },
  setCount(state, count) {
    state.count = count;
  },
  addProject(state, project) {
    state.projects.unshift(project);
  },
  updateProject(state, project) {
    const index = state.projects.findIndex((x) => x.id === project.id);
    if (index !== -1) {
      state.projects.splice(index, 1, project);
    }
  },
  toggleUpdatingStatus(state, { id, isUpdatingStatus }) {
    if (!id) throw new Error("No project");
    const index = state.projects.findIndex((project) => project.id === id);
    if (index !== -1) {
      if (state.projects[index]) {
        state.projects.splice(index, 1, { ...state.projects[index], isUpdatingStatus });
      }
    }
  },
  removeProject(state, projectId) {
    const index = state.projects.findIndex((x) => x.id === projectId);
    if (index !== -1) {
      state.projects.splice(index, 1);
    }
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setIsUpdating(state, isUpdating) {
    state.isUpdating = isUpdating;
  },
};

export const projects = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
