import {
  RESOURCE_ROUTE_PERMISSIONS,
  ROUTE_NAMES,
  ROUTE_PERMISSIONS,
} from "@/helpers/routeNames";
import multiguard from "../multiguard";
import { authGuard, userGuard } from "@/auth";
import { HELP_CATEGORIES } from "@/helpers/constants/help";

const { ROOT, LIST } = ROUTE_NAMES.FORM;

export const formRoutes = [
  {
    path: "/form",
    name: ROOT,
    meta: {
      permission: {
        isRedirectRoute: true,
      },
      helpCategory: HELP_CATEGORIES.FORM,
    },
    beforeEnter: multiguard([authGuard, userGuard]),
    component: () =>
      import(/* webpackChunkName: "form" */ "../../pages/FormManagement/Forms"),
    children: [
      {
        path: "list",
        name: LIST.ROOT,
        meta: { permission: ROUTE_PERMISSIONS.FORM },
        component: () =>
          import(
            /* webpackChunkName: "form" */ "../../pages/FormManagement/Forms/FormList"
          ),
        children: [
          {
            path: "menu/:formId",
            name: LIST.MENU.ROOT,
            meta: { permission: RESOURCE_ROUTE_PERMISSIONS.FORM },
            component: () =>
              import(
                /* webpackChunkName: "form" */ "../../pages/FormManagement/Forms/MenuPage"
              ),
            children: [
              {
                path: "details",
                name: LIST.MENU.DETAILS,
                meta: { permission: RESOURCE_ROUTE_PERMISSIONS.FORM },
                component: () =>
                  import(
                    /* webpackChunkName: "form" */ "../../pages/FormManagement/MenuPages/DetailsPage"
                  ),
              },
              {
                path: "comments",
                name: LIST.MENU.COMMENTS,
                meta: { permission: RESOURCE_ROUTE_PERMISSIONS.FORM.COMMENT },
                component: () =>
                  import(
                    /* webpackChunkName: "form" */ "../../pages/FormManagement/MenuPages/CommentsPage"
                  ),
              },
              {
                path: "storage/:folderId",
                name: LIST.MENU.STORAGE,
                meta: { permission: RESOURCE_ROUTE_PERMISSIONS.FORM },
                component: () =>
                  import(
                    /* webpackChunkName: "form" */ "../../pages/FormManagement/MenuPages/StoragePage"
                  ),
              },
              {
                path: "signature",
                name: LIST.MENU.SIGNATURE,
                meta: { permission: RESOURCE_ROUTE_PERMISSIONS.FORM },
                component: () =>
                  import(
                    /* webpackChunkName: "form" */ "../../pages/FormManagement/MenuPages/SignaturePage"
                  ),
              },
              {
                path: "history",
                name: LIST.MENU.HISTORY,
                meta: { permission: RESOURCE_ROUTE_PERMISSIONS.FORM },
                component: () =>
                  import(
                    /* webpackChunkName: "form" */ "../../pages/FormManagement/MenuPages/HistoryPage"
                  ),
              },
            ],
          },
        ],
      },
    ],
  },
];
