<template>
  <div class="youtube-player-wrapper rounded-lg overflow-hidden">
    <div
      :id="playerId"
      ref="playerContainer"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    videoId: {
      type: String,
      required: true,
    },
    autoplay: Boolean,
    width: {
      type: Number,
      default: 640,
    },
    height: {
      type: Number,
      default: 360,
    },
    controls: {
      type: Number,
      default: 1,
    },
  },

  data: () => ({
    player: null,
    playerId: `youtube-player-${Math.random().toString(36).substr(2, 9)}`,
    apiLoaded: false,
  }),

  mounted() {
    this.initializeYouTubeAPI();
  },

  beforeDestroy() {
    if (this.player) {
      this.player.destroy();
    }
  },

  methods: {
    initializeYouTubeAPI() {
      if (!window.YT) {
        if (!window.onYouTubeIframeAPIReady) {
          const tag = document.createElement("script");
          tag.src = "https://www.youtube.com/iframe_api";
          const firstScriptTag = document.getElementsByTagName("script")[0];
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

          window.onYouTubeIframeAPIReady = () => {
            this.apiLoaded = true;
            this.createPlayer();
          };
        }
        // Wait for API to load if another instance is already loading it
        const checkAPI = setInterval(() => {
          if (window.YT && window.YT.Player) {
            clearInterval(checkAPI);
            this.apiLoaded = true;
            this.createPlayer();
          }
        }, 100);
      } else {
        this.apiLoaded = true;
        this.createPlayer();
      }
    },
    createPlayer() {
      if (!this.apiLoaded || !window.YT || !window.YT.Player) return;

      this.player = new YT.Player(this.playerId, {
        height: this.height,
        width: this.width,
        videoId: this.videoId,
        playerVars: {
          autoplay: this.autoplay ? 1 : 0,
          controls: this.controls,
          origin: window.location.origin,
          rel: 0,
        },
        events: {
          onReady: this.onPlayerReady,
          onStateChange: this.onPlayerStateChange,
          onError: this.onPlayerError,
          onPlaybackQualityChange: this.onPlaybackQualityChange,
        },
      });
    },

    onPlayerStateChange({ data }) {
      this.$emit("stateChange", data);
    },

    onPlayerError(error) {
      this.$emit("error", error);
    },

    onPlayerReady(event) {
      this.$emit("ready", event);
    },

    onPlaybackQualityChange(event) {
      this.$emit("qualityChange", event.data);
    },

    play() {
      if (this.player) this.player.playVideo();
    },

    pause() {
      if (this.player) this.player.pauseVideo();
    },

    stop() {
      if (this.player) this.player.stopVideo();
    },

    seekTo(seconds, allowSeekAhead = true) {
      if (this.player) this.player.seekTo(seconds, allowSeekAhead);
    },
  },
};
</script>

<style scoped>
.youtube-player-wrapper :deep(iframe) {
  display: block;
}
</style>
