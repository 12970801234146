<template>
  <AppFiltersDrawer
    v-if="localShowDrawer"
    @close="closeFilters"
    :tableModel="tableModel"
    :children="children"
  />
</template>

<script>
import { mapGetters } from "vuex";
import { getSearchUsers } from "@/services/search/users";
import { filtersItemsMixins } from "@/helpers/mixins";
import { filtersMixins } from "@/helpers/mixins";
import { filtersWatchMixins } from "@/helpers/mixins";

export default {
  mixins: [filtersItemsMixins, filtersMixins, filtersWatchMixins],
  props: {
    showDrawer: Boolean,
    tableModel: String,
  },
  model: {
    prop: "showDrawer",
    event: "change",
  },
  data() {
    return {
      creators: [],
      isUsersLoading: false,
    };
  },
  methods: {
    getData() {
      this.getUsers();
    },
    closeFilters() {
      this.localShowDrawer = false;
    },
    async getUsers() {
      this.isUsersLoading = true;
      const params = {
        alwaysIncludeIds: this.creatorIds,
      };
      getSearchUsers({ params })
        .then((users) => {
          this.creators = users;
        })
        .finally(() => {
          this.isUsersLoading = false;
        });
    },
  },
  computed: {
    localShowDrawer: {
      get() {
        return this.showDrawer;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    ...mapGetters("activeIntegrations", {
      hasContactImportIntegrationEnabled: "hasContactImportIntegrationEnabled",
    }),
    creatorIds() {
      return this.filtersMixins_filterByKey("creatorIds") ?? [];
    },
    children() {
      const children = [
        {
          is: "AppFiltersSelect",
          labelKey: "filters.contactFilters.active",
          filterValueKey: "isActive",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_isActiveItems,
        },
        {
          is: "AppFiltersClientGroupedUserAutocomplete",
          labelKey: "filters.contactFilters.creator",
          filterValueKey: "creatorIds",
          itemValue: "id",
          itemText: "name",
          items: this.creators ?? null,
          loading: this.isUsersLoading,
          userAvatar: true,
        },
        {
          is: "AppFiltersClientMultiAutocomplete",
          labelKey: "filters.contactFilters.client",
          filterValueKey: "clientIds",
          itemValue: "id",
          itemText: "name",
          isReadyToInitialFetch: this.isReadyToInitialFetch,
          queryParams: {
            alwaysIncludeIds: this.filtersPropertyMixins_clientIds,
          },
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.contactFilters.hasClient",
          filterValueKey: "hasClient",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_yesOrNoItems,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.contactFilters.hasEmail",
          filterValueKey: "hasEmail",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_yesOrNoItems,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.contactFilters.hasPhone",
          filterValueKey: "hasPhone",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_yesOrNoItems,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.contactFilters.hasRole",
          filterValueKey: "hasRole",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_yesOrNoItems,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.contactFilters.createdDate",
          itemValue: "value",
          itemText: "text",
          filterValueKey: "createdDate",
          items: this.filtersItemsMixins_dateItems,
        },
        ...(this.hasContactImportIntegrationEnabled
          ? [
              {
                is: "AppFiltersSelect",
                labelKey: "filters.contactFilters.isSynchronized",
                filterValueKey: "isSynchronized",
                itemValue: "value",
                itemText: "text",
                items: this.filtersItemsMixins_yesOrNoItems,
              },
            ]
          : []),
      ];

      return children;
    },
  },
};
</script>
