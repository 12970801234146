import axios from "axios";
const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

const state = () => ({
  availableCases: [],
  insuranceCase: null,
  isLoadingCases: false,
  isLoading: false,
  isConnecting: false,
  isDisconnecting: false,
  hasError: false,
});

const getters = {
  hasInsuranceCase: (state) => !!state.insuranceCase,
};

const actions = {
  async getAvailableCases({ commit }) {
    commit("error", false);
    commit("loadingCases", true);
    
    return axios
      .get(`${apiUrl}in4mo/insurance-case`)
      .then(({ data }) => {
        commit("setAvailableCases", data.result);
        return data;
      })
      .catch((error) => {
        commit("error", true);
        throw new Error(error);
      })
      .finally(() => {
        commit("loadingCases", false);
      });
  },
  
  async getProjectInsuranceCase({ commit }, projectId) {
    if (!projectId) return;
    
    commit("error", false);
    commit("loading", true);
    
    return axios
      .get(`${apiUrl}in4mo/insurance-case/${projectId}`)
      .then(({ data }) => {
        const insuranceCase = data.result || data;
        commit("setInsuranceCase", insuranceCase);
        return insuranceCase;
      })
      .catch((error) => {
        commit("error", true);
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", false);
      });
  },
  
  async connectInsuranceCase({ commit }, { projectId, caseId }) {
    commit("error", false);
    commit("connecting", true);
    
    return axios
      .post(`${apiUrl}in4mo/insurance-case`, { projectId, caseId })
      .then(({ data }) => {
        commit("setInsuranceCase", data);
        return data;
      })
      .catch((error) => {
        commit("error", true);
        throw new Error(error);
      })
      .finally(() => {
        commit("connecting", false);
      });
  },
  
  async disconnectInsuranceCase({ commit }, { projectId }) {
    commit("error", false);
    commit("disconnecting", true);
    
    return axios
      .delete(`${apiUrl}in4mo/insurance-case`, {
        data: { projectId }
      })
      .then(({ data }) => {
        commit("setInsuranceCase", null);
        return data;
      })
      .catch((error) => {
        commit("error", true);
        throw new Error(error);
      })
      .finally(() => {
        commit("disconnecting", false);
      });
  },
  
  resetState({ commit }) {
    commit("setAvailableCases", []);
    commit("setInsuranceCase", null);
    commit("error", false);
    commit("loading", false);
    commit("connecting", false);
    commit("disconnecting", false);
  },
};

const mutations = {
  setAvailableCases(state, cases) {
    state.availableCases = cases;
  },
  setInsuranceCase(state, insuranceCase) {
    state.insuranceCase = insuranceCase;
  },
  loadingCases(state, isLoadingCases) {
    state.isLoadingCases = isLoadingCases;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  connecting(state, isConnecting) {
    state.isConnecting = isConnecting;
  },
  disconnecting(state, isDisconnecting) {
    state.isDisconnecting = isDisconnecting;
  },
  error(state, hasError) {
    state.hasError = hasError;
  },
};

export const in4mo = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}; 