<template>
  <v-dialog
    v-model="localValue"
    max-width="800px"
    scrollable
  >
    <v-card>
      <v-card-title class="d-flex justify-space-between align-center">
        <div>{{ $t("settings.billing.admin.title") }}</div>
        <v-btn
          icon
          @click="close"
        >
          <v-icon>{{ $icons.LIGHT.ACTION.CLOSE }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle>{{ $t("settings.billing.admin.subTitle") }}</v-card-subtitle>

      <v-card-text class="pb-4">
        <div v-if="!isLoading && billingDetails">
          <v-row>
            <v-col
              cols="6"
              class="pb-4"
            >
              <AdminSettingsCard
                :title="$t('settings.billing.admin.overridePrice.title')"
                :subTitle="$t('settings.billing.admin.overridePrice.subTitle')"
              >
                <template v-slot:actions>
                  <v-row>
                    <v-col cols="6">
                      <AppCurrencyTextField
                        :value="monthlySubscriptionPrice"
                        @blur="updatePricePerLicense"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </template>
              </AdminSettingsCard>
            </v-col>
            <v-col
              cols="6"
              class="pb-4"
            >
              <AdminSettingsCard
                :title="$t('settings.billing.admin.startDate.title')"
                :subTitle="$t('settings.billing.admin.startDate.subTitle')"
              >
                <template v-slot:actions>
                  <v-row>
                    <v-col cols="6">
                      <AppFieldMenuDatePicker
                        :date="activePlanSubscription.startDate"
                        @date:change="updateStartDate"
                        :clearable="false"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </template>
              </AdminSettingsCard>
            </v-col>
            <v-col
              cols="6"
              class="pb-4"
            >
              <AdminSettingsCard
                :title="$t('settings.billing.admin.dueIn.title')"
                :subTitle="$t('settings.billing.admin.dueIn.subTitle')"
              >
                <template v-slot:actions>
                  <v-row>
                    <v-col cols="6">
                      <AppDefaultTextField
                        :value="invoicesDueIn"
                        @change="updateInvoicesDueIn"
                        type="number"
                        hide-details
                      >
                        <template v-slot:append>
                          <span>{{ $t("common.days").toUpperCase() }}</span>
                        </template>
                      </AppDefaultTextField>
                    </v-col>
                  </v-row>
                </template>
              </AdminSettingsCard>
            </v-col>
            <v-col
              cols="6"
              class="pb-4"
            >
              <AdminSettingsCard
                :title="$t('settings.billing.admin.platformFee.title')"
                :subTitle="$t('settings.billing.admin.platformFee.subTitle')"
              >
                <template v-slot:title-append>
                  <AppDefaultSwitch
                    class="pa-0 ma-0"
                    v-model.sync="platformFeeIsActive"
                    :loading="isLoadingFeeSubscriptionIsActive"
                  />
                </template>
              </AdminSettingsCard>
            </v-col>
            <v-col
              cols="6"
              class="pb-4"
            >
              <AdminSettingsCard
                :title="$t('settings.billing.admin.trial.title')"
                :subTitle="$t('settings.billing.admin.trial.subTitle')"
              >
                <template v-slot:actions>
                  <v-row>
                    <v-col cols="6">
                      <AppFieldMenuDatePicker
                        :date="activePlanSubscription.endDate"
                        @date:change="updateEndDate"
                        :clearable="false"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </template>
              </AdminSettingsCard>
            </v-col>
            <v-col
              cols="6"
              class="pb-4"
            >
              <AdminSettingsCard
                :title="$t('settings.billing.admin.partner.title')"
                :subTitle="$t('settings.billing.admin.partner.subTitle')"
              >
                <template v-slot:title-append>
                  <AppDefaultSwitch
                    class="pa-0 ma-0"
                    v-model.sync="isPartner"
                  />
                </template>
              </AdminSettingsCard>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-skeleton-loader
            class="mb-6 rounded-lg"
            height="165"
            type="image"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AdminSettingsCard from "./AdminSettingsCard.vue";
import axios from "axios";

export default {
  components: { AdminSettingsCard },
  props: {
    value: Boolean,
    domain: Object,
  },
  data() {
    return {
      isLoading: false,
      billingDetails: null,
      isLoadingFeeSubscriptionIsActive: false,
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    activePlanSubscription() {
      return (
        this.billingDetails?.subscriptions?.find(
          (sub) => sub.plan.code === this.billingDetails?.activePlanCode,
        ) || { startDate: null, endDate: null, price: 0, interval: 1 }
      );
    },
    platformFeeSubscription() {
      return (
        this.billingDetails?.subscriptions?.find(
          (sub) => sub.plan.code === "PLATFORM_FEE",
        ) || { isActive: false }
      );
    },
    invoicesDueIn() {
      return this.billingDetails?.invoicesDueIn || 0;
    },
    platformFeeIsActive: {
      get() {
        return this.platformFeeSubscription?.isActive || false;
      },
      set(value) {
        this.updatePlatformFeeSubscription({ isActive: value });
      },
    },
    isPartner: {
      get() {
        return this.billingDetails?.isPartner || false;
      },
      set(value) {
        this.updateIsPartner(value);
      },
    },
    monthlySubscriptionPrice() {
      const interval = this.activePlanSubscription.interval || 1;
      const monthlyPrice = this.activePlanSubscription.price / interval;
      return monthlyPrice;
    },
  },
  methods: {
    close() {
      this.localValue = false;
    },
    async getBillingDetails() {
      if (!this.domain?.id) return;

      this.isLoading = true;
      const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

      return axios
        .get(`${apiUrl}billing/details`, {
          headers: {
            domain: this.domain.id,
          },
        })
        .then(({ data }) => {
          this.billingDetails = data.billingDetails;
        })
        .catch((error) => {
          throw new Error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    updatePricePerLicense(customPrice) {
      customPrice = parseInt(customPrice);
      if (isNaN(customPrice)) {
        customPrice = this.activePlanSubscription.price;
      }
      if (customPrice === this.activePlanSubscription.price) {
        return;
      }
      if (customPrice < 0) {
        customPrice = 0;
      }
      this.updateMainSubscription({ customPrice });
    },
    updateStartDate(startDate) {
      if (startDate === this.activePlanSubscription.startDate) {
        return;
      }
      this.updateMainSubscription({ startDate });
    },
    async updateEndDate(endDate) {
      const isTrial = this.activePlanSubscription.plan.code === "GRIPR_TRIAL";
      if (!isTrial) {
        return;
      }

      const isSameEndDate = endDate === this.activePlanSubscription.endDate;
      if (isSameEndDate) {
        return;
      }

      await this.updateMainSubscription({ endDate });
    },
    updateInvoicesDueIn(invoicesDueIn) {
      invoicesDueIn = parseInt(invoicesDueIn);
      if (isNaN(invoicesDueIn)) {
        invoicesDueIn = this.invoicesDueIn;
      }
      if (invoicesDueIn === this.invoicesDueIn) {
        return;
      }
      if (invoicesDueIn < 1) {
        invoicesDueIn = 1;
      }
      this.updateBillingAccount({ invoicesDueIn });
    },
    updateIsPartner(isPartner) {
      this.updateBillingAccount({ isPartner });
    },
    async updateMainSubscription(body) {
      if (!this.domain?.id) return;

      const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

      return axios
        .put(`${apiUrl}billing-admin/main-subscription`, body, {
          headers: {
            domain: this.domain.id,
          },
        })
        .then(({ data }) => {
          this.billingDetails = data.billingDetails;
          return data;
        })
        .catch((error) => {
          throw new Error(error);
        });
    },
    async updatePlatformFeeSubscription(body) {
      if (!this.domain?.id) return;

      this.isLoadingFeeSubscriptionIsActive = true;
      const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

      return axios
        .put(`${apiUrl}billing-admin/platform-fee-subscription`, body, {
          headers: {
            domain: this.domain.id,
          },
        })
        .then(({ data }) => {
          this.billingDetails = data.billingDetails;
          return data;
        })
        .catch((error) => {
          throw new Error(error);
        })
        .finally(() => {
          this.isLoadingFeeSubscriptionIsActive = false;
        });
    },
    async updateBillingAccount(body) {
      if (!this.domain?.id) return;

      const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

      return axios
        .put(`${apiUrl}billing-admin/account`, body, {
          headers: {
            domain: this.domain.id,
          },
        })
        .then(({ data }) => {
          this.billingDetails = data.billingDetails;
          return data;
        })
        .catch((error) => {
          throw new Error(error);
        });
    },
  },
  watch: {
    value(newVal) {
      if (newVal) {
        this.getBillingDetails();
      }
    },
    domain() {
      if (this.value) {
        this.getBillingDetails();
      }
    },
  },
};
</script>
