<template>
  <v-card
    flat
    class="border-a pa-6 pt-5 h-100 d-flex flex-column"
  >
    <div class="pb-1 d-flex align-center">
      <div class="text-h6 font-weight-bold">
        {{ title }}
      </div>
      <v-spacer />
      <slot name="title-append"></slot>
    </div>

    <div
      class="pb-3"
      v-html="subTitle"
    ></div>

    <v-spacer />

    <div>
      <slot name="actions"></slot>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    title: String,
    subTitle: String,
  },
};
</script>
