import _ from "lodash";

const STORE_PERMISSION_CONFIGS = {
  auth: {
    setUser: {
      resource: "me",
      getEntity: (payload) => payload?.user,
      getPermissions: (payload) => payload?.userInDomain?.permissions,
    },
  },
  project: {
    setProject: {
      resource: "project",
      getEntity: (payload) => payload,
    },
    updateProject: {
      resource: "project",
      getEntity: (payload) => payload,
    },
  },
  projects: {
    setProjects: {
      resource: "project",
      getEntities: (payload) => payload,
    },
    addProject: {
      resource: "project",
      getEntity: (payload) => payload,
    },
    updateProject: {
      resource: "project",
      getEntity: (payload) => payload,
    },
  },
  //this is the one for multiple tasks, but the naming is outdated
  task: {
    setTasks: {
      resource: "task",
      getEntities: (payload) => payload,
    },
    updateTask: {
      resource: "task",
      getEntity: (payload) => payload,
    },
    addTask: {
      resource: "task",
      getEntity: (payload) => payload,
    },
    addOrUpdateTask: {
      resource: "task",
      getEntity: (payload) => payload,
    },
  },
  taskView: {
    setTask: {
      resource: "task",
      getEntity: (payload) => payload,
    },
  },
  formTask: {
    setTask: {
      resource: "task",
      getEntity: (payload) => payload,
    },
    updateTask: {
      resource: "task",
      getEntity: (payload) => payload,
    },
  },
  form: {
    setForm: {
      resource: "form",
      getEntity: (payload) => payload,
    },
    updateForm: {
      resource: "form",
      getEntity: (payload) => payload,
    },
  },
  forms: {
    setForms: {
      resource: "form",
      getEntities: (payload) => payload,
    },
    updateForm: {
      resource: "form",
      getEntity: (payload) => payload,
    },
  },
  taskForms: {
    setForms: {
      resource: "form",
      getEntities: (payload) => payload,
    },
    addForm: {
      resource: "form",
      getEntity: (payload) => payload,
    },
    updateForm: {
      resource: "form",
      getEntity: (payload) => payload.form,
    },
  },
  timeEntries: {
    setTimeEntries: {
      resource: "timeEntry",
      getEntities: (payload) => payload,
    },
    addTimeEntry: {
      resource: "timeEntry",
      getEntity: (payload) => payload,
    },
    addTimeEntryUnderParent: {
      resource: "timeEntry",
      getEntity: (payload) => payload.timeEntry,
    },
    updateTimeEntry: {
      resource: "timeEntry",
      getEntity: (payload) => payload,
    },
  },
  products: {
    setProducts: {
      resource: "productUsage",
      getEntities: (payload) => payload?.productUsages,
    },
    updateProduct: {
      resource: "productUsage",
      getEntity: (payload) => payload,
    },
    addProduct: {
      resource: "productUsage",
      getEntity: (payload) => payload,
    },
  },
  productsInfinityList: {
    setProducts: {
      resource: "productUsage",
      getEntities: (payload) => payload,
    },
    pushProducts: {
      resource: "productUsage",
      getEntities: (payload) => payload,
    },
  },
};

const addEntityPermissions = (store, resource, entity) => {
  store.commit("permissions/setResourcePermissions", {
    resource,
    id: entity.id,
    permissions: entity.permissions,
  });
};

const addEntitiesPermissions = (store, resource, entities) => {
  entities.forEach((entity) => {
    store.commit("permissions/setResourcePermissions", {
      resource,
      id: entity.id,
      permissions: entity.permissions,
    });
  });
};

export const createPermissionExtractor = (store) => {
  store.subscribe((mutation, state) => {
    const [moduleName, mutationName] = mutation.type.split("/");
    const config = STORE_PERMISSION_CONFIGS[moduleName]?.[mutationName];

    if (config) {
      if (config.getEntities) {
        const entities = config.getEntities(mutation.payload);
        if (entities) {
          addEntitiesPermissions(store, config.resource, entities);
        }
      } else if (config.getEntity) {
        const entity = config.getEntity(mutation.payload);
        const permissions =
          config.getPermissions?.(mutation.payload) ?? entity?.permissions;
        if (entity && permissions) {
          addEntityPermissions(store, config.resource, { ...entity, permissions });
        }
      }
    }
  });
};
