import _ from "lodash";
import { mapState } from "vuex";

export const filtersWatchMixins2 = {
  //this mixin required showDrawer and getData
  //It is used for fetching data when filters is opened
  //and filtering down tasks for forms when projectIds change
  data() {
    return {
      //for autocomplete/select components that handle their own fetching
      isReadyToInitialFetch: false,
    };
  },
  computed: {
    routeParamProjectId() {
      return this.$route.params.projectId;
    },
    ...mapState("resourceViews", {
      isLoadingResourceViews: "isLoading",
      scopedFilters: "scopedFilters",
    }),
  },
  watch: {
    //logic to fetch task and form when active project changes
    routeParamProjectId: {
      handler(newVal, oldVal) {
        if (this.showDrawer && newVal !== oldVal) {
          this.getForms?.();
          this.getTasks?.();
        }
      },
    },
    //logic to fetch data initially
    showDrawer: {
      handler(val) {
        if (val && !this.isLoadingResourceViews) {
          this.getData?.();
          this.isReadyToInitialFetch = true;
        }
      },
      immediate: true,
    },
    isLoadingResourceViews: {
      handler(val) {
        if (!val && this.showDrawer) {
          this.getData?.();
          this.isReadyToInitialFetch = true;
        }
      },
      immediate: true,
    },
    //Logic to fetch task and form when project updates, or when task or form updates
    [`scopedFilters`]: {
      handler(newVal, oldVal) {
        if (_.isEqual(newVal, oldVal)) return;

        const isProjectIdsChangedAndIsNotInitialFetch =
          oldVal !== null &&
          oldVal !== undefined &&
          newVal?.projectIds !== oldVal?.projectIds;

        const isFormIdsChangedAndIsNotInitial =
          oldVal !== null &&
          oldVal !== undefined &&
          newVal?.belongsToFormIds !== oldVal?.belongsToFormIds;

        if (isProjectIdsChangedAndIsNotInitialFetch) {
          this.getForms?.();
        }

        if (isFormIdsChangedAndIsNotInitial) {
          this.getForms?.();
        }
      },
    },
  },
};
