import { REGULAR } from "../icons";
import { ROUTE_NAMES } from "../routeNames";

export const VALID_LANDING_PAGE_ROUTE_NAMES_AND_ICONS = [
  {
    name: ROUTE_NAMES.HOME.ROOT,
    icon: REGULAR.ROUTE.HOME,
    weight: 1,
  },
  {
    name: ROUTE_NAMES.DASHBOARD.ROOT,
    icon: REGULAR.ROUTE.DASHBOARD,
    weight: 2,
  },
  {
    name: ROUTE_NAMES.TASK.ROOT,
    icon: REGULAR.ROUTE.TASK,
    weight: 4,
  },
  {
    name: ROUTE_NAMES.FORM.ROOT,
    icon: REGULAR.ROUTE.FORM,
    weight: 5,
  },
  {
    name: ROUTE_NAMES.TIME_REGISTRATION.ROOT,
    icon: REGULAR.ROUTE.TIME_REGISTRATION,
  },
  {
    name: ROUTE_NAMES.PROJECT.ROOT,
    icon: REGULAR.ROUTE.PROJECT,
    weight: 3,
  },
  {
    name: ROUTE_NAMES.STORAGE.ROOT,
    icon: REGULAR.ROUTE.STORAGE,
  },
  {
    name: ROUTE_NAMES.CONTACT.ROOT,
    icon: REGULAR.ROUTE.CONTACT,
  },
  {
    name: ROUTE_NAMES.EMPLOYEE.ROOT,
    icon: REGULAR.ROUTE.USER,
  },
];
