import { ROUTE_NAMES } from "@/helpers/routeNames";
import { authGuard } from "@/auth";

const { ROOT } = ROUTE_NAMES.CHILD_DOMAIN_INVITATION;

export const childDomainInvitationRoutes = [
  {
    path: "/child-workspace-invitation/:token",
    name: ROOT,
    component: () =>
      import(
        /* webpackChunkName: "childDomainInvitation" */ "../../pages/ChildDomainInvitationPage"
      ),
    beforeEnter: authGuard,
  },
];
