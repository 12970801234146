import { MODULE_CONTROL_NAMES_TO_SHOW } from "@/helpers/constants";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  items: [],
  isLoading: false,
});

const getters = {
  modules: (state) => {
    return state.items.filter((i) => i.type === "MODULE");
  },
  extensions: (state) => {
    return state.items.filter((i) => i.type === "EXTENSION");
  },
};

const actions = {
  getModuleControls({ commit }) {
    commit("loading", true);
    return axios
      .get(`${apiUrl}module-control`)
      .then((res) => {
        const features = res?.data?.features;
        let items = [];

        if (features) {
          items = getFeatures(features);
        }

        commit("setItems", items);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
  updateModuleControl({ commit, dispatch }, item) {
    commit("toggleItemLoading", { item, isLoading: true });
    return axios
      .put(`${apiUrl}module-control`, {
        name: item.name,
        field: item.field,
        value: item.value,
      })
      .then((res) => {
        const feature = res?.data?.feature;
        commit("toggleItemLoading", { item, isLoading: false });
        commit("updateItem", feature);
        dispatch("auth/getMe", null, { root: true });
      })
      .catch((error) => {
        commit("toggleItemLoading", { item, isLoading: false });
        throw new Error(error);
      });
  },
};

const mutations = {
  setItems(state, items) {
    state.items = items;
  },
  updateItem(state, item) {
    if (!item) throw new Error("No item");
    const index = state.items.findIndex((x) => x.name === item.name);
    if (index !== -1) {
      const newItem = getFeatures([item])[0];
      if (newItem) {
        state.items[index].isEnabled = newItem.isEnabled;
        state.items[index].status = newItem.status;
      }
    }
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  toggleItemLoading(state, payload = { item: null, isLoading: false }) {
    if (!payload.item) throw new Error("No module");
    const index = state.items.findIndex((x) => x.name === payload.item?.name);
    if (index !== -1) {
      state.items[index].isLoading = payload.isLoading;
    }
  },
};

export const moduleControl = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

function getFeatures(features) {
  return features
    .filter((feature) =>
      MODULE_CONTROL_NAMES_TO_SHOW.find(
        (featureToShowName) => featureToShowName === feature.name,
      ),
    )
    .map((item) => ({
      ...item,
      isEnabled: item.status === "ENABLED" ? true : false,
      isLoading: false,
    }));
}
