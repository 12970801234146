import { mapGetters } from "vuex";
import { storageMixins } from "./storageMixins";

export const fileDropHandlerMixins = {
  mixins: [storageMixins],
  computed: {
    ...mapGetters("acceptedFileTypes", {
      isLoadingAcceptedFileTypes: "isLoadingAcceptedFileTypes",
      acceptedFileTypes: "acceptedFileTypes",
    }),
  },
  data() {
    return {
      fileDropHandlerMixins_isDraggingOver: false,
    };
  },

  created() {
    this.fileDropHandlerMixins_getAcceptedFileTypes();
  },

  methods: {
    async fileDropHandlerMixins_getAcceptedFileTypes() {
      await this.$store.dispatch("acceptedFileTypes/getAcceptedFileTypes");
    },
    fileDropHandlerMixins_onDragOver(event) {
      if (!event.dataTransfer?.types?.includes("Files")) return;
      this.fileDropHandlerMixins_isDraggingOver = true;
    },
    fileDropHandlerMixins_onDragLeave() {
      this.fileDropHandlerMixins_isDraggingOver = false;
    },
    fileDropHandlerMixins_onFileDrop({ mimeTypes }) {
      return (event) => {
        this.fileDropHandlerMixins_isDraggingOver = false;
        const files = event.dataTransfer.files;

        if (files.length > 0) {
          const acceptedFiles = [];
          const rejectedFileTypes = [];

          Array.from(files).forEach((file) => {
            if (
              this.fileDropHandlerMixins_isFileTypeAcceptedWithTypes({ file, mimeTypes })
            ) {
              acceptedFiles.push(file);
            } else {
              rejectedFileTypes.push(file.type || "unknown");
            }
          });

          if (acceptedFiles.length > 0) {
            this.$emit("fileDropHandlerMixins_files:dropped", acceptedFiles);
          }

          if (rejectedFileTypes.length > 0) {
            this.fileDropHandlerMixins_showRejectedFilesError(rejectedFileTypes);
          }
        }
      };
    },
    fileDropHandlerMixins_isFileTypeAcceptedWithTypes({ file, mimeTypes }) {
      const fileAccepts = this.fileDropHandlerMixins_fileAccepts({ mimeTypes });

      if (!fileAccepts) return true;
      const fileType = file.type || "";
      const fileExtension = "." + file.name.split(".").pop().toLowerCase();
      return fileAccepts
        .split(",")
        .some((type) => type.trim() === fileType || type.trim() === fileExtension);
    },

    fileDropHandlerMixins_showRejectedFilesError(rejectedTypes) {
      const uniqueRejectedTypes = [...new Set(rejectedTypes)];
      const errorMessage = this.$tc(
        "fileManagement.fileExplorer.uploadRejectionTypes",
        uniqueRejectedTypes.length,
        {
          types: uniqueRejectedTypes
            .map(this.storageMixins_getReadableFileType)
            .join(", "),
        },
      );
      this.$store.dispatch("snackbar/snackbar", {
        show: true,
        text: errorMessage,
        color: "error",
      });
    },
    fileDropHandlerMixins_fileAccepts({ mimeTypes }) {
      // If '*/*' is present, return all accepted file types
      if (mimeTypes?.includes("*/*")) {
        return this.acceptedFileTypes.join(",");
      }

      let filteredMimeTypes = mimeTypes?.flatMap((type) => {
        if (type.includes("/*")) {
          let baseType = type.split("/")[0];
          return this.acceptedFileTypes.filter((acceptedType) =>
            acceptedType.startsWith(baseType + "/"),
          );
        } else {
          return this.acceptedFileTypes.includes(type) ? [type] : [];
        }
      });

      let accepted =
        filteredMimeTypes?.length > 0
          ? filteredMimeTypes.join(",")
          : this.acceptedFileTypes.join(",");

      return accepted;
    },
  },
};
