<template>
  <v-slide-x-transition :hide-on-leave="true">
    <div class="widget_library_drawer primary-box-shadow border-r">
      <v-toolbar
        color="primary"
        dense
        flat
        fixed
        height="68"
      >
        <v-toolbar-title class="ui-background--text">
          {{ $t("widget.library.title") }}
        </v-toolbar-title>
      </v-toolbar>
      <v-divider class="pb-3" />

      <div
        v-if="isLoading"
        style="height: calc(100% - 68px)"
        class="d-flex align-center justify-center"
      >
        <AppCircularLoader />
      </div>
      <div
        v-else
        class="pa-6 pt-0 overflow-y-auto w-100"
        style="height: calc(100% - 68px)"
      >
        <div
          v-for="(widgetLibraryItem, index) in notAddedWidgetTemplates"
          :key="index"
          class="p-relative"
        >
          <component
            v-if="widgetLibraryItem"
            :key="index"
            :is="widgetLibraryItem.component.libraryItem"
            :planCode="widgetLibraryItem.minPlanCode"
            :showStar="!planRestrictionsMixins_canAddWidget(widgetLibraryItem.name)"
            :widget="widgetLibraryItem"
            @add="addWidget(widgetLibraryItem)"
          />
          <div class="pb-3"></div>
        </div>
      </div>
      <AppConfigureChartDialog
        v-model="createChart.dialog"
        @save="onCreateChartSave($event)"
      />
    </div>
  </v-slide-x-transition>
</template>

<script>
import { mapState } from "vuex";
import { planRestrictionsMixins } from "@/helpers/mixins";

export default {
  mixins: [planRestrictionsMixins],
  props: {
    tags: Array,
    model: String,
    modelId: String,
    addedWidgets: Array,
  },
  data() {
    return {
      createChart: {
        props: null,
        dialog: false,
      },
    };
  },
  computed: {
    ...mapState("widgetLibrary", {
      widgetLibrary: (state) => state.widgetLibrary,
      isLoading: (state) => state.isLoading,
    }),
    ...mapState("widgetLayout", {
      widgetLayout: (state) => state.widgetLayout,
    }),
    notAddedWidgetTemplates() {
      return this.widgetLibrary.filter((widgetLibraryItem) => {
        const isMultiAddAllowed = this.$constant.MULTI_ADD_ALLOWED_WIDGET_NAMES.includes(
          widgetLibraryItem.name,
        );
        const isAdded = this.addedWidgets.some(
          (widget) => widget.name === widgetLibraryItem?.name,
        );

        return isMultiAddAllowed || !isAdded;
      });
    },
    getFormTableModelBasedOnWidgetLayoutModel() {
      if (this.widgetLayout.layoutModel === "domain") {
        return this.$constant.FORMS;
      }
      if (this.widgetLayout.layoutModel === "project") {
        return this.$constant.PROJECT_FORMS;
      }
    },
    formListProps() {
      switch (this.widgetLayout.layoutModel) {
        case "domain": {
          return {
            tableModel: this.$constant.FORMS,
            groupBy: this.$constant.TABLE_HEADER[this.$constant.FORMS].CATEGORY,
          };
        }
        case "project": {
          return {
            tableModel: this.$constant.PROJECT_FORMS,
            projectId: this.modelId,
            groupBy: this.$constant.TABLE_HEADER[this.$constant.PROJECT_FORMS].CATEGORY,
          };
        }
      }
    },
    taskListProps() {
      switch (this.widgetLayout.layoutModel) {
        case "domain": {
          return {
            tableModel: this.$constant.TASKS,
            groupBy: this.$constant.TABLE_HEADER[this.$constant.TASKS].STATUS,
          };
        }
        case "project": {
          return {
            tableModel: this.$constant.PROJECT_TASKS,
            projectId: this.modelId,
            groupBy: this.$constant.TABLE_HEADER[this.$constant.PROJECT_TASKS].STATUS,
          };
        }
      }
    },
    eventLogProps() {
      switch (this.widgetLayout.layoutModel) {
        case "project": {
          return {
            itemId: this.modelId,
            models: [
              "project",
              "meta_project",
              "task",
              "form",
              "comment",
              "storage",
              "relation",
              "product_usage",
            ],
          };
        }
      }
    },
    latestImagesProps() {
      switch (this.widgetLayout.layoutModel) {
        case "project": {
          return {
            projectId: this.modelId,
          };
        }
      }
    },
    modelAndId() {
      return {
        model: this.widgetLayout.layoutModel,
        modelId: this.widgetLayout.layoutModelId,
      };
    },
  },
  methods: {
    getProps({ widgetName }) {
      //all props for all widgets are added here as new component require different props
      let props = {};
      switch (widgetName) {
        case "FORM_LIST": {
          props = this.formListProps;
          break;
        }
        case "TASK_LIST": {
          props = this.taskListProps;
          break;
        }
        case "TO_DO_LIST": {
          props = this.modelAndId;
          break;
        }
        case "EVENT_LOG": {
          props = this.eventLogProps;
          break;
        }
        case "LATEST_IMAGES": {
          props = this.latestImagesProps;
        }
        case "CUSTOM_CHART": {
          props = this.createChart.props;
          break;
        }
      }

      return {
        ...props,
      };
    },
    onCreateChartSave(event) {
      this.createChart.props = event;
      const createChartWidgetLibraryItem = this.widgetLibrary.find(
        (widget) => widget.name === this.$constant.CUSTOM_CHART_WIDGET.name,
      );
      this.addWidget(createChartWidgetLibraryItem);
      this.createChart.props = null;
    },
    addWidget(widgetLibraryItem) {
      const isCreateChartAndHasNotSetUp =
        widgetLibraryItem.name === this.$constant.CUSTOM_CHART_WIDGET.name &&
        _.isEmpty(this.createChart.props);

      if (isCreateChartAndHasNotSetUp) {
        this.createChart.dialog = true;
        return;
      }

      const props = this.getProps({ widgetName: widgetLibraryItem?.name });
      const ItemWithProps = { ...widgetLibraryItem, props, hasRequiredRoles: true };
      this.$emit("add", { widgetLibraryItem: ItemWithProps });
    },
  },
};
</script>
