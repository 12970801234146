<template>
  <BaseDocumentTemplatePickerDialog
    v-bind="$props"
    :title="$t(`templateManagement.project.builder.${group}.buttonText`)"
    :templates="documents"
    :isLoading="isLoading"
    :isSaving="false"
    :categories="categories"
    :isCategoriesLoading="isCategoriesLoading"
    v-model="localDialog"
    show-category
    allow-duplicate-templates
    @add-template="onAddDocumentsClick"
    @init="onInit"
    @filter-change="onFilterChange"
  />
</template>

<script>
import BaseDocumentTemplatePickerDialog from "./BaseDocumentTemplatePickerDialog.vue";
import { mapState } from "vuex";

export default {
  components: {
    BaseDocumentTemplatePickerDialog,
  },
  props: {
    dialog: Boolean,
    group: String,
    templateId: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    ...mapState("importedDocuments", {
      documents: (state) => state.documents,
      isLoading: (state) => state.isLoading,
    }),
    ...mapState("templateDocumentGroup", {
      categories: (state) => state.categories,
      isCategoriesLoading: (state) => state.isLoading,
    }),
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  methods: {
    onInit() {
      this.$store.commit("importedDocuments/setDocuments", []);
      this.getCategories();
      this.getDocuments();
    },
    getDocuments(args = {}) {
      this.$store.dispatch("importedDocuments/getDocuments", {
        ...args,
        group: this.group,
        templateId: this.templateId,
      });
    },
    getCategories() {
      this.$store.dispatch("templateDocumentGroup/getCategories", {
        group: this.group,
      });
    },
    onFilterChange(filter) {
      this.getDocuments(filter);
    },
    onAddDocumentsClick(selectedDocuments) {
      this.$emit("documents:selected", selectedDocuments);
      this.localDialog = false;
    },
  },
};
</script>
