<template>
  <span
    :class="[
      'keyboard-shortcut',
      { 'keyboard-shortcut--dark': dark },
      { 'keyboard-shortcut--small': small },
    ]"
  >
    {{ formattedShortcut }}
  </span>
</template>

<script>
import { isMacDevice } from "@/helpers/util";

export default {
  name: "AppKeyboardShortcut",
  props: {
    shortcut: {
      type: Object,
      required: true,
    },
    dark: Boolean,
    small: Boolean,
  },
  computed: {
    formattedShortcut() {
      const { isCtrlKey, key } = this.shortcut;
      if (!isCtrlKey) return this.getLocalizedKeyDisplay(key);

      const modifier = isMacDevice() ? "⌘" : "Ctrl";
      return `${modifier} + ${this.getLocalizedKeyDisplay(key)}`;
    },
  },
  methods: {
    getLocalizedKeyDisplay(key) {
      // Get the user's current locale
      const locale = this.$i18n.locale;

      // Create a keyboard event to get the localized key name
      const event = new KeyboardEvent("keydown", { key });
      return event.key.toLocaleUpperCase(locale);
    },
  },
};
</script>

<style scoped>
.keyboard-shortcut {
  background-color: #f5f5f5;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
  color: #333;
  display: inline-block;
  font-size: 0.85em;
  font-weight: bold;
  padding: 2px 4px;
  white-space: nowrap;
}

.keyboard-shortcut--dark {
  background-color: #333;
  border-color: #555;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  color: #f5f5f5;
}

.keyboard-shortcut--small {
  font-size: 0.75em;
  padding: 1px 3px;
}
</style>
