export const notificationMixins = {
  data() {
    return {
      notificationMixins_iconMap: new Map([
        ["DOMAIN", this.$icons.REGULAR.ROUTE.HOME],
        ["TASK", this.$icons.REGULAR.ROUTE.TASK],
        ["FORM", this.$icons.REGULAR.ROUTE.FORM],
        ["PROJECT", this.$icons.REGULAR.ROUTE.PROJECT],
        ["TEAM", this.$icons.REGULAR.ROUTE.TEAM],
        ["COMMENT", this.$icons.REGULAR.ROUTE.COMMENT],
        ["STORAGE", this.$icons.REGULAR.ROUTE.STORAGE],
        ["TIME_ENTRY", this.$icons.REGULAR.ROUTE.TIME_REGISTRATION],
      ]),
    };
  },
};
