import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  teams: [],
  isLoading: false,
  isCreating: false,
  filter: null,
});

const getters = {};

const actions = {
  async getTeams({ state, commit }, filter) {
    commit("loading", true);
    commit("setFilter", filter);
    return axios
      .get(`${apiUrl}team`, {
        params: { ...state.filter },
      })
      .then(({ data }) => {
        const teams = data?.teams;
        commit("setTeams", teams);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
  async createTeam({ state, dispatch, commit }, body) {
    commit("creating", true);
    return axios
      .post(`${apiUrl}team`, { ...body })
      .then(({ data }) => {
        dispatch("getTeams", state.filter);
        commit("creating", false);
      })
      .catch((error) => {
        commit("creating", false);
        throw new Error(error);
      });
  },
  async updateTeam({ commit }, { id, body }) {
    return axios
      .put(`${apiUrl}team/${id}`, { ...body })
      .then(({ data }) => {
        const team = data?.team;
        if (team) {
          commit("updateTeam", team);
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async deleteTeam({ commit }, id) {
    return axios
      .delete(`${apiUrl}team/${id}`)
      .then(async ({ data }) => {
        const team = data?.team;
        if (team) {
          commit("removeTeam", team);
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
};

const mutations = {
  setTeams(state, teams) {
    state.teams = teams;
  },
  setFilter(state, filter) {
    state.filter = filter;
  },
  updateTeam(state, team) {
    if (!team) throw new Error("No team");
    const index = state.teams.findIndex((x) => x.id === team.id);
    if (index !== -1) {
      state.teams.splice(index, 1, team);
    }
  },
  removeTeam(state, team) {
    if (!team) throw new Error("No team");
    const index = state.teams.findIndex((x) => x.id === team.id);
    if (index !== -1) {
      state.teams.splice(index, 1);
    }
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  creating(state, isCreating) {
    state.isCreating = isCreating;
  },
};

export const teams = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
