import { ROUTE_NAMES } from "@/helpers/routeNames";

export const getOpenInNewTabInfo = (type, id) => {
  const OPEN_IN_NEW_TAB_INFO = {
    mainProject: {
      route: ROUTE_NAMES.PROJECT.VIEW,
      params: { projectId: id },
      text: "common.openInNewTab.mainProject",
    },
    project: {
      route: ROUTE_NAMES.PROJECT.VIEW,
      params: { projectId: id },
      text: "common.openInNewTab.project",
    },
    form: {
      route: ROUTE_NAMES.FORM_VIEW.ROOT,
      params: { formId: id },
      text: "common.openInNewTab.form",
    },
    task: {
      route: ROUTE_NAMES.TASK_VIEW.ROOT,
      params: { taskId: id },
      text: "common.openInNewTab.task",
    },
  };
  return OPEN_IN_NEW_TAB_INFO[type];
};
