/**
 * Filters hour types and structures them with headers
 * @param {Array} hourTypes - Array of hour types to filter
 * @param {Object} options - Filter options
 * @param {boolean|null} options.isExtra - Whether to filter for extra hours (if null, no filtering)
 * @param {string|null} options.projectId - Project ID to filter by
 * @param {boolean} options.showAllGlobal - Whether to show all global hour types regardless of internal/external
 * @returns {Array} Filtered and structured hour types with headers
 */
export function filterHourTypes(hourTypes, { isExtra = null, projectId, showAllGlobal = false }) {
  // Filter regular items (excluding headers)
  const regularItems = hourTypes.filter((item) => !item.header);

  // Filter by extra/non-extra hours if isExtra is not null
  const extraFilteredItems = isExtra === null 
    ? regularItems 
    : regularItems.filter((hourType) => hourType.isExtra === isExtra);

  // Filter internal/external based on context
  const filteredHourTypes = showAllGlobal
    ? extraFilteredItems
    : extraFilteredItems.filter((hourType) => {
        if (projectId) {
          return !hourType.isInternal;
        }
        return hourType.isInternal;
      });
  
  // Group items using simpler structure
  const groups = {
    PROJECT_SPECIFIC: [],
    INTERNAL: [],
    INTERNAL_EXTRA: [],
    EXTERNAL: [],
    EXTERNAL_EXTRA: [],
  };

  // Sort items into groups
  filteredHourTypes.forEach(item => {
    const isProjectSpecific = !item.isInternal && item.projectId === projectId;
    
    if (isProjectSpecific) {
      // Project specific items (cannot be extra)
      groups.PROJECT_SPECIFIC.push(item);
    } else if (item.isInternal) {
      if (item.isExtra) {
        groups.INTERNAL_EXTRA.push(item);
      } else {
        groups.INTERNAL.push(item);
      }
    } else {
      if (item.isExtra) {
        groups.EXTERNAL_EXTRA.push(item);
      } else {
        groups.EXTERNAL.push(item);
      }
    }
  });

  // Create final array with headers
  const items = [];
  const baseKey = "settings.systemSettings.timeEntrySettings";
  
  // Map of group keys to their header configurations
  const groupConfigs = {
    PROJECT_SPECIFIC: {
      header: "timeRegistration.projectSpecificHourTypes.title",
      type: "project-specific"
    },
    INTERNAL: {
      header: `${baseKey}.internalActivities`,
      type: "internal"
    },
    INTERNAL_EXTRA: {
      header: `${baseKey}.internalExtraActivities`,
      type: "internal-extra"
    },
    EXTERNAL: {
      header: `${baseKey}.projectActivities`,
      type: "external"
    },
    EXTERNAL_EXTRA: {
      header: `${baseKey}.projectExtraActivities`,
      type: "external-extra"
    }
  };

  // Add each non-empty group with its header
  Object.entries(groups).forEach(([groupKey, groupItems]) => {
    if (groupItems.length > 0) {
      items.push({
        header: groupConfigs[groupKey].header,
        type: groupConfigs[groupKey].type
      });
      items.push(...groupItems);
    }
  });

  return items;
}

/**
 * Determines the type of global items based on project context
 * @param {string|null} projectId - Project ID if in project context
 * @param {boolean} isExtra - Whether these are extra hours
 * @returns {string} The appropriate type identifier
 */
export function getGlobalItemsType(projectId, isExtra) {
  if (projectId) {
    return isExtra ? "external-extra" : "external";
  }
  return isExtra ? "internal-extra" : "internal";
}

/**
 * Gets the translation key for global items header based on context
 * @param {string|null} projectId - Project ID if in project context
 * @param {boolean} isExtra - Whether these are extra hours
 * @returns {string} The appropriate translation key
 */
export function getGlobalItemsTranslationKey(projectId, isExtra) {
  const baseKey = "settings.systemSettings.timeEntrySettings";
  
  if (projectId) {
    return `${baseKey}.${isExtra ? "projectExtraActivities" : "projectActivities"}`;
  }
  return `${baseKey}.${isExtra ? "internalExtraActivities" : "internalActivities"}`;
}

/**
 * Filter function for hour type search that includes header matching
 * @param {Object} item - Current item being filtered
 * @param {string} queryText - Search text
 * @param {string} itemText - Item's display text
 * @param {Array} hourTypes - Full array of hour types including headers
 * @returns {boolean} Whether the item matches the search
 */
export function hourTypeSearchFilter(item, queryText, itemText, hourTypes) {
  if (!queryText) return true;
  
  const searchLower = queryText.toLowerCase();
  
  // If it's a header item
  if (item.header) {
    // Show header if it matches the search
    return item.header.toLowerCase().includes(searchLower);
  }
  
  // For regular items
  const itemIndex = hourTypes.indexOf(item);
  const headerIndex = hourTypes.findLastIndex((it, index) => 
    index < itemIndex && it.header
  );
  const header = headerIndex >= 0 ? hourTypes[headerIndex] : null;
  
  // Show item if either:
  // 1. The item name matches the search
  // 2. The header matches the search (show all items under matching header)
  return itemText.toLowerCase().includes(searchLower) || 
         (header && header.header.toLowerCase().includes(searchLower));
}
