<template>
  <div class="p-relative">
    <AppDefaultTooltip
      top
      :disabled="!tooltip"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="p-absolute"
          style="right: 0; bottom: 2px"
          v-bind="{ ...attrs, ...$attrs }"
          v-on="{ ...on, ...$listeners }"
          icon
          small
        >
          <v-icon>{{ $icons.LIGHT.ACTION.CONFIGURE }}</v-icon>
        </v-btn>
      </template>
      <span class="break-word">{{ tooltip }}</span>
    </AppDefaultTooltip>
  </div>
</template>

<script>
export default {
  props: {
    tooltip: String,
  },
};
</script>
