<template>
    <AppDefaultAutocomplete
      ref="autocomplete"
      v-bind="params"
      v-on="listeners"
      :items="filteredHourTypes"
      :loading="loading"
      item-text="name"
      item-value="id"
      :filter="(item, queryText, itemText) => 
        hourTypeSearchFilter(item, queryText, itemText, filteredHourTypes)"
    >
      <template
        #append-item
        v-if="canEnterSettingsPage"
      >
        <AppSelectAppendBtn
          @click="goToTimeEntrySettings"
          :icon="$icons.LIGHT.ACTION.SETTINGS"
        >
          {{ $t("timeRegistration.newTimeEntry.administrateActivities") }}
        </AppSelectAppendBtn>
      </template>
    </AppDefaultAutocomplete>
</template>

<script>
import { getHourTypes } from "@/services/timeEntries/hourTypes";
import { filterHourTypes, hourTypeSearchFilter } from "@/helpers/util";
import { permissionHelpers } from "@/helpers/util";
import _ from "lodash";

export default {
  props: {
    /** The selected hour type value. Can be a string ID, array of IDs, or hour type object */
    value: [String, Array, Object],
    /** The ID of the project to filter hour types by */
    projectId: String,
    /** Whether to show only extra hour types. Set to null to show all types */
    isExtra: {
      type: Boolean,
      default: null,
    },
    /** Additional query parameters to pass to the hour types API */
    queryParams: {
      type: Object,
      default: () => ({})
    },
    /** Whether to refetch hour types when queryParams changes */
    refetchOnQueryParamsChange: Boolean,
    /** Controls when to perform the initial fetch of hour types */
    isReadyToInitialFetch: Boolean,
    /** Whether to show all global hour types regardless of project */
    showAllGlobal: {
      type: Boolean,
      default: false
    },  
    /** Callback function to be called when hour types are ready */
    onFetchCompleteCallback: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      hourTypes: [],
      hasInitialFetched: false,
      hasPressedAdministrateHourTypes: false,
      visibilityListener: null
    };
  },
  watch: {
    //initial data fetch
    isReadyToInitialFetch: {
      handler(newVal) {
        if (newVal && !this.hasInitialFetched) {
          this.getHourTypes().then(() => {
            this.hasInitialFetched = true;
          });
        }
      },
      immediate: true,
    },
    //refetch on query params change
    queryParams: {
      handler(newVal, oldVal) {
        if (!newVal || !this.refetchOnQueryParamsChange) return;
        
        // Compare queryParams without alwaysIncludeIds
        const { alwaysIncludeIds: newAlwaysIncludeIds, ...newParams } = newVal;
        const { alwaysIncludeIds: oldAlwaysIncludeIds, ...oldParams } = oldVal || {};
        
        if (!_.isEqual(newParams, oldParams)) {
          this.getHourTypes();
        }
      },
      deep: true,
    },
    filteredHourTypes: {
      handler() {
        this.setDefaultHourTypeId();
      }
    }
  },
  computed: {
    canEnterSettingsPage() {
      return permissionHelpers.canViewRoute(
        this.$routeNames.SETTINGS.TIME_ENTRY_SETTINGS
      );
    },
    filteredHourTypes() {
      const items = filterHourTypes(this.hourTypes, {
        isExtra: this.isExtra,
        projectId: this.queryParams?.projectId,
        showAllGlobal: this.showAllGlobal
      });
      
      return items.map(item => {
        if (item.header) {
          return { ...item, header: this.$t(item.header) };
        }
        return item;
      });
    },
    params() {
      return { ...this.$props, ...this.$attrs };
    },
    listeners() {
      return { ...this.$listeners };
    }
  },
  methods: {
    hourTypeSearchFilter,
    async getHourTypes() {
      this.loading = true;
      try {
        const hourTypes = await getHourTypes({
          isActive: true,
          ...this.queryParams
        });
        this.hourTypes = hourTypes;

        this.$nextTick(() => {
            this.onFetchCompleteCallback({filteredHourTypes: this.filteredHourTypes});
        });
      } finally {
        this.loading = false;
      }
    },
    setDefaultHourTypeId() {
      if (this.value) return;
      if (this.filteredHourTypes?.length === 1) {
        this.$emit('input', this.filteredHourTypes[0].id);
      }
    },
    setupVisibilityListener() {
      this.visibilityListener = () => {
        if (!document.hidden && this.hasPressedAdministrateHourTypes) {
          setTimeout(() => {
            this.getHourTypes();
          }, 50);
        }
      };
      document.addEventListener("visibilitychange", this.visibilityListener);
    },
    goToTimeEntrySettings() {
      this.hasPressedAdministrateHourTypes = true;
      this.$emit("adminstrateHourTypes:click");
      window.open(
        this.$router.resolve({ name: this.$routeNames.SETTINGS.TIME_ENTRY_SETTINGS })
          .href,
        "_blank"
      );
    },
    focus() {
      this.$refs.autocomplete.focus();
    },
    activateMenu() {
      this.$refs.autocomplete.activateMenu();
    }
  },
  mounted() {
    this.setupVisibilityListener();
  },
  beforeDestroy() {
    document.removeEventListener("visibilitychange", this.visibilityListener);
  }
};
</script>
