// Define activity events as a constant to avoid redeclaring the array in multiple functions.
const ACTIVITY_EVENTS = ["mousedown", "mousemove", "keypress", "scroll", "touchstart"];

let intervals = new Map();
let lastUserActivity = Date.now();
const INACTIVITY_THRESHOLD = 5 * 60 * 1000; // 5 minutes

// Add error handling wrapper
function safeExecute(callback) {
  try {
    callback();
  } catch (error) {
    console.error("Periodic update error:", error);
  }
}

function isUserInactive() {
  return (
    Date.now() - lastUserActivity > INACTIVITY_THRESHOLD ||
    document.visibilityState === "hidden"
  );
}

// Add cleanup function
function cleanupActivityTracking() {
  ACTIVITY_EVENTS.forEach((event) => {
    document.removeEventListener(event, onUserActivity);
  });
  document.removeEventListener("visibilitychange", onVisibilityChange);
}

// Separate event handlers for easier cleanup
function onUserActivity() {
  // Consider removing or throttling this log in production for better performance.
  const wasInactive = isUserInactive();
  lastUserActivity = Date.now();

  if (wasInactive) {
    resumeAllIntervals();
  }
}

// Fixed visibility change handler
function onVisibilityChange() {
  if (document.visibilityState === "hidden") {
    pauseAllIntervals();
  } else {
    // Resume intervals when the tab becomes visible.
    lastUserActivity = Date.now(); // Reset activity timer
    resumeAllIntervals();
  }
}

function initializeActivityTracking() {
  ACTIVITY_EVENTS.forEach((event) => {
    document.addEventListener(event, onUserActivity, { passive: true });
  });
  document.addEventListener("visibilitychange", onVisibilityChange);
}

function startInterval(key, callback, interval) {
  if (!key || typeof callback !== "function" || !interval) {
    console.error("Invalid parameters for startInterval");
    return;
  }

  // Clear any existing interval before starting a new one.
  clearInterval(key);

  const config = {
    // Wrap the provided callback in safeExecute.
    callback: () => safeExecute(callback),
    interval,
    lastRun: Date.now(),
    timer: null,
  };

  // Start the interval immediately if the user is active.
  if (!isUserInactive()) {
    config.timer = setInterval(() => {
      if (!isUserInactive()) {
        config.lastRun = Date.now();
        safeExecute(callback);
      }
    }, interval);
  }

  intervals.set(key, config);
}

function clearInterval(key) {
  if (intervals.has(key)) {
    window.clearInterval(intervals.get(key).timer);
    intervals.delete(key);
  }
}

function pauseAllIntervals() {
  intervals.forEach((config) => {
    window.clearInterval(config.timer);
    config.timer = null;
  });
}

function resumeAllIntervals() {
  intervals.forEach((config, key) => {
    if (!config.timer) {
      const timeSinceLastRun = Date.now() - config.lastRun;
      if (timeSinceLastRun >= config.interval) {
        safeExecute(config.callback);
        config.lastRun = Date.now();
      }
      config.timer = setInterval(() => {
        if (!isUserInactive()) {
          config.lastRun = Date.now();
          safeExecute(config.callback);
        }
      }, config.interval);
    }
  });
}

// Add method to check if an interval exists.
function hasInterval(key) {
  return intervals.has(key);
}

// Add method to get the count of active intervals.
function getActiveIntervalsCount() {
  return intervals.size;
}

// Initialize activity tracking as soon as the service is created.
initializeActivityTracking();

export const periodicUpdateService = {
  startInterval,
  clearInterval,
  pauseAllIntervals,
  resumeAllIntervals,
  hasInterval,
  getActiveIntervalsCount,
  cleanup: cleanupActivityTracking,
};
