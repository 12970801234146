<template>
  <div>
    <DropArea
      ref="dropArea"
      :isDraggingOver="fileDropHandlerMixins_isDraggingOver"
      :hasFiles="hasSelectedItems"
      :loading="loading || isLoadingAcceptedFileTypes"
      border-enabled
      cursor-enabled
      @click="openFileExplorer"
      @dragOver="fileDropHandlerMixins_onDragOver"
      @dragLeave="fileDropHandlerMixins_onDragLeave"
      @fileDrop="onFileDropHandler"
    >
      <FileList
        v-if="hasSelectedItems"
        ref="fileList"
        :items="selectedItems"
        :maxHeight="maxHeight"
        :disableNameEdit="disableNameEdit"
        @removeItem="removeItem"
        @updateItemName="updateItemName"
        :isDraggingOver="fileDropHandlerMixins_isDraggingOver"
      />
      <EmptyState
        v-else
        :loading="loading || isLoadingAcceptedFileTypes"
      />
    </DropArea>
    <AddFileBtn
      v-if="isModeFileExplorer"
      translationKey="common.selectFilesFromFileExplorer"
      @click="openFileExplorer"
    />
    <AddFileBtn
      v-if="isModeStorageManager && canViewStorage"
      translationKey="common.selectFilesFromGripr"
      @click="openStorageManager"
    />
    <FileInput
      v-if="isModeFileExplorer"
      ref="uploader"
      :acceptedTypes="fileDropHandlerMixins_fileAccepts({ mimeTypes })"
      @fileSelect="afterFileSelect"
    />
    <AppStorageManagerDialog
      v-if="isModeStorageManager"
      v-model="dialogs.storageManager"
      @confirm="addItems"
    />
  </div>
</template>

<script>
import { fileDropHandlerMixins, storageMixins, permissionMixins } from "@/helpers/mixins";
import DropArea from "./DropArea.vue";
import FileList from "./FileList.vue";
import EmptyState from "./EmptyState.vue";
import AddFileBtn from "./AddFileBtn.vue";
import FileInput from "./FileInput.vue";

export default {
  components: {
    DropArea,
    FileList,
    EmptyState,
    AddFileBtn,
    FileInput,
  },
  mixins: [storageMixins, fileDropHandlerMixins, permissionMixins],
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    loading: Boolean,
    modes: {
      type: Array,
      default: () => ["FILE_EXPLORER", "STORAGE_MANAGER"],
    },
    maxHeight: String,
    disableNameEdit: Boolean,
    mimeTypes: {
      type: Array,
      default: () => ["*/*"],
    },
  },
  data() {
    return {
      dialogs: {
        storageManager: false,
      },
    };
  },
  created() {
    // Listen to the event in the component that uses the mixin
    this.$on("fileDropHandlerMixins_files:dropped", this.addItems);
  },
  watch: {
    selectedItems: {
      handler(newItems, oldItems) {
        if (newItems.length > oldItems.length) {
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        }
      },
      deep: true,
    },
  },
  computed: {
    selectedItems: {
      get() {
        return this.value;
      },
      set(newItems) {
        this.$emit("change", newItems);
      },
    },
    isModeStorageManager() {
      return this.modes.includes("STORAGE_MANAGER");
    },
    isModeFileExplorer() {
      return this.modes.includes("FILE_EXPLORER");
    },
    hasSelectedItems() {
      return this.selectedItems.length > 0;
    },
    canViewStorage() {
      return this.permissionMixins_storage.view.can;
    },
  },
  methods: {
    removeItem(item) {
      const index = this.selectedItems.indexOf(item);
      if (index > -1) {
        this.selectedItems.splice(index, 1);
      }
    },
    openFileExplorer() {
      this.$refs.uploader.$el.click();
    },
    openStorageManager() {
      this.dialogs.storageManager = true;
    },
    async afterFileSelect(fileList) {
      const files = Array.from(fileList);
      const convertedFiles = await this.storageMixins_filesConvetion(files);
      this.addItems(convertedFiles);
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.fileList.scrollToBottom();
      });
    },
    addItems(newItems) {
      const itemsWithSource = newItems.map((item) => {
        if (item instanceof File) {
          //spread syntax not work on File because its a specialized blob
          item.source = "FILE";
          return item;
        } else {
          item.source = "STORAGE";
          return item;
        }
      });

      this.selectedItems.push(...itemsWithSource);
    },
    updateItemName({ item, newName }) {
      const index = this.selectedItems.findIndex((i) => i === item);
      if (index !== -1) {
        let updatedItem;
        if (item.source === "FILE") {
          updatedItem = this.renameFileWithProperties(item, newName);
        } else if (item.source === "STORAGE") {
          updatedItem = { ...item, name: newName };
        }

        this.selectedItems[index] = updatedItem;
      }
    },
    renameFileWithProperties(oldFile, newFileName) {
      const newFile = new File([oldFile], newFileName, {
        type: oldFile.type,
        lastModified: oldFile.lastModified,
      });

      for (let key in oldFile) {
        if (oldFile.hasOwnProperty(key) && !newFile.hasOwnProperty(key)) {
          newFile[key] = oldFile[key];
        }
      }

      return newFile;
    },
    onFileDropHandler(event) {
      return this.fileDropHandlerMixins_onFileDrop(this.mimeTypes)(event);
    },
  },
};
</script>

<style scoped lang="scss">
.drop-area--empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62px;
}
</style>
