<template>
  <AppColorChip
    v-bind="$attrs"
    v-on="$listeners"
    :text="
      $te(`common.userStatus.${status.toLowerCase()}`)
        ? $t(`common.userStatus.${status.toLowerCase()}`)
        : status
    "
    :color="userStatusColor"
  />
</template>

<script>
export default {
  props: {
    user: Object,
  },
  computed: {
    status() {
      return this.user?.domainUser?.status;
    },
    userStatusColor() {
      let colorClass = "";
      switch (this.status) {
        case this.$constant.ACTIVE:
          colorClass = "success--text";
          break;
        case this.$constant.INVITED:
          colorClass = "pink--text";
          break;
        case this.$constant.INACTIVE:
          colorClass = "warning--text";
          break;
        case this.$constant.DELETED:
          colorClass = "error--text";
          break;
        case this.$constant.IMPORTED:
          colorClass = "purple--text";
          break;
      }
      return colorClass;
    },
  },
};
</script>

<style lang="scss" scoped></style>
