import { ROUTE_NAMES } from "./routeNames";

const { TASK, FORM, TIME_REGISTRATION, PROJECT, CONTACT, SETTINGS } = ROUTE_NAMES;

export const SERVER_SIDE_PAGINATION_TABLE__PARENT_ROUTE_NAMES = [
  TASK.LIST.ROOT,
  FORM.ROOT,
  TIME_REGISTRATION.ALL_HOURS,
  PROJECT.TIME_REGISTRATION,
  CONTACT.ROOT,
  PROJECT.LIST,
  PROJECT.TASK.LIST.ROOT,
  PROJECT.FORM.LIST.ROOT,
  PROJECT.SUB_PROJECTS_LIST,
  PROJECT.VIEW_CONTACTS,
  PROJECT.PRODUCTS,
  SETTINGS.PRODUCTS,
];

export const CALENDAR__PARENT_ROUTE_NAMES = [
  TASK.CALENDAR.ROOT,
  PROJECT.CALENDAR,
  PROJECT.TASK.CALENDAR.ROOT,
  PROJECT.SUB_PROJECTS_CALENDAR,
];
