<template>
  <div>
    <v-data-table
      ref="dataTable"
      v-model="dataTableBulkActionMixin_selectedItems"
      :class="`elevation-0 overflow-hidden border-a fix-loader-visibility-on-data-table ${stickyColsClass} ${groupHeaderClass}`"
      hide-default-footer
      fixed-header
      v-bind="$attrs"
      v-on="$listeners"
      :headers="_visibleHeaders"
      :items="hideItems ? [] : items"
      :options="dataTableOptions"
      :server-items-length="serverItemsLength"
      :loadingText="$t('common.loading')"
      @update:options="onOptionsUpdate"
      @update:page="onPageUpdate"
      @page-count="pageCount = $event"
    >
      <template v-slot:[`group.header`]="slotProps">
        <slot
          name="group.header"
          v-bind="{
            ...slotProps,
            isGroupIndeterminate: isGroupIndeterminate({ items: slotProps.items }),
            isGroupSelected: isItemsSelected(slotProps.items),
            toggleGroup: () => toggleItems(slotProps.items),
          }"
        ></slot>
      </template>
      <template
        v-slot:[`body.prepend`]="{ headers }"
        v-if="showSelectAllBanner"
      >
        <AppDataTableSelectAllBanner
          :headersLength="headers.length"
          :itemsLength="items.length"
          :totalItems="serverItemsLength"
          :isSelectingAll="dataTableBulkActionMixin_isSelectingAll"
          :disabled="$attrs.loading"
          @select-all="dataTableBulkActionMixin_selectAllItems"
        />
      </template>
      <template v-slot:[`header.data-table-select`]="{ props, on }">
        <v-simple-checkbox
          color="primary"
          v-ripple
          v-bind="props"
          v-on="on"
        />
      </template>
      <template v-slot:footer>
        <div
          ref="dataTableFooter"
          class="py-1 border-t p-relative d-flex align-center justify-center"
        >
          <AppStatsText
            :isStatsLoading="isStatsLoading"
            :statsText="statsText"
          />
          <v-pagination
            v-if="pageFromRoute"
            class="pagination-items-remove-box-shadow"
            v-model="pageFromRoute"
            :length="pageCount"
            total-visible="7"
          />
          <div
            class="p-absolute"
            style="right: 0; min-width: 65px"
          >
            <AppMinimalisticSelect
              :value="dataTableOptions.itemsPerPage"
              :items="itemsPerPageItems"
              @change="onItemsPerPageChange"
            />
          </div>
        </div>
      </template>
      <template
        v-for="slotName in Object.keys($scopedSlots).filter(
          (name) => name !== 'group.header',
        )"
        v-slot:[slotName]="slotProps"
      >
        <slot
          :name="slotName"
          v-bind="slotProps"
        ></slot>
      </template>
    </v-data-table>
    <AppBulkActionMenu
      v-if="showSelect"
      :bulkActionConfigs="bulkActionConfigs"
      :selectedItems="dataTableBulkActionMixin_selectedItems"
      style="
        bottom: 100px;
        z-index: 3;
        transform: translateX(-50%);
        left: 50%;
        position: absolute;
        width: fit-content;
      "
      @clear:click="dataTableBulkActionMixin_clearSelection"
    />
    <AppEditHeadersDialog2
      :headers="headersWithoutSelect"
      :defaultHeaders="defaultHeadersWithoutSelect"
      :frozenColumnsCount="dataTableOptions.stickyCols"
      @submit="onEditHeadersSubmit"
      v-model="editHeadersDialog"
    />
  </div>
</template>

<script>
import { dataTableBulkActionMixin } from "@/helpers/mixins";
import { dataFieldsMixin } from "@/helpers/mixins/dataFieldsMixin";
import _ from "lodash";
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  mixins: [dataTableBulkActionMixin, dataFieldsMixin],
  props: {
    items: Array,
    serverItemsLength: Number,
    tableModel: String,
    actionConfigs: Array,
    statsText: String,
    isStatsLoading: Boolean,
    defaultHeaders: Array,
  },
  data() {
    return {
      pageCount: 0,
      hideItems: false,
      resizeObserver: null,
      isInitialSetup: true, //for preventing excessive requests during component setup
      editHeadersDialog: false,
      _previousGroupBy: [],
    };
  },
  watch: {
    dataTableOptions: {
      handler(newOptions) {
        if (!_.isEqual(newOptions?.groupBy, this._previousGroupBy)) {
          // this.hideItems = true;
          this._previousGroupBy = [...(newOptions?.groupBy || [])];
        }
      },
      deep: true,
      immediate: true,
    },
    ["$attrs.loading"]: {
      handler(val) {
        if (!val) {
          this.hideItems = false;
        }
      },
    },
    serverItemsLength: {
      handler() {
        if (
          this.pageFromRoute > this.lastPageWithItems &&
          this.serverItemsLength !== null
        ) {
          this.pageFromRoute = this.lastPageWithItems;
        }
      },
    },
    "dataTableOptions.stickyCols": {
      handler() {
        this.$nextTick(() => {
          this.calculateColWidthVariables();
        });
      },
      immediate: true,
    },
    "$route.query.page": {
      handler(newPage) {
        if (newPage && parseInt(newPage) !== this.pageFromRoute) {
          this.pageFromRoute = parseInt(newPage);
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("resourceViews", [
      "getCurrentResourceView",
      "getCurrentResourceViewConfig",
    ]),
    ...mapState("resourceViews", ["unsavedConfig"]),
    viewConfig() {
      return this.getCurrentResourceViewConfig || {};
    },
    pageFromRoute: {
      get() {
        return parseInt(this.$route.query.page);
      },
      set(value) {
        this.updatePageQuery(value);
      },
    },
    dataTableOptions() {
      // Convert from view format to v-data-table format
      const config = this.viewConfig;
      if (!config) return this.getDefaultOptions();

      return {
        itemsPerPage: config.itemsPerPage || 25,
        page: this.pageFromRoute || 1,
        sortBy: config.sort?.map((s) => s.by) || [],
        sortDesc: config.sort?.map((s) => s.desc) || [],
        groupBy: config.group?.map((g) => g.by) || [],
        groupDesc: config.group?.map((g) => g.desc) || [],
        multiSort: config.multiSort || false,
        mustSort: config.mustSort || false,
        stickyCols: config.stickyCols || 0,
      };
    },
    bulkActionConfigs() {
      if (!this.actionConfigs) return;
      return this.$constant.getBulkActionConfigs(this.actionConfigs);
    },
    showSelect() {
      const headers = this.finalHeaders;
      const selectHeader = headers.find((header) => header.value === "data-table-select");

      if (!selectHeader) return false;

      return this.getHeaderAccess(selectHeader);
    },
    finalHeaders() {
      // Get enabled columns from view config
      const enabledColumns = this.viewConfig.columns || [];

      // Start with all default headers
      let headers = this.defaultHeaders.map((defaultHeader) => {
        // If no columns are specified in the view config, enable all by default
        let enabled = false;
        if (defaultHeader.value === "data-table-select") {
          enabled = true;
        } else if (enabledColumns.length === 0) {
          enabled = true;
        } else if (enabledColumns.includes(defaultHeader.value)) {
          enabled = true;
        }

        return {
          ...defaultHeader,
          enabled,
          showHeader:
            enabled &&
            (defaultHeader.access !== undefined
              ? this.getHeaderAccess(defaultHeader)
              : true),
        };
      });

      // Ensure select column is first if it exists
      const selectHeader = headers.find((h) => h.value === "data-table-select");

      // Filter out the select header for sorting
      let headersToSort = headers.filter((h) => h.value !== "data-table-select");

      // Sort headers based on the order in viewConfig.columns
      if (enabledColumns.length > 0) {
        headersToSort.sort((a, b) => {
          const indexA = enabledColumns.indexOf(a.value);
          const indexB = enabledColumns.indexOf(b.value);

          // If a header is not in enabledColumns, put it at the end
          if (indexA === -1) return 1;
          if (indexB === -1) return -1;

          return indexA - indexB;
        });
      }

      // Put select header back at the beginning if it exists
      if (selectHeader) {
        headers = [selectHeader, ...headersToSort];
      } else {
        headers = headersToSort;
      }

      return headers;
    },
    _visibleHeaders() {
      const headers = this.finalHeaders.filter((header) => header.showHeader);
      return headers;
    },
    showSelectAllBanner() {
      return this.showSelect && this.isAllItemsOnPageSelected;
    },
    stickyColsClass() {
      if (!this.items?.length || this.hideItems) return "";

      // Get stickyCols from view config
      let stickyCols = this.viewConfig.stickyCols || 0;

      // Base classes
      const classes = [];

      // Add sticky classes for each column
      if (stickyCols > 0) {
        const totalStickyCols = this.showSelect ? stickyCols + 1 : stickyCols;

        // Add sticky column classes
        for (let i = 1; i <= totalStickyCols; i++) {
          classes.push(`sticky-col-${i}`);
          if (this.hasGroupBy) {
            classes.push(`sticky-group-${i}`);
          }
        }

        // Add border to last sticky column
        classes.push(`sticky-border-${totalStickyCols}`);
      }

      if (this.hasGroupBy) {
        classes.push("group-header-z-index");
      }

      return classes.join(" ");
    },
    hasGroupBy() {
      return !!this.dataTableOptions.groupBy?.length;
    },
    itemsPerPageItems() {
      return Array.from(
        new Set([
          this.dataTableOptions.itemsPerPage,
          ...this.$constant.TABLE_ITEM_PER_PAGE,
        ]),
      ).sort((a, b) => a - b);
    },
    lastPageWithItems() {
      return Math.ceil(this.serverItemsLength / this.dataTableOptions.itemsPerPage) || 1;
    },
    isAllItemsOnPageSelected() {
      //no items or no selected items
      if (
        this.dataTableBulkActionMixin_selectedItems.length === 0 ||
        this.items.length === 0
      ) {
        return false;
      }

      for (let i = 0; i < this.items.length; i++) {
        if (
          !this.dataTableBulkActionMixin_selectedItems.some(
            (item) => item.id === this.items[i].id,
          )
        ) {
          return false;
        }
      }
      if (this.serverItemsLength === this.dataTableBulkActionMixin_selectedItems.length)
        return false;
      return true;
    },
    hasItems() {
      return this.itemsLength > 0;
    },
    itemsLength() {
      return this.items?.length;
    },
    groupHeaderClass() {
      return this.showSelectAllBanner ? "adjust-group-header" : "";
    },
    headersWithoutSelect() {
      return this.finalHeaders.filter((h) => h.value !== "data-table-select");
    },
    defaultHeadersWithoutSelect() {
      return this.defaultHeaders.filter((h) => h.value !== "data-table-select");
    },
  },
  methods: {
    ...mapActions("resourceViews", ["updateUnsavedConfig"]),
    getDefaultOptions() {
      return {
        itemsPerPage: 25,
        page: 1,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
        stickyCols: 0,
      };
    },
    getHeaderAccess(header) {
      const access = header.access;
      if (typeof access === "function") {
        return access({ items: this.items });
      }
      return access !== undefined ? access : true;
    },
    openEditHeadersDialog() {
      this.editHeadersDialog = true;
    },
    onEditHeadersSubmit({ headers, frozenColumnsCount }) {
      // Get current view config
      const currentViewId = this.$store.state.resourceViews.currentResourceViewId;
      if (!currentViewId) return;

      // Create updates object
      const updates = {
        columns: headers.filter((h) => h.enabled).map((h) => h.value),
        stickyCols: frozenColumnsCount,
      };

      // Use updateUnsavedConfig instead
      this.updateUnsavedConfig({ updates });

      // Emit refresh-view-data to update the display
      this.$root.$emit("refresh-view-data");
    },
    updateViewConfig(options) {
      if (!this.getCurrentResourceView?.id) return;

      // Convert from v-data-table format to view format
      const updates = {
        itemsPerPage: options.itemsPerPage,
        stickyCols: options.stickyCols,
        multiSort: options.multiSort,
        mustSort: options.mustSort,
        sort: options.sortBy.map((by, index) => ({
          by,
          desc: options.sortDesc[index] || false,
        })),
        group: options.groupBy.map((by, index) => ({
          by,
          desc: options.groupDesc[index] || false,
        })),
      };

      // Use updateUnsavedConfig instead
      this.updateUnsavedConfig({ updates });

      // Emit refresh-view-data to update the display
      this.$root.$emit("refresh-view-data");
    },
    toggleItems(items) {
      if (this.isItemsSelected(items)) {
        this.removeItems(items);
      } else {
        this.selectItems(items);
      }
    },
    selectItems(items) {
      const itemsToAdd = items.filter((item) => !this.isItemSelected({ id: item.id }));
      this.dataTableBulkActionMixin_selectedItems = [
        ...this.dataTableBulkActionMixin_selectedItems,
        ...itemsToAdd,
      ];
    },
    removeItems(items) {
      const itemsToRemove = items.filter((item) => this.isItemSelected({ id: item.id }));
      this.dataTableBulkActionMixin_selectedItems =
        this.dataTableBulkActionMixin_selectedItems.filter(
          (item) => !itemsToRemove.some((t) => t.id === item.id),
        );
    },
    isGroupIndeterminate({ items }) {
      return !this.isItemsSelected(items) && this.isSomeItemsSelected(items);
    },
    isItemsSelected(items) {
      return items.every((item) => this.isItemSelected({ id: item.id }));
    },
    isItemSelected({ id }) {
      return this.dataTableBulkActionMixin_selectedItems.some((item) => item.id === id);
    },
    isSomeItemsSelected(items) {
      return items.some((item) =>
        this.dataTableBulkActionMixin_selectedItems.some(
          (selectedItem) => selectedItem.id === item.id,
        ),
      );
    },
    updatePageQuery(page) {
      if (page === this.pageFromRoute) return;
      this.$router.replace({ query: { ...this.$route.query, page } });
    },
    onItemsPerPageChange(event) {
      const newItemsPerPage = event;

      // Calculate if we need to adjust the page
      const newLastPage = Math.ceil(this.serverItemsLength / newItemsPerPage) || 1;
      const newPage = this.pageFromRoute > newLastPage ? newLastPage : this.pageFromRoute;

      // Update the view config with new itemsPerPage
      this.updateViewConfig({
        ...this.dataTableOptions,
        itemsPerPage: newItemsPerPage,
      });

      // Update the page query if needed
      if (newPage !== this.pageFromRoute) {
        this.updatePageQuery(newPage);
      }
    },
    onPageUpdate(event) {
      if (event.page == this.pageFromRoute) return;
      this.$root.$emit("refresh-view-data");
    },
    onOptionsUpdate(event) {
      if (event.page !== this.pageFromRoute) {
        this.updatePageQuery(event.page);
      }

      if (this.isInitialSetup) {
        // Allow the initial setup to complete without triggering updates
        return;
      }

      // Handle page change separately

      // Only update view config if non-page options changed
      const currentOptions = this.dataTableOptions;

      const optionsEqual = _.isEqual(currentOptions, event);

      if (optionsEqual) return;
      // const optionsChanged =
      //   event.itemsPerPage !== currentOptions.itemsPerPage ||
      //   !_.isEqual(event.sortBy, currentOptions.sortBy) ||
      //   !_.isEqual(event.sortDesc, currentOptions.sortDesc) ||
      //   !_.isEqual(event.groupBy, currentOptions.groupBy) ||
      //   !_.isEqual(event.groupDesc, currentOptions.groupDesc) ||
      //   event.multiSort !== currentOptions.multiSort ||
      //   event.mustSort !== currentOptions.mustSort;

      this.updateViewConfig(event);
    },
    calculateLayout() {
      this.$nextTick(() => {
        this.updateDataTableHeight();
        this.calculateColWidthVariables();
      });
    },
    updateDataTableHeight() {
      const dataTableWrapper = this.$refs.dataTable?.$el.querySelector(
        ".v-data-table__wrapper",
      );
      if (dataTableWrapper) {
        dataTableWrapper.style.maxHeight = this.calcDataTableMaxHeight();
      }
    },
    calcDataTableMaxHeight() {
      if (this.height) return;
      const pixelsFromTopOfPageToTable =
        this.$refs.dataTable?.$el.getBoundingClientRect().top;
      const tableFooterHeight = this.$refs.dataTableFooter?.offsetHeight || 0;
      const extraPixelsToAccountFor = 30;
      const heightToRemove =
        pixelsFromTopOfPageToTable + tableFooterHeight + extraPixelsToAccountFor;

      const maxHeightStyle = `calc(100vh - ${heightToRemove}px)`;
      return maxHeightStyle;
    },
    calculateColWidthVariables() {
      if (!this.items?.length) return;

      setTimeout(() => {
        const dataTable = this.$refs.dataTable?.$el;
        if (!dataTable) return;

        const getColWidth = (trSelector, colNum) => {
          const col = dataTable.querySelector(
            `table > tbody > tr.${trSelector} > td:nth-child(${colNum})`,
          );
          return col?.offsetWidth || 0;
        };

        const setVariable = (variable, amount) => {
          dataTable.style.setProperty(variable, `${amount}px`);
        };

        // Calculate cumulative widths for list rows
        let cumulativeWidth = 0;
        const maxCols = this.showSelect
          ? this.dataTableOptions.stickyCols + 1
          : this.dataTableOptions.stickyCols;

        // First set all widths
        const colWidths = [];
        for (let i = 1; i <= maxCols; i++) {
          colWidths[i] = getColWidth("list_tr", i);
        }

        // Then set cumulative offsets
        for (let i = 1; i <= maxCols; i++) {
          setVariable(`--sticky-offset-${i}`, cumulativeWidth);
          cumulativeWidth += colWidths[i];
        }

        // Handle group headers similarly if needed
        if (this.hasGroupBy) {
          let groupCumulativeWidth = 0;
          const groupColWidths = [];

          // First get all widths
          for (let i = 1; i <= maxCols; i++) {
            groupColWidths[i] = getColWidth("v-row-group__header", i);
          }

          // Then set cumulative offsets
          for (let i = 1; i <= maxCols; i++) {
            setVariable(`--sticky-group-offset-${i}`, groupCumulativeWidth);
            groupCumulativeWidth += groupColWidths[i];
          }
        }
      }, 50);
    },
    initResizeObserver() {
      const tableElement = this.$refs.dataTable?.$el; // Assuming you have a ref="table" on your <table>
      if (tableElement) {
        // debounce the calculation of the layout to avoid performance issues
        this.calculateLayout();
        const debouncedCalculateLayout = _.debounce(() => {
          this.calculateLayout();
        }, 100);

        this.resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            debouncedCalculateLayout();
          }
        });
        this.resizeObserver.observe(tableElement);
      }
    },
    destroyResizeObserver() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
        this.resizeObserver = null;
      }
    },
    onInit() {
      this.initResizeObserver();
      this.finishInitialSetup();
    },
    finishInitialSetup() {
      this.$nextTick(() => {
        this.isInitialSetup = false;
      });
    },
    updateStickyOffsets() {
      const table = this.$el.querySelector("table");
      if (!table) return;

      let accumulatedWidth = 0;
      const stickyColumns = this.showSelect
        ? this.dataTableOptions.stickyCols + 1
        : this.dataTableOptions.stickyCols;

      // Set data attributes and calculate offsets
      for (let i = 0; i < stickyColumns; i++) {
        const cells = table.querySelectorAll(
          `td:nth-child(${i + 1}), th:nth-child(${i + 1})`,
        );
        cells.forEach((cell) => {
          cell.dataset.stickyIndex = i;
        });

        // Get width of current column
        const firstCell = table.querySelector(
          `th:nth-child(${i + 1}), td:nth-child(${i + 1})`,
        );
        if (firstCell) {
          table.style.setProperty(`--sticky-offset-${i}`, `${accumulatedWidth}px`);
          accumulatedWidth += firstCell.offsetWidth;
        }
      }
    },
  },
  mounted() {
    this.onInit();
    this.updateStickyOffsets();
  },
  beforeDestroy() {
    this.destroyResizeObserver();
  },
  destroyed() {
    this.destroyResizeObserver();
  },
};
</script>

<style>
.z-index-0 * > .v-row-group__header {
  z-index: 0 !important;
}

.group-header-z-index .v-row-group__header {
  z-index: 3 !important;
  background-color: inherit;
}

.adjust-group-header .v-row-group__header {
  top: 97px !important;
}
</style>
