<template>
  <AppDefaultTextField
    class="mr-3"
    style="min-width: 220px; max-width: 220px"
    :label="$t('common.search')"
    :append-icon="$icons.LIGHT.ACTION.SEARCH"
    hide-details
    autofocus
    clearable
    :value="scopedFilters.search"
    @input="onSearch"
    background-color="white"
  />
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      searchDebounce: null,
    };
  },
  computed: {
    ...mapState("resourceViews", {
      scopedFilters: (state) => state.scopedFilters,
    }),
  },
  methods: {
    onSearch(search) {
      clearTimeout(this.searchDebounce);
      this.searchDebounce = setTimeout(() => {
        this.$store.dispatch("resourceViews/updateScopedFilter", {
          key: "search",
          value: search,
        });
        this.$root.$emit("refresh-view-data");
      }, 600);
    },
  },
};
</script>

<style lang="scss" scoped></style>
