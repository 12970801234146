<script>
import moment from "moment";
import { VCard, VDivider } from "vuetify/lib";
import { formTemplateBuilderMixins, planRestrictionsMixins } from "@/helpers/mixins";
import AppTaskViewDialog from "../../dialogs/task/AppTaskViewDialog.vue";
import SectionHeader from "./SectionHeader.vue";
import ItemHierarchy from "./ItemHierarchy.vue";
import SessionSignatures from "./SessionSignatures.vue";
import { mapState, mapGetters } from "vuex";
import _ from "lodash";

export default {
  mixins: [formTemplateBuilderMixins, planRestrictionsMixins],
  props: {
    form: Object,
    formItems: Array,
    isLoading: Boolean,
    isSignable: Boolean,
    isUpdatingTask: Boolean,
  },
  data() {
    return {
      taskViewDialog: {
        active: false,
        itemId: null,
        taskId: null,
      },
      chosenPreviewItemId: null,
      fileIds: null,
    };
  },
  watch: {
    activeDomainId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getDomain();
      }
    },
  },
  computed: {
    ...mapGetters("statuses", {
      getStatusByModelAndId: "getStatusByModelAndId",
      hasFetchedStatuses: "hasFetchedStatuses",
    }),
    ...mapState("domain", {
      domain: (state) => state.domain,
      profile: (state) => state.profile,
    }),
    ...mapGetters("auth", {
      activeDomainId: "activeDomainId",
    }),
    dueDate() {
      if (!this.form?.dueDate) return "";
      return moment(this.form?.dueDate).format("DD.MM.YYYY");
    },
    //the item of the clicked task view dialog
    taskViewItem() {
      return this.formItems.find((i) => i.id === this.taskViewDialog.itemId) || null;
    },
    //the item task of the clicked task view dialog
    taskViewTask() {
      if (!this.taskViewItem) return null;
      return this.taskViewItem.tasks.find((t) => t.id === this.taskViewDialog.taskId);
    },
    hasSigners() {
      return this.form?.signatureSession?.signers.length || this.form.signatures?.length;
    },
  },
  methods: {
    getDomain() {
      this.$store.dispatch("domain/getDomain", this.activeDomainId);
    },
    genCompanyHeaderRow() {
      let company = { ...this.domain, ...this.profile };
      company = {
        ...company,
        name: company.company,
        zipCode: company.zip,
        orgNr: company.nr,
      };
      return this.$createElement("AppCompanyHeader", {
        props: {
          company,
          isMobile: false,
        },
      });
    },
    genHeader() {
      return this.$createElement(
        VCard,
        {
          class: "border-a pa-6 mb-10",
          props: {
            flat: true,
            loading: this.isLoading,
            disabled: this.isLoading,
          },
        },
        [
          this.genCompanyHeaderRow(),
          this.$createElement("div", { class: "text-h4 pb-3" }, this.form?.name),
          this.$createElement(
            "div",
            { class: "text-pre-wrap pb-10" },
            this.form?.description,
          ),
          this.$createElement("AppFormPreviewInfoRows", {
            props: {
              form: this.form,
            },
          }),
        ],
      );
    },
    genSection(item) {
      return this.$createElement(
        VCard,
        {
          class: "border-a pa-6 mb-10",
          props: {
            flat: true,
            loading: this.isLoading,
            disabled: this.isLoading,
          },
        },
        [
          this.$createElement("div", { class: "d-flex flex-column" }, [
            this.$createElement(SectionHeader, { props: { item } }),
            this.$createElement(ItemHierarchy, {
              props: { items: this.formItems, item },
              on: {
                "item:getImages": (e) => this.$emit("item:getImages", e),
                "item:taskClick": (e) => this.onItemTaskClick(e),
                "item:imagePreview": (e) => this.onPreviewDialogOpen(e),
              },
            }),
          ]),
        ],
      );
    },
    onItemTaskClick({ task, item }) {
      this.taskViewDialog.itemId = item.id;
      this.taskViewDialog.taskId = task.id;
      this.taskViewDialog.active = this.taskViewTask ? true : false;
    },
    genItemBuilder(items) {
      return items.map((item, index) => {
        return this.genSection(item);
      });
    },
    genTaskViewDialog() {
      return this.$createElement(AppTaskViewDialog, {
        props: {
          dialog: this.taskViewDialog.active,
          task: this.taskViewTask,
          isUpdating: this.isUpdatingTask,
        },
        on: {
          "dialog:change": (e) => (this.taskViewDialog.active = false),
          "task:update": (e) => {
            const item = this.taskViewItem;
            const task = e.task;
            const body = e.data;
            this.$emit("item:taskUpdate", { item, task, body });
          },
        },
      });
    },
    genSignatures() {
      if (!this.hasSigners) return;
      const completedSignatures = (this.form.signatureSession?.signers || []).filter(
        (s) => s.status === "COMPLETED",
      );
      //merge both signature types
      const simpleSignatures = (this.form?.signatures || []).filter((s) => s.name);
      const allSignatures = [...completedSignatures, ...simpleSignatures];
      return this.$createElement(SessionSignatures, {
        props: {
          signatures: allSignatures || [],
        },
      });
    },
    genPreviewDialog() {
      return this.$createElement("AppFilePreviewDialog", {
        props: { initialFileId: this.chosenPreviewItemId, fileIds: this.fileIds },
        on: { "dialog:close": this.onPreviewDialogClose },
      });
    },
    onPreviewDialogClose() {
      this.chosenPreviewItemId = null;
    },
    onPreviewDialogOpen({ imageId, imageIds }) {
      this.chosenPreviewItemId = imageId;
      this.fileIds = imageIds;
    },
    async getStatuses() {
      await this.$store.dispatch("statuses/getStatuses", { model: "task" });
    },
    genCard() {
      const rootItems = this.formTemplateBuilderMixins_getRootItems(this.formItems || []);

      return this.$createElement("div", [
        this.$createElement("div", { class: "pa-6" }, [
          ...[
            this.isLoading || !this.form
              ? []
              : [
                  this.genHeader(),
                  this.genItemBuilder(rootItems),
                  this.genTaskViewDialog(),
                  this.isLoading || !this.form || !this.isSignable
                    ? null
                    : this.$createElement(
                        VCard,
                        {
                          class: "border-a mt-2",
                          props: {
                            flat: true,
                            loading: this.isLoading,
                            disabled: this.isLoading,
                          },
                        },
                        [
                          this.$createElement("div", { class: "pa-6" }, [
                            ...[this.genSignatures()],
                          ]),
                        ],
                      ),
                  this.genPreviewDialog(),
                ],
          ],
        ]),
      ]);
    },
  },
  mounted() {
    if (!this.hasFetchedStatuses("task")) {
      this.getStatuses();
    }
    this.getDomain();
  },
  render(h) {
    return this.genCard();
  },
};
</script>
