<template>
  <div>
    <!-- Single line layout (caption and value on same line) -->
    <div
      v-if="singleLine"
      class="d-flex align-center mb-2"
    >
      <!-- Optional icon -->
      <v-icon
        v-if="icon"
        small
        :color="iconColor"
        class="mr-2"
      >
        {{ icon }}
      </v-icon>

      <!-- Caption/label -->
      <span class="text-caption grey--text text--darken-3">{{ label }}</span>

      <!-- Value on right side -->
      <div class="ml-auto font-weight-medium">
        <slot>{{ value }}</slot>
      </div>
    </div>

    <!-- Multi-line layout (value below caption) -->
    <div
      v-else
      class="mb-2"
    >
      <!-- Caption row -->
      <div class="d-flex align-center mb-1">
        <v-icon
          v-if="icon"
          small
          :color="iconColor"
          class="mr-2"
        >
          {{ icon }}
        </v-icon>
        <span class="text-caption grey--text text--darken-3">{{ label }}</span>
      </div>

      <!-- Value row (indented) -->
      <div class="pl-4 font-weight-medium">
        <slot>{{ value }}</slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Boolean, Object, Array],
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    iconColor: {
      type: String,
      default: "grey darken-2",
    },
    singleLine: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
