import {
  RESOURCE_ROUTE_PERMISSIONS,
  ROUTE_NAMES,
  ROUTE_PERMISSIONS,
} from "@/helpers/routeNames";
import multiguard from "../multiguard";
import { makeBeforeEnter, redirectFromLocalStorage } from "../redirect";
import { authGuard, userGuard } from "@/auth";
import { HELP_CATEGORIES } from "@/helpers/constants/help";

const {
  VIEW,
  INFO,
  INFO_DETAILS,
  TASK,
  STORAGE,
  ALL_FILES,
  VIEW_MEMBERS,
  MEMBERS_EMPLOYEE_LIST,
  VIEW_CONTACTS,
  CONTACT_PERSON_LIST,
  CLIENT_LIST,
  VIEW_COMMENTS,
  FORM,
  PRODUCTS,
  E_BYGGESOK,
  SUB_PROJECTS,
  SUB_PROJECTS_LIST,
  SUB_PROJECTS_CALENDAR,
  TIME_REGISTRATION,
  TIME_REGISTRATION_ALL_HOURS,
} = ROUTE_NAMES.PROJECT;

//projectViewTask redirect
const projectViewTaskLocalStorageKey = `REDIRECT_ROUTE:${TASK.ROOT}`;
const beforeEnterProjectViewTask = makeBeforeEnter({
  localStorageKey: projectViewTaskLocalStorageKey,
});

//projectViewSubProjects redirect
const projectSubProjectsLocalStorageKey = `REDIRECT_ROUTE:${SUB_PROJECTS}`;
const beforeEnterProjectSubProjects = makeBeforeEnter({
  localStorageKey: projectSubProjectsLocalStorageKey,
});

export const projectViewRoutes = [
  {
    path: "/project/:projectId",
    name: VIEW,
    meta: {
      permission: {
        isRedirectRoute: true,
      },
      helpCategory: HELP_CATEGORIES.PROJECT,
    },
    beforeEnter: multiguard([authGuard, userGuard]),
    component: () =>
      import(
        /* webpackChunkName: "projectView" */ "../../pages/ProjectPage/ProjectViewPage"
      ),
    children: [
      {
        path: "info",
        name: INFO,
        meta: { permission: RESOURCE_ROUTE_PERMISSIONS.PROJECT },
        component: () =>
          import(
            /* webpackChunkName: "projectView" */ "../../pages/ProjectPage/ProjectViewPage/projectInfoPage"
          ),
        children: [
          {
            path: "details",
            name: INFO_DETAILS,
            meta: { permission: RESOURCE_ROUTE_PERMISSIONS.PROJECT },
            component: () =>
              import(
                /* webpackChunkName: "projectView" */ "../../pages/ProjectPage/ProjectViewPage/projectInfoPage/MenuPage"
              ),
          },
        ],
      },
      {
        path: "task",
        name: TASK.ROOT,
        meta: {
          permission: RESOURCE_ROUTE_PERMISSIONS.PROJECT.TASK,
          helpCategory: HELP_CATEGORIES.TASK,
        },
        component: () =>
          import(
            /* webpackChunkName: "projectView" */ "../../pages/TaskManagement/Tasks"
          ),
        redirect: () =>
          redirectFromLocalStorage({
            localStorageKey: projectViewTaskLocalStorageKey,
            validRoutes: [TASK.LIST.ROOT, TASK.CALENDAR.ROOT],
            defaultRoute: TASK.LIST.ROOT,
          }),
        children: [
          {
            path: "list",
            name: TASK.LIST.ROOT,
            meta: { permission: RESOURCE_ROUTE_PERMISSIONS.PROJECT.TASK },
            beforeEnter: beforeEnterProjectViewTask,
            component: () =>
              import(
                /* webpackChunkName: "projectView" */ "../../pages/TaskManagement/Tasks/TaskList"
              ),
            children: [
              {
                path: "menu/:taskId",
                name: TASK.LIST.MENU.ROOT,
                meta: { permission: RESOURCE_ROUTE_PERMISSIONS.TASK },
                component: () =>
                  import(
                    /* webpackChunkName: "projectView" */ "../../pages/TaskManagement/Tasks/MenuPage"
                  ),
                children: [
                  {
                    path: "details",
                    name: TASK.LIST.MENU.DETAILS,
                    meta: { permission: RESOURCE_ROUTE_PERMISSIONS.TASK },
                    component: () =>
                      import(
                        /* webpackChunkName: "projectView" */ "../../pages/TaskManagement/MenuPages/DetailsPage"
                      ),
                  },
                  {
                    path: "history",
                    name: TASK.LIST.MENU.HISTORY,
                    meta: { permission: RESOURCE_ROUTE_PERMISSIONS.TASK },
                    component: () =>
                      import(
                        /* webpackChunkName: "projectView" */ "../../pages/TaskManagement/MenuPages/HistoryPage"
                      ),
                  },
                  {
                    path: "to-do-list",
                    name: TASK.LIST.MENU.TO_DO_LIST,
                    meta: { permission: RESOURCE_ROUTE_PERMISSIONS.TASK },
                    component: () =>
                      import(
                        /* webpackChunkName: "projectView" */ "../../pages/TaskManagement/MenuPages/ToDoList"
                      ),
                  },
                  {
                    path: "storage/:folderId",
                    name: TASK.LIST.MENU.STORAGE,
                    meta: { permission: RESOURCE_ROUTE_PERMISSIONS.TASK },
                    component: () =>
                      import(
                        /* webpackChunkName: "projectView" */ "../../pages/TaskManagement/MenuPages/StoragePage"
                      ),
                  },
                  {
                    path: "forms",
                    name: TASK.LIST.MENU.FORMS,
                    meta: { permission: RESOURCE_ROUTE_PERMISSIONS.TASK.FORM },
                    component: () =>
                      import(
                        /* webpackChunkName: "projectView" */ "../../pages/TaskManagement/MenuPages/FormPage"
                      ),
                  },
                  {
                    path: "product-usage",
                    name: TASK.LIST.MENU.PRODUCT_USAGE,
                    meta: { permission: RESOURCE_ROUTE_PERMISSIONS.TASK },
                    component: () =>
                      import(
                        /* webpackChunkName: "projectView" */ "../../pages/TaskManagement/MenuPages/ProductUsagePage"
                      ),
                  },
                  {
                    path: "comments",
                    name: TASK.LIST.MENU.COMMENTS,
                    meta: { permission: RESOURCE_ROUTE_PERMISSIONS.TASK.COMMENT },
                    component: () =>
                      import(
                        /* webpackChunkName: "projectView" */ "../../pages/TaskManagement/MenuPages/CommentsPage"
                      ),
                  },
                ],
              },
            ],
          },
          {
            path: "calendar",
            name: TASK.CALENDAR.ROOT,
            meta: { permission: RESOURCE_ROUTE_PERMISSIONS.PROJECT.TASK },
            beforeEnter: beforeEnterProjectViewTask,
            component: () =>
              import(
                /* webpackChunkName: "projectView" */ "../../pages/TaskManagement/Tasks/TaskCalendar"
              ),
            children: [
              {
                path: "menu/:taskId",
                name: TASK.CALENDAR.MENU,
                meta: { permission: RESOURCE_ROUTE_PERMISSIONS.TASK },
                component: () =>
                  import(
                    /* webpackChunkName: "projectView" */ "../../pages/TaskManagement/Tasks/MenuPage"
                  ),
                children: [
                  {
                    path: "details",
                    name: TASK.CALENDAR.MENU.DETAILS,
                    meta: { permission: RESOURCE_ROUTE_PERMISSIONS.TASK },
                    component: () =>
                      import(
                        /* webpackChunkName: "projectView" */ "../../pages/TaskManagement/MenuPages/DetailsPage"
                      ),
                  },
                  {
                    path: "history",
                    name: TASK.CALENDAR.MENU.HISTORY,
                    meta: { permission: RESOURCE_ROUTE_PERMISSIONS.TASK },
                    component: () =>
                      import(
                        /* webpackChunkName: "projectView" */ "../../pages/TaskManagement/MenuPages/HistoryPage"
                      ),
                  },
                  {
                    path: "to-do-list",
                    name: TASK.CALENDAR.MENU.TO_DO_LIST,
                    meta: { permission: RESOURCE_ROUTE_PERMISSIONS.TASK },
                    component: () =>
                      import(
                        /* webpackChunkName: "projectView" */ "../../pages/TaskManagement/MenuPages/ToDoList"
                      ),
                  },
                  {
                    path: "storage/:folderId",
                    name: TASK.CALENDAR.MENU.STORAGE,
                    meta: { permission: RESOURCE_ROUTE_PERMISSIONS.TASK },
                    component: () =>
                      import(
                        /* webpackChunkName: "projectView" */ "../../pages/TaskManagement/MenuPages/StoragePage"
                      ),
                  },
                  {
                    path: "forms",
                    name: TASK.CALENDAR.MENU.FORMS,
                    meta: { permission: RESOURCE_ROUTE_PERMISSIONS.TASK.FORM },
                    component: () =>
                      import(
                        /* webpackChunkName: "projectView" */ "../../pages/TaskManagement/MenuPages/FormPage"
                      ),
                  },
                  {
                    path: "product-usage",
                    name: TASK.CALENDAR.MENU.PRODUCT_USAGE,
                    meta: { permission: RESOURCE_ROUTE_PERMISSIONS.TASK },
                    component: () =>
                      import(
                        /* webpackChunkName: "projectView" */ "../../pages/TaskManagement/MenuPages/ProductUsagePage"
                      ),
                  },
                  {
                    path: "comments",
                    name: TASK.CALENDAR.MENU.COMMENTS,
                    meta: { permission: RESOURCE_ROUTE_PERMISSIONS.TASK.COMMENT },
                    component: () =>
                      import(
                        /* webpackChunkName: "projectView" */ "../../pages/TaskManagement/MenuPages/CommentsPage"
                      ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "storage",
        name: STORAGE,
        meta: {
          permission: RESOURCE_ROUTE_PERMISSIONS.PROJECT,
          helpCategory: HELP_CATEGORIES.STORAGE,
        },
        component: () =>
          import(
            /* webpackChunkName: "projectView" */ "../../pages/FileManagement/ProjectStoragePage"
          ),
        children: [
          {
            path: "all-files/:rootId/:folderId",
            name: ALL_FILES,
            meta: { permission: RESOURCE_ROUTE_PERMISSIONS.PROJECT },
            component: () =>
              import(
                /* webpackChunkName: "projectView" */ "../../pages/FileManagement/ProjectStoragePage/AllFiles"
              ),
          },
        ],
      },
      {
        path: "members",
        name: VIEW_MEMBERS,
        meta: {
          permission: {
            isRedirectRoute: true,
          },
        },
        component: () =>
          import(
            /* webpackChunkName: "projectView" */ "../../pages/ProjectPage/ProjectViewPage/ProjectMembersPage"
          ),
        children: [
          {
            path: "employees",
            name: MEMBERS_EMPLOYEE_LIST,
            meta: { permission: RESOURCE_ROUTE_PERMISSIONS.PROJECT },
            component: () =>
              import(
                /* webpackChunkName: "projectView" */ "../../pages/ProjectPage/ProjectViewPage/ProjectMembersPage/projectMembersList"
              ),
          },
        ],
      },
      {
        path: "contacts",
        name: VIEW_CONTACTS,
        meta: {
          permission: {
            isRedirectRoute: true,
          },
        },
        component: () =>
          import(
            /* webpackChunkName: "projectView" */ "../../pages/ProjectPage/ProjectViewPage/projectContactsPage"
          ),
        children: [
          {
            path: "contact-person-list",
            name: CONTACT_PERSON_LIST,
            meta: {
              permission: RESOURCE_ROUTE_PERMISSIONS.PROJECT,
              helpCategory: HELP_CATEGORIES.CONTACT,
            },
            component: () =>
              import(
                /* webpackChunkName: "projectView" */ "../../pages/ProjectPage/ProjectViewPage/projectContactsPage/ContactPersonListPage"
              ),
          },
          {
            path: "client-list",
            name: CLIENT_LIST,
            meta: {
              permission: RESOURCE_ROUTE_PERMISSIONS.PROJECT,
              helpCategory: HELP_CATEGORIES.CLIENT,
            },
            component: () =>
              import(
                /* webpackChunkName: "projectView" */ "../../pages/ProjectPage/ProjectViewPage/projectContactsPage/ClientListPage"
              ),
          },
        ],
      },
      {
        path: "comments",
        name: VIEW_COMMENTS,
        meta: { permission: RESOURCE_ROUTE_PERMISSIONS.PROJECT.COMMENT },
        component: () =>
          import(
            /* webpackChunkName: "projectView" */ "../../pages/ProjectPage/ProjectViewPage/projectCommentsPage"
          ),
      },
      {
        path: "form",
        name: FORM.ROOT,
        component: () =>
          import(
            /* webpackChunkName: "projectView" */ "../../pages/FormManagement/ProjectForms"
          ),
        meta: {
          permission: {
            isRedirectRoute: true,
          },
          helpCategory: HELP_CATEGORIES.FORM,
        },
        children: [
          {
            path: "list",
            name: FORM.LIST.ROOT,
            meta: { permission: RESOURCE_ROUTE_PERMISSIONS.PROJECT.FORM },
            component: () =>
              import(
                /* webpackChunkName: "projectView" */ "../../pages/FormManagement/Forms/FormList"
              ),
            children: [
              {
                path: "menu/:formId",
                name: FORM.LIST.MENU.ROOT,
                meta: { permission: RESOURCE_ROUTE_PERMISSIONS.FORM },
                component: () =>
                  import(
                    /* webpackChunkName: "projectView" */ "../../pages/FormManagement/Forms/MenuPage"
                  ),
                children: [
                  {
                    path: "details",
                    name: FORM.LIST.MENU.DETAILS,
                    meta: { permission: RESOURCE_ROUTE_PERMISSIONS.FORM },
                    component: () =>
                      import(
                        /* webpackChunkName: "projectView" */ "../../pages/FormManagement/MenuPages/DetailsPage"
                      ),
                  },
                  {
                    path: "comments",
                    name: FORM.LIST.MENU.COMMENTS,
                    meta: { permission: RESOURCE_ROUTE_PERMISSIONS.FORM.COMMENT },
                    component: () =>
                      import(
                        /* webpackChunkName: "projectView" */ "../../pages/FormManagement/MenuPages/CommentsPage"
                      ),
                  },
                  {
                    path: "storage/:folderId",
                    name: FORM.LIST.MENU.STORAGE,
                    meta: { permission: RESOURCE_ROUTE_PERMISSIONS.FORM },
                    component: () =>
                      import(
                        /* webpackChunkName: "projectView" */ "../../pages/FormManagement/MenuPages/StoragePage"
                      ),
                  },
                  {
                    path: "signature",
                    name: FORM.LIST.MENU.SIGNATURE,
                    meta: { permission: RESOURCE_ROUTE_PERMISSIONS.FORM },
                    component: () =>
                      import(
                        /* webpackChunkName: "projectView" */ "../../pages/FormManagement/MenuPages/SignaturePage"
                      ),
                  },
                  {
                    path: "history",
                    name: FORM.LIST.MENU.HISTORY,
                    meta: { permission: RESOURCE_ROUTE_PERMISSIONS.FORM },
                    component: () =>
                      import(
                        /* webpackChunkName: "projectView" */ "../../pages/FormManagement/MenuPages/HistoryPage"
                      ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "product-usage",
        name: PRODUCTS,
        meta: { permission: RESOURCE_ROUTE_PERMISSIONS.PROJECT },
        component: () =>
          import(
            /* webpackChunkName: "projectView" */ "../../pages/ProjectPage/ProjectViewPage/projectProductsPage"
          ),
      },
      {
        path: "ebyggesok",
        name: E_BYGGESOK,
        meta: { permission: RESOURCE_ROUTE_PERMISSIONS.PROJECT.EBYGGESOK },
        component: () =>
          import(
            /* webpackChunkName: "projectView" */ "../../pages/ProjectPage/ProjectViewPage/projectEByggesokPage"
          ),
      },
      {
        path: "sub-projects",
        name: SUB_PROJECTS,
        meta: { permission: RESOURCE_ROUTE_PERMISSIONS.PROJECT },
        component: () =>
          import(
            /* webpackChunkName: "projectView" */ "../../pages/ProjectPage/ProjectViewPage/projectSubProjectsPage"
          ),
        redirect: () =>
          redirectFromLocalStorage({
            localStorageKey: projectSubProjectsLocalStorageKey,
            validRoutes: [SUB_PROJECTS_LIST, SUB_PROJECTS_CALENDAR],
            defaultRoute: SUB_PROJECTS_LIST,
          }),
        children: [
          {
            path: "list",
            name: SUB_PROJECTS_LIST,
            meta: { permission: RESOURCE_ROUTE_PERMISSIONS.PROJECT },
            beforeEnter: beforeEnterProjectSubProjects,
            component: () =>
              import(
                /* webpackChunkName: "projectView" */ "../../pages/ProjectPage/ProjectListPage"
              ),
          },
          {
            path: "calendar",
            name: SUB_PROJECTS_CALENDAR,
            meta: { permission: RESOURCE_ROUTE_PERMISSIONS.PROJECT },
            beforeEnter: beforeEnterProjectSubProjects,
            component: () =>
              import(
                /* webpackChunkName: "projectView" */ "../../pages/ProjectPage/ProjectCalendarPage"
              ),
          },
        ],
      },
      {
        path: "time-registration",
        name: TIME_REGISTRATION,
        meta: {
          permission: {
            isRedirectRoute: true,
          },
          helpCategory: HELP_CATEGORIES.TIME_REGISTRATION,
        },
        component: () =>
          import(
            /* webpackChunkName: "timeRegistration" */ "../../pages/TimeRegistrationPage"
          ),
        children: [
          {
            path: "all-hours",
            name: TIME_REGISTRATION_ALL_HOURS,
            meta: { permission: RESOURCE_ROUTE_PERMISSIONS.PROJECT.TIME_ENTRY },
            component: () =>
              import(
                /* webpackChunkName: "timeRegistration" */ "../../pages/TimeRegistrationPage/AllHoursPage"
              ),
          },
        ],
      },
    ],
  },
];
