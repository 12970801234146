<template>
  <div>
    <div class="pb-4 d-flex">
      <AppDefaultTextField
        class="mw-250px mr-2"
        :label="$t('common.search')"
        :append-icon="$icons.LIGHT.ACTION.SEARCH"
        :value="filtersMixins_filterByKey('search')"
        @input="filtersMixins_debounceSearch"
        autofocus
        hide-details
        background-color="white"
        clearable
      />
    </div>
    <AppUserTable
      :users="users"
      :isLoading="isLoading || filtersMixins_isLoadingFilters"
      @user:onDomainUserUpdate="onDomainUserUpdate"
      @user:onDomainUsersUpdate="onDomainUsersUpdate"
      @users:invite="inviteUsers"
      @afterEdit:completed="usersRefresh"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { filtersMixins } from "@/helpers/mixins";
import { planRestrictionsMixins } from "@/helpers/mixins";
export default {
  mixins: [filtersMixins, planRestrictionsMixins],
  props: {
    tableModel: String,
  },
  data() {
    return {
      dialog: {
        invite: {
          active: false,
        },
      },
      statuses: [
        { text: this.$t("common.userStatus.active"), value: this.$constant.ACTIVE },
        { text: this.$t("common.userStatus.inactive"), value: this.$constant.INACTIVE },
        { text: this.$t("common.userStatus.invited"), value: this.$constant.INVITED },
        { text: this.$t("common.userStatus.imported"), value: this.$constant.IMPORTED },
        { text: this.$t("common.userStatus.deleted"), value: this.$constant.DELETED },
      ],
    };
  },
  computed: {
    ...mapState("settingsUsers", {
      users: (state) => state.users,
      isLoading: (state) => state.isLoading,
    }),
  },
  watch: {
    filtersMixins_filters: {
      handler(newVal, oldVal) {
        this.getUsers();
      },
      deep: true,
    },
  },
  methods: {
    getUserStats() {
      this.$store.dispatch("userStats/getStats");
    },
    getUsers() {
      this.$store.dispatch("settingsUsers/getUsers", {
        filters: { ...this.filtersMixins_filters },
      });
    },
    getUserStats() {
      this.$store.dispatch("userStats/getStats");
    },
    openInviteDialog() {
      this.dialog.invite.active = true;
    },
    inviteUsers(e) {
      this.$emit("users:invite", e);
    },
    onDomainUsersUpdate(e) {
      this.$store.dispatch("settingsUsers/updateDomainUsers", e);
    },
    onDomainUserUpdate({ id, status, labelId }) {
      this.$store.dispatch("settingsUsers/updateDomainUser", { id, status, labelId });
    },
    usersRefresh() {
      this.getUsers();
      this.getUserStats();
    },
    usersResetGet() {
      this.$store.dispatch("settingsUsers/resetState");
      this.filtersMixins_getFilters();
    },
  },
  mounted() {
    this.usersResetGet();
  },
};
</script>
