import * as MODEL from "./dataTableModels";

//tables that use backend pagination
export const TABLE_HEADER = {
  [MODEL.TIME_ENTRIES]: {
    STATUS: "status",
    DATE: "date",
    PROJECT: "project.name",
    USER: "user.name",
    COMMENT: "comment",
    TASK: "task.name",
    DURATION: "duration",
    BREAK_DURATION: "breakDuration",
    TIME: "fromTime",
    IMAGE_IDS: "imageIds",
    TRANSFERRED: "transferred",
    HOUR_TYPE: "hourType.name",
    MENU: "menu",
  },
  [MODEL.SETTINGS_PRODUCTS]: {
    CODE: "code",
    NAME: "name",
    CLIENT: "client.name",
    UNIT: "unit.label",
    UNIT_PRICE: "unitPrice",
    IS_ACTIVE: "isActive",
    MENU: "menu",
  },
  [MODEL.FORMS]: {
    STATUS: "status.name",
    NAME: "name",
    SIGNATURE_STATUS: "signatureSession.status",
    PROGRESS: "progress.answers",
    OWNER: "owner.name",
    PROJECT: "project.name",
    TASK: "task.name",
    CATEGORY: "category.name",
    DUE_DATE: "dueDate",
    MENU: "menu",
  },
  [MODEL.PROJECT_FORMS]: {
    STATUS: "status.name",
    NAME: "name",
    SIGNATURE_STATUS: "signatureSession.status",
    PROGRESS: "progress.answers",
    OWNER: "owner.name",
    TASK: "task.name",
    CATEGORY: "category.name",
    DUE_DATE: "dueDate",
    MENU: "menu",
  },
  [MODEL.TASKS]: {
    STATUS: "status.name",
    NAME: "name",
    COUNT: "count",
    OWNER: "owner.name",
    MEMBERS: "members",
    PROJECT: "project.name",
    BELONGS_TO_FORM_ITEM: "formItem.name",
    START_DATE: "startDate",
    DUE_DATE: "dueDate",
    MENU: "menu",
  },
  [MODEL.PROJECT_TASKS]: {
    STATUS: "status.name",
    NAME: "name",
    COUNT: "count",
    OWNER: "owner.name",
    MEMBERS: "members",
    PROJECT: "project.name",
    BELONGS_TO_FORM_ITEM: "formItem.name",
    START_DATE: "startDate",
    DUE_DATE: "dueDate",
    MENU: "menu",
  },
  [MODEL.PROJECTS]: {
    NUMBER: "projectNumber",
    STATUS: "status.name",
    NAME: "name",
    CLIENT: "client.name",
    OWNER: "owner.name",
    MEMBERS: "members",
    START_DATE: "startDate",
    MENU: "menu",
  },
  [MODEL.PROJECT_CLIENTS]: {
    NAME: "name",
    ORGANIZATION_NUMBER: "organizationNumber",
    OWNER: "owner.name",
    TYPE: "type",
    PHONE: "phone",
    EMAIL: "email",
    ACTIVE: "active",
  },
  [MODEL.PROJECT_CONTACTS]: {
    NAME: "name",
    CLIENT: "client.name",
    LABEL: "label.name",
    RELATION_LABEL: "relation.label.name",
    PHONE: "phone",
    EMAIL: "email",
    ACTIVE: "active",
    MENU: "menu",
  },
  [MODEL.PROJECT_SUB_PROJECTS]: {
    NUMBER: "projectNumber",
    STATUS: "status.name",
    NAME: "name",
    CLIENT: "client.name",
    OWNER: "owner.name",
    MEMBERS: "members",
    START_DATE: "startDate",
    MENU: "menu",
  },
  [MODEL.PROJECT_TIME_ENTRIES]: {
    STATUS: "status",
    DATE: "date",
    USER: "user.name",
    COMMENT: "comment",
    TASK: "task.name",
    DURATION: "duration",
    BREAK_DURATION: "breakDuration",
    TIME: "fromTime",
    IMAGE_IDS: "imageIds",
    TRANSFERRED: "transferred",
    HOUR_TYPE: "hourType.name",
    MENU: "menu",
  },
  [MODEL.CLIENTS]: {
    NAME: "name",
    ORGANIZATION_NUMBER: "organizationNumber",
    OWNER: "owner.name",
    TYPE: "type",
    PHONE: "phone",
    EMAIL: "email",
    ACTIVE: "active",
  },
  [MODEL.CONTACTS]: {
    NAME: "name",
    CLIENT: "client.name",
    LABEL: "label.name",
    PHONE: "phone",
    EMAIL: "email",
    ACTIVE: "active",
    MENU: "menu",
  },
  [MODEL.PROJECT_PRODUCTS]: {
    CODE: "code",
    NAME: "name",
    TASK: "task.name",
    AMOUNT: "amount",
    UNIT: "unit.label",
    UNIT_PRICE: "unitPrice",
    TOTAL: "totalPrice",
    ADDED_AT: "addedAt",
    USER: "user.name",
    IS_SENT_TO_ACCOUNTING: "status",
    MENU: "menu",
  },
  [MODEL.CORPORATE_MANAGEMENT]: {
    NAME: "companyName",
    ORG_NR: "orgNr",
    OWNER: "owner.name",
    STATUS: "status",
    PLAN: "plan.name",
    CONNECTED_AT: "connectedAt",
    ACCESSES_GIVEN: "accessesGiven",
    MENU: "menu",
  },
};

export const TABLE_ITEM_PER_PAGE = [10, 25, 50, 100, 250, 500];

const DEFAULT_GROUP_BY = {
  [MODEL.TIME_ENTRIES]: [TABLE_HEADER[MODEL.TIME_ENTRIES].DATE],
};

const DEFAULT_SORT_BY = {};

export const DEFAULT_DATA_TABLE_OPTIONS = {
  itemsPerPage: 25,
  page: 1,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
  stickyCols: 0,
  //empty array means show all headers
  headers: [],
};

const GROUP_BY = "groupBy";
const GROUP_DESC = "groupDesc";
const SORT_BY = "sortBy";
const SORT_DESC = "sortDesc";
const ITEMS_PER_PAGE = "itemsPerPage";
const STICKY_COLS = "stickyCols";
const HEADERS = "headers";

const getLocalStorageJson = ({ model, property }) => {
  const localStorageKey = `${model}:${property}`;
  try {
    const item = localStorage.getItem(localStorageKey);
    return JSON.parse(item);
  } catch (error) {
    localStorage.removeItem(localStorageKey);
    return null;
  }
};

const getLocalStorageInt = ({ model, property }) => {
  const localStorageKey = `${model}:${property}`;
  const item = localStorage.getItem(localStorageKey);
  const parsedItem = parseInt(item);

  if (isNaN(parsedItem)) {
    localStorage.removeItem(localStorageKey);
    return null;
  }

  return parsedItem;
};

const getLocalStorageOptions = ({ model }) => {
  const defaultGroupBy = DEFAULT_GROUP_BY[model] || DEFAULT_DATA_TABLE_OPTIONS[GROUP_BY];
  const defaultGroupDesc = DEFAULT_DATA_TABLE_OPTIONS[GROUP_DESC];
  const defaultSortBy = DEFAULT_SORT_BY[model] || DEFAULT_DATA_TABLE_OPTIONS[SORT_BY];
  const defaultSortDesc = DEFAULT_DATA_TABLE_OPTIONS[SORT_DESC];
  const defaultItemsPerPage = DEFAULT_DATA_TABLE_OPTIONS[ITEMS_PER_PAGE];
  const defaultStickyCols = DEFAULT_DATA_TABLE_OPTIONS[STICKY_COLS];
  const defaultHeaders = DEFAULT_DATA_TABLE_OPTIONS[HEADERS];

  const options = {
    [GROUP_BY]: getLocalStorageJson({ model, property: GROUP_BY }) || defaultGroupBy,
    [GROUP_DESC]:
      getLocalStorageJson({ model, property: GROUP_DESC }) || defaultGroupDesc,
    [SORT_BY]: getLocalStorageJson({ model, property: SORT_BY }) || defaultSortBy,
    [SORT_DESC]: getLocalStorageJson({ model, property: SORT_DESC }) || defaultSortDesc,
    [ITEMS_PER_PAGE]:
      getLocalStorageInt({ model, property: ITEMS_PER_PAGE }) || defaultItemsPerPage,
    [STICKY_COLS]:
      getLocalStorageJson({ model, property: STICKY_COLS }) || defaultStickyCols,
    [HEADERS]: getLocalStorageJson({ model, property: HEADERS }) || defaultHeaders,
  };
  return options;
};

export const DATA_TABLE_OPTIONS = (() => {
  let dataTableOptions = [
    {
      model: MODEL.TIME_ENTRIES,
      options: {
        ...DEFAULT_DATA_TABLE_OPTIONS,
        ...getLocalStorageOptions({ model: MODEL.TIME_ENTRIES }),
      },
    },
    {
      model: MODEL.SETTINGS_PRODUCTS,
      options: {
        ...DEFAULT_DATA_TABLE_OPTIONS,
        ...getLocalStorageOptions({ model: MODEL.SETTINGS_PRODUCTS }),
      },
    },
    {
      model: MODEL.FORMS,
      options: {
        ...DEFAULT_DATA_TABLE_OPTIONS,
        ...getLocalStorageOptions({ model: MODEL.FORMS }),
      },
    },
    {
      model: MODEL.PROJECT_FORMS,
      options: {
        ...DEFAULT_DATA_TABLE_OPTIONS,
        ...getLocalStorageOptions({ model: MODEL.PROJECT_FORMS }),
      },
    },
    {
      model: MODEL.TASKS,
      options: {
        ...DEFAULT_DATA_TABLE_OPTIONS,
        ...getLocalStorageOptions({ model: MODEL.TASKS }),
      },
    },
    {
      model: MODEL.PROJECT_TASKS,
      options: {
        ...DEFAULT_DATA_TABLE_OPTIONS,
        ...getLocalStorageOptions({ model: MODEL.PROJECT_TASKS }),
      },
    },
    {
      model: MODEL.PROJECTS,
      options: {
        ...DEFAULT_DATA_TABLE_OPTIONS,
        ...getLocalStorageOptions({ model: MODEL.PROJECTS }),
      },
    },
    {
      model: MODEL.PROJECT_SUB_PROJECTS,
      options: {
        ...DEFAULT_DATA_TABLE_OPTIONS,
        ...getLocalStorageOptions({ model: MODEL.PROJECT_SUB_PROJECTS }),
      },
    },
    {
      model: MODEL.CLIENTS,
      options: {
        ...DEFAULT_DATA_TABLE_OPTIONS,
        ...getLocalStorageOptions({ model: MODEL.CLIENTS }),
      },
    },
    {
      model: MODEL.CONTACTS,
      options: {
        ...DEFAULT_DATA_TABLE_OPTIONS,
        ...getLocalStorageOptions({ model: MODEL.CONTACTS }),
      },
    },
    {
      model: MODEL.PROJECT_PRODUCTS,
      options: {
        ...DEFAULT_DATA_TABLE_OPTIONS,
        ...getLocalStorageOptions({ model: MODEL.PROJECT_PRODUCTS }),
      },
    },
  ];

  dataTableOptions.map(validateDataTableOptions);

  return dataTableOptions;
})();

export function setLocalStorageDataTableOptions({
  model,
  groupBy,
  groupDesc,
  sortBy,
  sortDesc,
  itemsPerPage,
  stickyCols,
  headers,
}) {
  localStorage.setItem(`${model}:${GROUP_BY}`, JSON.stringify(groupBy));
  localStorage.setItem(`${model}:${GROUP_DESC}`, JSON.stringify(groupDesc));
  localStorage.setItem(`${model}:${SORT_BY}`, JSON.stringify(sortBy));
  localStorage.setItem(`${model}:${SORT_DESC}`, JSON.stringify(sortDesc));
  localStorage.setItem(`${model}:${ITEMS_PER_PAGE}`, JSON.stringify(itemsPerPage));
  localStorage.setItem(`${model}:${STICKY_COLS}`, JSON.stringify(stickyCols));
  localStorage.setItem(`${model}:${HEADERS}`, JSON.stringify(headers));
}

function validateDataTableOptions(option) {
  //must set to array if not array in localStorage
  const model = option.model;
  const header = TABLE_HEADER[model];

  const validSortByValues = filterValues(option.options.sortBy, header);
  const validSortByIndices = getValidIndices(option.options.sortBy, header);

  // Synchronize sortDesc with sortBy
  const validSortDescValues = synchronizeValues(
    option.options.sortDesc,
    validSortByIndices,
  );

  const validGroupByValues = filterValues(option.options.groupBy, header);
  const validGroupByIndices = getValidIndices(option.options.groupBy, header);

  // Synchronize groupDesc with groupBy
  const validGroupDescValues = synchronizeValues(
    option.options.groupDesc,
    validGroupByIndices,
  );

  const validStickyCols = validateStickyCols(option.options.stickyCols, header);

  const validHeaders = validateHeaders(option.options.headers, model);

  const isItemsPerPageValid = TABLE_ITEM_PER_PAGE.includes(option.options.itemsPerPage);

  option.options.sortBy = validSortByValues;
  option.options.sortDesc = validSortDescValues;
  option.options.groupBy = validGroupByValues;
  option.options.groupDesc = validGroupDescValues;
  option.options.itemsPerPage = isItemsPerPageValid
    ? option.options.itemsPerPage
    : DEFAULT_DATA_TABLE_OPTIONS[ITEMS_PER_PAGE];
  option.options.stickyCols = validStickyCols;
  option.options.headers = validHeaders;

  setLocalStorageDataTableOptions({
    model,
    ...option.options,
  });

  return option;
}

function validateStickyCols(stickyColsValue, header) {
  // Handle legacy boolean conversion
  if (typeof stickyColsValue === "boolean") {
    return stickyColsValue ? 2 : 0;
  }

  // Validate number
  const maxColumns = Object.keys(header).length;
  const isValidNumber =
    typeof stickyColsValue === "number" &&
    Number.isInteger(stickyColsValue) &&
    stickyColsValue >= 0 &&
    stickyColsValue < maxColumns;

  return isValidNumber ? stickyColsValue : DEFAULT_DATA_TABLE_OPTIONS[STICKY_COLS];
}

function validateHeaders(headers, model) {
  if (!headers.length) {
    return [];
  }

  const isObject = (value) => typeof value === "object" && value !== null;
  const hasValueProperty = (value) => "value" in value;
  const hasEnabledProperty = (value) => "enabled" in value;
  const isIncludedInTableHeaders = (value) =>
    Object.values(TABLE_HEADER[model]).includes(value);

  return headers.filter(
    (header) =>
      isObject(header) &&
      hasValueProperty(header) &&
      hasEnabledProperty(header) &&
      isIncludedInTableHeaders(header.value),
  );
}

function filterValues(values, header) {
  return values
    ? values.filter((value) => header && Object.values(header).includes(value))
    : values;
}

function synchronizeValues(values, indices) {
  return values.filter((_, index) => indices.includes(index));
}

function getValidIndices(values, header) {
  return values
    .map((value, index) =>
      header && Object.values(header).includes(value) ? index : null,
    )
    .filter((index) => index !== null);
}
