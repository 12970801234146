<template>
  <div class="text-border-reset">
    <AppDeleteConfirmationDialog
      ref="deleteConfirmation"
      v-model="dialog.deleteTask.active"
      :title="
        $tc('taskManagement.deleteConfirmation.title', dialog.deleteTask.item?.length)
      "
      skip-validation
      :item="dialog.deleteTask.item"
      @delete="deleteTasks"
    />
    <AppDataTableServerSidePagination
      ref="dataTable"
      :loading="isLoading"
      :defaultHeaders="defaultHeaders"
      :items="tasks"
      :tableModel="tableModel"
      :serverItemsLength="serverItemsLength"
      :noDataText="$t('common.noTasks')"
      :getAllItems="getAllItems"
      :actionConfigs="actionConfigs"
      :statsText="statsText"
      :isStatsLoading="isStatsLoading"
      removeMutationType="task/removeTask"
      updateMutationType="task/updateTask"
    >
      <template
        v-slot:group.header="{
          toggle,
          group,
          isOpen,
          headers,
          isGroupIndeterminate,
          isGroupSelected,
          toggleGroup,
        }"
      >
        <td
          @click.stop="toggleGroup"
          class="table-cell--hover-effect"
          v-ripple
        >
          <div class="d-flex align-center justify-center h-100">
            <AppDefaultCheckboxIcon
              :isIndeterminate="isGroupIndeterminate"
              :isSelected="isGroupSelected"
            />
          </div>
        </td>
        <td :colspan="headers.length - 6">
          <div class="d-flex align-center">
            <v-btn
              class="mr-4"
              icon
              @click.stop="toggle"
            >
              <v-icon>
                {{
                  isOpen
                    ? $icons.LIGHT.COMMON.CHEVRON_DOWN
                    : $icons.LIGHT.COMMON.CHEVRON_UP
                }}
              </v-icon>
            </v-btn>
            <div class="group-header__title">
              {{ headerTitle(group) }}
            </div>
          </div>
        </td>
        <td :colspan="5"></td>
      </template>
      <template v-slot:item="{ item, headers, select, isSelected }">
        <AppTaskTableItem
          :task="item"
          :headers="headers"
          :tableModel="tableModel"
          :select="select"
          :isSelected="isSelected"
          :isContextMenuActiveItem="isContextMenuActiveItem(item)"
          :isActiveItem="isActiveTask(item)"
          @menu:click="openContextMenu"
          @filters:close="$emit('filters:close')"
        />
      </template>
    </AppDataTableServerSidePagination>
    <AppDataTableContextMenu
      ref="contextMenu"
      :actionConfigs="actionConfigs"
      :items="[contextMenuActiveItem]"
      @input="onContextMenuChange"
    />
    <AppCustomStatusPickerMenu
      ref="statusMenu"
      @submit="onTasksUpdate"
      model="task"
    />
    <AppUserPickerMenu
      ref="responsibleMenu"
      valueKey="owner"
      @submit="(id) => onTasksUpdate({ ownerId: id })"
      model="task"
    />
    <AppUserPickerMenu
      ref="membersMenu"
      valueKey="members"
      @submit="(ids) => onTasksUpdate({ memberIds: ids })"
      model="task"
      multiple
    />
    <AppProjectPickerMenu
      ref="projectMenu"
      @submit="onTasksUpdate"
      model="task"
    />
    <AppDatePickerMenu
      ref="startDateMenu"
      @submit="({ date }) => onTasksUpdate({ startDate: date })"
      model="task"
    />
    <AppDatePickerMenu
      ref="dueDateMenu"
      @submit="({ date }) => onTasksUpdate({ dueDate: date })"
      model="task"
    />
  </div>
</template>

<script>
import { groupByMixins, permissionMixins } from "@/helpers/mixins";
import { createTableHeaders } from "@/helpers/util";
import { getAllTasks } from "@/services/task/allTasks";

export default {
  mixins: [groupByMixins, permissionMixins],
  props: {
    tasks: Array,
    isLoading: Boolean,
    taskId: String,
    tableModel: String,
    serverItemsLength: Number,
    selectedTasks: Array,
    statsText: String,
    isStatsLoading: Boolean,
  },
  data() {
    return {
      dialog: {
        deleteTask: {
          active: false,
          item: [],
        },
      },
      contextMenuActiveItem: null,
    };
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    activeTask() {
      return this.tasks.find((t) => t.id === this.taskId);
    },
    actions() {
      const {
        EDIT_STATUS,
        COPY_URL,
        OVERVIEW,
        EDIT_RESPONSIBLE,
        EDIT_MEMBERS,
        EDIT_START_DATE,
        EDIT_DUE_DATE,
        EDIT_PROJECT,
        DELETE,
      } = this.$constant.ACTION_KEYS;

      const openMenu = (menuRef) => (e) => this.openEditMenu({ menuRef, ...e });
      const canNotEdit = ({ items }) => !this.canEditTasks({ items });
      const canNotDelete = ({ items }) => !this.canDeleteTasks({ items });

      return {
        [EDIT_STATUS]: {
          disabled: canNotEdit,
          click: openMenu("statusMenu"),
        },
        [COPY_URL]: {
          click: this.onCopyUrl,
        },
        [OVERVIEW]: {
          click: this.onTaskDetailsClick,
        },
        [EDIT_RESPONSIBLE]: {
          disabled: canNotEdit,
          click: openMenu("responsibleMenu"),
        },
        [EDIT_MEMBERS]: {
          disabled: canNotEdit,
          click: openMenu("membersMenu"),
        },
        [EDIT_START_DATE]: {
          disabled: canNotEdit,
          click: openMenu("startDateMenu"),
        },
        [EDIT_DUE_DATE]: {
          disabled: canNotEdit,
          click: openMenu("dueDateMenu"),
        },
        [EDIT_PROJECT]: {
          disabled: canNotEdit,
          click: openMenu("projectMenu"),
        },
        [DELETE]: {
          disabled: canNotDelete,
          click: this.onTasksDelete,
        },
      };
    },

    defaultHeaders() {
      const {
        EDIT_STATUS,
        OVERVIEW,
        EDIT_RESPONSIBLE,
        EDIT_MEMBERS,
        EDIT_PROJECT,
        EDIT_START_DATE,
        EDIT_DUE_DATE,
      } = this.$constant.ACTION_KEYS;

      const actions = this.actions;

      return createTableHeaders([
        {
          preset: "SELECT",
        },
        {
          preset: "STATUS",
          value: this.filtersMixins_dataFields.STATUS,
          ...actions[EDIT_STATUS],
        },
        {
          preset: "NAME",
          text: this.$t("taskManagement.list.taskName"),
          value: this.filtersMixins_dataFields.NAME,
          ...actions[OVERVIEW],
        },
        {
          preset: "CONTENT_OVERVIEW",
          value: this.filtersMixins_dataFields.COUNT,
        },
        {
          text: this.$t("taskManagement.list.responsible"),
          value: this.filtersMixins_dataFields.OWNER,
          ...actions[EDIT_RESPONSIBLE],
        },
        {
          text: this.$t("taskManagement.list.participants"),
          value: this.filtersMixins_dataFields.MEMBERS,
          ...actions[EDIT_MEMBERS],
        },
        {
          text: this.$t("common.project"),
          value: this.filtersMixins_dataFields.PROJECT,
          access: !this.projectId,
          ...actions[EDIT_PROJECT],
        },
        {
          text: this.$t("taskManagement.list.belongsToFormItem"),
          value: this.filtersMixins_dataFields.BELONGS_TO_FORM_ITEM,
          disabled: true,
        },
        {
          preset: "DATE",
          text: this.$t("taskManagement.list.startDate"),
          value: this.filtersMixins_dataFields.START_DATE,
          ...actions[EDIT_START_DATE],
        },
        {
          preset: "DATE",
          text: this.$t("taskManagement.list.dueDate"),
          value: this.filtersMixins_dataFields.DUE_DATE,
          ...actions[EDIT_DUE_DATE],
        },
        {
          preset: "MENU",
          value: this.filtersMixins_dataFields.MENU,
        },
      ]);
    },
    actionConfigs() {
      const {
        DELETE,
        COPY_URL,
        OVERVIEW,
        EDIT_STATUS,
        EDIT_RESPONSIBLE,
        EDIT_MEMBERS,
        EDIT_START_DATE,
        EDIT_DUE_DATE,
        EDIT_PROJECT,
      } = this.$constant.ACTION_KEYS;

      const withMenuContext = (clickHandler) => (e) => {
        clickHandler({
          direction: e.hasSubMenu ? "right" : "top",
          parentMenuRef: e.hasSubMenu ? this.$refs.contextMenu : null,
          ...e,
        });
      };

      const actions = this.actions;

      const customActions = [
        {
          key: OVERVIEW,
          on: {
            click: actions[OVERVIEW].click,
          },
        },
        {
          key: EDIT_STATUS,
          props: {
            disabled: actions[EDIT_STATUS].disabled,
          },
          on: {
            click: withMenuContext(actions[EDIT_STATUS].click),
          },
        },
        {
          key: COPY_URL,
          on: {
            click: actions[COPY_URL].click,
          },
        },
        {
          key: EDIT_RESPONSIBLE,
          props: {
            disabled: actions[EDIT_RESPONSIBLE].disabled,
          },
          on: {
            click: withMenuContext(actions[EDIT_RESPONSIBLE].click),
          },
        },
        {
          key: EDIT_MEMBERS,
          props: {
            disabled: actions[EDIT_MEMBERS].disabled,
          },

          on: {
            click: withMenuContext(actions[EDIT_MEMBERS].click),
          },
        },
        {
          key: EDIT_START_DATE,
          props: {
            disabled: actions[EDIT_START_DATE].disabled,
          },
          on: {
            click: withMenuContext(actions[EDIT_START_DATE].click),
          },
        },
        {
          key: EDIT_DUE_DATE,
          props: {
            disabled: actions[EDIT_DUE_DATE].disabled,
          },

          on: {
            click: withMenuContext(actions[EDIT_DUE_DATE].click),
          },
        },
        {
          key: EDIT_PROJECT,
          props: {
            disabled: actions[EDIT_PROJECT].disabled,
          },

          on: {
            click: withMenuContext(actions[EDIT_PROJECT].click),
          },
        },
        {
          key: DELETE,
          props: {
            disabled: actions[DELETE].disabled,
          },

          on: {
            click: actions[DELETE].click,
          },
          divider: true,
        },
      ];

      const config = this.$constant.generateActionConfig({
        primaryActions: customActions,
        secondaryActions: this.$constant.TASK_ACTIONS,
      });

      return config;
    },
  },
  methods: {
    canEditTasks({ items }) {
      return this.permissionMixins_canEditItems({ items });
    },
    canDeleteTasks({ items }) {
      return this.permissionMixins_canDeleteItems({ items });
    },
    openEditHeadersDialog() {
      this.$refs.dataTable.openEditHeadersDialog();
    },
    openEditMenu(e) {
      const { items, menuRef } = e;
      this.$refs[menuRef].open(e);
      this.itemsToEdit = items;
    },
    onCopyUrl({ items }) {
      const route = {
        name: this.$routeNames.TASK_VIEW.ROOT,
        params: { taskId: items[0].id },
      };

      const taskUrl = this.$router.resolve(route).href;

      const fullPath = `${window.location.origin}${taskUrl}`;

      navigator.clipboard.writeText(fullPath);

      this.$store.dispatch("snackbar/snackbar", {
        show: true,
        text: this.$t("common.copied"),
        color: "success",
      });
    },
    onContextMenuChange(isOpen) {
      if (!isOpen) {
        this.contextMenuActiveItem = null;
      }
    },
    isContextMenuActiveItem(item) {
      return this.contextMenuActiveItem?.id === item?.id;
    },
    async getAllItems() {
      return await getAllTasks({
        filters: {
          ...this.filtersMixins_filters,
          ...(this.projectId ? { projectId: this.projectId } : {}),
        },
      });
    },
    headerTitle(group) {
      const commonHeaderTitle = this.groupByMixins_commonHeaderTitle(group);
      if (commonHeaderTitle) return commonHeaderTitle;
      return group;
    },
    isActiveTask(task) {
      const isTaskIdInParams = task?.id === this.activeTask?.id;
      return isTaskIdInParams;
    },
    openContextMenu({ from, task, direction }) {
      this.$refs.contextMenu.open({ from, direction });
      this.$nextTick(() => {
        this.contextMenuActiveItem = task;
      });
    },
    onTaskDetailsClick({ items }) {
      this.$emit("task:details", { item: items[0] });
    },
    onTasksUpdate(value) {
      const body = { ...value, ids: this.itemsToEdit.map((item) => item.id) };
      this.$emit("tasks:update", { body });
    },
    onTasksDelete({ items }) {
      this.dialog.deleteTask.item = items;
      this.dialog.deleteTask.active = true;
    },
    deleteTasks() {
      this.$emit("tasks:delete", { tasks: this.dialog.deleteTask.item });
    },
  },
};
</script>
