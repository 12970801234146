<template>
  <div>
    <div class="text-subtitle-1 font-weight-bold mb-3">
      {{ $t("settings.corporateManagement.giveAccess") }}
    </div>
    <div
      class="d-flex flex-column"
      style="gap: 16px"
    >
      <AppDefaultWideCard
        v-for="(card, key) in accessCards"
        :key="key"
        :title="t.dialog(`sections.accessControl.cards.${key}.title`)"
        :sub-title="t.dialog(`sections.accessControl.cards.${key}.subtitle`)"
        :fa-icon="card.icon"
      >
        <AppDefaultSwitch
          :disabled="!canEditCorporateManagement"
          class="mt-0"
          :input-value="accessCards[key].value"
          @change="onPermissionChange(key, $event)"
        />
      </AppDefaultWideCard>
    </div>
  </div>
</template>

<script>
import EmptyState from "@/components/common/filePicker/EmptyState.vue";
import { permissionMixins } from "@/helpers/mixins";

export default {
  mixins: [permissionMixins],
  components: {
    EmptyState,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      accessCards: {
        templates: {
          icon: this.$icons.REGULAR.ROUTE.TEMPLATE,
          value: false,
        },
        storages: {
          icon: this.$icons.REGULAR.ROUTE.STORAGE,
          value: false,
        },
      },
    };
  },
  computed: {
    canEditCorporateManagement() {
      return this.permissionMixins_corporateManagement.edit.can;
    },
    t() {
      return {
        dialog: (key) => this.$t(`settings.corporateManagement.detailsDialog.${key}`),
      };
    },
  },
  methods: {
    async onPermissionChange(key, value) {
      this.accessCards[key].value = value;
      this.$emit("update:accessesGiven", {
        ids: [this.item.id],
        data: {
          [key]: value,
        },
      });
    },
  },
  watch: {
    "item.accessesGiven": {
      immediate: true,
      handler(newAccessesGiven) {
        if (newAccessesGiven) {
          Object.keys(this.accessCards).forEach((key) => {
            this.accessCards[key].value = newAccessesGiven[key] || false;
          });
        }
      },
    },
  },
};
</script>
