<template>
  <v-card
    v-if="isVisible"
    :max-width="maxWidth"
    :max-height="maxHeight"
    :width="width"
    :height="height"
    :class="[
      'debug-overlay position-relative',
      {
        'is-fullscreen': isFullscreen,
        'is-embedded': isEmbedded,
        'fullscreen-transition': isTransitioning,
      },
    ]"
    :rounded="!isFullscreen"
    dark
    ref="dragCard"
    @mousedown="onStartDrag"
  >
    <!-- Resize handles - Update the v-if condition -->
    <template v-if="!isFullscreen">
      <div
        v-for="direction in getAvailableResizeHandles"
        :key="direction"
        :class="['resize-handle', `resize-${direction}`]"
        @mousedown.stop="onStartResize(direction, $event)"
      ></div>
    </template>

    <div class="d-flex flex-column h-100 overflow-hidden">
      <div class="px-4 py-2">
        <div class="d-flex align-center">
          <v-btn
            v-if="activeDetail"
            icon
            small
            @click="handleBackButton"
          >
            <v-icon color="white">{{ $icons.REGULAR.COMMON.ARROW_LEFT }}</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-card-title class="pa-0">
            <span v-if="!activeDetail">Debug panel</span>
            <span v-else>{{ getDetailTitle(activeDetail) }}</span>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            class="mr-1"
            @click="toggleEmbed"
            :disabled="isFullscreen"
          >
            <v-icon color="white">
              {{
                isEmbedded
                  ? $icons.REGULAR.COMMON.ARROW_LEFT
                  : $icons.REGULAR.COMMON.ARROW_RIGHT
              }}
            </v-icon>
          </v-btn>
          <v-btn
            icon
            small
            class="mr-1"
            @click="toggleFullscreen"
            :disabled="isEmbedded"
          >
            <v-icon color="white">
              {{
                isFullscreen
                  ? $icons.REGULAR.COMMON.WINDOW_RESTORE
                  : $icons.REGULAR.COMMON.WINDOW_MAXIMIZE
              }}
            </v-icon>
          </v-btn>
          <v-btn
            icon
            small
            class="mr-1"
            @click="isVisible = false"
          >
            <v-icon color="white">{{ $icons.REGULAR.ACTION.CLOSE }}</v-icon>
          </v-btn>
        </div>
        <v-text-field
          autofocus
          v-model="mainPanelSearchQuery"
          dense
          hide-details
          clearable
          class="mx-4 mt-2"
          placeholder="Filter debug items..."
          v-if="!activeDetail"
        />
        <v-text-field
          v-if="activeDetail"
          v-model="searchQuery"
          autofocus
          dense
          hide-details
          clearable
          class="mx-4 mt-2"
          placeholder="Search..."
          @input="highlightSearch"
        />
      </div>
      <v-card-text class="flex-grow-1 overflow-hidden pa-0">
        <div class="h-100">
          <template v-if="!activeDetail">
            <div class="px-4 pb-2 overflow-y-auto h-100">
              <div
                v-for="(value, key) in filteredDebugInfo"
                :key="key"
                class="debug-item d-flex align-center"
                :class="{
                  clickable: isClickable(key),
                  'debug-link': isClickable(key),
                }"
                @click="onDebugItemClick(key)"
              >
                <div style="width: 180px">
                  <span class="debug-key">{{ getDetailTitle(key) }}:</span>
                </div>
                <div class="d-flex align-center">
                  <span>{{ value }}</span>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="pa-4 h-100">
              <pre
                class="debug-text overflow-y-auto h-100"
                v-html="highlightedContent"
                contenteditable="true"
                @keydown.tab.prevent="handleTab"
              ></pre>
            </div>
          </template>
        </div>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import { getInstance } from "@/auth";
import { permissionHelpers } from "@/helpers/util";
import { mapGetters, mapState } from "vuex";

export default {
  name: "DebugOverlay",

  data() {
    return {
      isVisible: this.getSavedValue("debugOverlayVisible", false),
      activeDetail: this.getSavedValue("debugOverlayActiveDetail", null),
      searchQuery: this.getSavedValue("debugOverlaySearchQuery", "", true),
      mainPanelSearchQuery: this.getSavedValue("debugOverlayMainSearchQuery", "", true),
      resizeCheckInterval: null,
      width: this.getSavedValue(
        this.isEmbedded ? "debugOverlayEmbeddedWidth" : "debugOverlayWidth",
        600,
      ),
      height: this.getSavedValue("debugOverlayHeight", 400),
      maxWidth: window.innerWidth,
      maxHeight: window.innerHeight,
      minWidth: 300,
      minHeight: 200,
      isFullscreen: this.getSavedValue("debugOverlayFullscreen", false),
      previousSize: this.getSavedPreviousSize(),
      highlightedContent: "",
      isTransitioning: false,
      isEmbedded: this.getSavedValue("debugOverlayEmbedded", false),
    };
  },

  computed: {
    ...mapGetters("auth", {
      user: "user",
      userInDomain: "userInDomain",
      currentDomain: "currentDomain",
      subscriptionPlan: "subscriptionPlan",
    }),
    ...mapState("permissions", {
      permissionsState: "permissions",
    }),
    debugInfo() {
      return {
        // API/Swagger access information (grouped together for convenience)
        auth_token: "Copy auth token",
        domain_id: "Copy domain ID",

        // User information
        user: this.user?.email,
        role: this.userInDomain?.role.type,

        // Domain information
        domain: this.currentDomain?.name,

        // Subscription information
        plan: this.subscriptionPlan?.plan,

        // Permissions information
        permissions_for_route: "",
        permissions_state: "",

        // System information
        environment: process.env.NODE_ENV,
        version: require("@/../package.json").version,
      };
    },

    detailContent() {
      switch (this.activeDetail) {
        case "auth_token": {
          const authService = getInstance();
          return authService.isAuthenticated ? authService.getTokenSilently() : null;
        }
        case "domain_id": {
          return this.currentDomain?.id || null;
        }
        case "permissions_for_route": {
          if (!this.$route?.name) return null;
          const permissionForRoute = permissionHelpers.getPermissionsForRoute(
            this.$route.name,
          );
          return permissionForRoute || null;
        }
        case "user": {
          if (!this.userInDomain || !this.user) return null;
          const userInDomain = { ...this.userInDomain };
          if (userInDomain.permissions) {
            delete userInDomain.permissions;
          }

          return {
            user: this.user,
            userInDomain: userInDomain,
          };
        }
        case "domain": {
          if (!this.currentDomain) return null;
          return {
            domain: this.currentDomain,
          };
        }
        case "permissions_state": {
          return this.permissionsState || null;
        }
        default:
          return null;
      }
    },

    formattedDetailContent() {
      return JSON.stringify(this.detailContent, null, 2);
    },

    getAvailableResizeHandles() {
      if (this.isEmbedded) {
        return ["w"];
      }
      return ["w", "e", "n", "s", "nw", "ne", "sw", "se"];
    },

    filteredDebugInfo() {
      if (!this.mainPanelSearchQuery) {
        return this.debugInfo;
      }

      const searchTerm = this.mainPanelSearchQuery.toLowerCase();
      const result = {};

      Object.entries(this.debugInfo).forEach(([key, value]) => {
        const keyMatches = key.toLowerCase().includes(searchTerm);
        const valueMatches = String(value).toLowerCase().includes(searchTerm);

        if (keyMatches || valueMatches) {
          result[key] = value;
        }
      });

      return result;
    },
  },

  mounted() {
    window.addEventListener("keydown", this.handleDebugKeypress);

    this.$nextTick(() => {
      this.ensureWithinBounds();
      this.startSizeMonitoring();
    });
  },

  beforeDestroy() {
    window.removeEventListener("keydown", this.handleDebugKeypress);

    this.stopSizeMonitoring();
    document.body.style.paddingRight = "0px";
  },

  methods: {
    getDetailTitle(title) {
      return this.$options.filters.capitalize(title).replace(/_/g, " ");
    },
    handleDebugKeypress(event) {
      // Check for pipe key
      if (event.key === "|") {
        // Prevent the character from being written
        event.preventDefault();

        // Toggle visibility regardless of input focus
        this.isVisible = !this.isVisible;
        // Save visibility state
        localStorage.setItem("debugOverlayVisible", JSON.stringify(this.isVisible));
        return;
      }
    },

    isClickable(key) {
      return [
        "auth_token",
        "domain_id",
        "permissions_for_route",
        "user",
        "domain",
        "permissions_state",
      ].includes(key);
    },

    async onDebugItemClick(key) {
      if (this.isClickable(key)) {
        if (key === "auth_token") {
          const authService = getInstance();
          if (authService.isAuthenticated) {
            const token = await authService.getTokenSilently();
            navigator.clipboard.writeText(token);
            this.$store.dispatch("snackbar/snackbar", {
              show: true,
              text: "Auth token copied to clipboard",
              timeout: 2000,
              color: "success",
            });
          }
          return;
        }
        if (key === "domain_id") {
          if (this.currentDomain?.id) {
            navigator.clipboard.writeText(this.currentDomain.id);
            this.$store.dispatch("snackbar/snackbar", {
              show: true,
              text: "Domain ID copied to clipboard",
              timeout: 2000,
              color: "success",
            });
          }
          return;
        }
        this.activeDetail = key;
        localStorage.setItem("debugOverlayActiveDetail", JSON.stringify(key));
        // Don't reset search when changing detail view
        this.highlightedContent = this.escapeHtml(this.formattedDetailContent);
        this.highlightSearch(); // Re-apply search highlighting
      }
    },

    handleBackButton() {
      this.activeDetail = null;
      localStorage.setItem("debugOverlayActiveDetail", "null");
      // Clear search when going back
      this.searchQuery = "";
      localStorage.removeItem("debugOverlaySearchQuery");
    },

    onStartDrag(e) {
      // Don't allow dragging in fullscreen or embedded mode
      if (this.isFullscreen || this.isEmbedded) return;

      // Don't initiate drag if clicking inside pre or debug-text-container
      if (
        e.target.closest(".debug-text-container, pre") ||
        e.target.closest('button, input, a, [role="button"]')
      ) {
        return;
      }

      const el = this.$refs.dragCard.$el;
      el.style.cursor = "grabbing";

      const initialX = e.clientX - el.offsetLeft;
      const initialY = e.clientY - el.offsetTop;

      const onMouseMove = (e) => {
        const newX = e.clientX - initialX;
        const newY = e.clientY - initialY;

        // Get window and element dimensions
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const elementWidth = el.offsetWidth;
        const elementHeight = el.offsetHeight;

        // Calculate bounds
        const maxX = windowWidth - elementWidth;
        const maxY = windowHeight - elementHeight;

        // Constrain position within window bounds
        const boundedX = Math.min(Math.max(0, newX), maxX);
        const boundedY = Math.min(Math.max(0, newY), maxY);

        el.style.left = boundedX + "px";
        el.style.top = boundedY + "px";
        el.style.right = "auto";
        el.style.bottom = "auto";
      };

      const onMouseUp = () => {
        el.style.cursor = "grab";
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
        this.savePosition();
      };

      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    },

    ensureWithinBounds() {
      const el = this.$refs.dragCard?.$el;
      if (!el) return;

      // Get current positions and dimensions
      const rect = el.getBoundingClientRect(); // More accurate than offset values
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      // Calculate bounds
      const maxX = windowWidth - rect.width;
      const maxY = windowHeight - rect.height;

      // Constrain position
      const boundedX = Math.min(Math.max(0, rect.left), maxX);
      const boundedY = Math.min(Math.max(0, rect.top), maxY);

      // Only update if position needs to change
      if (boundedX !== rect.left || boundedY !== rect.top) {
        el.style.left = boundedX + "px";
        el.style.top = boundedY + "px";
        el.style.right = "auto";
        el.style.bottom = "auto";
      }
    },

    startSizeMonitoring() {
      // Clear any existing interval
      if (this.resizeCheckInterval) {
        clearInterval(this.resizeCheckInterval);
      }

      // Check size changes every 100ms
      this.resizeCheckInterval = setInterval(() => {
        this.ensureWithinBounds();
      }, 100);

      // Use ResizeObserver if available
      if (window.ResizeObserver) {
        this.resizeObserver = new ResizeObserver(() => {
          this.ensureWithinBounds();
        });

        if (this.$refs.dragCard?.$el) {
          this.resizeObserver.observe(this.$refs.dragCard.$el);
        }
      }

      // Also watch for DOM mutations
      this.mutationObserver = new MutationObserver(() => {
        this.$nextTick(this.ensureWithinBounds);
      });

      if (this.$refs.dragCard?.$el) {
        this.mutationObserver.observe(this.$refs.dragCard.$el, {
          childList: true,
          subtree: true,
          attributes: true,
          characterData: true,
        });
      }

      // Simplified window resize listener
      window.addEventListener("resize", () => {
        this.maxWidth = window.innerWidth;
        this.maxHeight = window.innerHeight;

        // If in fullscreen, maintain full window size
        if (this.isFullscreen) {
          this.width = window.innerWidth;
          this.height = window.innerHeight;
        }

        // Restore saved position
        const el = this.$refs.dragCard?.$el;
        if (el && !this.isFullscreen && !this.isEmbedded) {
          const savedLeft = localStorage.getItem("debugOverlayLeft");
          const savedTop = localStorage.getItem("debugOverlayTop");

          if (savedLeft && savedTop) {
            el.style.left = savedLeft;
            el.style.top = savedTop;
          }
        }

        this.ensureWithinBounds();
      });
    },

    stopSizeMonitoring() {
      if (this.resizeCheckInterval) {
        clearInterval(this.resizeCheckInterval);
      }
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
      if (this.mutationObserver) {
        this.mutationObserver.disconnect();
      }
      window.removeEventListener("resize", this.handleWindowResize);
    },

    onStartResize(direction, e) {
      // Don't allow resizing in fullscreen mode
      if (this.isFullscreen) return;

      e.preventDefault();
      const el = this.$refs.dragCard.$el;
      const startX = e.clientX;
      const startY = e.clientY;
      const startWidth = el.offsetWidth;
      const startHeight = el.offsetHeight;
      const startLeft = el.offsetLeft;
      const startTop = el.offsetTop;

      const onMouseMove = (e) => {
        let newWidth = startWidth;
        let newHeight = startHeight;
        let newLeft = startLeft;
        let newTop = startTop;

        // Calculate changes based on direction
        switch (direction) {
          case "e":
            newWidth = startWidth + (e.clientX - startX);
            break;
          case "w":
            newWidth = startWidth - (e.clientX - startX);
            newLeft = startLeft + (e.clientX - startX);
            break;
          case "n":
            newHeight = startHeight - (e.clientY - startY);
            newTop = startTop + (e.clientY - startY);
            break;
          case "s":
            newHeight = startHeight + (e.clientY - startY);
            break;
          case "nw":
            newWidth = startWidth - (e.clientX - startX);
            newHeight = startHeight - (e.clientY - startY);
            newLeft = startLeft + (e.clientX - startX);
            newTop = startTop + (e.clientY - startY);
            break;
          case "ne":
            newWidth = startWidth + (e.clientX - startX);
            newHeight = startHeight - (e.clientY - startY);
            newTop = startTop + (e.clientY - startY);
            break;
          case "sw":
            newWidth = startWidth - (e.clientX - startX);
            newHeight = startHeight + (e.clientY - startY);
            newLeft = startLeft + (e.clientX - startX);
            break;
          case "se":
            newWidth = startWidth + (e.clientX - startX);
            newHeight = startHeight + (e.clientY - startY);
            break;
        }

        // Constrain dimensions
        newWidth = Math.min(Math.max(this.minWidth, newWidth), this.maxWidth);
        newHeight = Math.min(Math.max(this.minHeight, newHeight), this.maxHeight);

        // Update dimensions
        this.width = newWidth;
        if (!this.isEmbedded) {
          this.height = newHeight;
        }

        // Update position if needed
        if (!this.isEmbedded) {
          if (["w", "nw", "sw"].includes(direction)) {
            el.style.left = `${newLeft}px`;
          }
          if (["n", "nw", "ne"].includes(direction)) {
            el.style.top = `${newTop}px`;
          }
        }

        // Update body padding when embedded
        if (this.isEmbedded) {
          document.body.style.paddingRight = `${newWidth}px`;
        }
      };

      const onMouseUp = () => {
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
        document.body.style.cursor = "";
        this.savePosition();
      };

      // Set appropriate cursor
      const cursors = {
        n: "n-resize",
        s: "s-resize",
        e: "e-resize",
        w: "w-resize",
        nw: "nw-resize",
        ne: "ne-resize",
        sw: "sw-resize",
        se: "se-resize",
      };
      document.body.style.cursor = cursors[direction];

      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    },

    highlightSearch() {
      if (!this.formattedDetailContent) return;

      // Save search query to localStorage
      localStorage.setItem("debugOverlaySearchQuery", this.searchQuery);

      if (!this.searchQuery) {
        this.highlightedContent = this.escapeHtml(this.formattedDetailContent);
        return;
      }

      try {
        const escapedQuery = this.searchQuery.toLowerCase();
        const contentObj = JSON.parse(this.formattedDetailContent);
        const filteredObj = this.filterObject(contentObj, escapedQuery);

        if (filteredObj === undefined) {
          this.highlightedContent = "No matches found";
          return;
        }

        const filteredContent = JSON.stringify(filteredObj, null, 2);
        const escapedContent = this.escapeHtml(filteredContent);

        // Make the regex case-insensitive and global
        const safeQuery = escapedQuery.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const regex = new RegExp(safeQuery, "gi");

        this.highlightedContent = escapedContent.replace(
          regex,
          (match) => `<mark class="debug-highlight">${match}</mark>`,
        );
      } catch (error) {
        console.error("Error processing search:", error);
        this.highlightedContent = this.escapeHtml(this.formattedDetailContent);
      }
    },

    filterObject(obj, searchTerm) {
      // Check if searchTerm looks like a UUID
      const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
      const isUuidSearch = uuidRegex.test(searchTerm);

      if (typeof obj !== "object" || obj === null) {
        const stringValue = String(obj).toLowerCase();
        return stringValue.includes(searchTerm) ? obj : undefined;
      }

      if (Array.isArray(obj)) {
        if (isUuidSearch) {
          // For UUID searches, only return the specific matching object
          const matchingItem = obj.find((item) =>
            JSON.stringify(item).toLowerCase().includes(searchTerm.toLowerCase()),
          );
          return matchingItem ? [matchingItem] : undefined;
        } else {
          // Normal search behavior for non-UUID searches
          const filtered = obj
            .map((item) => this.filterObject(item, searchTerm))
            .filter((item) => item !== undefined);
          return filtered.length ? filtered : undefined;
        }
      }

      // For objects during UUID search, only return if this exact object contains the UUID
      if (isUuidSearch) {
        return JSON.stringify(obj).toLowerCase().includes(searchTerm.toLowerCase())
          ? obj
          : undefined;
      }

      const result = {};
      let hasMatch = false;

      for (const [key, value] of Object.entries(obj)) {
        const stringKey = String(key).toLowerCase();
        if (stringKey.includes(searchTerm)) {
          result[key] = value;
          hasMatch = true;
          continue;
        }

        const filteredValue = this.filterObject(value, searchTerm);
        if (filteredValue !== undefined) {
          result[key] = filteredValue;
          hasMatch = true;
        }
      }

      return hasMatch ? result : undefined;
    },

    escapeHtml(text) {
      return text
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
    },

    getSavedValue(key, defaultValue, isString = false) {
      const saved = localStorage.getItem(key);
      if (saved === null) return defaultValue;

      if (isString) return saved;

      try {
        return JSON.parse(saved);
      } catch {
        return defaultValue;
      }
    },

    getSavedPreviousSize() {
      const savedSize = localStorage.getItem("debugOverlayPreviousSize");
      return savedSize ? JSON.parse(savedSize) : null;
    },

    savePosition() {
      const el = this.$refs.dragCard?.$el;
      if (!el) return;

      // Save position and dimensions based on mode
      if (this.isEmbedded) {
        localStorage.setItem("debugOverlayEmbeddedWidth", this.width);
      } else {
        localStorage.setItem("debugOverlayWidth", this.width);
        localStorage.setItem("debugOverlayHeight", this.height);
        localStorage.setItem("debugOverlayLeft", el.style.left);
        localStorage.setItem("debugOverlayTop", el.style.top);
      }

      localStorage.setItem("debugOverlayFullscreen", JSON.stringify(this.isFullscreen));

      if (this.previousSize) {
        localStorage.setItem(
          "debugOverlayPreviousSize",
          JSON.stringify(this.previousSize),
        );
      }
    },

    restorePosition() {
      const el = this.$refs.dragCard?.$el;
      if (!el) return;

      if (this.isEmbedded) {
        // Get saved embedded width or default to 400
        const savedEmbeddedWidth = parseInt(
          localStorage.getItem("debugOverlayEmbeddedWidth") || "400",
          10,
        );
        this.width = savedEmbeddedWidth;
        document.body.style.paddingRight = `${savedEmbeddedWidth}px`;
      } else if (this.isFullscreen) {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        el.style.left = "0px";
        el.style.top = "0px";
      } else {
        const left = localStorage.getItem("debugOverlayLeft");
        const top = localStorage.getItem("debugOverlayTop");
        if (left && top) {
          el.style.left = left;
          el.style.top = top;
        }
      }
    },

    toggleFullscreen() {
      if (this.isEmbedded) return;
      const el = this.$refs.dragCard.$el;
      this.isTransitioning = true;

      if (this.isFullscreen) {
        // Restore previous size and position
        if (this.previousSize) {
          const { width, height, left, top } = this.previousSize;
          this.width = width;
          this.height = height;
          el.style.left = left;
          el.style.top = top;
        }
      } else {
        // Save current size and position
        this.previousSize = {
          width: this.width,
          height: this.height,
          left: el.style.left,
          top: el.style.top,
        };

        // Set to full screen
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        el.style.left = "0px";
        el.style.top = "0px";
      }

      this.isFullscreen = !this.isFullscreen;
      this.savePosition();

      // Remove transition class after animation
      setTimeout(() => {
        this.isTransitioning = false;
      }, 300);
    },

    toggleEmbed() {
      if (this.isFullscreen) return;

      this.isTransitioning = true;

      if (!this.isEmbedded) {
        // Save current position before embedding
        const el = this.$refs.dragCard.$el;
        this.previousSize = {
          width: this.width,
          height: this.height,
          left: el.style.left,
          top: el.style.top,
        };
        // Switch to embedded width
        this.width = parseInt(
          localStorage.getItem("debugOverlayEmbeddedWidth") || "400",
          10,
        );
      } else {
        // Restore previous position and normal mode width
        if (this.previousSize) {
          const { width, height, left, top } = this.previousSize;
          this.width = width;
          this.height = height;
          const el = this.$refs.dragCard.$el;
          el.style.left = left;
          el.style.top = top;
        }
      }

      this.isEmbedded = !this.isEmbedded;

      if (this.isEmbedded) {
        document.body.style.paddingRight = `${this.width}px`;
      } else {
        document.body.style.paddingRight = "0px";
      }

      localStorage.setItem("debugOverlayEmbedded", JSON.stringify(this.isEmbedded));

      setTimeout(() => {
        this.isTransitioning = false;
      }, 300);
    },

    handleTab(event) {
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const tabNode = document.createTextNode("\t");
      range.insertNode(tabNode);
      range.setStartAfter(tabNode);
      range.setEndAfter(tabNode);
      selection.removeAllRanges();
      selection.addRange(range);
    },
  },

  directives: {
    drag: {
      mounted(el) {
        el.style.cursor = "move";
        el.onmousedown = function (e) {
          const initialX = e.clientX - el.offsetLeft;
          const initialY = e.clientY - el.offsetTop;

          document.onmousemove = function (e) {
            el.style.left = e.clientX - initialX + "px";
            el.style.top = e.clientY - initialY + "px";
            el.style.right = "auto";
            el.style.bottom = "auto";
          };

          document.onmouseup = function () {
            document.onmousemove = null;
            document.onmouseup = null;
          };
        };
      },
    },
  },

  watch: {
    isVisible: {
      handler(newVal) {
        // Save visibility state whenever it changes
        localStorage.setItem("debugOverlayVisible", JSON.stringify(newVal));

        if (newVal) {
          this.$nextTick(() => {
            this.restorePosition();
            this.ensureWithinBounds();
            this.startSizeMonitoring();
          });
        } else {
          this.stopSizeMonitoring();
          // Reset padding if panel was embedded when closed
          if (this.isEmbedded) {
            document.body.style.paddingRight = "0px";
          }
        }
      },
      immediate: true,
    },

    detailContent: {
      immediate: true,
      handler() {
        this.highlightSearch();
      },
    },
  },
};
</script>

<style>
/* Remove scoped to allow styling of dynamically inserted content */
.debug-highlight {
  background-color: rgba(159, 112, 228, 0.3) !important; /* #9f70e4 with opacity */
  border-bottom: 2px solid #9f70e4 !important;
  color: white !important;
  font-weight: bold !important;
  padding: 2px !important;
  border-radius: 2px !important;
}
</style>

<style scoped>
.debug-overlay {
  position: fixed !important;
  z-index: 203;
  bottom: 16px;
  right: 16px;
  background-color: rgba(33, 33, 33, 0.95) !important;
  color: white !important;
  user-select: none;
  cursor: grab;
  display: flex;
  flex-direction: column;
}

.debug-overlay.is-fullscreen,
.debug-overlay.is-embedded {
  cursor: default !important;
  border-radius: 0 !important;
}

.debug-overlay.is-embedded {
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: auto !important;
  height: 100vh !important;
  cursor: default !important;
  border-radius: 0 !important;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2) !important;
  transform: none !important;
}

.debug-overlay.fullscreen-transition {
  transition: all 0.3s ease !important;
}

.resize-handle {
  position: absolute;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.15);
  transition: background-color 0.2s ease;
}

/* Edge handles */
.resize-e,
.resize-w {
  top: 0;
  bottom: 0;
  width: 12px;
  cursor: ew-resize;
}

.resize-n,
.resize-s {
  left: 0;
  right: 0;
  height: 12px;
  cursor: ns-resize;
}

.resize-w {
  left: -4px;
}
.resize-e {
  right: -4px;
}
.resize-n {
  top: -4px;
}
.resize-s {
  bottom: -4px;
}

/* Corner handles */
.resize-nw,
.resize-ne,
.resize-sw,
.resize-se {
  width: 16px;
  height: 16px;
}

.resize-nw {
  top: -6px;
  left: -6px;
  cursor: nw-resize;
}
.resize-ne {
  top: -6px;
  right: -6px;
  cursor: ne-resize;
}
.resize-sw {
  bottom: -6px;
  left: -6px;
  cursor: sw-resize;
}
.resize-se {
  bottom: -6px;
  right: -6px;
  cursor: se-resize;
}

/* Hover effect for resize handles */
.resize-handle:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.debug-text-container {
  max-height: 100%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  cursor: text;
}

.debug-text {
  white-space: pre-wrap;
  font-family: monospace;
  font-size: 14px;
  color: white !important;
  margin: 0;
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  cursor: text;
  user-select: text !important;
  height: 100%;
  overflow-y: auto;
}

.debug-text * {
  user-select: text !important;
  cursor: text;
}

.debug-key-column {
  width: 50%;
  padding-right: 8px;
}

.debug-value-column {
  width: 50%;
  padding-left: 8px;
}

.debug-item {
  padding: 4px 0;
  font-family: monospace;
  font-size: 14px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.debug-item:last-child {
  border-bottom: none;
}

.debug-key {
  font-weight: bold;
}

.clickable {
  cursor: pointer;
  transition: all 0.2s;
}

.clickable:hover {
  color: #96ebfb !important;
}

.debug-link {
  color: #3ab8ea !important;
}

.highlight {
  background-color: rgba(255, 255, 0, 0.3);
  color: white;
  font-weight: bold;
}
</style>
