<template>
  <td
    v-ripple="!isDisabled"
    :class="cellClasses"
    :ref="cellRef"
    @click.stop="handleClick"
  >
    <div :class="contentClasses">
      <template v-if="isSelectCell">
        <AppDefaultCheckboxIcon
          :isSelected="isSelected"
          :disabled="isDisabled"
        />
      </template>
      <slot v-else></slot>
    </div>
  </td>
</template>

<script>
export default {
  name: "AppDefaultTableCell",
  props: {
    header: {
      type: Object,
      required: true,
    },
    index: {
      type: [String, Number],
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    select: {
      type: Function,
      default: null,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  computed: {
    cellRef() {
      return `header-${this.index}`;
    },
    isSelectCell() {
      return this.header.value === "data-table-select";
    },
    hasAccess() {
      const access = this.header.access;
      if (typeof access === "function") {
        return access({ items: [this.item] });
      }
      return access;
    },
    isDisabled() {
      const disabled = this.header.disabled;
      if (typeof disabled === "function") {
        return disabled({ items: [this.item] });
      }
      return disabled;
    },
    isCompact() {
      return this.header.compact;
    },
    contentAlignment() {
      if (this.header.align === "center") return "justify-center";
      if (this.header.align === "end") return "justify-end";
      return "justify-start";
    },
    contentClasses() {
      return {
        "d-flex": true,
        "align-center": true,
        [this.contentAlignment]: true,
      };
    },
    cellClasses() {
      return {
        "table-cell--hover-effect": !this.isDisabled,
        "table-cell--compact": this.isCompact,
        "underline-on-hover": this.header.value === "name" && !this.isDisabled,
      };
    },
  },
  methods: {
    handleClick(e) {
      if (this.isDisabled) return;

      if (this.isSelectCell) {
        this.select(!this.isSelected);
        return;
      }

      this.header.click?.({
        from: this.$refs[this.cellRef],
        items: [this.item],
        direction: "bottom",
      });

      // this.$emit("click", {
      //   from: this.$refs[this.cellRef],
      //   header: this.header,
      //   item: this.item,
      // });
    },
  },
};
</script>
