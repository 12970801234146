<template>
  <div class="p-relative pt-2">
    <!-- Main components -->
    <AppResourceViewContextMenu
      ref="contextMenu"
      :selected-resource-view="selectedResourceView"
      :model="model"
      @delete-confirmed="onDeleteConfirmed"
      @open-rename-dialog="openRenameDialog"
    />

    <AppResourceViewTabList
      ref="tabList"
      :resource-views="sortedResourceViews"
      :active-resource-view-id="activeResourceViewId"
      :model="model"
      @tab-click="onTabClick"
      @context-menu="onContextMenu"
      @drag-end="onDragEnd"
      @create-view="createNewView"
    />

    <AppResourceViewDialog
      v-model="dialogData"
      :is-updating="isUpdating"
      @save="saveResourceViewDialog"
    />

    <!-- Unsaved changes snackbar -->
    <AppResourceViewUnsavedChangesSnackbar
      v-if="activeResourceViewId"
      :resource-view-id="activeResourceViewId"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "AppResourceViewTabs",
  props: {
    model: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selectedResourceView: null,
      sortedResourceViews: [],
      dialogData: {
        show: false,
        name: "",
        editId: null,
      },
      componentActive: true,
    };
  },

  computed: {
    ...mapState("resourceViews", {
      resourceViews: (state) => state.resourceViews,
      isLoading: (state) => state.isLoading,
      isCreating: (state) => state.isCreating,
      isUpdating: (state) => state.isUpdating,
    }),
    ...mapGetters("resourceViews", {
      getResourceViewById: "getResourceViewById",
      hasUnsavedChanges: "hasUnsavedChanges",
    }),
    ...mapGetters("auth", ["currentUserId", "activeDomainId"]),
    activeResourceViewId() {
      return this.$route.query.resourceViewId;
    },
    activeResourceViewData() {
      return this.getResourceViewById(this.activeResourceViewId);
    },
    localStorageKey() {
      return `resourceViews_${this.currentUserId}_${this.activeDomainId}_${this.model}`;
    },
  },

  watch: {
    resourceViews: {
      handler(newViews) {
        this.sortedResourceViews = [...newViews];
        if (newViews.length > 0) {
          this.saveToLocalStorage();

          // Check if the current resource view ID exists in the fetched views
          if (
            this.activeResourceViewId &&
            !this.getResourceViewById(this.activeResourceViewId)
          ) {
            // If not found, redirect to the first available view
            if (newViews.length > 0) {
              this.updateRoute({ resourceViewId: newViews[0].id });
            }
          }
        }
      },
    },
    activeResourceViewId: {
      async handler(newViewId, oldViewId) {
        //Handles updating data or initial fetching of data
        if (newViewId !== oldViewId && newViewId) {
          //Save the current view to local storage
          this.saveToLocalStorage();
          //Clear scoped filters
          this.$store.commit("resourceViews/setScopedFilters", {});
          //get new resource view from store
          await this.$store.dispatch("resourceViews/getUnsavedConfig", {
            resourceViewId: newViewId,
          });
          if (this.hasUnsavedChanges) {
            this.$store.dispatch("resourceViews/discardUnsavedChanges");
          }
          this.updateRoute({ resourceViewId: newViewId, page: 1 });
          //Re-fetch data
          this.$root.$emit("refresh-view-data");
        }
      },
      immediate: true,
    },
  },
  methods: {
    // Tab handling with unsaved changes check
    async onTabClick(tabId) {
      if (tabId !== this.activeResourceViewId) {
        this.updateRoute({ resourceViewId: tabId, page: 1 });
      }
    },

    saveToLocalStorage() {
      const data = {
        lastViewId: this.activeResourceViewId,
        views: this.resourceViews.map((view) => ({
          id: view.id,
          name: view.name,
          isPrivate: view.isPrivate,
          permissions: view.permissions,
        })),
      };
      localStorage.setItem(this.localStorageKey, JSON.stringify(data));
    },

    getFromLocalStorage() {
      try {
        const stored = localStorage.getItem(this.localStorageKey);
        if (!stored) return null;
        return JSON.parse(stored);
      } catch (error) {
        console.error("Error reading from localStorage:", error);
        return null;
      }
    },

    resetState() {
      //When the component is mounted,
      //we need to reset the unsaved config and scoped filters
      //to avoid having them carry over from a previous view (ex when scope changes)
      this.$store.commit("resourceViews/setUnsavedConfig", null);
      this.$store.commit("resourceViews/setScopedFilters", {});
    },
    async onInit() {
      // Initialize component by loading views
      this.resetState();
      const cachedData = this.loadCachedViews();
      await this.fetchResourceViews();
      await this.handleViewNavigation(cachedData);
    },

    loadCachedViews() {
      // Try to get cached data first
      const cachedData = this.getFromLocalStorage();

      if (cachedData?.views?.length) {
        this.sortedResourceViews = cachedData.views;

        if (cachedData.lastViewId && !this.activeResourceViewId) {
          this.updateRoute({ resourceViewId: cachedData.lastViewId });
        }
      }

      return cachedData;
    },

    async fetchResourceViews() {
      try {
        // Only proceed if component is still active
        await this.$store.dispatch("resourceViews/getResourceViews", {
          model: this.model,
        });

        // Check if component is still active before saving
        if (this.componentActive) {
          this.saveToLocalStorage();
          this.calculateVisibleTabs();
        }
      } catch (error) {
        console.error("Error fetching resource views:", error);
      }
    },

    async handleViewNavigation(cachedData) {
      try {
        if (this.activeResourceViewId) {
          await this.handleExistingViewNavigation();
        } else if (this.resourceViews.length > 0) {
          // No active view selected but views exist
          this.updateRoute({ resourceViewId: this.resourceViews[0].id });
        }
      } catch (error) {
        console.error("Error handling view navigation:", error);
      }
    },

    async handleExistingViewNavigation() {
      const viewExists = this.resourceViews.some(
        (view) => view.id === this.activeResourceViewId,
      );

      if (!viewExists) {
        await this.handleNonExistentView();
      } else {
        // Only fetch the config if the view still exists
        await this.$store.dispatch("resourceViews/getUnsavedConfig", {
          resourceViewId: this.activeResourceViewId,
        });
      }
    },

    async handleNonExistentView() {
      // If the view doesn't exist anymore, redirect to the first available view
      if (this.resourceViews.length > 0) {
        this.updateRoute({ resourceViewId: this.resourceViews[0].id });
      } else {
        // Remove resourceViewId from query if no views are available
        const newQuery = { ...this.$route.query };
        delete newQuery.resourceViewId;
        this.$router.replace({ query: newQuery });
      }
    },

    updateRoute({ resourceViewId, page } = {}) {
      // Only update the route if it would actually change
      if (resourceViewId !== this.activeResourceViewId) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            resourceViewId,
            page,
          },
        });
      }
    },

    onContextMenu({ element, resourceView }) {
      this.selectedResourceView = resourceView;
      this.$refs.contextMenu.open(element);
    },
    async onDragEnd({ ids }) {
      await this.$store.dispatch("resourceViews/reorderResourceViews", {
        model: this.model,
        ids,
      });
    },

    async createNewView() {
      try {
        const defaultName = this.$t("views.types.TABLE");

        const newResourceView = await this.$store.dispatch(
          "resourceViews/createResourceView",
          {
            model: this.model,
            name: defaultName,
            type: "TABLE",
          },
        );

        if (newResourceView) {
          this.updateRoute({ resourceViewId: newResourceView.id });
          this.calculateVisibleTabs();
        }
      } catch (error) {
        console.error("Error creating resource view:", error);
      }
    },

    openRenameDialog(resourceView) {
      this.dialogData = {
        show: true,
        name: resourceView.name,
        editId: resourceView.id,
      };
    },

    async saveResourceViewDialog({ name, editId }) {
      try {
        await this.$store.dispatch("resourceViews/updateResourceView", {
          resourceViewId: editId,
          updates: { name },
        });

        this.dialogData.show = false;
      } catch (error) {
        console.error("Error renaming resource view:", error);
      }
    },

    async onDeleteConfirmed(resourceViewId) {
      try {
        await this.$store.dispatch("resourceViews/deleteResourceView", {
          resourceViewId,
        });

        if (resourceViewId === this.activeResourceViewId) {
          if (this.resourceViews.length > 0) {
            this.updateRoute({ resourceViewId: this.resourceViews[0].id });
          } else {
            const newQuery = { ...this.$route.query };
            delete newQuery.resourceViewId;

            this.$router.replace({
              query: newQuery,
            });
          }
        }

        this.calculateVisibleTabs();
      } catch (error) {
        console.error("Error deleting resource view:", error);
      }
    },
    calculateVisibleTabs() {
      // Force the tab list to recalculate visible tabs
      this.$nextTick(() => {
        if (
          this.$refs.tabList &&
          typeof this.$refs.tabList.calculateVisibleTabs === "function"
        ) {
          this.$refs.tabList.calculateVisibleTabs();
        }
      });
    },
  },
  mounted() {
    this.onInit();
  },
  beforeDestroy() {
    // Set flag to prevent any pending async operations from affecting localStorage
    this.componentActive = false;
  },
};
</script>
