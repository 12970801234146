<template>
  <div>
    <AppContactDialog
      v-model="dialog.updateContact.active"
      :data="dialog.updateContact.item"
      :title="$t('contact.contactPerson.updateContact.title')"
      @confirm="afterContactUpdate"
      :confirmText="$t('common.save')"
    />
    <AppDeleteConfirmationDialog
      v-model="dialog.deleteContact.active"
      :item="dialog.deleteContact.item"
      :title="
        $tc(
          'contact.contactPerson.deleteContact.title',
          dialog.deleteContact.item?.length,
        )
      "
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('contact.contactPerson.deleteContact.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="deleteContact"
    />
    <AppDataTableServerSidePagination
      ref="dataTable"
      :loading="isLoading"
      :defaultHeaders="defaultHeaders"
      :items="contacts"
      :tableModel="tableModel"
      :serverItemsLength="serverItemsLength"
      :noDataText="$t('common.noContacts')"
      :actionConfigs="actionConfigs"
      removeMutationType="contacts/removeContact"
      updateMutationType="contacts/updateContact"
    >
      <template v-slot:item="{ item, headers, select, isSelected }">
        <AppContactTableItem
          :contact="item"
          :jobTitleLabel="getJobTitleLabel(item)"
          :responsibilityLabel="getResponsibilityLabel(item)"
          :isContextMenuActiveItem="isContextMenuActiveItem(item)"
          :isSelected="isSelected"
          :select="select"
          :headers="headers"
          :dataFields="dataFields"
          @menu:click="onMenuClick"
        />
      </template>
    </AppDataTableServerSidePagination>
    <AppActivityIndicator
      :show="isInviting"
      :text="$t('users.userInvite.sendingInvite')"
    />
    <AppLabelMenu
      ref="labelMenuJobTitle"
      @label:change="onJobTitleLabelChange"
      :labelType="jobTitleLabelType"
      selectedItemLabelPath="label"
    />
    <AppLabelMenu
      ref="labelMenuResponsibility"
      @label:change="onResponsibilityLabelChange"
      :labelType="responsibilityLabelType"
      selectedItemLabelPath="relation.label"
    />
    <AppDataTableContextMenu
      v-if="!projectId"
      ref="contextMenu"
      :actionConfigs="actionConfigs"
      :items="[contextMenuActiveItem]"
      @input="onContextMenuChange"
    />
  </div>
</template>

<script>
import { permissionMixins } from "@/helpers/mixins";
import { createTableHeaders } from "@/helpers/util";
import { inviteUsers } from "@/services/auth/inviteUsers";
import { mapGetters } from "vuex";

export default {
  mixins: [permissionMixins],
  props: {
    contacts: Array,
    isLoading: Boolean,
    serverItemsLength: Number,
    tableModel: String,
  },
  data() {
    return {
      isInviting: false,
      dialog: {
        updateContact: {
          active: false,
          item: null,
        },
        deleteContact: {
          active: false,
          item: [],
        },
      },
      contextMenuActiveItem: null,
      jobTitleLabelType: this.$constant.LABEL_TYPE.JOB_TITLE,
      responsibilityLabelType: this.$constant.LABEL_TYPE.RESPONSIBILITY,
    };
  },
  computed: {
    ...mapGetters("label", {
      labelsByType: "labelsByType",
    }),
    projectId() {
      return this.$route.params.projectId;
    },
    dataFields() {
      return this.$constant.TABLE_HEADER[this.tableModel];
    },
    actions() {
      const { EDIT, INVITE, DELETE, EDIT_JOB_TITLE, EDIT_RESPONSIBILITY, EDIT_ACTIVE } =
        this.$constant.ACTION_KEYS;

      const canNotEdit = ({ items }) => !this.canEditItems({ items });
      const openMenu = (menuRef) => (e) => this.openEditMenu({ ...e, menuRef });
      const canNotInvite = ({ items }) => !this.canInvite({ items });
      const canNotDelete = ({ items }) => !this.canDeleteItems({ items });

      return {
        [EDIT_JOB_TITLE]: {
          disabled: canNotEdit,
          click: openMenu("labelMenuJobTitle"),
        },
        [EDIT_RESPONSIBILITY]: {
          disabled: canNotEdit,
          click: openMenu("labelMenuResponsibility"),
        },
        [EDIT]: {
          disabled: canNotEdit,
          click: this.onContactEdit,
        },
        [INVITE]: {
          disabled: canNotInvite,
          click: this.onInviteContactToWorkspace,
        },
        [EDIT_ACTIVE]: {
          disabled: canNotEdit,
          click: this.onContactEditIsActive,
        },
        [DELETE]: {
          disabled: canNotDelete,
          click: this.onContactDelete,
        },
      };
    },
    defaultHeaders() {
      const { EDIT_JOB_TITLE, EDIT_RESPONSIBILITY, EDIT_ACTIVE } =
        this.$constant.ACTION_KEYS;

      return createTableHeaders([
        {
          preset: "SELECT",
          access: !this.projectId,
        },
        {
          preset: "NAME",
          value: this.dataFields.NAME,
          disabled: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.customerSupplier")),
          value: this.dataFields.CLIENT,
          disabled: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("users.label.jobTitle.title")),
          value: this.dataFields.LABEL,
          compact: true,
          ...this.actions[EDIT_JOB_TITLE],
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("contact.contactPerson.label.responsibility.title"),
          ),
          access: this.projectId,
          value: this.dataFields.RELATION_LABEL,
          compact: true,
          ...this.actions[EDIT_RESPONSIBILITY],
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.phoneNumber")),
          value: this.dataFields.PHONE,
          disabled: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.email")),
          value: this.dataFields.EMAIL,
          disabled: true,
        },

        {
          text: this.$t("common.active"),
          value: this.dataFields.ACTIVE,
          compact: true,
          align: "center",
          ...this.actions[EDIT_ACTIVE],
        },
        {
          preset: "MENU",
          access: !this.projectId,
        },
      ]);
    },
    actionConfigs() {
      const { EDIT, INVITE, DELETE } = this.$constant.ACTION_KEYS;
      const actions = this.actions;
      const customActions = [
        {
          key: EDIT,
          props: { disabled: actions[EDIT].disabled },
          on: { click: actions[EDIT].click },
        },
        {
          key: INVITE,
          props: {
            disabled: actions[INVITE].disabled,
          },
          on: { click: actions[INVITE].click },
        },
        {
          key: DELETE,
          props: {
            disabled: actions[DELETE].disabled,
          },
          on: { click: actions[DELETE].click },
          divider: true,
        },
      ];
      return this.$constant.generateActionConfig({
        primaryActions: customActions,
        secondaryActions: this.$constant.CONTACT_ACTIONS,
      });
    },
  },
  methods: {
    onContactEditIsActive({ items }) {
      const item = items[0];
      this.updateContact({
        id: item.id,
        body: { isActive: !item.isActive },
      });
    },
    canEditItems({ items }) {
      return this.permissionMixins_canEditItems({ items }) && !this.projectId;
    },
    canDeleteItems({ items }) {
      return this.permissionMixins_canDeleteItems({ items }) && !this.projectId;
    },
    canInvite({ items }) {
      return items.every((item) => item?.permissions?.invite?.can) && !this.projectId;
    },
    openEditHeadersDialog() {
      this.$refs.dataTable.openEditHeadersDialog();
    },
    openEditMenu(e) {
      const { items, menuRef } = e;
      this.$refs[menuRef].open(e);
      // this.itemsToEdit = items;
    },
    onContextMenuChange(isOpen) {
      if (!isOpen) {
        this.contextMenuActiveItem = null;
      }
    },
    getResponsibilityLabel(item) {
      return this.labelsByType(this.responsibilityLabelType).find(
        (label) => label?.id === item?.relation?.labelId,
      );
    },
    getJobTitleLabel(item) {
      return this.labelsByType(this.jobTitleLabelType).find(
        (label) => label?.id === item?.labelId,
      );
    },
    getLabels() {
      this.$store.dispatch("label/getLabels", {
        type: this.jobTitleLabelType,
      });
      if (!this.projectId) return;
      this.$store.dispatch("label/getLabels", {
        type: this.responsibilityLabelType,
      });
    },
    onInviteContactToWorkspace({ items }) {
      const showSnack = ({ text, color = "success" }) => {
        this.$store.dispatch("snackbar/snackbar", {
          show: true,
          text,
          color,
        });
      };

      if (items.some((item) => !item?.email)) {
        showSnack({
          text: this.$t("common.emailIsRequiredToInvite"),
          color: "error",
        });
        return;
      }

      this.isInviting = true;

      const body = {
        users: items.map((item) => ({
          email: item.email,
          role: "CONTACT",
          contactId: item.id,
        })),
      };

      inviteUsers({ body })
        .then((data) => {
          if (!!data?.message?.key) {
            const translationKeys = {
              userAlreadyInvited: "userHasBeenReinvited",
              contactNotFound: "contactNotFound",
            };
            const translationKey = translationKeys[data.message.key];
            const path = `users.userInvite.messages.${translationKey}`;

            if (translationKey && this.$te(path)) {
              showSnack({
                text: this.$t(path),
              });
              return;
            }
          }
          showSnack({
            text: this.$t("users.userInvite.messages.inviteSent"),
          });
        })
        .finally(() => {
          this.isInviting = false;
        });
    },
    isContextMenuActiveItem(item) {
      return this.contextMenuActiveItem?.id === item?.id;
    },
    onMenuClick({ from, contact }) {
      if (this.projectId) return;
      this.$refs.contextMenu.open({ from });
      this.$nextTick(() => {
        this.contextMenuActiveItem = contact;
      });
    },
    onContactDelete({ items }) {
      this.dialog.deleteContact.active = true;
      this.dialog.deleteContact.item = items;
    },
    deleteContact({ item }) {
      this.$emit("contact:delete", { items: item });
    },
    onContactEdit({ items }) {
      const item = items[0];
      this.dialog.updateContact.active = true;
      this.dialog.updateContact.item = item;
    },
    onJobTitleLabelChange({ items, label }) {
      const id = items[0].id;
      this.updateContact({
        id,
        body: {
          labelId: label.id,
          projectId: this.projectId,
        },
      });
    },
    onResponsibilityLabelChange({ items, label }) {
      const id = items[0].id;
      this.updateContact({
        id,
        body: {
          relation: {
            model: "project",
            modelId: this.projectId,
            labelId: label.id,
          },
        },
      });
    },
    updateContact(e) {
      this.$emit("contact:update", e);
    },
    afterContactUpdate() {
      this.$emit("contact:afterUpdate");
    },
  },
  mounted() {
    this.getLabels();
  },
};
</script>
