export const KEYBOARD_SHORTCUTS = {
  //trigger help panel
  help: {
    code: "KeyH",
    key: "h", // for display purposes only
    isCtrlKey: false,
    action: ({ store }) => store.dispatch("helpPanel/toggle"),
  },
  //create new folder
  createFolder: {
    code: "KeyM",
    key: "m", // for display purposes only
    isCtrlKey: true,
    action: ({ store, actionParams }) => actionParams.action(),
  },
};
