import { ROUTE_NAMES, ROUTE_PERMISSIONS } from "@/helpers/routeNames";
import multiguard from "../multiguard";
import { authGuard, userGuard } from "@/auth";
import { HELP_CATEGORIES } from "@/helpers/constants/help";

const { ROOT, ALL_HOURS } = ROUTE_NAMES.TIME_REGISTRATION;

export const timeRegistrationRoutes = [
  {
    path: "/time-registration",
    name: ROOT,
    meta: {
      permission: {
        isRedirectRoute: true,
      },
      helpCategory: HELP_CATEGORIES.TIME_REGISTRATION,
    },
    beforeEnter: multiguard([authGuard, userGuard]),
    component: () =>
      import(
        /* webpackChunkName: "timeRegistration" */ "../../pages/TimeRegistrationPage"
      ),
    children: [
      {
        path: "all-hours",
        name: ALL_HOURS,
        meta: {
          permission: ROUTE_PERMISSIONS.TIME_ENTRY,
        },
        component: () =>
          import(
            /* webpackChunkName: "timeRegistration" */ "../../pages/TimeRegistrationPage/AllHoursPage"
          ),
      },
    ],
  },
];
