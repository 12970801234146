<template>
  <AppEmbedPanel
    v-if="isVisible"
    :initial-width="500"
    :title="panelTitle"
    @close="onClose"
  >
    <template #append-header>
      <AppDefaultTooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            class="mr-2"
            @click="showFeedbackDialog = true"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>{{ $icons.REGULAR.ACTION.FEEDBACK }}</v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t("helpPanel.feedbackTooltip", { default: "Provide feedback" }) }}
        </span>
      </AppDefaultTooltip>
      <AppDefaultTooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            class="mr-2"
            @click="refreshHelpContent"
            :loading="isRefreshing"
            :disabled="!hasContentChanged && !isRefreshing"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>{{ $icons.REGULAR.ACTION.REFRESH }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("helpPanel.refreshTooltip", { page: currentPageName }) }}</span>
      </AppDefaultTooltip>
    </template>
    <div
      class="position-relative h-100"
      :class="{ border: currentHelp.url }"
      :style="{ backgroundColor: currentHelp.url ? '#eef2f7' : undefined }"
    >
      <template v-if="!currentHelp.url">
        <v-card-text
          class="d-flex flex-column align-center justify-center text-center h-100"
        >
          <v-icon
            x-large
            color="grey lighten-1"
            class="mb-4"
          >
            {{ $icons.REGULAR.ACTION.INFO }}
          </v-icon>
          <div class="text-h6 grey--text">{{ $t("helpPanel.noHelp.message") }}</div>
        </v-card-text>
      </template>
      <template v-else>
        <v-progress-circular
          v-if="isLoading && !isRefreshing"
          indeterminate
          class="loading-spinner"
        ></v-progress-circular>
        <iframe
          :src="currentHelp.url"
          width="100%"
          height="100%"
          allowfullscreen
          frameborder="0"
          @load="onIframeLoad"
        ></iframe>
      </template>
    </div>
    <AppHubspotFeedbackDialog v-model="showFeedbackDialog" />
  </AppEmbedPanel>
</template>

<script>
import { getHelpConfig } from "@/helpers/constants/help";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AppHelpPanel",

  data() {
    return {
      isLoading: true,
      isRefreshing: false,
      lockedCategory: null,
      showFeedbackDialog: false,
    };
  },

  computed: {
    routeCategory() {
      const matchedRoutes = [...this.$route.matched].reverse();
      const routeWithHelp = matchedRoutes.find((route) => route.meta?.helpCategory);
      return routeWithHelp?.meta?.helpCategory;
    },
    currentCategory() {
      // Use locked category if available, otherwise use route category
      return this.lockedCategory || this.routeCategory;
    },
    currentHelp() {
      return getHelpConfig(this.currentCategory);
    },
    hasContentChanged() {
      return (
        this.routeCategory !== this.lockedCategory && this.routeCategory !== undefined
      );
    },
    ...mapGetters("helpPanel", ["isVisible"]),
    panelTitle() {
      if (!this.currentHelp.url) {
        return "";
      }

      // Get the page name from the route key
      const pageName = this.$t(this.currentHelp.routeKey);

      // Format the title with the page name
      return this.$t("helpPanel.title", { page: pageName });
    },
    currentPageName() {
      if (!this.routeCategory) return "";

      // Get the help config for the route category to access its routeKey
      const routeHelp = getHelpConfig(this.routeCategory);
      if (!routeHelp.routeKey) return "";

      // Return the translated page name
      return this.$t(routeHelp.routeKey);
    },
  },

  watch: {
    isVisible: {
      handler(newValue) {
        if (newValue) {
          // Lock the category when panel becomes visible
          this.lockedCategory = this.routeCategory;
          // Reset isLoading when the panel becomes visible
          this.isLoading = true;
        } else {
          // Reset locked category when panel is closed
          this.lockedCategory = null;
        }
      },
      immediate: true,
    },
    currentCategory: {
      handler() {
        this.isLoading = true;
      },
    },
    showFeedbackDialog(newValue) {
      if (newValue) {
        // Load the HubSpot form when the dialog is opened
        this.$nextTick(() => {
          this.loadHubspotForm();
        });
      }
    },
  },

  methods: {
    ...mapActions("helpPanel", ["hide"]),
    onClose() {
      this.hide();
    },
    refreshHelpContent() {
      this.lockedCategory = this.routeCategory;
      this.isRefreshing = true;
    },
    onIframeLoad() {
      // Clear both loading states when iframe loads
      this.isLoading = false;
      this.isRefreshing = false;
    },
    loadHubspotForm() {
      // Only load the script if it hasn't been loaded yet
      if (!window.hbspt) {
        const script = document.createElement("script");
        script.charset = "utf-8";
        script.type = "text/javascript";
        script.src = "//js-eu1.hsforms.net/forms/embed/v2.js";
        script.onload = () => this.createHubspotForm();
        document.head.appendChild(script);
      } else {
        this.createHubspotForm();
      }
    },
    createHubspotForm() {
      // Clear the container first
      if (this.$refs.hubspotFormContainer) {
        this.$refs.hubspotFormContainer.innerHTML = "";

        // Create the form
        window.hbspt.forms.create({
          portalId: "144035730",
          formId: "a35a774a-5459-41d0-8988-a6b680002268",
          region: "eu1",
          target: this.$refs.hubspotFormContainer,
        });
      }
    },
  },
};
</script>

<style scoped>
.position-relative {
  position: relative;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.hubspot-form-container {
  min-height: 400px;
}
</style>
