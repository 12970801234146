<template>
  <div class="custom-tabs p-relative border-b-2">
    <div
      class="custom-tabs-container"
      ref="tabsContainer"
    >
      <AppResourceViewTabDraggableList
        :value="visibleTabs"
        :activeTabId="activeResourceViewId"
        @tab-click="$emit('tab-click', $event)"
        @context-menu="$emit('context-menu', $event)"
        @drag-end="handleDragEnd"
        ref="draggableList"
      />

      <AppResourceViewTabOverflowMenu
        v-if="overflowTabs.length > 0"
        v-model="moreMenuOpen"
        :tabs="tabs"
        :overflowCount="overflowTabs.length"
        :activeTabId="activeResourceViewId"
        @select-tab="selectTab"
        @create-view="$emit('create-view')"
        @context-menu="$emit('context-menu', $event)"
        @drag-end="handleDragEnd"
      />

      <v-divider
        class="mx-2 my-2"
        vertical
      ></v-divider>

      <AppResourceViewTab
        @click="$emit('create-view')"
        :disabled="isLoadingResourceViews"
      >
        <v-icon
          small
          class="mr-2"
        >
          {{ $icons.SOLID.ACTION.PLUS }}
        </v-icon>
        <span class="custom-tab-text">{{ $t("views.actions.addViewBtn") }}</span>
      </AppResourceViewTab>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AppResourceViewTabList",
  props: {
    resourceViews: {
      type: Array,
      required: true,
    },
    activeResourceViewId: {
      type: String,
      default: null,
    },
    model: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tabs: [],
      maxVisibleTabs: 5,
      moreMenuOpen: false,
      lastWidth: 0,
      resizeObserver: null,
      rafId: null,
      _calculateRafId: null,
      displayTabs: [],
      isUpdating: false,
      _cachedTabWidths: null,
    };
  },
  computed: {
    visibleTabs() {
      return this.displayTabs.slice(0, this.maxVisibleTabs);
    },
    overflowTabs() {
      const visibleIds = this.visibleTabs.map((tab) => tab.id);
      return this.tabs.filter((tab) => !visibleIds.includes(tab.id));
    },
    ...mapState("resourceViews", {
      isLoadingResourceViews: "isLoading",
    }),
  },
  watch: {
    resourceViews: {
      immediate: true,
      handler(newViews) {
        this.tabs = [...newViews];
        this.updateDisplayTabs();
      },
    },
    activeResourceViewId: {
      handler() {
        this.updateDisplayTabs();
      },
    },
    tabs: {
      handler() {
        if (!this.isUpdating) {
          this.updateDisplayTabs();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setupResizeHandling();
    });
  },
  beforeDestroy() {
    this.cleanupResizeHandling();
  },
  methods: {
    setupResizeHandling() {
      const container = this.$refs.tabsContainer;
      this.lastWidth = container.offsetWidth;
      this.calculateVisibleTabs();

      if (window.ResizeObserver) {
        this.resizeObserver = new ResizeObserver(this.handleContainerResize);
        this.resizeObserver.observe(container);

        if (this.$el.parentElement) {
          this.resizeObserver.observe(this.$el.parentElement);
        }
      }

      window.addEventListener("resize", this.handleResize);
    },

    cleanupResizeHandling() {
      window.removeEventListener("resize", this.handleResize);

      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }

      if (this.rafId) {
        cancelAnimationFrame(this.rafId);
      }

      if (this._calculateRafId) {
        cancelAnimationFrame(this._calculateRafId);
      }
    },

    handleContainerResize(entries) {
      if (this.rafId) {
        cancelAnimationFrame(this.rafId);
      }

      this.rafId = requestAnimationFrame(() => {
        const container = this.$refs.tabsContainer;
        const containerWidth = container.offsetWidth;

        // Always update the width for smoother response
        if (containerWidth !== this.lastWidth) {
          this.lastWidth = containerWidth;
          this.calculateVisibleTabs();
        }
      });
    },

    handleResize() {
      if (this.rafId) {
        cancelAnimationFrame(this.rafId);
      }

      this.rafId = requestAnimationFrame(() => {
        const containerWidth = this.$refs.tabsContainer.offsetWidth;

        // Always update the width for smoother response
        if (containerWidth !== this.lastWidth) {
          this.lastWidth = containerWidth;
          this.calculateVisibleTabs();
        }
      });
    },

    getTabWidths() {
      const widths = [];

      // Try to get widths from the draggable list component
      if (this.$refs.draggableList && this.$refs.draggableList.$el) {
        const tabElements =
          this.$refs.draggableList.$el.querySelectorAll(".draggable-item");

        // If we don't have all tabs rendered yet, use stored widths or defaults
        if (tabElements.length < this.tabs.length) {
          // Initialize with reasonable defaults if we don't have stored widths
          if (
            !this._cachedTabWidths ||
            this._cachedTabWidths.length !== this.tabs.length
          ) {
            this._cachedTabWidths = Array(this.tabs.length).fill(120);
          }

          // Update the cached widths with what we can measure
          tabElements.forEach((tabEl, index) => {
            const width = tabEl.offsetWidth;
            // Only update if the width seems reasonable (between 50-300px)
            if (width >= 50 && width <= 300) {
              this._cachedTabWidths[index] = width;
            }
          });

          return [...this._cachedTabWidths];
        }

        // All tabs are rendered, measure and cache them
        this._cachedTabWidths = [];
        tabElements.forEach((tabEl, index) => {
          const width = tabEl.offsetWidth;
          // Apply sanity check to measured widths (between 50-300px)
          const sanitizedWidth = Math.max(50, Math.min(300, width));
          widths.push(sanitizedWidth);
          this._cachedTabWidths[index] = sanitizedWidth;
        });
      } else {
        // Use cached widths if available, otherwise use defaults
        if (this._cachedTabWidths && this._cachedTabWidths.length === this.tabs.length) {
          return [...this._cachedTabWidths];
        }
        return Array(this.tabs.length).fill(120);
      }

      return widths;
    },

    calculateVisibleTabs() {
      // Use requestAnimationFrame for smoother updates
      if (this._calculateRafId) {
        cancelAnimationFrame(this._calculateRafId);
      }

      this._calculateRafId = requestAnimationFrame(() => {
        const containerWidth = this.lastWidth;
        const tabCount = this.tabs.length;

        // Get actual tab widths from the DOM
        const tabWidths = this.getTabWidths();

        // Reserved width for the "+" button and divider
        const reservedWidth = 200;
        const availableWidth = containerWidth - reservedWidth;

        // Calculate how many tabs can fit
        let totalWidth = 0;
        let newMaxVisible = 0;

        // Add a small buffer to prevent oscillation (5px per tab)
        const buffer = 5;

        for (let i = 0; i < tabCount; i++) {
          const tabWidth = tabWidths[i] + buffer;
          totalWidth += tabWidth;

          if (totalWidth <= availableWidth) {
            newMaxVisible++;
          } else {
            break;
          }
        }

        newMaxVisible = Math.max(1, Math.min(newMaxVisible, tabCount));

        // Add hysteresis to prevent oscillation
        if (Math.abs(newMaxVisible - this.maxVisibleTabs) === 1) {
          // If we're just toggling between n and n+1 tabs, require a larger change to switch
          const currentTotalWidth = tabWidths
            .slice(0, this.maxVisibleTabs)
            .reduce((sum, width) => sum + width + buffer, 0);
          const newTotalWidth = tabWidths
            .slice(0, newMaxVisible)
            .reduce((sum, width) => sum + width + buffer, 0);

          // Only change if there's at least 20px difference or we're adding tabs and have plenty of space
          const threshold = 20;
          const shouldChange =
            Math.abs(currentTotalWidth - newTotalWidth) > threshold ||
            (newMaxVisible > this.maxVisibleTabs &&
              availableWidth - newTotalWidth > threshold);

          if (!shouldChange) {
            return;
          }
        }

        if (this.maxVisibleTabs !== newMaxVisible) {
          this.maxVisibleTabs = newMaxVisible;
          this.ensureActiveTabIsVisible();
        }
      });
    },

    updateDisplayTabs() {
      if (!this.activeResourceViewId) {
        this.displayTabs = [...this.tabs];
        return;
      }

      const activeIndex = this.tabs.findIndex(
        (tab) => tab.id === this.activeResourceViewId,
      );

      if (activeIndex < 0 || activeIndex < this.maxVisibleTabs) {
        this.displayTabs = [...this.tabs];
        return;
      }

      this.displayTabs = [
        ...this.tabs.slice(0, this.maxVisibleTabs - 1),
        this.tabs[activeIndex],
        ...this.tabs.slice(this.maxVisibleTabs - 1, activeIndex),
        ...this.tabs.slice(activeIndex + 1),
      ];
    },

    ensureActiveTabIsVisible() {
      this.updateDisplayTabs();
    },

    handleDragEnd(event) {
      this.isUpdating = true;

      let sourceIndex, targetIndex;

      if (event.tabs) {
        sourceIndex = event.oldIndex;
        targetIndex = event.newIndex;
        this.tabs = [...event.tabs];
      } else {
        const sourceTab = this.displayTabs[event.oldIndex];
        const targetTab = this.displayTabs[event.newIndex];

        sourceIndex = this.tabs.findIndex((tab) => tab.id === sourceTab.id);
        targetIndex = this.tabs.findIndex((tab) => tab.id === targetTab.id);

        const [movedTab] = this.tabs.splice(sourceIndex, 1);
        this.tabs.splice(targetIndex, 0, movedTab);
      }

      this.updateDisplayTabs();

      this.$emit("drag-end", {
        ids: this.tabs.map((tab) => tab.id),
        oldIndex: sourceIndex,
        newIndex: targetIndex,
      });

      setTimeout(() => {
        this.isUpdating = false;
      }, 500);
    },
    selectTab(tabId) {
      this.moreMenuOpen = false;
      this.$emit("tab-click", tabId);
    },
  },
};
</script>

<style scoped>
.primary-border-bottom {
  border-bottom: 2px solid var(--v-primary-border-base);
}

.custom-tabs {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 40px;
}

.custom-tabs-container {
  display: flex;
  align-items: center;
  scrollbar-width: none;
  position: relative;
  z-index: 1;
}

.custom-tabs-container::-webkit-scrollbar {
  display: none;
}

.custom-tabs-container > .v-divider,
.custom-tabs-container > .custom-tab:last-child {
  flex-shrink: 0;
}

.custom-tab-text {
  white-space: nowrap;
}
</style>
