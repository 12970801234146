<template>
  <AppFiltersDrawer
    v-if="localShowDrawer"
    @close="closeFilters"
    :tableModel="tableModel"
    :children="children"
  />
</template>

<script>
import { getSearchUsers } from "@/services/search/users";
import { getSearchForms } from "@/services/search/form";
import { getTeams } from "@/services/team/assignableTeams";
import { filtersItemsMixins, filtersMixins, filtersWatchMixins } from "@/helpers/mixins";

export default {
  mixins: [filtersItemsMixins, filtersMixins, filtersWatchMixins],
  props: {
    showDrawer: Boolean,
    tableModel: String,
    hideProject: Boolean,
  },
  model: {
    prop: "showDrawer",
    event: "change",
  },
  data() {
    return {
      owners: [],
      isOwnersLoading: false,
      members: [],
      isMembersLoading: false,
      creators: [],
      isCreatorsLoading: false,
      forms: [],
      isFormsLoading: false,
      teams: [],
      isTeamsLoading: false,
    };
  },
  methods: {
    getData() {
      this.getOwners();
      this.getMembers();
      this.getCreators();

      this.getForms();
      this.getTeams();
    },
    closeFilters() {
      this.localShowDrawer = false;
    },
    async getOwners() {
      this.isOwnersLoading = true;
      const ownerIds = this.ownerIds;
      const params = {
        alwaysIncludeIds: ownerIds,
      };
      getSearchUsers({ params })
        .then((users) => {
          this.owners = users;
        })
        .finally(() => {
          this.isOwnersLoading = false;
        });
    },
    async getMembers() {
      this.isMembersLoading = true;
      const memberIds = this.memberIds;
      const params = { alwaysIncludeIds: memberIds };
      getSearchUsers({ params })
        .then((users) => {
          this.members = users;
        })
        .finally(() => {
          this.isMembersLoading = false;
        });
    },
    async getCreators() {
      this.isCreatorsLoading = true;
      const creatorIds = this.creatorIds;
      const params = { alwaysIncludeIds: creatorIds };
      getSearchUsers({ params })
        .then((users) => {
          this.creators = users;
        })
        .finally(() => {
          this.isCreatorsLoading = false;
        });
    },
    async getForms() {
      this.isFormsLoading = true;
      const formIds = this.belongsToFormIds;
      const params = {
        alwaysIncludeIds: formIds,
        projectIds: [...this.projectIds, this.routeParamProjectId],
      };
      getSearchForms({ params })
        .then((forms) => {
          this.forms = forms;
        })
        .finally(() => {
          this.isFormsLoading = false;
        });
    },
    async getTeams() {
      this.projectRouteQueryParams;
      this.isTeamsLoading = true;
      getTeams()
        .then((teams) => {
          this.teams = teams;
        })
        .finally(() => {
          this.isTeamsLoading = false;
        });
    },
  },
  computed: {
    localShowDrawer: {
      get() {
        return this.showDrawer;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    projectId() {
      return this.$route.params.projectId;
    },
    projectIds() {
      return this.filtersMixins_filterByKey("projectIds") ?? [];
    },
    ownerIds() {
      return this.filtersMixins_filterByKey("ownerIds") ?? [];
    },
    memberIds() {
      return this.filtersMixins_filterByKey("memberIds") ?? [];
    },
    creatorIds() {
      return this.filtersMixins_filterByKey("creatorIds") ?? [];
    },
    belongsToFormIds() {
      return this.filtersMixins_filterByKey("belongsToFormIds") ?? [];
    },
    children() {
      const children = [
        {
          is: "AppCustomStatusFilter",
          labelKey: "filters.taskFilters.status",
          filterValueKey: "statusIds",
          itemValue: "value",
          itemText: "text",
          clearable: true,
          model: "task",
        },
        {
          is: "AppFiltersClientGroupedUserAutocomplete",
          labelKey: "filters.taskFilters.responsible",
          filterValueKey: "ownerIds",
          itemValue: "id",
          itemText: "name",
          items: this.owners ?? null,
          loading: this.isOwnersLoading,
          userAvatar: true,
        },
        {
          is: "AppFiltersMultiAutocomplete",
          labelKey: "filters.taskFilters.teams",
          filterValueKey: "teamIds",
          itemValue: "id",
          itemText: "name",
          items: this.teams ?? null,
          loading: this.isTeamsLoading,
        },
        {
          is: "AppFiltersClientGroupedUserAutocomplete",
          labelKey: "filters.taskFilters.participants",
          filterValueKey: "memberIds",
          itemValue: "id",
          itemText: "name",
          items: this.members ?? null,
          loading: this.isMembersLoading,
          userAvatar: true,
        },
        {
          is: "AppFiltersClientGroupedUserAutocomplete",
          labelKey: "filters.taskFilters.createdBy",
          itemValue: "id",
          itemText: "name",
          filterValueKey: "creatorIds",
          items: this.creators ?? null,
          loading: this.isCreatorsLoading,
          userAvatar: true,
        },
        ...(!this.projectId
          ? [
              {
                is: "AppFiltersProjectMultiAutocomplete",
                labelKey: "filters.taskFilters.project",
                filterValueKey: "projectIds",
                isReadyToInitialFetch: this.isReadyToInitialFetch,
                queryParams: {
                  alwaysIncludeIds: this.projectIds,
                },
              },
            ]
          : []),
        {
          is: "AppFiltersFormMultiAutocomplete",
          labelKey: "filters.taskFilters.form",
          filterValueKey: "belongsToFormIds",
          itemValue: "id",
          itemText: "name",
          items: this.forms ?? null,
          showHelper: this.projectIds?.length > 0,
          loading: this.isFormsLoading,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.taskFilters.startDate",
          filterValueKey: "startDate",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_dateItemsWithFutureDates,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.taskFilters.dueDate",
          filterValueKey: "dueDate",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_dateItemsWithFutureDates,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.taskFilters.createdDate",
          itemValue: "value",
          itemText: "text",
          filterValueKey: "createdDate",
          items: this.filtersItemsMixins_dateItems,
        },
        ...(!this.projectId
          ? [
              {
                is: "AppFiltersSelect",
                labelKey: "filters.taskFilters.internal",
                filterValueKey: "isInternal",
                itemValue: "value",
                itemText: "text",
                items: this.filtersItemsMixins_yesOrNoItems,
              },
            ]
          : []),
        {
          is: "AppFiltersSelect",
          labelKey: "filters.taskFilters.hasAttachment",
          filterValueKey: "hasAttachment",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_yesOrNoItems,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.taskFilters.belongsToFormItem",
          filterValueKey: "belongsToFormItem",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_yesOrNoItems,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.taskFilters.hasToDoListItems",
          filterValueKey: "hasTodoList",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_yesOrNoItems,
        },
      ];

      return children;
    },
  },
};
</script>
