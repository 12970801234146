<template>
  <v-menu
    v-model="isOpen"
    :close-on-content-click="false"
    offset-y
    nudge-bottom="2"
    min-width="300"
    content-class="overflow-hidden"
  >
    <template v-slot:activator="{ on, attrs }">
      <AppResourceViewTab
        v-bind="attrs"
        v-on="on"
      >
        <span>{{ overflowCount }} {{ $t("views.actions.showMoreBtn") }}...</span>
        <v-icon
          small
          class="ml-1"
        >
          {{ $icons.SOLID.ACTION.DROPDOWN }}
        </v-icon>
      </AppResourceViewTab>
    </template>

    <v-card>
      <v-card-title class="py-2 px-3">
        <AppDefaultTextField
          v-model="searchQuery"
          autofocus
          clearable
          :append-icon="$icons.LIGHT.ACTION.SEARCH"
          :label="$t('common.search')"
          dense
          hide-details
          outlined
          class="mt-1"
        />
      </v-card-title>

      <div class="border-b"></div>

      <v-card-text class="overflow-tabs-container pa-0">
        <v-list>
          <AppDefaultDraggable
            v-model="menuTabsLocal"
            :ghostClass="$constant.DRAG_SETTINGS.GHOST_CLASS_HIDDEN"
            :dragClass="$constant.DRAG_SETTINGS.DRAG_CLASS_BACKGROUND"
            v-bind="dragOptions"
            :disabled="!!searchQuery || isLoading"
            @end="onDragEnd"
          >
            <v-list-item
              v-for="tab in filteredTabs"
              class="draggable-item list_tr"
              :class="rowStateClasses(tab)"
              :key="tab.id"
              @click="selectTab(tab.id)"
              @contextmenu.prevent="openContextMenu($event, tab)"
            >
              <v-list-item-icon class="d-flex justify-center">
                <v-icon
                  small
                  :class="['drag-handle', { 'drag-handle--disabled': !!searchQuery }]"
                >
                  {{ $icons.SOLID.ACTION.DRAG }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="d-flex flex-row align-center">
                  <v-icon
                    small
                    class="mr-3"
                    v-if="tab.isPrivate"
                  >
                    {{ $icons.SOLID.ACTION.LOCK }}
                  </v-icon>
                  <AppTextTruncate
                    :text="tab.name"
                    maxWidth="200"
                    :pixelThreshold="10"
                    class="tab-name-text"
                    v-if="!searchQuery"
                  />
                  <span
                    class="tab-name-text"
                    v-html="highlightedTabName(tab)"
                    v-else
                  ></span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  small
                  @click.stop="openContextMenu($event.currentTarget, tab)"
                >
                  <v-icon>{{ $icons.REGULAR.ACTION.MENU }}</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </AppDefaultDraggable>
        </v-list>
      </v-card-text>

      <div class="border-b"></div>

      <v-card-actions class="pa-0 ma-0">
        <v-btn
          block
          text
          color="primary"
          @click="createNewView"
        >
          <v-icon
            small
            class="mr-2"
          >
            {{ $icons.SOLID.ACTION.PLUS }}
          </v-icon>
          {{ $t("views.actions.addView") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AppResourceViewTabOverflowMenu",
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    overflowCount: {
      type: Number,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    activeTabId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      searchQuery: "",
      isOpen: this.value,
      menuTabsLocal: [],
      dragOptions: {
        fallbackOnBody: true,
      },
    };
  },
  computed: {
    filteredTabs() {
      if (!this.searchQuery) return this.menuTabsLocal;

      const query = this.searchQuery.toLowerCase();
      return this.menuTabsLocal.filter((tab) => tab.name.toLowerCase().includes(query));
    },
    ...mapState("resourceViews", {
      isLoading: (state) => state.isLoading,
    }),
  },
  watch: {
    value(val) {
      this.isOpen = val;
    },
    isOpen(val) {
      this.$emit("input", val);
    },
    tabs: {
      immediate: true,
      handler(newTabs) {
        this.menuTabsLocal = [...newTabs];
      },
    },
  },
  methods: {
    rowStateClasses(tab) {
      return {
        "list_tr--selected": this.activeTabId === tab.id,
      };
    },
    selectTab(tabId) {
      this.$emit("select-tab", tabId);
      this.isOpen = false;
    },
    createNewView() {
      this.$emit("create-view");
      this.isOpen = false;
    },
    onDragEnd(event) {
      if (event.oldIndex !== event.newIndex) {
        const reorderedTabs = [...this.menuTabsLocal];

        this.$emit("drag-end", {
          tabs: reorderedTabs,
          oldIndex: event.oldIndex,
          newIndex: event.newIndex,
        });
      }
    },
    openContextMenu(element, tab) {
      this.$emit("context-menu", { element, resourceView: tab });
    },
    highlightedTabName(tab) {
      return this.$textHelpers.highlightText(
        tab.name,
        this.searchQuery,
        this.activeTabId === tab.id,
      );
    },
  },
};
</script>

<style scoped>
.overflow-tabs-container {
  max-height: 400px;
  overflow-y: auto;
}

.drag-handle {
  opacity: 0.5;
  cursor: grab;
}

.v-list-item:hover .drag-handle {
  opacity: 0.8;
}

.drag-handle--disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

.v-list-item:hover .drag-handle--disabled {
  opacity: 0.2;
}

.tab-name-text {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px; /* Adjust this value as needed */
}

/* Add this to ensure the list items can handle the truncated text properly */
.v-list-item {
  min-width: 0;
}
</style>
