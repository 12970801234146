<template>
  <AppModuleAutocomplete
    ref="autocomplete"
    v-bind="params"
    v-on="listeners"
    item-text="name"
    :items="groupedItems"
    :filter="customFilter"
    :titleField="$constant.MODULE_FIELD_CONFIG.USER.TITLE_FIELD"
    :subTitleFields="$constant.MODULE_FIELD_CONFIG.USER.SUB_TITLE_FIELDS"
  >
    <slot
      v-for="slot in Object.keys($slots)"
      :name="slot"
      :slot="slot"
    ></slot>
    <template
      #prepend-inner="{ item }"
      v-if="userAvatar"
    >
      <AppUserAvatar
        :user="item"
        :truncate="60"
        :itemText="itemText"
        class="pr-4 d-flex"
      />
    </template>
  </AppModuleAutocomplete>
</template>

<script>
import { mapGetters } from "vuex";

const noClientKey = "no_client";
const ownWorkspaceKey = "own_workspace";

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: "text",
    },
    itemValue: {
      type: String,
      default: "id",
    },
    labelPaths: {
      type: Array,
      default: () => [],
    },
    userAvatar: Boolean,
  },
  model: {
    prop: "value",
    event: "change",
  },
  computed: {
    ...mapGetters("auth", {
      currentDomain: "currentDomain",
    }),
    listeners() {
      return { ...this.$listeners };
    },
    params() {
      const params = { ...this.$props, ...this.$attrs };
      return params;
    },
    noClientText() {
      return this.$t("contact.addContact.noClient");
    },
    ownWorkspaceText() {
      return this.currentDomain.name;
    },
    groupedItems() {
      const grouped = this.items.reduce((acc, item) => {
        // Determine the group based on contact status first, then by client
        let groupKey;

        if (item.hasContactRelation) {
          if (item.client?.name) {
            groupKey = item.client.id;
          } else {
            groupKey = noClientKey;
          }
        } else {
          groupKey = ownWorkspaceKey;
        }

        if (!acc[groupKey]) {
          acc[groupKey] = [];
        }
        acc[groupKey].push({ ...item, groupKey });
        return acc;
      }, {});

      const groupsWithHeaders = Object.entries(grouped).map(([groupKey, contacts]) => {
        let header;
        if (groupKey === noClientKey) {
          header = this.noClientText;
        } else if (groupKey === ownWorkspaceKey) {
          header = this.ownWorkspaceText; // Name for the group of users who are not contacts
        } else {
          header = contacts[0].client ? contacts[0].client.name : "";
        }

        const sortedContacts = contacts.sort((a, b) => {
          const nameA = a.fullName || "";
          const nameB = b.fullName || "";
          return nameA.localeCompare(nameB);
        });

        const headerItem = {
          header,
          groupKey,
          isHeader: true,
        };

        return [headerItem, ...sortedContacts];
      });

      const sortedGroups = groupsWithHeaders.sort((groupA, groupB) => {
        // Ensure the not-contact group is always first
        if (groupA[0].groupKey === ownWorkspaceKey) return -1;
        if (groupB[0].groupKey === ownWorkspaceKey) return 1;

        // Then handle the no-client group
        if (groupA[0].groupKey === noClientKey) return -1;
        if (groupB[0].groupKey === noClientKey) return 1;

        // Finally, sort by client name
        return groupA[0].header.localeCompare(groupB[0].header);
      });

      const flattenedAndSorted = sortedGroups.flat();

      //dont display client because items are already grouped by client
      const itemsWithoutClients = flattenedAndSorted.map((item) => {
        delete item.client;
        return item;
      });

      return itemsWithoutClients;
    },
  },
  methods: {
    focus() {
      this.$refs.autocomplete.focus();
    },
    customFilter(item, queryText) {
      const query = queryText.toLowerCase();

      if (item?.searchStr?.includes(query)) {
        return true;
      }

      const flattenItems = this.groupedItems.flatMap((group) =>
        group.isHeader ? [] : group,
      );

      // For header items, check if any items under the header match the query
      if (item.hasOwnProperty("header")) {
        const itemsUnderHeader = flattenItems.filter(
          (contact) => contact.groupKey === item.groupKey,
        );
        return itemsUnderHeader.some((contact) => this.customFilter(contact, query));
      }

      // Check if header text matches the search
      const isNoClientHeader = item.groupKey === noClientKey;
      const isNoClientHeaderTextMatch = this.noClientText.toLowerCase().includes(query);
      const isOwnWorkspaceHeader = item.groupKey === ownWorkspaceKey;
      const isOwnWorkspaceHeaderTextMatch = this.ownWorkspaceText
        .toLowerCase()
        .includes(query);

      if (
        (isNoClientHeader && isNoClientHeaderTextMatch) ||
        (isOwnWorkspaceHeader && isOwnWorkspaceHeaderTextMatch)
      ) {
        return true; // Show header if its text matches search
      }

      return false;
    },
  },
};
</script>
