<template>
  <v-dialog
    v-model="dialog"
    max-width="700px"
  >
    <v-card>
      <v-card-title class="headline">
        {{ $t("projectView.info.in4mo.connectDialogTitle") }}
      </v-card-title>
      
      <v-card-text>
        <div class="mb-4">
          <AppDefaultTextField
            class="mt-1 mb-4"
            :label="$t('common.search')"
            :append-icon="$icons.LIGHT.ACTION.SEARCH"
            v-model="search"
            hide-details
            clearable
            autofocus
            :disabled="isConnecting"
          />

          <v-simple-table
            class="border-a"
            fixed-header
            height="400px"
          >
            <template v-slot:default>
              <tbody class="border-radius-0">
                <template v-if="isLoading">
                  <tr>
                    <td>
                      <v-progress-linear
                        indeterminate
                        rounded
                        height="6"
                      />
                    </td>
                  </tr>
                </template>
                <template v-else-if="hasError">
                  <tr>
                    <td class="text-center pa-4">
                      {{ $t("common.somethingWentWrong") }}
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <template v-if="filteredCases.length === 0">
                    <tr>
                      <td class="text-center pa-4">
                        {{ $t("projectView.info.in4mo.noCasesAvailable") }}
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr
                      v-for="(insuranceCase, i) in filteredCases"
                      :key="i"
                      class="cursor-pointer list_tr"
                      :class="insuranceCase.caseId === selectedCaseId ? 'list_tr--selected' : ''"
                      @click="selectCase(insuranceCase)"
                    >
                      <td class="border-radius-0">
                        <div class="d-flex justify-space-between">
                          <div class="font-weight-medium">{{ insuranceCase.caseId }}</div>
                          <div class="text-caption">{{ formatDate(insuranceCase.createdAt) }}</div>
                        </div>
                        <div class="text-caption" v-if="insuranceCase.status">
                          {{ $t('projectView.info.in4mo.status') }}: {{ insuranceCase.status }}
                        </div>
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </template>
          </v-simple-table>
          
          <!-- Selected case details -->
          <v-simple-table
            v-if="selectedCase"
            class="border-a mt-4"
          >
            <tbody>
              <tr>
                <td class="font-weight-bold" width="150">{{ $t("projectView.info.in4mo.caseId") }}</td>
                <td>{{ selectedCase.caseId }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t("projectView.info.in4mo.createdAt") }}</td>
                <td>{{ formatDate(selectedCase.createdAt) }}</td>
              </tr>
              <tr v-if="selectedCase.status">
                <td class="font-weight-bold">{{ $t("projectView.info.in4mo.status") }}</td>
                <td>{{ selectedCase.status }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-card-text>

      <v-divider></v-divider>
      
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="close"
          :disabled="isConnecting"
        >
          {{ $t("common.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          :loading="isConnecting"
          :disabled="!selectedCaseId"
          @click="connectCase"
        >
          {{ $t("projectView.info.in4mo.connectButton") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    projectId: {
      type: String,
      required: true
    }
  },
  
  data() {
    return {
      search: "",
      selectedCaseId: null,
      isConnecting: false
    };
  },
  
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    ...mapState("in4mo", {
      availableCases: state => state.availableCases,
      isLoading: state => state.isLoadingCases,
      hasError: state => state.hasError
    }),
    selectedCase() {
      if (!this.selectedCaseId || !this.availableCases) return null;
      return this.availableCases.find(c => c.caseId === this.selectedCaseId);
    },
    filteredCases() {
      if (!this.availableCases) return [];
      if (!this.search) return this.availableCases;
      
      const searchLower = this.search.toLowerCase();
      return this.availableCases.filter(c => {
        return (c.caseId && c.caseId.toLowerCase().includes(searchLower)) ||
          (c.status && c.status.toLowerCase().includes(searchLower));
      });
    }
  },
  
  methods: {
    close() {
      this.selectedCaseId = null;
      this.search = "";
      this.$emit("input", false);
    },
    
    selectCase(insuranceCase) {
      this.selectedCaseId = insuranceCase.caseId;
    },
    
    async connectCase() {
      if (!this.selectedCaseId) return;
      
      this.isConnecting = true;
      try {
        await this.$store.dispatch("in4mo/connectInsuranceCase", {
          projectId: this.projectId,
          caseId: this.selectedCaseId
        });
        this.$emit("connected");
        this.close();
      } catch (error) {
        this.$toast.error(this.$t("projectView.info.in4mo.connectError"));
      } finally {
        this.isConnecting = false;
      }
    },
    
    formatDate(dateString) {
      if (!dateString) return '';
      
      try {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat(this.$i18n.locale, { 
          year: 'numeric', 
          month: 'short', 
          day: 'numeric'
        }).format(date);
      } catch (e) {
        return dateString;
      }
    }
  },
  
  watch: {
    value(newVal) {
      // Load available cases when dialog opens
      if (newVal) {
        this.$store.dispatch("in4mo/getAvailableCases");
      } else {
        // Reset when dialog closes
        this.selectedCaseId = null;
        this.search = "";
      }
    }
  }
};
</script>

<style scoped>
.border-a {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.list_tr {
  transition: background-color 0.2s;
}
.list_tr:hover {
  background-color: #f5f5f5;
}
.list_tr--selected {
  background-color: #e3f2fd !important;
}
</style>
