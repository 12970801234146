import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
import router from "../../../router";
import { ROUTE_NAMES } from "@/helpers/routeNames";

const state = () => ({
  project: null,
  isLoading: false,
  isUpdating: false,
});

const getters = {
  members: (state) => state.project?.members || [],
  projectFolderId: (state) => state.project?.folderId || null,
};

const actions = {
  async getProject({ commit }, id) {
    commit("loading", true);

    return axios
      .get(`${apiUrl}project/${id}`)
      .then(({ data }) => {
        const project = data?.project;
        if (project) {
          commit("setProject", project);
        }
        commit("loading", false);
        return project;
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
  async updateProject({ commit, state }, { id, body, updateLocal }) {
    commit("updating", true);
    const oldProject = state.project;

    if (updateLocal) {
      commit("updateProject", { ...oldProject, ...body });
    }

    return axios
      .put(`${apiUrl}project/${id}`, { ...body })
      .then(({ data }) => {
        const project = data?.project;
        if (project) {
          commit("updateProject", project);
        }
      })
      .catch((error) => {
        if (updateLocal) {
          commit("updateProject", oldProject);
        }
        throw new Error(error);
      })
      .finally(() => {
        commit("updating", false);
      });
  },
  async deleteProject({ commit }, id) {
    return axios
      .delete(`${apiUrl}project/${id}`)
      .then(async (res) => {
        const project = res?.data?.project;
        if (project) {
          commit("removeProject", project);
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  resetProject({ commit }) {
    commit("setProject", null);
    commit("loading", false);
    commit("updating", false);
  },
};

const mutations = {
  setProject(state, project) {
    state.project = project;
  },
  updateProject(state, project) {
    if (!project) throw new Error("No project");
    state.project = project;
  },
  removeProject(state, project) {
    if (!project) throw new Error("No project");
    state.project = project;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  updating(state, isUpdating) {
    state.isUpdating = isUpdating;
  },
};

export const project = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
