<template>
  <v-card
    flat
    :width="effectiveWidth"
    :class="[
      'embedded-panel border-l',
      { 'full-width': isMobile },
      { 'panel-active': isPanelActive },
    ]"
    ref="panelCard"
  >
    <div
      class="resize-handle"
      :class="{ 'touch-device': isTouchDevice }"
      @mousedown="startResizeMouse"
      @touchstart="startResizeTouch"
    ></div>
    <div class="d-flex flex-column h-100 overflow-hidden">
      <div class="d-flex align-center pa-4">
        <div class="text-h5">{{ title }}</div>
        <v-spacer></v-spacer>
        <slot name="append-header"></slot>
        <v-btn
          icon
          class="mr-1"
          @click="$emit('close')"
        >
          <v-icon>{{ $icons.REGULAR.ACTION.CLOSE }}</v-icon>
        </v-btn>
      </div>
      <v-card-text class="flex-grow-1 overflow-hidden pa-0">
        <div class="h-100">
          <slot></slot>
        </div>
      </v-card-text>
    </div>
    <div
      v-if="isResizing"
      class="resize-overlay"
    ></div>
  </v-card>
</template>

<script>
// Define constants outside the component
const MIN_PANEL_WIDTH = 350;
const MAX_PANEL_WIDTH = 800;
const MOBILE_BREAKPOINT = 960; // Standard tablet breakpoint

export default {
  name: "AppEmbedPanel",
  props: {
    initialWidth: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    storageKey: {
      type: String,
      default: "app-embedded-panel-width",
    },
  },
  data() {
    return {
      width: this.getSavedWidth(),
      isResizing: false,
      isMobile: window.innerWidth < MOBILE_BREAKPOINT,
      isPanelActive: false,
      isTouchDevice: false,
    };
  },
  computed: {
    effectiveWidth() {
      return this.isMobile ? "100%" : this.width;
    },
  },
  methods: {
    getSavedWidth() {
      const savedWidth = localStorage.getItem(this.storageKey);
      if (savedWidth) {
        const parsedWidth = parseInt(savedWidth, 10);
        // Use the constants directly for validation
        if (parsedWidth >= MIN_PANEL_WIDTH && parsedWidth <= MAX_PANEL_WIDTH) {
          return parsedWidth;
        }
      }
      return this.initialWidth;
    },
    saveWidth() {
      localStorage.setItem(this.storageKey, this.width.toString());
    },
    startResizeMouse(e) {
      this.startResizeGeneric(
        e,
        "mousemove",
        "mouseup",
        this.handleResizeMouse,
        this.stopResizeMouse,
      );
    },
    startResizeTouch(e) {
      // Indicate this is a touch device when touch is used
      this.isTouchDevice = true;
      this.startResizeGeneric(
        e,
        "touchmove",
        "touchend",
        this.handleResizeTouch,
        this.stopResizeTouch,
      );
    },
    startResizeGeneric(e, moveEvent, endEvent, moveHandler, endHandler) {
      this.isResizing = true;
      document.addEventListener(moveEvent, moveHandler);
      document.addEventListener(endEvent, endHandler);
      document.body.style.userSelect = "none";
      document.body.classList.add("panel-resizing");

      // Disable transitions during resize for immediate feedback
      document.body.classList.add("disable-transitions");

      e.preventDefault();
    },
    handleResizeMouse(e) {
      this.handleResizeGeneric(e.clientX);
    },
    handleResizeTouch(e) {
      const touch = e.touches[0];
      this.handleResizeGeneric(touch.clientX);
      e.preventDefault(); // Prevent scrolling while resizing
    },
    handleResizeGeneric(clientX) {
      if (!this.isResizing) return;

      // Calculate new width based on position from right edge of screen
      const newWidth = window.innerWidth - clientX;

      // Apply constraints using the constants directly
      if (newWidth >= MIN_PANEL_WIDTH && newWidth <= MAX_PANEL_WIDTH) {
        this.width = newWidth;

        // Update CSS variables and body padding
        if (!this.isMobile) {
          document.body.style.paddingRight = `${this.width}px`;
          document.documentElement.style.setProperty(
            "--embedded-panel-width",
            `${this.width}px`,
          );
        }
      }
    },
    stopResizeMouse() {
      this.stopResizeGeneric(
        "mousemove",
        "mouseup",
        this.handleResizeMouse,
        this.stopResizeMouse,
      );
    },
    stopResizeTouch() {
      this.stopResizeGeneric(
        "touchmove",
        "touchend",
        this.handleResizeTouch,
        this.stopResizeTouch,
      );
    },
    stopResizeGeneric(moveEvent, endEvent, moveHandler, endHandler) {
      this.isResizing = false;
      document.removeEventListener(moveEvent, moveHandler);
      document.removeEventListener(endEvent, endHandler);
      document.body.style.userSelect = "";
      document.body.classList.remove("panel-resizing");

      // Re-enable transitions after resize is complete
      document.body.classList.remove("disable-transitions");

      this.saveWidth();
    },
    checkScreenSize() {
      const wasMobile = this.isMobile;
      this.isMobile = window.innerWidth < MOBILE_BREAKPOINT;

      // Update padding and CSS variables when screen size changes
      if (this.isMobile !== wasMobile) {
        if (this.isMobile) {
          document.body.style.paddingRight = "0px";
          document.documentElement.style.setProperty("--embedded-panel-width", "100%");
        } else {
          document.body.style.paddingRight = `${this.width}px`;
          document.documentElement.style.setProperty(
            "--embedded-panel-width",
            `${this.width}px`,
          );
        }
      }
    },
    checkDeviceType() {
      // Check if device supports touch
      this.isTouchDevice =
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0;
    },
  },
  mounted() {
    this.checkDeviceType();
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);

    document.body.classList.add("has-embedded-panel");

    // Add a small delay before adding the active class to trigger the animation
    this.$nextTick(() => {
      setTimeout(() => {
        this.isPanelActive = true;

        // Add panel-active class to body for mobile backdrop
        if (this.isMobile) {
          document.body.classList.add("panel-active");
        }

        // Apply padding after the same delay to sync with panel animation
        if (!this.isMobile) {
          document.body.style.paddingRight = `${this.width}px`;
          document.documentElement.style.setProperty(
            "--embedded-panel-width",
            `${this.width}px`,
          );
        }
      }, 50);
    });
  },
  beforeDestroy() {
    // Clean up any event listeners if component is destroyed while resizing
    if (this.isResizing) {
      document.removeEventListener("mousemove", this.handleResizeMouse);
      document.removeEventListener("mouseup", this.stopResizeMouse);
      document.removeEventListener("touchmove", this.handleResizeTouch);
      document.removeEventListener("touchend", this.stopResizeTouch);
      document.body.style.userSelect = "";
      document.body.classList.remove("panel-resizing");
    }

    // Remove panel-active class from body for mobile backdrop
    document.body.classList.remove("panel-active");

    window.removeEventListener("resize", this.checkScreenSize);

    // Set padding to 0 first to trigger the animation
    document.body.style.paddingRight = "0px";
    document.documentElement.style.setProperty("--embedded-panel-width", "0px");

    // Remove the class after the animation completes
    setTimeout(() => {
      document.body.classList.remove("has-embedded-panel");
    }, 300); // Match the transition duration
  },
};
</script>

<style scoped>
.embedded-panel {
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: auto !important;
  height: 100vh !important;
  border-radius: 0 !important;
  transform: translateX(100%);
  transition: transform 0.2s cubic-bezier(0.25, 0.8, 0.5, 1);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.embedded-panel.full-width {
  left: 0 !important;
  z-index: 100;
  transform: translateX(100%);
}

/* Add this new class that will be applied when the panel is mounted */
.embedded-panel.panel-active {
  transform: translateX(0);
}

.resize-handle {
  position: absolute;
  left: 0;
  top: 0;
  width: 6px;
  height: 100%;
  cursor: ew-resize;
  z-index: 10;
  transition: background-color 0.15s ease;
}

/* Make the handle more visible on touch devices */
.resize-handle.touch-device {
  width: 12px;
  background-color: rgba(0, 0, 0, 0.05);
}

.resize-handle.touch-device::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 50px;
  width: 4px;
  border-radius: 2px;
  background-color: #9b9b9b;
}

.resize-handle:hover,
.resize-handle:active {
  background-color: rgba(0, 0, 0, 0.1);
}

.resize-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: transparent;
  cursor: ew-resize;
}
</style>

<style>
/* Add this outside the scoped style block */
:root {
  /* This is just a default value that will be overridden by JS */
  --embedded-panel-width: 400px;
}

/* Add transition to the body padding */
body {
  transition: padding-right 0.2s cubic-bezier(0.25, 0.8, 0.5, 1);
}

/* Disable transitions during resize for immediate feedback */
body.disable-transitions,
body.disable-transitions * {
  transition: none !important;
}

body.has-embedded-panel .v-dialog.v-dialog--fullscreen {
  width: calc(100% - var(--embedded-panel-width)) !important;
  /* Add transition for dialog width */
  transition: width 0.2s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
}

/* Disable pointer events on iframes during resizing */
body.panel-resizing iframe {
  pointer-events: none !important;
}

@media (max-width: 960px) {
  body.has-embedded-panel .v-dialog.v-dialog--fullscreen {
    width: 100% !important;
  }
}

/* Add visual feedback for touch users during resize */
body.panel-resizing .resize-handle {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

body.panel-resizing .resize-handle::after {
  background-color: rgba(0, 0, 0, 0.3) !important;
}
</style>
