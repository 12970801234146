<template>
  <Table
    :item="item"
    :readonly="true"
  />
</template>

<script>
import Table from "@/components/form/common/answerTypes/Table.vue";

export default {
  components: { Table },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
