var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600px","scrollable":""},model:{value:(_vm.localDialog),callback:function ($$v) {_vm.localDialog=$$v},expression:"localDialog"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex flex-column w-100"},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('span',[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.localDialog = false}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.$icons.REGULAR.ACTION.CLOSE)+" ")])],1)],1),_c('div',{staticClass:"d-flex flex-row align-center"},[_c('AppDefaultTextField',{staticClass:"pt-2 pr-6 font-weight-regular",attrs:{"hide-details":"","autofocus":"","append-icon":_vm.$icons.LIGHT.ACTION.SEARCH,"label":_vm.$t('common.search'),"clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('AppDefaultSwitch',{staticClass:"pa-0 ma-0",attrs:{"label":_vm.$t('defaultAnswer.showAnswer')},model:{value:(_vm.showItemContent),callback:function ($$v) {_vm.showItemContent=$$v},expression:"showItemContent"}})],1)])]),_c('v-card-text',{ref:"cardText",staticClass:"primary-text--text"},[_c('div',{staticStyle:{"height":"600px"}},[_c('AppDefaultDraggable',{staticClass:"pt-1",class:`
            ${
              _vm.showItemContent
                ? 'draggable-container-gap-8'
                : 'draggable-container-gap-4'
            }
            ${_vm.showItemContent ? 'mb-2' : 'mb-1'}
          `,attrs:{"ghostClass":_vm.$constant.DRAG_SETTINGS.GHOST_CLASS_DASHED_OUTLINE,"disabled":_vm.hasSearched},model:{value:(_vm.localDefaultValueTemplates),callback:function ($$v) {_vm.localDefaultValueTemplates=$$v},expression:"localDefaultValueTemplates"}},_vm._l((_vm.filteredDefaultValueTemplates),function(defaultValueTemplate,index){return _c('div',{key:defaultValueTemplate.id,staticClass:"draggable-item"},[_c('AppEditDefaultValueTemplateItem',{ref:`draggableEditableItem-${index}`,refInFor:true,attrs:{"item":defaultValueTemplate,"name":defaultValueTemplate.name,"labelName":defaultValueTemplate.labelName,"labelColor":defaultValueTemplate.labelColor,"value":defaultValueTemplate.value,"predefinedColors":_vm.predefinedColors,"hasSearched":_vm.hasSearched,"showItemContent":_vm.showItemContent},on:{"delete:click":function($event){return _vm.onDeleteClick({ defaultValueTemplate })},"data:update":(updatedField) =>
                  _vm.updateDefaultValueTemplate({
                    defaultValueTemplate: { ...defaultValueTemplate, ...updatedField },
                  }),"showItemContent:change":function($event){_vm.showItemContent = $event}}})],1)}),0),(_vm.isCreateActive)?_c('AppDefaultTextField',{ref:"createDefaultValueTemplateInput",attrs:{"backgroundColor":"ui-background","autofocus":"","hide-details":""},on:{"blur":_vm.onCreateDefaultValueTemplate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$refs.createDefaultValueTemplateInput.blur()}},model:{value:(_vm.newDefaultValueTemplateName),callback:function ($$v) {_vm.newDefaultValueTemplateName=$$v},expression:"newDefaultValueTemplateName"}}):_vm._e()],1)]),_c('v-card-actions',[_c('v-btn',{attrs:{"block":"","depressed":"","color":"primary"},on:{"click":function($event){_vm.isCreateActive = true}}},[_vm._v(" "+_vm._s(_vm.$t("common.create"))+" ")])],1)],1),_c('AppDeleteConfirmationDialog',{attrs:{"title":_vm.$t('answerTemplates.delete.title'),"subtitle":_vm.$t('answerTemplates.delete.subTitle'),"validator":_vm.$t('common.delete').toLowerCase(),"validatorText":_vm.$t('answerTemplates.delete.validatorText', {
        delete: _vm.$t('common.delete').toLowerCase(),
      })},on:{"delete":_vm.deleteDefaultValueTemplate},model:{value:(_vm.deleteConfirmationDialog.active),callback:function ($$v) {_vm.$set(_vm.deleteConfirmationDialog, "active", $$v)},expression:"deleteConfirmationDialog.active"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }