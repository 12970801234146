import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  me: null,
  isLoadingUserInitially: false,
  changePassword: {
    isChanging: false,
    error: "",
  },
});

const getters = {
  // Core User Data - Base level user information
  user: (state) => state.me?.user || null,
  currentUserId: (state, getters) => getters.user?.id || null,
  currentUserEmail: (state, getters) => getters.user?.email || null,

  // Domain Related - Domain management and hierarchy
  currentDomain: (state) => state.me?.currentDomain || null,
  domains: (state) => state.me?.domains || [],
  activeDomainId: (state, getters) => getters.currentDomain.id,
  invitedDomains: (state, getters) => getters.user?.invitedDomains || [],
  hasParentDomain: (state, getters) => getters.currentDomain?.hasParentDomain,

  // User Domain Context - User's relationship with current domain
  userInDomain: (state) => state.me?.userInDomain || null,
  isSupportingDomain: (state, getters) => getters.userInDomain?.auth?.isSupportingDomain,

  // User Permissions & Roles
  hasCmsAccess: (state, getters) => getters.user.auth?.hasCmsAccess,
  isSupportUser: (state, getters) => getters.user?.auth?.isSupportUser,

  // Landing Page
  landingPage: (state, getters) => getters.userInDomain?.landingPage || null,
  landingPageId: (state, getters) => getters.userInDomain?.landingPageId || null,

  // Subscription & Trial
  subscriptionPlan: (state, getters) => getters.currentDomain?.subscription,
  hasExtendedTrial: (state, getters) => getters.subscriptionPlan?.trial?.extended,

  // Enabled features
  features: (state, getters) => getters.currentDomain?.features || [],
  isTimeEntryFeatureEnabled: (state, getters) => getters.features?.isTimeEntryEnabled,
  isTimeEntryAdvancedFeatureEnabled: (state, getters) =>
    getters.features?.isTimeEntryAdvancedEnabled,
  isLoomRecordingFeatureEnabled: (state, getters) =>
    getters.features?.isLoomRecordingEnabled,
  isHseFeatureEnabled: (state, getters) => getters.features?.isHseEnabled,
  isQaFeatureEnabled: (state, getters) => getters.features?.isQaEnabled,
};

const actions = {
  async getMe({ commit, state }) {
    if (!state.me) commit("loadingInitially", true);
    return axios
      .get(`${apiUrl}auth/me`)
      .then(({ data }) => {
        localStorage.setItem("language", data.language);
        commit("setUser", data);
        return data;
      })
      .finally(() => {
        commit("loadingInitially", false);
      });
  },
  async changeActiveDomain({ commit }, domainId) {
    return axios.put(`${apiUrl}auth/active-domain`, { domainId }).then(({ data }) => {
      return data.me;
    });
  },
  async create({ commit }, payload) {
    return axios.post(`${apiUrl}auth/create-domain`, payload).then((res) => {
      return res;
    });
  },
  async changePassword({ commit }, data) {
    commit("changingPassword", true);
    commit("setChangePasswordError", "");
    return axios
      .patch(`${apiUrl}auth/change-password`, { ...data }, { skipErrorHandling: true })
      .then((res) => {
        commit("changingPassword", false);
      })
      .catch((error) => {
        commit("changingPassword", false);
        let message = error?.response?.data?.message || "Error";
        message = Array.isArray(message) ? message[0] : message;
        commit("setChangePasswordError", message);
        throw new Error(error);
      });
  },
};

const mutations = {
  setUser(state, me) {
    state.me = me;
  },
  loadingInitially(state, isLoading) {
    state.isLoadingUserInitially = isLoading;
  },
  setChangePasswordError(state, error) {
    state.changePassword.error = error;
  },
  changingPassword(state, isChangingPassword) {
    state.changePassword.isChanging = isChangingPassword;
  },
  updateUserLanguage(state, language) {
    if (state.me) {
      state.me.user.language = language;
    }
  },
};

export const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
