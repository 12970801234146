<template>
  <v-menu
    contentClass="more-bulk-action-menu"
    top
    left
    offset-y
    nudge-top="10"
    nudge-right="8"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
      >
        <AppMenuActionBtn
          v-bind="moreActionConfig.props"
          v-on="moreActionConfig.on"
          :actionType="$constant.ACTION_TYPES.BULK"
          :isMoreAction="true"
          dark
          small
          :selectedItems="selectedItems"
        />
      </div>
    </template>
    <v-card>
      <div
        class="pa-2"
        :class="{ 'bulk-action-grid': bulkActionConfigs.length > 1 }"
      >
        <AppMenuActionBtn
          v-for="(actionConfig, index) in bulkActionConfigs"
          :key="index"
          v-bind="actionConfig.props"
          v-on="actionConfig.on"
          :selectedItems="selectedItems"
          :actionType="$constant.ACTION_TYPES.BULK"
          :isMoreAction="true"
        />
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import AppMenuActionBtn from "./AppMenuActionBtn.vue";

export default {
  components: {
    AppMenuActionBtn,
  },
  props: {
    bulkActionConfigs: {
      type: Array,
      required: true,
    },
    selectedItems: {
      type: Array,
      required: true,
    },
  },
  computed: {
    moreActionConfig() {
      const KEY = this.$constant.ACTION_KEYS.MORE;
      return this.$constant.COMMON_ACTIONS.find((action) => action.key === KEY);
    },
  },
};
</script>
