import { getInstance } from "@/auth";
import store from "@/store";

const apiUrl = process.env.VUE_APP_API_URL;

export async function uploadFiles(files, uploadType, uploadTypeId, onProgress) {
  const authService = getInstance();
  const token = authService.isAuthenticated ? await authService.getTokenSilently() : null;
  const domainId = store.getters["auth/activeDomainId"];

  const headers = {
    Authorization: token ? `Bearer ${token}` : undefined,
    domain: domainId,
  };

  const promises = files.map((file) =>
    uploadFile(file, uploadType, uploadTypeId, onProgress, headers),
  );
  return await Promise.all(promises);
}

export function uploadFile(file, uploadType, uploadTypeId, onProgress, headers) {
  return new Promise((resolve, reject) => {
    const worker = new Worker(new URL("./uploadWorker.js", import.meta.url));

    worker.onmessage = (event) => {
      const { type, data } = event.data;
      switch (type) {
        case "progress":
          if (onProgress) onProgress(data);
          break;
        case "success":
          resolve(data);
          worker.terminate();
          break;
        case "error":
          reject(data);
          worker.terminate();
          break;
      }
    };

    worker.postMessage({
      file,
      uploadType,
      uploadTypeId,
      apiUrl,
      headers,
    });
  });
}
