<template>
  <tr
    class="list_tr list_tr-height"
    :class="rowStateClasses"
    @contextmenu="dataTableItemMixins_onRightClick"
  >
    <AppDefaultTableCell
      v-for="(header, index) in headers"
      :key="index"
      :header="header"
      :isSelected="isSelected"
      :select="select"
      :index="index"
      :item="form"
    >
      <template v-if="header.value == dataFields.STATUS">
        <AppCustomStatusIcon
          model="form"
          :statusId="statusId"
          :loading="form?.isUpdatingStatus"
          :disabled="form?.isUpdatingStatus"
        />
      </template>

      <template v-if="header.value === dataFields.NAME">
        <div class="table-cell-content__breakable-long-text">
          {{ form.name }}
        </div>
      </template>

      <template v-if="header.value === dataFields.SIGNATURE_STATUS">
        <AppFormSignatureSessionStatusChip
          :signatureSession="form.signatureSession"
          @click.stop="$emit('signature:click', { form })"
        />
      </template>

      <template v-if="header.value === dataFields.PROGRESS">
        <AppFormProgressionChip :progress="form.progress" />
      </template>

      <template v-if="header.value === dataFields.OWNER">
        <template v-if="form.owner">
          <AppUserAvatar
            :user="form.owner"
            show-name
            :truncate="20"
          />
        </template>
      </template>

      <template v-if="header.value === dataFields.PROJECT">
        <div
          v-if="form?.project?.id"
          class="table-cell-content__breakable-long-text"
        >
          <AppActionText
            :to="{
              name: $routeNames.PROJECT.VIEW,
              params: { projectId: form?.project?.id },
            }"
          >
            {{ form.project.name }}
          </AppActionText>
        </div>
      </template>

      <template v-if="header.value === dataFields.TASK">
        <div
          v-if="form?.task?.id"
          class="table-cell-content__breakable-long-text"
        >
          <AppActionText
            :to="{
              name: $routeNames.TASK_VIEW.ROOT,
              params: { taskId: form?.task?.id },
            }"
          >
            {{ taskName }}
          </AppActionText>
        </div>
      </template>

      <template v-if="header.value === dataFields.CATEGORY">
        <AppColorChip
          v-if="form.category"
          :text="
            $te(`formManagement.category.${form.category.name}`)
              ? $t(`formManagement.category.${form.category.name}`)
              : form.category.name
          "
          color="info--text"
        />
      </template>

      <template v-if="header.value === dataFields.DUE_DATE">
        <template v-if="form.dueDate">
          <div>{{ formatDueDate(form.dueDate) }}</div>
        </template>
      </template>

      <template v-if="header.value === dataFields.MENU">
        <v-btn
          icon
          @click="dataTableItemMixins_onEllipsisClick"
          ref="ellipsis"
        >
          <v-icon>{{ $icons.REGULAR.ACTION.MENU }}</v-icon>
        </v-btn>
      </template>
    </AppDefaultTableCell>
  </tr>
</template>

<script>
import moment from "moment";
import { dataTableItemMixins } from "@/helpers/mixins";
import { dataFieldsMixin } from "@/helpers/mixins/dataFieldsMixin";

export default {
  mixins: [dataTableItemMixins, dataFieldsMixin],
  props: {
    form: Object,
    selectedFormId: String,
    isHighlighted: Boolean,
    tableModel: String,
    headers: Array,
    select: Function,
    isSelected: Boolean,
    isContextMenuActiveItem: Boolean,
    isActiveItem: Boolean,
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    taskName() {
      return this.form?.task?.name;
    },
    rowStateClasses() {
      return {
        "list_tr--active": this.isContextMenuActiveItem,
        "list_tr--selected": this.isSelected,
        "list_tr--success": this.isActiveItem,
      };
    },
    statusId() {
      return this.form?.statusId;
    },
  },
  methods: {
    openMenu(params) {
      this.$emit("menu:click", { form: this.form, ...params });
    },
    formatDueDate(date) {
      return date ? moment(date).format("DD.MM.YYYY") : "";
    },
  },
};
</script>
