import { ROUTE_NAMES, ROUTE_PERMISSIONS } from "@/helpers/routeNames";
import multiguard from "../multiguard";
import { makeBeforeEnter, redirectFromLocalStorage } from "../redirect";
import { authGuard, userGuard } from "@/auth";
import { HELP_CATEGORIES } from "@/helpers/constants/help";

const { ROOT, LIST, CALENDAR } = ROUTE_NAMES.PROJECT;

//project redirect
const projectLocalStorageKey = `REDIRECT_ROUTE:${ROOT}`;
const beforeEnterProject = makeBeforeEnter({ localStorageKey: projectLocalStorageKey });

export const projectRoutes = [
  {
    path: "/project",
    name: ROOT,
    meta: {
      permission: ROUTE_PERMISSIONS.PROJECT,
      helpCategory: HELP_CATEGORIES.PROJECT,
    },
    beforeEnter: multiguard([authGuard, userGuard]),
    component: () => import(/* webpackChunkName: "project" */ "../../pages/ProjectPage"),
    redirect: () =>
      redirectFromLocalStorage({
        localStorageKey: projectLocalStorageKey,
        validRoutes: [LIST, CALENDAR],
        defaultRoute: LIST,
      }),
    children: [
      {
        path: "list",
        name: LIST,
        meta: { permission: ROUTE_PERMISSIONS.PROJECT },
        beforeEnter: beforeEnterProject,
        component: () =>
          import(
            /* webpackChunkName: "project" */ "../../pages/ProjectPage/ProjectListPage"
          ),
      },
      {
        path: "calendar",
        name: CALENDAR,
        meta: { permission: ROUTE_PERMISSIONS.PROJECT },
        beforeEnter: beforeEnterProject,
        component: () =>
          import(
            /* webpackChunkName: "project" */ "../../pages/ProjectPage/ProjectCalendarPage"
          ),
      },
    ],
  },
];
