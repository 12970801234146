<script>
import { VIcon } from "vuetify/lib";
import { VBtn } from "vuetify/lib";
import { VSpacer } from "vuetify/lib";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      default: 40,
    },
  },
  render(h) {
    return h(
      "div",
      {
        class: "text-caption font-weight-medium d-flex align-center pb-2",
        style: `height: ${this.height}px`,
      },
      [
        h("div", { class: "subtitle--text" }, [this.title]),
        h(VSpacer),
        h("div", { class: "d-flex align-center" }, [this.$scopedSlots.append()]),
      ],
    );
  },
};
</script>
