import { MODULE_FIELD_CONFIG } from "@/helpers/constants";
import { formatItemWithSearchStr } from "@/helpers/util/moduleItem";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function getSearchForms({ params } = {}) {
  return axios
    .get(`${apiUrl}search/form`, {
      params: {
        ...params,
        fields: MODULE_FIELD_CONFIG.FORM.FIELDS_QUERY_PARAM,
      },
    })
    .then(({ data }) => {
      const forms = data?.forms;
      const formWithSearchStr = formatItemWithSearchStr(forms, "FORM");
      return formWithSearchStr;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
