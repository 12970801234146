<template>
  <v-dialog
    v-model="localDialog"
    scrollable
    max-width="600px"
  >
    <v-card v-if="dialog">
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <v-form
          ref="newProductForm"
          v-model="isValid"
          v-on:submit.prevent
          @submit="onSubmit"
          class="primary-text--text"
        >
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("common.productFields.name") }}
            </div>
            <AppDefaultTextField
              ref="nameRef"
              autofocus
              v-model="product.name.value"
              :rules="product.name.rules"
            />
          </div>
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("common.productFields.productNumber") }}
            </div>
            <AppDefaultTextField
              v-model="product.code.value"
              :rules="product.code.rules"
            />
          </div>
          <div class="p-relative">
            <div class="label--small">
              {{ $t("common.productFields.supplier") }}
            </div>
            <AppClientAutocomplete
              v-model="product.clientId.value"
              :type="$constant.SUPPLIER"
              isReadyToInitialFetch
              :queryParams="{
                isActive: true,
                alwaysIncludeIds: [data?.clientId],
              }"
              @created="onClientCreated"
            />
          </div>
          <div class="p-relative">
            <div class="label--small">
              {{ $t("common.productFields.unitPrice") }}
            </div>
            <AppCurrencyTextField
              v-if="dialog"
              v-model="product.unitPrice.value"
            />
          </div>

          <div class="p-relative">
            <div class="label--small">
              {{ $t("common.productFields.unit") }}
            </div>
            <AppDefaultAutocomplete
              v-model="product.unit.value"
              :items="units"
              :loading="isUnitsLoading"
              item-text="label"
              item-value="value"
            />
          </div>
          <div class="p-relative">
            <div class="label--small">
              {{ $t("common.productFields.status") }}
            </div>
            <AppDefaultSelect
              v-if="dialog"
              :clearable="false"
              v-model="product.isActive.value"
              :items="isActiveItems"
              item-text="text"
              item-value="value"
            />
          </div>
        </v-form>
        <AppCheckToKeepOpen
          v-model="keepOpen"
          v-if="!isEditMode"
        />
      </v-card-text>
      <v-card-actions>
        <div class="w-100">
          <AppErrorAlert
            class="mb-2"
            v-if="errorMessage"
          >
            {{ errorMessage }}
          </AppErrorAlert>
          <div>
            <AppDialogActionBtnPair
              @confirm="onSubmit"
              @cancel="onCancel"
              :confirmText="$t(isEditMode ? 'common.confirm' : 'common.create')"
              :loading="isSubmitting"
            />
          </div>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    dialog: Boolean,
    data: Object,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      isValid: null,
      isSubmitting: false,
      errorMessage: null,
      product: {
        name: {
          value: null,
          rules: this.$rules.REQUIRED,
        },
        code: {
          value: null,
          rules: this.$rules.REQUIRED,
        },
        clientId: {
          value: null,
          rules: [],
        },
        unit: {
          value: null,
          rules: [],
        },
        unitPrice: {
          value: null,
          rules: [],
        },
        isActive: {
          value: true,
          rules: [],
        },
      },
      isActiveItems: [
        { text: this.$t("common.active"), value: true },
        { text: this.$t("common.inactive"), value: false },
      ],
      keepOpen: false,
    };
  },
  watch: {
    dialog: {
      handler(newVal) {
        if (newVal) {
          this.fillFormWithData();
          this.getUnits();
          this.$nextTick(() => {
            this.$refs.newProductForm.resetValidation();
          });
        }
      },
    },
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    isEditMode() {
      return !!this.data;
    },
    ...mapState("productUnits", {
      units: (state) => state.units,
      isUnitsLoading: (state) => state.isLoading,
    }),
    title() {
      return this.$t(
        this.isEditMode
          ? "common.dialogs.editProduct.title"
          : "common.dialogs.createProduct.title",
      );
    },
  },
  methods: {
    fillFormWithData() {
      const data = this.data;
      this.product.isActive.value = true;

      if (!data) return;
      this.product.name.value = data.name;
      this.product.code.value = data.code;
      this.product.clientId.value = data.client?.id || null;
      this.product.unit.value = data.unit.value;
      this.product.unitPrice.value = data.unitPrice;
      this.product.isActive.value = data.isActive;
    },
    onClientCreated({ client }) {
      this.product.clientId.value = client.id;
    },
    async getUnits() {
      await this.$store.dispatch("productUnits/getProductUnits");
    },
    onDialogClose() {
      this.localDialog = false;
      setTimeout(() => {
        this.keepOpen = false;
      }, 100);
    },
    validate() {
      this.isValid = this.$refs.newProductForm.validate();
    },
    mapObject(obj, func) {
      return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => {
          if (key === "unitPrice" && func(value) != null) {
            return [key, parseFloat(func(value))];
          }
          return [key, func(value)];
        }),
      );
    },
    createOrUpdateProduct() {
      const storeType = this.isEditMode
        ? "products/updateProduct"
        : "products/createProduct";
      const body = this.mapObject(this.product, (val) => val.value);
      this.isSubmitting = true;
      this.errorMessage = "";
      this.$store
        .dispatch(storeType, { body, id: this.data?.id })
        .then(() => {
          this.afterSubmit();
        })
        .catch((error) => {
          this.errorMessage = error.message;
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },

    onCancel() {
      this.onDialogClose();
      this.$refs.newProductForm.resetValidation();
    },
    onSubmit() {
      this.validate();
      if (!this.isValid) return;
      this.createOrUpdateProduct();
    },
    afterSubmit() {
      if (!this.keepOpen) {
        this.onDialogClose();
      }
      this.product.name.value = null;
      this.product.code.value = null;
      this.product.clientId.value = null;
      this.product.unit.value = null;
      this.product.unitPrice.value = null;
      this.product.isActive.value = true;
      this.$refs.newProductForm.resetValidation();
      this.$refs.nameRef.focus();
    },
  },
};
</script>
