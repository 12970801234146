<template>
  <div>
    <AppDeleteConfirmationDialog
      v-model="dialog.deleteDialog.active"
      :item="dialog.deleteDialog.item"
      :title="$tc('cms.template.form.delete.title', dialog.deleteDialog.item.length)"
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('fileManagement.fileExplorer.deleteConfirmation.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="templateDelete"
    />
    <AppCopyItemsDialog
      v-model="dialog.copyDialog.active"
      :inItems="dialog.copyDialog.items"
      type="FORM_TEMPLATE"
      @submit="onTemplateCopy"
    />
    <AppDataTable
      :loading="isLoading"
      :headers="defaultHeaders"
      :items="templates"
      :noDataText="$t('common.noTemplates')"
      :actionConfigs="actionConfigs"
      :removeMutationType="removeMutationType"
      :updateMutationType="updateMutationType"
      :statsText="statsText"
      :isStatsLoading="isStatsLoading"
    >
      <template v-slot:item="{ item, headers, select, isSelected }">
        <AppFormTemplateTableItem
          :template="item"
          :isSelected="isSelected"
          :select="select"
          :headers="headers"
          :isContextMenuActiveItem="isContextMenuActiveItem(item)"
          @menu:click="openContextMenu"
        />
      </template>
    </AppDataTable>
    <AppDataTableContextMenu
      ref="contextMenu"
      :actionConfigs="actionConfigs"
      :items="[contextMenuActiveItem]"
      @input="onContextMenuChange"
    />
    <AppIsActivePickerMenu
      ref="isSignableMenu"
      @submit="({ isActive }) => onTemplatesUpdate({ hasDefaultSignature: isActive })"
    />
    <AppIsActivePickerMenu
      ref="shareableMenu"
      :activeLabelKey="$t('common.shareable')"
      :inactiveLabelKey="$t('common.notShareable')"
      @submit="
        ({ isActive }) => onTemplatesUpdate({ isShareableWithChildDomains: isActive })
      "
    />
    <AppIsActivePickerMenu
      ref="publishedStatusMenu"
      :activeLabelKey="$t('common.published')"
      :inactiveLabelKey="$t('common.unpublished')"
      @submit="({ isActive }) => onTemplatesUpdate({ isPublished: isActive })"
    />
    <AppFormCategoryPickerMenu
      ref="categoryMenu"
      @submit="({ categoryId }) => onTemplatesUpdate({ categoryId })"
    />
  </div>
</template>

<script>
import { createTableHeaders } from "@/helpers/util";
import { permissionMixins } from "@/helpers/mixins";
import { mapGetters } from "vuex";

export default {
  mixins: [permissionMixins],
  props: {
    templates: Array,
    isLoading: Boolean,
    isCms: Boolean,
    statsText: String,
    isStatsLoading: Boolean,
  },
  data() {
    return {
      assignableUsers: [],
      isAssignableUsersLoading: false,
      assignableProjects: [],
      isAssignableProjectsLoading: false,
      dialog: {
        deleteDialog: {
          active: false,
          item: [],
        },
        copyDialog: {
          active: false,
          items: [],
        },
      },
      contextMenuActiveItem: null,
      itemsToEdit: [],
    };
  },
  computed: {
    ...mapGetters("auth", {
      hasParentDomain: "hasParentDomain",
    }),
    removeMutationType() {
      return this.isCms
        ? "cmsFormTemplates/removeTemplate"
        : "formTemplates/removeTemplate";
    },
    updateMutationType() {
      return this.isCms
        ? "cmsFormTemplates/updateTemplate"
        : "formTemplates/updateTemplate";
    },
    canEditFormTemplate() {
      return this.permissionMixins_formTemplate.edit.can;
    },
    shareableWithChildDomainPermissions() {
      return this.permissionMixins_formTemplate.shareableWithChildDomain;
    },
    canViewShareableWithChildDomain() {
      return this.shareableWithChildDomainPermissions.view.can && !this.isCms;
    },
    canEditShareableWithChildDomain() {
      return this.shareableWithChildDomainPermissions.edit.can;
    },
    actions() {
      const {
        GO_TO,
        DELETE,
        COPY,
        EDIT_CATEGORY,
        EDIT_PUBLISHED_STATUS,
        EDIT_IS_SIGNABLE,
        EDIT_IS_SHAREABLE,
      } = this.$constant.ACTION_KEYS;

      const openMenu = (menuRef) => (e) => this.openEditMenu({ ...e, menuRef });

      return {
        [GO_TO]: {
          click: this.onTemplateEditClick,
        },
        [COPY]: {
          click: this.onCopyClick,
        },
        [EDIT_CATEGORY]: {
          click: openMenu("categoryMenu"),
          disabled: !this.canEditFormTemplate,
        },
        [EDIT_IS_SIGNABLE]: {
          click: openMenu("isSignableMenu"),
          disabled: !this.canEditFormTemplate,
        },
        [EDIT_PUBLISHED_STATUS]: {
          click: openMenu("publishedStatusMenu"),
          disabled: !this.canEditFormTemplate,
        },
        [EDIT_IS_SHAREABLE]: {
          click: openMenu("shareableMenu"),
          disabled: !this.canEditShareableWithChildDomain,
        },
        [DELETE]: {
          click: this.onTemplateDelete,
        },
      };
    },
    actionConfigs() {
      const {
        GO_TO,
        DELETE,
        COPY,
        EDIT_CATEGORY,
        EDIT_PUBLISHED_STATUS,
        EDIT_IS_SIGNABLE,
        EDIT_IS_SHAREABLE,
      } = this.$constant.ACTION_KEYS;

      const withMenuContext = (clickHandler) => (e) => {
        clickHandler({
          direction: e.hasSubMenu ? "right" : "top",
          parentMenuRef: e.hasSubMenu ? this.$refs.contextMenu : null,
          ...e,
        });
      };

      const actions = this.actions;

      const customActions = [
        {
          key: GO_TO,
          on: {
            click: actions[GO_TO].click,
          },
        },
        {
          key: COPY,
          props: {
            hidden: this.isCms,
          },
          on: {
            click: actions[COPY].click,
          },
        },

        {
          key: EDIT_CATEGORY,
          props: {
            hidden: this.isCms,
            disabled: actions[EDIT_CATEGORY].disabled,
          },
          on: {
            click: withMenuContext(actions[EDIT_CATEGORY].click),
          },
        },
        {
          key: EDIT_IS_SIGNABLE,
          props: {
            hidden: this.isCms,
            disabled: actions[EDIT_IS_SIGNABLE].disabled,
          },

          on: {
            click: withMenuContext(actions[EDIT_IS_SIGNABLE].click),
          },
        },
        {
          key: EDIT_PUBLISHED_STATUS,
          props: {
            hidden: this.isCms,
            disabled: actions[EDIT_PUBLISHED_STATUS].disabled,
          },

          on: {
            click: withMenuContext(actions[EDIT_PUBLISHED_STATUS].click),
          },
        },
        {
          key: EDIT_IS_SHAREABLE,
          props: {
            hidden: !this.canViewShareableWithChildDomain,
            disabled: actions[EDIT_IS_SHAREABLE].disabled,
          },

          on: {
            click: withMenuContext(actions[EDIT_IS_SHAREABLE].click),
          },
        },
        {
          key: DELETE,
          on: {
            click: actions[DELETE].click,
          },
        },
      ];

      const config = this.$constant.generateActionConfig({
        primaryActions: customActions,
        secondaryActions: this.$constant.FORM_TEMPLATE_ACTIONS,
      });

      return config;
    },
    defaultHeaders() {
      const { GO_TO, EDIT_PUBLISHED_STATUS, EDIT_CATEGORY, EDIT_IS_SHAREABLE } =
        this.$constant.ACTION_KEYS;

      return createTableHeaders([
        {
          preset: "SELECT",
        },
        {
          preset: "NAME",
          text: this.$t("cms.template.form.name"),
          value: "name",
          ...this.actions[GO_TO],
        },

        {
          text: this.$t("common.status"),
          value: "isPublished",
          ...this.actions[EDIT_PUBLISHED_STATUS],
        },
        {
          text: this.$t("common.category"),
          value: "category.name",
          ...this.actions[EDIT_CATEGORY],
        },
        {
          access: this.hasParentDomain,
          text: this.$t("common.itemOrigin"),
          value: "origin",
          compact: true,
        },
        {
          access: this.canViewShareableWithChildDomain,
          text: this.$t("common.shareable"),
          value: "isShareableWithChildDomains",
          ...this.actions[EDIT_IS_SHAREABLE],
        },
        {
          preset: "DATE",
          text: this.$t("common.lastUpdated"),
          value: "updatedAt",
          disabled: true,
        },
        {
          preset: "MENU",
        },
      ]);
    },
  },
  methods: {
    openEditMenu(e) {
      const { items, menuRef } = e;
      this.$refs[menuRef].open(e);
      this.itemsToEdit = items;
    },
    onContextMenuChange(isOpen) {
      if (!isOpen) {
        this.contextMenuActiveItem = null;
      }
    },
    isContextMenuActiveItem(item) {
      return this.contextMenuActiveItem?.id === item?.id;
    },
    onTemplateEditClick({ items }) {
      const template = items?.[0];
      this.$emit("template:editClick", { template });
    },
    onTemplateDelete({ items }) {
      this.dialog.deleteDialog.active = true;
      this.dialog.deleteDialog.item = items;
    },
    templateDelete({ item }) {
      this.$emit("template:delete", { items: item });
    },
    onTemplatesUpdate(value) {
      const body = { ...value, ids: this.itemsToEdit.map((item) => item.id) };
      this.$emit("templates:update", { body });
    },
    onCopyClick({ items }) {
      this.dialog.copyDialog.active = true;
      this.dialog.copyDialog.items = items;
    },
    onTemplateCopy(items) {
      this.$emit("template:copy", { items });
    },
    openContextMenu({ from, template }) {
      this.$refs.contextMenu.open({ from });
      this.$nextTick(() => {
        this.contextMenuActiveItem = template;
      });
    },
  },
};
</script>
