<template>
  <div>
    <AppNavigationList>
      <AppNavigationItem
        v-if="canView($routeNames.TEMPLATE.FORM_TEMPLATES)"
        :to="{ name: $routeNames.TEMPLATE.FORM_TEMPLATES }"
        :prependIcon="$icons.REGULAR.ROUTE.FORM"
        :title="$t('drawer.route.formTemplates')"
        prepend-icon-small
        replace
      />
      <AppNavigationItem
        v-if="canView($routeNames.TEMPLATE.PROJECT_TEMPLATES)"
        :to="{ name: $routeNames.TEMPLATE.PROJECT_TEMPLATES }"
        :prependIcon="$icons.REGULAR.ROUTE.PROJECT"
        :title="$t('drawer.route.projectTemplates')"
        prepend-icon-small
        replace
      />
    </AppNavigationList>
  </div>
</template>

<script>
import { permissionHelpers } from "@/helpers/util";

export default {
  methods: {
    canView(routeName) {
      return permissionHelpers.canViewRoute(routeName);
    },
  },
};
</script>
