<template>
  <v-btn
    depressed
    class="custom-tab border-accent"
    :class="{
      'custom-tab-active': isActive,
    }"
    style="border-radius: 4px 4px 0 0 !important"
    color="background"
    v-bind="$attrs"
    v-on="$listeners"
    :disabled="disabled"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: "AppResourceViewTab",
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  inheritAttrs: false,
};
</script>

<style scoped>
.custom-tab {
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: none !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.custom-tab-active {
  color: var(--v-primary-base, #1976d2) !important;
}
</style>
