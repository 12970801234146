import { withPrefix } from "../util/icon";

export const REGULAR = withPrefix("regular", {
  //if route and action. Icons closely tied to routes
  ROUTE: {
    FORM: "ballot-check",
    EVENT_LOG: "clock-rotate-left",
    OVERVIEW: "circle-info",
    PROJECT: "lightbulb-on",
    DOCUMENT_TEMPLATES: "file",
    STORAGE: "folder",
    HOME: "house",
    DASHBOARD: "chart-simple",
    TASK: "calendar-day",
    CONTACT: "address-book",
    USER: "user-helmet-safety",
    MEMBER: "user-helmet-safety",
    INBOX: "inbox",
    TEAM: "users",
    TEMPLATE: "clone",
    SUB_PROJECT: "diagram-project",
    EBYGGESOK: "file-signature",
    PRODUCT: "boxes-stacked",
    MODULE_CONTROL: "bolt",
    BILLING: "file-invoice",
    WORKSPACE: "table-list",
    NOTIFICATION: "bell",
    SETTINGS: "gear",
    SYSTEM_SETTINGS: "wrench",
    ACCOUNT_SETTINGS: "buildings",
    INTEGRATION_SETTINGS: "grid-2-plus",
    PROFILE_SETTINGS: "circle-user",
    TIME_REGISTRATION: "clock",
    COMMENT: "messages",
    CORPORATE_MANAGEMENT: "sitemap",
  },
  //if only action. icons closely tied to actions
  ACTION: {
    EDIT: "pen",
    DELETE: "trash-can",
    CLOSE: "xmark",
    CLEAR: "circle-xmark",
    DOWNLOAD: "download",
    UPLOAD_FILE: "file-arrow-up",
    GET_DOCUMENT_TEMPLATE: "file-arrow-down",
    MENU: "ellipsis-vertical",
    CIRCLE_MENU: "circle-ellipsis-vertical",
    RESET: "arrow-rotate-left",
    RESET_TO_LAST: "clock-rotate-left",
    REFRESH: "arrows-rotate",
    ADD: "plus",
    NOTIFICATION: "bell",
    POP_OUT: "up-right-from-square",
    OPEN_WITH: "up-down-left-right",
    LOG_OUT: "right-from-bracket",
    ACCEPT: "check",
    WARNING: "triangle-exclamation",
    EMAIL: "envelope",
    PUSH_NOTIFICATIONS: "mobile",
    LINK: "link",
    REPEAT: "repeat",
    COLOR: "droplet",
    CUSTOM_COLOR: "eye-dropper",
    CONFIGURE: "gear",
    DROPDOWN: "caret-down",
    EMPTY: "minus",
    DUE_DATE: "alarm-clock",
    START_DATE: "flag-checkered",
    CATEGORY: "shapes",
    FORM_SECTION: "distribute-spacing-vertical",
    FILTER: "bars-filter",
    ADD_WIDGETS: "grid-2-plus",
    SETTINGS: "gear",
    INFO: "circle-info",
    NO_TASK: "$regularCalendarDaySlash",
    SWITCH: "rotate",
    GO_PREV_LEVEL: "circle-arrow-left",
    SIGNATURE: "signature",
    STATUS: "square",
    COPY: "copy",
    SEND_TO_ACCOUNTING: "arrow-up",
    UNIT_TYPE: "ruler-triangle",
    CLIENT: "handshake",
    CUSTOMER: "user-tie",
    SUPPLIER: "truck-field",
    UNIT_PRICE: "coins",
    PIN: "thumbtack",
    START: "play",
    FREEZE: "snowflake",
    ACCESS_CONTROL: "lock",
    ANALYTICS: "chart-line",
    ROLE: "user-shield",
    INVITE: "envelope",
    TABLE: "table",
    FEEDBACK: "message-smile",
  },
  FILE: {
    FILE_LINKED: "network-wired",
    FILE_PDF: "file-pdf",
    FILE_IMAGE: "file-image",
    FILE_WORD: "file-word",
    FILE_EXCEL: "file-excel",
    FILE_POWERPOINT: "file-powerpoint",
    FILE_LINES: "file-lines",
    FILE_SIGNATURE: "file-signature",
    FILE_INVOICE: "file-invoice",
    FILE_EXPORT: "file-export",
    FILE_PLUS: "file-plus",
    FILE_CODE: "file-code",
    FILE: "file",
    FOLDER: "folder",
    FOLDER_PLUS: "folder-plus",
  },
  COMMON: {
    ANGLE_LEFT: "angle-left",
    ANGLE_RIGHT: "angle-right",
    ANGLE_DOWN: "angle-down",
    ARROW_LEFT: "arrow-left",
    ARROW_UP: "arrow-up",
    ARROW_RIGHT: "arrow-right",
    ARROW_DOWN: "arrow-down",
    ENVELOPE: "envelope",
    USER_SLASH: "user-slash",
    SIGNATURE_SLASH: "signature-slash",
    LOCATION_DOT: "location-dot",
    LOCATION_DOT_SLASH: "location-dot-slash",
    EYE: "eye",
    BOLT: "bolt",
    USER_CIRCLE: "user-circle",
    CIRCLE_QUESTION: "circle-question",
    CIRCLE_CHECK: "circle-check",
    SQUARE_CHECK: "square-check",
    SQUARE: "square",
    BOOK_OPEN_READER: "book-open-reader",
    LOCK: "lock",
    LOCK_OPEN: "lock-open",
    CALENDAR: "calendar",
    CALENDAR_DAYS: "calendar-days",
    CALENDAR_DAY: "calendar-day",
    CALENDAR_WEEK: "calendar-week",
    TOGGLE_ON: "toggle-on",
    TOGGLE_OFF: "toggle-off",
    HOURGLASS_START: "hourglass-start",
    HOURGLASS_HALF: "hourglass-half",
    HOURGLASS_END: "hourglass-end",
    NOTE_STICKY: "note-sticky",
    DICE: "dice",
    TEXT: "text",
    RIGHT_LONG: "right-long",
    MERGE: "merge",
    TOGGLE_LARGE_ON: "toggle-large-on",
    TOGGLE_LARGE_OFF: "toggle-large-off",
    USER: "user",
    USERS: "users",
    USER_GROUP: "user-group",
    WINDOW_RESTORE: "window-restore",
    WINDOW_MAXIMIZE: "window-maximize",
    ALIGN_LEFT: "align-left",
    ROCKET: "rocket",
  },
});
