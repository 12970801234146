<script>
import moment from "moment";

export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  methods: {
    genCategoryRow() {
      const label = this.$t("common.category");
      const value = this.$te(`formManagement.category.${this.form?.category?.name}`)
        ? this.$t(`formManagement.category.${this.form?.category?.name}`)
        : this.form?.category?.name;
      return this.genInfoRow(label, value);
    },
    genIdRow() {
      return this.genInfoRow("ID", `${this.form?.number}`);
    },
    genCreatedAtRow() {
      const label = this.$t("formManagement.preview.createdAt");
      const value = moment(this.form?.createdAt).format("DD.MM.YYYY HH:mm");
      return this.genInfoRow(label, value);
    },
    genCreatedByRow() {
      const label = this.$t("formManagement.preview.createdBy");
      const value = this.$createElement("AppUserAvatar", {
        props: {
          user: this.form.owner,
          showName: true,
          size: "32",
          truncate: 55,
        },
      });
      return this.genInfoRow(label, value);
    },
    genDueDateRow() {
      const label = this.$t("formManagement.preview.dueDate");
      const value = this.form?.dueDate
        ? moment(this.form.dueDate).format("DD.MM.YYYY")
        : null;
      return this.genInfoRow(label, value);
    },
    genResponsibleRow() {
      const label = this.$t("formManagement.preview.responsible");
      const value = this.form?.owner
        ? this.$createElement("AppUserAvatar", {
            props: {
              user: this.form.owner,
              showName: true,
              size: "32",
              truncate: 55,
            },
          })
        : null;
      return this.genInfoRow(label, value);
    },
    genTaskRow() {
      const label = this.$options.filters.capitalize(this.$t("common.task"));
      const value = this.form?.task?.name
        ? this.$createElement(
            "AppActionText",
            {
              props: {
                to: {
                  name: this.$routeNames.TASK_VIEW.ROOT,
                  params: { taskId: this.form?.task?.id },
                },
              },
            },
            this.form?.task?.name,
          )
        : null;
      return this.genInfoRow(label, value);
    },
    genProjectNumberRow() {
      const label = this.$t("formManagement.preview.projectNumber");
      const value = this.form?.project?.projectNumber;
      return this.genInfoRow(label, value);
    },
    genProjectRow() {
      const label = this.$t("common.project");
      const value = this.form?.project
        ? this.$createElement(
            "AppActionText",
            {
              props: {
                to: {
                  name: this.$routeNames.PROJECT.VIEW,
                  params: { projectId: this.form.project.id },
                },
              },
            },
            this.form.project.name,
          )
        : null;
      return this.genInfoRow(label, value);
    },
    genProjectAddressRow() {
      const label = this.$t("formManagement.preview.projectAddress");
      const value = this.form?.project?.address;
      return this.genInfoRow(label, value);
    },
    genCustomerRow() {
      const label = this.$t("formManagement.preview.customer");
      const value = this.form?.project?.client?.name;
      return this.genInfoRow(label, value);
    },
    genTotalQuestionsRow() {
      const label = this.$t("formManagement.preview.totalQuestions");
      const value = this.$t("formManagement.preview.questionCount", {
        count: this.form?.progress?.questions,
      });
      return this.genInfoRow(label, value);
    },
    genTotalAnswersRow() {
      const label = this.$t("formManagement.preview.totalAnswers");
      const value = this.$t("formManagement.preview.answerCount", {
        count: this.form?.progress?.answers,
      });
      return this.genInfoRow(label, value);
    },
    genInfoRow(label, value) {
      if (!value) return null;
      return this.h(
        "AppFormPreviewInfoRow",
        {
          props: {
            label,
          },
        },
        [value],
      );
    },
  },
  render(h) {
    this.h = h;
    const firstColumnRows = [
      this.genCategoryRow(),
      this.genIdRow(),
      this.genCreatedAtRow(),
      this.genCreatedByRow(),
      this.genDueDateRow(),
      this.genResponsibleRow(),
      this.genTaskRow(),
      this.genProjectNumberRow(),
      this.genProjectRow(),
      this.genProjectAddressRow(),
      this.genCustomerRow(),
    ];

    const secondColumnRows = [this.genTotalQuestionsRow(), this.genTotalAnswersRow()];

    return h("div", { class: "pb-6" }, [
      h("div", { class: "pb-6" }, firstColumnRows),
      h("div", [
        h("div", { class: "text-h6 pb-2" }, this.$t("formManagement.preview.statistics")),
        ...secondColumnRows,
      ]),
    ]);
  },
};
</script>
