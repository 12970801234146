<script>
import { VCard, VDivider, VListItem } from "vuetify/lib";
import { formTemplateBuilderMixins, permissionMixins } from "@/helpers/mixins";
import SectionHeader from "./SectionHeader.vue";
import ItemHierarchy from "./ItemHierarchy.vue";
import Signatures from "./Signatures.vue";
import AppDeleteConfirmationDialog from "../../dialogs/AppDeleteConfirmationDialog.vue";
import CantEditInfoAlert from "./CantEditInfoAlert.vue";

export default {
  components: {
    CantEditInfoAlert,
  },
  mixins: [formTemplateBuilderMixins, permissionMixins],
  props: {
    form: Object,
    formItems: Array,
    isLoading: Boolean,
    isCreatingSignature: Boolean,
    assignableUsers: Array,
    isSignable: Boolean,
  },
  data() {
    return {
      name: {
        isValid: false,
        rules: [
          (v) => !!v || this.$t("common.required"),
          (v) =>
            v
              ? v.length <= this.$rules.MAX_CHARACTERS ||
                this.$t("common.maxCharacters", { amount: this.$rules.MAX_CHARACTERS })
              : true,
        ],
      },
      description: {
        isValid: false,
        rules: [],
      },
      deleteDialog: {
        title: "",
        active: false,
        item: null,
        event: null,
      },
      newTaskDialog: {
        active: false,
        item: null,
      },
      newFormItemDialog: {
        active: false,
        item: null,
      },
      filePickerDialog: {
        active: false,
        item: null,
      },
      chosenPreviewItemId: null,
      fileIds: null,
      selectedFormItemId: null,
    };
  },
  computed: {
    templateCategoryName() {
      return this.$te(`formManagement.category.${this.form?.category?.name}`)
        ? this.$t(`formManagement.category.${this.form?.category?.name}`)
        : this.form?.category?.name;
    },
    showNote() {
      return this.selectedFormItem?.showNote || !!this.selectedFormItem?.note;
    },
    canClearAnswer() {
      const value = this.selectedFormItem?.value;
      const valueIsArray = Array.isArray(value);
      const hasValue = valueIsArray ? value.length > 0 : !!value;
      const canClear = hasValue || this.selectedFormItem?.defaultValueTemplateId;

      return canClear;
    },
    selectedFormItem() {
      return this.formItems.find((item) => item.id === this.selectedFormItemId);
    },
    isSentToSign() {
      return !_.isEmpty(this.form?.signatureSession);
    },
    formItemId() {
      return this.$route.query.formItemId;
    },
    canCreateTask() {
      return this.permissionMixins_task.create.can;
    },
    canEditForm() {
      return this.form?.permissions?.edit.can;
    },
  },
  methods: {
    genHeader() {
      return this.$createElement(
        VCard,
        {
          class: "border-a pa-6 mb-10",
          props: {
            flat: true,
            loading: this.isLoading,
            disabled: this.isLoading || this.isSentToSign,
          },
        },
        [
          this.$createElement("div", { class: "text-h4 pb-3" }, this.form?.name),
          this.$createElement("div", { class: "text-pre-wrap" }, this.form?.description),
        ],
      );
    },
    genSignatures() {
      if (!this.isSignable) return;
      return this.$createElement(Signatures, {
        props: {
          signatures: this.form.signatures || [],
          isCreatingSignature: this.isCreatingSignature,
        },
        on: {
          "signature:create": (e) => this.$emit("signature:create", e),
          "signature:update": (e) => this.$emit("signature:update", e),
          "signature:input": (e) => this.$emit("signature:input", e),
          "signature:delete": (e) => this.onSignatureDelete(e),
        },
      });
    },
    genSection(item) {
      return this.$createElement(
        VCard,
        {
          class: "border-a pa-6 mb-10",
          props: {
            flat: true,
            loading: this.isLoading,
            disabled: this.isLoading || this.isSentToSign || !this.canEditForm,
          },
        },
        [
          this.$createElement("div", { class: "d-flex flex-column" }, [
            this.$createElement(SectionHeader, {
              props: { item },
              on: { "item:itemAdd": (e) => this.openNewFormItemDialog(e) },
            }),
            this.$createElement(ItemHierarchy, {
              props: {
                items: this.formItems,
                item,
                assignableUsers: this.assignableUsers,
              },
              on: {
                "item:update": (e) => this.$emit("item:update", e),
                "item:commitUpdate": (e) => this.commitUpdate(e),
                "item:delete": (e) => this.onItemDelete(e),
                "item:imageDelete": (e) => this.onImageDelete(e),
                "item:triggerFilePicker": ({ item }) => {
                  this.filePickerDialog.active = true;
                  this.filePickerDialog.item = item;
                },
                "item:imageUpload": (e) => this.onImagesUpload(e),
                "item:imagePreview": (e) => this.onPreviewDialogOpen(e),
                "item:itemAdd": (e) => this.openNewFormItemDialog(e),
                "item:taskUpdate": (e) => this.$emit("item:taskUpdate", e),
                "item:taskDelete": (e) => this.onTaskDelete(e),
                "optionsMenu:open": this.onOptionsMenuOpen,
              },
            }),
          ]),
        ],
      );
    },
    genItemBuilder(items) {
      return items.map((item, index) => {
        return this.genSection(item);
      });
    },
    onItemDelete({ item }) {
      this.deleteDialog.title = this.$t(
        "formManagement.completion.deleteConfirmation.itemTitle",
      );
      this.deleteDialog.event = "item:delete";
      this.deleteDialog.item = item;
      this.deleteDialog.active = true;
    },
    onImageDelete(e) {
      this.deleteDialog.title = this.$t(
        "formManagement.completion.deleteConfirmation.imageTitle",
      );
      this.deleteDialog.event = "item:imageDelete";
      this.deleteDialog.item = e;
      this.deleteDialog.active = true;
    },
    onTaskDelete(e) {
      this.deleteDialog.title = this.$tc("taskManagement.deleteConfirmation.title", 1);
      this.deleteDialog.event = "item:taskDelete";
      this.deleteDialog.item = e;
      this.deleteDialog.active = true;
    },
    onSignatureDelete(e) {
      this.deleteDialog.title = this.$t(
        "formManagement.signature.deleteConfirmation.title",
      );
      this.deleteDialog.event = "signature:delete";
      this.deleteDialog.item = { id: e };
      this.deleteDialog.active = true;
    },
    formatFiles(files) {
      const formData = new FormData();
      for (let file of files) {
        formData.append("file", file);
      }
      return formData;
    },
    onFilePickerSubmit(items) {
      const formData = this.formatFiles(items);
      this.$emit("item:imageUpload", { item: this.filePickerDialog.item, formData });

      //TODO: Implement when we implement the other function in formItems store, we dont need the code above
      // this.$emit("item:imageUpload", { item: this.filePickerDialog.item, items });
    },
    onImagesUpload(e) {
      const { files, item } = e;
      const formData = this.formatFiles(files);
      this.$emit("item:imageUpload", { item, formData });
    },
    genFilePickerDialog() {
      return this.$createElement("AppFilePickerDialog", {
        props: {
          dialog: this.filePickerDialog.active,
          mimeTypes: ["image/*"],
          // TODO: Add upload from STORAGE_MANAGER
          modes: ["FILE_EXPLORER"],
          disableNameEdit: true,
        },
        on: {
          change: (e) => (this.filePickerDialog.active = e),
          submit: (e) => this.onFilePickerSubmit(e),
        },
      });
    },
    genDeleteDialog() {
      return this.$createElement(AppDeleteConfirmationDialog, {
        props: {
          dialog: this.deleteDialog.active,
          item: this.deleteDialog.item,
          event: this.deleteDialog.event,
          title: this.deleteDialog.title,
          validator: this.$t("common.delete").toLowerCase(),
          validatorText: this.$t(
            "fileManagement.fileExplorer.deleteConfirmation.validatorText",
            {
              delete: this.$t("common.delete").toLowerCase(),
            },
          ),
        },
        on: {
          "dialog:change": (e) => (this.deleteDialog.active = false),
          delete: ({ item, event }) => {
            this.$emit(event, item);
          },
        },
      });
    },
    genPreviewDialog() {
      return this.$createElement("AppFilePreviewDialog", {
        props: { initialFileId: this.chosenPreviewItemId, fileIds: this.fileIds },
        on: { "dialog:close": this.onPreviewDialogClose },
      });
    },
    genNewTaskDialog() {
      const projectId = this.form?.projectId;
      return this.$createElement("AppTaskDialog", {
        props: {
          notRepeatable: true,
          dialog: this.newTaskDialog.active,
          data: { projectId, ...this.newTaskDialog?.data },
        },
        on: {
          "dialog:change": (e) => (this.newTaskDialog.active = false),
          created: ({ task }) =>
            this.$emit("item:taskAdd", { task, item: this.newTaskDialog.item }),
        },
      });
    },
    genNewFormItemDialog() {
      const parentId = this.newFormItemDialog.item?.id;
      const formId = this.newFormItemDialog.item?.formId;

      return this.$createElement("AppNewFormItemDialog", {
        props: {
          dialog: this.newFormItemDialog.active,
          parentId,
          formId,
        },
        on: {
          "dialog:change": (e) => (this.newFormItemDialog.active = false),
          submit: (e) => this.$emit("item:itemAdd", e),
        },
      });
    },
    onOptionsMenuOpen(e) {
      const { items } = e;
      this.selectedFormItemId = items[0].id;
      this.$refs.contextMenu?.open(e);
    },
    onToggleShowDefaultAnswerBadge({ item, hideDefaultValueBadge }) {
      const params = {
        item,
        body: {
          hideDefaultValueBadge,
        },
      };
      this.$emit("item:update", params);
    },
    onToggleFormItemNotRelevant(e) {
      const params = {
        item: this.selectedFormItem,
        body: { isNotRelevant: e },
      };
      this.$emit("item:update", params);
    },
    onDefaultAnswerSelect({ item, defaultValueTemplate }) {
      const params = {
        item: item,
        body: { defaultValueTemplateId: defaultValueTemplate?.id },
        extra: { defaultValueTemplate },
      };

      this.$emit("item:update", params);
    },
    onAddNote() {
      const newItem = { ...this.selectedFormItem, showNote: true };
      this.commitUpdate(newItem);
      this.$nextTick(() => {
        document.getElementById(`note_${this.selectedFormItem.id}`)?.focus();
      });
    },
    commitUpdate(item) {
      this.$emit("item:commitUpdate", item);
    },
    onClearAnswer() {
      const value = Array.isArray(this.selectedFormItem.value) ? [] : null;
      const body = {
        value: Array.isArray(this.selectedFormItem.value) ? [] : null,
        defaultValueTemplateId: null,
      };

      this.$emit("item:update", {
        item: this.selectedFormItem,
        body,
      });
      this.selectedItem = { ...this.item, ...body };
    },
    afterDefaultAnswerTemplateMenuOpen(e) {
      if (e) {
        const data = {
          model: "form",
          categoryId: this.form.categoryId,
          currentDefaultValueTemplate: this.selectedFormItem?.defaultValueTemplate,
          selectedItem: this.selectedFormItem,
        };

        this.$refs.defaultAnswerTemplateMenu.onAfterOpen(data);
      }
    },
    onPreviewDialogClose() {
      this.chosenPreviewItemId = null;
    },
    onPreviewDialogOpen({ imageId, imageIds }) {
      this.fileIds = imageIds;
      this.chosenPreviewItemId = imageId;
    },
    openNewTaskDialog({ item }) {
      this.newTaskDialog.active = true;
      this.newTaskDialog.item = item;
      this.newTaskDialog.data = { formItemId: item.id };
    },
    openNewFormItemDialog({ item }) {
      this.newFormItemDialog.active = true;
      this.newFormItemDialog.item = item;
    },
    genOptionsContextMenu() {
      const showOptions = this.selectedFormItem?.hasOptions;
      const isNotRelevant = this.selectedFormItem?.isNotRelevant;

      return this.$createElement(
        "AppContextMenu",
        {
          ref: "contextMenu",
          on: {
            input: (value) => (this.isContextMenuOpen = value),
          },
        },
        [
          showOptions
            ? this.$createElement("AppMenuItem", {
                props: {
                  icon: this.$icons.REGULAR.ROUTE.TASK,
                  text: this.$t("formManagement.completion.addTask"),
                  disabled: isNotRelevant,
                },
                on: {
                  click: () => this.openNewTaskDialog({ item: this.selectedFormItem }),
                },
              })
            : null,
          showOptions
            ? this.$createElement("AppMenuItem", {
                props: {
                  icon: this.$icons.REGULAR.COMMON.NOTE_STICKY,
                  text: this.$t("formManagement.completion.addNote"),
                  disabled: this.showNote || isNotRelevant,
                },
                on: {
                  click: () => this.onAddNote({ item: this.selectedFormItem }),
                },
              })
            : null,
          this.genDefaultAnswerTemplateMenu(),
          this.$createElement("AppMenuItem", {
            props: {
              icon: this.$icons.REGULAR.ACTION.CLEAR,
              text: this.$t("formManagement.completion.clearAnswer"),
              disabled: !this.canClearAnswer || isNotRelevant,
            },
            on: {
              click: () => this.onClearAnswer(),
            },
          }),
          this.$createElement(VDivider),
          this.$createElement(
            VListItem,
            {
              on: {
                click: (e) => {
                  this.$refs.notRelevantSwitch.onChange();
                  e.stopPropagation();
                },
              },
            },
            [
              this.$createElement("AppDefaultSwitch", {
                class: "pa-0 ma-0",
                ref: "notRelevantSwitch",
                props: {
                  label: this.$t("formManagement.completion.setNotRelevant"),
                  inputValue: this.selectedFormItem?.isNotRelevant,
                },
                on: {
                  change: (e) => this.onToggleFormItemNotRelevant(e),
                  click: (e) => e.stopPropagation(),
                },
              }),
            ],
          ),
        ],
      );
    },
    genDefaultAnswerTemplateMenu() {
      const isNotRelevant = this.selectedFormItem?.isNotRelevant;

      if (this.selectedFormItem?.type !== this.$constant.TEXTAREA) return;
      return this.$createElement("AppDefaultValueTemplateMenu", {
        ref: "defaultAnswerTemplateMenu",
        on: {
          "item:select": (e) => this.onDefaultAnswerSelect(e),
          "badge:toggle": (e) => this.onToggleShowDefaultAnswerBadge(e),
          input: (e) => this.afterDefaultAnswerTemplateMenuOpen(e),
        },
        props: {
          editDialogTitle: this.$t("answerTemplates.edit", {
            category: this.templateCategoryName,
          }),
          useActivator: true,
          disabled: isNotRelevant,
        },
        scopedSlots: {
          activator: ({ on }) => {
            return this.$createElement("AppMenuItem", {
              props: {
                text: this.$t("answerTemplates.addAnswerTemplate"),
                icon: this.$icons.REGULAR.COMMON.BOLT,
                disabled: isNotRelevant,
                angleRight: true,
              },
              style: "min-width: 210px",
              on,
            });
          },
        },
      });
    },
    genCard() {
      const rootItems = this.formTemplateBuilderMixins_getRootItems(this.formItems || []);

      return this.$createElement("div", [
        this.$createElement(CantEditInfoAlert, {
          props: { showAlert: this.isSentToSign },
        }),
        this.$createElement("div", [
          ...[
            this.isLoading || !this.form
              ? []
              : [
                  this.genHeader(),
                  this.genItemBuilder(rootItems),
                  this.genDeleteDialog(),
                  this.genFilePickerDialog(),
                  this.genPreviewDialog(),
                  this.genNewTaskDialog(),
                  this.genNewFormItemDialog(),
                  this.genOptionsContextMenu(),
                ],
          ],
        ]),

        this.isLoading || !this.form || !this.isSignable
          ? null
          : this.$createElement(
              VCard,
              {
                class: "border-a mt-2",
                props: {
                  flat: true,
                  loading: this.isLoading,
                  disabled: this.isLoading,
                },
              },
              [
                this.$createElement("div", { class: "pa-6" }, [
                  ...[this.genSignatures()],
                ]),
              ],
            ),
      ]);
    },
    scrollToFormItem(itemId) {
      if (itemId) {
        this.$nextTick(() => {
          const element = document.getElementById(`form-item-${itemId}`);
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        });
      }
    },
  },
  watch: {
    formItems: {
      handler() {
        if (this.formItemId) {
          this.scrollToFormItem(this.formItemId);
          // Remove the query parameter after scrolling
          this.$router.replace({
            query: { ...this.$route.query, formItemId: undefined },
          });
        }
      },
      deep: true,
    },
  },
  render(h) {
    return this.genCard();
  },
};
</script>
