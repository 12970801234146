<template>
  <AppDefaultTooltip
    :top="position === 'top'"
    :bottom="position === 'bottom'"
    :left="position === 'left'"
    :right="position === 'right'"
    :disabled="!isTruncated || disabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <span
        ref="textElement"
        :class="[
          textClass,
          { 'text-truncate': truncateStyle, 'text-no-wrap': !breakWord },
        ]"
        :style="customStyle"
        v-bind="attrs"
        v-on="on"
        @mouseenter="checkTruncation"
      >
        {{ text }}
      </span>
    </template>
    <span :class="{ 'break-word': breakWord }">{{ text }}</span>
  </AppDefaultTooltip>
</template>

<script>
export default {
  name: "AppTextTruncate",
  props: {
    text: {
      type: String,
      required: true,
    },
    maxWidth: {
      type: [String, Number],
      default: null,
    },
    truncateStyle: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    breakWord: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: "top",
      validator: (value) => ["top", "bottom", "left", "right"].includes(value),
    },
    textClass: {
      type: String,
      default: "",
    },
    pixelThreshold: {
      type: Number,
      default: 10, // 10px difference threshold
    },
  },
  data() {
    return {
      isTruncated: false,
    };
  },
  computed: {
    customStyle() {
      const style = {};
      if (this.maxWidth) {
        style.maxWidth = isNaN(this.maxWidth) ? this.maxWidth : `${this.maxWidth}px`;
      }
      return style;
    },
  },
  mounted() {
    // Run multiple checks to ensure we catch the truncation
    this.$nextTick(() => {
      this.checkTruncation();

      // Additional check after a delay
      setTimeout(() => {
        this.checkTruncation();
      }, 100);

      // One more check after all images and resources might have loaded
      setTimeout(() => {
        this.checkTruncation();
      }, 500);
    });

    window.addEventListener("resize", this.checkTruncation);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkTruncation);
  },
  watch: {
    text() {
      this.$nextTick(this.checkTruncation);
    },
  },
  methods: {
    checkTruncation() {
      const el = this.$refs.textElement;
      if (!el) return;

      // Get the measurements
      const scrollWidth = el.scrollWidth;
      const clientWidth = el.clientWidth;

      // Calculate the difference in pixels
      const difference = scrollWidth - clientWidth;

      // Only consider it truncated if the difference exceeds the threshold
      this.isTruncated = difference > this.pixelThreshold;
    },
  },
};
</script>

<style scoped>
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-no-wrap {
  white-space: nowrap;
}
.break-word {
  word-break: break-word;
}
</style>
