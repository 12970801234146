<template>
  <v-snackbar
    :value="showSnackbar"
    :timeout="-1"
    color="primary lighten-1"
    bottom
    app
    left
    :nudge-right="240"
    multi-line
  >
    <div class="d-flex align-center">
      <div class="mr-2 d-flex flex-column">
        <span class="font-weight-bold mb-1">{{ $t("views.changes.unsaved") }}</span>
        <span class="mr-2">{{ $t("views.changes.unsavedPrompt") }}</span>
      </div>
      <v-btn
        small
        outlined
        class="ml-2"
        @click="discardChanges"
      >
        {{ $t("views.changes.discard") }}
      </v-btn>
      <v-btn
        small
        outlined
        color="white"
        class="ml-2"
        :loading="isUpdating"
        @click="saveChanges"
      >
        <v-icon
          small
          left
        >
          {{ $icons.SOLID.ACTION.SAVE }}
        </v-icon>
        {{ $t("views.changes.save") }}
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "AppResourceViewUnsavedChangesSnackbar",

  computed: {
    ...mapGetters("resourceViews", {
      hasUnsavedChanges: "hasUnsavedChanges",
      getCurrentResourceView: "getCurrentResourceView",
    }),

    // Get the active resource view ID directly from the route
    activeResourceViewId() {
      return this.$route.query.resourceViewId;
    },
    ...mapState("resourceViews", {
      isUpdating: (state) => state.isUpdating,
    }),
    canEdit() {
      return this.getCurrentResourceView.permissions.edit.can;
    },
    showSnackbar() {
      return this.hasUnsavedChanges && this.canEdit;
    },
  },

  methods: {
    async saveChanges() {
      try {
        await this.$store.dispatch("resourceViews/saveResourceViewConfig", {
          resourceViewId: this.activeResourceViewId,
        });
      } catch (error) {
        console.error("Error saving changes:", error);
        this.$store.dispatch("snackbar/showSnackbar", {
          text: this.$t("views.changes.saveError"),
          color: "error",
        });
      }
    },

    discardChanges() {
      this.$store.dispatch("resourceViews/discardUnsavedChanges");
      this.$root.$emit("refresh-view-data");
    },
  },
};
</script>
