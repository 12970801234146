<template>
  <AppFiltersDrawer2
    v-if="localShowDrawer"
    @close="closeFilters"
    :tableModel="tableModel"
    :children="children"
  />
</template>

<script>
import { mapState } from "vuex";
import { getSearchUsers } from "@/services/search/users";
import { getTeams } from "@/services/team/assignableTeams";
import { filtersItemsMixins } from "@/helpers/mixins";
import { formatViewQueryParams } from "@/helpers/util";
import { filtersWatchMixins2 } from "@/helpers/mixins";

export default {
  mixins: [filtersItemsMixins, filtersWatchMixins2],
  props: {
    showDrawer: Boolean,
    tableModel: String,
  },
  model: {
    prop: "showDrawer",
    event: "change",
  },
  data() {
    return {
      users: [],
      isUsersLoading: false,
      teams: [],
      isTeamsLoading: false,
    };
  },
  methods: {
    getData() {
      this.getUsers();
      this.getFormCategories();
      this.getTeams();
    },
    closeFilters() {
      this.localShowDrawer = false;
    },
    async getUsers() {
      this.isUsersLoading = true;
      const params = {
        alwaysIncludeIds: [...this.ownerIds, ...this.creatorIds],
      };
      getSearchUsers({ params })
        .then((users) => {
          this.users = users;
        })
        .finally(() => {
          this.isUsersLoading = false;
        });
    },
    async getFormCategories() {
      this.$store.dispatch("formCategories/getCategories");
    },
    async getTeams() {
      this.isTeamsLoading = true;
      getTeams()
        .then((teams) => {
          this.teams = teams;
        })
        .finally(() => {
          this.isTeamsLoading = false;
        });
    },
  },
  computed: {
    localShowDrawer: {
      get() {
        return this.showDrawer;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    ...mapState("formCategories", {
      categories: (state) => state.categories,
      isCategoriesLoading: (state) => state.isLoading,
    }),
    projectId() {
      return this.$route.params.projectId;
    },
    projectIds() {
      return formatViewQueryParams()?.projectIds ?? [];
    },
    taskIds() {
      return formatViewQueryParams()?.taskIds ?? [];
    },
    ownerIds() {
      return formatViewQueryParams()?.ownerIds ?? [];
    },
    creatorIds() {
      return formatViewQueryParams()?.creatorIds ?? [];
    },
    children() {
      const children = [
        {
          is: "AppCustomStatusFilter",
          labelKey: "filters.formFilters.status",
          filterValueKey: "statusIds",
          itemValue: "value",
          itemText: "text",
          clearable: true,
          model: "form",
        },
        {
          is: "AppFiltersMultiAutocomplete",
          labelKey: "filters.formFilters.category",
          itemValue: "id",
          itemText: "text",
          filterValueKey: "categoryIds",
          items: this.categories,
          loading: this.isCategoriesLoading,
        },
        {
          is: "AppFiltersClientGroupedUserAutocomplete",
          labelKey: "filters.formFilters.responsible",
          filterValueKey: "ownerIds",
          itemValue: "id",
          itemText: "name",
          items: this.users ?? null,
          loading: this.isUsersLoading,
          userAvatar: true,
        },
        {
          is: "AppFiltersMultiAutocomplete",
          labelKey: "filters.formFilters.teams",
          filterValueKey: "teamIds",
          itemValue: "id",
          itemText: "name",
          items: this.teams ?? null,
          loading: this.isTeamsLoading,
        },
        {
          is: "AppFiltersClientGroupedUserAutocomplete",
          labelKey: "filters.formFilters.createdBy",
          filterValueKey: "creatorIds",
          itemValue: "id",
          itemText: "name",
          items: this.users ?? null,
          loading: this.isUsersLoading,
          userAvatar: true,
        },
        ...(!this.projectId
          ? [
              {
                is: "AppFiltersProjectMultiAutocomplete",
                labelKey: "filters.formFilters.project",
                filterValueKey: "projectIds",
                isReadyToInitialFetch: this.isReadyToInitialFetch,
                queryParams: {
                  alwaysIncludeIds: this.projectIds,
                },
              },
            ]
          : []),
        {
          is: "AppFiltersTaskMultiAutocomplete",
          labelKey: "filters.formFilters.task",
          filterValueKey: "taskIds",
          itemValue: "id",
          itemText: "name",
          showHelper: this.projectIds?.length > 0,
          isReadyToInitialFetch: this.isReadyToInitialFetch,
          refetchOnQueryParamsChange: true,
          dialogData: { projectId: this.projectId },
          queryParams: {
            projectId: this.projectId,
            alwaysIncludeIds: this.taskIds,
            projectIds: this.projectIds,
          },
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.formFilters.dueDate",
          filterValueKey: "dueDate",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_dateItemsWithFutureDates,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.formFilters.createdDate",
          filterValueKey: "createdDate",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_dateItems,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.formFilters.formsYouCanSign",
          filterValueKey: "hasUserBeenInvitedToSign",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_yesOrNoItems,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.formFilters.signatureStatus",
          filterValueKey: "signatureSessionStatus",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_signatureSessionStatus,
        },
        ...(!this.projectId
          ? [
              {
                is: "AppFiltersSelect",
                labelKey: "filters.formFilters.internal",
                filterValueKey: "isInternal",
                itemValue: "value",
                itemText: "text",
                items: this.filtersItemsMixins_yesOrNoItems,
              },
            ]
          : []),
        {
          is: "AppFiltersSelect",
          labelKey: "filters.formFilters.hasAttachment",
          itemValue: "value",
          itemText: "text",
          filterValueKey: "hasAttachment",
          items: this.filtersItemsMixins_yesOrNoItems,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.formFilters.hasComment",
          itemValue: "value",
          itemText: "text",
          filterValueKey: "hasComments",
          items: this.filtersItemsMixins_yesOrNoItems,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.formFilters.isPrivate",
          itemValue: "value",
          itemText: "text",
          filterValueKey: "isPrivate",
          items: this.filtersItemsMixins_yesOrNoItems,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.formFilters.isSigned",
          itemValue: "value",
          itemText: "text",
          filterValueKey: "isSigned",
          items: this.filtersItemsMixins_yesOrNoItems,
        },
      ];

      return children;
    },
  },
};
</script>
