import {
  RESOURCE_ROUTE_PERMISSIONS,
  ROUTE_NAMES,
  ROUTE_PERMISSIONS,
} from "@/helpers/routeNames";
import multiguard from "../multiguard";
import { makeBeforeEnter, redirectFromLocalStorage } from "../redirect";
import { authGuard, userGuard } from "@/auth";
import { HELP_CATEGORIES } from "@/helpers/constants/help";

const { ROOT, LIST, CALENDAR } = ROUTE_NAMES.TASK;

const taskLocalStorageKey = "REDIRECT_ROUTE:task";
const beforeEnterTask = makeBeforeEnter({ localStorageKey: taskLocalStorageKey });

export const taskRoutes = [
  {
    path: "/task",
    name: ROOT,
    meta: {
      permission: ROUTE_PERMISSIONS.TASK,
      helpCategory: HELP_CATEGORIES.TASK,
    },
    beforeEnter: multiguard([authGuard, userGuard]),
    component: () =>
      import(/* webpackChunkName: "task" */ "../../pages/TaskManagement/Tasks"),
    redirect: () =>
      redirectFromLocalStorage({
        localStorageKey: taskLocalStorageKey,
        validRoutes: [LIST.ROOT, CALENDAR.ROOT],
        defaultRoute: LIST.ROOT,
      }),
    children: [
      {
        path: "list",
        name: LIST.ROOT,
        meta: {
          permission: ROUTE_PERMISSIONS.TASK,
        },
        beforeEnter: beforeEnterTask,
        component: () =>
          import(
            /* webpackChunkName: "task" */ "../../pages/TaskManagement/Tasks/TaskList"
          ),
        children: [
          {
            path: "menu/:taskId",
            name: LIST.MENU.ROOT,
            meta: {
              permission: RESOURCE_ROUTE_PERMISSIONS.TASK,
            },
            component: () =>
              import(
                /* webpackChunkName: "task" */ "../../pages/TaskManagement/Tasks/MenuPage"
              ),
            children: [
              {
                path: "details",
                name: LIST.MENU.DETAILS,
                meta: {
                  permission: RESOURCE_ROUTE_PERMISSIONS.TASK,
                },
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/DetailsPage"
                  ),
              },
              {
                path: "history",
                name: LIST.MENU.HISTORY,
                meta: {
                  permission: RESOURCE_ROUTE_PERMISSIONS.TASK,
                },
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/HistoryPage"
                  ),
              },
              {
                path: "to-do-list",
                name: LIST.MENU.TO_DO_LIST,
                meta: {
                  permission: RESOURCE_ROUTE_PERMISSIONS.TASK,
                },
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/ToDoList"
                  ),
              },
              {
                path: "storage/:folderId",
                name: LIST.MENU.STORAGE,
                meta: {
                  permission: RESOURCE_ROUTE_PERMISSIONS.TASK,
                },
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/StoragePage"
                  ),
              },
              {
                path: "forms",
                name: LIST.MENU.FORMS,
                meta: {
                  permission: RESOURCE_ROUTE_PERMISSIONS.TASK.FORM,
                },
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/FormPage"
                  ),
              },
              {
                path: "product-usage",
                name: LIST.MENU.PRODUCT_USAGE,
                meta: {
                  permission: RESOURCE_ROUTE_PERMISSIONS.TASK,
                },
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/ProductUsagePage"
                  ),
              },
              {
                path: "comments",
                name: LIST.MENU.COMMENTS,
                meta: {
                  permission: RESOURCE_ROUTE_PERMISSIONS.TASK.COMMENT,
                },
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/CommentsPage"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "calendar",
        name: CALENDAR.ROOT,
        meta: {
          permission: ROUTE_PERMISSIONS.TASK,
        },
        beforeEnter: beforeEnterTask,
        component: () =>
          import(
            /* webpackChunkName: "task" */ "../../pages/TaskManagement/Tasks/TaskCalendar"
          ),
        children: [
          {
            path: "menu/:taskId",
            name: CALENDAR.MENU.ROOT,
            meta: {
              permission: RESOURCE_ROUTE_PERMISSIONS.TASK,
            },
            component: () =>
              import(
                /* webpackChunkName: "task" */ "../../pages/TaskManagement/Tasks/MenuPage"
              ),
            children: [
              {
                path: "details",
                name: CALENDAR.MENU.DETAILS,
                meta: {
                  permission: RESOURCE_ROUTE_PERMISSIONS.TASK,
                },
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/DetailsPage"
                  ),
              },
              {
                path: "history",
                name: CALENDAR.MENU.HISTORY,
                meta: {
                  permission: RESOURCE_ROUTE_PERMISSIONS.TASK,
                },
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/HistoryPage"
                  ),
              },
              {
                path: "to-do-list",
                name: CALENDAR.MENU.TO_DO_LIST,
                meta: {
                  permission: RESOURCE_ROUTE_PERMISSIONS.TASK,
                },
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/ToDoList"
                  ),
              },
              {
                path: "storage/:folderId",
                name: CALENDAR.MENU.STORAGE,
                meta: {
                  permission: RESOURCE_ROUTE_PERMISSIONS.TASK,
                },
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/StoragePage"
                  ),
              },
              {
                path: "forms",
                name: CALENDAR.MENU.FORMS,
                meta: {
                  permission: RESOURCE_ROUTE_PERMISSIONS.TASK.FORM,
                },
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/FormPage"
                  ),
              },
              {
                path: "product-usage",
                name: CALENDAR.MENU.PRODUCT_USAGE,
                meta: {
                  permission: RESOURCE_ROUTE_PERMISSIONS.TASK,
                },
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/ProductUsagePage"
                  ),
              },
              {
                path: "comments",
                name: CALENDAR.MENU.COMMENTS,
                meta: {
                  permission: RESOURCE_ROUTE_PERMISSIONS.TASK.COMMENT,
                },
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/CommentsPage"
                  ),
              },
            ],
          },
        ],
      },
    ],
  },
];
