<script>
import { VCheckbox } from "vuetify/lib";
import { formTemplateBuilderMixins } from "@/helpers/mixins";
import ResponseItemChoices from "./ResponseItemChoices.vue";
import { VCard } from "vuetify/lib";
import ResponseItemTableHeaders from "./ResponseItemTableHeaders.vue";

export default {
  mixins: [formTemplateBuilderMixins],
  props: {
    item: Object,
    responseItemIndex: Number,
    sectionIndex: Number,
    parentGroupIndex: Number,
  },
  computed: {
    showExplanation() {
      return this.item.hasExplanation;
    },
  },
  methods: {
    genNameInput() {
      return this.$createElement("div", this.item?.name);
    },
    genResponseText() {
      const text = this.$te(
        `templateManagement.form.builder.item.type.${this.item?.type}`,
      )
        ? this.$t(`templateManagement.form.builder.item.type.${this.item?.type}`)
        : this.item?.type ||
          this.$t("templateManagement.form.builder.item.selectResponseType");
      const showValues = this.item.type === this.$constant.RADIO_GROUP;
      return this.$createElement(
        "div",
        { class: "pa-3" },
        `${text} ${showValues ? `(${this.item?.values})` : ""}`,
      );
    },
    genExplanation() {
      return this.$createElement("div", { class: "w-100 pa-2 border-b" }, [
        this.genExplanationInput(),
      ]);
    },
    genExplanationInput() {
      return this.$createElement(
        "div",
        { class: "pa-2 pl-1" },
        `${this.$t("templateManagement.form.builder.item.helpText")}: ${
          this.item?.explanation
        }`,
      );
    },
    genValues() {
      switch (this.item?.type) {
        case this.$constant.MULTI_CHOICE:
          return this.$createElement(ResponseItemChoices, {
            class: "border-b",
            props: {
              values: this.item?.values,
              id: this.item.id,
            },
            on: {
              "values:update": ({ values }) => {
                this.formTemplateBuilderMixins_updateItem({
                  item: this.item,
                  body: { values },
                });
              },
            },
          });
        case this.$constant.TABLE:
          return this.$createElement(ResponseItemTableHeaders, {
            props: {
              values: this.item?.values,
            },
          });
      }
    },
    genOptionsBar() {
      return this.$createElement(
        "div",
        {
          class: "pl-2 pr-1 d-flex align-center",
          style: "padding-top: 6px; padding-bottom: 6px",
        },
        [
          this.$createElement(
            VCheckbox,
            {
              class: "ma-0 pa-0  mr-5 text-caption",
              props: {
                inputValue: this.item.isRequired,
                label: this.$t("common.mandatory"),
                dense: true,
                hideDetails: true,
                disabled: true,
              },
            },
            [
              this.$createElement("template", { slot: "label" }, [
                this.$createElement(
                  "div",
                  {
                    class: `text-body-2 ${this.item.isRequired ? " primary--text" : ""}`,
                  },
                  [this.$t("common.mandatory")],
                ),
              ]),
            ],
          ),
          this.$createElement(
            VCheckbox,
            {
              class: "ma-0 pa-0 mr-5",
              props: {
                inputValue: this.item.hasExplanation,
                label: this.$t("templateManagement.form.builder.item.helpText"),
                dense: true,
                hideDetails: true,
                disabled: true,
              },
            },
            [
              this.$createElement("template", { slot: "label" }, [
                this.$createElement(
                  "div",
                  {
                    class: `text-body-2 ${
                      this.item.hasExplanation ? " primary--text" : ""
                    }`,
                  },
                  [this.$t("templateManagement.form.builder.item.helpText")],
                ),
              ]),
            ],
          ),
          this.$createElement(
            VCheckbox,
            {
              class: "ma-0 pa-0 mr-5",
              props: {
                inputValue: this.item.hasOptions,
                label: this.$t("templateManagement.form.builder.item.options"),
                dense: true,
                hideDetails: true,
                disabled: true,
              },
            },
            [
              this.$createElement("template", { slot: "label" }, [
                this.$createElement(
                  "div",
                  {
                    class: `text-body-2 ${this.item.hasOptions ? " primary--text" : ""}`,
                  },
                  [this.$t("templateManagement.form.builder.item.options")],
                ),
              ]),
            ],
          ),
        ],
      );
    },
  },
  render(h) {
    const indexingText = this.formTemplateBuilderMixins_genIndexingText({
      responseItemIndex: this.responseItemIndex,
      sectionIndex: this.sectionIndex,
      parentGroupIndex: this.parentGroupIndex,
    });

    return h(
      VCard,
      {
        class: `d-flex flex-column border-a rounded`,
        props: { flat: true },
      },
      [
        h(
          "div",
          {
            class:
              "border-b input-background--background pl-2 py-1 text-body-2 font-weight-bold",
          },
          indexingText,
        ),
        h("div", { class: "d-flex border-b" }, [
          h("div", { class: "pa-3 d-flex align-center", style: "width: 70%;" }, [
            this.genNameInput(),
          ]),
          h("div", { class: "border-l d-flex align-center", style: "width: 30%;" }, [
            this.genResponseText(),
          ]),
        ]),
        ...(this.showExplanation ? [this.genExplanation()] : []),
        this.genValues(),
        this.genOptionsBar(),
      ],
    );
  },
};
</script>
