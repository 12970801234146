<template>
  <div>
    <AppClientDialog
      v-model="dialog.updateClient.active"
      :data="dialog.updateClient.item"
      :title="$t('contact.client.updateClient.title')"
      @confirm="afterClientUpdate"
      :confirmText="$t('common.save')"
    />
    <AppDeleteConfirmationDialog
      v-model="dialog.deleteClient.active"
      :item="dialog.deleteClient.item"
      :title="$tc('contact.client.deleteClient.title', dialog.deleteClient.item?.length)"
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('contact.client.deleteClient.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="deleteClient"
    />
    <AppDataTableServerSidePagination
      ref="dataTable"
      :loading="isLoading"
      :defaultHeaders="defaultHeaders"
      :items="clients"
      :serverItemsLength="serverItemsLength"
      :tableModel="tableModel"
      :noDataText="$t('common.noClients')"
      :actionConfigs="actionConfigs"
      removeMutationType="clients/removeClient"
      updateMutationType="clients/updateClient"
      :getAllItems="getAllItems"
    >
      <template v-slot:item="{ item, headers, select, isSelected }">
        <AppClientTableItem
          :client="item"
          :headers="headers"
          :dataFields="dataFields"
          :isContextMenuActiveItem="isContextMenuActiveItem(item)"
          :isSelected="isSelected"
          :select="select"
          @menu:click="openContextMenu"
        />
      </template>
    </AppDataTableServerSidePagination>
    <AppDataTableContextMenu
      ref="contextMenu"
      :actionConfigs="actionConfigs"
      :items="[contextMenuActiveItem]"
      @input="onContextMenuChange"
    />
  </div>
</template>

<script>
import { permissionMixins } from "@/helpers/mixins/permissionMixins";
import { createTableHeaders } from "@/helpers/util";

export default {
  mixins: [permissionMixins],
  props: {
    clients: Array,
    isLoading: Boolean,
    serverItemsLength: Number,
    tableModel: String,
    getAllItems: Function,
  },
  data() {
    return {
      dialog: {
        updateClient: {
          active: false,
          item: null,
        },
        deleteClient: {
          active: false,
          item: null,
        },
      },
      contextMenuActiveItem: null,
    };
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    dataFields() {
      return this.$constant.TABLE_HEADER[this.tableModel];
    },
    actions() {
      const { EDIT, DELETE, EDIT_ACTIVE } = this.$constant.ACTION_KEYS;
      const canNotEdit = ({ items }) => !this.canEditItems({ items });
      const canNotDelete = ({ items }) => !this.canDeleteItems({ items });
      return {
        [EDIT]: {
          disabled: canNotEdit,
          click: this.onClientEdit,
        },
        [DELETE]: {
          disabled: canNotDelete,
          click: this.onClientDelete,
        },
        [EDIT_ACTIVE]: {
          click: ({ items }) => {
            const item = items[0];
            this.updateClient({
              id: item.id,
              body: { isActive: !item.isActive },
            });
          },
          disabled: canNotEdit,
        },
      };
    },
    actionConfigs() {
      const { EDIT, DELETE } = this.$constant.ACTION_KEYS;
      const actions = this.actions;
      const customActions = [
        {
          key: EDIT,
          props: {
            disabled: actions[EDIT].disabled,
          },
          on: {
            click: actions[EDIT].click,
          },
        },
        {
          key: DELETE,
          props: {
            disabled: actions[DELETE].disabled,
          },
          on: {
            click: actions[DELETE].click,
          },
          divider: true,
        },
      ];

      const config = this.$constant.generateActionConfig({
        primaryActions: customActions,
        secondaryActions: this.$constant.CLIENT_ACTIONS,
      });

      return config;
    },
    defaultHeaders() {
      const { EDIT_ACTIVE } = this.$constant.ACTION_KEYS;
      const actions = this.actions;

      return createTableHeaders([
        {
          preset: "SELECT",
          access: !this.projectId,
        },
        {
          preset: "NAME",
          value: this.dataFields.NAME,
          disabled: true,
        },
        {
          text: this.$t("common.orgnr"),
          value: this.dataFields.ORGANIZATION_NUMBER,
          sortable: false,
          disabled: true,
        },
        {
          text: this.$t("contact.client.list.responsible"),
          value: this.dataFields.OWNER,
          disabled: true,
        },
        {
          text: this.$t("common.type"),
          value: this.dataFields.TYPE,
          disabled: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("contact.client.list.phone")),
          value: this.dataFields.PHONE,
          disabled: true,
        },
        {
          text: this.$t("contact.client.list.email"),
          value: this.dataFields.EMAIL,
          disabled: true,
        },
        {
          text: this.$t("common.active"),
          value: this.dataFields.ACTIVE,
          compact: true,
          align: "center",
          ...actions[EDIT_ACTIVE],
        },
        {
          preset: "MENU",
          value: this.dataFields.MENU,
        },
      ]);
    },
  },

  methods: {
    openEditHeadersDialog() {
      this.$refs.dataTable.openEditHeadersDialog();
    },
    canDeleteItems({ items }) {
      if (this.projectId) return false;
      return this.permissionMixins_canDeleteItems({ items });
    },
    canEditItems({ items }) {
      if (this.projectId) return false;
      return this.permissionMixins_canEditItems({ items });
    },
    onContextMenuChange(isOpen) {
      if (!isOpen) {
        this.contextMenuActiveItem = null;
      }
    },
    isContextMenuActiveItem(item) {
      return this.contextMenuActiveItem?.id === item?.id;
    },
    openContextMenu({ from, client, direction }) {
      this.$refs.contextMenu.open({ from, direction });
      this.$nextTick(() => {
        this.contextMenuActiveItem = client;
      });
    },
    onClientDelete({ items }) {
      this.dialog.deleteClient.active = true;
      this.dialog.deleteClient.item = items;
    },
    deleteClient({ item }) {
      this.$emit("client:delete", { items: item });
    },
    onClientEdit({ items }) {
      const item = items[0];
      this.dialog.updateClient.active = true;
      this.dialog.updateClient.item = item;
    },
    updateClient(e) {
      this.$emit("client:update", e);
    },
    afterClientUpdate() {
      this.$emit("client:afterUpdate");
    },
  },
};
</script>
