<template>
  <AppGroupByBtn2
    :tableModel="tableModel"
    :menuItems="menuItems"
    :isNoGroupSelected="!groupByMixins2_groupBy"
  />
</template>

<script>
import { groupByMixins2 } from "@/helpers/mixins/groupByMixins2";
import { mapGetters } from "vuex";

export default {
  mixins: [groupByMixins2],
  props: {
    tableModel: String,
  },
  computed: {
    ...mapGetters("activeIntegrations", {
      hasTimeEntriesIntegrationEnabled: "hasTimeEntriesIntegrationEnabled",
    }),
    projectId() {
      return this.$route.params.projectId;
    },
    menuItems() {
      const menuItems = [
        {
          text: this.$options.filters.capitalize(this.$t("common.noGroupText")),
          icon: this.$icons.REGULAR.ACTION.EMPTY,
          isSelected: !this.groupByMixins2_groupBy,
          click: () => this.groupByMixins2_setGroupBy(undefined),
        },
        {
          text: this.$t("common.date"),
          icon: this.$icons.REGULAR.COMMON.CALENDAR,
          isSelected: this.groupByMixins2_isGroupSelected(this.dataFields.DATE),
          click: () => this.groupByMixins2_setGroupBy(this.dataFields.DATE),
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.status")),
          icon: this.$icons.REGULAR.COMMON.SQUARE,
          isSelected: this.groupByMixins2_isGroupSelected(this.dataFields.STATUS),
          click: () => this.groupByMixins2_setGroupBy(this.dataFields.STATUS),
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.user")),
          icon: this.$icons.REGULAR.ROUTE.USER,
          isSelected: this.groupByMixins2_isGroupSelected(this.dataFields.USER),
          click: () => this.groupByMixins2_setGroupBy(this.dataFields.USER),
        },
        ...(!this.projectId
          ? [
              {
                text: this.$t("common.project"),
                icon: this.$icons.REGULAR.ROUTE.PROJECT,
                isSelected: this.groupByMixins2_isGroupSelected(this.dataFields.PROJECT),
                click: () => this.groupByMixins2_setGroupBy(this.dataFields.PROJECT),
              },
            ]
          : []),
        {
          text: this.$options.filters.capitalize(this.$t("common.task")),
          icon: this.$icons.REGULAR.ROUTE.TASK,
          isSelected: this.groupByMixins2_isGroupSelected(this.dataFields.TASK),
          click: () => this.groupByMixins2_setGroupBy(this.dataFields.TASK),
        },
      ];

      if (this.hasTimeEntriesIntegrationEnabled) {
        menuItems.push({
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.accounting"),
          ),
          icon: this.$icons.REGULAR.FILE.FILE_INVOICE,
          isSelected: this.groupByMixins2_isGroupSelected(this.dataFields.TRANSFERRED),
          click: () => this.groupByMixins2_setGroupBy(this.dataFields.TRANSFERRED),
        });
      }

      return menuItems;
    },
  },
};
</script>
