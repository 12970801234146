import axios from "axios";
import { formatDataTableOptionsToQuery } from "@/helpers/util";
import { shouldUpdateItems } from "@/helpers/util";
const apiUrl = process.env.VUE_APP_API_URL;
const CancelToken = axios.CancelToken;
let getClientsCancel;

const state = () => ({
  clients: [],
  count: null,
  isLoading: false,
});

const getters = {};

const actions = {
  async getClients({ commit }, { filters, dataTableOptions, projectId }) {
    if (getClientsCancel) getClientsCancel();
    commit("loading", true);

    const optionsQuery = formatDataTableOptionsToQuery(dataTableOptions);

    const params = {
      ...filters,
      ...optionsQuery,
      projectId,
    };

    return axios
      .get(`${apiUrl}client`, {
        params,
        cancelToken: new CancelToken(function executor(c) {
          getClientsCancel = c;
        }),
      })
      .then(({ data }) => {
        const { clients, count } = data;

        const shouldUpdate = shouldUpdateItems(
          count,
          dataTableOptions?.page,
          dataTableOptions?.itemsPerPage,
        );

        if (shouldUpdate) {
          commit("setClients", clients);
        }
        commit("setCount", count);
        commit("loading", false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          commit("loading", false);
          throw new Error(error);
        }
      });
  },
  async createClient({ dispatch }, { body, filters, dataTableOptions }) {
    return axios
      .post(`${apiUrl}client`, { ...body })
      .then(({ data }) => {
        const client = data?.client;
        dispatch("getClients", { filters, dataTableOptions });
        return client;
      })
      .catch((error) => {
        const message =
          error?.response?.data.message || error?.message || "Something went wrong";
        throw new Error(message);
      });
  },
  async updateClient({ dispatch, commit }, { id, body, filters }) {
    commit("loading", true);
    return axios
      .put(`${apiUrl}client/${id}`, { ...body })
      .then(({ data }) => {
        let client = data?.client;
        if (client) {
          commit("updateClient", client);
          return client;
        } else {
          dispatch("getClients", { filters });
          return null;
        }
      })
      .catch((error) => {
        const message =
          error?.response?.data.message || error?.message || "Something went wrong";
        throw new Error(message);
      })
      .finally(() => {
        commit("loading", false);
      });
  },
  async deleteClients({ commit, state }, { ids }) {
    commit("loading", true);
    return axios
      .delete(`${apiUrl}client`, { data: { ids } })
      .then(async (res) => {
        const clients = res?.data?.clients;
        for (const client of clients) {
          commit("removeClient", client);
        }
        commit("setCount", state.count - clients.length);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", false);
      });
  },
  async resetState({ commit }) {
    commit("setClients", []);
    commit("setCount", null);
  },
};

const mutations = {
  setClients(state, clients) {
    state.clients = clients;
  },
  setCount(state, count) {
    state.count = count;
  },
  updateClient(state, client) {
    if (!client) throw new Error("No client");
    const index = state.clients.findIndex((x) => x.id === client.id);
    if (index !== -1) {
      state.clients.splice(index, 1, client);
    }
  },
  removeClient(state, client) {
    if (!client) throw new Error("No client");
    const index = state.clients.findIndex((x) => x.id === client.id);
    if (index !== -1) {
      state.clients.splice(index, 1);
    }
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const clients = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
