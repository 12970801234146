<template>
  <tr
    class="list_tr list_tr-height"
    :class="rowStateClasses"
    @contextmenu="dataTableItemMixins_onRightClick"
  >
    <AppDefaultTableCell
      v-for="(header, index) in headers"
      :key="index"
      :header="header"
      :isSelected="isSelected"
      :select="select"
      :index="index"
      :item="template"
    >
      <template v-if="header.value === 'name'">
        <div class="table-cell-content__breakable-long-text">
          {{ template.name }}
        </div>
      </template>

      <template v-if="header.value === 'isPublished'">
        <AppColorChip
          :text="$t(template.isPublished ? 'common.published' : 'common.unpublished')"
          :color="template.isPublished ? 'success--text' : 'error--text'"
        />
      </template>

      <template v-if="header.value === 'origin'">
        <AppItemOriginChip :origin="template?.origin" />
      </template>

      <template v-if="header.value === 'category.name'">
        <AppColorChip
          v-if="template.category"
          :text="categoryName"
          color="info--text"
        />
      </template>

      <template v-if="header.value === 'isShareableWithChildDomains'">
        <AppColorChip
          :text="
            $t(
              template.isShareableWithChildDomains
                ? 'common.shareable'
                : 'common.notShareable',
            )
          "
          :color="template.isShareableWithChildDomains ? 'success--text' : 'error--text'"
        />
      </template>

      <template v-if="header.value === 'updatedAt'">
        <span class="text-no-wrap">
          {{ new Date(template.updatedAt) | dateFormat("DD.MM.YYYY HH:mm") }}
        </span>
      </template>

      <template v-if="header.value === 'menu'">
        <v-btn
          icon
          @click="dataTableItemMixins_onEllipsisClick"
          ref="ellipsis"
        >
          <v-icon>{{ $icons.REGULAR.ACTION.MENU }}</v-icon>
        </v-btn>
      </template>
    </AppDefaultTableCell>
  </tr>
</template>

<script>
import { dataTableItemMixins } from "@/helpers/mixins";

export default {
  mixins: [dataTableItemMixins],
  props: {
    template: Object,
    isHighlighted: Boolean,
    isSelected: Boolean,
    select: Function,
    isContextMenuActiveItem: Boolean,
    headers: Array,
  },
  computed: {
    rowStateClasses() {
      return {
        "list_tr--active": this.isContextMenuActiveItem,
        "list_tr--selected": this.isSelected,
      };
    },
    categoryName() {
      const category = this.template.category;
      return this.$te(`formManagement.category.${category.name}`)
        ? this.$t(`formManagement.category.${category.name}`)
        : category.name;
    },
  },
  methods: {
    openMenu(params) {
      this.$emit("menu:click", { template: this.template, ...params });
    },
  },
};
</script>
