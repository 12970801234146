import { ROUTE_NAMES, ROUTE_PERMISSIONS } from "@/helpers/routeNames";
import { authGuard, userGuard } from "@/auth";
import multiguard from "../multiguard";
import { HELP_CATEGORIES } from "@/helpers/constants/help";

const { ROOT } = ROUTE_NAMES.DASHBOARD;

export const dashboardRoutes = [
  {
    path: "/dashboard",
    name: ROOT,
    beforeEnter: multiguard([authGuard, userGuard]),
    meta: {
      permission: ROUTE_PERMISSIONS.DASHBOARD,
      helpCategory: HELP_CATEGORIES.DASHBOARD,
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../../pages/DashboardPage"),
  },
];
