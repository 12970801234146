<template>
  <AppContextMenu
    ref="menu"
    v-on="$listeners"
    v-bind="$attrs"
    height="220"
    width="300"
    :closeOnContentClick="false"
  >
    <AppRoleAutocomplete
      ref="roleAutocomplete"
      v-model="selectedRole"
      autofocus
      preventFetch
      activateOnFocus
      :queryParams="{ excludeTypes: ['DOMAIN_OWNER'] }"
      @change="onSubmit"
    />
  </AppContextMenu>
</template>

<script>
export default {
  data() {
    return {
      selectedRole: null,
    };
  },
  methods: {
    onSubmit(role) {
      if (!role) return;
      this.$emit("submit", { role });
      this.close();
    },
    close() {
      this.$refs.menu.close();
    },
    open(e) {
      const { items } = e;
      this.selectedRole = items.length === 1 ? items[0].role : null;
      this.$refs.menu.open(e);
      setTimeout(() => {
        this.$refs.roleAutocomplete.getRoles();
      }, 20);
    },
  },
};
</script>
