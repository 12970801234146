import { render, staticRenderFns } from "./AppInformoConnectCaseDialog.vue?vue&type=template&id=af48589c&scoped=true"
import script from "./AppInformoConnectCaseDialog.vue?vue&type=script&lang=js"
export * from "./AppInformoConnectCaseDialog.vue?vue&type=script&lang=js"
import style0 from "./AppInformoConnectCaseDialog.vue?vue&type=style&index=0&id=af48589c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af48589c",
  null
  
)

export default component.exports