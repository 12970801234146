export const timeEntryActionMixins = {
  methods: {
    timeEntryActionMixins_isEditDisabled({ items }) {
      if (this.timeEntryActionMixins_isMultipleSelected({ items })) {
        return true;
      }
      const canEditItems = items.every((te) => te?.permissions?.edit?.can);
      return !canEditItems;
    },
    timeEntryActionMixins_isDeleteDisabled({ items }) {
      const canDeleteItems = items.every((te) => te?.permissions?.delete?.can);
      return !canDeleteItems;
    },
    timeEntryActionMixins_isChangeStatusesDisabled(timeEntries, isMultipleSelected) {
      const status = this.timeEntryActionMixins_isChangeStatusesItemsDisabled(
        timeEntries,
        isMultipleSelected,
      );
      return Object.values(status).every((isDisabled) => isDisabled);
    },
    timeEntryActionMixins_isChangeStatusesItemsDisabled(timeEntries, isMultipleSelected) {
      const isDisabledStatuses = this.statusMixins_timeEntriesStatusItems.reduce(
        (result, action) => {
          result[action.value] = timeEntries.some(
            (timeEntry) =>
              this.timeEntryActionMixins_isChangeStatusItemsDisabled(
                timeEntry,
                isMultipleSelected,
              )[action.value],
          );
          return result;
        },
        {},
      );

      return isDisabledStatuses;
    },
    timeEntryActionMixins_isChangeStatusItemsDisabled(timeEntry, isMultipleSelected) {
      if (!timeEntry) return false;
      const isDisabled = this.statusMixins_timeEntriesStatusItems.reduce(
        (result, statusItem) => {
          result[statusItem.value] = false;
          return result;
        },
        {},
      );

      const setIsDisabled = (value) => {
        for (const statusItem of this.statusMixins_timeEntriesStatusItems) {
          isDisabled[statusItem.value] = value;
        }
      };

      const canEditTimeEntryStatus = timeEntry?.permissions?.status?.edit?.can;

      if (!canEditTimeEntryStatus) {
        setIsDisabled(true);
      }
      // Logic for all users regardless of role
      if (isMultipleSelected) {
        isDisabled.DECLINED = true;
      }
      return isDisabled;
    },
    timeEntryActionMixins_hasMappedHourTypes({ items }) {
      return items.every((te) => !!te.hourType.externalCode);
    },
    timeEntryActionMixins_isAllItemsApproved({ items }) {
      return items.every(
        (te) => te?.status === this.$constant.TIME_ENTRY_STATUS.APPROVED,
      );
    },
    timeEntryActionMixins_isMultipleSelected({ items }) {
      return items.length > 1;
    },
    timeEntryActionMixins_isDeclined({ item }) {
      const isDeclined = item?.status === this.$constant.TIME_ENTRY_STATUS.DECLINED;
      return isDeclined;
    },
  },
};
