import { mapGetters } from "vuex";
export const planRestrictionsMixins = {
  computed: {
    ...mapGetters("auth", {
      subscriptionPlan: "subscriptionPlan",
    }),
    planRestrictionsMixins_canAddDocumentTemplates() {
      return this.subscriptionPlan.canGetLibraryTemplate.document;
    },
    planRestrictionsMixins_canAddFormTemplates() {
      return this.subscriptionPlan.canGetLibraryTemplate.form;
    },
    planRestrictionsMixins_canIntegrateAccounting() {
      return this.subscriptionPlan.canIntegrate.accounting;
    },
    planRestrictionsMixins_canIntegrateBoligmappa() {
      return this.subscriptionPlan.canIntegrate.boligmappa;
    },
    planRestrictionsMixins_canIntegrateEbyggesok() {
      return this.subscriptionPlan.canIntegrate.ebyggesok;
    },
    planRestrictionsMixins_canIntegrateAutogear() {
      return this.subscriptionPlan.canIntegrate.autogear;
    },
    planRestrictionsMixins_canIntegrateIn4mo() {
      return this.subscriptionPlan.canIntegrate.in4mo;
    },
    planRestrictionsMixins_canCreateProject() {
      return this.subscriptionPlan.hasAvailableProject;
    },
    planRestrictionsMixins_canUploadToStorage() {
      return this.subscriptionPlan.hasAvailableStorage;
    },
    planRestrictionsMixins_canUseOfficeOnline() {
      return this.subscriptionPlan.canEditOfficeOnline;
    },
    planRestrictionsMixins_canMergeFiles() {
      return this.subscriptionPlan.canMergeFiles;
    },
    planRestrictionsMixins_canSaveFormDocument() {
      return this.subscriptionPlan.canSaveFormDocument;
    },
    planRestrictionsMixins_canSendFormToSign() {
      return this.subscriptionPlan.canSendFormToSign;
    },
    planRestrictionsMixins_canActivateTimeEntry() {
      return this.subscriptionPlan.canActivateTimeEntry;
    },
    planRestrictionsMixins_licenseCount() {
      return this.subscriptionPlan.licenseCount;
    },
    planRestrictionsMixins_usedLicenseCount() {
      return this.subscriptionPlan.usedLicenseCount;
    },
    planRestrictionsMixins_getUnusedLicenseCount() {
      return (
        this.planRestrictionsMixins_licenseCount -
        this.planRestrictionsMixins_usedLicenseCount
      );
    },
  },
  methods: {
    planRestrictionsMixins_getAdditionalRequiredLicensesForInvite(
      totalDesiredLicensesToUse,
    ) {
      const unusedLicenseCount = this.planRestrictionsMixins_getUnusedLicenseCount;
      const licensesToPurchase = Math.max(
        totalDesiredLicensesToUse - unusedLicenseCount,
        0,
      );
      return licensesToPurchase;
    },
    planRestrictionsMixins_canIntegrateByIntegrationName(name) {
      if (this.$constant.ACCOUNTING_INTEGRATIONS.includes(name)) {
        return this.planRestrictionsMixins_canIntegrateAccounting;
      }
      if (name === this.$constant.BOLIGMAPPA) {
        return this.planRestrictionsMixins_canIntegrateBoligmappa;
      }
      if (name === this.$constant.EBYGGESOK) {
        return this.planRestrictionsMixins_canIntegrateEbyggesok;
      }
      if (name === this.$constant.AUTOGEAR) {
        return this.planRestrictionsMixins_canIntegrateAutogear;
      }
      if (name === this.$constant.IN4MO) {
        return this.planRestrictionsMixins_canIntegrateIn4mo;
      }
      return false;
    },
    planRestrictionsMixins_canAddWidget(name) {
      if (this.$constant.ADD_HSE_TEMPLATES_WIDGET.name === name) {
        return this.planRestrictionsMixins_canAddDocumentTemplates;
      }
      if (this.$constant.ADD_FORM_TEMPLATES_WIDGET.name === name) {
        return this.planRestrictionsMixins_canAddFormTemplates;
      }
      if (this.$constant.AUTOGEAR_VEHICLE_WIDGET.name === name) {
        return this.planRestrictionsMixins_canIntegrateAutogear;
      }
      return true;
    },
    planRestrictionsMixins_planCodeByIntegrationName(name) {
      const isProIntegration = this.$constant.PRO_INTEGRATIONS.includes(name);
      if (isProIntegration) return this.$constant.PLAN_CODE.GRIPR_PRO;
      return this.$constant.PLAN_CODE.GRIPR_FREE;
    },
  },
};
