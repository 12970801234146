//A map to tie route names to /me permissions path

//If a route has "isRedirectRoute: true", it doesnt have its own permissions
//the logic will check if user have access to any of the children instead

/** Base permission paths to access properties on permission objects from backend */
const PATHS = {
  BILLING: "billing",
  CLIENT: "client",
  COMMENT: "comment",
  CONTACT: "contact",
  CORPORATE_MANAGEMENT: "corporateManagement",
  DASHBOARD: "dashboard",
  DOMAIN_DETAILS: "domainDetails",
  DOMAIN_REFERRAL: "domainReferral",
  DOMAIN_SECURITY: "domainSecurity",
  DOMAIN_SHARED_CONTENT: "domainSharedContent",
  DOMAIN_TIME_ENTRY_SETTINGS: "domainTimeEntrySettings",
  EBYGGESOK: "ebyggesok",
  USER: "user",
  FORM: "form",
  FORM_TEMPLATE: "formTemplate",
  INTEGRATION: "integration",
  MODULE_CONTROL: "moduleControl",
  PRODUCT: "product",
  PROJECT: "project",
  PROJECT_TEMPLATE: "projectTemplate",
  STORAGE: "storage",
  TASK: "task",
  TIME_ENTRY: "timeEntry",
  USER: "user",
  //cms and support is added by FE with custom logic in getPermissionObject method
  CMS: "cms",
  SUPPORT: "support",
};

/**
 * Global route permissions
 * Usage: meta: { permission: ROUTE_PERMISSIONS.COMMENT }
 */
export const ROUTE_PERMISSIONS = Object.keys(PATHS).reduce((acc, key) => {
  Object.defineProperty(acc, key, {
    get() {
      return { path: PATHS[key] };
    },
  });
  return acc;
}, {});

/**
 * Resource-specific route permissions
 * Usage: meta: { permission: RESOURCE_ROUTE_PERMISSIONS.TASK.COMMENT }
 * Includes idParam for resource-specific permissions
 */

export const RESOURCE_ROUTE_PERMISSIONS = {
  get TASK() {
    const base = { idParam: "taskId" };
    return {
      ...base,
      FORM: { ...base, path: PATHS.FORM },
      COMMENT: { ...base, path: PATHS.COMMENT },
    };
  },
  get FORM() {
    const base = { idParam: "formId" };
    return {
      ...base,
      COMMENT: { ...base, path: PATHS.COMMENT },
    };
  },
  get PROJECT() {
    const base = { idParam: "projectId" };
    return {
      ...base,
      TASK: { ...base, path: PATHS.TASK },
      FORM: { ...base, path: PATHS.FORM },
      COMMENT: { ...base, path: PATHS.COMMENT },
      EBYGGESOK: { ...base, path: PATHS.EBYGGESOK },
      TIME_ENTRY: { ...base, path: PATHS.TIME_ENTRY },
    };
  },
};
