<template>
  <v-dialog
    v-model="localDialog"
    scrollable
    max-width="600px"
  >
    <v-card>
      <v-card-title class="pb-3">
        {{ $tc("projectView.products.sendToAccountingDialog.title", count) }}
      </v-card-title>
      <v-card-subtitle class="primary-text--text">
        {{ $tc("projectView.products.sendToAccountingDialog.subTitle", count) }}
      </v-card-subtitle>
      <v-card-text>
        <div class="d-flex">
          <AppSelectableCard
            :icon="$icons.LIGHT.COMMON.CIRCLE_UP"
            :title="$t('projectView.products.sendToAccountingDialog.createSingleOrder')"
            :subTitle="
              $t('projectView.products.sendToAccountingDialog.createSingleOrderSubTitle')
            "
            :isSelected="isSelected('ORDER')"
            @click="onSelect('ORDER')"
            :class="showAddToProjectButton ? 'mr-2' : ''"
          />
          <AppSelectableCard
            v-if="showAddToProjectButton"
            class="ml-2"
            :icon="$icons.LIGHT.ROUTE.PROJECT"
            :title="$t('projectView.products.sendToAccountingDialog.addToProject')"
            :subTitle="
              $t('projectView.products.sendToAccountingDialog.addToProjectSubTitle')
            "
            :isSelected="isSelected('PROJECT')"
            @click="onSelect('PROJECT')"
          />
        </div>
        <v-expand-transition>
          <template v-if="showSendWithoutAttachmentButton">
            <div>
              <v-radio-group
                v-model="orderLineAddAttachmentsInInvoice"
                @change="onAttachmentPreferenceChange"
                :label="
                  $t(
                    'projectView.products.sendToAccountingDialog.sendWithoutAttachmentLabel',
                  )
                "
              >
                <v-radio
                  :label="
                    $t('projectView.products.sendToAccountingDialog.withAttachment')
                  "
                  :value="true"
                />
                <v-radio
                  :label="
                    $t('projectView.products.sendToAccountingDialog.withoutAttachment')
                  "
                  :value="false"
                />
              </v-radio-group>
            </div>
          </template>
        </v-expand-transition>
      </v-card-text>
      <v-card-actions>
        <div class="w-100">
          <AppErrorAlert
            class="mb-2"
            v-if="errorMessage"
          >
            {{ errorMessage }}
          </AppErrorAlert>
          <div>
            <AppDialogActionBtnPair
              @confirm="onSubmit"
              @cancel="closeDialog"
              :confirmText="$t('common.sendToAccounting')"
              :disabled="!selected"
              :loading="isSubmitting"
            />
          </div>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    dialog: Boolean,
    productUsages: Array,
    projectId: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      isSubmitting: false,
      selected: null,
      selectableItems: ["ORDER", "PROJECT"],
      orderLineAddAttachmentsInInvoice: this.getStoredAttachmentPreference(),
      errorMessage: null,
    };
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    productIds() {
      return this.productUsages?.map((usage) => usage.id);
    },
    count() {
      return this.productIds?.length;
    },
    showAddToProjectButton() {
      return this.$constant.SEND_PRODUCT_TO_ACCOUNTING_ADD_TO_PROJECT_ALLOWED_INTREGRATIONS.some(
        (name) => this.isIntegrationEnabled(name),
      );
    },
    showSendWithoutAttachmentButton() {
      return (
        this.$constant.SEND_PRODUCT_TO_ACCOUNTING_WITHOUT_ATTACHMENT_INTEGRATION_NAMES.some(
          (name) => this.isIntegrationEnabled(name),
        ) && this.selected === "ORDER"
      );
    },
    ...mapGetters("activeIntegrations", {
      isIntegrationEnabled: "isIntegrationEnabled",
    }),
  },
  methods: {
    isSelected(name) {
      return this.selected === name;
    },
    closeDialog() {
      this.selected = null;
      this.localDialog = false;
    },
    onSubmit() {
      this.isSubmitting = true;
      const body = {
        type: this.selected,
        productUsageIds: this.productIds,
        projectId: this.projectId,
        ...(this.showSendWithoutAttachmentButton
          ? { orderLineAddAttachmentsInInvoice: this.orderLineAddAttachmentsInInvoice }
          : {}),
      };
      this.$store
        .dispatch("productUsage/sendToAccounting", { body })
        .then((res) => {
          this.errorMessage = null;
          this.closeDialog();
        })
        .catch((error) => {
          this.errorMessage = error.message;
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    onSelect(name) {
      this.errorMessage = null;
      if (this.selected === name) {
        this.selected = null;
      } else {
        this.selected = name;
      }
    },
    getStoredAttachmentPreference() {
      const storedPreference = localStorage.getItem("orderLineAddAttachmentsInInvoice");
      return storedPreference === null ? true : JSON.parse(storedPreference);
    },
    updateStoredAttachmentPreference(value) {
      localStorage.setItem("orderLineAddAttachmentsInInvoice", JSON.stringify(value));
    },
    onAttachmentPreferenceChange(value) {
      this.orderLineAddAttachmentsInInvoice = value;
      this.updateStoredAttachmentPreference(value);
    },
  },
};
</script>
