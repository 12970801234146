<template>
  <div>
    <AppContextMenu
      ref="contextMenu"
      width="200"
      @input="onContextMenuInput"
    >
      <AppMenuEditBtn
        :disabled="!canEditResourceView"
        :text="$t('common.rename')"
        @click="renameResourceView"
      />

      <AppMenuItem
        :disabled="!canDeleteResourceView"
        :text="
          selectedResourceView?.isPrivate
            ? $t('views.actions.makePublic')
            : $t('views.actions.makePrivate')
        "
        :icon="
          selectedResourceView?.isPrivate
            ? $icons.REGULAR.COMMON.LOCK_OPEN
            : $icons.REGULAR.COMMON.LOCK
        "
        @click="togglePrivate"
      />
      <v-divider class="my-2" />
      <AppMenuDeleteBtn
        @click="openDeleteDialog"
        :disabled="!canDeleteResourceView"
      />
    </AppContextMenu>

    <AppDeleteConfirmationDialog
      v-model="deleteDialog.show"
      :title="$t('views.delete.title')"
      :subtitle="
        $t('views.delete.confirmMessage', { name: deleteDialog.resourceViewName })
      "
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('views.delete.validatorText', {
          validator: $t('common.delete').toLowerCase(),
        })
      "
      :item="{ resourceViewId: deleteDialog.resourceViewId }"
      @delete="onDeleteConfirmed"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AppResourceViewContextMenu",
  props: {
    selectedResourceView: {
      type: Object,
      default: null,
    },
    model: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      deleteDialog: {
        show: false,
        resourceViewId: null,
        resourceViewName: "",
      },
    };
  },
  computed: {
    resourceViewPermissions() {
      return this.selectedResourceView?.permissions;
    },
    ...mapState("resourceViews", {
      isLoadingResourceViews: "isLoading",
    }),
    canDeleteResourceView() {
      return this.resourceViewPermissions?.delete.can && !this.isLoadingResourceViews;
    },
    canEditResourceView() {
      return this.resourceViewPermissions?.edit.can && !this.isLoadingResourceViews;
    },
  },
  methods: {
    open(element) {
      this.$refs.contextMenu.open({
        from: element,
        direction: "bottom",
        nudge: 2,
      });
    },

    onContextMenuInput(value) {
      if (!value) {
        setTimeout(() => {
          if (!this.deleteDialog.show) {
            this.$emit("context-menu-closed");
          }
        }, 300);
      }
    },

    renameResourceView() {
      if (!this.selectedResourceView) return;

      this.$emit("open-rename-dialog", this.selectedResourceView);

      // this.$parent.dialogData = {
      //   show: true,
      //   name: this.selectedResourceView.name,
      //   editId: this.selectedResourceView.id,
      // };
    },

    async togglePrivate() {
      if (!this.selectedResourceView) return;

      try {
        await this.$store.dispatch("resourceViews/updateResourceView", {
          resourceViewId: this.selectedResourceView.id,
          updates: { isPrivate: !this.selectedResourceView.isPrivate },
        });
      } catch (error) {
        console.error("Error toggling privacy:", error);
      }
    },

    openDeleteDialog() {
      if (!this.selectedResourceView || this.selectedResourceView.isDefault) return;

      this.deleteDialog = {
        show: true,
        resourceViewId: this.selectedResourceView.id,
        resourceViewName: this.selectedResourceView.name,
      };
    },

    onDeleteConfirmed({ item }) {
      this.$emit("delete-confirmed", item.resourceViewId);
    },
  },
};
</script>
