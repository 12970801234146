import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import helpers from "./plugins/helpers";
import i18n from "./plugins/i18n";
import packageJson from "../package.json";
import { Auth0Plugin } from "./auth";
import {
  axiosAuthSetup,
  axiosErrorHandlingSetup,
  axiosBoligmappaResponseSetup,
} from "./helpers/interceptors";
import VueFilterDateFormat from "@vuejs-community/vue-filter-date-format";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import VueApexCharts from "vue-apexcharts";
import Rollbar from "rollbar";

import "./styles/main.scss";
import "./helpers/registerAppComponents";
import "./helpers/animation/customAnimation";
import "./registerServiceWorker";

require("./plugins/googleMaps");

const isNavigationRedirectError = (errorMsg) => {
  return (
    errorMsg &&
    typeof errorMsg === "string" &&
    errorMsg.includes("Redirected when going from") &&
    errorMsg.includes("via a navigation guard")
  );
};

// Catch synchronous errors
window.onerror = function (message, source, lineno, colno, error) {
  if (isNavigationRedirectError(message)) {
    return true; // Prevent this specific error
  }
  return false; // Let other errors propagate normally
};

// Catch Promise rejections
window.addEventListener(
  "unhandledrejection",
  function (event) {
    const error = event.reason;
    if (error && isNavigationRedirectError(error.message)) {
      event.preventDefault();
    }
  },
  true,
);

Vue.use(helpers);

const scope = "openid email profile read:current_user update:current_user_identities";
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  authorizationParams: { audience: process.env.VUE_APP_AUTH0_AUDIENCE, scope },
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl ? appState.targetUrl : window.location.pathname,
    );
  },
});

Vue.use(VueFilterDateFormat);
Vue.use(VueTelInputVuetify, {
  vuetify,
});
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

axiosAuthSetup();
axiosErrorHandlingSetup();
axiosBoligmappaResponseSetup();

Vue.config.productionTip = false;

if (location.hostname !== "localhost") {
  Vue.prototype.$rollbar = new Rollbar({
    accessToken: process.env.VUE_APP_ROLLBAR_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    ignoredMessages: [
      "ResizeObserver loop completed with undelivered notifications.",
      "ResizeObserver loop limit exceeded",
    ],
    checkIgnore: function (isUncaught, args, payload) {
      const exception = payload?.body?.trace?.exception;
      const message = exception?.message;
      const stack = payload?.body?.trace?.frames;

      const isStonlyChunkError =
        message?.includes("Loading chunk") &&
        (stack?.some((frame) => frame.filename.includes("stonly")) ||
          message.includes("stonly"));

      const isBackendError = payload?.request?.status && payload.request.status >= 500;

      const isNavRedirectError = isNavigationRedirectError(message);

      const isErrorToPrevent = isStonlyChunkError || isBackendError || isNavRedirectError;

      if (isErrorToPrevent) {
        return true;
      }

      return false;
    },
    payload: {
      environment: process.env.NODE_ENV,
      code_version: packageJson.version,
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: packageJson.version,
          guess_uncaught_frames: true,
        },
      },
    },
  });

  Vue.config.errorHandler = (err, vm, info) => {
    vm.$rollbar.error(err);
    if (err.message && err.message.includes("Loading chunk")) {
      window.location.reload();
    }
    throw err;
  };
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

window.addEventListener("popstate", (e) => {
  store.commit("route/removeLastRouteFromHistory");
});
