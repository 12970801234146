<template>
  <div class="h-100">
    <div class="primary--opaque--background px-6 py-5 rounded border-a h-100">
      <v-row>
        <v-col style="min-width: 175px">
          <div class="d-flex align-center h-100">
            <div class="d-flex text-body-1 font-weight-bold">{{ title }}</div>
          </div>
        </v-col>
        <!-- 17.5px is the width of the arrow icon to keep the same spacing -->
        <div style="width: 17.5px"></div>
        <v-col
          style="min-width: 157.5px"
          v-if="showAccounting && hasTimeEntriesIntegrationEnabled"
        >
          <div class="d-flex align-center">
            <div class="d-flex text-body-1 font-weight-bold">
              {{ $t("common.accounting") }}
            </div>
          </div>
        </v-col>
        <v-col
          style="min-width: 157.5px"
          v-if="showNumber"
        >
          <div class="d-flex align-center">
            <div class="d-flex text-body-1 font-weight-bold">
              {{ $t("common.number") }}
            </div>
          </div>
        </v-col>
      </v-row>
      <div
        v-for="(hourType, index) of filteredHourTypes"
        :key="index"
      >
        <MapHourTypeItem
          :hourType="hourType"
          :accountingHourTypes="filteredAccountingHourTypes"
          :isAccountingHourTypesLoading="isAccountingHourTypesLoading"
          :showNumber="showNumber"
          :showAccounting="showAccounting"
          @hourType:create="onCreateNewHourType"
          @hourType:delete="onDeleteConfirmation"
          @hourType:update="onHourTypeUpdate"
        />
      </div>
      <v-btn
        text
        class="text-transform-none mt-3"
        color="info"
        @click="onAddTempHourType"
      >
        <v-icon
          small
          class="mr-2"
        >
          {{ $icons.REGULAR.ACTION.ADD }}
        </v-icon>
        {{ $t("settings.systemSettings.timeEntrySettings.newActivity") }}
      </v-btn>
    </div>

    <AppDeleteConfirmationDialog
      v-model="deleteDialog.active"
      :item="deleteDialog.item"
      :title="$t('timeRegistration.deleteActivity.title')"
      skip-validation
      @delete="confirmDeleteHourType"
    />
  </div>
</template>

<script>
import MapHourTypeItem from "./MapHourTypeItem.vue";
import { v4 } from "uuid";
import { mapState, mapGetters } from "vuex";

export default {
  props: {
    title: String,
    isExtra: Boolean,
    isInternal: Boolean,
    projectId: String,
    showNumber: {
      type: Boolean,
      default: false,
    },
    showAccounting: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      deleteDialog: {
        active: false,
        item: null,
      },
    };
  },
  computed: {
    ...mapState("hourTypes", {
      hourTypes: "hourTypes",
    }),
    ...mapState("accountingHourTypes", {
      isAccountingHourTypesLoading: "isLoading",
    }),
    ...mapGetters("accountingHourTypes", {
      getAccountingHourTypesByType: "getAccountingHourTypesByType",
    }),
    ...mapGetters("activeIntegrations", {
      hasTimeEntriesIntegrationEnabled: "hasTimeEntriesIntegrationEnabled",
    }),
    filteredAccountingHourTypes() {
      // show all accounting hour types for all inputs
      return this.getAccountingHourTypesByType(this.isInternal);
    },
    filteredHourTypes() {
      return this.hourTypes
        .filter((hourType) => {
          const matchesProject = this.projectId
            ? hourType?.projectId === this.projectId
            : !hourType?.projectId;

          return (
            matchesProject &&
            hourType.isInternal === this.isInternal &&
            hourType.isExtra === this.isExtra
          );
        })
        .sort((a, b) => b.isLocked - a.isLocked);
    },
  },
  methods: {
    onAddTempHourType() {
      const tempId = v4();
      const tempHourType = {
        isExtra: this.isExtra,
        isInternal: this.isInternal,
        isActive: true,
        ...(this.projectId && { projectId: this.projectId }),
        tempId,
      };
      this.$store.commit("hourTypes/addHourType", tempHourType);
      this.$nextTick(() => {
        const input = document.getElementById(
          `hour-type-list-item-name-text-field-${tempId}`,
        );
        input.focus();
      });
    },
    onDeleteConfirmation({ hourType }) {
      this.deleteDialog.item = hourType;
      this.deleteDialog.active = true;
    },
    async confirmDeleteHourType({ item }) {
      await this.$store.dispatch("hourTypes/deleteHourType", { id: item.id });
    },
    async onHourTypeUpdate({ body, id }) {
      await this.$store.dispatch("hourTypes/updateHourType", { body, id });
    },
    async onCreateNewHourType({ body }) {
      const newBody = {
        isExtra: !!this.isExtra,
        isInternal: !!this.isInternal,
        isActive: true,
        ...(this.projectId && { projectId: this.projectId }),
        ...body,
      };

      if (!newBody.name) {
        this.removeHourType(newBody.tempId);
      } else {
        await this.$store.dispatch("hourTypes/createHourType", { body: newBody });
      }
    },
    removeHourType(tempId) {
      this.$store.commit("hourTypes/removeHourType", { tempId });
    },
  },
  components: { MapHourTypeItem },
};
</script>
