import {
  CUSTOM_STATUS_CATEGORIES,
  CUSTOM_STATUS_CATEGORY_COLOR,
} from "@/helpers/constants/status";

export const chartTypes = [
  {
    textKey: "chart.chartTypes.area",
    value: "area",
  },
  {
    textKey: "chart.chartTypes.bar",
    value: "bar",
  },
  {
    textKey: "chart.chartTypes.line",
    value: "line",
  },
  {
    textKey: "chart.chartTypes.pie",
    value: "pie",
  },
  {
    textKey: "chart.chartTypes.donut",
    value: "donut",
  },
  {
    textKey: "chart.chartTypes.polarArea",
    value: "polarArea",
  },
  // { //Exclude radar chart for now as it probably wont give much value yet.
  //   text: i18n.t("chart.chartTypes.radar"),
  //   value: "radar",
  // },
  // { //Exclude treemap as it is not working as expected
  //   text: i18n.t("chart.chartTypes.treeMap"),
  //   value: "treemap",
  // },
];

export const chartDatasetOptions = [
  {
    textKey: "common.forms",
    value: "FORM",
  },
  {
    textKey: "common.projects",
    value: "PROJECT",
  },
  {
    textKey: "common.tasks",
    value: "TASK",
  },
];

export const chartGroupingOptions = [
  {
    textKey: "chart.chartGroupingOptions.STATUS_CATEGORY",
    value: "STATUS_CATEGORY",
    axisType: "category",
  },
  {
    textKey: "chart.chartGroupingOptions.CREATED_AT",
    value: "CREATED_AT",
    axisType: "datetime",
  },
];

export const createdRangeOptions = [
  { textKey: "chart.timeRanges.allTime", value: "ALL_TIME" },
  { textKey: "chart.timeRanges.lastYear", value: "LAST_YEAR" },
  { textKey: "chart.timeRanges.lastQuarter", value: "LAST_QUARTER" },
  { textKey: "chart.timeRanges.lastMonth", value: "LAST_MONTH" },
  { textKey: "chart.timeRanges.lastWeek", value: "LAST_WEEK" },
];

export const dataPointIntervalOptions = [
  { textKey: "chart.intervals.day", value: "day" },
  { textKey: "chart.intervals.week", value: "week" },
  { textKey: "chart.intervals.month", value: "month" },
  { textKey: "chart.intervals.quarter", value: "quarter" },
  { textKey: "chart.intervals.year", value: "year" },
];

const chartCategoryGroupOptions = CUSTOM_STATUS_CATEGORIES.map((category) => ({
  textKey: `statuses.categories.${category}`,
  value: category,
  color: CUSTOM_STATUS_CATEGORY_COLOR[category],
}));

export const chartGroupOptions = {
  STATUS_CATEGORY: chartCategoryGroupOptions,
};

export const datasetTypes = [
  {
    textKey: "chart.dataSetTypes.resource",
    value: "RESOURCE",
  },

  {
    textKey: "chart.dataSetTypes.formCategory",
    value: "FORM_CATEGORY",
  },
];
