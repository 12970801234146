import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function getHourTypes({ isActive, includeProjectId }) {
  const params = {
    isActive,
    ...(includeProjectId && { includeProjectId }),
  };

  return axios
    .get(`${apiUrl}hour-type`, { params })
    .then(({ data }) => {
      const hourTypes = data?.hourTypes;
      return hourTypes;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
