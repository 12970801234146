<template>
  <div>
    <file-pond
      name="logo"
      ref="pond"
      acceptedFileTypes="image/png,image/jpeg,image/bmp"
      imageCropAspectRratio="1"
      instantUpload="true"
      allowImagePreview="true"
      allowImageTransform="true"
      allowImageCrop="true"
      styleLoadIndicatorPosition="right top"
      styleProgressIndicatorPosition="right top"
      styleButtonRemoveItemPosition="right top"
      styleButtonProcessItemPosition="right top"
      :files="files"
      :credits="[]"
    />
  </div>
</template>

<script>
import vueFilePond, { setOptions } from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import en from "filepond/locale/en-en";
import nb from "filepond/locale/no_nb";

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginImageTransform,
  FilePondPluginImageEdit,
);

export default {
  components: {
    FilePond,
  },
  props: {
    domainId: {
      type: String,
      required: true,
    },
    logoUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      files: [],
    };
  },
  watch: {
    logoUrl: {
      immediate: true,
      handler(newVal) {
        //Next tick is used to ensure that the file pond is initialized
        this.$nextTick(() => {
          if (newVal && this.files.length === 0) {
            this.files = [
              {
                source: newVal,
                options: {
                  type: "local",
                },
              },
            ];
          }
        });
      },
    },
  },
  mounted() {
    this.initializeFilePond();
  },
  methods: {
    initializeFilePond() {
      if (this.$i18n.locale === "en") {
        setOptions(en);
      } else {
        setOptions(nb);
      }

      setOptions({
        server: {
          process: async (
            fieldName,
            file,
            metadata,
            load,
            error,
            progress,
            abort,
            transfer,
            options,
          ) => {
            const formData = new FormData();
            formData.append(fieldName, file, file.name);
            await this.$store.dispatch("domain/uploadProfileLogo", {
              id: this.domainId,
              formData,
            });
            load();
            return {
              abort: () => {
                abort();
              },
            };
          },
          load: async (source, load, error, progress, abort, headers) => {
            const blob = await this.$store.dispatch(
              "domain/getProfileLogo",
              this.domainId,
            );
            load(blob);
          },
          remove: async (source, load, error) => {
            await this.$store.dispatch("domain/deleteProfileLogo", this.domainId);
            load();
          },
          revert: async (uniqueFileId, load, error) => {
            await this.$store.dispatch("domain/deleteProfileLogo", this.domainId);
            load();
          },
        },
      });
    },
  },
};
</script>
