export const projectTemplateBuilderMixins = {
  data() {
    return {
      projectTemplateBuilderMixins_taskNameWidth: 70,
      projectTemplateBuilderMixins_dueDateWidth: 30,
      projectTemplateBuilderMixins_nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && !!v.trim()) || "Name is required",
        (v) => (v ? v.length <= 255 || `Maximum 255 characters` : ""),
      ],
    };
  },
  methods: {
    projectTemplateBuilderMixins_moveItem({ item, body }) {
      this.$emit("item:move", { item, body });
    },
    projectTemplateBuilderMixins_isItemSaved(item) {
      const hasId = !!item.id;
      return hasId;
    },
  },
};
