<template>
  <AppContextMenu
    ref="menu"
    v-on="$listeners"
    v-bind="$attrs"
    height="440"
    width="300"
    :closeOnContentClick="false"
  >
    <AppClientGroupedUserAutocomplete
      ref="autocomplete"
      activateOnFocus
      resetOnSubmit
      hide-details
      isReadyToInitialFetch
      :maxWidthMultiplier="1"
      :items="users"
      :value="value"
      :loading="isUsersLoading"
      :multiple="multiple"
      @change="onSubmit"
    />
  </AppContextMenu>
</template>

<script>
import { getSearchUsers } from "@/services/search/users";

/**
 * User picker menu component for selecting users in various contexts
 * Supports both single and multiple selection modes
 * Can edit a single item or multiple items at once (batch editing)
 */
export default {
  props: {
    // Additional parameters to pass to the user search API
    queryParams: Object,
    // Whether multiple users can be selected
    multiple: Boolean,
    // The property name on the items that contains the user data (e.g., 'owner', 'members')
    valueKey: String,
  },
  data() {
    return {
      // List of users available for selection
      users: [],
      // Loading state for the user data
      isUsersLoading: false,
      // The items being edited (e.g., projects when editing project owners)
      itemsToEdit: [],
    };
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    /**
     * The current value to display in the autocomplete
     * For multiple items being edited, we don't show any initial value
     * For a single item, we show its current value for the specified property
     */
    value() {
      // Don't show a value when batch-editing (multiple items)
      if (this.itemsToEdit?.length > 1) return null;

      // For single item editing, show the current value
      return this.itemsToEdit[0]?.[this.valueKey];
    },
  },
  methods: {
    /**
     * Handles submission of the user selection
     * @param {Array|Object} e - Selected user(s) from the autocomplete
     */
    onSubmit(e) {
      if (this.multiple) {
        // When multiple selection is enabled
        const isEditingMultipleItems = this.itemsToEdit?.length > 1;
        const isUpdatingToEmpty = e?.length === 0;

        // Prevent clearing all values when batch-editing multiple items
        // This is a safety measure since the dropdown starts empty in batch mode
        if (isUpdatingToEmpty && isEditingMultipleItems) return;
        this.$emit("submit", e);
      } else {
        if (!e) return;
        // For single selection, always emit the selected value
        this.$emit("submit", e);
      }
      this.close();
    },
    /**
     * Closes the menu
     */
    close() {
      this.$refs.menu.close();
    },
    /**
     * Opens the menu and initializes the edit state
     * @param {Object} e - Event object containing items to edit
     */
    open(e) {
      this.$refs.menu.open(e);
      // Store the items being edited (e.g., projects when changing project owners)
      this.itemsToEdit = e.items;
      this.getUsers();
    },
    /**
     * Fetches the available users for selection
     * Ensures that users currently associated with the items being edited
     * are included in the results
     */
    getUsers() {
      this.isUsersLoading = true;

      let alwaysIncludeIds = [];

      // Extract all user IDs from the items being edited
      this.itemsToEdit.forEach((item) => {
        const value = item[this.valueKey];

        if (Array.isArray(value)) {
          // For properties that contain arrays of users (e.g., 'members')
          // Extract all user IDs from the array
          const ids = value.map((user) => user.id).filter(Boolean);
          alwaysIncludeIds.push(...ids);
        } else if (value?.id) {
          // For properties that contain a single user object (e.g., 'owner')
          alwaysIncludeIds.push(value.id);
        }
      });

      // Remove duplicate IDs
      alwaysIncludeIds = [...new Set(alwaysIncludeIds)];

      // Fetch users, ensuring current users are included in results
      getSearchUsers({
        params: {
          ...this.queryParams,
          alwaysIncludeIds,
        },
      })
        .then((users) => {
          this.users = users;
          setTimeout(() => {
            this.$refs.autocomplete.focus();
          }, 12);
        })
        .finally(() => {
          this.isUsersLoading = false;
        });
    },
  },
};
</script>
