<template>
  <AppFiltersDrawer2
    v-if="localShowDrawer"
    @close="closeFilters"
    :tableModel="tableModel"
    :children="children"
  />
</template>

<script>
import { getSearchUsers } from "@/services/search/users";
import { filtersItemsMixins } from "@/helpers/mixins";
import { filtersWatchMixins2 } from "@/helpers/mixins";
import { formatViewQueryParams } from "@/helpers/util";

export default {
  mixins: [filtersItemsMixins, filtersWatchMixins2],
  props: {
    showDrawer: Boolean,
    tableModel: String,
    hideProjectLevel: Boolean,
  },
  model: {
    prop: "showDrawer",
    event: "change",
  },
  data() {
    return {
      users: [],
      isUsersLoading: false,
      statusChangers: [],
      isStatusChangersLoading: false,

      hourTypes: [],
      isHourTypesLoading: false,
      tasks: [],
      isTasksLoading: false,
    };
  },
  methods: {
    //Initial data load logic is handled in filtersWatchMixins2
    getData() {
      this.getUsers();
      this.getStatusChangers();
    },
    closeFilters() {
      this.localShowDrawer = false;
    },
    async getUsers() {
      this.isUsersLoading = true;
      const params = { alwaysIncludeIds: this.userIds };
      getSearchUsers({ params })
        .then((users) => {
          this.users = users;
        })
        .finally(() => {
          this.isUsersLoading = false;
        });
    },
    async getStatusChangers() {
      this.isStatusChangersLoading = true;
      const params = { alwaysIncludeIds: this.statusChangerIds };
      getSearchUsers({ params })
        .then((users) => {
          this.statusChangers = users;
        })
        .finally(() => {
          this.isStatusChangersLoading = false;
        });
    },
  },
  computed: {
    localShowDrawer: {
      get() {
        return this.showDrawer;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    projectId() {
      return this.$route.params.projectId;
    },
    projectIds() {
      return formatViewQueryParams()?.projectIds ?? [];
    },
    taskIds() {
      return formatViewQueryParams()?.taskIds ?? [];
    },
    statusChangerIds() {
      return formatViewQueryParams()?.statusChangerIds ?? [];
    },
    userIds() {
      return formatViewQueryParams()?.userIds ?? [];
    },
    children() {
      const children = [
        {
          is: "AppStatusFilter",
          timeEntries: true,
          labelKey: "filters.timeEntries.status",
          filterValueKey: "status",
          itemValue: "value",
          itemText: "text",
        },
        {
          is: "AppFiltersClientGroupedUserAutocomplete",
          labelKey: "filters.timeEntries.user",
          filterValueKey: "userIds",
          itemValue: "id",
          itemText: "name",
          items: this.users ?? null,
          loading: this.isUsersLoading,
          userAvatar: true,
        },
        {
          is: "AppFiltersClientGroupedUserAutocomplete",
          labelKey: "filters.timeEntries.statusChangedBy",
          filterValueKey: "statusChangerIds",
          itemValue: "id",
          itemText: "name",
          items: this.statusChangers ?? null,
          loading: this.isStatusChangersLoading,
          userAvatar: true,
        },
        {
          is: "AppFiltersHourTypeMultiAutocomplete",
          labelKey: "filters.timeEntries.type",
          filterValueKey: "hourTypeIds",
          isReadyToInitialFetch: this.isReadyToInitialFetch,
          showAllGlobal: !this.projectId,
          queryParams: {
            projectId: this.projectId,
          },
        },
        !this.projectId && {
          is: "AppFiltersProjectMultiAutocomplete",
          labelKey: "filters.timeEntries.project",
          filterValueKey: "projectIds",
          isReadyToInitialFetch: this.isReadyToInitialFetch,
          queryParams: {
            alwaysIncludeIds: this.projectIds,
          },
        },
        {
          is: "AppFiltersTaskMultiAutocomplete",
          labelKey: "filters.timeEntries.task",
          filterValueKey: "taskIds",
          itemValue: "id",
          itemText: "name",
          showHelper: this.projectIds?.length > 0,
          queryParams: {
            alwaysIncludeIds: this.taskIds,
            //filter project based on selected projectIds
            projectIds: this.projectIds,
            //if project scope, filter project based on projectId instead
            ...(this.projectId && {
              projectIds: this.projectId,
            }),
          },
          isReadyToInitialFetch: this.isReadyToInitialFetch,
          refetchOnQueryParamsChange: true,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.timeEntries.hasComment",
          filterValueKey: "hasComment",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_yesOrNoItems,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.timeEntries.isSynchronized",
          filterValueKey: "isSynchronized",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_yesOrNoItems,
        },
      ];

      return children;
    },
  },
};
</script>
