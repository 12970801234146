import { LIGHT } from "../icons";

export const storageMixins = {
  methods: {
    storageMixins_getStorageIconName(item) {
      const { mime, meta, type, isLocked } = item;

      if (type === this.$constant.FOLDER) {
        if (isLocked) {
          return LIGHT.FILE.FOLDER_LOCK;
        }
        return LIGHT.FILE.FOLDER;
      }
      let iconName = LIGHT.FILE.FILE;
      if (mime) {
        if (mime.match("image/*")) {
          iconName = LIGHT.FILE.FILE_IMAGE;
        }

        if (mime.match("video/*")) {
          iconName = LIGHT.FILE.FILE_VIDEO;
        }

        if (mime.match("application/pdf")) {
          iconName = LIGHT.FILE.FILE_PDF;
        }

        // is word
        if (this.$constant.OFFICE_WORD_MIMETYPES.includes(mime)) {
          iconName = "$docx";
        }

        // is odt
        if (this.$constant.ODT_MIMETYPE === mime) {
          iconName = "$odt";
        }

        // is excel
        if (this.$constant.OFFICE_EXCEL_MIMETYPES.includes(mime)) {
          iconName = "$xlsx";
        }

        // is ods
        if (this.$constant.ODS_MIMETYPE === mime) {
          iconName = "$ods";
        }

        // is powerpoint
        if (this.$constant.OFFICE_POWERPOINT_MIMETYPES.includes(mime)) {
          iconName = "$pptx";
        }

        // is odp
        if (this.$constant.ODP_MIMETYPE === mime) {
          iconName = "$odp";
        }

        if (mime.match("text/plain")) {
          iconName = LIGHT.FILE.FILE_LINES;
        }
      }
      if (Array.isArray(meta)) {
        const metaItem = meta.find(
          (item) => item.field === this.$constant.E_SIGN_META_FIELD,
        );
        if (metaItem) {
          iconName = LIGHT.FILE.FILE_SIGNATURE;
        }
      }
      return iconName;
    },
    storageMixins_getPermissionLevelIconName(permissionLevel) {
      if (permissionLevel === "RESTRICTED") {
        return LIGHT.COMMON.USER_GROUP;
      }
      if (permissionLevel === "PRIVATE") {
        return LIGHT.COMMON.USER;
      }
      if (permissionLevel === "PUBLIC") {
        return LIGHT.COMMON.USERS;
      }
    },
    storageMixins_getStorageCount(storage) {
      const fileCount = parseInt(storage.countFiles || 0);
      const folderCount = parseInt(storage.countFolders || 0);
      const totalCount = fileCount + folderCount;
      return totalCount >= 99 ? "99+" : `${totalCount}`;
    },
    async storageMixins_getFiles(e) {
      const files = Array.from(e.target.files ? e.target.files : e.dataTransfer.files);
      return this.storageMixins_filesConvetion(files);
    },
    storageMixins_filesConvetion(files) {
      const convertedFiles = Promise.all(
        files.map((file) => {
          const extension = file.name?.split(".").pop()?.toLowerCase();
          if (
            (extension === "heic" || extension === "heif") &&
            (!file.type || file.type === "")
          ) {
            const newType = `image/${extension}`;
            return new File([file], file.name, {
              type: newType,
              lastModified: file.lastModified,
            });
          }
          return file;
        }),
      );
      return convertedFiles;
    },
    storageMixins_getReadableFileType(type) {
      const translationKey = `fileManagement.fileExplorer.readableFileTypes.${type}`;
      return this.$te(translationKey) ? this.$t(translationKey) : type;
    },
  },
};
