<script>
import ResponseItem from "./ResponseItem.vue";
import { formTemplateBuilderMixins } from "@/helpers/mixins";
import GroupItem from "./GroupItem.vue";
import ResponseItemHeader from "./ResponseItemHeader.vue";
import { VIcon } from "vuetify/lib";
import Draggable from "vuedraggable";
import { VBtn } from "vuetify/lib";
import { permissionMixins } from "@/helpers/mixins";

export default {
  mixins: [formTemplateBuilderMixins, permissionMixins],
  props: {
    template: Object,
    item: Object,
    items: Array,
    isCreatingItem: Boolean,
    sectionIndex: Number,
    isCms: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    canEditFormTemplate() {
      return this.permissionMixins_formTemplate.edit.can;
    },
  },
  methods: {
    onChange(e, parentId) {
      if (e.moved) {
        this.formTemplateBuilderMixins_moveItem({
          item: e.moved?.element,
          body: { index: e.moved?.newIndex, parentId },
        });
      }
      if (e.added) {
        this.formTemplateBuilderMixins_moveItem({
          item: e.added?.element,
          body: { index: e.added?.newIndex, parentId },
        });
      }
    },
    onDragChoose() {
      document.documentElement.classList.add("drag-cursor");
      document.documentElement.classList.add("user-select-none");
    },
    onDragUnchoose() {
      document.documentElement.classList.remove("drag-cursor");
      document.documentElement.classList.remove("user-select-none");
    },
    genActionButton(item) {
      return this.$createElement(
        VBtn,
        {
          class: `ui-background--background text-transform-none mt-4`,
          props: {
            text: true,
            color: "primary",
            disabled: this.isCreatingItem || !this.canEditFormTemplate,
            outlined: true,
            small: true,
          },
          on: {
            click: (e) =>
              this.formTemplateBuilderMixins_createItem({
                body: {
                  parentId: item.id,
                  type: this.$constant.TEXTAREA,
                  name: "",
                },
              }),
          },
        },
        [
          this.$createElement(
            VIcon,
            { props: { small: true } },
            this.$icons.REGULAR.ACTION.ADD,
          ),
          this.$createElement(
            "div",
            { class: "pl-2" },
            this.$t("templateManagement.form.builder.item.addQuestion"),
          ),
        ],
      );
    },
    genGroup(item, groupIndex) {
      return this.$createElement(
        "div",
        {
          class: [
            `py-12 background-inherit rounded-lg`,
            { "v-card--disabled": item.isLoading && item.type === this.$constant.GROUP },
          ],
          attrs: {
            id: `form-template-item-${item.id}`,
          },
        },
        [
          this.$createElement(
            "div",
            { class: "d-flex w-100 justify-center background-inherit" },
            [
              this.$createElement(
                "div",
                {
                  class: "d-flex align-center flex-column",
                  style: "min-width: 32px",
                },
                [
                  this.canEditFormTemplate &&
                    this.$createElement(
                      VIcon,
                      {
                        class: "draggable-handle cursor-grab",
                        style: "margin-top: 10px;",
                      },
                      this.$icons.SOLID.ACTION.DRAG,
                    ),
                ],
              ),
              this.$createElement("div", { class: "w-100 background-inherit" }, [
                this.$createElement(GroupItem, {
                  props: { item },
                  class: "background-inherit",
                  on: {
                    "item:update": (e) => this.formTemplateBuilderMixins_updateItem(e),
                    "item:delete": (e) => this.formTemplateBuilderMixins_deleteItem(e),
                    "items:copy": (e) => this.$emit("items:copy", e),
                  },
                }),

                this.$createElement(ResponseItemHeader),
                this.$createElement(
                  Draggable,
                  {
                    props: {
                      list: item.children,
                    },
                    attrs: {
                      handle: ".draggable-handle",
                      group: "a",
                      animation: this.$constant.DRAG_SETTINGS.ANIMATION_DURATION,
                      ghostClass: this.$constant.DRAG_SETTINGS.GHOST_CLASS_DASHED_OUTLINE,
                      dragClass: this.$constant.DRAG_SETTINGS.DRAG_CLASS_WHITE_BACKGROUND,
                      emptyInsertThreshold: 150,
                      invertedSwapThreshold: 0.4,
                      forceFallback: true,
                      options: {
                        disabled: !this.canEditFormTemplate,
                      },
                    },
                    class: "draggable-container-gap-40",
                    on: {
                      change: (e) => this.onChange(e, item.id),
                      choose: this.onDragChoose,
                      unchoose: this.onDragUnchoose,
                    },
                  },
                  this.genHierarchy(item.children, groupIndex),
                ),
                this.genActionButton(item),
              ]),
            ],
          ),
        ],
      );
    },
    genHierarchy(items, parentGroupIndex) {
      const hierarchy = [];
      let responseItemIndex = 0;
      for (let [index, item] of items.entries()) {
        if (item.type === this.$constant.GROUP) {
          hierarchy.push(this.genGroup(item, responseItemIndex));
          responseItemIndex++;
        } else {
          hierarchy.push(
            this.$createElement(
              "div",
              {
                key: item.id,
                class: "background-inherit",
              },
              [
                this.$createElement(ResponseItem, {
                  class: `w-100`,
                  props: {
                    template: this.template,
                    item,
                    responseItemIndex,
                    sectionIndex: this.sectionIndex,
                    parentGroupIndex,
                    isCms: this.isCms,
                  },
                  on: {
                    "item:update": (e) => this.formTemplateBuilderMixins_updateItem(e),
                    "item:delete": (e) => this.formTemplateBuilderMixins_deleteItem(e),
                    "items:copy": (e) => this.$emit("items:copy", e),
                    "defaultAnswerTemplateMenu:open": (e) =>
                      this.formTemplateBuilderMixins_openDefaultAnswerTemplateMenu(e),
                  },
                }),
              ],
            ),
          );
          responseItemIndex++;
        }
      }

      return hierarchy;
    },
  },
  render(h) {
    const items = this.formTemplateBuilderMixins_getHierarchyByParentId(
      this.items,
      this.item.id,
    );
    return h("div", { class: "background-inherit" }, [
      h(ResponseItemHeader),
      h(
        Draggable,
        {
          props: {
            list: this.formTemplateBuilderMixins_getChildrenByParentId(
              this.items,
              this.item.id,
            ),
          },
          attrs: {
            handle: ".draggable-handle",
            group: "a",
            animation: this.$constant.DRAG_SETTINGS.ANIMATION_DURATION,
            dragClass: this.$constant.DRAG_SETTINGS.DRAG_CLASS,
            ghostClass: this.$constant.DRAG_SETTINGS.GHOST_CLASS_DASHED_OUTLINE,
            emptyInsertThreshold: 100,
            invertedSwapThreshold: 0.4,
            forceFallback: true,
            options: {
              disabled: !this.canEditFormTemplate,
            },
          },
          on: {
            change: (e) => this.onChange(e, this.item.id),
            choose: this.onDragChoose,
            unchoose: this.onDragUnchoose,
          },
          class: "text-border-reset draggable-container-gap-40 background-inherit",
        },

        this.genHierarchy(items),
      ),
    ]);
  },
};
</script>
