<template>
  <AppBtnWithIndicator
    :showIndicator="hasFilters"
    :icon="$icons.SOLID.ACTION.ADD"
    :indicatorText="totalFilterCount"
    @click="click"
    :text="$t('common.filters')"
  />
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AppBtnWithIndicator from "./AppBtnWithIndicator.vue";
import { isEmptyValue } from "@/helpers/util";

export default {
  props: {
    tableModel: String,
  },
  computed: {
    ...mapGetters("resourceViews", {
      currentResourceViewConfig: "getCurrentResourceViewConfig",
    }),
    ...mapState("resourceViews", {
      scopedFilters: (state) => state.scopedFilters,
    }),
    // Count all filters (both saveable and unsaveable)
    totalFilterCount() {
      let count = 0;

      // Count saveable filters from the current view
      if (this.currentResourceViewConfig?.filters) {
        count += this.countValidFilters(this.currentResourceViewConfig.filters);
      }

      // Count unsaveable filters
      count += this.countValidFilters(this.scopedFilters);

      return count;
    },

    hasFilters() {
      return this.totalFilterCount > 0;
    },
  },
  methods: {
    countValidFilters(filtersObject) {
      let count = 0;
      Object.entries(filtersObject).forEach(([key, value]) => {
        if (!isEmptyValue(value)) {
          count++;
        }
      });
      return count;
    },

    click() {
      this.$emit("click");
    },
  },
  components: { AppBtnWithIndicator },
};
</script>
