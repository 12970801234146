<template>
  <div class="information_drawer">
    <v-toolbar
      class="border-b"
      dense
      flat
      fixed
      height="68"
    >
      <v-toolbar-title class="font-weight-bold">
        {{ $t("common.overview") }}
      </v-toolbar-title>
    </v-toolbar>
    <AppCenterLoader
      v-model="isLoading"
      :title="$t('common.loading')"
      :height="'calc(100% - 68px)'"
    />
    <div
      class="pa-5 pb-0 secondary-height overflow-y-auto"
      v-if="!isLoading && template"
    >
      <div
        class="pb-5"
        v-if="typeof template.isRestrictedToTemplateOwner === 'boolean' || true"
      >
        <div class="label--small">
          {{
            $t("templateManagement.project.drawer.details.restrictedToOrg") | capitalize
          }}
        </div>
        <AppDefaultSwitch
          :disabled="!canEditProjectTemplate"
          hide-details
          inset
          class="mt-0"
          :input-value="template.isRestrictedToTemplateOwner"
          @change="updateTemplateField($event, 'isRestrictedToTemplateOwner')"
        />
      </div>
      <div
        class="pb-5"
        v-if="canViewShareableWithChildDomain"
      >
        <div class="label--small">
          {{ $t("common.shareableWithChildDomains") | capitalize }}
        </div>
        <AppDefaultSwitch
          :disabled="!canEditShareableWithChildDomain"
          hide-details
          inset
          class="mt-0"
          :input-value="template?.isShareableWithChildDomains"
          @change="updateTemplateField($event, 'isShareableWithChildDomains')"
        />
      </div>
      <div class="pb-5">
        <div class="label--small">
          {{ $t("common.createdBy") | capitalize }}
        </div>
        <AppUserAvatar
          class="text-body-1"
          :user="template.creator"
          show-name
          :truncate="30"
        />
      </div>

      <div class="pb-5">
        <div class="label--small">
          {{ $t("common.creationDate") | capitalize }}
        </div>
        <div class="text-body-1">
          {{ new Date(template.createdAt) | dateFormat("DD.MM.YYYY HH:mm") }}
        </div>
      </div>

      <div class="pb-5">
        <div class="label--small">
          {{ $t("common.lastUpdated") | capitalize }}
        </div>
        <div class="text-body-1">
          {{ new Date(template.updatedAt) | dateFormat("DD.MM.YYYY HH:mm") }}
        </div>
      </div>

      <div class="pb-5">
        <div class="label--small">
          {{ $t("common.id") | capitalize }}
        </div>
        <div class="text-body-1">{{ template.number }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { permissionMixins } from "@/helpers/mixins";

export default {
  mixins: [permissionMixins],
  props: {
    template: Object,
    isLoading: Boolean,
  },
  computed: {
    canEditProjectTemplate() {
      return this.permissionMixins_projectTemplate.edit.can;
    },
    shareableWithChildDomainPermissions() {
      return this.permissionMixins_projectTemplate.shareableWithChildDomain;
    },
    canViewShareableWithChildDomain() {
      return this.shareableWithChildDomainPermissions.view.can;
    },
    canEditShareableWithChildDomain() {
      return this.shareableWithChildDomainPermissions.edit.can;
    },
    selectedCategory() {
      return this.template?.category?.id || null;
    },
  },
  methods: {
    updateTemplateField(value, field) {
      this.updateTemplate({ [field]: value });
    },
    updateTemplate(body) {
      this.$emit("template:update", { template: this.template, body });
    },
  },
};
</script>
