<script>
import DateField from "./answerTypes/DateField.vue";
import { VBtn, VIcon, VSpacer } from "vuetify/lib";
import Textarea from "./answerTypes/Textarea.vue";
import RadioGroup from "./answerTypes/RadioGroup.vue";
import MultiChoice from "./answerTypes/MultiChoice.vue";
import ItemNote from "./ItemNote.vue";
import ItemImages from "./ItemImages.vue";
import ItemTasks from "./ItemTasks.vue";
import Table from "./answerTypes/Table.vue";
import { fileDropHandlerMixins, formTemplateBuilderMixins } from "@/helpers/mixins";
import moment from "moment";
import DropArea from "@/components/common/filePicker/DropArea.vue";

export default {
  components: { DropArea },
  mixins: [formTemplateBuilderMixins, fileDropHandlerMixins],
  props: {
    item: Object,
    observer: IntersectionObserver,
    assignableUsers: Array,
  },
  computed: {
    showNote() {
      return this.item.showNote || !!this.item.note;
    },
  },
  data() {
    return {
      isDraggingOver: false,
    };
  },
  created() {
    this.$on("fileDropHandlerMixins_files:dropped", this.onUploadImages);
  },
  methods: {
    genNameRow() {
      const appendInner = this.item?.isAddedAfterCreation
        ? this.genIsAddedAfterCreationDeleteAction()
        : null;
      return this.$createElement("AppQuestionItemNameRow", {
        props: { item: this.item },
        on: {
          "questionName:mounted": (e) => this.handleQuestionNameMounted(e),
        },
        scopedSlots: {
          "append-inner": () => appendInner,
        },
      });
    },
    genExplanationRow() {
      const explanation = this.item?.explanation || "";
      if (!explanation.trim()) return;
      return this.$createElement("div", this.item.explanation);
    },
    genAnswerInput() {
      let answer = this.$createElement("div", "invalid type");

      if (this.item.type === this.$constant.DATE_FIELD) {
        answer = this.$createElement(DateField, {
          props: { item: this.item },
          on: { "item:update": (e) => this.formTemplateBuilderMixins_updateItem(e) },
        });
      } else if (this.item.type === this.$constant.TEXTAREA) {
        answer = this.$createElement(Textarea, {
          props: { item: this.item },
          on: { "item:update": (e) => this.formTemplateBuilderMixins_updateItem(e) },
        });
      } else if (this.item.type === this.$constant.RADIO_GROUP) {
        answer = this.$createElement(RadioGroup, {
          props: { item: this.item },
          on: { "item:update": (e) => this.formTemplateBuilderMixins_updateItem(e) },
        });
      } else if (this.item.type === this.$constant.MULTI_CHOICE) {
        answer = this.$createElement(MultiChoice, {
          props: { item: this.item },
          on: { "item:update": (e) => this.formTemplateBuilderMixins_updateItem(e) },
        });
      } else if (this.item.type === this.$constant.TABLE) {
        answer = this.$createElement(Table, {
          props: { item: this.item },
          on: { "item:update": (e) => this.formTemplateBuilderMixins_updateItem(e) },
        });
      }

      return this.$createElement("div", { class: "py-2 d-flex flex-column" }, [
        this.$createElement(
          "span",
          { class: "text-body-2 font-weight-bold pb-1" },
          this.$t("formManagement.preview.answer"),
        ),
        [answer],
      ]);
    },
    genImages() {
      if (this.item.images?.length < 1) return;
      return this.$createElement(ItemImages, {
        props: { item: this.item },
        on: {
          "item:imageDelete": (e) => this.$emit("item:imageDelete", e),
          "item:imagePreview": (e) => this.$emit("item:imagePreview", e),
        },
      });
    },
    genNote() {
      if (!this.showNote) return;
      return this.$createElement(ItemNote, {
        props: { note: this.item.note, item: this.item },
        on: {
          "note:close": (e) => {
            const newItem = { ...this.item, showNote: false };
            this.$emit("item:commitUpdate", newItem);
          },
          "item:update": (e) => this.formTemplateBuilderMixins_updateItem(e),
        },
      });
    },
    genTasks() {
      if (this.item.tasks?.length < 1) return;
      return this.$createElement(ItemTasks, {
        props: { item: this.item, assignableUsers: this.assignableUsers },
        on: {
          "item:taskUpdate": (e) => this.$emit("item:taskUpdate", e),
          "item:taskDelete": (e) => this.$emit("item:taskDelete", e),
        },
      });
    },
    genOptionsBar() {
      return this.$createElement("div", { class: "d-flex py-2" }, [
        this.$createElement("div", { class: "d-flex align-center w-100" }, [
          ...[this.item?.lastUpdatedBy ? [this.genLastUpdatedBy()] : []],
          this.$createElement(VSpacer),
          this.genUploadImagesBtn(),
          this.$createElement(
            VBtn,
            {
              ref: "optionsBtn",
              props: { icon: true },
              on: { click: this.openOptionsMenu },
              class: "ml-1",
            },
            [
              this.$createElement(
                VIcon,
                { props: { small: true } },
                this.$icons.REGULAR.ACTION.MENU,
              ),
            ],
          ),
        ]),
      ]);
    },
    genOptionsBtn({ icon, textKey, clickHandler, additionalProps }) {
      const defaultProps = {
        text: true,
        small: true,
      };
      const props = { ...defaultProps, ...additionalProps };

      return this.$createElement(
        VBtn,
        {
          props,
          on: { click: clickHandler },
        },
        [
          this.$createElement(VIcon, { props: { small: true } }, icon),
          this.$createElement("div", { class: "pl-2 text-capitalize" }, this.$t(textKey)),
        ],
      );
    },
    genUploadImagesBtn() {
      if (!this.item.hasOptions) return null;
      return this.genOptionsBtn({
        icon: this.$icons.LIGHT.COMMON.CAMERA,
        textKey: "formManagement.completion.addPictures",
        clickHandler: (e) => this.onTriggerFilePicker(),
        additionalProps: {
          loading: this.item.imagesUploading,
          disabled: this.item?.isNotRelevant,
        },
      });
    },

    genLastUpdatedBy() {
      return this.$createElement("div", { class: "d-flex align-center" }, [
        this.$createElement("AppUserAvatarTooltip", {
          props: {
            user: this.item.lastUpdatedBy,
            size: "30",
            top: true,
          },
        }),
        this.$createElement("div", { class: "pl-2" }, [
          this.$createElement(
            "div",
            { style: "line-height: 1.2 !important; font-size: 0.75rem" },
            [
              this.$t("formManagement.completion.lastUpdatedBy") +
                " " +
                moment(this.item.updatedAt).format("DD.MM.YYYY HH:mm"),
            ],
          ),
          this.$createElement(
            "div",
            {
              class: "font-weight-medium text-body-2",
              style: "line-height: 1.2 !important",
            },
            [this.item.lastUpdatedBy.name],
          ),
        ]),
      ]);
    },
    genIsAddedAfterCreationDeleteAction() {
      return this.$createElement("div", { class: "pl-2" }, [
        this.$createElement(
          VBtn,
          {
            props: { icon: true, color: "error" },
            on: {
              click: (e) =>
                this.formTemplateBuilderMixins_deleteItem({ item: this.item }),
            },
          },
          [this.$createElement(VIcon, this.$icons.LIGHT.ACTION.DELETE)],
        ),
      ]);
    },
    onTriggerFilePicker() {
      this.$emit("item:triggerFilePicker", {
        item: this.item,
      });
    },
    onUploadImages(files) {
      this.$emit("item:imageUpload", { files, item: this.item });
    },
    openOptionsMenu() {
      this.formTemplateBuilderMixins_openOptionsMenu({
        from: this.$refs.optionsBtn.$el,
        direction: "bottom",
        items: [this.item],
      });
    },
    handleQuestionNameMounted(ref) {
      if (this.item?.hasOptions && this.observer && ref) {
        this.observer.observe(ref);
      }
    },
  },
  render(h) {
    const questionContent = h(
      "div",
      {
        class: "d-flex flex-column py-3",
        attrs: {
          //Used for scroll to item when query param for formItemId is present
          id: `form-item-${this.item.id}`,
        },
      },
      [
        this.$createElement("div", { class: "py-2" }, [
          this.genNameRow(),
          !this.item.isNotRelevant ? this.genExplanationRow() : null,
        ]),
        !this.item.isNotRelevant ? this.genAnswerInput() : null,
        ...[
          this.item.hasOptions && !this.item.isNotRelevant
            ? [this.genNote(), this.genImages(), this.genTasks()]
            : [],
          this.genOptionsBar(),
        ],
      ],
    );

    const isImageUploadDisabled = !this.item.hasOptions || this.item?.isNotRelevant;
    return h(
      DropArea,
      {
        props: {
          isDraggingOver: this.fileDropHandlerMixins_isDraggingOver,
          loading: this.item.imagesUploading || this.isLoadingAcceptedFileTypes,
          disabled: isImageUploadDisabled,
        },
        on: {
          dragOver: this.fileDropHandlerMixins_onDragOver,
          dragLeave: this.fileDropHandlerMixins_onDragLeave,
          fileDrop: this.fileDropHandlerMixins_onFileDrop({ mimeTypes: ["image/*"] }),
        },
      },
      [questionContent],
    );
  },
};
</script>
